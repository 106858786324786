import { TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
const localeMap: Record<string, any> = {
  en: enLocale,
  ru: ruLocale,
};
const RU_PLACEHODLED = 'дд.мм.гггг';

interface Props {
  name?: string;
  label?: string;
  inputProps: TextFieldProps & { 'data-testid'?: string };
  onChange: (date: string) => void;
  value: string;
  ref: any;
  disabled?: boolean;
  ['data-testid']?: string;
}
export default function DatePickerInput(props: Partial<Props>) {
  const { inputProps, ...other } = props;
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
      <DesktopDatePicker
        label={props.label}
        inputFormat='dd.MM.yyyy'
        disableOpenPicker={true}
        mask='__.__.____'
        renderInput={(params) => (
          <TextField
            sx={{ width: 1 }}
            variant='standard'
            inputRef={props.ref}
            {...params}
            disabled={props.disabled}
            inputProps={{
              ...params.inputProps,
              'data-testid': props['data-testid'],
              placeholder: RU_PLACEHODLED,
            }}
          />
        )}
        onChange={(date, inputStr) => {
          // TODO: Rewrite this schit
          if (!date || `${date}` === 'Invalid Date' || inputStr?.length !== '__.__.____'.length) return;
          const formatDate = utils.date.format(date, 'YYYY-MM-DD');
          other.onChange?.(formatDate);
        }}
        value={other.value ? dayjs(other.value) : null}
      />
    </LocalizationProvider>
  );
}
