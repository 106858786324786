import { ApiError } from 'api/errors';
import { ErrorMessage } from 'api/http';
import { getLinkInfo } from 'api/services/short-link';
import { IShortLinkDataEntryInvite, IShortLinkEntity } from 'api/services/short-link.dto';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * use logic to invite client
 *
 * @example
 * ```ts
 * const logic=useInviteLogic();
 *
 * useEffect(()=>{
 *    logic.getShortLinkData(`shortinfo`);
 * },[]);
 *
 * ```
 * @returns
 */
export default function useInviteLogic<T = IShortLinkDataEntryInvite>() {
  const [error, setError] = useState<ErrorMessage>();
  const { shortlink } = useParams<{ shortlink: string }>();
  const [shortlinkData, setShortLinkData] = useState<IShortLinkEntity<T>>();

  /**
   * get information about shortlink
   * @param {string} link - invite shortlink
   * @returns {Promise<void>}
   */
  const getShortLinkData = useCallback(
    async (link = shortlink) => {
      try {
        const res = await getLinkInfo<T>(link as string);
        setShortLinkData(res.body);
      } catch (err: unknown) {
        setError(err as ApiError);
      }
    },
    [shortlink],
  );

  useEffect(() => {
    if (shortlink) getShortLinkData();
  }, [shortlink]);
  return { error, shortlinkData, getShortLinkData, shortlink };
}
export type UseInviteLogic = ReturnType<typeof useInviteLogic>;
