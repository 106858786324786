import { Typography } from '@mui/material';
import { IRegisterUser } from 'api/services/auth.dto';
import { IShortLinkDataEntryInvite } from 'api/services/short-link.dto';
import useInviteLogic from 'hooks/logic/useInviteLogic';
import { useRegisterLogic } from 'hooks/logic/useRegisterLogic';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

import AuthRegisterForm from '../Register/components/Form/AuthRegisterForm';
import AuthRegisterTemplate from '../Register/template/AuthRegisterTemplate';

export default function AuthInviteRegisterPage() {
  const { t } = useTranslation();

  const logic = useRegisterLogic();
  const shortlinkLogic = useInviteLogic();

  const shortLinkData = useMemo(() => {
    return shortlinkLogic.shortlinkData?.data as IShortLinkDataEntryInvite;
  }, [shortlinkLogic.shortlink, shortlinkLogic.shortlinkData]);

  const handleRegister = async (smsCode: string, formData: IRegisterUser) => {
    formData.inviteLink = shortlinkLogic.shortlink;

    return logic.registerRequest(smsCode, formData);
  };

  return (
    <AuthRegisterTemplate>
      <AuthRegisterForm {...logic} registerRequest={handleRegister}>
        <Fragment>
          <Typography>
            {t('interface:page.AuthRegisterPage.Form.inviteClientText')} {utils.format.nameWithInitials(shortLinkData?.inviter)}
          </Typography>
          <Typography>{utils.format.hidePhoneNumber(shortLinkData?.inviter?.phone)}</Typography>
        </Fragment>
      </AuthRegisterForm>
    </AuthRegisterTemplate>
  );
}
