/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

export const Email = createSvgIcon(
  <svg viewBox='0 0 72 68' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 25.6364V64.0455C1 64.8291 1.30729 65.5806 1.85427 66.1346C2.40125 66.6887 3.14312 67 3.91667 67H68.0833C68.8569 67 69.5987 66.6887 70.1457 66.1346C70.6927 65.5806 71 64.8291 71 64.0455V25.6364L36 2L1 25.6364Z'
      fill='#F5F5F5'
    />
    <path d='M71 25.6364L42.3802 46.3182H29.6198L1 25.6364' fill='#F5F5F5' />
    <path
      d='M1 25.6364V64.0455C1 64.8291 1.30729 65.5806 1.85427 66.1346C2.40125 66.6887 3.14312 67 3.91667 67H68.0833C68.8569 67 69.5987 66.6887 70.1457 66.1346C70.6927 65.5806 71 64.8291 71 64.0455V25.6364M1 25.6364L36 2L71 25.6364M1 25.6364L29.6198 46.3182M71 25.6364L42.3802 46.3182M29.6198 46.3182L1.91146 66.1506M29.6198 46.3182H42.3802M70.0885 66.1506L42.3802 46.3182'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>,
  'Email',
);
