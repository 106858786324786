import { Stack } from '@mui/material';
import BaseDetailBlock from 'components/base/DetailBlock';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullName, getPhoneNumber } from 'utils/format/format';
const AgentClientDetails = ({ profile }) => {
  const { t } = useTranslation();

  const { detailBlocks } = useMemo(() => {
    const { birth, documents, gender, addresses, contacts } = profile ?? {};
    const fullName = getFullName(profile ?? {});
    const { docNo, docSeries, issueDate, issueUnit, issuedBy } = documents?.find((doc) => doc.type === 'passport').data ?? {};
    const forInsuranceAddress = addresses?.find((address) => address.type === 'forInsurance') ?? {};
    const registrationAddress = addresses?.find((address) => address.type === 'registration') ?? {};
    const email = contacts?.find((contact) => contact.type === 'email') ?? {};
    const phone = contacts?.find((contact) => contact.type === 'mobilePhone') ?? {};
    const detailBlocks = [
      {
        label: t('interface:base.AgentClientDetails.name'),
        dataTestId: 'AgentClientDetails.name',
        text: fullName,
      },
      {
        label: t('interface:base.AgentClientDetails.birthDate'),
        dataTestId: 'AgentClientDetails.birthDate',
        text: birth?.date ? birth.date : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.birthPlace'),
        dataTestId: 'AgentClientDetails.birthPlace',
        text: birth?.place ? birth.place : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.gender.label'),
        dataTestId: 'AgentClientDetails.gender.label',
        text: gender ? t(`interface:base.AgentClientDetails.gender.${gender}`) : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.passportSeriesAndNumber'),
        dataTestId: 'AgentClientDetails.passportSeriesAndNumber',
        text: docSeries || docNo ? [docSeries, docNo].join(' ') : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.issueDate'),
        dataTestId: 'AgentClientDetails.issueDate',
        text: issueDate ? issueDate : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.issueUnit'),
        dataTestId: 'AgentClientDetails.issuedUnit',
        text: issueUnit ? issueUnit : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.issuedBy'),
        dataTestId: 'AgentClientDetails.issuedBy',
        text: issuedBy ? issuedBy : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.address.forInsurance.full'),
        dataTestId: 'AgentClientDetails.address.forInsurance.full',
        text: forInsuranceAddress?.fullAddress ? forInsuranceAddress.fullAddress : '-',
        'data-testid': 'fullAdress',
      },
      {
        label: t('interface:base.AgentClientDetails.address.forInsurance.floor'),
        dataTestId: 'AgentClientDetails.address.forInsurance.floor',
        text: forInsuranceAddress?.floor ? forInsuranceAddress.floor : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.address.forInsurance.size'),
        dataTestId: 'AgentClientDetails.address.forInsurance.size',
        text: forInsuranceAddress?.totalAreaSize ? forInsuranceAddress.totalAreaSize : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.address.forInsurance.houseBuildYear'),
        dataTestId: 'AgentClientDetails.address.forInsurance.houseBuildYear',
        text: forInsuranceAddress?.houseBuildYear ? forInsuranceAddress.houseBuildYear : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.phone'),
        dataTestId: 'AgentClientDetails.phone',
        text: phone.value ? getPhoneNumber(phone.value) : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.email'),
        dataTestId: 'AgentClientDetails.email',
        text: email.value ? email.value : '-',
      },
      {
        label: t('interface:base.AgentClientDetails.address.registration.full'),
        dataTestId: 'AgentClientDetails.address.registration.full',
        text: registrationAddress.fullAddress ? registrationAddress.fullAddress : '-',
      },
    ];

    return { detailBlocks };
  }, [profile, t]);

  return (
    <Stack spacing={2}>
      {detailBlocks.map(({ label, text, dataTestId }) => (
        <BaseDetailBlock key={label} label={label} text={text} data-testid={dataTestId} />
      ))}
    </Stack>
  );
};

AgentClientDetails.propTypes = {
  profile: PropTypes.any,
};

export default AgentClientDetails;
