import commonEN from 'assets/locales/en/common.json';
import errorsEN from 'assets/locales/en/errors.json';
import helmetEN from 'assets/locales/en/helmet.json';
import interfaceEN from 'assets/locales/en/interface.json';
import commonRU from 'assets/locales/ru/common.json';
import errorsRU from 'assets/locales/ru/errors.json';
import helmetRU from 'assets/locales/ru/helmet.json';
import interfaceRU from 'assets/locales/ru/interface.json';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getApiUrl, is, isDev } from 'utils/env/env';

const { REACT_APP_DEBUG_I18N: DEBUG_I18N } = import.meta.env;
const BASE_URL = getApiUrl();

const localResources = {
  en: {
    interface: interfaceEN,
    errors: errorsEN,
    helmet: helmetEN,
    common: commonEN,
  },
  ru: {
    interface: interfaceRU,
    errors: errorsRU,
    helmet: helmetRU,
    common: commonRU,
  },
} as const;

export const configureI18n = (language: string) => {
  //@ts-ignore
  i18n
    .use(detector)
    .use(ChainedBackend)
    .init({
      debug: isDev() && is(DEBUG_I18N),

      backend: {
        backends: [resourcesToBackend(localResources), HttpBackend],
        backendOptions: [
          {},
          {
            loadPath: `${BASE_URL}/i18n/{{lng}}/form/{{ns}}`,
            allowMultiLoading: true,
          },
        ],
      },

      fallbackLng: 'en',
      ns: ['interface', 'errors', 'common', 'helmet', 'profile', 'object', 'agent-insurance', 'insurance-mortgage'],
      defaultNS: 'interface',
      lng: language,

      interpolation: {
        escapeValue: false, // not needed for react!!
        defaultVariables: {
          value: {
            currency: 'RUB',
          },
        },
      },

      // react i18next special options
      react: {
        useSuspense: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },

      initImmediate: true,
    });

  return i18n;
};
