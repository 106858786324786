import { ObjectType } from 'utils/iots';

import { req, UserRequestOptions } from '../http';

import {
  EClientType,
  EEntryAgentClientStageStatusFilter,
  GetClientsMortgageRequestArgs,
  IGreetingSmsRecipientDataDTO,
  IMortgageEntryAgentClientQueryDTO,
  IRegisterClientAsAgent,
  TMortgageEntryCLientListResultDTO,
} from './agent.dto';
import { IPublicUser, IUserEntity } from './user.dto';
import queryString from 'query-string';

export const getInsurancePackage = (uuid: string) => {
  // Request
  return req(`insurance/property/${uuid}`, {
    method: 'GET',
  });
};

export const getInsurancePackages = (filterCompaines = [], filterPerils = [], sort = 'price:asc', page = 1, size = 50) => {
  // Make filter params
  const query = new URLSearchParams();
  filterCompaines.forEach((item) => query.append('filterCompany[]', item));
  filterPerils.forEach((item) => query.append('filterPerils[]', item));
  query.append('sort', sort);
  query.append('page', String(page));
  query.append('size', String(size));

  // Request
  return req(`insurance/property?${query.toString()}`, {
    method: 'GET',
  });
};

export const getClients = (filterTags = [], sort = 'createdAt:desc', page = 1, size = 50) => {
  // Make filter params
  const query = new URLSearchParams();
  filterTags.forEach((item) => query.append('filterTags[]', item));
  query.append('sort', sort);
  query.append('clientType', EClientType.insurance);
  query.append('page', String(page));
  query.append('size', String(size));

  // Request
  return req<null, ObjectType>(`profile?${query.toString()}`, {
    method: 'GET',
  });
};

export const getProfileClientById = (id: string | number) => {
  return req(`profile/client/${id}`, { method: 'GET' });
};

export const getPolicies = (filterCompany = [], filterStatus = [], sort = 'createdAt:desc', page = 1, size = 50) => {
  const query = new URLSearchParams();

  filterCompany.forEach((item) => query.append('filterCompany[]', item));
  filterStatus.forEach((item) => query.append('filterStatus[]', item));
  query.append('sort', sort);
  query.append('page', String(page));
  query.append('size', String(size));
  // Request
  return req(`insurance/order?${query.toString()}`, { method: 'GET' });
};

export const getPolicy = (uuid: string) => {
  return req(`insurance/order/${uuid}`, { method: 'GET' });
};

export const getInsuranceOrderStats = () => {
  return req('insurance/order/stats', { method: 'GET' });
};

export const makeInsurancePropertyOrder = (uuidPackage: string, uuidClient: string) => {
  return req('insurance/order', {
    method: 'POST',
    body: {
      uuidInsurancePropertyPackage: uuidPackage,
      uuidProfile: uuidClient,
    },
  });
};

export const deleteIncuranceOrderById = (id: string | number) => {
  return req(`insurance/order/${id}`, { method: 'DELETE' });
};

export const deleteAgentClientById = (id: string | number) => {
  return req(`agent/client/${id}`, { method: 'DELETE' });
};

export const getClientsMortgage = (
  { filterTags = [], sort = 'createdAt:desc', page = 1, size = 50 }: Partial<GetClientsMortgageRequestArgs>,
  options?: UserRequestOptions,
) => {
  const query = new URLSearchParams();
  filterTags.forEach((item) => query.append('filterTags[]', item));
  query.append('sort', sort);
  query.append('clientType', EClientType.referral);
  query.append('page', String(page));
  query.append('size', String(size));
  query.append('calcProgress', String(true));

  // Request
  return req<null, { results: ObjectType[]; settings: { size: number; page: number; count: number } }>(`profile?${query.toString()}`, {
    method: 'GET',
    ...options,
  });
};

export const inviteFromAgent = (role: string) =>
  req<{ role: string }, Record<string, string>>('agent/mortgage/referral/link', { method: 'POST', body: { role } });

export const getUuidEntry = (uuidClient: string, options?: UserRequestOptions) => {
  return req<null, { uuidEntry: string }>(`profile/client/${uuidClient}/uuidEntry`, {
    method: 'GET',
    ...options,
  });
};

/**
 * Create user & profile (attached to agent) for client
 * @param payload
 * @returns
 */
export function postClientCreate(payload: IRegisterClientAsAgent) {
  return req<IRegisterClientAsAgent, IUserEntity>(`agent/client`, {
    method: 'POST',
    body: payload,
  });
}

/**
 * request to send an invitation link to the client via sms from the agent
 * @param data
 * @returns
 */
export default function sendSmsGreeting(data: IGreetingSmsRecipientDataDTO) {
  return req<IGreetingSmsRecipientDataDTO, {}>('agent/client/sms/greeting', { method: 'POST', body: data });
}

/**
 * get client list for manager or agent
 * {@link https://gate.ip.mindcode.ru//documentation/static/index.html#/user-entry/getAllClientsAgent}
 * @param qp
 * @returns {TMortgageEntryCLientListResultDTO} result of clients
 */
export function getAllClientsAgent(qp: IMortgageEntryAgentClientQueryDTO, signal: AbortSignal) {
  return req<null, TMortgageEntryCLientListResultDTO>(`businessCredit/agentClients?${queryString.stringify(qp)}`, {
    method: 'GET',
    signal,
  });
}

export function getClientAgentFilters(signal?: AbortSignal) {
  return req<null, Record<EEntryAgentClientStageStatusFilter, number>>(`businessCredit/agentClients/filter`, { method: 'GET', signal });
}

export function getManagersList() {
  return req<any, IPublicUser[]>(`manager/list`, { method: 'GET' });
}

export function changeClientManager(uuidPersonalManager: string, uuidUser: string) {
  return req<unknown, unknown>(`manager/${uuidPersonalManager}/user/${uuidUser}`, { method: 'PUT' });
}
