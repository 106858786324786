import { RawCreateParams, z } from 'zod';

export const DefaultValidation = {
  required_error: 'errors:validation.required',
  invalid_type_error: 'errors:validation.required',
};

export const NumberSchema = z.number({ required_error: 'errors:validation.required', invalid_type_error: 'errors:validation.required' });

export const NaviteEnumValidation: RawCreateParams = {
  errorMap: (issue, _ctx) => {
    switch (issue.code) {
      case 'invalid_type':
        return { message: 'errors:validation.required' };
      case 'invalid_enum_value':
        return { message: 'errors:validation.required' };
      default:
        return { message: 'errors:validation.required' };
    }
  },
};
