import { Button, SvgIcon, SxProps, useTheme } from '@mui/material';
import ArrowRightIcon from 'components/Icons/ArrowRight'; // TODO: Remove
import React from 'react';

interface IProps {
  sx?: SxProps;
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  value: string;
}

const BaseButtonListItem = ({ children, sx = {}, onClick, value }: IProps) => {
  const theme = useTheme();
  // TODO: Take colors & sizes from theme
  return (
    <Button
      sx={{
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: 0,
        justifyContent: 'space-between',
        ...sx,
      }}
      value={value}
      endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default BaseButtonListItem;
