import { PaletteMode, ThemeOptions } from '@mui/material';

import accordion from './overrides/accordion';
import appbar from './overrides/appbar';
import buttons from './overrides/buttons';
import checkbox from './overrides/checkbox';
import inputs from './overrides/inputs';
import link from './overrides/link';
import paletteDark from './paletteDark';
import paletteLigth from './paletteLigth';
import typography from './typography';

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: mode === 'light' ? paletteLigth : paletteDark,
  typography,
  components: {
    ...appbar,
    ...buttons,
    ...inputs,
    ...checkbox,
    ...accordion,
    ...link,
    // TODO: Move to overrides
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderRadius: '8px 8px 0px 0px',
        },
      },
    },
  },
});

export default getDesignTokens;
