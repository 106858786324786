import { SvgIconComponent } from '@mui/icons-material';
import { Button, Chip, Divider, Paper, SvgIcon, Typography, useTheme } from '@mui/material';
import { Box, Container } from '@mui/system';
import InsuranceAlfaIcon from 'components/Icons/InsuranceAlfa';
import InsuranceIngosIcon from 'components/Icons/InsuranceIngos';
import InsuranceResoIcon from 'components/Icons/InsuranceReso';
import BaseLinkRouter from 'components/base/Link/Router';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_AGENT_INSURANCE_PROPERTY_POLICY } from 'routes/paths';
import utils from 'utils';
import { getFullName } from 'utils/format/format';

interface PolicyTag {
  color: string;
  title: string;
}

interface PolicyTags {
  failing: PolicyTag;
  processing: PolicyTag;
  paid: PolicyTag;
  [key: string]: PolicyTag;
}

interface PolicyCompany {
  companyName: string;
  icon: SvgIconComponent;
}

interface PoliciesCompanies {
  resoGarant: PolicyCompany;
  ingosstrah: PolicyCompany;
  alfastrah: PolicyCompany;
  [key: string]: PolicyCompany;
}

// TODO: Stupid son of bitch, need to move this russians labels to i18n
const mapPoliciesTags: PolicyTags = {
  failing: {
    color: '#0FC2EB',
    title: 'заполнение данных',
  },
  processing: {
    color: '#F4A611',
    title: 'ожидание оплаты',
  },
  paid: {
    color: '#31C918',
    title: 'полис оплачен',
  },
};

const mapPoliciesCompany: PoliciesCompanies = {
  resoGarant: {
    companyName: 'РЕСО-Гарантия',
    icon: InsuranceResoIcon,
  },
  ingosstrah: {
    companyName: 'Ингосстрах',
    icon: InsuranceIngosIcon,
  },
  alfastrah: {
    companyName: 'АльфаСтрахование',
    icon: InsuranceAlfaIcon,
  },
};

interface IProps {
  uuid: string;
  policyNumber: number;
  address: string;
  price: number;
  lastname: string;
  firstname: string;
  patronymic: string;
  company: string;
  status: string;
}

const AgentInsurancePropertyPolicyListItem = (
  { uuid, policyNumber, address, price, lastname, firstname, patronymic, company, status }: IProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { companyName, icon } = mapPoliciesCompany[company];
  const fullName = getFullName({ lastname, firstname, patronymic });
  const { color, title } = mapPoliciesTags[status];

  return (
    <Paper
      className='agent-insurance-propery-policy-list-item'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        minHeight: '100px',
        padding: '24px',
        borderRadius: '16px',
      }}
      variant='outlined'
      ref={ref}
    >
      <Box sx={{ display: 'flex', marginBottom: '12px', overflow: 'auto', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box key={title}>
          <Chip
            key={title}
            label={title}
            variant={'outlined'}
            sx={{
              color: theme.palette.common.white,
              backgroundColor: color,
              border: 'none',
              fontWeight: 400,
              fontSize: '14px',
            }}
          />
        </Box>

        {!!policyNumber && (
          <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
            {policyNumber}
          </Typography>
        )}
      </Box>

      <Box>
        <Typography variant='h4' sx={{ marginBottom: '4px' }}>
          {fullName}
        </Typography>

        <Typography variant='h6' sx={{ color: theme.palette.text.secondary, marginBottom: '16px' }}>
          {address}
        </Typography>

        <Divider sx={{ marginTop: '16px' }} />

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '8px' }}>
            <SvgIcon component={icon} sx={{ width: '28px', height: '28px' }} />

            <Typography variant='h5'>{companyName}</Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={'h5m'}>{utils.format.price(price / 100)}</Typography>
          </Box>
        </Box>

        <Container sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button
            component={BaseLinkRouter}
            to={`${PATH_AGENT_INSURANCE_PROPERTY_POLICY}/${uuid}`}
            variant={'text'}
            sx={{ color: theme.palette.common.link }}
          >
            {t('interface:page.AgentInsurancePropertyPolicyListPage.List.btnDetails')}
          </Button>
        </Container>
      </Box>
    </Paper>
  );
};

export default forwardRef(AgentInsurancePropertyPolicyListItem);
