import { Children, cloneElement, ReactChild, ReactElement, useRef, useState } from 'react';
import { Coordinates } from 'types/draggable/index';
import { getPositionFromEvent, preventTouchScrolling, releaseTouchScrolling } from 'utils/draggable';

interface IProps {
  children?: ReactChild;
  onDragStart?: (arg: Coordinates) => void;
  onDrag?: (arg: Coordinates) => void;
  onDragStop?: (arg?: Coordinates) => void;
}

const BaseDraggable = ({ children, onDragStart, onDrag, onDragStop }: IProps) => {
  const lastPos = useRef<Coordinates>({ x: 0, y: 0 });

  const [isStart, setIsStart] = useState<boolean>(false);
  const [isDraggingDisabled, setIsDraggingDisabled] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragStart = (event: TouchEvent & MouseEvent) => {
    const position = getPositionFromEvent(event);
    lastPos.current = position;

    if (onDragStart) {
      onDragStart(position);
    }

    setIsStart(true);
    setIsDragging(true);
  };

  const handleDrag = (event: TouchEvent & MouseEvent) => {
    if (isDraggingDisabled || !isDragging) {
      return;
    }

    const pos = getPositionFromEvent(event);
    pos.deltaX = pos.x - lastPos.current.x;
    pos.deltaY = pos.y - lastPos.current.y;

    if (isStart) {
      if (Math.abs(pos.deltaY) >= Math.abs(pos.deltaX)) {
        setIsDraggingDisabled(true);
        return;
      }

      preventTouchScrolling();

      setIsStart(false);
    }

    if (onDrag) {
      onDrag(pos);
    }

    lastPos.current = pos;
  };

  const handleDragStop = () => {
    releaseTouchScrolling();

    if (isDraggingDisabled) {
      setIsDraggingDisabled(false);
    }

    setIsDragging(false);

    if (onDragStop) {
      onDragStop();
    }
  };

  return cloneElement(Children.only(children) as ReactElement, {
    className: 'base-draggable',
    onMouseDown: handleDragStart,
    onMouseMove: handleDrag,
    onMouseUp: handleDragStop,
    onMouseLeave: handleDragStop,

    onTouchStart: handleDragStart,
    onTouchMove: handleDrag,
    onTouchEnd: handleDragStop,
  });
};

export default BaseDraggable;
