import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box, Button, IconButton, SxProps, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import { createRef, CSSProperties, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ReactSignatureCanvas from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';
import { ObjectType } from 'utils/iots';

import signAnim from './signLottie.json';

type Props = {
  lables?: {
    title?: string;
    btnSign?: string;
  };
  handleSignBtnClick: (data: ObjectType) => void;
  sx?: SxProps;
  styleLottie?: CSSProperties;
};

const DrawSign = ({ handleSignBtnClick, lables, sx, styleLottie }: Props) => {
  const sigPad = createRef<ReactSignatureCanvas>();
  const { t } = useTranslation();

  const theme = useTheme();
  const [showAnim, setShowAnim] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);

  const hideAnimation = (): void => {
    setShowAnim(false);
  };

  const handleCanvasClick = () => {
    setIsReady(true);
  };

  const clear = (): void => {
    sigPad.current?.clear();

    setIsReady(false);
  };

  const handleSaveSignature = () => {
    if (!!sigPad.current) {
      const data = sigPad.current.toDataURL();
      handleSignBtnClick(data);
    }
  };

  const [isPortait, setPortait] = useState(true);

  const heightMobile = isPortait ? window.innerHeight / 1.3 : window.innerHeight;
  const widthMobile = isPortait ? window.innerWidth / 0.95 : window.innerWidth;

  const fullScreenSx = {
    top: '10px',
    left: 0,
    width: 1,
    height: 1,
    position: 'absolute',
    background: '#fff',
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAnim(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        overflow: 'hidden',
        ...(!isPortait ? fullScreenSx : {}),
      }}
    >
      {lables?.title && isPortait && (
        <Typography variant='h3b' sx={{ fontSize: { lg: '26px', xs: '18px' } }}>
          {t(lables.title)}
        </Typography>
      )}

      <Box
        sx={{
          width: 1,
          height: 1,
          position: 'relative',
          borderRadius: '16px',
          backgroundColor: theme.palette.secondary.main,
          ...(sx || {}),
        }}
      >
        {!isDesktop && (
          <Box sx={{ position: 'absolute', top: 5, right: 5, zIndex: 9999 }}>
            <IconButton
              sx={{ background: '#fff !important' }}
              onClick={() => {
                setPortait(!isPortait);
              }}
            >
              <FullscreenIcon fontSize='large' />
            </IconButton>
          </Box>
        )}

        {showAnim && (
          <Lottie
            style={{
              width: `${!isDesktop ? widthMobile : window.innerWidth - 120}px`,
              height: `${!isDesktop ? heightMobile : window.innerHeight / 2}px`,
              ...(styleLottie || {}),
            }}
            onClick={hideAnimation}
            animationData={signAnim}
          />
        )}
        {!showAnim && (
          <SignatureCanvas
            ref={sigPad}
            penColor='black'
            canvasProps={{
              onTouchEnd: handleCanvasClick,
              onMouseUp: handleCanvasClick,
              // height: !isDesktop ? window.innerHeight / 2 : window.innerHeight / 2,
              // width: !isDesktop ? window.innerWidth : window.innerWidth - CANVAS_OFFSET,
              width: `${!isDesktop ? widthMobile : window.innerWidth - 120}px`,
              height: `${!isDesktop ? heightMobile : window.innerHeight / 2}px`,
              className: 'sigCanvas',
              style: {
                userSelect: 'none',
              },
            }}
          />
        )}
        {showAnim && (
          <Typography color='text.secondary' sx={{ paddingBottom: '20px' }}>
            {t('interface:base.DrawSign.description')}
          </Typography>
        )}
        {!showAnim && !isPortait && (
          <Button
            disabled={!isReady}
            sx={{ position: 'absolute', left: '16px', bottom: '16px' }}
            onClick={handleSaveSignature}
            variant='contained'
            color='primary'
            size='small'
          >
            {t(lables?.btnSign as string, { defaultValue: '' })}
          </Button>
        )}

        {!showAnim && (
          <Button
            sx={{ position: 'absolute', right: '16px', bottom: '16px' }}
            onClick={clear}
            variant='contained'
            size='small'
            color='error'
          >
            {t('interface:base.DrawSign.btnClear')}
          </Button>
        )}
      </Box>

      {/* Controls */}
      {lables?.btnSign && (
        <Button disabled={!isReady} sx={{ marginBottom: '20px' }} onClick={handleSaveSignature} fullWidth variant='contained' size='large'>
          {t(lables?.btnSign as string, { defaultValue: '' })}
        </Button>
      )}
    </Box>
  );
};

export default DrawSign;
