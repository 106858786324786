import { EUserRole } from 'api/services/user.dto';
import { useAuth } from 'components/providers/AuthProvider';
import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';

interface RequireAuthProps {
  children?: React.ReactNode;
  component: ReactElement;
  needAuth?: boolean;
  role?: EUserRole;
}

/**
 * a component used to protect internal paths to check the level of user access and authorization roles
 */
export const RequireAuth = ({ children, component, needAuth = true }: RequireAuthProps) => {
  const auth = useAuth();
  const location = useLocation();

  // Case: User must be authorized
  if (needAuth) {
    if (!auth.isAuth) {
      // Redirect them to the /auth/signin page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to={PATH_HOME} state={{ from: location }} replace />;
    }
    // Case: User must choice group
    if (auth?.user?.roles.includes(EUserRole.ghost)) {
      return <Navigate to={PATH_HOME} replace />;
    }
    // // Case: User must have special group
    // if (group && auth?.user?.group !== group) {
    //   return <Navigate to={PATH_HOME} replace />;
    // }
  }

  // Case: User must be unauthorized
  if (!needAuth && auth.isAuth) {
    return <Navigate to={PATH_HOME} replace />;
  }
  return component || children;
};
