import { Button, Stack, SxProps, Typography } from '@mui/material';
import SadEmojiIcon from 'components/Icons/SadEmoji';
import { ReactText } from 'react';

interface IProps {
  title: ReactText;
  containerStyle?: object;
  button?: {
    text: string;
    props?: Record<string, object>;
  };
  customIcon?: React.ReactChild;
}

const BaseListEmpty = ({ title, containerStyle, button, customIcon }: IProps) => {
  return (
    <Stack direction='column' justifyContent='center' alignItems='center' sx={containerStyle}>
      {customIcon ? customIcon : <SadEmojiIcon sx={{ width: '128px', height: '128px', mb: 3 }} />}

      <Typography align='center' variant='h2m' sx={{ mb: 5, whiteSpace: 'pre-line' }}>
        {title}
      </Typography>

      {button && (
        <Button variant='contained' size='large' fullWidth {...button.props} sx={{ maxWidth: '272px', ...(button.props?.sx as SxProps) }}>
          {button.text}
        </Button>
      )}
    </Stack>
  );
};

export default BaseListEmpty;
