import { EMortgageEntryRole, IMortgageEntryEntity } from 'api/services/mortgage.dto';
import { createContext, Fragment, ReactNode, useContext, useMemo } from 'react';

import { useAuth } from '../AuthProvider';
import { useMortgage } from '../MortageEntryProvider';

import { AccessProtect, AccessProtection, ProtectableProps } from './types';

export const MortgageAccessContext = createContext<Partial<AccessProtection<EMortgageEntryRole>>>({});

export default function MortgageAccessProvider({
  children,
  protectedRoles,
  entry,
}: {
  children: ReactNode | JSX.Element;
  protectedRoles: EMortgageEntryRole[];
  entry?: IMortgageEntryEntity;
}) {
  const auth = useAuth();

  const mortgage = useMortgage();

  const validateAccess = (roles?: EMortgageEntryRole[]) => {
    if (!roles && !protectedRoles) {
      return false;
    }
    const entryUser = (entry || mortgage.entry)?.users?.find((x) => x.uuidUser === auth?.user?.uuid);
    if (!entryUser) {
      return false;
    }

    return protectedRoles.includes(entryUser?.role);
  };

  const hasAccess = useMemo(() => {
    return validateAccess(protectedRoles);
  }, [entry, auth, protectedRoles, mortgage.entry]);

  const protect: AccessProtect<EMortgageEntryRole> = (element, { optionalRoles }) => {
    return <Fragment>{validateAccess(optionalRoles) && element}</Fragment>;
  };

  return <MortgageAccessContext.Provider value={{ hasAccess, protect }}>{children}</MortgageAccessContext.Provider>;
}

export function Protectable(props: ProtectableProps<EMortgageEntryRole>) {
  return (
    <MortgageAccessContext.Consumer>
      {(context) => {
        if (props?.customRule) {
          return props.customRule ? props.children : null;
        }
        if (!context) {
          throw new Error(`${MortgageAccessProvider.name}: has not provided`);
        }

        if (!context.hasAccess) {
          return null;
        }
        return props.children;
      }}
    </MortgageAccessContext.Consumer>
  );
}

export function useMortgageAccessControl() {
  const ctx = useContext(MortgageAccessContext);
  if (!ctx) {
    throw new Error(`${MortgageAccessProvider.name}: has not provided`);
  }
  return ctx;
}
