import { useQuery } from '@tanstack/react-query';
import { req } from 'api/http';
import { RealEstateEntity } from './realEstate.interface';

/**
 * get real estate object by uuidEntry
 * @param uuidEntry
 */
export async function getRealEstate({ uuidEntry }: { uuidEntry?: string }) {
  return req<null, RealEstateEntity>(`mortgageEntry/${uuidEntry}/realEstate`, { method: 'GET' });
}

export function putRealEstateVerification({ uuidEntry }: { uuidEntry: string }) {
  return req(`mortgageEntry/${uuidEntry}/realEstate/verification`, { method: 'PUT' });
}

export function postRealEstate({ uuidEntry, formValues }: { uuidEntry: string; formValues: RealEstateEntity }) {
  return req(`mortgageEntry/${uuidEntry}/realEstate`, { method: 'POST', body: formValues });
}

export function putRealEstate({ uuidEntry, formValues }: { uuidEntry: string; formValues: RealEstateEntity }) {
  return req(`mortgageEntry/${uuidEntry}/realEstate`, { method: 'PUT', body: formValues });
}

export function useGetRealEstateForm(uuidEntry?: string) {
  return useQuery({
    queryKey: ['realEstate', uuidEntry],
    queryFn: async () => getRealEstate({ uuidEntry }),
    enabled: !!uuidEntry,
    retry: false,
  });
}
