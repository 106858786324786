import { suggestFullCompany, suggestSearchCompany } from 'api/services/suggestions';
import { SuggestionCompanyResponse } from 'api/services/suggestions.dto';
import DadataSuggestion from 'components/base/DadataSuggestion';
import _ from 'lodash';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

import { FormSchemeData } from '../../form.types';
import { ft } from '../../utils';

interface Props {
  node: FormSchemeData;
  path?: string[];
  hookForm: UseFormReturn<FieldValues, ObjectType>;
}

export default function SuggestionField({ node, hookForm, path = [] }: Props) {
  const { t } = useTranslation();
  const { control, watch, setValue } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  const suggestionCompany = async (data: SuggestionCompanyResponse) => {
    if (!data || !data.inn) {
      return;
    }
    try {
      const res = await suggestFullCompany(String(data.inn));
      const p = path.slice(0, path.length - 1).join('.');
      if (res.ok) {
        const { inn, phone, name, employeeCount, creationDate } = res.body;
        setValue(`${p}.inn`, inn);
        if (phone) setValue(`${p}.phone`, String(phone));
        if (creationDate) setValue(`${p}.companyRegDate`, utils.date.dateByFormat(creationDate, 'DD.MM.YYYY'));
        setValue(`${p}.companyName`, name);
        if (employeeCount) setValue(`${p}.companyEmployeeCount`, String(employeeCount));
      }
    } catch (err) {
      //todo
      console.log(err);
    }
  };
  const handleSelect = (selectedValue: SuggestionCompanyResponse) => {
    suggestionCompany(selectedValue);
  };

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={({ fieldState, field }) => (
        <DadataSuggestion
          variant='standard'
          onInputChange={field.onChange}
          onQuery={suggestSearchCompany}
          path={['name']}
          error={!!fieldState.error}
          helperText={fieldState?.error?.message}
          id={path.join('.')}
          data-testid={path.join('.')}
          label={t(`${ft(path)}.title`)}
          onSelect={handleSelect}
          value={field?.value}
        />
      )}
    />
  );
}
