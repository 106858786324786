import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { HTTP_AUTH_SECRET } from 'api/http';
import { IRegisterUser } from 'api/services/auth.dto';
import CloseIcon from 'components/Icons/Close';
import ErrorMessage from 'components/base/ErrorMessage';
import CommonCheckCode from 'components/common/CheckCode/CheckCode';
import { RegisterLogic } from 'hooks/logic/useRegisterLogic';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH_ROLE } from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const {
  REACT_APP_DOC_SGNUP_PRTPOPDOU,
  REACT_APP_DOC_SGNUP_CTTPOPD,
  REACT_APP_DOC_SGNUP_TOU,
  REACT_APP_DOC_SGNUP_CTRN,
  REACT_APP_DOC_SGNUP_AOTEOED,
} = import.meta.env;

const PhoneMaskCustom = (props: ObjectType) => {
  return (
    <NumberFormat
      allowEmptyFormatting
      customInput={TextField}
      {...props}
      onValueChange={(values: ObjectType) => {
        props?.onChange?.({ target: { value: values?.value as string } } as ChangeEvent<HTMLInputElement>);
      }}
      format='+7 (###) ###-##-##'
      mask='_'
    />
  );
};

interface Props extends RegisterLogic {
  children?: React.ReactNode;
}

const AuthRegisterForm = ({ children, ...logic }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();

  const isDesktop = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  const { handleSubmit, control, watch, getValues } = useForm({
    defaultValues: {
      lastname: '',
      phone: '',
      firstname: '',

      termSignupCTTPOPDPRTPOPDOU: true,
      termSignupTOU: true,
      termSignupAOTEOED: true,
      termSignupCTRN: true,

      patronymic: '',
      email: '',
    },
  });

  const [phone, term1, term2, term3] = watch(['phone', 'termSignupCTTPOPDPRTPOPDOU', 'termSignupTOU', 'termSignupAOTEOED']);

  const smsCodeRequest = async () => {
    const clearPhone = utils.format.digitNumber(phone) as number;
    await logic.sendSmsRequest(clearPhone);
  };

  const handleRegister = async (code: string) => {
    const clearPhone = utils.format.digitNumber(phone) as number;
    const values = getValues();

    const body: IRegisterUser = {
      code,
      autoLogin: true,
      ...values,
      phone: clearPhone,
      secret: HTTP_AUTH_SECRET,
    };

    return logic.registerRequest(code, body);
  };

  const handleGoBackClick = () => {
    navigate(-1);
  };
  const onSubmit = () => {
    smsCodeRequest();
  };

  const userAgreedAll = useMemo(() => term1 && term2 && term3, [term1, term2, term3]);

  useEffect(() => {
    if (!userAgreedAll) {
      //todo: add conditions
    } else {
      logic.setError(undefined);
    }
  }, [userAgreedAll]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Header */}
      <Box
        sx={{
          paddingTop: '26px',
          display: { xs: 'none', lg: 'flex' },
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h1b'>{t('interface:page.AuthRegisterPage.Form.title')}</Typography>
        <IconButton sx={{ alignItems: 'flex-start' }} aria-label='Back' onClick={handleGoBackClick}>
          <CloseIcon sx={{ padding: '12px 0', fontSize: 22 }} />
        </IconButton>
      </Box>

      {children && children}

      {/* Form */}
      <Grid container direction='row' spacing={5} sx={{ paddingTop: { xs: 0, lg: '40px' } }}>
        <Grid item xs={12} md={6}>
          <Controller
            name='lastname'
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field, fieldState }) => (
              <TextField
                label={t('interface:page.AuthRegisterPage.Form.profile.lastname')}
                inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.lastname' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name='firstname'
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field, fieldState }) => (
              <TextField
                label={t('interface:page.AuthRegisterPage.Form.profile.firstname')}
                inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.firstname' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name='patronymic'
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label={t('interface:page.AuthRegisterPage.Form.profile.patronymic')}
                inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name='phone'
            control={control}
            rules={{ required: true, minLength: 11 }}
            render={({ field, fieldState }) => (
              <PhoneMaskCustom
                label={t('interface:page.AuthRegisterPage.Form.profile.phoneNumber')}
                inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.phoneNumber' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name='email'
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            render={({ field, fieldState }) => (
              <TextField
                label={t('interface:page.AuthRegisterPage.Form.profile.Email')}
                inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.email' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container direction='row' spacing={0} sx={{ paddingTop: '32px' }}>
        <Grid item xs={12}>
          <Controller
            name='termSignupCTTPOPDPRTPOPDOU'
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
                sx={{ fontSize: '12px', padding: '8px 16px' }}
                checked={field.value}
                disabled={logic.smsInputAllow}
                defaultChecked
                label={
                  <Typography variant='h7' component={'p'}>
                    <Trans i18nKey='interface:page.AuthRegisterPage.Form.agreements.signupCTTPOPDPRTPOPDOU'>
                      <Link
                        variant='h7'
                        color={theme.palette.common.link}
                        underline='none'
                        target={'_blank'}
                        href={REACT_APP_DOC_SGNUP_CTTPOPD}
                      />
                      <Link
                        variant='h7'
                        color={theme.palette.common.link}
                        underline='none'
                        target={'_blank'}
                        href={REACT_APP_DOC_SGNUP_PRTPOPDOU}
                      />
                    </Trans>
                  </Typography>
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='termSignupTOU'
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
                sx={{ fontSize: '12px', padding: '8px 16px' }}
                checked={field.value}
                disabled={logic.smsInputAllow}
                label={
                  <Typography variant='h7' component={'p'}>
                    <Trans i18nKey='interface:page.AuthRegisterPage.Form.agreements.signupTOU'>
                      <Link
                        variant='h7'
                        color={theme.palette.common.link}
                        underline='none'
                        target={'_blank'}
                        href={REACT_APP_DOC_SGNUP_TOU}
                      />
                    </Trans>
                  </Typography>
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='termSignupAOTEOED'
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
                sx={{ fontSize: '12px', padding: '8px 16px' }}
                checked={field.value}
                disabled={logic.smsInputAllow}
                label={
                  <Typography variant='h7' component={'p'}>
                    <Trans i18nKey='interface:page.AuthRegisterPage.Form.agreements.signupAOTEOED'>
                      <Link
                        variant='h7'
                        color={theme.palette.common.link}
                        underline='none'
                        target={'_blank'}
                        href={REACT_APP_DOC_SGNUP_AOTEOED}
                      />
                    </Trans>
                  </Typography>
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='termSignupCTRN'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
                sx={{ fontSize: '12px', padding: '8px 16px' }}
                checked={field.value}
                disabled={logic.smsInputAllow}
                label={
                  <Typography variant='h7' component={'p'}>
                    <Trans i18nKey='interface:page.AuthRegisterPage.Form.agreements.signupCTRN'>
                      <Link
                        variant='h7'
                        color={theme.palette.common.link}
                        underline='none'
                        target={'_blank'}
                        href={REACT_APP_DOC_SGNUP_CTRN}
                      />
                    </Trans>
                  </Typography>
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Submit */}
      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          padding: '29px 0',
        }}
      >
        {!logic.smsInputAllow ? (
          <Button
            disabled={!userAgreedAll || logic.loading}
            type='submit'
            variant='contained'
            size={isDesktop ? 'superlarge' : 'large'}
            data-testid='registerBtn2'
          >
            {logic.loading ? <CircularProgress size={24} /> : t('interface:page.AuthRegisterPage.Form.registerBtn')}
          </Button>
        ) : (
          <CommonCheckCode resendCodeRequest={smsCodeRequest} checkCodeRequest={handleRegister} pathAfterCheck={PATH_AUTH_ROLE} />
        )}
        <ErrorMessage error={logic.errors} />
      </Box>
    </form>
  );
};

export default AuthRegisterForm;
