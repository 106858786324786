/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material/utils';

const InsuranceResoIcon = createSvgIcon(
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M48 32.3501L47.6842 29.4092C47.0526 23.8425 45.2632 19.221 42.421 15.7549C39.8947 12.4989 36.5263 10.1882 32.4211 8.50766V0L29.4737 0.315098C23.8947 0.945295 19.2632 2.73085 15.7895 5.56674C12.5263 8.08753 10.2105 11.4486 8.52631 15.5449H0L0.315789 18.4858C0.947368 24.0525 2.73684 28.674 5.57895 32.2451C8.10526 35.5011 11.4737 37.8118 15.5789 39.4923V48L18.5263 47.6849C24.1053 47.0547 28.7368 45.2691 32.2105 42.4333C35.4737 39.9125 37.7895 36.5514 39.4737 32.4551H48V32.3501ZM28.9474 38.1269C26.7368 39.8074 24.1053 41.0678 20.8421 41.8031V24.3676H15.5789V33.7155C13.2632 32.5602 11.2632 30.9847 9.78947 28.9891C8.10526 26.7834 6.8421 24.1576 6.10526 20.9015H23.6842V15.6499H14.3158C15.4737 13.3392 17.0526 11.3435 19.0526 9.87309C21.2632 8.19256 23.8947 6.93217 27.1579 6.19694V23.9475H32.4211V14.3895C34.7368 15.5449 36.7368 17.1204 38.2105 19.116C39.8947 21.3217 41.1579 23.9475 41.8947 27.2035H24.5263V32.4551H33.6842C32.5263 34.6608 30.9474 36.6565 28.9474 38.1269Z'
      fill='#009639'
    />
  </svg>,
  'InsuranceResoIcon',
);

export default InsuranceResoIcon;
