import { ApiError } from 'api/errors';
import { postFinalProposeDecision, updateProposeDecision } from 'api/services/manager/manager.service';
import { IMortgageEntryPropose } from 'api/services/mortgage.dto';
import { useMortgage } from 'components/providers/MortageEntryProvider';
import { createContext, useContext, useMemo, useState } from 'react';

interface ProposeContextProps {
  updateProposeParams(proposeFormData: Partial<IMortgageEntryPropose>): Promise<void>;
  editPropose(uuidPropose?: string): void;
  editablePropose: string;
  managerUpdateDecision(data: { uuidPropose: string; propose: IMortgageEntryPropose }): Promise<void>;
}

export const ProposeContext = createContext({});

export default function ProposeProvider({ children }: { children: React.ReactChild }) {
  const [, setError] = useState<ApiError>();
  const [editablePropose, setEditPropose] = useState<string>();

  const mortgage = useMortgage();
  /**
   * link to update propose params
   */
  const updateProposeParams = async (proposeFormData: Partial<IMortgageEntryPropose>) => {
    if (!editablePropose) {
      return;
    }
    try {
      await updateProposeDecision(editablePropose, proposeFormData);
      await mortgage.getEntryRequest?.();
    } catch (err) {
      setError(err as ApiError);
    }
  };

  /**
   * select propose by uuid to mount editable form
   * @param uuidPropose uuid of propose
   */
  const editPropose = (uuidPropose: string | undefined) => {
    setEditPropose(uuidPropose);
  };
  /**
   * manager approve update proposeDecision to final stage
   * @param
   * @returns
   */
  const managerUpdateDecision = async ({ uuidPropose, propose }: { uuidPropose: string; propose: IMortgageEntryPropose }) => {
    if (!propose.decision) {
      return;
    }
    try {
      await postFinalProposeDecision(mortgage.uuid as string, uuidPropose, propose.decision);
      await mortgage.getEntryRequest?.();
    } catch (err) {}
  };

  const storeValue = useMemo(
    () => ({
      editPropose,
      editablePropose,
      updateProposeParams,
      managerUpdateDecision,
    }),
    [editablePropose],
  );

  return <ProposeContext.Provider value={storeValue}>{children}</ProposeContext.Provider>;
}

export function usePropose() {
  const ctx = useContext(ProposeContext) as ProposeContextProps;
  if (!ctx) {
    throw new Error('ProposeProvider not initialized at parent level');
  }
  return ctx;
}
