/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const DownloadIcon = createSvgIcon(
  <svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.18182 7.36364L9 11.1818M9 11.1818L12.8182 7.36364M9 11.1818V1M17 11.1818V16.2727C17 16.4656 16.9234 16.6506 16.787 16.787C16.6506 16.9234 16.4656 17 16.2727 17H1.72727C1.53439 17 1.3494 16.9234 1.21301 16.787C1.07662 16.6506 1 16.4656 1 16.2727V11.1818'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'DownloadIcon',
);

export default DownloadIcon;
