import { Chip, CircularProgress } from '@mui/material';

import _ from 'lodash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { EDocRecognitionStatus, EProfileDocumentType, ERequiredPassportParts } from 'api/services/experiment/experiment.dto';
import { EBusinessCreditEntryDocumentType } from 'api/services/businessCredit/businessCredit.enum';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
export default function FileChipInfo({
  type,
  part,
  loading,
  status,
}: {
  status: EDocRecognitionStatus;
  type: EProfileDocumentType | EBusinessCreditEntryDocumentType | 'other';
  part?: ERequiredPassportParts | string;
  loading?: boolean;
}) {
  const { t } = useTranslation();
  if (loading) {
    return null;
  }

  if (status === EDocRecognitionStatus.awaiting) {
    return <Chip icon={<CircularProgress size='20px' />} label={t(`common:documents.processing.${status}`)} variant='outlined' />;
  }

  if (type === EProfileDocumentType.passport && part === ERequiredPassportParts.unknown) {
    return <Chip icon={<HelpIcon color='warning' />} label='Выберите страницу паспорта' color='warning' variant='outlined' />;
  }

  return (
    <>
      {type === EProfileDocumentType.unknown ? (
        <Fragment>
          <Chip icon={<HelpIcon color='warning' />} label='Выберите тип файла' color='error' variant='outlined' />
        </Fragment>
      ) : (
        <Chip icon={<CheckCircleIcon color='success' />} label='Загружено' color='success' variant='outlined' />
      )}
    </>
  );
}
