import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Fade, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup } from '@mui/material';
import BottomPopover from 'components/base/BottomPopover';
import { FC, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { Controller } from 'react-hook-form';
import { ObjectType } from 'utils/iots';

interface RadioWrapperOptions {
  label: string;
  value: string | boolean;
}

interface RadioWrapperInterface {
  control: any;
  name: string;
  ['data-testid']: string;
  label: string;
  options: RadioWrapperOptions[];
  infoTitle?: React.ReactChild;
  infoDescription?: React.ReactChild;
  row?: boolean;
  required?: boolean;
  defaultValue?: boolean | string;
}

export const RadioWrapper: FC<RadioWrapperInterface> = ({
  label,
  options,
  control,
  'data-testid': testId,
  infoTitle,
  infoDescription,
  name,
  row = true,
  required = false,
  defaultValue,
}) => {
  const hasExpand = !!infoTitle && !!infoDescription;
  const [expanded, expand] = useState(false);
  return (
    <FormControl sx={{ width: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <FormLabel sx={{ typography: 'h4m', color: 'common.black' }}>{label}</FormLabel>
        {hasExpand && (
          <IconButton
            sx={{ color: '#DFC801' }}
            onClick={() => {
              expand(!expanded);
            }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        )}
      </Box>
      {isDesktop && expanded && (
        <Box>
          <Fade in={expanded} timeout={1000}>
            <Grid item md={12} sx={{ textAlign: 'justify' }}>
              {infoDescription}
            </Grid>
          </Fade>
        </Box>
      )}

      <Controller
        name={name}
        rules={{ required }}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup row={row} {...field}>
            {options?.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio inputProps={{ 'data-testid': testId } as ObjectType} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      />
      {hasExpand && (
        <BottomPopover
          isOpen={expanded && !isDesktop}
          handleClose={() => {
            expand(!expanded);
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 16px -32px 16px' }}>
              <IconButton
                onClick={() => {
                  expand(!expanded);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              {infoTitle}
              <Box sx={{ padding: '0 16px' }}>{infoDescription}</Box>
            </Box>
          </Box>
        </BottomPopover>
      )}
    </FormControl>
  );
};
