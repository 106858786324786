import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { AppBar, Box, Breadcrumbs, Button, Link, SvgIcon, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { EUserRole } from 'api/services/user.dto';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import LogoIcon from 'components/Icons/Logo';
import MenuIcon from 'components/Icons/Menu';
import UserIcon from 'components/Icons/User';
import StatusDot from 'components/base/StatusDot';
import CommonButtonNotification from 'components/common/Notification';
import { useAuth } from 'components/providers/AuthProvider';
import { useMenu } from 'components/providers/BaseMenuProvider';
import useSupportModal from 'components/providers/SupportProvider';
import useSupportChat from 'hooks/useSupportChat';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as PathLink, useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_REMOTE_CONTENT, PATH_HOME, PATH_USER_GENERAL } from 'routes/paths';
import utils from 'utils';
import { isEwaTender } from 'utils/env/env';
import { IDataNameFormat } from 'utils/format/format';
import { ObjectType } from 'utils/iots';

const CONTENT_PAGE_ABOUT = 'about-app';
// const CONTENT_PAGE_GUIDE = 'mortgage-guide';

function BackButton({ backRoute, action }: { backRoute?: string; action?: () => void }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <AppBar
      position='static'
      elevation={0}
      sx={{
        minHeight: '72px',

        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        alignItems: 'center',
      }}
    >
      <Button
        sx={{ color: '#787770' }}
        startIcon={<ArrowBackIosOutlinedIcon />}
        onClick={() => {
          if (action) {
            action();
            return;
          }
          navigate((backRoute || -1) as unknown as ObjectType);
        }}
      >
        {t('base.TopMenu.BackButton.back')}
      </Button>
    </AppBar>
  );
}

interface TopMenuProps {
  onClickUser?: () => void;
  breadcrumbs?: Array<{ title: string; activeLink?: boolean; href?: string; action?: () => void }>;
  children?: React.ReactChild;
  sx?: SxProps<Theme>;
}

function TopMenu({ onClickUser, breadcrumbs, children, sx }: TopMenuProps) {
  const supportModal = useSupportModal();
  const { t } = useTranslation();
  const auth = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const menu = useMenu();
  const theme = useTheme();
  const supportChat = useSupportChat();

  const handleSupportClick = () => {
    supportChat.open();
  };

  const handleNavigateHome = () => {
    navigate(PATH_HOME);
  };
  const logoContainer = (
    <Box
      display='flex'
      flexDirection='row'
      gap='2px'
      alignItems='center'
      padding='16px 0'
      onClick={handleNavigateHome}
      sx={{ cursor: 'pointer' }}
    >
      <SvgIcon viewBox='0 0 88 88' sx={{ width: '40px', height: '40px' }} component={LogoIcon} />
      <Typography variant='h2b' sx={{ paddingLeft: '10px' }}>
        kopidoo
      </Typography>
    </Box>
  );
  // const handleGuideClick = () => navigate(PATH_CLIENT_MORTGAGE_REMOTE_CONTENT.replace(':urlname', CONTENT_PAGE_GUIDE));
  const handleAboutClick = () => navigate(PATH_CLIENT_MORTGAGE_REMOTE_CONTENT.replace(':urlname', CONTENT_PAGE_ABOUT));

  const handleNavigate = () => {
    if (onClickUser) {
      onClickUser();
    } else navigate(PATH_USER_GENERAL);
  };

  const userContainer = (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px', padding: '16px 0', cursor: 'pointer' }} onClick={handleNavigate}>
      <SvgIcon viewBox='0 0 40 40' component={UserIcon} sx={{ height: '40px', width: '40px' }} />
      <Typography>{utils.format.nameWithInitials(user as IDataNameFormat)}</Typography>
      <SvgIcon sx={{ height: '20px', marginTop: '3px' }} component={ArrowRightIcon} />
    </Box>
  );

  const isEwaTenderHidden = useMemo(isEwaTender, []);

  if (isEwaTenderHidden) {
    return null;
  }
  // @ts-ignore
  return (
    <Fragment>
      <AppBar
        position='static'
        elevation={0}
        sx={{
          minHeight: '72px',
          ...(sx || {}),

          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
          alignItems: 'center',
        }}
      >
        {auth.hasRole(EUserRole.agent) && (
          <SvgIcon fontSize='small' stroke={`${theme.palette.text.primary}`} onClick={menu?.showMenu} component={MenuIcon} />
        )}
        {logoContainer}
        <Box sx={{ display: 'flex', gap: '20px', padding: '25px 0px', flexGrow: 1 }}>
          <Button onClick={handleAboutClick}>{t('interface:page.HomeClientPage.TopMenu.menuItems.aboutApp')}</Button>
          {/* <Button onClick={handleGuideClick}>{t('interface:page.HomeClientPage.TopMenu.menuItems.mortgageGuide')}</Button> */}
          <Button onClick={handleSupportClick}>{t('interface:page.HomeClientPage.TopMenu.menuItems.writeSupport')}</Button>
          <Button onClick={supportModal.openModal}>{t('interface:page.HomeClientPage.TopMenu.menuItems.getConsultation')}</Button>
        </Box>
        <Box sx={{ padding: '25px 0' }}>
          <CommonButtonNotification sxIcon={{ height: '30px', width: '30px' }} />
        </Box>
        {userContainer}
      </AppBar>
      {breadcrumbs && (
        <Box sx={{ paddingBottom: '24px' }}>
          <Breadcrumbs separator={<StatusDot color='#D2D2CF' sx={{ width: '4px', height: '4px' }} />} aria-label='breadcrumb'>
            {breadcrumbs.map((item) => (
              <Link
                component={PathLink}
                onClick={item.action}
                key={item.title}
                sx={{
                  color: !item.activeLink ? theme.palette.common.link : theme.palette.text.secondary,
                  pointerEvents: !item.activeLink ? 'element' : 'none',
                }}
                underline='none'
                to={item.href || '/'}
              >
                {item.title}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>
      )}

      {children && children}
    </Fragment>
  );
}

TopMenu.BackButton = BackButton;

export default TopMenu;
