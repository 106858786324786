import { Grid, Link, Paper, Typography, useTheme } from '@mui/material';
import { EInsPropPackageCompany } from 'api/services/mortgage.dto';
import { EUserRole, IUserEntity, IUserSettingsInsPropOptions, IUserSettingsMortgageOptions } from 'api/services/user.dto';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useSupportChat from 'hooks/useSupportChat';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL_CONTENT } from 'routes/paths';

enum ETrainigPageItemSize {
  wide = 'wide',
  regular = 'regular',
}
interface ITrainigPageItem {
  urlname: string;
  size: ETrainigPageItemSize;
  thumbnail: { src: string; color: string; size: string; logo?: boolean };
  soon: boolean;
  visible: (user?: IUserEntity | null) => boolean;
}

const AgentInsurancePropertyTutorialPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const auth = useAuth();
  const supportChat = useSupportChat();
  const serviceSettings = auth.getServiceSettings?.('insProp') as IUserSettingsInsPropOptions & IUserSettingsMortgageOptions;

  const trainingPages: ITrainigPageItem[] = [
    {
      urlname: 'tutorial-insp-kickstart',
      size: ETrainigPageItemSize.wide,
      thumbnail: { src: '/img/tutorial/tutorial-insp-kickstart.png', color: '#F8E11A', size: '108px' },
      soon: false,
      visible: (user?: IUserEntity | null) => auth.hasRole(EUserRole.agent) && !user?.company,
    },
    {
      urlname: 'tutorial-insp-intro',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-intro.png', color: '#F9F8F4', size: '108px' },
      soon: false,
      visible: () => true,
    },

    {
      urlname: 'tutorial-insp-howto-police',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-howto-police.png', color: '#F9F8F4', size: '108px' },
      soon: false,
      visible: () => true,
    },
    {
      urlname: 'tutorial-insp-howto-incomes',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-howto-incomes.png', color: '#F9F8F4', size: '108px' },
      soon: false,
      visible: (user?: IUserEntity | null) => auth.hasRole(EUserRole.agent) && !user?.company,
    },
    {
      urlname: 'tutorial-insp-faq',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-faq.png', color: '#F9F8F4', size: '108px' },
      soon: false,
      visible: () => true,
    },

    // Insurances
    {
      urlname: 'tutorial-insp-details-reso',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-details-reso.png', color: '#E7F8EE', size: '76px', logo: true },
      soon: false,
      visible: () => {
        if (!serviceSettings?.availableCompanies) return true;
        return serviceSettings.availableCompanies.includes(EInsPropPackageCompany.resoGarant);
      },
    },
    {
      urlname: 'tutorial-insp-details-ingosstrah',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-details-ingos.png', color: '#E7F0FE', size: '76px', logo: true },
      soon: false,
      visible: () => {
        if (!serviceSettings?.availableCompanies) return true;
        return serviceSettings.availableCompanies.includes(EInsPropPackageCompany.ingosstrah);
      },
    },
    {
      urlname: 'tutorial-insp-details-alfa',
      size: ETrainigPageItemSize.regular,
      thumbnail: { src: '/img/tutorial/tutorial-insp-details-alfa.png', color: '#FFEBEE', size: '76px', logo: true },
      soon: false,
      visible: () => {
        if (!serviceSettings?.availableCompanies) return true;
        return serviceSettings.availableCompanies.includes(EInsPropPackageCompany.alfastrah);
      },
    },
  ];

  const handlePageClick = (e: React.MouseEvent<HTMLElement>) => {
    const urlname = e.currentTarget.getAttribute('data-urlname');
    if (!urlname) return;
    navigate(PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL_CONTENT.replace(':urlname', urlname));
  };

  const handleSupportClick = () => {
    supportChat.open();
  };

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyTutorialPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyTutorialPage.description') }]}
      header={{
        type: 'withNotification',
        title: t('interface:page.AgentInsurancePropertyTutorialPage.headerTitle'),
      }}
    >
      <Grid container spacing={{ xs: '8px' }}>
        {trainingPages.map((pg) => (
          <Grid hidden={!pg.visible(auth?.user)} item xs={pg.size === ETrainigPageItemSize.wide ? 12 : 6} lg={6} xl={4} key={pg.urlname}>
            <Paper
              onClick={pg.soon ? undefined : handlePageClick}
              elevation={0}
              data-urlname={pg.urlname}
              sx={{
                filter: pg.soon ? 'grayscale(1)' : 'none',
                cursor: 'pointer',
                padding: '16px',
                paddingRight: pg.size === ETrainigPageItemSize.wide ? '35%' : '16px',
                background: pg.thumbnail.color,
                minHeight: pg.size === ETrainigPageItemSize.wide ? '137px' : '164px',
                borderRadius: '16px',
                backgroundImage: `url(${pg.thumbnail.src})`,
                backgroundSize: `${pg.thumbnail.size}`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: pg.size === ETrainigPageItemSize.wide ? 'right center' : 'right bottom',
                ...(pg.thumbnail.logo
                  ? { backgroundPosition: 'right bottom, 16px 16px', backgroundOrigin: 'content-box, padding-box' }
                  : {}),
              }}
            >
              {/* TODO: lineHeight -> Problems with typography theme override */}
              <Typography variant={pg.size === ETrainigPageItemSize.wide ? 'h3b' : 'h4b'} sx={{ lineHeight: '130%' }}>
                {t(`interface:page.AgentInsurancePropertyTutorialPage.pages.${pg.urlname}.title`)}
              </Typography>
              <Typography variant={pg.size === ETrainigPageItemSize.wide ? 'h5' : 'h6'}>
                {t(`interface:page.AgentInsurancePropertyTutorialPage.pages.${pg.urlname}.description`)}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Link
        sx={{ cursor: 'pointer', margin: '36px 0' }}
        textAlign='center'
        variant='h4'
        color={theme.palette.common.link}
        underline='none'
        onClick={handleSupportClick}
      >
        {t('interface:page.AgentHomePage.supportChatBtn')}
      </Link>
    </Page>
  );
};

export default AgentInsurancePropertyTutorialPage;
