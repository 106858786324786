import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { getClients } from 'api/services/agent';
import BaseInsuranceListSearchAndFilter from 'components/base/Insurance/List/SearchAndFilter';
import BaseInsuranceListSorting from 'components/base/Insurance/List/Sorting';
import BaseLinkRouter from 'components/base/Link/Router';
import BaseListEmpty from 'components/base/List/Empty';
import Page from 'components/layouts/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE } from 'routes/paths';
import { IDLE, LOADING, SUCCESS } from 'types/statuses';

import ListItem from './components/ListItem';

// TODO: Need refactoring with i18n
// TODO: Need refactoring in this component
const AgentClientListPage = () => {
  const { t } = useTranslation();
  const [clients, setClients] = useState(null);
  const [filtered, setFiltered] = useState(null); // TODO: Remove filtered, only use clients
  const [sortByCreatedTime, setSortByCreatedTime] = useState('desc');
  const [queryFilter, setQueryFilter] = useState(null);
  const [status, setStatus] = useState(IDLE);

  const loadClients = () => {
    const selectedTags = [];
    setStatus(LOADING);
    getClients(selectedTags, `createdAt:${sortByCreatedTime}`)
      .then((res) => {
        const {
          body: { results = [] } = {},
        } = res;
        setClients(results);
        setFiltered(results);
      })
      .catch((err) => {
        // TODO: Error handling
        console.error(err);
      })
      .finally(() => setStatus(SUCCESS));
  };

  const handleSort = () => {
    setSortByCreatedTime(sortByCreatedTime === 'desc' ? 'asc' : 'desc');
  };

  useEffect(() => {
    if (!clients) {
      loadClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  useEffect(() => {
    setFiltered(
      (clients || []).filter((client) => {
        if (!queryFilter) return true;
        const { address = '', firstname = '', lastname = '', patronymic = '' } = client;
        return `${address} ${firstname} ${lastname} ${patronymic}`.toLocaleLowerCase().indexOf(queryFilter.toLocaleLowerCase()) > -1;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilter]);

  const onChangeQuery = (e) => {
    setQueryFilter(e.target.value);
  };

  const isEmpty = status !== LOADING && clients?.length === 0;

  // Triggers for load clients
  useEffect(() => {
    const loadClients = () => {
      const selectedTags = [];
      getClients(selectedTags, `createdAt:${sortByCreatedTime}`)
        .then((res) => {
          const {
            body: { results = [] } = {},
          } = res;
          setClients(results);
          setFiltered(results);
        })
        .catch((err) => {
          // TODO: Error handling
          console.error(err);
        });
    };

    loadClients();
  }, [sortByCreatedTime]);

  const onDeleted = (uuid) => {
    setFiltered((state) => state.filter((client) => client.uuid !== uuid));
  };

  return (
    <Page
      title={t('helmet:AgentClientListPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentClientListPage.description') }]}
      header={{
        type: 'withNotification',
        title: t('interface:page.AgentClientListPage.headerTitle'),
      }}
      sx={{ height: 'none', paddingBottom: '96px' }}
    >
      {isEmpty && (
        <BaseListEmpty
          containerStyle={{ flexGrow: 1 }}
          title={t('interface:page.AgentClientListPage.emptyList')}
          button={{
            text: t('interface:page.AgentClientListPage.addClientBtn'),
            props: { startIcon: <AddIcon />, component: BaseLinkRouter, to: PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE },
          }}
        />
      )}

      {!isEmpty && (
        <>
          <BaseInsuranceListSearchAndFilter NoFilter onChangeQuery={onChangeQuery} />
          <BaseInsuranceListSorting handleSort={handleSort} title={t('interface:page.AgentClientListPage.sortByDate')} />
          {filtered?.map((client) => (
            <ListItem key={client?.uuid} client={client} onDeleted={onDeleted} />
          ))}
          <div style={{ position: 'fixed', left: 0, bottom: '20px', width: '100%', padding: '0 20px' }}>
            <Button
              component={BaseLinkRouter}
              to={PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE}
              startIcon={<AddIcon />}
              variant='contained'
              size='large'
              type='submit'
              fullWidth
            >
              {t('interface:page.AgentClientListPage.addClientBtn')}
            </Button>
          </div>
        </>
      )}
    </Page>
  );
};

export default AgentClientListPage;
