import { Box, SvgIcon, SxProps, Theme, Typography } from '@mui/material';
import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';
import PinIcon from 'components/Icons/Pin';
import { mapStatus, mapStatusColor } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/utils';
import { useTranslation } from 'react-i18next';

export default function ProposeStatusPanel({
  proposeStatus,
  sx,
  isSelected,
  canApprove,
}: {
  canApprove?: boolean;
  sx?: SxProps;
  theme: Theme;
  isSelected?: boolean;
  proposeStatus?: EMortgageEntryProposeStatus;
}) {
  const { t } = useTranslation();
  const panelState = mapStatusColor({ status: proposeStatus, selectionStatus: isSelected, finalDecision: canApprove });

  const statusText = t(mapStatus(proposeStatus, isSelected));
  if (!panelState) {
    return null;
  }
  return (
    <Box
      sx={{
        padding: '5px 10px 5px 10px',
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        borderRadius: '8px',
        alignItems: 'center',
        ...panelState,
        ...(sx || {}),
      }}
    >
      {isSelected && !canApprove && <SvgIcon sx={{ width: '10px', height: '10px' }} component={PinIcon} />}
      <Typography variant='h6' component='span' sx={{ color: panelState.color }}>
        {statusText}
      </Typography>
    </Box>
  );
}
