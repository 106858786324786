import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, useTheme } from '@mui/material';
import { EScoringResultColor, EScoringStatus, EScoringValueType, IScoringRow } from 'api/services/scoring.dto';
import { useTranslation } from 'react-i18next';
import { percent, price } from 'utils/format/format';
import { format, formatDuration, intervalToDuration, startOfDay, subDays } from 'date-fns';
import { ru } from 'date-fns/esm/locale';

export function mapStatusColor({
  status,
}: {
  status?: EScoringStatus;
}): { color: string; backgroundColor: string } {
  switch (status) {
    case EScoringStatus.done: {
      return {
        color: '#0C0B02',
        backgroundColor: '#F8E11A',
      };
    }
    case EScoringStatus.error:
      return {
        backgroundColor: '#FFEAEA',
        color: '#E81E1E',
      };
    case EScoringStatus.notEnoughData:
      return {
        backgroundColor: '#F5F5F5',
        color: '#787770',
      };
    default:
      return { backgroundColor: '#FFF5E3', color: '#F4A611' };
  }
}

const makeI18nVars = (row: IScoringRow) => {
  const vars: Record<string, string> = {};
  row?.conditions?.forEach((cond) => {
    vars[cond.key] = cond.value;
  });
  return vars;
};

export default function ScoringTable({ rows }: { rows: any[] }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderValue = (row: IScoringRow) => {
    if (!row?.value) return t(`common:scoring.color.${row.result}`);
    if (row.valueType === EScoringValueType.price) return `${price(row.value / 100)}`;
    if (row.valueType === EScoringValueType.number) return `${row.value}`;
    if (row.valueType === EScoringValueType.percent) return `${percent(row.value)}`;
    if (row.valueType === EScoringValueType.days) {
      const ducation = intervalToDuration({ start: startOfDay(subDays(new Date(), row.value)), end: new Date() });
      return `${formatDuration(ducation, { format: ['years', 'months', 'days'], locale: ru })}`;
    }
    return row.value;
  };

  const mapResultColor = ({ result }: { result: EScoringResultColor }) => {
    switch (result) {
      case EScoringResultColor.yellow: {
        return {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.primary.contrastText,
        };
      }
      case EScoringResultColor.red:
        return {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.secondary.main,
        };
      case EScoringResultColor.green:
        return {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.secondary.main,
        };
    }
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('interface:base.ScoringTable.source')}</TableCell>
            <TableCell align="left">{t('interface:base.ScoringTable.rule')}</TableCell>
            <TableCell align="center">{t('interface:base.ScoringTable.status')}</TableCell>
            <TableCell align="center">{t('interface:base.ScoringTable.result')}</TableCell>
            <TableCell align="right">{t('interface:base.ScoringTable.date')}</TableCell>
            <TableCell align="right">{t('interface:base.ScoringTable.data')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {t(`common:scoring.rule.${row.type}.group`)}
              </TableCell>
              <TableCell component="th" scope="row">
                {t(`common:scoring.rule.${row.type}.title`, makeI18nVars(row))}
              </TableCell>
              <TableCell align="center">
                <Chip label={t(`common:scoring.status.${row.status}`)} sx={{ ...mapStatusColor(row) }} />
              </TableCell>
              <TableCell align="center">
                {row.status === EScoringStatus.done ? <Chip label={renderValue(row)} sx={{ ...mapResultColor(row) }} /> : <>-</>}
              </TableCell>
              <TableCell align="right">
                {row?.dates?.createdAtTs ? format(row?.dates?.createdAtTs * 1000, 'dd.MM.yyyy HH:mm') : '-'}
              </TableCell>
              <TableCell align="right">{row.additional}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
