import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';

// TODO: Remove dayjs

// localization default
dayjs.locale('ru');
// extend for duration and relativeTime
dayjs.extend(duration);
dayjs.extend(relativeTime);

const getCurrentDate = () => dayjs();
const format = (date: string | dayjs.Dayjs | null = null, format = 'YYYY-MM-DD') => dayjs(date ?? dayjs()).format(format);
const dayDuration = (months: number) => dayjs.duration({ months: months }).humanize();
const unixToDate = (unix: number, format = 'DD.MM.YYYY') => dayjs.unix(unix).format(format);
const addDays = (unix: number, days: number, format = 'DD.MM.YYYY') => dayjs.unix(unix).add(days, 'day').format(format);
const genUnixTs = () => Math.round(genUnixTsInMs() / 1000);
const genUnixTsInMs = () => Math.round(new Date().getTime());
const yearsCalculate = (month: number) => ({ years: Math.trunc(month / 12), months: month % 12 });
const dateByFormat = (date: string, format: string) => dayjs(date, format);

export default { getCurrentDate, format, dayDuration, genUnixTs, genUnixTsInMs, yearsCalculate, unixToDate, addDays, dateByFormat };
