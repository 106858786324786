import { Paper, SvgIcon, Typography, Box, Grid, MenuItem, TextField, Button } from '@mui/material';
import RadioField from 'components/common/Forms/RadioField';
import UploadButton from 'components/common/UploadButton';
import { MapIcons } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/icons';
import { isDesktop } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const BankGuaranteeCard = (props: any) => {
  const { t } = useTranslation();

  if (props.declined === true) {
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          borderRadius: '12px',
          height: '100%',
          width: 1,
          justfiyContent: 'space-between',
        }}
        variant='outlined'
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Box>{props.statusPanel}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', width: '100%' }}>
              <SvgIcon component={MapIcons[`${props.bank}`]} sx={{ width: 48, height: 48 }} />
              <Typography variant='h4'>{t(`common:mortgage.banks.${props.bank}`)}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }

  const { control } = useForm();

  const mobileCard = () => {
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          borderRadius: '12px',
          height: '100%',
          width: 1,
          justfiyContent: 'space-between',
        }}
        variant='outlined'
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Box>{props.statusPanel}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', width: '100%' }}>
              <SvgIcon component={MapIcons[`${props.bank}`]} sx={{ width: 48, height: 48 }} />
              <Typography variant='h4'>{t(`common:mortgage.banks.${props.bank}`)}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name='entry.params.type'
                  render={({ field, fieldState }) => (
                    <TextField
                      id={field.name}
                      label={'Ведение бухгалтерского учета'}
                      variant='standard'
                      fullWidth
                      required
                      {...field}
                      error={!!fieldState.error}
                      helperText={t(`${fieldState.error?.message || ''}`)}
                      inputProps={{ ['data-testid']: field.name }}
                      select
                    >
                      <MenuItem key={'1'} value='1'>
                        Упрощенная (6%)
                      </MenuItem>
                      <MenuItem key={'2'} value='2'>
                        Упрощенная (15%)
                      </MenuItem>
                      <MenuItem key={'3'} value='3'>
                        Основная
                      </MenuItem>
                      <MenuItem key={'3'} value='3'>
                        ЕНВД
                      </MenuItem>
                      <MenuItem key={'3'} value='3'>
                        ЕСХН
                      </MenuItem>
                      <MenuItem key={'3'} value='3'>
                        Патент
                      </MenuItem>
                      <MenuItem key={'3'} value='3'>
                        Иное
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <RadioField
                  control={control}
                  name='actives'
                  label={'Имеет ли компания просроченную задолженность по зарплате более 40 дней?'}
                  options={[
                    { value: 'yes', label: 'Да' },
                    { value: 'no', label: 'Нет' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='fio.organ'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={'СНИЛС бенефициарного владельца 1'}
                      inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                      variant='standard'
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(`${fieldState.error?.message || ''}`)}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='fio.organ'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={'СНИЛС бенефициарного владельца 2'}
                      inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                      variant='standard'
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(`${fieldState.error?.message || ''}`)}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 700 }}>Загрузите документы</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Пояснения по расхождению чистой прибыли к нераспредленнной более 2 тыс руб</Typography>
                <UploadButton />
              </Grid>
              <Grid item xs={12}>
                <Typography>Пояснение по распределению чистой прибыли</Typography>
                <UploadButton />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{}}>Пояснение по распределению чистой прибыли</Typography>
                <UploadButton />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  type='submit'
                  // onClick={submitData}
                  data-testid='mortgage.create'
                >
                  Отправить
                </Button>
              </Grid>
            </Grid>
            {/* <Grid container direction='row'>
              <Grid item xs={6}>
                <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
                  {t('interface:base.ProposeCard.rate')}
                </Typography>
                <Typography sx={{ ...typographySx }} variant='h4'>
                  {utils.format.percent(props.rate)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                  {t('interface:base.ProposeCard.creditDuration')}
                </Typography>
                <Typography variant='h4' sx={typographySx}>
                  {props.entry?.params?.period || ''} мес.
                </Typography>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 0.2, paddingTop: '12px' }} />
      </Paper>
    );
  };
  const desktopCard = () => (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '24px',
        borderRadius: '12px',
        height: '100%',
        width: 1,
        justfiyContent: 'space-between',
      }}
      variant='outlined'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', flexGrow: 0.7, paddingRight: '24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
            <SvgIcon component={MapIcons[`${props.bank}`]} sx={{ width: 48, height: 48 }} />
            <Typography variant='h4'>{t(`common:mortgage.banks.${props.bank}`)}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name='entry.params.type'
                render={({ field, fieldState }) => (
                  <TextField
                    id={field.name}
                    label={'Ведение бухгалтерского учета'}
                    variant='standard'
                    fullWidth
                    required
                    {...field}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                    select
                  >
                    <MenuItem key={'1'} value='1'>
                      Упрощенная (6%)
                    </MenuItem>
                    <MenuItem key={'2'} value='2'>
                      Упрощенная (15%)
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      Основная
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      ЕНВД
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      ЕСХН
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      Патент
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      Иное
                    </MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RadioField
                control={control}
                name='actives'
                label={'Имеет ли компания просроченную задолженность по зарплате более 40 дней?'}
                options={[
                  { value: 'yes', label: 'Да' },
                  { value: 'no', label: 'Нет' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name='fio.organ'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={'СНИЛС бенефициарного владельца 1'}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name='fio.organ'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={'СНИЛС бенефициарного владельца 2'}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 700 }}>Загрузите документы</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Пояснения по расхождению чистой прибыли к нераспредленнной более 2 тыс руб</Typography>
              <UploadButton />
            </Grid>
            <Grid item xs={12}>
              <Typography>Пояснение по распределению чистой прибыли</Typography>
              <UploadButton />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{}}>Пояснение по распределению чистой прибыли</Typography>
              <UploadButton />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                size='large'
                type='submit'
                // onClick={submitData}
                data-testid='mortgage.create'
              >
                Отправить
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container direction='row' spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6} md={4}>
              <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
                {t('interface:base.ProposeCard.rate')}
              </Typography>
              <Typography sx={{ ...typographySx }} variant='h4'>
                {utils.format.percent(props.rate)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('interface:base.ProposeCard.creditDuration')}
              </Typography>
              <Typography sx={{ ...typographySx }} variant='h4'>
                {props.entry?.params?.period || ''} мес.
              </Typography>
            </Grid>

            <Grid item xs={6} md={4}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('interface:base.ProposeCard.creditAmount')}
              </Typography>
              <Typography variant='h4' sx={typographySx}>
                {utils.format.price(props.entry?.params?.amount)}
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Paper>
  );
  return isDesktop ? desktopCard() : mobileCard();
};

export default BankGuaranteeCard;
