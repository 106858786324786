import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import BaseListEmpty from 'components/base/List/Empty';

const GuiBaseListEmpty = () => {
  return (
    <Stack spacing={2}>
      <Typography variant='h1b'>BaseListEmpty</Typography>

      <BaseListEmpty
        containerStyle={{ minHeight: '65vh' }}
        title={'Нет добавленных \nклиентов'}
        button={{
          text: 'Добавить клиента',
          props: { startIcon: <AddIcon />, onClick: () => console.log('click') },
        }}
      />

      <Typography variant='h1b'>BaseListEmpty without button</Typography>

      <BaseListEmpty containerStyle={{ minHeight: '65vh' }} title={'Нет добавленных \nклиентов'} />
    </Stack>
  );
};

export default GuiBaseListEmpty;
