import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Fade, Grid, IconButton, TextField, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import utils from 'utils';

interface NumberFieldProps {
  prefix: string;
  label: string;
  helperText?: boolean;
  suffix?: string;
  thousandSeparator?: string;
  required?: boolean;
  infoText?: string;
  format?: string;
  mask?: string;
  defaultValue?: number;
}

export const NumberField: FC<NumberFieldProps> = ({
  prefix,
  label,
  format,
  mask,
  suffix = '',
  thousandSeparator = '',
  helperText = '',
  required = false,
  infoText = '',
  defaultValue,
}) => {
  const [expanded, expand] = useState(false);
  const theme = useTheme();

  const { t } = useTranslation();
  const { control } = useFormContext();

  const handleExpand = () => {
    expand(!expanded);
  };

  const canExpand = !!infoText;

  return (
    <Box sx={{ position: 'relative' }}>
      <Controller
        control={control}
        rules={{ required }}
        name={label}
        defaultValue={defaultValue}
        render={({ field }) => (
          <NumberFormat
            sx={{ width: '100%' }}
            customInput={TextField}
            thousandSeparator={thousandSeparator}
            suffix={suffix}
            label={t(`${prefix}.${label}.title`)}
            helperText={helperText ? t(`${prefix}.${label}.helperText`) : ''}
            variant='standard'
            format={format && format}
            mask={mask && mask}
            inputProps={{
              'data-testid': label,
            }}
            {...field}
            onChange={() => ''}
            onValueChange={({ value }) => {
              field.onChange(utils.format.digitString(value)!);
            }}
          />
        )}
      />
      {canExpand && (
        <Box sx={{ position: 'absolute', right: 0, top: '10px' }}>
          <IconButton sx={{ color: theme.palette.primary.dark }} onClick={handleExpand}>
            <InfoOutlinedIcon />
          </IconButton>
        </Box>
      )}
      {expanded && (
        <Fade in={expanded} timeout={1000}>
          <Grid item md={12} sx={{ textAlign: 'justify' }}>
            {infoText && infoText}
          </Grid>
        </Fade>
      )}
    </Box>
  );
};
