/* eslint-disable max-len */
import { Box, List, ListItem, ListItemText, SxProps, Typography, useTheme } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { EServiceName, EUserRole } from 'api/services/user.dto';
import { useAuth } from 'components/providers/AuthProvider';
import { Fragment, memo } from 'react';
import useSupportChat from 'hooks/useSupportChat';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as p from 'routes/paths';
import utils from 'utils';

import MenuUserInfo from './MenuUserInfo';

interface MenuType {
  name: string;
  visible: boolean;
  onClick: () => void;
  id: string;
  component: React.ReactElement;
  children: Array<Partial<MenuType>>;
  sx: SxProps;
}

interface MenuDrawerProps {
  menuVisible: boolean;
  onCloseMenu: () => void;
  onOpenMenu: () => void;
}

const MenuContainer = memo((props: Partial<MenuType>) => {
  const { t } = useTranslation();

  if (!props.visible) {
    return null;
  }
  if (!props.children) {
    return (
      <ListItem sx={{ padding: '0 0 0 16px !important' }} button key={`items.${props.name}`} onClick={props.onClick}>
        <ListItemText primary={t(`interface:base.Menu.items.${props.name}`)} />
      </ListItem>
    );
  }

  return (
    <Fragment key={`items.${props.name}`}>
      {props.name && (
        <ListItem button onClick={props.onClick}>
          <Typography variant='h4' sx={{ fontWeight: 700 }}>
            {t(`interface:base.Menu.items.${props.name}`)}
          </Typography>
        </ListItem>
      )}

      <List
        sx={{
          padding: 0,
          ...props.sx,
        }}
      >
        {props.children?.map((child) => (
          <MenuContainer key={child?.name} {...child} />
        ))}
      </List>
    </Fragment>
  );
});

const MenuDrawer = ({ menuVisible, onCloseMenu, onOpenMenu }: MenuDrawerProps) => {
  const theme = useTheme();
  const supportChat = useSupportChat();
  const navigate = useNavigate();

  const { user, isAuth, logout, hasService } = useAuth();

  // Hide menu for guests
  if (!isAuth) return null;

  const checkUserGroup = (roles: EUserRole[]) => {
    if (!user) return false;
    if (!roles) return true;
    // CHECK: validate user role utils
    return roles.some((x) => user.roles.includes(x));
  };

  const isIndependentAgent = checkUserGroup([EUserRole.agent]);

  const menu: Array<Partial<MenuType>> = [
    // top
    {
      id: 'top',
      children: [{ name: 'user', onClick: () => navigate(p.PATH_USER), visible: true }],
      component: <MenuUserInfo />,
      visible: true,
    },
    {
      name: 'mortgage',
      onClick: () => navigate(p.PATH_AGENT_MORTGAGE_HOME),
      visible:
        utils.env.is(import.meta.env.REACT_APP_MORTGAGE_ENABLE) && checkUserGroup([EUserRole.agent]) && hasService?.(EServiceName.mortgage),
      children: [
        { name: 'main', onClick: () => navigate(p.PATH_AGENT_MORTGAGE_HOME), visible: true },
        { name: 'clients', onClick: () => navigate(p.PATH_AGENT_MORTGAGE_PROPERTY_CLIENTS), visible: checkUserGroup([EUserRole.agent]) },
        // { name: 'policies', onClick: () => navigate(p.PATH_AGENT_MORTGAGE_PROPERTY_POLICY), visible: checkUserGroup([EUserRole.agent]) },
        { name: 'income', onClick: () => navigate(p.PATH_AGENT_MORTGAGE_PROPERTY_INCOMES), visible: isIndependentAgent },
        // { name: 'statistic', onClick: () => navigate(p.PATH_AGENT_MORTGAGE_PROPERTY), visible: !isIndependentAgent },
        // {
        //   name: 'training',
        //   onClick: () => navigate(p.PATH_AGENT_MORTGAGE_PROPERTY_TUTORIAL),
        //   visible: checkUserGroup([EUserRole.agent]),
        // },
      ],
    },
    {
      id: 'middle',
      visible: true,
      children: [
        { name: 'profile', onClick: () => navigate('/profile'), visible: checkUserGroup([EUserRole.client]) },
        { name: 'documents', onClick: () => navigate('#'), visible: checkUserGroup([EUserRole.client]) },
        { name: 'banks', onClick: () => navigate('/bank/list'), visible: checkUserGroup([EUserRole.client]) },
        { name: 'meetings', onClick: () => navigate('#'), visible: checkUserGroup([EUserRole.client]) },
        { name: 'agent', onClick: () => navigate('#'), visible: checkUserGroup([EUserRole.client]) },
        {
          name: 'propretyInsurance',
          onClick: () => navigate(p.PATH_AGENT_INSURANCE_LIST),
          visible: checkUserGroup([EUserRole.agent]) && hasService?.(EServiceName.insProp),
          children: [
            { name: 'main', onClick: () => navigate(p.PATH_AGENT_INSURANCE_LIST), visible: true },
            {
              name: 'clients',
              onClick: () => navigate(p.PATH_AGENT_INSURANCE_PROPERTY_CLIENT),
              visible: checkUserGroup([EUserRole.agent]),
            },
            {
              name: 'policies',
              onClick: () => navigate(p.PATH_AGENT_INSURANCE_PROPERTY_POLICY),
              visible: checkUserGroup([EUserRole.agent]),
            },
            { name: 'income', onClick: () => navigate(p.PATH_AGENT_INSURANCE_PROPERTY_INCOMES), visible: isIndependentAgent },
            { name: 'statistic', onClick: () => navigate(p.PATH_AGENT_INSURANCE_PROPERTY_STATISTIC), visible: !isIndependentAgent },
            {
              name: 'training',
              onClick: () => navigate(p.PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL),
              visible: checkUserGroup([EUserRole.agent]),
            },
          ],
        },
      ],
    },

    {
      id: 'bottom',
      sx: { color: `${theme.palette.text.secondary}` },
      visible: true,
      children: [
        { name: 'notifications', onClick: () => navigate(p.PATH_NOTIFICATIONS), visible: true },
        {
          name: 'supportChat',
          onClick: () => {
            supportChat.open();
          },
          visible: true,
        },
        { name: 'logout', onClick: logout, visible: true },
        { name: 'develop', onClick: () => navigate(p.PATH_GUI), visible: utils.env.is(import.meta.env.REACT_APP_DEBUG_MENU) },
        // TODO: Move to user settins page
        // { name: 'tutorial', onClick: () => navigate(p.PATH_TUTORIAL), visible: utils.env.is(import.meta.env.REACT_APP_DEBUG_MENU) },
      ],
    },
  ];

  return (
    <SwipeableDrawer sx={{ height: 1 }} open={menuVisible} onClose={onCloseMenu} onOpen={onOpenMenu}>
      <Box
        sx={{
          height: 1,
          width: 314,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        role='presentation'
        onClick={onCloseMenu}
        onKeyDown={onCloseMenu}
      >
        {menu.map((menuItem) =>
          menuItem.visible ? (
            <Box sx={{ paddingBottom: '48px' }} key={menuItem.id || menuItem.name}>
              {menuItem.component}
              <MenuContainer key={menuItem.name} {...menuItem} />
            </Box>
          ) : null,
        )}
      </Box>
      <Typography variant='h8' sx={{ width: 1, textAlign: 'left', padding: '0 15px' }}>
        {import.meta.env.REACT_APP_VERSION}
      </Typography>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
