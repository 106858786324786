import { EFormItemSettingsValidatorType, IFormItemSettings, IFormItemSettingsValidator } from 'api/services/form.dto';
import * as datefns from 'date-fns';
import _ from 'lodash';
import { ObjectType } from 'utils/iots';

import formatter from '../format/formatter';

const translationErrors = {
  required: 'errors:validation.required',
  length: 'errors:validation.length',
  length_eq: 'errors:validation.length_eq',
  length_min: 'errors:validation.length_min',
  length_max: 'errors:validation.length_max',
  date: 'errors:validation.date',
  email: 'errors:validation.email',
};

interface ValidationError {
  message?: string;
  options?: Record<string, ObjectType>;
}

export default class ValidationRule {
  _settings: IFormItemSettings;

  constructor(settings: IFormItemSettings) {
    this._settings = settings;
  }
  format(value: ObjectType) {
    if (!this._settings?.format) {
      return value;
    }
    const fn = formatter[this._settings.format];

    return fn(value);
  }
  validate = (value: ObjectType): ValidationError | null | undefined => {
    const errors = [];

    const formated = this.format(value);

    for (const item of this?._settings?.validations || []) {
      switch (item.type) {
        case EFormItemSettingsValidatorType.isDate: {
          errors.push(this.isDate()(formated));
          break;
        }
        case EFormItemSettingsValidatorType.isEmail: {
          errors.push(this.email()(formated));
          break;
        }
        case EFormItemSettingsValidatorType.length: {
          errors.push(this.length(item)(formated));
          break;
        }
        case EFormItemSettingsValidatorType.required: {
          errors.push(this.required()(formated));
          break;
        }
      }
    }

    return errors.filter((x) => _.isObject(x)).at(0);
  };
  email() {
    return (value?: string) => {
      if (!value) {
        return null;
      }
      return !!String(value).toLowerCase().match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
        ? null
        : {
            message: translationErrors.email,
          };
    };
  }
  required() {
    return (value?: unknown) => {
      return !!value ? null : { message: translationErrors.required };
    };
  }
  length(rule: IFormItemSettingsValidator) {
    return (value?: string) => {
      if (!value) {
        return null;
      }
      const v = String(value)?.length;
      if (rule?.params?.eq) {
        return v === rule?.params?.eq
          ? null
          : {
              message: translationErrors.length_eq,
              options: {
                ...rule.params,
              },
            };
      }

      if (rule?.params?.min && rule.params.max) {
        if (rule?.params?.min === rule?.params?.max) {
          return v === rule?.params?.min
            ? null
            : {
                message: translationErrors.length_min,
                options: {
                  ...rule.params,
                },
              };
        } else {
          return v > rule?.params?.min && v < rule.params.max
            ? null
            : {
                message: translationErrors.length_max,
                options: {
                  ...rule.params,
                },
              };
        }
      }
      if (rule?.params?.min) {
        return v <= rule?.params?.min
          ? {
              message: translationErrors.length_min,
              options: {
                ...rule.params,
              },
            }
          : null;
      }
      if (rule?.params?.max) {
        return v >= rule?.params?.max
          ? {
              message: translationErrors.length_max,
              options: {
                ...rule.params,
              },
            }
          : null;
      }

      return null;
    };
  }
  isDate() {
    return (value?: unknown) => {
      if (typeof value === 'object') {
        return datefns.isValid(value)
          ? null
          : {
              message: translationErrors.date,
            };
      }
      if (typeof value === 'string') {
        return datefns.isValid(datefns.parse(value, 'P', new Date()))
          ? null
          : {
              message: translationErrors.date,
            };
      }
      return null;
    };
  }
}
