import { LS_INVITE_LINK } from 'api/http';
import Page from 'components/layouts/Page';
import { useTranslation } from 'react-i18next';

import RoleInviteAccept from './components/InviteAccept';
import RoleSelector from './components/RoleSelector';

const AuthRolePage = () => {
  const { t } = useTranslation();

  const hasInvite = !!sessionStorage.getItem(LS_INVITE_LINK);

  return (
    <Page
      sx={{
        justifyContent: 'space-evenly',
        height: 1,
        width: { sm: 500 },
        margin: '0 auto',
      }}
      hideHeader={true}
      title={t('helmet:AuthRolePage.title')}
      meta={[{ name: 'description', content: t('helmet:AuthRolePage.description') }]}
    >
      {hasInvite ? <RoleInviteAccept /> : <RoleSelector />}
    </Page>
  );
};

export default AuthRolePage;
