import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import {
  EBusinessCreditEntryParamsPurpose,
  IBusinessCreditCompanyEntryCreatingDataCompany,
} from 'api/services/businessCredit/businessCredit.dto';
import { ECompanyAddressType, ECompanyContactType } from 'api/services/businessCredit/businessCredit.enum';
import { businessCreditCreateSchema, BusinessCreditSchema } from 'api/services/businessCredit/businessCredit.schema';
import { suggestSearchCompany } from 'api/services/suggestions';
import { SuggestionCompanyResponse } from 'api/services/suggestions.dto';
import BaseButtonBack from 'components/base/Button/Back';
import DadataSuggestion from 'components/base/DadataSuggestion';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const T_PREFIX = 'interface:page.BusinessCreditEntryCreatePage';

export default function BusinessCreditCreatePage() {
  const params = useParams();
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, formState, reset, watch } = useForm<BusinessCreditSchema>({
    resolver: zodResolver(businessCreditCreateSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      entry: { params: { purpose: EBusinessCreditEntryParamsPurpose.account } },
    },
  });
  const theme = useTheme();
  const businessProductContext = useBusinessCredit();
  const handleSelect = (cmp: SuggestionCompanyResponse) => {
    const company: Partial<IBusinessCreditCompanyEntryCreatingDataCompany> = {
      name: cmp?.name!,
      inn: cmp.inn!,
      requisite: [],
      addresses: [],
      contacts: [],
    };
    if (cmp.factAddress) {
      company?.addresses?.push({ type: ECompanyAddressType.factAddress, data: cmp.factAddress });
    }
    if (cmp.address) {
      company?.addresses?.push({ type: ECompanyAddressType.legalAddress, data: cmp.address });
    }
    if (cmp.phone) {
      company?.contacts?.push({ type: ECompanyContactType.phone, data: cmp.phone });
    }
    setValue('company', company);
  };

  const submitData = async (values: BusinessCreditSchema) => {
    if (params.uuidEntry) {
      return businessProductContext.updateEntryRequest(params.uuidEntry, values);
    }
    return businessProductContext.createEntryCompanyRequest(values);
  };

  const hasErrors = Object.values(formState.errors).length > 0;

  useEffect(() => {
    if (params.uuidEntry) {
      businessProductContext.loadEntryRequest({ uuidEntry: params.uuidEntry! }, (entry) => {
        reset(entry);
      });
    }
  }, []);
  const company = watch('company');

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreatePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreatePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntryCreatePage.title')} />,
        lg: (
          <TopMenu
            sx={{ zIndex: 9999 }}
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },

              { title: t('interface:base.TopMenu.routes.creditParams'), activeLink: true },
            ]}
          />
        ),
      }}
      sx={{ height: 1, width: 1 }}
      maxWidth='xl'
    >
      <Grid container spacing={2} component='form' onSubmit={handleSubmit(submitData)}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 700 }}>{t(`${T_PREFIX}.amount.title`)}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>{t(`${T_PREFIX}.amount.description`)}</Typography>
          <br />
          <Controller
            control={control}
            name='entry.params.amount'
            render={({ field, fieldState }) => (
              <NumberFormat
                id={field.name}
                label={t(`${T_PREFIX}.amount.label`)}
                variant='standard'
                suffix=' ₽'
                fullWidth
                customInput={TextField}
                required
                thousandSeparator=' '
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
                inputProps={{ ['data-testid']: field.name }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: 700 }}>{t(`${T_PREFIX}.period.title`)}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>{t(`${T_PREFIX}.period.description`)}</Typography>
          <br />
          <Controller
            control={control}
            name='entry.params.period'
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                label={t(`${T_PREFIX}.period.label`)}
                variant='standard'
                inputProps={{ ['data-testid']: field.name }}
                suffix={t(`${T_PREFIX}.period.suffix`)}
                fullWidth
                customInput={TextField}
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: 700 }}>Выберите вид кредитования</Typography>
          <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>
            Укажите вид кредитования для вашего бизнеса
          </Typography>
         
          <Controller
            control={control}
            name='entry.params.purpose'
            render={({ field, fieldState }) => (
              <TextField
                id='purpose'
                required
                select
                label={'Тип кредитования'}
                variant='standard'
                fullWidth
                ref={field.ref}
                value={field.value || ''}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
                inputProps={{ ['data-testid']: field.name }}
              >
                <MenuItem value={E.bankGuarantee}>Банковская гарантия</MenuItem>
                <MenuItem value={EMsbEntryParamsPurpose.account}>Кредитование</MenuItem>
              </TextField>
            )}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: 700 }}>{t(`${T_PREFIX}.company.title`)}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>{t(`${T_PREFIX}.company.description`)}</Typography>
          <br />
          <DadataSuggestion
            value={company?.name}
            label={t(`${T_PREFIX}.company.label`)}
            variant='standard'
            onSelect={handleSelect}
            path={['name']}
            onQuery={suggestSearchCompany}
            placeholder={t('base.CompanySearch.searchPlaceholder')}
            data-testid='company.search'
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Button disabled={hasErrors} fullWidth variant='contained' size='large' type='submit' data-testid='mortgage.create'>
            {t('interface:page.ClientMortgageEntryCreatePage.btnCreate')}
          </Button>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
}
