import { Box, Button, Container } from '@mui/material';
import { getForm, postForm } from 'api/services/form';
import { EOcrDocumentType, IOcrDocument } from 'api/services/ocr.dto';
import BaseButtonBack from 'components/base/Button/Back';
import BaseFormGenerator from 'components/base/FormGenerator';
import { useFormLayoutContext } from 'components/base/FormGenerator/context/FormGeneratorProvider';
import { FormScheme } from 'components/base/FormGenerator/form.types';
import { buildValues, filterFromValues, ft, getFlatKeyValue, getObjValues, setNullForDepended } from 'components/base/FormGenerator/utils';
import UploadPassport from 'components/base/UploadPassport';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useMortgageAccessControl } from 'components/providers/AccessProviders/MortgageAccessProvider';
import useModal from 'hooks/useModal';
import { useResolver } from 'hooks/useResolver';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload, useNavigate, useSearchParams } from 'react-router-dom';
import {
  PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS,
  PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN,
  PATH_CLIENT_MORTGAGE_FORM_VERIFICATION,
  PATH_HOME,
} from 'routes/paths';
import utils from 'utils';
import { isDev } from 'utils/env/env';

import SkeletonForm from './components/SkeletonForm';

import { useAuth } from 'components/providers/AuthProvider';
import { EExperimentName } from 'api/services/user.dto';

import useTypedParams from 'hooks/useTypedParams';

const ClientMortgageFormPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formLayoutContext = useFormLayoutContext();

  const { hasExperiment } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [, setError] = useState<string | null>(null);
  const accessControl = useMortgageAccessControl();
  const [scheme, setScheme] = useState<FormScheme>();
  const { formName, groupName, uuidEntry, uuidUser } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN>();

  const [queryParams] = useSearchParams();
  const validator = useResolver(formName!, scheme);

  const hookForm = useForm({ defaultValues: {}, mode: 'onBlur', reValidateMode: 'onBlur', resolver: validator.resolver });

  const findFormGroup = (formSchema: FormScheme | undefined, groupName: string) => {
    if (!formSchema?.data) return null;
    return formSchema?.data.find(({ name = '', type = '' }) => name === groupName && type === 'group') || null;
  };

  const formGroup = useMemo(() => findFormGroup(scheme, groupName!), [scheme, groupName]);
  const nextFormGroupTitle = () => {
    if (formGroup?.settings?.hiddenNextStepTitle) {
      return t('common:next');
    }
    if (!formGroup?.settings?.nextStep) return null;
    const p = formGroup?.settings?.nextStep;

    return t('interface:page.ClientMortgageFormPage.saveAndGotoProductsBtn', {
      group: t(`${ft(p.split('.'))}.title`),
    });
  };

  const loadForm = async () => {
    setLoading(true);
    try {
      const res = await getForm(formName!, {
        mode: 'fill',
        uuidEntry,
        uuidEntryUser: uuidUser!,
      });

      setScheme(res.body as FormScheme);
    } catch (err) {
      console.log(err);
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };
  /**
   * method of execute save form
   * @returns {Promise<void>}
   */
  const saveForm = async () => {
    const data = setNullForDepended(
      filterFromValues(getFlatKeyValue(getObjValues(hookForm.getValues())), [formName, groupName].join('.')),
      formGroup,
      [formName as never],
    );

    const hasErrors = Object.keys(hookForm.formState.errors);

    if (hasErrors.length > 0) {
      if (isDev()) throw Error(`invalid ${JSON.stringify(hookForm.formState.errors, null, 2)}`);
      else throw Error('invalid form');
    } else {
      const formatedSchema = validator.format(data);
      const res = await postForm(formName!, {
        data: formatedSchema,
        options: {
          uuidEntry,
          uuidEntryUser: uuidUser,
        },
      });
      return res;
    }
  };

  useEffect(() => {
    if (!scheme) loadForm();
    if (scheme) {
      hookForm.reset(buildValues(scheme));
      hookForm.trigger();
      formLayoutContext.setSaveForm(saveForm);
    }
  }, [scheme, uuidUser]);

  useEffect(() => {
    setScheme(undefined);
  }, [groupName]);

  useEffect(() => {
    if (!loading) {
      const qp = queryParams.get('focus') as string;
      const target = document.getElementById(qp);
      target?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      target?.focus({ preventScroll: true });
    }
  }, [queryParams, loading]);

  const handlePrimaryBtnClick = () => {
    const nextStep = () => {
      const [f, g] = formGroup?.settings?.nextStep?.split('.') as string[];

      const url =
        g === 'verification'
          ? utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_VERIFICATION, { uuidEntry: uuidEntry, uuidUser })
          : utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, {
              formName: f,
              groupName: g,
              uuidUser,
              uuidEntry,
            });
      navigate(url);
    };

    saveForm().then(() => {
      nextStep();
    });
  };

  const handleSecondaryBtnClick = () => {
    if (!accessControl.hasAccess) {
      navigate(PATH_HOME);
    }
    saveForm().then(() => navigate(PATH_HOME));
  };
  const hasErrors = Object.keys(hookForm.formState.errors).length > 0;

  const handlePassportOCR = (doc: IOcrDocument) => {
    console.log(doc);
    if (doc.type === EOcrDocumentType.passport) {
      hookForm.setValue('profile.personal.primaryIdentityDoc.passport.issuedBy', doc?.data?.issueBy);
      hookForm.setValue('profile.personal.primaryIdentityDoc.passport.issueDate', doc?.data?.issueDate?.split('.')?.reverse()?.join('-'));
      hookForm.setValue(
        'profile.personal.primaryIdentityDoc.passport.birthDatePassport',
        doc?.data?.birthDate?.split('.')?.reverse()?.join('-'),
      );
      hookForm.setValue('profile.personal.gender', doc.data.gender);
      hookForm.setValue('profile.personal.primaryIdentityDoc.passport.issueUnit', doc?.data?.issueUnit);
      hookForm.setValue('profile.personal.primaryIdentityDoc.passport.birthPlace', doc?.data?.birthPlace);
      hookForm.setValue('profile.personal.primaryIdentityDoc.passport.docNo', (doc?.data?.seriesNumber || '').split(' ')[1]);
      hookForm.setValue('profile.personal.primaryIdentityDoc.passport.docSeries', (doc?.data?.seriesNumber || '').split(' ')[0]);
      hookForm.setValue('profile.personal.primaryIdentityDoc.primaryIdentityDocType', 'passport', { shouldValidate: true });
    }
  };

  const handleDirect = (pathTo: string) => {
    navigate(
      utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, { formName: 'profile', groupName: pathTo, uuidUser, uuidEntry }),
    );
  };

  useEffect(() => {
    formLayoutContext.cleanState();
  }, [groupName]);

  useEffect(() => {
    formLayoutContext.cleanState();
    formLayoutContext.setRedirect(handleDirect);
  }, []);
  const modalState = useModal();

  useBeforeUnload(
    useCallback(
      (ev: BeforeUnloadEvent) => {
        ev.returnValue = '';
        if (!modalState.isOpen) modalState.open();
      },
      [modalState.isOpen],
    ),
  );

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageFormPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageFormPage.description') }]}
      maxWidth='xl'
      header={{
        lg: (
          <TopMenu
            breadcrumbs={
              !formLayoutContext?.formControls?.hidden
                ? [
                    {
                      title: t(`interface:page.ClientMortgageFormPage.progressBreadcrumbs`),
                      href: utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: uuidEntry }),
                    },
                    {
                      title: t(`interface:page.ClientMortgageFormPage.forms.${formName}.title`),
                      activeLink: true,
                    },
                    {
                      title: t(loading ? 'interface:page.ClientMortgageFormPage.loadingLabel' : `${formName}:${formGroup?.name}.title`),

                      action: () => {
                        formLayoutContext.backEvent.action?.();
                      },
                      activeLink: !formLayoutContext?.formControls?.hidden,
                    },
                  ]
                : undefined
            }
          >
            <Fragment>
              {formLayoutContext?.formControls?.hidden && (
                <TopMenu.BackButton
                  action={() => {
                    formLayoutContext.backEvent.action?.();
                  }}
                />
              )}
            </Fragment>
          </TopMenu>
        ),
        xs: (
          <BaseButtonBack
            title={t(loading ? 'interface:page.ClientMortgageFormPage.loadingLabel' : `${formName}:${formGroup?.name}.title`)}
            subtitle={t(`interface:page.ClientMortgageFormPage.forms.${formName}.title`)}
            onBackEvent={() => {
              if (formLayoutContext.backEvent.action) {
                formLayoutContext.backEvent.action?.();
                return;
              }
              if (hookForm.formState.isDirty) {
                const res = window.confirm(t('common:leavePage'));
                if (res) {
                  navigate(-1);
                }
              } else {
                navigate(-1);
              }
            }}
          />
        ),
      }}
      sx={{ height: 1, marginBottom: '24px' }}
    >
      <Container
        maxWidth='md'
        sx={{ height: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'space-evently', gap: '16px', paddingBottom: '20px' }}
      >
        {/* Skeleton */}
        {loading && <SkeletonForm />}
        {/* TODO: This is piece of shit */}
        {groupName === 'personal' && hasExperiment(EExperimentName.ocr) && <UploadPassport onChange={handlePassportOCR} />}
        {/* Form */}
        {!loading && scheme && (
          <BaseFormGenerator path={[]} scheme={scheme} hookForm={hookForm} group={groupName} options={{ hideGroupTitle: true }} />
        )}
        {/* Form controlls */}
        {!loading && !formLayoutContext?.formControls?.hidden && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {nextFormGroupTitle() && !formGroup?.settings?.hideNextStep && (
              <Button
                onClick={handlePrimaryBtnClick}
                disabled={hasErrors}
                fullWidth
                variant='contained'
                size='large'
                data-testid='nextStep'
              >
                {nextFormGroupTitle()}
              </Button>
            )}

            <Button
              onClick={handleSecondaryBtnClick}
              fullWidth
              variant='contained'
              color='secondary'
              size='large'
              data-testid='saveAndContinueBtn'
            >
              {t('interface:page.ClientMortgageFormPage.saveAndContinueBtn')}
            </Button>
          </Box>
        )}
      </Container>
    </ContainerLayout>
  );
};

export default ClientMortgageFormPage;
