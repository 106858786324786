/* eslint-disable max-len */
import { Box, Button, TextField, Typography } from '@mui/material';
import { ApiError } from 'api/errors';
import { getContractContentBy } from 'api/services/contract';
import { EContractType } from 'api/services/contract.dto';
import BaseCheckbox from 'components/base/Checkbox';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';

type Props = {
  onSubmitForm: (values: ObjectType) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckboxWrapper = ({ name, control }: { checkboxType: string; name: string; control: any }) => {
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      defaultValue={true}
      render={({ field: formField }) => (
        <BaseCheckbox
          label={
            <Typography variant='h3b' sx={{ fontSize: '7pt' }}>
              Я даю свое согласие
            </Typography>
          }
          checked={formField.value}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => formField.onChange(e.target.checked)}
        />
      )}
    />
  );
};

const PrivacyContent = ({ onSubmitForm }: Props) => {
  const { t } = useTranslation();
  const [, setError] = useState<ApiError>();

  const [contractContent, setContractContent] = useState('');

  const loadAsync = useCallback(async () => {
    try {
      const contract = await getContractContentBy(EContractType.creditHistoryContract);
      const content = contract.body.content;
      setContractContent(content);
    } catch (err: unknown) {
      setError(err as ApiError);
    }
  }, []);

  useEffect(() => {
    loadAsync();
  }, []);

  const hookForm = useForm<ObjectType>({
    defaultValues: {
      public: {
        official: 'no',
        affiliation: 'no',
      },

      details: {
        official: 'no',
        affiliation: 'no',
        officialPerson: null,
        relationWithOfficialPerson: null,
        unresolvedJudicialDispute: 'no',
        judiciallyCondemned: 'no',
        taxResidentInUSA: 'no',
      },
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [publicValue] = hookForm.watch(['public']);

  const parseOptions: HTMLReactParserOptions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    replace: (node: any) => {
      if (node.name === 'hr') {
        return <hr style={{ border: '1px solid #D2D2CF', width: '100%' }} />;
      }
      if (/{.*}/gm.test(node?.data)) {
        const [type, name] = node.data.replace('{', '').replace('}', '').split(':');
        if (!name) {
          return null;
        }

        return <CheckboxWrapper checkboxType={type} name={`agreements.${name.trim()}`} control={hookForm.control} />;
      }
      return null;
    },
  };

  const onSubmit = () => {
    const values = { ...hookForm.getValues() };
    Object.keys(values.details).forEach((key) => {
      if (_.isString(_.get(values, `details.${key}`))) {
        _.set(values, `details.${key}`, _.get(values, `details.${key}`) === 'yes');
      }
    });

    values['public'] = undefined;

    onSubmitForm(values);
  };
  const parsed = parse(contractContent, parseOptions);

  const isEnabledPublicOfficial = publicValue?.official === 'yes';
  const isEnabledPublicAffiliation = publicValue?.affiliation === 'yes';

  const hasErrors = Object.keys(hookForm?.formState?.errors).length > 0;

  return (
    <>
      {parsed}
      <form onSubmit={hookForm.handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography variant='h5'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.courtDecision')}</Typography>
          <Controller
            name='details.unresolvedJudicialDispute'
            control={hookForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <Box sx={{ display: 'flex' }}>
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.no')}</Typography>}
                  checked={field.value === 'no'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'no' : 'yes')}
                />
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.yes')}</Typography>}
                  checked={field.value === 'yes'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'yes' : 'no')}
                />
              </Box>
            )}
          />
          <Typography variant='h5'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.sentencedToPunishment')}</Typography>
          <Controller
            name='details.judiciallyCondemned'
            control={hookForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <Box sx={{ display: 'flex' }}>
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.no')}</Typography>}
                  checked={field.value === 'no'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'no' : 'yes')}
                />
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.yes')}</Typography>}
                  checked={field.value === 'yes'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'yes' : 'no')}
                />
              </Box>
            )}
          />
          <Typography variant='h5'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.publicOfficial')}</Typography>
          <Controller
            name='public.official'
            control={hookForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <Box sx={{ display: 'flex' }}>
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.no')}</Typography>}
                  checked={field.value === 'no'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'no' : 'yes')}
                />
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.yes')}</Typography>}
                  checked={field.value === 'yes'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'yes' : 'no')}
                />
              </Box>
            )}
          />
          {isEnabledPublicOfficial && (
            <>
              <Controller
                name='details.officialPerson.workPositionTitle'
                control={hookForm.control}
                rules={isEnabledPublicOfficial ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.position')}
                  />
                )}
              />
              <Controller
                name='details.officialPerson.employerTitle'
                control={hookForm.control}
                rules={isEnabledPublicOfficial ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.employerName')}
                  />
                )}
              />
              <Controller
                name='details.officialPerson.employerFullAddress'
                control={hookForm.control}
                rules={isEnabledPublicOfficial ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.employerAddress')}
                  />
                )}
              />
            </>
          )}

          <Typography variant='h5' sx={{ paddingTop: '40px' }}>
            {t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.pulbicAffiliation')}
          </Typography>
          <Controller
            name='public.affiliation'
            control={hookForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <Box sx={{ display: 'flex' }}>
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.no')}</Typography>}
                  checked={field.value === 'no'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'no' : 'yes')}
                />
                <BaseCheckbox
                  label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.yes')}</Typography>}
                  checked={field.value === 'yes'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => field.onChange(e.target.checked ? 'yes' : 'no')}
                />
              </Box>
            )}
          />
          {isEnabledPublicAffiliation && (
            <>
              <Controller
                name='details.relationWithOfficialPerson.relationType'
                control={hookForm.control}
                rules={isEnabledPublicAffiliation ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.affiliationDegree')}
                  />
                )}
              />
              <Controller
                name='details.relationWithOfficialPerson.workPositionTitle'
                control={hookForm.control}
                rules={isEnabledPublicAffiliation ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.position')}
                  />
                )}
              />
              <Controller
                name='details.relationWithOfficialPerson.employerTitle'
                control={hookForm.control}
                rules={isEnabledPublicAffiliation ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.employerName')}
                  />
                )}
              />
              <Controller
                name='details.relationWithOfficialPerson.employerFullAddress'
                control={hookForm.control}
                rules={isEnabledPublicAffiliation ? { required: true } : undefined}
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    label={t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.employerAddress')}
                  />
                )}
              />
            </>
          )}
        </Box>
        <Typography variant='h5' sx={{ paddingTop: '40px' }}>
          {t('interface:page.ClientMortgageEntrySignPrivacyPage.additional.usaTaxResident')}
        </Typography>
        <Controller
          name='details.taxResidentInUSA'
          control={hookForm.control}
          rules={{ required: true }}
          render={({ field }) => (
            <Box sx={{ display: 'flex' }}>
              <BaseCheckbox
                label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.no')}</Typography>}
                checked={field.value === 'no'}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => field.onChange(e.target.checked ? 'no' : 'yes')}
              />
              <BaseCheckbox
                label={<Typography variant='h3b'>{t('interface:page.ClientMortgageEntrySignPrivacyPage.checkbox.yes')}</Typography>}
                checked={field.value === 'yes'}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => field.onChange(e.target.checked ? 'yes' : 'no')}
              />
            </Box>
          )}
        />
        <Button onClick={onSubmit} sx={{ marginBottom: '20px' }} disabled={hasErrors} fullWidth variant='contained' size='large'>
          {t('interface:page.ClientMortgageEntrySignPrivacyPage.btnNext')}
        </Button>
      </form>
    </>
  );
};

export default PrivacyContent;
