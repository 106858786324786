import { Box, Button, Link, SvgIcon, Theme, useMediaQuery, useTheme } from '@mui/material';
import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';
import ArrowDownIcon from 'components/Icons/ArrowDown';
import ArrowUpIcon from 'components/Icons/ArrowUp';
import ProposeCardDesktop from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeCard.desktop';
import ProposeCardMobile from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeCard.mobile';
import ProposeCardPending from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeCard.pending';
import ProposeStatusPanel from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeStatusPanel';
import { MapIcons } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/icons';
import { isPendingStatus } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposeCardProps } from './types';
import ProposeEditor from './components/ProposeEditor';
import { usePropose } from '../../ProposeProvider';
import { Accessible } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';

export default function ProposeCard(props: ProposeCardProps) {
  const theme = useTheme();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));
  const proposeHook = usePropose();
  const { t } = useTranslation();

  const { propose, selected, onCancelPropose, onConfirmPropose, canApprove, onChangePropose } = props;

  const status = isPendingStatus(propose?.status);

  const [isExpanded, expand] = useState(false);

  const handleExpand = () => {
    expand(!isExpanded);
  };

  const handleCancelPropose = useCallback(() => {
    if (propose) onCancelPropose?.(propose);
  }, [propose]);

  const handleConfirmPropose = useCallback(() => {
    if (propose) onConfirmPropose?.(propose);
  }, [propose]);

  const handleChangePropose = useCallback(() => {
    if (propose) onChangePropose?.(propose);
  }, [propose]);

  const proposeButtonState = useMemo(() => {
    const mp = {
      selected: {
        text: t('interface:base.ProposeCard.cancelBank'),
        action: handleCancelPropose,
        styles: { background: theme.palette.error.light },
      },
      default: {
        text: t('interface:base.ProposeCard.selectBank'),
        action: handleChangePropose,
        styles: {},
      },
      approved: {
        text: t('interface:base.ProposeCard.accept'),
        action: handleConfirmPropose,
        styles: { background: theme.palette.success.main, color: theme.palette.common.white },
      },
    };
    if (canApprove) {
      return mp.approved;
    }
    if (selected) {
      return mp.selected;
    }

    return mp.default;
  }, [propose, selected, canApprove]);

  const renderButton = useMemo(() => {
    return (
      <Button variant='contained' sx={{ width: 1, ...proposeButtonState.styles }} onClick={proposeButtonState.action}>
        {proposeButtonState.text}
      </Button>
    );
  }, [proposeButtonState]);

  const percentColor = useMemo(() => {
    if (!canApprove) {
      return theme.palette.text.primary;
    }
    switch (propose?.status) {
      case EMortgageEntryProposeStatus.approved:
        return theme.palette.success.main;
      default:
        return theme.palette.text.primary;
    }
  }, [propose, canApprove]);

  const iconComponent = propose?.bank as string;

  if (proposeHook.editablePropose === propose.uuid) {
    return <ProposeEditor selected={selected} propose={propose} />;
  }

  // return <ProposeEditor isPendingStatus={status} propose={propose} />

  if (status) {
    return (
      <ProposeCardPending
        uuidPropose={propose.uuid}
        theme={theme}
        bank={propose?.bank}
        icon={MapIcons[iconComponent]}
        selected={selected}
        status={propose?.status}
        onClickPopover={props.onOpenPopover}
      />
    );
  }

  const actionButton = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: 1 }}>
      {renderButton}
      {selected && !canApprove && (
        <Accessible requireRoles={[EUserRole.admin, EUserRole.manager]}>
          <Button
            title={t('interface:base.ProposeCard.finalDecisionConfirmInfo')}
            color='secondary'
            variant='contained'
            onClick={() => {
              proposeHook.managerUpdateDecision({ uuidPropose: propose.uuid, propose });
            }}
          >
            {t('interface:base.ProposeCard.finalDecisionConfirm')}
          </Button>
        </Accessible>
      )}
      <Link
        variant='h5'
        onClick={props.onOpenPopover}
        sx={{ color: theme.palette.common.link, textDecoration: 'none', textAlign: 'center' }}
      >
        {t('interface:base.ProposeCard.discussConsultant')}
      </Link>
    </Box>
  );
  const statusPanel = (
    <ProposeStatusPanel
      sx={{ display: propose?.status !== EMortgageEntryProposeStatus.approved || selected ? 'flex' : 'none' }}
      isSelected={selected}
      theme={theme}
      canApprove={canApprove}
      proposeStatus={propose?.status}
    />
  );
  const accordion = (
    <>
      <Box>{isExpanded && <div dangerouslySetInnerHTML={{ __html: propose?.decision?.additionalConditions || '' }} />}</Box>

      <Link
        variant='h5'
        onClick={handleExpand}
        sx={{
          color: theme.palette.common.link,
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {isExpanded ? t('interface:base.ProposeCard.closeViewMore') : t('interface:base.ProposeCard.viewMore')}
        <SvgIcon htmlColor='inhirent' component={isExpanded ? ArrowUpIcon : ArrowDownIcon} stroke={theme.palette.text.secondary} />
      </Link>
    </>
  );

  const match = !props?.layout ? matches : false;
  return match ? (
    <ProposeCardDesktop
      selected={selected}
      uuid={propose.uuid}
      actionButton={!props.hiddenButtons ? actionButton : <></>}
      statusPanel={statusPanel}
      accordion={accordion}
      bank={propose?.bank}
      percentColor={percentColor}
      decision={propose?.decision}
    />
  ) : (
    <ProposeCardMobile
      actionButton={
        !props.hiddenButtons ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            {renderButton}
            <Button sx={{ padding: '6px', minWidth: '48px' }} onClick={handleExpand} variant='contained' color='secondary'>
              <SvgIcon component={isExpanded ? ArrowUpIcon : ArrowDownIcon} stroke={theme.palette.text.secondary} />
            </Button>
          </Box>
        ) : (
          <div />
        )
      }
      selected={selected}
      uuid={propose.uuid}
      statusPanel={statusPanel}
      accordion={<Box>{isExpanded && <div dangerouslySetInnerHTML={{ __html: propose?.decision?.additionalConditions || '' }} />}</Box>}
      bank={propose?.bank}
      percentColor={percentColor}
      decision={propose?.decision}
    />
  );
}
