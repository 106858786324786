import { Box, Grid } from '@mui/material';
import { useProposeCommon } from 'modules/Propose/context';
import ProposeCard from '../Card';
import { useCallback, useEffect } from 'react';
import { ProposeModalTypes, useProposeModal } from 'components/providers/ProposeModalProvider';
import { supportSend } from 'api/services/support';
import { ESupportReqType } from 'api/services/support.dto';
import WaitingNotice from 'pages/BusinessCredit/Propose/components/WaitingNotice';

export default function ProposeContainer() {
  const proposeContext = useProposeCommon();

  useEffect(() => {
    proposeContext.getProposeList();
  }, []);
  const { handleModal } = useProposeModal();
  const handleOpenPopover = useCallback(() => {
    handleModal?.(ProposeModalTypes.BottomPopover, (status) => {
      if (status) {
        handleModal(ProposeModalTypes.CallbackPropose, (handlerStatus) => {
          if (handlerStatus) {
            supportSend({ reqType: ESupportReqType.call });
            // handleChangePropose(propose);
          }
        });
      }
    });
  }, []);

  if (proposeContext.proposes.length === 0) {
    return <WaitingNotice />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '32px', paddingBottom: '48px', paddingTop: '20px' }}>
      <Grid container spacing={{ xs: '16px' }} alignItems='stretch'>
        {proposeContext.proposes.map((propose) => (
          <Grid item xs={12} lg={3} key={propose.uuid}>
            <ProposeCard {...propose} onOpenPopover={handleOpenPopover} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
