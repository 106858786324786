import { EUserRole } from 'api/services/user.dto';
import { createContext, ReactChild, useContext, useMemo } from 'react';
import { useAuth } from './AuthProvider';

export const RoleAccessContext = createContext<Partial<RoleAccessContextProps>>({
  userRoles: [],
});

interface RoleAccessContextProps {
  userRoles: EUserRole[];
  hasAccess: (roles: EUserRole) => boolean;
  accessible?: boolean;
}

interface AccessibleProps {
  children: ReactChild;
  requireRoles: EUserRole[];
}

export default function RoleAccessProvider({ children }: { children: ReactChild }) {
  const auth = useAuth();
  const roles = auth.user?.roles;
  const storeData = useMemo(() => ({ userRoles: roles }), [roles]);
  return <RoleAccessContext.Provider value={storeData}>{children}</RoleAccessContext.Provider>;
}

/**
 * Accessible  component used to protect interface elements and grant access according to the role defined in requireRoles:[EUserRole.agent,EUserRole.client]
 * @returns
 */
export function Accessible({ children, requireRoles }: AccessibleProps) {
  return (
    <RoleAccessContext.Consumer>
      {({ userRoles }) => {
        const hasRoles = userRoles?.some((x) => requireRoles.includes(x));
        if (hasRoles) return <>{children}</>;
        return null;
      }}
    </RoleAccessContext.Consumer>
  );
}

/**
 * get role accesss context
 * @returns {RoleAccessContextProps}
 */
export function useRoleAccess(proctectedRoles: EUserRole[]) {
  const ctx = useContext(RoleAccessContext);
  ctx.accessible = useMemo(() => ctx.userRoles?.some((x) => proctectedRoles.includes(x)), [proctectedRoles]);
  return ctx;
}
