/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import { ObjectType } from 'utils/iots';

export const ft = (path: Array<string | undefined>) => {
  const [form, ...p] = path;
  return `${form}:${p.join('.')}`;
};

export const getNodeByPath = (node, path = []) => {
  // TODO: Rewrite this shit
  if (!node) return undefined;
  if (path.length === 1 && node.name === path[0]) return node;
  if (path.length > 1 && node.name === path[0]) {
    const [, ...np] = path;
    return getNodeByPath(node.data, np);
  }
  if (node.data) return getNodeByPath(node.data, path);
  if (Array.isArray(node)) {
    const n = node.find(({ name }) => name === path[0]);
    const [, ...np] = path;
    return np.length > 0 ? getNodeByPath(n, np) : n;
  }
  return undefined;
};

/**
 *
 * @param {FormSchemeData} node
 * @param {string[]|undefined} depends
 * @returns {string[]}
 */

export function getAllDependedKeys(node: ObjectType, depends: ObjectType[] = []) {
  if (Array.isArray(node?.data)) {
    depends.push(...node.data.map((n) => getAllDependedKeys(n)).flat());
  }
  if (Array.isArray(node?.settings?.dependsOn)) {
    depends.push(...node.settings.dependsOn.map(({ name }) => name));
  }
  return [...new Set(depends)];
}

export const getAllDependedNodesKeys = (node, depends = [], p = []) => {
  if (Array.isArray(node?.data)) {
    depends.push(...node.data.map((n) => getAllDependedNodesKeys(n, [], [...p, node.name])).flat());
  }
  if (Array.isArray(node?.settings?.dependsOn)) {
    depends.push([...p, node.name].join('.'));
  }
  return [...new Set(depends)];
};
/**
 *
 * @param {FormSchemeData} node
 * @returns {Record<string,any>}
 */
export const buildValues = (node) => {
  if (Array.isArray(node)) return node.reduce((p, c) => ({ ...p, ...buildValues(c) }), {});
  if (node.data) return { [node.name]: buildValues(node.data) };
  return { [node.name]: node.value };
};

export const getObjValues = (obj, path = [], skipUndefined = true, setEmptyAsNull = true) => {
  const values = [];

  if (obj === null) return values;

  if (Array.isArray(obj)) {
    obj.forEach((val, i) => {
      values.push(...getObjValues(val, [...path, String(i)], skipUndefined, setEmptyAsNull));
    });
    return values;
  }

  if (typeof obj === 'object') {
    Object.entries(obj).forEach(([key, val]) => {
      values.push(...getObjValues(val, [...path, key !== null ? String(key) : key], skipUndefined, setEmptyAsNull));
    });
    return values;
  }

  // skipUndefined
  if (typeof obj === 'undefined' && skipUndefined) return values;

  // setEmptyAsNull
  if (obj === '' && setEmptyAsNull) {
    values.push({ key: path.join('.'), val: null });
    return values;
  }

  values.push({ key: path.join('.'), val: obj });

  return values;
};

export const getFlatKeyValue = (arr) => {
  const obj = {};
  arr.forEach(({ key, val }) => (obj[key] = val));
  return obj;
};

export const filterFromValues = (obj, keyStarts) => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (key.startsWith(keyStarts)) result[key] = val;
  });
  return result;
};

export const setNullForDepended = (values, scheme, root: ObjectType[] = []) => {
  let clearedValues = Object.entries(values);
  const dependsNodes = getAllDependedNodesKeys(scheme);

  dependsNodes.forEach((depend) => {
    const p = depend.replace(`${root.join('.')}.`, '').split('.');
    const node = getNodeByPath(scheme, p);
    node?.settings?.dependsOn?.forEach((dependsOn) => {
      if (!dependsOn.requiredValues.includes(values[dependsOn.name])) {
        clearedValues = clearedValues.map(([k, v]) => {
          if (k.startsWith([...root, depend].join('.'))) return [k, null];
          return [k, v];
        });
      }
    });
  });

  return Object.fromEntries(clearedValues);
};
