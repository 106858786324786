import { Alert, Box, Button, Input, Stack, Typography } from '@mui/material';
import { inviteFromAgent } from 'api/services/agent';
import QRCode from 'components/base/QRCode';
import useCopy from 'hooks/useCopy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const hasShareAPI = !!navigator.share;

export default function InviteShort({ role }: { role: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [shortLink, setShortLink] = useState<string>('');
  // const MOCK_INVITE_URL = [import.meta.env.REACT_APP_HOST || `http://${window.location.host}`, 'mock', 'entry', uuid].join('/');
  const [copied, copy, setCopied] = useCopy(shortLink);

  useEffect(() => {
    if (role) {
      handleGetShortLink(role);
    }
  }, [role]);

  const handleGetShortLink = async (role: string) => {
    try {
      const result = await inviteFromAgent(role);
      if (!result.ok) {
        throw new Error('invalid request');
      }
      console.log(result.body);
      setShortLink([`${window.location.protocol}//${window.location.host}`, 's', result.body.url].join('/'));
    } catch (err) {
      return err;
    }
  };

  const handleBtnBackClick = (): void => {
    navigate(-1);
  };

  const handleBtnShareClick = (): void => {
    if (!hasShareAPI) return;
    navigator.share({ url: shortLink });
  };

  const handleLinkInputClick = () => {
    copy();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center', width: 1 }}>
      {copied && (
        <Alert sx={{ position: 'absolute', top: '20px', left: '20px', width: 'calc(100% - 40px)' }} severity='info' variant='filled'>
          {t('interface:page.AgentMortgagePropertyInvitePage.InviteShort.copiedLbl')}
        </Alert>
      )}
      <Typography variant='h3m' textAlign='center'>
        {t(`interface:page.AgentMortgagePropertyInvitePage.InviteShort.roles.${role}`)}
      </Typography>
      <Box alignItems='center' justifyContent='center' display='flex'>
        <QRCode url={shortLink} />
      </Box>
      <Stack gap='8px'>
        <Input variant='shareLink' value={shortLink} onClick={handleLinkInputClick} />
        {hasShareAPI && (
          <Button variant='contained' size='large' onClick={handleBtnShareClick}>
            {t(`interface:page.AgentMortgagePropertyInvitePage.InviteShort.btnShare`)}
          </Button>
        )}
        <Button variant='contained' color='secondary' size='large' onClick={handleBtnBackClick}>
          {t(`interface:page.AgentMortgagePropertyInvitePage.InviteShort.btnBack`)}
        </Button>
      </Stack>
    </Box>
  );
}
