import { Avatar, Box, Chip, CircularProgress, Paper, Typography } from '@mui/material';
import { IPreloadDocument } from 'api/services/experiment/experiment.dto';
import { Fragment } from 'react';
import ImageIcon from '@mui/icons-material/Image';

interface FileQueueProps extends IPreloadDocument {}

export default function FileQueue(props: FileQueueProps) {
  return (
    <Fragment>
      <Paper
        elevation={0}
        sx={{
          background: '#f5f5f5',
          padding: '8px',
          borderRadius: '8px',
          border: `2px solid transparent`,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, gap: '8px', justifyContent: 'flex-start' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', flexGrow: { lg: 1, xs: 0 } }}>
            <Avatar sx={{ borderRadius: 0, zIndex: 0 }}>
              <ImageIcon />
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography sx={{ fontSize: '14px' }}>{props.name}</Typography>
              {/* <Typography sx={{ fontSize: '12px',color:theme.palette.common.softGrey }}>{file.}</Typography> */}
            </Box>
            <Chip icon={<CircularProgress size='20px' />} label={'Загружаем документ...'} variant='outlined' />
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
}
