import { Typography, Button, Container, Grid, Box, Paper, SvgIcon, useTheme, SxProps } from '@mui/material';
import ContainerLayout from 'components/layouts/ContainerLayout';
import WithNotificationHeader from 'components/layouts/WithNotificationHeader';
import { useBankGuarantee } from 'components/providers/BankGuaranteeProvider';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { MapIcons } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/icons';
import { useEffect, useMemo } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import p from 'constants/business.popular.json';
import utils from 'utils';
import SuccessIcon from 'components/Icons/Success';
import { PATH_BUSINESS_CREDIT_ENTRY_COMPANY } from 'routes/paths';

function getMultipleRandom<T>(arr: T[], num: number) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
}

const typographySx: SxProps = { fontSize: { md: '22px', xs: '18px' }, fontWeight: { xs: 500, md: 400 } };

const BankGuaranteeCard = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const mobileCard = () => {
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          borderRadius: '12px',
          height: '100%',
          width: 1,
          justfiyContent: 'space-between',
        }}
        variant='outlined'
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* <Box>{props.statusPanel}</Box> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', width: '100%' }}>
              <SvgIcon component={MapIcons[`${props.bank}`]} sx={{ width: 48, height: 48 }} />
              <Typography variant='h4'>{t(`common:mortgage.banks.${props.bank}`)}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Grid container direction='row'>
              <Grid item xs={6}>
                <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
                  {t('interface:base.ProposeCard.rate')}
                </Typography>
                <Typography sx={{ ...typographySx }} variant='h4'>
                  {utils.format.percent(props.rate)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                  {t('interface:base.ProposeCard.creditDuration')}
                </Typography>
                <Typography variant='h4' sx={typographySx}>
                  {props.entry?.params?.period || ''} мес.
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: 1 }}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('interface:base.ProposeCard.creditAmount')}
              </Typography>
              <Typography variant='h4' sx={typographySx}>
                {utils.format.price(props?.entry?.params?.amount)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 0.2, paddingTop: '12px' }} />
      </Paper>
    );
  };
  const desktopCard = () => (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '24px',
        borderRadius: '12px',
        height: '100%',
        width: 1,
        justfiyContent: 'space-between',
      }}
      variant='outlined'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', flexGrow: 0.7, paddingRight: '24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
            <SvgIcon component={MapIcons[`${props.bank}`]} sx={{ width: 48, height: 48 }} />
            <Typography variant='h4'>{t(`common:mortgage.banks.${props.bank}`)}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Grid container direction='row' spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6} md={4}>
              <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
                {t('interface:base.ProposeCard.rate')}
              </Typography>
              <Typography sx={{ ...typographySx }} variant='h4'>
                {utils.format.percent(props.rate)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('interface:base.ProposeCard.creditDuration')}
              </Typography>
              <Typography sx={{ ...typographySx }} variant='h4'>
                {props.entry?.params?.period || ''} мес.
              </Typography>
            </Grid>

            <Grid item xs={6} md={4}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('interface:base.ProposeCard.creditAmount')}
              </Typography>
              <Typography variant='h4' sx={typographySx}>
                {utils.format.price(props.entry?.params?.amount)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
  return isDesktop ? desktopCard() : mobileCard();
};

export default function BankGuaranteeWelcomePage() {
  const { t } = useTranslation();
  const BankGuaranteeContext = useBankGuarantee();
  const params = useParams();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_COMPANY, params as any));
  };
  useEffect(() => {
    if (params.uuidEntry) {
      BankGuaranteeContext.loadEntryRequest({ uuidEntry: params.uuidEntry! });
    }
  }, []);
  const items = useMemo(() => {
    const r = p.filter((x) => MapIcons[x.bankName]);
    return getMultipleRandom(r, 3);
  }, []);

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntrySignInfoPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntrySignInfoPage.description') }]}
      header={{ xs: <WithNotificationHeader title={'Рассмотрение заявки'} />, lg: <TopMenu /> }}
      maxWidth='xl'
      sx={{ paddingBottom: '20px' }}
    >
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          flexDirection: 'column',

          height: 1,
          gap: '24px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
          <SvgIcon sx={{ fontSize: 40, marginBottom: '5px' }} component={SuccessIcon} />
          <Typography variant='h1b' sx={{ fontSize: { md: '48px', xs: '26px' } }}>
            Заявка создана
          </Typography>
        </Box>

        <Typography variant='h3' sx={{ fontSize: '18px', textAlign: 'left' }}>
          Спасибо, мы получили вашу заявку. Для одобрения потребуется информация о вашей компании и документы по запросу банка.
        </Typography>
        <Typography variant='h3' sx={{ fontSize: '18px', textAlign: 'left' }}>
          Пожалуйста, ответьте на несколько вопросов.
        </Typography>
        {/* Contls */}

        <Typography variant='h3b' sx={{ fontSize: '18px', textAlign: 'left' }}>
          Подходящие предложения
        </Typography>
        <Grid container direction='row' spacing={{ md: 2, xs: 2 }}>
          <Grid item container xs={12} spacing={2}>
            {items.map((item) => (
              <Grid item xs={12}>
                <BankGuaranteeCard {...item} bank={item.bankName} entry={BankGuaranteeContext.entry?.entry} />
              </Grid>
            ))}
          </Grid>
          <Grid item md={12} xs={12}>
            <Button fullWidth variant='contained' size='large' onClick={handleNavigate}>
              Вперед
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
}
