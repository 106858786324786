import { Box, Button, Checkbox, Container, FormControlLabel, SvgIcon, Typography } from '@mui/material';
import IngosIcon from 'components/Icons/Ingos';
import InsuranceAlfaIcon from 'components/Icons/InsuranceAlfa';
import ResoIcon from 'components/Icons/Reso';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function () {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => step + 1);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fadeAnimation = (v: number) => ({
    '@keyframes myAnim': {
      '0%': { opacity: 0, transform: 'translateY(50px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    animation: `myAnim ${v}s ease-in 0s 1 normal forwards`,
    width: 1,
  });
  return (
    <Box sx={{ height: 1, width: 1, marginTop: '40px' }}>
      <Container sx={{ width: 1, height: 1, display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <FormControlLabel
            control={<Checkbox checked={step >= 2} sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
            sx={{ fontSize: '12px', padding: '8px 16px' }}
            label={'Страхование имущества'}
          />
          <FormControlLabel
            control={<Checkbox checked={step >= 3} sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
            sx={{ fontSize: '12px', padding: '8px 16px' }}
            label={t('interface:page.ClientMortgageEntryCreatePage.form.insurance.life')}
          />
          <FormControlLabel
            control={<Checkbox checked={step >= 4} sx={{ width: '16px', height: '16px', marginRight: '16px' }} />}
            sx={{ fontSize: '12px', padding: '8px 16px' }}
            label={t('interface:page.ClientMortgageEntryCreatePage.form.insurance.title')}
          />
        </Box>
        {step >= 5 && (
          <Box sx={fadeAnimation(0.5)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #D2D2CF',
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                <SvgIcon viewBox='0 0 64 64' component={ResoIcon} sx={{ height: '40px', width: '40px' }} />
                <Typography sx={{ fontSize: '14px' }}>РЕСО-Гарантия</Typography>
                <Typography sx={{ fontWeight: 'bold', margin: '0 0 0 auto' }}>31 546 ₽</Typography>
              </Box>
              <Button variant='contained'>Оформить</Button>
            </Box>
          </Box>
        )}

        {step >= 6 && (
          <Box sx={fadeAnimation(0.5)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #D2D2CF',
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' }}>
                <SvgIcon viewBox='0 0 64 64' component={IngosIcon} sx={{ height: '40px', width: '40px' }} />
                <Typography sx={{ fontSize: '14px' }}>Ингосстрах</Typography>
                <Typography sx={{ fontWeight: 'bold', margin: '0 0 0 auto' }}>32 180 ₽</Typography>
              </Box>
              <Button variant='contained'>Оформить</Button>
            </Box>
          </Box>
        )}
        {step >= 7 && (
          <Box sx={fadeAnimation(0.5)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #D2D2CF',
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                <SvgIcon viewBox='0 0 64 64' component={InsuranceAlfaIcon} sx={{ height: '40px', width: '40px' }} />
                <Typography sx={{ fontSize: '14px' }}>АльфаСтрахование</Typography>
                <Typography sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', margin: '0 0 0 auto' }}>33 840 ₽</Typography>
              </Box>
              <Button variant='contained'>Оформить</Button>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
}
