import { Box } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_HOME } from 'routes/paths';
import utils from 'utils';
import RealEstateForm from './RealEstateProvider';

export default function MortgageEntryRealEstatePage() {
  const { t } = useTranslation();
  const { uuidEntry } = useParams();
  const location = useLocation();
  const groupName = location.pathname.split('/').slice(-1).join() as string;
  const breadcrumbs = [
    { title: t('interface:page.MortgageEntryRealEstatePage.breadcrumbs.main'), href: PATH_HOME },
    {
      title: t('interface:page.MortgageEntryRealEstatePage.breadcrumbs.progress'),
      href: utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: uuidEntry }),
    },
    { title: t(`interface:page.MortgageEntryRealEstatePage.title`), activeLink: true },
    { title: t(`interface:page.MortgageEntryRealEstatePage.form.${groupName}.title`), activeLink: true },
  ];
  return (
    <ContainerLayout
      title={t('helmet:MortgageEntryRealEstatePage.title')}
      meta={[{ name: 'description', content: t('helmet:MortgageEntryRealEstatePage.description') }]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.MortgageEntryRealEstatePage.form.${groupName}.title`)}
            subtitle={t(`interface:page.MortgageEntryRealEstatePage.title`)}
          />
        ),
        lg: <TopMenu breadcrumbs={breadcrumbs} />,
      }}
    >
      <RealEstateForm groupName={groupName}>
        <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flexGrow: 0.8, paddingBottom: '20px' }}>
            <Outlet />
          </Box>
          {/* TODO: add handle error */}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, gap: '16px', paddingBottom: '24px', alignSelf: 'flex-end' }}>
            <RealEstateForm.SubmitButton groupName={groupName} />
            <RealEstateForm.SaveButton groupName={groupName} />
          </Box>
        </Box>
      </RealEstateForm>
    </ContainerLayout>
  );
}
