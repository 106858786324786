/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const Spin = createSvgIcon(
  <svg viewBox='0 0 128 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127.5C98.7939 127.5 127 99.2939 127 64.5C127 29.7061 98.7939 1.5 64 1.5C29.2061 1.5 1 29.7061 1 64.5C1 99.2939 29.2061 127.5 64 127.5Z'
      fill='#FEFCE8'
      stroke='#F8E11A'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M78.5302 55.8162H93M93 55.8162V41.0874M93 55.8162L82.7504 45.4139C80.2894 42.9051 77.3665 40.9148 74.1492 39.5569C70.9318 38.199 67.4829 37.5 63.9999 37.5C60.5169 37.5 57.0681 38.199 53.8507 39.5569C50.6333 40.9148 47.7105 42.9051 45.2495 45.4139M49.4698 73.1839H35M35 73.1839V87.9127M35 73.1839L45.2495 83.5861C47.7105 86.0949 50.6333 88.0852 53.8507 89.4431C57.0681 90.801 60.5169 91.5 63.9999 91.5C67.4829 91.5 70.9318 90.801 74.1492 89.4431C77.3665 88.0852 80.2894 86.0949 82.7504 83.5861'
      stroke='#F8E11A'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>,
  'Spin',
);

export default Spin;
