import { Skeleton, SkeletonProps } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends SkeletonProps {
  children: ReactNode;
  loading?: boolean;
}

export default function WithSkeleton({ children, loading, ...other }: Props) {
  if (loading) {
    return <Skeleton {...other} />;
  }
  return <>{children}</>;
}
