import { Box, Typography } from '@mui/material';
import { RadioWrapper } from 'components/base/RadioWrapper';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface RadioChoiceProps {
  prefix: string;
  label: string;
  row?: boolean;
  required?: boolean;
  infoIcon?: boolean;
  defaultValue?: boolean;
}

export const RadioChoice: FC<RadioChoiceProps> = ({ prefix, label, row = true, required = false, infoIcon = false, defaultValue }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <RadioWrapper
      defaultValue={defaultValue}
      required={required}
      row={row}
      name={label}
      control={control}
      infoTitle={
        infoIcon ? (
          <Box sx={{ padding: '0 20px' }}>
            <Typography variant='h2b'>{t(`${prefix}.${label}.title`)}</Typography>
          </Box>
        ) : (
          ''
        )
      }
      infoDescription={
        infoIcon ? (
          <Typography variant='h5' sx={{ fontSize: '16px', lineHeight: '150%' }}>
            {t(`${prefix}.${label}.helperText`)}
          </Typography>
        ) : (
          ''
        )
      }
      options={[
        {
          label: t(`base.RadioChoice.yes`),
          value: true,
        },
        {
          label: t(`base.RadioChoice.no`),
          value: false,
        },
      ]}
      label={t(`${prefix}.${label}.title`)}
      data-testid={label}
    />
  );
};
