import { Box, Button, Typography, useTheme } from '@mui/material';
import { LS_INVITE_LINK } from 'api/http';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useInviteLogic from 'hooks/logic/useInviteLogic';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_AUTH_INVITE_REGISTER, PATH_AUTH_SIGNIN, PATH_HOME, PATH_SHORTLINK_ACCEPT } from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const ShortLinkPage = () => {
  const { shortlinkData, shortlink } = useInviteLogic();

  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  // // TODO: Send errors to sentry
  useEffect(() => {
    if (!shortlink) {
      navigate(PATH_HOME);
    }

    sessionStorage.setItem(LS_INVITE_LINK, shortlink || '');

    if (auth.isAuth) {
      navigate(utils.url.urlReplace(PATH_SHORTLINK_ACCEPT, { shortlink }));
    }
  }, [shortlink, auth.isAuth]);

  //TODO: change traslations

  const roleTranslation = useMemo(() => {
    const translated = (shortlinkData?.data as ObjectType)?.role;
    if (!translated) {
      return '';
    }
    return `common:mortgage.roles.${translated}`;
  }, [shortlinkData]);

  const registerUrl = utils.url.urlReplace(PATH_AUTH_INVITE_REGISTER, { shortlink: shortlinkData?.url });

  const loginUrl = utils.url.urlReplace(PATH_AUTH_SIGNIN, {});
  return (
    <Page
      sx={{ padding: 0, height: 1, width: 1 }}
      hideHeader={true}
      title={t('helmet:ShortLinkPage.title')}
      meta={[{ name: 'description', content: t('helmet:ShortLinkPage.description') }]}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexDirection: 'column',

          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '8px',
          }}
        >
          <Typography variant='h3b'>{utils.format.nameWithInitials(shortlinkData?.data.inviter)}</Typography>
          <Typography variant='h3'>{utils.format.hidePhoneNumber(shortlinkData?.data.inviter?.phone)}</Typography>
          <Typography variant='h4'>{t('interface:page.ShortLink.description')}</Typography>
          <Button size='small' variant='contained'>
            {t(roleTranslation, { defaultValue: 's' })}
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
            justifyContent: 'center',
            width: { xs: 1, sm: 500 },
            paddingBottom: '42px',
            justifySelf: 'flex-end',
          }}
        >
          <Typography variant='h4b' component='span' sx={{ textAlign: 'center' }}>
            {t('interface:page.ShortLink.supportText')}
          </Typography>
          <Button
            sx={{ height: '56px' }}
            variant='contained'
            component={Link}
            to={loginUrl}
            disabled={!shortlinkData?.url}
            data-testid='signinBtn'
          >
            {t('interface:page.HomeGuestPage.signinBtn')}
          </Button>
          <Button
            sx={{ backgroundColor: theme.palette.secondary.main, height: '56px' }}
            variant='contained'
            disabled={!shortlinkData?.url}
            data-testid='registerBtn'
            component={Link}
            to={registerUrl}
          >
            {t('interface:page.HomeGuestPage.registerBtn')}
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default ShortLinkPage;
