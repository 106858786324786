import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Button, CircularProgress, Fade, Grid, IconButton, SvgIcon, Typography, useTheme } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { userUploadFile } from 'api/services/user';
import DeleteIcon from 'components/Icons/Delete';
import DownloadIcon from 'components/Icons/Download';
import SuccIcon from 'components/Icons/Succ';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ft } from '../FormGenerator/utils';
import { EUserRole } from 'api/services/user.dto';
import { useAuth } from 'components/providers/AuthProvider';

const { REACT_APP_STORAGE_URL: STORAGE_URL } = import.meta.env;

interface UploaderProps {
  fileLink?: string;
  labelUploaded?: string;
  allowDelete?: boolean;
  allowDownload?: boolean;
  path?: string[];
  allowInfo?: boolean;
  infoDescription?: string;
  'data-testid'?: string;
  onChange?: (files?: string[]) => void;
  disabled?: boolean;
}

export default function Uploader(props: UploaderProps) {
  const { fileLink, allowDelete, allowDownload, 'data-testid': testId, onChange, infoDescription, allowInfo, path } = props;
  const auth = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<string[]>([]);
  const [isShowInfo, showInfo] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (fileLink) setFiles(fileLink?.split(';'));
  }, [fileLink]);

  const handleDelete = (fileId: number) => {
    setFiles(files.filter((_, id) => id !== fileId));
    if (onChange) onChange(files.filter((_, id) => id !== fileId));
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files.item(0);
    if (!file) {
      return;
    }
    setError(null);
    setLoading(true);

    try {
      const res = await userUploadFile({ resourceType: 'artifact', file });
      if (res.ok) {
        const body = res.body as { link: string };
        const link = `${STORAGE_URL}${body.link}`;
        setFiles([...files, link]);
        if (onChange) onChange([...files, link]);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          pointerEvents: props.disabled ? 'none' : 'all',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {path && <Typography variant='h4m'>{t(`${ft(path as string[])}.title`)}</Typography>}

        {allowInfo && (
          <IconButton
            sx={{ color: '#DFC801' }}
            onClick={() => {
              showInfo(!isShowInfo);
            }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        )}
      </Box>
      {isShowInfo && (
        <Fade in={isShowInfo} timeout={1000}>
          <Grid item md={12} sx={{ textAlign: 'justify' }}>
            <Trans i18nKey={`profile:${infoDescription}`}>
              <Typography sx={{ fontWeight: 'bold', whiteSpace: 'pre-line' }} />
            </Trans>
          </Grid>
        </Fade>
      )}
      <Button fullWidth disabled={loading || props.disabled} variant='contained' color='secondary' size='large' component='label'>
        {loading ? <CircularProgress size={24} /> : t('interface:base.CommonButtonUpload.uploadFiles')}
        <input data-testid={testId} onChange={handleUpload} type='file' accept='image/*' hidden />
      </Button>

      {error && (
        <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
          {t(`errors:${error}`)}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {files?.map((fl, idx) => (
          <Box
            key={fl}
            sx={{
              display: 'flex',
              alignItems: 'center',
              minHeight: '56px',
              justifyContent: 'space-between',
              padding: '15px',
              border: '1px solid',
              backgroundColor: theme.palette.secondary.main,
              borderColor: theme.palette.primary.dark,
              borderRadius: '8px',
            }}
          >
            <Avatar
              variant="rounded"
              src={fl.replace('orig', 'compressed').replace(/(png|jpeg)/, 'jpg')}
              sx={auth.hasRole(EUserRole.manager) ? { width: 150, height: 150 } : { width: 56, height: 56 }}
            >
              <ImageIcon />
            </Avatar>
            <Typography>{t('interface:base.UploadButton.labelUploaded')}</Typography>
            <Box>
              {allowDownload && (
                <IconButton href={fl} target='_blank'>
                  <SvgIcon sx={{ height: '16px', width: '16px' }} component={DownloadIcon} stroke={theme.palette.primary.dark} />
                </IconButton>
              )}
              {allowDelete && (
                <IconButton
                  onClick={() => {
                    handleDelete(idx);
                  }}
                >
                  <SvgIcon sx={{ height: '10px', width: '10px' }} component={DeleteIcon} stroke={theme.palette.text.secondary} />
                </IconButton>
              )}
              {!allowDownload && !allowDelete && (
                <SvgIcon sx={{ height: '16px', width: '16px' }} component={SuccIcon} stroke={theme.palette.primary.dark} />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
