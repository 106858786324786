import { LS_SIGN_CONTEXT } from 'api/http';
import SuccessIcon from 'components/Icons/SuccessLigth';
import NoticeScreen from 'components/base/NoticeScreen';
import Page from 'components/layouts/Page';
import useBroadcastState from 'hooks/useBroadcastState';
import useNavigateParams from 'hooks/useNavigateParams';
import { get } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, PATH_HOME } from 'routes/paths';

const GosuslugiPage = () => {
  const { t } = useTranslation();
  const [, sendBroadcast] = useBroadcastState('ls-esea-key');
  const navigate = useNavigateParams();

  const entrySuccessProps = {
    successIcon: SuccessIcon,
    successTitle: t('interface:page.GosuslugiPage.entrySuccess.title'),
    successSubtitle: t('interface:page.GosuslugiPage.entrySuccess.subtitle'),
    successBtnText: t('interface:page.GosuslugiPage.entrySuccess.textBtn'),
  };

  const [searchParams] = useSearchParams();

  const uuidEntry = useMemo(() => {
    const ctx = localStorage.getItem(LS_SIGN_CONTEXT);

    if (ctx) {
      const data = JSON.parse(ctx as string);

      return get(data, 'uuidEntry');
    }
    return;
  }, []);

  useEffect(() => {
    const gosCode = searchParams.get('code');
    if (gosCode) {
      // userEsiaFill(gosCode);
      sendBroadcast({ eseaToken: gosCode });
    }
  }, [searchParams]);

  const handleNavigate = () => {
    if (uuidEntry) {
      navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, { params: { uuid: uuidEntry }, query: { step: 'signByEsia' } });
    } else {
      navigate(PATH_HOME);
    }
  };

  return (
    <Page
      title={t('helmet:GosuslugiPage.title')}
      hideHeader={true}
      meta={[{ name: 'description', content: t('helmet:GosuslugiPage.description') }]}
      sx={{ width: 1, height: 1 }}
    >
      <NoticeScreen {...entrySuccessProps} onBtnClick={handleNavigate} />
    </Page>
  );
};

export default GosuslugiPage;
