import { useQuery } from '@tanstack/react-query';

import { entryProgress } from '../mortgage';

export function useGetProgressQuery(uuidEntry?: string, ...dependencies: string[]) {
  return useQuery(
    ['progress', uuidEntry, ...dependencies],
    async () => {
      const data = await entryProgress(uuidEntry as string);
      return data.body;
    },
    {
      enabled: !!uuidEntry,
    },
  );
}
