import { Box, Container, SvgIcon, Typography } from '@mui/material';
import CloseIcon from 'components/Icons/Close';
import PropTypes from 'prop-types';

const BottomPopup = ({ visible, onCloseClick, title, body }) => {
  if (!visible) return null;

  // TODO: Refactor markup

  return (
    <>
      <Box
        sx={{ position: 'fixed', zIndex: 2, left: 0, top: 0, right: 0, height: 1, width: 1, background: '#000000', opacity: '0.5' }}
        onClick={onCloseClick}
      />
      <Container
        sx={{
          background: 'white',
          width: 1,
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0.3,
          maxHeight: 0.8,
          overflowY: 'scroll',
          padding: '24px',
          borderRadius: '8px',
          zIndex: 3,
          left: 0,
          bottom: 0,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '24px' }}>
          <Typography variant={'h2b'}>{title}</Typography>
          <Box>
            <SvgIcon component={CloseIcon} sx={{ width: '15px', height: '15px' }} onClick={onCloseClick} />
          </Box>
        </Box>
        <Box>
          <Typography variant={'span'} sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: body }} />
        </Box>
      </Container>
    </>
  );
};

BottomPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
};

export default BottomPopup;
