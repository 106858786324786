import { Grid, TextField, Typography } from '@mui/material';
import { IncomesFormProps } from './types';

export default function SelfEmployee(_props: IncomesFormProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5m">Необходимая информация</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth variant='standard' label={'Сфера деятельности компании'} />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth variant='standard' label={'Тип документа, подтверждающего доход'} />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth variant='standard' label={'Ежемесячный доход (После уплаты налогов)'} />
      </Grid>
    </Grid>
  );
}
