import { useRegisterLogic } from 'hooks/logic/useRegisterLogic';

import AuthRegisterForm from './components/Form/AuthRegisterForm';
import AuthRegisterTemplate from './template/AuthRegisterTemplate';

const AuthRegisterPage = () => {
  const logic = useRegisterLogic();

  return (
    <AuthRegisterTemplate>
      <AuthRegisterForm {...logic} />
    </AuthRegisterTemplate>
  );
};

export default AuthRegisterPage;
