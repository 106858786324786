import { RealEstateEntity, ECRealEstateDocument } from 'api/services/realEstate/realEstate.interface';
import { RealEstateFormSchema } from 'api/services/realEstate/realEstate.schema';
import { set } from 'lodash';

export class RealEstateUtils {
  static serialize(payload: RealEstateEntity): Partial<RealEstateFormSchema> {
    const result: any = {
      ...payload,
    };
    if (payload.documents && payload.documents.length > 0) {
      result.documents = payload?.documents?.reduce((prev, cur) => {
        return { ...prev, [String(cur.type)]: cur };
      }, {}) as Record<string, ECRealEstateDocument>;
    }
    return result;
  }

  static deserialize(payload: RealEstateFormSchema): Partial<RealEstateEntity> {
    const updatedSchema: RealEstateEntity = JSON.parse(JSON.stringify(payload, (_, v) => (!v ? undefined : v))) as RealEstateEntity;
    if (payload.documents) {
      const documents = Object.values(payload.documents) as ECRealEstateDocument[];
      set(
        updatedSchema,
        'documents',
        documents?.filter((x) => !!x),
      );
    }
    return updatedSchema;
  }
}
