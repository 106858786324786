import { Box, Breadcrumbs, Link, Typography, useTheme } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import BaseInsuranceListSearchAndFilter from 'components/base/Insurance/List/SearchAndFilter';
import BaseInsuranceListSorting from 'components/base/Insurance/List/Sorting';
import StatusDot from 'components/base/StatusDot';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';
import useTypedParams from 'hooks/useTypedParams';
import { PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, PATH_BUSINESS_CREDIT_PROPOSE, PATH_HOME } from 'routes/paths';
import utils from 'utils';
import BankGuaranteeCard from './components/BankGarant';
import ProposeStatusPanel from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeStatusPanel';
import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';

export default function BankGuaranteeProposePage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { uuidEntry } = useTypedParams<typeof PATH_BUSINESS_CREDIT_PROPOSE>();
  const loading = false;
  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageProposePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageProposePage.description') }]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.ClientMortgageProposePage.${loading ? 'loadingLabel' : 'title'}`)}
            subtitle={t(`interface:page.ClientMortgageProposePage.${loading ? 'loadingLabel' : 'subtitle'}`)}
          />
        ),
        lg: <TopMenu />,
      }}
      maxWidth='xl'
      sx={{ height: 1, gap: '16px', paddingBottom: '20px' }}
    >
      <Box sx={{ display: { xs: 'none', lg: 'flex' }, flexDirection: 'row', gap: '20px' }}>
        <Breadcrumbs separator={<StatusDot color='#D2D2CF' sx={{ width: '4px', height: '4px' }} />} aria-label='breadcrumb'>
          <Link sx={{ color: theme.palette.common.link }} underline='none' href={PATH_HOME}>
            {t('interface:page.ClientMortgageProposePage.breadcrumbs.main')}
          </Link>
          <Link
            sx={{ color: theme.palette.common.link }}
            underline='none'
            href={utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, { uuidEntry })}
          >
            {t('interface:page.ClientMortgageProposePage.breadcrumbs.progress')}
          </Link>
          <Link sx={{ color: theme.palette.text.secondary, userSelect: 'none' }} underline='none'>
            {t('interface:page.ClientMortgageProposePage.breadcrumbs.selectBank')}
          </Link>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: { xs: 'flex-start', lg: 'space-between' },
          alignItems: 'center',
          width: '100%',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '24px',
            width: { xs: 1, lg: 0.7 },
            marginTop: '16px',
          }}
        >
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <Typography component='span' variant='h1b'>
              {t('interface:page.ClientMortgageProposePage.title')}
            </Typography>
          </Box>
          <BaseInsuranceListSearchAndFilter sx={{ marginTop: 0, width: { lg: '361px', xs: 1 } }} NoFilter />
        </Box>

        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <BaseInsuranceListSorting sx={{ marginTop: 0 }} title={t('interface:page.ClientMortgageProposePage.sortByRate')} />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <BaseInsuranceListSorting title={t('interface:page.ClientMortgageProposePage.sortByRate')} />
      </Box>

      <Box sx={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <BankGuaranteeCard
          bank={'alfa'}
          declined
          statusPanel={<ProposeStatusPanel theme={theme} proposeStatus={EMortgageEntryProposeStatus.declined} />}
        />
        {/* <WaitingNotice /> */}
        <BankGuaranteeCard
          bank={'sberbank'}
          statusPanel={<ProposeStatusPanel theme={theme} proposeStatus={EMortgageEntryProposeStatus.approved} />}
        />
      </Box>
    </ContainerLayout>
  );
}
