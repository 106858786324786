import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';

const blacklist = [
  EMortgageEntryProposeStatus.declined,
  EMortgageEntryProposeStatus.failed,
  EMortgageEntryProposeStatus.pending,
  EMortgageEntryProposeStatus.withoutSuitablePrograms,
  EMortgageEntryProposeStatus.reworkRequired,
];

export function isPendingStatus(status: EMortgageEntryProposeStatus) {
  return blacklist.includes(status);
}

export function mapStatus(status?: EMortgageEntryProposeStatus, selected?: boolean) {
  if (selected) {
    return 'interface:base.ProposeCard.proposeStatus.selected';
  }
  return [`interface:base.ProposeCard.proposeStatus.${status}`, 'interface:base.ProposeCard.proposeStatus.pending'];
}

export function mapStatusColor({
  status,
  selectionStatus,
  finalDecision,
}: {
  status?: EMortgageEntryProposeStatus;
  selectionStatus?: boolean;
  finalDecision?: boolean;
}): { color: string; backgroundColor: string } {
  switch (status) {
    case EMortgageEntryProposeStatus.approved: {
      if (finalDecision) {
        return { color: '#31C918', backgroundColor: '#EAFAE8' };
      }
      if (selectionStatus) {
        return {
          color: '#0C0B02',
          backgroundColor: '#F8E11A',
        };
      }
      return {
        color: '#0C0B02',
        backgroundColor: '#F8E11A',
      };
    }
    case EMortgageEntryProposeStatus.declined:
      return {
        backgroundColor: '#FFEAEA',
        color: '#E81E1E',
      };
    case EMortgageEntryProposeStatus.withoutSuitablePrograms:
      return {
        backgroundColor: '#F5F5F5',
        color: '#787770',
      };

    default:
      return { backgroundColor: '#FFF5E3', color: '#F4A611' };
  }
}
