import { Box, Button, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import Carousel from 'components/base/Carousel';
import ContainerLayout from 'components/layouts/ContainerLayout';
import WithNotificationHeader from 'components/layouts/WithNotificationHeader';
import useSupportModal from 'components/providers/SupportProvider';
import useNavigateParams from 'hooks/useNavigateParams';
import useSupportChat from 'hooks/useSupportChat';
import Calculator from 'pages/Client/Home/components/Calculator';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PATH_AGENT_MORTGAGE_PROPERTY_CLIENTS,
  PATH_AGENT_MORTGAGE_PROPERTY_INVITE,
  PATH_CLIENT_MORTGAGE_REMOTE_CONTENT,
  PATH_USER,
} from 'routes/paths';
import utils from 'utils';

import ClientCard from './components/ClientCard';
import { useAgentClients } from 'components/providers/ClientProvider';

const CONTENT_PAGE_ABOUT = 'agent-about-app';
const CONTENT_PAGE_GUIDE = 'mortgage-guide';

const MORTGAGE_MNTH_RATE = 650;

const AgentMortgageHome = () => {
  const supportRequest = useSupportModal();

  const { clients, clientNavigateProgress } = useAgentClients();
  const supportChat = useSupportChat();
  const { t } = useTranslation();
  const navigate = useNavigateParams();
  const theme = useTheme();

  const [monthlyPayment, setMonthlyPayment] = useState<number>(0);
  const [monthPercent, setMonthPercent] = useState<number>(0);

  const handleGuideClick = () => navigate(PATH_CLIENT_MORTGAGE_REMOTE_CONTENT.replace(':urlname', CONTENT_PAGE_GUIDE));
  const handleAboutClick = () => navigate(PATH_CLIENT_MORTGAGE_REMOTE_CONTENT.replace(':urlname', CONTENT_PAGE_ABOUT));

  const handleOnCalculate = (amount: number, rate?: number): void => {
    setMonthlyPayment(amount);
    if (rate) setMonthPercent(rate);
  };

  const handleOpenSupportChat = () => {
    supportChat.open();
  };

  const handleClickProgress = async (params: { uuidEntry: string }) => {
    //@ts-ignore //TODO: fix after PR
    clientNavigateProgress({ entry: { uuid: params.uuidEntry! } });
    // return requestEntryUUID({ uuidClient, uuidUser });
  };

  const handleViewAll = () => {
    navigate(PATH_AGENT_MORTGAGE_PROPERTY_CLIENTS);
  };

  const handleAddClient = () => {
    navigate(PATH_AGENT_MORTGAGE_PROPERTY_INVITE);
  };

  useEffect(() => {}, []);

  return (
    <ContainerLayout
      title={t('helmet:AgentMortgageHome.title')}
      meta={[{ name: 'description', content: t('helmet:AgentMortgageHome.description') }]}
      header={{
        lg: (
          <TopMenu
            onClickUser={() => {
              navigate(PATH_USER);
            }}
          />
        ),
        xs: <WithNotificationHeader title={t('helmet:AgentMortgageHome.title')} />,
      }}
      maxWidth='xl'
      sx={{ overflowX: 'hidden', marginBottom: { xs: '0' } }}
    >
      <Grid container spacing={{ xs: '8px', lg: '32px' }}>
        {/* Guide */}
        <Grid item xs={6} lg={2} sx={{ display: { xs: 'block', lg: 'none' }, order: 1 }}>
          <Paper
            onClick={handleGuideClick}
            elevation={0}
            sx={{
              cursor: 'pointer',
              padding: '16px',
              background: theme.palette.secondary.main,
              minHeight: '124px',
              borderRadius: '16px',
              backgroundImage: `url(/img/home/client/guide-bg.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right bottom',
            }}
          >
            {/* TODO: lineHeight -> Problems with typography theme override */}
            <Typography variant={'h4b'} sx={{ lineHeight: '130%' }}>
              {t(`interface:page.AgentMortgageHome.btnMortgageGuide`)}
            </Typography>
          </Paper>
        </Grid>
        {/* About */}
        <Grid item xs={6} lg={2} sx={{ display: { xs: 'block', lg: 'none' }, order: 2 }}>
          <Paper
            onClick={handleAboutClick}
            elevation={0}
            sx={{
              cursor: 'pointer',
              padding: '16px',
              background: theme.palette.secondary.main,
              minHeight: '124px',
              borderRadius: '16px',
              backgroundImage: `url(/img/home/client/about-bg.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right bottom',
            }}
          >
            {/* TODO: lineHeight -> Problems with typography theme override */}
            <Typography variant={'h4b'} sx={{ lineHeight: '130%' }}>
              {t(`interface:page.AgentMortgageHome.btnAboutApp`)}
            </Typography>
          </Paper>
        </Grid>
        {/* Support */}

        {/* My entry */}
        <Grid item sx={{ order: { xs: 4, lg: 3 }, display: 'flex', flexDirection: 'column', gap: '16px' }} xs={12} lg={6}>
          <Box sx={{ order: { xs: 2, lg: 1 } }}>
            <ClientCard
              onAddClient={handleAddClient}
              onViewAll={handleViewAll}
              onClickProgress={handleClickProgress}
              count={clients?.length}
              clients={clients?.slice(0, 3)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={6} sx={{ order: 4 }}>
          <Paper
            elevation={0}
            sx={{
              cursor: 'pointer',
              padding: { xs: '16px', lg: '24px' },
              background: theme.palette.common.white,
              border: '1px solid #D2D2CF',
              borderRadius: '16px',
            }}
          >
            {/* TODO: lineHeight -> Problems with typography theme override */}
            <Typography variant={'h3b'} sx={{ lineHeight: '130%', fontSize: { lg: '26px', xs: '22px' } }}>
              {t(`interface:page.AgentMortgageHome.blkCalculator`)}
            </Typography>
            <Calculator percent={MORTGAGE_MNTH_RATE} onCalculate={handleOnCalculate} />
          </Paper>
          <Box sx={{ display: { lg: 'inline-flex', xs: 'none' }, gap: '8px', width: '100%', paddingTop: '16px' }}>
            <Paper
              elevation={0}
              sx={{
                cursor: 'pointer',
                padding: '16px',
                background: theme.palette.secondary.main,
                width: '100%',
                borderRadius: '16px',
              }}
            >
              <Stack direction='row'>
                <div>
                  <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
                    {t('interface:page.AgentMortgageHome.lblMonthlyPayment')}
                  </Typography>
                  <Typography variant={'h2b'} sx={{ lineHeight: '130%' }}>
                    {monthlyPayment > 0
                      ? t('interface:page.AgentMortgageHome.lblFrom', { amount: utils.format.price(Math.round(monthlyPayment / 100)) })
                      : '—'}
                  </Typography>
                </div>
              </Stack>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                cursor: 'pointer',
                padding: '16px',
                background: theme.palette.secondary.main,
                borderRadius: '16px',
                width: '100%',
              }}
            >
              <Stack>
                <div style={{ padding: '0 15px' }}>
                  <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
                    {t('interface:page.AgentMortgageHome.lblRate')}
                  </Typography>
                  <Typography variant={'h2b'} sx={{ lineHeight: '130%' }}>
                    {monthlyPayment > 0
                      ? t('interface:page.AgentMortgageHome.lblFrom', { amount: utils.format.percent(monthPercent) })
                      : '—'}
                  </Typography>
                </div>
              </Stack>
            </Paper>
          </Box>
          <Box sx={{ display: { xs: 'block', lg: 'none' }, gap: '8px', width: '100%', paddingTop: '16px' }}>
            <Paper
              elevation={0}
              sx={{
                cursor: 'pointer',
                padding: '16px',
                background: theme.palette.secondary.main,
                width: '100%',
                borderRadius: '16px',
              }}
            >
              <Stack direction='row'>
                <div>
                  <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
                    {t('interface:page.AgentMortgageHome.lblMonthlyPayment')}
                  </Typography>
                  <Typography variant={'h2b'} sx={{ lineHeight: '130%' }}>
                    {monthlyPayment > 0
                      ? t('interface:page.AgentMortgageHome.lblFrom', { amount: utils.format.price(Math.round(monthlyPayment / 100)) })
                      : '—'}
                  </Typography>
                </div>
                <div style={{ padding: '0 15px' }}>
                  <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
                    {t('interface:page.AgentMortgageHome.lblRate')}
                  </Typography>
                  <Typography variant={'h2b'} sx={{ lineHeight: '130%' }}>
                    {monthlyPayment > 0
                      ? t('interface:page.AgentMortgageHome.lblFrom', { amount: utils.format.percent(monthPercent) })
                      : '—'}
                  </Typography>
                </div>
              </Stack>
            </Paper>
          </Box>
          <Box sx={{ overflow: 'hidden', paddingTop: '16px' }}>
            <Carousel />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: { sx: 'flex', lg: 'none' }, flexDirection: 'column', gap: '8px', paddingBottom: '40px' }}>
        {/* Support btn */}
        <Button
          fullWidth
          sx={{ display: { sx: 'block', lg: 'none' }, marginTop: '16px' }}
          onClick={supportRequest.openModal}
          variant='contained'
          size='large'
          data-testid={'btnGetConsultation'}
        >
          {t('interface:page.AgentMortgageHome.btnGetConsultation')}
        </Button>
        <Button
          fullWidth
          sx={{ display: { sx: 'block', lg: 'none' }, marginTop: '16px', background: theme.palette.secondary.main }}
          onClick={handleOpenSupportChat}
          size='large'
          data-testid={'btnGetConsultation'}
        >
          {t('interface:page.AgentMortgageHome.writeSupport')}
        </Button>
      </Box>
    </ContainerLayout>
  );
};

export default AgentMortgageHome;
