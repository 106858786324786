/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const UserIcon = createSvgIcon(
  <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='40' height='40' rx='20' fill='#F5F5F5' />
    <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' stroke='#D2D2CF' strokeOpacity='0.3' />
    <path
      d='M9.56679 28.7503C9.42891 28.9896 9.51109 29.2953 9.75035 29.4332C9.9896 29.5711 10.2953 29.4889 10.4332 29.2497L9.56679 28.7503ZM29.5668 29.2497C29.7047 29.4889 30.0104 29.5711 30.2497 29.4332C30.4889 29.2953 30.5711 28.9896 30.4332 28.7503L29.5668 29.2497ZM26.0979 16.6087C26.0979 19.9833 23.367 22.7175 20 22.7175V23.7175C23.9209 23.7175 27.0979 20.534 27.0979 16.6087H26.0979ZM20 22.7175C16.633 22.7175 13.9021 19.9833 13.9021 16.6087H12.9021C12.9021 20.534 16.0791 23.7175 20 23.7175V22.7175ZM13.9021 16.6087C13.9021 13.2342 16.633 10.5 20 10.5V9.5C16.0791 9.5 12.9021 12.6835 12.9021 16.6087H13.9021ZM20 10.5C23.367 10.5 26.0979 13.2342 26.0979 16.6087H27.0979C27.0979 12.6835 23.9209 9.5 20 9.5V10.5ZM10.4332 29.2497C11.4028 27.5672 12.7973 26.1702 14.4767 25.199L13.976 24.3333C12.1446 25.3926 10.6239 26.916 9.56679 28.7503L10.4332 29.2497ZM14.4767 25.199C16.156 24.2277 18.0609 23.7164 20 23.7164V22.7164C17.8851 22.7164 15.8075 23.2741 13.976 24.3333L14.4767 25.199ZM20 23.7164C21.9391 23.7164 23.844 24.2277 25.5233 25.199L26.024 24.3333C24.1925 23.2741 22.1149 22.7164 20 22.7164V23.7164ZM25.5233 25.199C27.2027 26.1702 28.5972 27.5672 29.5668 29.2497L30.4332 28.7503C29.3761 26.916 27.8554 25.3926 26.024 24.3333L25.5233 25.199Z'
      fill='#D2D2CF'
    />
  </svg>,
  'UserIcon',
);

export default UserIcon;
