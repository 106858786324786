import { Box, Input } from '@mui/material';
import TextMask from 'components/base/Form/MaskInput';
import PropTypes from 'prop-types';
import React from 'react';

const SigninPhoneCodeInput = ({ onChange, value }) => {
  // TODO: I'm not sure about input & svg sizing
  // TODO: Colors from theme
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 1 }}>
      <Input
        variant='signin-phone'
        sx={{ width: '228px', marginBottom: '-16px', letterSpacing: '14px', fontKerning: 'none' }}
        value={value}
        onChange={onChange}
        inputComponent={TextMask}
        inputProps={{
          inputMode: 'numeric',
          mask: '0 0 0 0',
          overwrite: true,
          autoFocus: true,
          'data-testid': 'signin-phone-code',
        }}
      />
      <svg style={{ width: '208px', marginLeft: '-20px' }} height='2' viewBox='0 0 208 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M28,1 L0,1' stroke={value.length > 0 ? '#F8E11A' : '#B9B9B2'} />
        <path d='M88,1 L60,1' stroke={value.length > 2 ? '#F8E11A' : '#B9B9B2'} />
        <path d='M148,1 L120,1' stroke={value.length > 4 ? '#F8E11A' : '#B9B9B2'} />
        <path d='M208,1 L180,1' stroke={value.length > 6 ? '#F8E11A' : '#B9B9B2'} />
      </svg>
    </Box>
  );
};

SigninPhoneCodeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SigninPhoneCodeInput;
