import { Button } from '@mui/material';
import { getPolicy } from 'api/services/agent';
import BaseLinkRouter from 'components/base/Link/Router';
import Page from 'components/layouts/Page';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY_ORDER_SHARE, PATH_AGENT_INSURANCE_PROPERTY_POLICY } from 'routes/paths';
import { nameWithInitials } from 'utils/format/format';

import InsurancePoliciesDetails from './components/Details';

const AgentInsurancePropertyPolicyDetailsPage = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { data: details, isSuccess } = useFetch(() => getPolicy(uuid)) ?? {};

  const { client, product, status, policyNumber, createdAt } = details ?? {};

  const { data } = product ?? {};
  const { bounty, price, company, perils, condition } = data ?? {};

  const hasOnlinePayment = () => {
    return details?.purchase?.type === 'onlineLink';
  };

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyPolicyDetailsPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyPolicyDetailsPage.description') }]}
      header={{
        type: 'backButton',
        title: nameWithInitials(client ?? {}, t('common:noName')),
        subtitle: t('interface:page.AgentInsurancePropertyPolicyDetailsPage.headerTitle'),
        backRoute: PATH_AGENT_INSURANCE_PROPERTY_POLICY,
      }}
      sx={{ paddingBottom: '96px' }}
    >
      {isSuccess && (
        <>
          <InsurancePoliciesDetails
            bounty={+bounty}
            client={client}
            price={price}
            perils={perils}
            policyNumber={policyNumber}
            status={status}
            company={company}
            condition={condition}
            createdAt={+createdAt}
          />
          {hasOnlinePayment() && (
            <div style={{ position: 'fixed', left: 0, bottom: '20px', width: '100%', padding: '0 20px' }}>
              <Button
                component={BaseLinkRouter}
                to={PATH_AGENT_INSURANCE_PROPERTY_ORDER_SHARE.replace(':uuidOrder', uuid)}
                variant='contained'
                size='large'
                type='submit'
                fullWidth
              >
                {t('interface:page.AgentInsurancePropertyPolicyDetailsPage.shareLinkBtn')}
              </Button>
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default AgentInsurancePropertyPolicyDetailsPage;
