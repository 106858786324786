import { useTranslation } from 'react-i18next';

import { Controller, UseFormReturn } from 'react-hook-form';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { EProfileEducationType } from 'api/services/profile/profile.enum';
import { Fragment } from 'react';
import RadioField from 'components/common/Forms/RadioField';
import { IFillingQuestionnaireDTO } from 'api/services/experiment/experiment.dto';

export default function ProfileExperimentForm({ hookForm }: { hookForm: UseFormReturn<IFillingQuestionnaireDTO> }) {
  const { t } = useTranslation();
  const haveAlterationOfName = hookForm.watch('haveAlterationOfName');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioField
          control={hookForm.control}
          name="haveAlterationOfName"
          label={t('base.ExperimentProfile.lastnameChanged.title')}
          options={[
            { value: 'yes', label: 'Да' },
            { value: 'no', label: 'Нет' },
          ]}
        />
      </Grid>
      {(haveAlterationOfName as unknown as string) === 'yes' && (
        <Fragment>
          <Grid item xs={12}>
            <Controller
              control={hookForm.control}
              name={'altFirstname'}
              render={({ field }) => (
                <TextField fullWidth variant='standard' label={'Прежняя фамилия'} {...field} inputProps={{ ['data-testid']: field.name }} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={hookForm.control}
              name={'altLastname'}
              render={({ field }) => (
                <TextField fullWidth variant='standard' label={'Прежнее имя'} {...field} inputProps={{ ['data-testid']: field.name }} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={hookForm.control}
              name="altPatronymic"
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='standard'
                  label={'Прежнее отчество'}
                  {...field}
                  inputProps={{ ['data-testid']: field.name }}
                />
              )}
            />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <Typography variant="h4m" sx={{ color: 'common.black' }}>
          {t('base.ExperimentProfile.education.title')}
        </Typography>
        <Controller
          name="primaryEducation"
          control={hookForm.control}
          defaultValue={'' as EProfileEducationType}
          render={({ field, fieldState }) => (
            <TextField
              select
              required
              fullWidth
              variant='standard'
              {...field}
              value={field.value || ''}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ ['data-testid']: field.name }}
            >
              {Object.values(EProfileEducationType).map((scope) => (
                <MenuItem value={scope} key={scope}>
                  {t(`base.ExperimentProfile.education.options.${scope}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
    </Grid>
  );
}
