import { Button, MenuItem, Modal, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { userGetContractByType, userSetContractDetails, userUpdateInfo } from 'api/services/user';
import { EUserRole } from 'api/services/user.dto';
import { MASK, maskComponent } from 'components/base/FormGenerator/Input/inputMask';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';

const CONTRACT_TYPE = 'agentContract';
const STATUSES = ['individual', 'individualEntrepreneur'];
const DEFAULT_VALUES = {
  firstname: '',
  lastname: '',
  patronymic: '',
  inn: '',
  email: '',
  phone: '',
  type: STATUSES[0],
};

const UserGeneralPage = () => {
  const [isLogout, setLogoutModal] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { user, logout, getUserInfo, hasRole } = useAuth();
  const { isSuccess, data } = useFetch(() => userGetContractByType(CONTRACT_TYPE));
  const [isEditMode, setIsEditMode] = useState(false);
  const { handleSubmit, reset, control } = useForm({ defaultValues: DEFAULT_VALUES });
  const type = useWatch({ control, name: 'type' });

  useEffect(() => {
    if (hasRole(EUserRole.client)) {
      reset(user || {});
      return;
    }
    if (isSuccess) reset(data?.details?.personal || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
    reset(user || {});
  };

  const handleSubmitClick = () => {
    handleSubmit((data) => {
      if (hasRole(EUserRole.client)) {
        userUpdateInfo(data).then((res) => {
          if (res.ok) {
            setIsEditMode(false);
            getUserInfo();
          }
        });
        return;
      }
      // TODO: Error processing
      userSetContractDetails(CONTRACT_TYPE, { personal: data });
      setIsEditMode(false);
    })();
  };

  return (
    <>
      <Page
        title={t('helmet:UserGeneralPage.title')}
        meta={[{ name: 'description', content: t('helmet:UserGeneralPage.description') }]}
        header={{ type: 'backButton', title: t('interface:page.UserGeneralPage.headerTitle'), backRoute: PATH_USER }}
      >
        <Box sx={{ display: 'flex', height: 1, flexDirection: 'column', justifyContent: 'space-evenly' }}>
          <Stack spacing={2}>
            <Controller
              name='lastname'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={!isEditMode}
                  InputProps={{ 'data-testid': 'profile-lastname' }}
                  variant='standard'
                  onChange={onChange}
                  value={value}
                  label={t('interface:page.UserGeneralPage.lastname')}
                />
              )}
            />
            <Controller
              name='firstname'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={!isEditMode}
                  InputProps={{ 'data-testid': 'profile-firstname' }}
                  variant='standard'
                  onChange={onChange}
                  value={value}
                  label={t('interface:page.UserGeneralPage.firstname')}
                />
              )}
            />
            <Controller
              name='patronymic'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={!isEditMode}
                  InputProps={{ 'data-testid': 'profile-patronomyc' }}
                  variant='standard'
                  onChange={onChange}
                  value={value}
                  label={t('interface:page.UserGeneralPage.patronymic')}
                />
              )}
            />

            {user?.group === 'agent' && !user?.company && (
              <Controller
                name='inn'
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!isEditMode}
                    variant='standard'
                    label={t('interface:page.UserGeneralPage.inn')}
                    InputProps={maskComponent(MASK.INN_PERSON, { ...field, 'data-testid': 'profile-inn' })}
                  />
                )}
              />
            )}
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={!isEditMode || hasRole(EUserRole.client)}
                  InputProps={{ 'data-testid': 'profile-email' }}
                  variant='standard'
                  onChange={onChange}
                  value={value}
                  label={t('interface:page.UserGeneralPage.email')}
                />
              )}
            />
            <Controller
              name='phone'
              control={control}
              render={({ field }) => {
                // TODO: Refactoring
                field.value = `${field.value}` || '';
                return (
                  <TextField
                    disabled={!isEditMode || hasRole(EUserRole.client)}
                    variant='standard'
                    label={t('interface:page.UserGeneralPage.phone')}
                    InputProps={maskComponent(MASK.PHONE, { ...field, 'data-testid': 'profile-phone' })}
                  />
                );
              }}
            />
            {user?.group === 'agent' && !user?.company && (
              <Controller
                name='type'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputProps={{ 'data-testid': 'forms.user.general.type' }}
                    onChange={onChange}
                    disabled={!isEditMode}
                    variant='standard'
                    value={value}
                    label={t('forms.User.General.type')}
                  >
                    {STATUSES.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`interface:page.UserGeneralPage.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
            {type === STATUSES[1] && (
              <Controller
                name='ogrnip'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isEditMode}
                    variant='standard'
                    InputProps={maskComponent(MASK.OGRNIP, { ...field, 'data-testid': 'profile-ogrnip' })}
                    label={t('interface:page.UserGeneralPage.ogrnip')}
                  />
                )}
              />
            )}
          </Stack>

          <Stack sx={{ margin: '20px 0', flexGrow: 1 }} spacing={2}>
            {!isEditMode && (
              <Button variant='contained' color='secondary' size='large' onClick={handleEditClick} data-testid='editBtn'>
                {t('interface:page.UserGeneralPage.editBtn')}
              </Button>
            )}

            {isEditMode && (
              <>
                <Button variant='contained' size='large' onClick={handleSubmitClick} data-testid='submitBtn'>
                  {t('interface:page.UserGeneralPage.submitBtn')}
                </Button>
                <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
                  {t('interface:page.UserGeneralPage.cancelBtn')}
                </Button>
              </>
            )}
          </Stack>

          {hasRole(EUserRole.client) && (
            <Button fullWidth sx={{ color: theme.palette.error.main }} size='large' onClick={() => setLogoutModal(true)}>
              {t('interface:page.UserGeneralPage.logout')}
            </Button>
          )}
          <Modal
            open={isLogout}
            onClose={() => {
              setLogoutModal(false);
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
                gap: '8px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Typography variant='h5' component='span' sx={{ textAlign: 'center' }}>
                {t('interface:page.UserGeneralPage.logoutModalTitle')}
              </Typography>

              <Box sx={{ display: 'inline-flex', gap: '8px', paddingTop: '24px' }}>
                <Button
                  fullWidth
                  onClick={() => {
                    setLogoutModal(false);
                  }}
                  variant='contained'
                  size='large'
                  data-testid={'btnGetConsultation'}
                >
                  {t('interface:page.HomeClientPage.EntryCard.back')}
                </Button>
                <Button
                  fullWidth
                  onClick={() => {
                    logout();
                  }}
                  sx={{ background: theme.palette.error.light, color: '#E81E1E' }}
                  size='large'
                  data-testid={'btnGetConsultation'}
                >
                  {t('interface:page.UserGeneralPage.logout')}
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Page>

      <Typography variant='h8' sx={{ width: 1, textAlign: 'center', padding: '25px 0', position: 'absolute', bottom: 0 }}>
        {import.meta.env.REACT_APP_VERSION}
      </Typography>
    </>
  );
};

export default UserGeneralPage;
