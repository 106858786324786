import { Box, IconButton, Paper, SvgIcon, Typography } from '@mui/material';
import { EUserRole } from 'api/services/user.dto';
import ProposeCardContent from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeCard.content';
import { MapIcons } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/icons';
import { ProposeCardCommonProps } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/types';
import { useTranslation } from 'react-i18next';
import { usePropose } from '../../../ProposeProvider';
import EditIcon from '@mui/icons-material/Edit';
import { Accessible } from 'components/providers/RoleAccessProvider';

export default function ProposeCardMobile(props: ProposeCardCommonProps) {
  const { bank, decision, uuid } = props;
  const { t } = useTranslation();
  const proposeHook = usePropose();

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        borderRadius: '12px',
        height: '100%',
        width: 1,
        justfiyContent: 'space-between',
      }}
      variant='outlined'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <Box>{props.statusPanel}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', width: '100%' }}>
            <SvgIcon component={MapIcons[`${bank}`]} sx={{ width: 48, height: 48 }} />
            <Typography variant='h4'>{t(`common:mortgage.banks.${bank}`)}</Typography>
            {!props.selected && proposeHook.editPropose && (
              <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
                <Box>
                  <IconButton
                    aria-label='edit-manager'
                    data-testid={`${bank}.edit-button`}
                    onClick={() => {
                      proposeHook.editPropose(uuid);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Accessible>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <ProposeCardContent {...decision} isShowPayment={true} />
          {props.accordion}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 0.2, paddingTop: '12px' }}>
        {props.actionButton}
      </Box>
    </Paper>
  );
}
