import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_LANDING } from 'routes/paths';

interface Props {
  sx?: SxProps<Theme>;
}
const { REACT_APP_PRIVACY_PAGE } = import.meta.env;

export default function Footer({ sx }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      component='footer'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 12px 12px 12px',
        height: '200px',
        textAlign: 'center',
        ...(sx || {}),
      }}
    >
      <Link variant='h6' href={REACT_APP_PRIVACY_PAGE} target='_blank' sx={{ fontSize: { xs: '12px', lg: '14px' }, cursor: 'pointer' }}>
        {t('interface:base.Footer.documents')}
      </Link>
      <Typography
        variant='h6'
        component='span'
        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: { xs: '12px', lg: '14px' } }}
      >
        © Kopidoo, 2022.
      </Typography>
      <Typography
        variant='h6'
        component='span'
        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: { xs: '12px', lg: '14px' }, display: { xs: 'block', lg: 'none' } }}
      >
        {t('interface:base.Footer.companyName')}
        <br /> {t('interface:base.Footer.companyOgrn')}
        <br />
        {t('interface:base.Footer.address')}
      </Typography>
      <Typography
        variant='h6'
        component='span'
        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: { xs: '12px', lg: '14px' }, display: { xs: 'none', lg: 'block' } }}
      >
        {t('interface:base.Footer.companyName')} {t('interface:base.Footer.companyOgrn')} <br /> {t('interface:base.Footer.address')}
      </Typography>
      <Typography
        variant='h6'
        component='span'
        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: { xs: '12px', lg: '14px' } }}
      >
        {t('interface:base.Footer.email')}:{' '}
        <Link variant='h6' sx={{ fontSize: { xs: '12px', lg: '14px' } }} href={`mailto:${import.meta.env.REACT_APP_OFFICE_EMAIL}`}>
          {import.meta.env.REACT_APP_OFFICE_EMAIL}
        </Link>
      </Typography>
      <Typography
        variant='h6'
        component='span'
        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: { xs: '12px', lg: '14px' } }}
      >
        {t('interface:base.Footer.phone')}:{' '}
        <Link variant='h6' sx={{ fontSize: { xs: '12px', lg: '14px' } }} href={`tel:${import.meta.env.REACT_APP_OFFICE_PHONE}`}>
          {import.meta.env.REACT_APP_OFFICE_PHONE}
        </Link>
      </Typography>
      <Link
        onClick={() => {
          navigate(PATH_LANDING);
        }}
        sx={{ fontSize: { xs: '12px', lg: '14px' }, cursor: 'pointer' }}
      >
        kopidoo.ru
      </Link>
    </Box>
  );
}
