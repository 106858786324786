import { Box, Stack } from '@mui/material';
import BaseListEmpty from 'components/base/List/Empty';
import { useTranslation } from 'react-i18next';

const NotificationsList = () => {
  const { t } = useTranslation();
  // const { data } = useFetch(() => userGetNotificationList()) as { data: ObjectType };
  // const list = data?.results ?? [];
  // const hasItem = list.length != 0;

  return (
    <Box>
      <Stack spacing={2}>
        <BaseListEmpty containerStyle={{ minHeight: '65vh' }} title={t('interface:page.NotificationsPage.List.empty')} />
      </Stack>
    </Box>
  );
};

export default NotificationsList;
