import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.7994 12.2225C18.8512 12.1606 18.8455 12.0705 18.7879 12.0198C18.6094 11.8678 18.4366 11.7158 18.2524 11.5695C16.1852 9.9481 13.6228 9.05859 10.9567 9.05859C9.82235 9.05859 8.6995 9.21623 7.62272 9.5315C5.50945 10.1451 3.64379 11.3274 2.21 12.9206C1.10442 14.39 0.649523 15.5948 0.46526 16.2704C0.217657 17.3795 0.0621858 18.5167 0.0161201 19.6483C-0.20845 25.0923 1.92209 30.4238 5.8607 34.2746C9.21198 37.5512 13.5709 39.5329 18.2524 39.927C18.6785 39.9608 19.1046 39.9833 19.5365 39.9945C19.6862 40.0002 19.8416 40.0002 19.9914 40.0002C26.573 40.0002 32.7343 36.8306 36.4771 31.5273C36.5174 31.4653 36.5117 31.3921 36.4598 31.3415C36.431 31.3133 36.3907 31.2964 36.3504 31.2964C36.3216 31.2964 36.2928 31.3077 36.264 31.3189C34.646 32.3492 32.8379 33.0135 30.9492 33.2838C30.3216 33.3738 29.6882 33.4189 29.0433 33.4189C24.5979 33.4189 20.6593 31.2683 18.2524 27.9692C17.0259 26.2971 16.2024 24.3267 15.903 22.193C15.8282 21.63 15.7821 21.0614 15.7821 20.4759C15.7821 17.7623 16.6573 15.1106 18.2524 12.9206C18.4251 12.6842 18.6094 12.4477 18.7994 12.2225Z'
      fill='#00447C'
    />
    <path
      d='M37.7902 27.0795C38.8957 25.6102 39.3506 24.4054 39.5349 23.7298C39.7825 22.6207 39.9322 21.4891 39.984 20.3463C40.2086 14.9022 38.0838 9.57636 34.1395 5.72555C30.4772 2.1506 25.6231 0.118227 20.4637 0.00562984C20.314 0 20.1585 0 20.0088 0C19.4215 0 18.8341 0.0281492 18.2526 0.0788178C12.3389 0.585504 6.92613 3.64251 3.52303 8.47291C3.48272 8.53484 3.48848 8.61366 3.54031 8.66433C3.5691 8.69248 3.6094 8.70937 3.64971 8.70937C3.6785 8.70937 3.70729 8.69811 3.73033 8.68685C5.34838 7.65659 7.15646 6.98663 9.04515 6.72203C9.6728 6.63195 10.3062 6.58692 10.9511 6.58692C13.6402 6.58692 16.1508 7.38072 18.2468 8.73752C21.3677 10.7586 23.5674 14.0296 24.0856 17.8128C24.1662 18.3702 24.2065 18.9444 24.2065 19.5299C24.2065 22.5419 23.1355 25.475 21.1835 27.7889C21.1317 27.8508 21.1374 27.9353 21.2008 27.9916C23.3486 29.9001 26.1298 30.9529 29.0319 30.9529C30.1663 30.9529 31.2891 30.7952 32.3659 30.48C34.4907 29.8551 36.3564 28.6784 37.7902 27.0795Z'
      fill='#00447C'
    />
  </svg>,
  'Bbr',
);
