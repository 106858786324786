import { IRequestResult, req } from '../http';

import { ISupportSendParams } from './support.dto';

export const supportSend = (support: ISupportSendParams): Promise<IRequestResult<boolean>> => {
  return req<ISupportSendParams, boolean>(`user/support`, { method: 'POST', body: support });
};

export const supportPublicSend = (support: ISupportSendParams): Promise<IRequestResult<boolean>> => {
  return req<ISupportSendParams, boolean>(`support`, { method: 'POST', body: support });
};
