import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Accordion as MaterialAccordion, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface AccordionProps {
  children: ReactNode | ReactNode[];
  helperText: string;
  title: string;
}

export const Accordion: FC<AccordionProps> = ({ title, helperText, children }) => {
  const theme = useTheme();

  return (
    <Box>
      <MaterialAccordion square elevation={0} data-testid='accordion-policyHolder'>
        <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
          <Box display='flex' flexDirection='column'>
            <Typography variant='h4b'>{title}</Typography>
            <Typography variant='h7' color={theme.palette.text.secondary}>
              {helperText}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
      </MaterialAccordion>
    </Box>
  );
};
