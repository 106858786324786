// export const BASE_URL = import.meta.env.REACT_APP_HTTP_API_BASEURL;
export const HTTP_AUTH_SECRET = import.meta.env.REACT_APP_HTTP_AUTH_SECRET;

export const LS_SESS_KEY = 'appSession';
export const LS_SESS_TOKEN_ACCS = 'tokenAccess';
export const LS_SESS_TOKEN_REFR = 'tokenRefresh';

export const LS_SIGN_CONTEXT = 'ls-entry-sign-context';
export const LS_TUTORIAL = 'tutorial_show';

export const LS_INVITE_LINK = 'invite_link';

import utils from 'utils';

import { ApiError, ErrorResponse } from './errors';
import { getApiUrl } from 'utils/env/env';

export const BASE_URL = getApiUrl();

export interface IHttpRequestOptions {
  /** A BodyInit object or null to set request's body. */
  body?: ReadableStream | XMLHttpRequestBodyInit | null;
  /** A string indicating how the request will interact with the browser's cache to set request's cache. */
  cache?: RequestCache;
  /** A string indicating whether credentials will be sent with the request always, never, or only when sent to a same-origin URL.
   * Sets request's credentials. */
  credentials?: RequestCredentials;
  /** A Headers object, an object literal, or an array of two-item arrays to set request's headers. */
  headers?: HeadersInit;
  /** A cryptographic hash of the resource to be fetched by request. Sets request's integrity. */
  integrity?: string;
  /** A boolean to set request's keepalive. */
  keepalive?: boolean;
  /** A string to set request's method. */
  method?: string;
  /** A string to indicate whether the request will use CORS, or will be restricted to same-origin URLs. Sets request's mode. */
  mode?: RequestMode;
  /** A string indicating whether request follows redirects, results in an error upon encountering a redirect, or returns the
   * redirect (in an opaque fashion). Sets request's redirect. */
  redirect?: RequestRedirect;
  /** A string whose value is a same-origin URL, "about:client", or the empty string, to set request's referrer. */
  referrer?: string;
  /** A referrer policy to set request's referrerPolicy. */
  referrerPolicy?: ReferrerPolicy;
  /** An AbortSignal to set request's signal. */
  signal?: AbortSignal | null;
  /** Can only be null. Used to disassociate request from any Window. */
  window?: null;
}

export interface ErrorMessage {
  message?: string;
  name?: string;
}

export interface IRequestAbort {
  signal?: AbortSignal;
}
export type UserRequestOptions = IRequestAbort;

export interface IRequestOptions<T> extends IRequestAbort {
  method: string;
  body?: T | { file: string | Blob } | ReadableStream | XMLHttpRequestBodyInit | null;
  headers?: Record<string, string>;
}
export interface IRequestResult<T> {
  readonly ok: boolean;
  readonly status: number;
  readonly statusText: string;
  readonly body: T;
  readonly error?: ErrorMessage;
}

export const req = async <RequestBody, ResponseBody>(
  path: string,
  opts: IRequestOptions<RequestBody>,
): Promise<IRequestResult<ResponseBody>> => {
  const options: IHttpRequestOptions = {
    method: opts.method,
  };
  if (opts.signal) {
    options.signal = opts.signal;
  }

  if (opts.body) {
    // TODO: Typing for file or move from here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (opts.body.file) {
      const formData = new FormData();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append('file', opts.body.file);
      options.body = formData;
    } else {
      options.body = JSON.stringify(opts.body);
      options.headers = {
        'Content-Type': 'application/json',
      };
    }
  }

  if (utils.env.isDev()) {
    options.headers = { ...options.headers, Authorization: `Bearer ${localStorage.getItem(LS_SESS_TOKEN_ACCS)}` };
  }

  const response = await fetch(`${BASE_URL}/${path}`, { ...options, credentials: 'include' });

  const result = {
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
    body: {} as ResponseBody,
    error: {} as ErrorMessage,
  };
  const text = await response.text();

  // handle response to fill body result
  if (opts.headers?.['Content-Type'] === 'text/html') {
    result.body = text as ResponseBody;
  } else {
    result.body = (!!text ? JSON.parse(text) : {}) as ResponseBody;
  }
  // generate error
  if (!result.ok) {
    throw new ApiError(result.body as unknown as ErrorResponse);
  }

  return result;
};
