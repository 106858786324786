import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// TODO: resolve relevance of naming ContractView
// TODO: rename const ContractView to UserContractView
const ContractView = ({ text, subtitle }: { text: string; subtitle?: string }) => {
  const { t } = useTranslation();
  return (
    <>
      {subtitle && (
        <Typography sx={{ paddingBottom: '32px' }} variant={'h4'}>
          {subtitle === 'signed' ? '' : t('interface:page.UserContractPage.ContractView.subtitle')}
        </Typography>
      )}
      <Typography sx={{ paddingTop: '16px', whiteSpace: 'none' }} variant={'h5'} dangerouslySetInnerHTML={{ __html: text }} />
    </>
  );
};

export default ContractView;
