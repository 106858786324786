import { Button, Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import BaseButtonBack from 'components/base/Button/Back';
import UploadButton from 'components/common/UploadButton';
import Header from 'components/layouts/Header';
import Page from 'components/layouts/Page';
import WithNotificationHeader from 'components/layouts/WithNotificationHeader';
import { useNavigate } from 'react-router-dom';

import GuiBaseListEmpty from './base/List/Empty';
import GuiCommonCheckCode from './common/CheckCode';

const GuiPage = () => {
  const navigation = useNavigate();

  if (import.meta.env.MODE === 'production') {
    navigation('/');
  }

  return (
    <Page header={{ type: 'withNotification', title: 'GUI' }}>
      <Stack spacing={2} sx={{ pt: 2 }}>
        <Typography variant='h1b' component='div' gutterBottom>
          + Common complex components
        </Typography>
        <div>
          <GuiCommonCheckCode />
        </div>
        <Box>
          <Typography variant='h1b' component='div' gutterBottom>
            + Headers
          </Typography>
          <Header title='Default Header' />
          <BaseButtonBack title='Header with Back button' />
          <WithNotificationHeader title='Header with Notifications' />
        </Box>
      </Stack>
      <Stack spacing={2} sx={{ pt: 2 }}>
        <Typography variant='h1b' component='div' gutterBottom>
          + Uploader
        </Typography>
        <UploadButton />
        <UploadButton fileLink='https://www.youtube.com/watch?v=dQw4w9WgXcQ' labelUploaded='sexy-rick.mp4' />
        <UploadButton fileLink='https://www.youtube.com/watch?v=dQw4w9WgXcQ' allowDelete />
        <UploadButton fileLink='https://www.youtube.com/watch?v=dQw4w9WgXcQ' allowDownload />
        <UploadButton fileLink='https://www.youtube.com/watch?v=dQw4w9WgXcQ' allowDelete allowDownload />
      </Stack>
      <Stack spacing={2} sx={{ pt: 2 }}>
        <Typography variant='h1b' component='div' gutterBottom>
          + Typography
        </Typography>
        <pre style={{ overflow: 'hidden' }}>
          &gt; source: https://www.figma.com/file/hfR7FHrhwopqXTW0NjQ6Aw/%F0%9F%90%9D-Kopidoo---UI?node-id=6%3A442
        </pre>
        <Typography variant='h1b' component='div' gutterBottom>
          H1 Bold: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h1m' component='div' gutterBottom>
          H1 Medium: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h1' component='div' gutterBottom>
          H1 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <Typography variant='h2b' gutterBottom component='div'>
          H2 Bold: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h2m' gutterBottom component='div'>
          H2 Medium: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h2' gutterBottom component='div'>
          H2 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <Typography variant='h3b' gutterBottom component='div'>
          H3 Bold: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h3m' gutterBottom component='div'>
          H3 Medium: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h3' gutterBottom component='div'>
          H3 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <Typography variant='h4b' gutterBottom component='div'>
          H4 Bold: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h4m' gutterBottom component='div'>
          H4 Medium: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h4' gutterBottom component='div'>
          H4 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <Typography variant='h5b' gutterBottom component='div'>
          H5 Bold: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h5m' gutterBottom component='div'>
          H5 Medium: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h5' gutterBottom component='div'>
          H5 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <Typography variant='h6' gutterBottom component='div'>
          H6 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h7' gutterBottom component='div'>
          H7 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='h8' gutterBottom component='div'>
          H8 Regular: Lorem ipsum dolor sit amet \ Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <pre>&gt; Body text using style from regular h4, h5</pre>
        <Typography variant='body1' gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum
          inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam. \
          Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='body2' gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum
          inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam. \
          Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <Typography variant='button' display='block' gutterBottom>
          button text / Съешь [же] ещё этих мягких французских булок да выпей чаю
        </Typography>
        <hr />
        <pre>&gt; !!! Unstyled now</pre>
        <Typography variant='subtitle1' gutterBottom component='div'>
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur \ Съешь [же] ещё этих мягких
          французских булок да выпей чаю
        </Typography>
        <Typography variant='subtitle2' gutterBottom component='div'>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur \ Съешь [же] ещё этих мягких
          французских булок да выпей чаю
        </Typography>
        <Typography variant='caption' display='block' gutterBottom>
          caption text
        </Typography>
        <Typography variant='overline' display='block' gutterBottom>
          overline text
        </Typography>
      </Stack>

      <Box>
        <Typography variant='h1b' component='div' gutterBottom>
          + Links
        </Typography>
        <Link href='/technical' variant='body2'>
          Link test
        </Link>
      </Box>

      <Box>
        <Typography variant='h1b' component='div' gutterBottom>
          + Buttons
        </Typography>
        <Typography variant='h4b' component='div' gutterBottom>
          ++ Basic button
        </Typography>
        <Stack spacing={2} direction='row'>
          <Button variant='text'>Text primary</Button>
          <Button variant='contained'>Contained primary</Button>
          <Button variant='outlined'>Outlined primary</Button>
        </Stack>
        <Stack spacing={2} direction='row'>
          <Button color='secondary' variant='text'>
            Text secondary
          </Button>
          <Button color='secondary' variant='contained'>
            Contained secondary
          </Button>
          <Button color='secondary' variant='outlined'>
            Outlined secondary
          </Button>
        </Stack>

        <Typography variant='h4b' component='div' gutterBottom>
          ++ Text button
        </Typography>
        <Stack spacing={2} direction='row'>
          <Button>Primary</Button>
          <Button disabled>Disabled</Button>
          <Button href='#text-buttons'>Link</Button>
        </Stack>

        <Typography variant='h4b' component='div' gutterBottom>
          ++ Contained button
        </Typography>
        <Stack spacing={2} direction='row'>
          <Button variant='contained'>Contained</Button>
          <Button variant='contained' disabled>
            Disabled
          </Button>
          <Button variant='contained' href='#contained-buttons'>
            Link
          </Button>
        </Stack>

        <Typography variant='h4b' component='div' gutterBottom>
          ++ Outlined button
        </Typography>
        <Stack spacing={2} direction='row'>
          <Button variant='outlined'>Primary</Button>
          <Button variant='outlined' disabled>
            Disabled
          </Button>
          <Button variant='outlined' href='#outlined-buttons'>
            Link
          </Button>
        </Stack>

        <Typography variant='h4b' component='div' gutterBottom>
          ++ Sizes
        </Typography>
        <Box sx={{ '& button': { m: 1 } }}>
          <div>
            <Button size='small'>Small</Button>
            <Button size='medium'>Medium</Button>
            <Button size='large'>Large</Button>
          </div>
          <div>
            <Button variant='outlined' size='small'>
              Small size
            </Button>
            <Button variant='outlined' size='medium'>
              Medium size
            </Button>
            <Button variant='outlined' size='large'>
              Large size
            </Button>
          </div>
          <div>
            <Button variant='contained' size='small'>
              Small size
            </Button>
            <Button variant='contained' size='medium'>
              Medium size
            </Button>
            <Button variant='contained' size='large'>
              Large size
            </Button>
          </div>
        </Box>
      </Box>

      <GuiBaseListEmpty />
    </Page>
  );
};

export default GuiPage;
