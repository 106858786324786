export interface IPurchasesPaymentResponse {
  paymentLink: string;
  namePurchase: string;
  uuidPurchase: string;
}

export enum EPurchaseName {
  personalManager = 'personalManager',
  personalManagerDev = 'personalManagerDev',
  personalManagerLocal = 'personalManagerLocal',
}

export interface IPurchaseDates {
  created: number;
  updated: number;
}

export enum EPurchasesStatus {
  process = 'process',
  waiting = 'waiting',
  paid = 'paid',
  fail = 'fail',
}

export interface IPurchaseResponse {
  uuid: string;
  dates?: IPurchaseDates;
  name: string;
  price: number;
  description?: string;
  status: EPurchasesStatus;
}
