import { ApiError } from 'api/errors';
import { DTOCreateBankGuaranteeEntryAndCompanyBodyReq } from 'api/services/bankGuarantee/bankGuarantee.dto';
import bankGuaranteeService from 'api/services/bankGuarantee/bankGuarantee.service';
import useNavigateParams from 'hooks/useNavigateParams';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { PATH_BANK_GARANTEE_ENTRY_COMPANY } from 'routes/paths';

interface BankGuaranteeContextProps {
  getByUUID: (uuid: string) => Promise<unknown>;
  createEntryCompanyRequest: (FormData: any) => Promise<void>;
  apiError?: ApiError;
  cleanError: (isLoading: boolean) => void;
  loadEntryRequest: ({ uuidEntry }: { uuidEntry: string }, cb?: (payload: any) => void) => Promise<void>;
  updateCompanyRequest: (uuidCompany: string, payload: any) => Promise<void>;
  loading: boolean;
  entry?: any;
  updateEntryRequest: (uuidEntry: string, formData: any) => Promise<void>;
  loadEntries: () => Promise<void>;
  entries?: any[];
  hasEntries: boolean;
}

export const BankGuaranteeContext = createContext<Partial<BankGuaranteeContextProps>>({});

export default function BankGuaranteeProvider({ children }: { children: ReactNode }) {
  const [entries, setEntries] = useState<any[]>([]);
  const [entry, setEntry] = useState<any>();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigateParams();
  const [apiError, setApiError] = useState<ApiError>();
  const cleanError = (isLoading: boolean) => {
    setApiError(undefined);
    setLoading(isLoading);
  };

  /**
   * load list of entries
   *
   * @param param0
   * @returns
   */
  const loadEntryRequest = async ({ uuidEntry }: { uuidEntry: string }, cb?: (payload: any) => void) => {
    cleanError(true);
    try {
      const res = await bankGuaranteeService.getEntry({ uuidEntry });
      setEntry(res.body);
      cb?.(res.body);
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   *
   * @param uuidEntry uuid of entry
   * @param formData
   */
  const updateEntryRequest = async (_uuidEntry: string, formData: any) => {
    cleanError(true);
    try {
      await bankGuaranteeService.putUpdateEntry(formData);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const updateCompanyRequest = async (_uuidCompany: string, _formData: any) => {
    cleanError(true);
    try {
      throw new Error('not implemented error');
      // await bankGuaranteeService.putUpdateCompany({ uuidCompany }, formData as any);
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const createEntryCompanyRequest = async (formData: DTOCreateBankGuaranteeEntryAndCompanyBodyReq) => {
    cleanError(true);
    try {
      const res = await bankGuaranteeService.postCreateEntryWithCompany(formData);
      navigate(PATH_BANK_GARANTEE_ENTRY_COMPANY, { params: { uuidEntry: res.body.entry.uuid! } });
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const loadEntries = async () => {
    cleanError(true);
    try {
      const res = await bankGuaranteeService.getEntryList();
      setEntries(res.body);
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const hasEntries = useMemo(() => entries && entries.length > 0, [entries]);
  return (
    <BankGuaranteeContext.Provider
      value={{
        createEntryCompanyRequest,
        apiError,
        cleanError,
        loadEntryRequest,
        entry,
        loading,
        updateCompanyRequest,
        updateEntryRequest,
        loadEntries,
        entries,
        hasEntries,
      }}
    >
      {children}
    </BankGuaranteeContext.Provider>
  );
}

/**
 *
 *
 */
export function useBankGuarantee(): BankGuaranteeContextProps {
  const ctx = useContext(BankGuaranteeContext) as BankGuaranteeContextProps;
  if (!ctx) {
    throw new Error('BankGuaranteeProvider has not initialized at parent');
  }
  return ctx;
}
