import { createSearchParams, useNavigate } from 'react-router-dom';
import utils from 'utils';
import { RouteParams } from 'utils/iots';

export default function useNavigateParams() {
  const navigate = useNavigate();

  return <Path extends string>(url: Path, options?: { params?: RouteParams<Path>; query?: Record<string, string | string[]> }) => {
    let fullUrl: string = url;
    if (options?.params) {
      fullUrl = utils.url.urlReplace(url, options.params);
    }
    if (options?.query) {
      fullUrl += `?${createSearchParams(options.query).toString()}`;
    }

    navigate(fullUrl);
  };
}
