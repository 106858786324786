import { ApiError } from 'api/errors';
import {
  IBusinessCreditCompanyEntryCreatingDataCompany,
  IBusinessCreditEntryEntity,
  IBusinessCreditEntryResponse,
} from 'api/services/businessCredit/businessCredit.dto';
import { BusinessCreditSchema } from 'api/services/businessCredit/businessCredit.schema';
import businessCreditService from 'api/services/businessCredit/businessCredit.service';
import useNavigateParams from 'hooks/useNavigateParams';

import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PATH_BUSINESS_CREDIT_ENTRY_COMPANY } from 'routes/paths';

interface BusinessCreditContextProps {
  getByUUID: (uuid: string) => Promise<unknown>;
  createEntryCompanyRequest: (FormData: BusinessCreditSchema) => Promise<void>;
  apiError?: ApiError;
  cleanError: (isLoading: boolean) => void;
  loadEntryRequest: ({ uuidEntry }: { uuidEntry: string }, cb?: (payload: IBusinessCreditEntryResponse) => void) => Promise<void>;
  updateCompanyRequest: (uuidCompany: string, payload: IBusinessCreditCompanyEntryCreatingDataCompany) => Promise<void>;
  loading: boolean;
  entry?: IBusinessCreditEntryResponse;
  updateEntryRequest: (uuidEntry: string, formData: BusinessCreditSchema) => Promise<void>;
  loadEntries: () => Promise<void>;
  entries?: IBusinessCreditEntryEntity[];
  hasEntries: boolean;
  bankList: string[];
}

export const BusinessCreditContext = createContext<Partial<BusinessCreditContextProps>>({});

export default function BusinessCreditProvider({ children }: { children: ReactNode }) {
  const params = useParams();
  const [entries, setEntries] = useState<IBusinessCreditEntryEntity[]>([]);
  const [entry, setEntry] = useState<IBusinessCreditEntryResponse>();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigateParams();
  const [apiError, setApiError] = useState<ApiError>();
  const cleanError = (isLoading: boolean) => {
    setApiError(undefined);
    setLoading(isLoading);
  };

  /**
   * load list of entries
   *
   * @param param0
   * @returns
   */
  const loadEntryRequest = async ({ uuidEntry }: { uuidEntry: string }, cb?: (payload: IBusinessCreditEntryResponse) => void) => {
    cleanError(true);
    try {
      const res = await businessCreditService.getEntry({ uuidEntry });
      setEntry(res.body);
      cb?.(res.body);
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   *
   * @param uuidEntry uuid of entry
   * @param formData
   */
  const updateEntryRequest = async (uuidEntry: string, formData: BusinessCreditSchema) => {
    cleanError(true);
    try {
      await businessCreditService.putUpdateEntry({ uuidEntry }, formData as any);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const updateCompanyRequest = async (uuidEntry: string, formData: IBusinessCreditCompanyEntryCreatingDataCompany) => {
    cleanError(true);
    try {
      await businessCreditService.putUpdateCompany({ uuidEntry }, formData);
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const createEntryCompanyRequest = async (formData: BusinessCreditSchema) => {
    cleanError(true);
    try {
      const res = await businessCreditService.postCreateEntryWithCompany(formData);
      navigate(PATH_BUSINESS_CREDIT_ENTRY_COMPANY, { params: { uuidEntry: res.body.entry.uuid } });
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const loadEntries = async () => {
    cleanError(true);
    try {
      const res = await businessCreditService.getEntryList();
      setEntries(res.body);
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!entries || !entries?.length) loadEntries().then();
    if (!entry) loadEntryRequest({ uuidEntry: params.uuidEntry! });
  }, []);

  const bankList = useMemo(() => {
    return entry?.entry.excludedProviders;
  }, [entry?.entry?.excludedProviders]);

  const hasEntries = useMemo(() => entries && entries.length > 0, [entries]);
  return (
    <BusinessCreditContext.Provider
      value={{
        createEntryCompanyRequest,
        apiError,
        cleanError,
        loadEntryRequest,
        entry,
        loading,
        updateCompanyRequest,
        updateEntryRequest,
        loadEntries,
        entries,
        hasEntries,

        bankList,
      }}
    >
      {children}
    </BusinessCreditContext.Provider>
  );
}

/**
 *
 *
 */
export function useBusinessCredit(): BusinessCreditContextProps {
  const ctx = useContext(BusinessCreditContext) as BusinessCreditContextProps;
  if (!ctx) {
    throw new Error('BusinessCreditProvider has not initialized at parent');
  }
  return ctx;
}
