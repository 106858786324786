import { Box, LinearProgress, Typography, useTheme } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useAuth } from 'components/providers/AuthProvider';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH_SIGNIN_CODE } from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

import SignInPhoneInput from './components/PhoneInput';

const AuthSignInByPhonePage = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('+7');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: ObjectType) => {
    setPhone(event.target.value);
  };

  useEffect(() => {
    const clearPhone = phone.replace(/\D/g, '');

    // Check phone length
    if (clearPhone.length !== 11) return;

    // Show incorrect phone message (RU only)
    if (!clearPhone.startsWith('79')) {
      setError('logic.wrongPhoneNumber');
      return;
    }
    setError(null);

    const formated = utils.format.digitNumber(clearPhone) as number;
    // Request sms code
    setLoading(true);
    auth
      ?.requestSmsCode?.(formated)
      .then((res) => {
        if (res?.ok) return navigate(PATH_AUTH_SIGNIN_CODE);
        setError('logic.somethingWrong');
        setLoading(false);
      })
      .catch(() => {
        setError('logic.badConnection');
        setLoading(false);
      });
  }, [phone]);

  return (
    <ContainerLayout
      disableGutters
      sx={{ height: 1, width: 1 }}
      title={t('helmet:AuthSignInByPhonePage.title')}
      meta={[{ name: 'description', content: t('helmet:AuthSignInByPhonePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.AuthSignInByPhonePage.inpPhone')} backRoute='/' />,
        lg: <TopMenu.BackButton backRoute='/' />,
      }}
      maxWidth='xl'
    >
      {loading && <LinearProgress sx={{ margin: '0 -20px', width: 1, position: 'absolute' }} />}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '57px',
          gap: { xs: '0px', lg: '40px' },
        }}
      >
        <Typography variant='h2' sx={{ display: { xs: 'none', lg: 'block' }, fontWeight: 400, width: 'fit-content' }}>
          {t('interface:page.AuthSignInByPhonePage.inpPhone')}
        </Typography>
        <SignInPhoneInput value={phone} onChange={handleChange} />
      </Box>
      {error && (
        <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
          {t(`errors:${error}`)}
        </Typography>
      )}
    </ContainerLayout>
  );
};

export default AuthSignInByPhonePage;
