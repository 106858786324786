import { Box, Grid, Link, Stack, SxProps, Typography, useTheme } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import Footer from 'components/base/Footer';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useSupportChat from 'hooks/useSupportChat';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { getUtmCookieQuery } from 'utils/cookie/utm';

const {
  REACT_APP_SORE_NASHSTORE,
  REACT_APP_SORE_APPSTORE,
  REACT_APP_SORE_RUSTORE,
  REACT_APP_SORE_GALAXYSTORE,
  REACT_APP_SORE_APPGALLERY,
  REACT_APP_SORE_GOOGLEPLAY,
} = import.meta.env;

export default function AuthRegisterTemplate({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const supportChat = useSupportChat();
  const handleSupportClick = () => {
    supportChat.open();
  };

  const handleAppStore = () => {
    window.location.href = `${REACT_APP_SORE_APPSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleRuStore = () => {
    window.location.href = `${REACT_APP_SORE_RUSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleNashStore = () => {
    window.location.href = `${REACT_APP_SORE_NASHSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleGalaxyStore = () => {
    window.location.href = `${REACT_APP_SORE_GALAXYSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleAppGallery = () => {
    window.location.href = `${REACT_APP_SORE_APPGALLERY as string}?${getUtmCookieQuery()}`;
  };
  const handleGooglePlay = () => {
    window.location.href = `${REACT_APP_SORE_GOOGLEPLAY as string}?${getUtmCookieQuery()}`;
  };

  const sxStyles: SxProps = { width: '110px', height: '35px', cursor: 'pointer' };
  const Stores: Record<string, JSX.Element> = {
    appStore: <Box component='img' sx={sxStyles} src='/img/getapp/store-appstore.png' alt='' onClick={handleAppStore} />,
    ruStore: <Box component='img' sx={sxStyles} src='/img/getapp/store-rustore.png' alt='' onClick={handleRuStore} />,
    nashStore: <Box component='img' sx={sxStyles} src='/img/getapp/store-nashstore.png' alt='' onClick={handleNashStore} />,
    appGallery: <Box component='img' sx={sxStyles} src='/img/getapp/app-gallery.png' alt='' onClick={handleAppGallery} />,
    galaxyStore: <Box component='img' sx={sxStyles} src='/img/getapp/galaxy-store.png' alt='' onClick={handleGalaxyStore} />,
    googlePlay: <Box component='img' sx={sxStyles} src='/img/getapp/google-play.png' alt='' onClick={handleGooglePlay} />,
  };
  return (
    <ContainerLayout
      noContainer
      sx={{
        width: 1,
        height: 1,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',

        overflowY: { xs: 'auto', lg: 'hidden' },
      }}
      hideHeader={true}
      title={t('helmet:AuthRegisterPage.title')}
      meta={[{ name: 'description', content: t('helmet:AuthRegisterPage.description') }]}
      maxWidth='xl'
    >
      <Grid container sx={{ height: 1 }}>
        <Grid
          item
          lg={6}
          sx={{
            padding: { xs: '0px 20px', lg: '0px 56px 0 56px' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <TopMenu.BackButton backRoute='/' />
          </Box>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <BaseButtonBack backRoute='/' title={t('interface:page.AuthRegisterPage.Form.title')} />
          </Box>
          {children}
          <Footer
            sx={{
              textAlign: { xs: 'center', lg: 'left' },
              justifyContent: { lg: 'flex-start', xs: 'center' },
              alignItems: { lg: 'flex-start', xs: 'center' },
            }}
          />
          <Link
            sx={{ cursor: 'pointer', paddingBottom: '50px', display: { xs: 'block', md: 'none' } }}
            textAlign='center'
            variant='h4'
            color={theme.palette.common.link}
            underline='none'
            onClick={handleSupportClick}
          >
            {t('interface:page.AuthRegisterPage.Form.supportChat')}
          </Link>
        </Grid>

        <Grid
          item
          lg={6}
          sx={{
            backgroundColor: '#EAF3FF',
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            paddingTop: '56px',
            alignItems: 'center',
          }}
        >
          <Typography variant='h1b' textAlign='center' sx={{ whiteSpace: 'pre-line' }}>
            {t('interface:page.AuthRegisterPage.GetApp.title')}
          </Typography>
          <Stack gap='48px'>
            <Box
              sx={{
                margin: '0 auto',
                background: 'url(/img/getapp/getapp.png) no-repeat',
                backgroundSize: 'cover',
                height: { xs: '240px', md: '480px' },
                width: { xs: '240px', md: '480px' },
              }}
            >
              <Box
                sx={{
                  margin: { xs: '190px 145px', md: '380px 290px' },
                  width: { xs: '115px', md: '230px' },
                  height: { xs: '56px', md: '112px' },
                  backgroundColor: '#FAEA64',
                  transform: 'rotate(-12deg)',
                  borderRadius: '16px',
                  padding: { xs: '10px 0', md: '20px 0' },
                  textAlign: 'center',
                  fontWeight: 400,
                  fontSize: { xs: '16px', md: '32px' },
                  lineHeight: '110%',
                }}
              >
                Финансы — <br />
                это просто
              </Box>
            </Box>
          </Stack>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ paddingTop: '55px', marginLeft: { xl: '400px', lg: 0 } }}
          >
            <Grid item>
              <img width='85px' height='85px' src={'/img/landing/qr-getapp.png'} alt='QR код' />
            </Grid>
            <Grid item md={6}>
              <Grid container direction='row' columns={{ lg: 3 }} sx={{ width: { xl: 0.5, md: 1, lg: 1 } }}>
                {Object.keys(Stores).map((store) => (
                  <Grid key={store} item lg={1}>
                    {Stores[store]}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
}
