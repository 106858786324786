import { Box, Button, Divider, SvgIcon, Typography, useTheme } from '@mui/material';
import { postMortgageSelectPropose } from 'api/services/insurance/insurance';
import ArrowDown from 'components/Icons/ArrowDown';
import ArrowUp from 'components/Icons/ArrowUp';
import useNavigateParams from 'hooks/useNavigateParams';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE_GROUP } from 'routes/paths';
import { ICONS_COMPANY, IconsCompany } from 'utils/iconsCompany';

interface ProposalProps {
  company: IconsCompany;
  price: number;
  description: string;
  uuidPropose: string;
}

export const Proposal: FC<ProposalProps> = ({ company, price, description, uuidPropose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigateParams();
  const { uuidEntry, uuidUser } = useParams();

  const { t } = useTranslation();
  const theme = useTheme();

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceCompanyForm';

  const onToggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleNavigateNext = () => {
    postMortgageSelectPropose(`${uuidEntry}`, uuidPropose);
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE_GROUP, { params: { uuidEntry, uuidUser, groupName: 'payment' } });
  };

  return (
    <Box sx={{ padding: '24px', border: '1px solid #D2D2CF', borderRadius: '16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon component={ICONS_COMPANY[company]} sx={{ width: '40px', height: '40px', marginRight: '10px' }} />
        {t(`${prefix}.companies.${company}`)}
        <Typography variant={'h4b'} component={'p'} sx={{ marginLeft: 'auto' }}>
          {price} ₽
        </Typography>
      </Box>
      {isOpen && (
        <Box sx={{ marginTop: '25px' }}>
          <Divider sx={{ marginBottom: '25px' }} />
          <Typography variant={'h4b'}>{t(`${prefix}.proposal.conditions`)}</Typography>
          <Box dangerouslySetInnerHTML={{ __html: description }} />
          <Typography variant={'h5'} component={'p'}>
            {t(`${prefix}.proposal.rules`)}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '8px', marginTop: '24px' }}>
        <Button onClick={handleNavigateNext} fullWidth variant='contained' size='large' type='submit' data-testid='nextStep'>
          {t(`${prefix}.proposal.issue`)}
        </Button>
        <Button onClick={onToggle} variant='contained' color='secondary' size='large' data-testid='saveAndContinueBtn'>
          {isOpen ? (
            <ArrowUp sx={{ stroke: theme.palette.common.greyLight }} />
          ) : (
            <ArrowDown sx={{ stroke: theme.palette.common.greyLight }} />
          )}
        </Button>
      </Box>
    </Box>
  );
};
