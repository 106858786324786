import { Button, Container, Grid, Typography } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useNavigateParams from 'hooks/useNavigateParams';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_BUSINESS_CREDIT_ENTRY_PROGRESS } from 'routes/paths';
import utils from 'utils';

const BankGuaranteeSignPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigateParams();

  const handleNavigate = () => {
    navigate(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, params));
  };

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntrySignInfoPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntrySignInfoPage.description') }]}
      header={{ xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntrySignInfoPage.title')} />, lg: <TopMenu /> }}
      maxWidth='xl'
      sx={{ height: 0.85 }}
    >
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          height: 1,
          gap: '24px',
        }}
      >
        <Typography variant='h1b' sx={{ fontSize: { md: '48px', xs: '26px' } }}>
          {t('interface:page.ClientMortgageEntrySignInfoPage.lblTitleReady')}
        </Typography>
        <Typography variant='h3'>{t('interface:page.BankGuaranteeSignPage.description')}</Typography>
        <Grid container direction='row' spacing={{ md: 2, xs: 2 }}>
          <Grid item md={12} xs={12}>
            <Button onClick={handleNavigate} fullWidth variant='contained' size='large'>
              {t('interface:page.BankGuaranteeSignPage.continue')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
};

export default BankGuaranteeSignPage;
