import { Box, Grid } from '@mui/material';
import { getClients, getInsurancePackages } from 'api/services/agent';
import BaseInsuranceFilter from 'components/base/Insurance/Filter';
import BaseInsuranceListSearchAndFilter from 'components/base/Insurance/List/SearchAndFilter';
import BaseInsuranceListSorting from 'components/base/Insurance/List/Sorting';
import InsuranceProductCard from 'components/base/InsuranceProductCard';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY_ORDER_CREATE, PATH_AGENT_INSURANCE_PROPERTY_ORDER_SELECT, PATH_USER } from 'routes/paths';

const filtersConfig = {
  perils: [
    { fieldName: 'civilResponsibility', translationKey: 'perils.civilResponsibility' },
    { fieldName: 'structuralElements', translationKey: 'perils.structuralElements' },
    { fieldName: 'movableProperty', translationKey: 'perils.movableProperty' },
    { fieldName: 'decoration', translationKey: 'perils.decoration' },
  ],
  companies: [
    { fieldName: 'resoGarant', translationKey: 'companies.resoGarant' },
    { fieldName: 'ingosstrah', translationKey: 'companies.ingosstrah' },
    { fieldName: 'alfastrah', translationKey: 'companies.alfastrah' },
  ],
};

const DEFAULT_FILTERS = {
  companies: {
    alfastrah: true,
    ingosstrah: true,
    resoGarant: true,
  },
  perils: {
    civilResponsibility: false,
    decoration: false,
    movableProperty: false,
    structuralElements: false,
  },
};

const AgentInsurancePropertyProductListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { company } = useParams();
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState(null);
  const [clients, setClients] = useState(null);
  const [queryFilter, setQueryFilter] = useState(null);
  const [filters, setFilters] = useState({
    perils: DEFAULT_FILTERS.perils,
    companies: Object.fromEntries(Object.entries(DEFAULT_FILTERS.companies).map(([name]) => [name, company === 'all' || company === name])),
  });
  const [sortByCost, setSortByCost] = useState('asc');
  const [showFilters, setShowFilters] = useState(false);
  const { getServiceSettings } = useAuth();
  const serviceSettings = getServiceSettings('insProp');

  // Show/Hide filters
  const toggleFilters = () => {
    setShowFilters((current) => !current);
  };
  const applyFilters = (filters) => {
    setFilters(filters);
    toggleFilters();
  };

  const handleOrderButton = (e) => {
    if (searchParams.get('uuidClient')) {
      navigate(
        PATH_AGENT_INSURANCE_PROPERTY_ORDER_CREATE.replace(':uuidPackage', e.target.value).replace(
          ':uuidProfile',
          searchParams.get('uuidClient'),
        ),
      );
    } else {
      navigate(PATH_AGENT_INSURANCE_PROPERTY_ORDER_SELECT.replace(':uuidPackage', e.target.value));
    }
  };

  const handleSort = () => {
    setSortByCost(sortByCost === 'asc' ? 'desc' : 'asc');
  };

  const onChangeQuery = (e) => {
    setQueryFilter(e.target.value);
  };

  // Load service settings
  useEffect(() => {
    if (!serviceSettings?.availableCompanies) return;
    filtersConfig.companies = filtersConfig.companies.filter(({ fieldName }) => serviceSettings.availableCompanies.includes(fieldName));
  }, [serviceSettings]);

  // Load all data on fist render
  useEffect(() => {
    const loadPackages = (filters) => {
      const selectedCompanies = Object.keys(filters.companies).filter((key) => !!filters.companies[key]);
      const selectedPerils = Object.keys(filters.perils).filter((key) => !!filters.perils[key]);
      getInsurancePackages(selectedCompanies, selectedPerils, `price:${sortByCost}`)
        .then((res) => {
          const {
            body: { results = [] } = {},
          } = res;
          setProducts(results);
        })
        .catch((err) => {
          // TODO: Errors processing
          console.log(err);
        });
    };

    // TODO: Rewrite
    const loadInsuranceClients = () => {
      getClients()
        .then((res) => {
          const {
            body: { results = [] } = {},
          } = res;
          setClients(results);
          // if (searchParams.get('uuidClient')) {
          //   setSelectedClient(searchParams.get('uuidClient'));
          // }
        })
        .catch((err) => {
          // TODO: Errors processing
          console.log(err);
        });
    };

    if (!products) loadPackages(filters);
    if (!clients) loadInsuranceClients();
  }, [clients, filters, products, sortByCost]);

  // Triggers for load insurance packages
  useEffect(() => {
    const loadInsurancePackages = (filters) => {
      const selectedCompanies = Object.keys(filters.companies).filter((key) => !!filters.companies[key]);
      const selectedPerils = Object.keys(filters.perils).filter((key) => !!filters.perils[key]);
      getInsurancePackages(selectedCompanies, selectedPerils, `price:${sortByCost}`)
        .then((res) => {
          const {
            body: { results = [] } = {},
          } = res;
          setProducts(results);
        })
        .catch((err) => {
          // TODO: Errors processing
          console.log(err);
        });
    };

    loadInsurancePackages(filters);
  }, [filters, sortByCost]);

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyProductListPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyProductListPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.AgentInsurancePropertyProductListPage.headerTitle'), backRoute: PATH_USER }}
    >
      {showFilters ? (
        <BaseInsuranceFilter filtersConfig={filtersConfig} defaultValues={filters} applyFilters={applyFilters} onClose={toggleFilters} />
      ) : (
        <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
          <BaseInsuranceListSearchAndFilter toggleFilters={toggleFilters} onChangeQuery={onChangeQuery} />
          <BaseInsuranceListSorting handleSort={handleSort} title={t('interface:page.AgentInsurancePropertyProductListPage.sortByCost')} />
          <Grid container spacing={{ xs: '16px' }}>
            {products
              ?.filter((product) => {
                // TODO: Move this part to backend
                if (!queryFilter) return true;
                const searchSource = [t(`common:insurance.companies.${product?.company}`), product?.title].join(' ');
                return searchSource.toLocaleLowerCase().indexOf(queryFilter.toLocaleLowerCase()) > -1;
              })
              .map((product) => {
                return (
                  <Grid item xs={12} lg={6} xl={4} key={product?.uuid}>
                    <InsuranceProductCard item={product} button={{ handleClick: handleOrderButton }} key={product?.uuid} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}
    </Page>
  );
};

export default AgentInsurancePropertyProductListPage;
