import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Button, CircularProgress, Fade, Grid, IconButton, SvgIcon, Typography, useTheme } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { userUploadFile } from 'api/services/user';
import DeleteIcon from 'components/Icons/Delete';
import DownloadIcon from 'components/Icons/Download';
import SuccIcon from 'components/Icons/Succ';
import { ft } from 'components/base/FormGenerator/utils';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EUserRole } from 'api/services/user.dto';
import { useAuth } from 'components/providers/AuthProvider';

const { REACT_APP_STORAGE_URL: STORAGE_URL } = import.meta.env;

type Props = {
  path?: string[];
  fileLink?: string;
  labelUploaded?: string;
  allowDelete?: boolean;
  allowDownload?: boolean;
  'data-testid'?: string;
  allowInfo?: boolean;
  infoDescription?: string;
  onChange?: (fileUrl: string | null) => void;
  disabled?: boolean;
  handleCustomUpload?: (file: File) => void;
};

const UploadButton: React.FC<Props> = ({
  labelUploaded = 'interface:base.UploadButton.labelUploaded',
  allowDelete = false,
  allowDownload = false,
  disabled,
  allowInfo = false,
  path,
  fileLink,
  ['data-testid']: testId,
  infoDescription,
  onChange,
  handleCustomUpload,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const auth = useAuth();
  const theme = useTheme();
  const [isShowInfo, showInfo] = useState<boolean>(false);

  useEffect(() => {
    if (fileLink) setFileUrl(fileLink);
  }, [fileLink]);

  const handleDelete = () => {
    setFileUrl(null);
    if (onChange) onChange(null);
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files.item(0);
    if (!file) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      if (handleCustomUpload) {
        await handleCustomUpload(file);
        return;
      }

      const res = await userUploadFile({ resourceType: 'artifact', file });
      if (res.ok) {
        const body = res.body as { link: string };
        const link = `${STORAGE_URL}${body.link}`;
        setFileUrl(link);
        if (onChange) onChange(link);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {path && <Typography variant='h4m'>{t(`${ft(path as string[])}.title`)}</Typography>}
        {allowInfo && (
          <IconButton
            sx={{ color: '#DFC801' }}
            onClick={() => {
              showInfo(!isShowInfo);
            }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        )}
      </Box>
      {isShowInfo && (
        <Fade in={isShowInfo} timeout={1000}>
          <Grid item md={12} sx={{ textAlign: 'justify' }}>
            <Trans i18nKey={`profile:${infoDescription}`}>
              <Typography sx={{ fontWeight: 'bold', whiteSpace: 'pre-line' }} />
            </Trans>
          </Grid>
        </Fade>
      )}

      {fileUrl && (
        <Box
          sx={{
            pointerEvents: disabled ? 'none' : 'all',
            display: 'flex',
            alignItems: 'center',
            minHeight: '56px',
            justifyContent: 'space-between',
            padding: '15px',
            border: '1px solid',
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.primary.dark,
            borderRadius: '8px',
          }}
        >
          <Avatar
            variant="rounded"
            src={fileUrl.replace('orig', 'compressed').replace(/(png|jpeg)/, 'jpg')}
            sx={auth.hasRole(EUserRole.manager) ? { width: 150, height: 150 } : { width: 56, height: 56 }}
          >
            <ImageIcon />
          </Avatar>

          <Typography component='span' variant='body1'>
            {t(labelUploaded)}
          </Typography>
          <Box>
            {allowDownload && (
              <IconButton href={fileUrl} target='_blank'>
                <SvgIcon sx={{ height: '16px', width: '16px' }} component={DownloadIcon} stroke={theme.palette.primary.dark} />
              </IconButton>
            )}
            {allowDelete && (
              <IconButton onClick={handleDelete}>
                <SvgIcon sx={{ height: '10px', width: '10px' }} component={DeleteIcon} stroke={theme.palette.text.secondary} />
              </IconButton>
            )}
            {!allowDownload && !allowDelete && (
              <SvgIcon sx={{ height: '16px', width: '16px' }} component={SuccIcon} stroke={theme.palette.primary.dark} />
            )}
          </Box>
        </Box>
      )}

      {!fileUrl && (
        <Button fullWidth disabled={loading} variant='contained' color='secondary' size='large' component='label'>
          {loading ? <CircularProgress size={24} /> : t('interface:base.CommonButtonUpload.uploadFiles')}
          <input data-testid={testId} onChange={handleUpload} type='file' accept='application/pdf,image/*' hidden />
        </Button>
      )}
      {error && (
        <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
          {t(`errors:${error}`)}
        </Typography>
      )}
    </>
  );
};

export default UploadButton;
