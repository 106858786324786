import { Box, IconButton, Paper, SvgIcon, Typography, useTheme } from '@mui/material';
import { EUserRole } from 'api/services/user.dto';
import { Accessible } from 'components/providers/RoleAccessProvider';
import ProposeCardContent from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeCard.content';
import { MapIcons } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/icons';
import { ProposeCardCommonProps } from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/types';
import { Trans, useTranslation } from 'react-i18next';
import utils from 'utils';
import { usePropose } from '../../../ProposeProvider';
import EditIcon from '@mui/icons-material/Edit';

export default function ProposeCardDesktop(props: ProposeCardCommonProps) {
  const { bank, decision, uuid } = props;
  const proposeHook = usePropose();
  const { t } = useTranslation();
  const theme = useTheme();

  const monthPayment = decision?.monthlyPayment ? utils.format.price((decision?.monthlyPayment as number) / 100) : '-';
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '24px',
        borderRadius: '12px',
        height: '100%',
        width: 1,
        justfiyContent: 'space-between',
      }}
      variant='outlined'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', flexGrow: 0.7, paddingRight: '24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
            <SvgIcon component={MapIcons[`${bank}`]} sx={{ width: 48, height: 48 }} />
            <Typography variant='h4'>{t(`common:mortgage.banks.${bank}`)}</Typography>
          </Box>

          {props.statusPanel && <Box>{props?.statusPanel}</Box>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <ProposeCardContent {...decision} isShowPayment={false} />
          {props?.accordion}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 0.2 }}>
        <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
          {t('interface:base.ProposeCard.monthlyPayment')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end', gap: '8px', paddingBottom: '12px' }}>
          <Trans i18nKey='common:utils.numberFrom'>
            <Typography fontWeight={500} variant='h2'>
              {{ value: monthPayment }}
            </Typography>
          </Trans>
        </Box>
        {props.actionButton}
      </Box>

      {!props.selected && proposeHook.editPropose && (
        <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
          <Box>
            <IconButton
              aria-label='edit-manager'
              data-testid={`${bank}.edit-button`}
              onClick={() => {
                proposeHook.editPropose(uuid);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Accessible>
      )}
    </Paper>
  );
}
