import { Button, Stack, TextField, Box } from '@mui/material';
import { userGetContractByType, userSetContractDetails } from 'api/services/user';
import BaseSuggestionAddressInput from 'components/base/Suggestion/AddressInput';
import Page from 'components/layouts/Page';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';

const CONTRACT_TYPE = 'agentContract';
const DEFAULT_VALUES = {
  fullAddress: '',
  flat: '',
};

const UserAddressPage = () => {
  const { t } = useTranslation();
  const { isSuccess, data } = useFetch(() => userGetContractByType(CONTRACT_TYPE));
  const [isEditMode, setIsEditMode] = useState(false);
  const { handleSubmit, reset, setValue, control } = useForm({ defaultValues: DEFAULT_VALUES });

  useEffect(() => {
    if (isSuccess) reset(data?.details?.registrationPlace || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleSelectAddress = ({ fullAddress }) => {
    setValue('fullAddress', fullAddress);
  };
  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };
  const handleSubmitClick = () => {
    handleSubmit((data) => {
      // TODO: Error processing
      userSetContractDetails(CONTRACT_TYPE, { registrationPlace: data });
      setIsEditMode(false);
    })();
  };

  return (
    <Page
      title={t('helmet:UserAddressPage.title')}
      meta={[{ name: 'description', content: t('helmet:UserAddressPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.UserAddressPage.headerTitle'), backRoute: PATH_USER }}
    >
      <Box sx={{ display: 'flex', height: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
        {isSuccess && (
          <Stack direction='column' spacing={2}>
            <Controller
              name='fullAddress'
              control={control}
              render={({ field }) => (
                <BaseSuggestionAddressInput
                  label={t('interface:page.UserAddressPage.fullAddress')}
                  sx={{ width: 1 }}
                  disabled={!isEditMode}
                  onSelect={handleSelectAddress}
                  value={field?.value}
                />
              )}
            />
            <Controller
              name='flat'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={!isEditMode}
                  variant='standard'
                  onChange={onChange}
                  value={value}
                  label={t('interface:page.UserAddressPage.flat')}
                />
              )}
            />
          </Stack>
        )}

        <Stack sx={{ margin: '20px 0' }} spacing={2}>
          {!isEditMode && (
            <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
              {t('interface:page.UserAddressPage.editBtn')}
            </Button>
          )}

          {isEditMode && (
            <Button variant='contained' size='large' onClick={handleSubmitClick}>
              {t('interface:page.UserAddressPage.submitBtn')}
            </Button>
          )}

          {isEditMode && (
            <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
              {t('interface:page.UserAddressPage.cancelBtn')}
            </Button>
          )}
        </Stack>
      </Box>
    </Page>
  );
};

export default UserAddressPage;
