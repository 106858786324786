import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { getPolicies } from 'api/services/agent';
import BaseInsuranceFilter from 'components/base/Insurance/Filter';
import BaseInsuranceListSearchAndFilter from 'components/base/Insurance/List/SearchAndFilter';
import BaseInsuranceListSorting from 'components/base/Insurance/List/Sorting';
import BaseLinkRouter from 'components/base/Link/Router';
import BaseListEmpty from 'components/base/List/Empty';
import Page from 'components/layouts/Page';
import useFetch from 'hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE, PATH_AGENT_INSURANCE_PROPERTY_POLICY } from 'routes/paths';
import { getFilteredArrayOfOjects } from 'utils/filter';

import AgentInsurancePropertyPolicyList from './List';

const POLICY_FILTERS = {
  companies: {
    alfastrah: true,
    ingosstrah: true,
    resoGarant: true,
  },
  tags: {
    processing: false,
    paid: false,
  },
};

const filtersConfig = {
  companies: [
    { fieldName: 'alfastrah', translationKey: 'companies.alfastrah' },
    { fieldName: 'ingosstrah', translationKey: 'companies.ingosstrah' },
    { fieldName: 'resoGarant', translationKey: 'companies.resoGarant' },
  ],
  tags: [
    { fieldName: 'processing', translationKey: 'tags.waitingPayment' },
    { fieldName: 'paid', translationKey: 'tags.paid' },
  ],
};

const AgentInsurancePropertyPolicyListPage = () => {
  const { t } = useTranslation();
  const [policies, setPolicies] = useState([]);
  const [filters, setFilters] = useState(POLICY_FILTERS);
  const [sortByCreatedTime, setSortByCreatedTime] = useState('desc');
  const [queryFilter, setQueryFilter] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const load = async (filters) => {
    const selectedCompanies = Object.keys(filters.companies).filter((key) => !!filters.companies[key]);
    const selectedTags = Object.keys(filters.tags).filter((key) => !!filters.tags[key]);
    const res = await getPolicies(selectedCompanies, selectedTags, `createdAt:${sortByCreatedTime}`);

    setPolicies(res.body.results);
  };

  const { status, isLoading } = useFetch(() => load(filters));

  const toggleFilters = () => {
    setShowFilters((current) => !current);
  };
  const applyFilters = (filters) => {
    setFilters(filters);
    toggleFilters();
  };

  const handleSort = () => {
    setSortByCreatedTime(sortByCreatedTime === 'desc' ? 'asc' : 'desc');
  };

  const getFiltered = (policies) => {
    return getFilteredArrayOfOjects(
      policies,
      ['client.address', 'client.firstname', 'client.firstname', 'client.patronymic', 'product.data.title'],
      queryFilter,
    );
  };

  const onChangeQuery = (e) => {
    setQueryFilter(e.target.value);
  };

  const onPolicyDeleted = (uuid) => {
    setPolicies((state) => state.filter((policy) => policy.uuid !== uuid));
  };

  const hasItems = useMemo(() => policies.length > 0, [policies]);

  useEffect(() => {
    if (policies.length > 0) {
      load(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortByCreatedTime]);

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyPolicyListPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyPolicyListPage.description') }]}
      header={{
        type: showFilters ? 'backButton' : 'withNotification',
        title: t('interface:page.AgentInsurancePropertyPolicyListPage.headerTitle'),
        backRoute: PATH_AGENT_INSURANCE_PROPERTY_POLICY,
      }}
      sx={{ paddingBottom: '96px' }}
    >
      {!hasItems && !isLoading && (
        <BaseListEmpty
          containerStyle={{ flexGrow: 1 }}
          title={t('interface:page.AgentInsurancePropertyPolicyListPage.dontHaveClients')}
          button={{
            text: t('interface:page.AgentInsurancePropertyPolicyListPage.addClientBtn'),
            props: { startIcon: <AddIcon />, component: BaseLinkRouter, to: PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE },
          }}
        />
      )}

      {showFilters && hasItems && (
        <BaseInsuranceFilter defaultValues={filters} applyFilters={applyFilters} filtersConfig={filtersConfig} onClose={toggleFilters} />
      )}

      {!showFilters && hasItems && (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <BaseInsuranceListSearchAndFilter toggleFilters={toggleFilters} onChangeQuery={onChangeQuery} />

            <BaseInsuranceListSorting handleSort={handleSort} title={t('interface:page.AgentInsurancePropertyPolicyListPage.sortByDate')} />

            <AgentInsurancePropertyPolicyList items={getFiltered(policies)} onDeleted={onPolicyDeleted} status={status} />
          </Box>

          <div style={{ position: 'fixed', left: 0, bottom: '20px', width: '100%', padding: '0 20px' }}>
            <Button
              component={BaseLinkRouter}
              to={PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE}
              variant='contained'
              size='large'
              type='submit'
              fullWidth
              startIcon={<AddIcon />}
            >
              {t('interface:page.AgentInsurancePropertyPolicyListPage.addClientBtn')}
            </Button>
          </div>
        </Box>
      )}
    </Page>
  );
};

export default AgentInsurancePropertyPolicyListPage;
