import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

interface DateFieldProps {
  prefix: string;
  label: string;
  required?: boolean;
  defaultValue?: Date | string;
}

export const DateField: FC<DateFieldProps> = ({ prefix, label, defaultValue, required = false }) => {
  const { control } = useFormContext();

  const { t } = useTranslation();

  return (
    <Controller
      name={label}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={t(`${prefix}.${label}.title`)}
            inputFormat='dd.MM.yyyy'
            disableOpenPicker={true}
            mask='__.__.____'
            renderInput={(params) => (
              <TextField
                id={label}
                sx={{ width: 1 }}
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': label,
                  placeholder: t('base.DateField.placeholder'),
                }}
              />
            )}
            onChange={(date, inputStr) => {
              // TODO: Rewrite this schit
              if (!date || `${date}` === 'Invalid Date' || inputStr?.length !== '__.__.____'.length) return;
              const formatDate = utils.date.format(date, 'YYYY-MM-DD');
              onChange(formatDate);
            }}
            value={value ? dayjs(value) : null}
          />
        </LocalizationProvider>
      )}
    />
  );
};
