import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, Paper, SvgIcon, Typography, useTheme } from '@mui/material';
import DeleteIcon from 'components/Icons/Delete';
import { computedKeys } from 'components/base/FormGenerator/Elements/ComputedContainer';
import ComputableElement from 'components/base/FormGenerator/Elements/ComputedElement';
import { FormSchemeData, SchemeSettings } from 'components/base/FormGenerator/form.types';
import { ft } from 'components/base/FormGenerator/utils';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';

interface CardGroupItemProps {
  item: FormSchemeData;
  settings?: Partial<SchemeSettings>;
  path: string[];
  onSelect?: () => void;
  onDelete?: () => void;
  values: ObjectType[];
}

const CardGroupItem = ({ item, settings = {}, path, onSelect, onDelete, values }: CardGroupItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClick = onSelect;
  const handleDeleteClick = onDelete;

  const p = ft(path);
  const filteredValues = values?.filter((x) => !!x.value);

  return (
    <Paper
      elevation={0}
      key={p}
      sx={{
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: filteredValues?.length > 0 ? theme.palette.primary.dark : theme.palette.common.greyLight,
        borderRadius: '8px',
        padding: '24px 24px 24px 24px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '&:hover': {
          opacity: 0.8,
        },
      }}
    >
      <Box onClick={handleClick} sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ width: { xs: 1, md: 0.5 } }}>
            {settings?.hasCardTitle && (
              <Typography display='block' variant='h3m'>
                {t(`${p}.${t(`${p}.settings.cardTitle`, item)}.title`)}
              </Typography>
            )}

            {settings?.hasCardSubtitle && (
              <Typography display='block' color={theme.palette.text.secondary} variant='h5'>
                {t(`${p}.settings.cardSubtitle`, item)}
              </Typography>
            )}

            <Fragment>
              {item.settings?.children?.map((child) => (
                <ComputableElement
                  key={computedKeys(child.props)}
                  path={path as string[]}
                  node={item}
                  fieldValues={filteredValues}
                  renderOption={child}
                />
              ))}

              {/* {settings?.hasCardDescription && (
            <Typography display='block' variant='h5'>
              {t(`${p}.settings.cardDescription`, item)}
            </Typography>
          )} */}
            </Fragment>
          </Box>
          {!settings.useRadio && (
            <IconButton onClick={handleClick}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {onDelete && (
        <IconButton sx={{ padding: 0 }} onClick={handleDeleteClick}>
          <SvgIcon sx={{ height: '10px', width: '10px' }} component={DeleteIcon} stroke={theme.palette.text.secondary} />
        </IconButton>
      )}
    </Paper>
  );
};

export default CardGroupItem;
