import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import MortgageAccessProvider from 'components/providers/AccessProviders/MortgageAccessProvider';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_CREATE_SUCCESS, PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_HOME } from 'routes/paths';
import utils from 'utils';

import { useRoleAccess } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';
import MortgageEntryParamsForm from './components/MortgageEntryParams';
import UnitBillet from 'components/base/UnitBillet';
import { ECMortgageEntryParams, ecMortgageEntryParams } from 'api/services/mortgage/mortgage.schema';
import { postMortgageEntryParams, putMortgageEntryParams } from 'api/services/mortgage/mortgage.mutation';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useAuth } from 'components/providers/AuthProvider';
import {
  EMortgageEntryInitialPaySourceType,
  EMortgageEntryParamsPurpose,
  EMortgageEntryParamsSubjectSubtype,
} from 'api/services/mortgage/mortgage.enum';
import { entryGet } from 'api/services/mortgage';
import { zodResolver } from '@hookform/resolvers/zod';
type FormType = 'create' | 'edit';

const ClientMortgageEntryCreatePage = ({ formType = 'create' }: { formType?: FormType }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { uuid, uuidUser } = useParams();
  const access = useRoleAccess([EUserRole.manager, EUserRole.agent]);
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.up('sm'));
  const handleNavigateProgress = () => {
    navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid }));
  };
  const hookForm = useForm<ECMortgageEntryParams>({
    defaultValues: {
      insurance: { life: false, property: false, title: false },
      isMortgageWithTwoDocs: false,
      initialPaymentSources: [],
    },
    resolver: zodResolver(ecMortgageEntryParams),
    shouldUnregister: true,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const initialPayment = hookForm.watch('initialPayment');
  const subjectPrice = hookForm.watch('subject.price');

  const payment = useMemo(() => {
    return (initialPayment / subjectPrice) * 100 * 100;
  }, [initialPayment, subjectPrice]);

  const canClickButton = useMemo(() => {
    if (access.accessible || !uuidUser) {
      return true;
    }
    const uuidCurrentUser = auth?.user?.uuid;
    return uuidCurrentUser === uuidUser;
  }, [uuidUser, access.accessible]);

  const handleCreateEntry = async (values: ECMortgageEntryParams) => {
    const users = [];
    if (uuidUser) {
      users.push({ role: EMortgageEntryRole.borrower, uuidUser });
    }
    return postMortgageEntryParams({ users, params: values });
  };
  const handleUpdateEntry = async (values: ECMortgageEntryParams) => {
    return await putMortgageEntryParams(uuid!, values);
  };

  const handleSubmit = async () => {
    const values = hookForm.getValues();
    if (!values.subject.subtype) {
      values.subject.subtype = EMortgageEntryParamsSubjectSubtype.secondary;
    }
    if (!values.initialPaymentSources) {
      values.initialPaymentSources = [];
    }
    if (values.initialPaymentSources.length > 0) {
      const item = values.initialPaymentSources[0];
      const hasAmount = !item.amount || item?.amount < 1;
      if (item.type === EMortgageEntryInitialPaySourceType.maternityCapital && hasAmount) {
        values.initialPaymentSources = [];
      }
    }
    if (values.special === '') {
      values.special = null;
    }
    if (values.mortgagePeriod) {
      values.mortgagePeriod *= 12;
    }
    try {
      const action = formType === 'create' ? handleCreateEntry : handleUpdateEntry;
      const result = await action(values);

      if (formType === 'edit') {
        navigate(-1);
        return;
      }
      const user = result.body.users.find((x) => x.role === EMortgageEntryRole.borrower);
      navigate(
        utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_CREATE_SUCCESS, {
          uuid: result.body.uuid,
          uuidProfile: user?.uuidUser,
        }),
      );
    } catch (err) {}
  };

  const loadAsync = async () => {
    try {
      const { body: mortgage } = await entryGet(uuid!);

      if (mortgage.params.mortgagePeriod) {
        mortgage.params.mortgagePeriod /= 12;
      }
      hookForm.reset(mortgage.params);
    } catch (err) {}
  };

  useEffect(() => {
    if (uuid) loadAsync();
  }, []);
  const subjectType = hookForm.watch('purpose');

  const hasErrors = Object.keys(hookForm.formState.errors).length > 0;

  return (
    <FormProvider {...hookForm}>
      <MortgageAccessProvider protectedRoles={[EMortgageEntryRole.borrower, EMortgageEntryRole.agent]}>
        <ContainerLayout
          title={t('helmet:ClientMortgageEntryCreatePage.title')}
          meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreatePage.description') }]}
          header={{
            xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntryCreatePage.title')} />,
            lg: (
              <TopMenu
                sx={{ zIndex: 9999 }}
                breadcrumbs={[
                  { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },
                  {
                    title: t('interface:base.TopMenu.routes.progress'),
                    activeLink: formType === 'create',
                    action: formType !== 'create' ? handleNavigateProgress : undefined,
                  },
                  { title: t('interface:base.TopMenu.routes.creditParams'), activeLink: true },
                ]}
              />
            ),
          }}
          sx={{ height: 1, width: 1 }}
          maxWidth='xl'
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <MortgageEntryParamsForm />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <UnitBillet
                title={t('interface:page.ClientMortgageEntryCreatePage.calculations.amountLoanTotal')}
                amount={subjectPrice - initialPayment}
                direction={media ? 'row' : 'column'}
                loading={subjectPrice - initialPayment < 0 || isNaN(subjectPrice - initialPayment)}
                additional={
                  subjectType !== EMortgageEntryParamsPurpose.homeownerLoan
                    ? [
                        {
                          loading: isNaN(payment),
                          title: t('interface:page.ClientMortgageEntryCreatePage.form.initialPayment'),
                          ammount: utils.format.percent(payment),
                        },
                      ]
                    : []
                }
                sx={{ justifyContent: 'space-between' }}
              />
              {canClickButton && (
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  disabled={hasErrors}
                  onClick={handleSubmit}
                  data-testid="mortgage.create"
                >
                  {t('interface:page.ClientMortgageEntryCreatePage.btnCreate')}
                </Button>
              )}
            </Grid>
          </Grid>
        </ContainerLayout>
      </MortgageAccessProvider>
    </FormProvider>
  );
};

export default ClientMortgageEntryCreatePage;
