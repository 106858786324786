import { Alert, Button, Input, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getPolicy } from 'api/services/agent';
import SadEmojiIcon from 'components/Icons/SadEmoji';
import QRCode from 'components/base/QRCode';
import Page from 'components/layouts/Page';
import useCopy from 'hooks/useCopy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export type TLocationParams = {
  uuidOrder: string;
};

const hasShareAPI = !!navigator.share;

const AgentInsurancePropertyOrderSharePage = () => {
  const { t } = useTranslation();
  // TODO: API Typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [order, setOrder] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { uuidOrder } = useParams<keyof TLocationParams>() as TLocationParams;
  const [copied, copy, setCopied] = useCopy(order?.purchase?.data?.url);

  const isLinkReady = (): boolean => {
    return !!order?.purchase?.data?.url;
  };

  const loadPackage = async () => {
    setLoading(true);
    try {
      const res = await getPolicy(uuidOrder);
      const { body } = res;
      setOrder(body);
    } catch (err) {
      setError('logic.badConnection');
      console.log('error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!order) loadPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleLinkInputClick = () => {
    copy();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  const handleShareButtonClick = () => {
    if (!hasShareAPI) return;
    navigator.share({ url: order?.purchase?.data?.url });
  };

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyOrderSharePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyOrderSharePage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.AgentInsurancePropertyOrderSharePage.headerTitle') }}
      sx={{ gap: 2, paddingBottom: '20px', justifyContent: 'space-between', height: 1 }}
    >
      {/* TODO: Alert refactoring */}

      {copied && (
        <Alert sx={{ position: 'absolute', top: '20px', left: '20px', width: 'calc(100% - 40px)' }} severity='info' variant='filled'>
          {t('interface:page.AgentInsurancePropertyOrderSharePage.copiedLbl')}
        </Alert>
      )}
      {loading && (
        <Stack sx={{ height: 1, gap: 6, justifyContent: 'center' }}>
          <Typography textAlign={'center'} variant='h3m'>
            <Skeleton animation='wave' height={40} width={'100%'} />
          </Typography>
          <Skeleton animation='wave' variant='rectangular' width={'100%'} height={250} />
        </Stack>
      )}
      {!loading && !isLinkReady() && (
        <Stack sx={{ height: 1, gap: 6, justifyContent: 'center' }}>
          <Typography textAlign={'center'} variant='h3m'>
            {t('interface:page.AgentInsurancePropertyOrderSharePage.titleNotReady')}
          </Typography>
          <SadEmojiIcon sx={{ width: 1, minHeight: '250px' }} />
        </Stack>
      )}
      {!loading && isLinkReady() && (
        <>
          <Typography textAlign={'center'} variant='h3m'>
            {t('interface:page.AgentInsurancePropertyOrderSharePage.titleReady')}
          </Typography>
          <Box alignItems='center' justifyContent='center' display='flex'>
            <QRCode url={order?.purchase?.data?.url} />
          </Box>
          <Stack gap={2}>
            <Input variant='shareLink' value={order?.purchase?.data?.url} onClick={handleLinkInputClick} />
            {hasShareAPI && (
              <Button variant='contained' size='large' onClick={handleShareButtonClick}>
                {t('interface:page.AgentInsurancePropertyOrderSharePage.shareBtn')}
              </Button>
            )}
          </Stack>
        </>
      )}
    </Page>
  );
};

export default AgentInsurancePropertyOrderSharePage;
