import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Fab, Typography, useTheme } from '@mui/material';
import { LS_TUTORIAL } from 'api/http';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';

export const useSwipe = ({ left, right, up, down }: { left?: () => void; right?: () => void; up?: () => void; down?: () => void }) => {
  const touchCoordsRef = useRef({ touchStart: { x: 0, y: 0, time: Date.now() } });
  const fnsRef = useRef({ up, down, left, right });
  fnsRef.current = {
    up,
    left,
    down,
    right,
  };
  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      touchCoordsRef.current.touchStart.x = e.targetTouches[0].clientX;
      touchCoordsRef.current.touchStart.y = e.targetTouches[0].clientY;
      touchCoordsRef.current.touchStart.time = Date.now();
    };
    const handleTouchEnd = (e: TouchEvent) => {
      const threshold = 150;
      const swipeSpeed = 1; // sec;
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      const touchStartX = touchCoordsRef.current.touchStart.x;
      const touchStartY = touchCoordsRef.current.touchStart.y;
      const elapsedTime = (Date.now() - touchCoordsRef.current.touchStart.time) / 1000;
      if (elapsedTime > swipeSpeed) {
        return;
      }
      const xDistance = touchStartX - touchEndX;
      const yDistance = touchStartY - touchEndY;

      if (Math.abs(xDistance) < threshold && Math.abs(yDistance) < threshold) {
        return;
      }

      if (Math.abs(xDistance) >= Math.abs(yDistance)) {
        xDistance > 0 ? fnsRef.current.right?.() : fnsRef.current.left?.();
      } else {
        yDistance > 0 ? fnsRef.current.down?.() : fnsRef.current.up?.();
      }
    };
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  });
};

const Steps = ({ activeId, stepWidth }: { activeId: number; stepWidth: string }) => {
  const theme = useTheme();
  const steps = new Array(6).fill(null).map((_, idx) => ({ id: idx }));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', justifyContent: 'center', width: '100%' }}>
      {steps.map((step) => (
        <Box
          key={step.id}
          sx={{
            width: stepWidth,
            height: `2px`,
            background: theme.palette.common.black,
            opacity: activeId === step.id ? 1.0 : 0.3,
            borderRadius: '102px',
          }}
        />
      ))}
    </Box>
  );
};

export default function NewTutorial() {
  const [current, setCurrent] = useState(0);
  const steps = ['progress', 'propose', 'sign', 'object', 'insurance', 'manager'];
  const ref = useRef<HTMLDivElement>();
  const pathname = window.location.pathname.split('/');

  const stepName: Record<string, string> = {
    progress: 'Автозаполнение анкеты',
    propose: 'Предложения банков',
    sign: 'Электронное подписание',
    object: 'Одобрение недвижимости',
    insurance: 'Страхование',
    manager: 'Личный менеджер',
  };
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (steps[current] === 'progress') {
      if (ref.current && counter === 5) {
        ref.current.scroll({ top: window.innerHeight, left: 0, behavior: 'smooth' });
      }
    }
  }, [current, counter]);

  useEffect(() => {
    const p = pathname[pathname.length - 1];
    const idx = steps.indexOf(p);
    if (idx !== -1) {
      setCurrent(idx);
    }
  }, [pathname]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => {
        return counter + 1;
      });
    }, 700);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(LS_TUTORIAL, 'true');
  }, []);

  const { t } = useTranslation();
  useSwipe({
    right: () => {
      setCurrent((c) => c + 1);
      setCounter(0);
      navigate(`/onboarding/${steps[current + 1]}`);
    },
    left: () => {
      setCurrent((c) => c - 1);
      setCounter(0);
      navigate(`/onboarding/${steps[current - 1]}`);
    },
  });

  return (
    <ContainerLayout
      title={t('helmet:AgentHomePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentHomePage.description') }]}
      noContainer
      sx={{ overflow: 'hidden', height: '100%', position: 'relative' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: 1, padding: '8px 0' }}>
        <Button
          component='span'
          sx={{ color: theme.palette.text.secondary }}
          onClick={() => {
            navigate(PATH_HOME);
          }}
        >
          {t('page.TutorialPage.skip')}
        </Button>
      </Box>
      <Steps activeId={current} stepWidth={'60px'} />

      <Fragment>
        <Box sx={{ padding: '10px', width: 1, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>{stepName[steps[current]]}</Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: 'common.white',
            margin: { md: '0 auto', xs: '0 auto' },
            borderRadius: '40px',
            height: { xs: '680px', md: '900px' },
            width: { md: '400px', xs: '90%' },
            border: '2px solid #0C0B02',
            overflowY: 'scroll',
            pointerEvents: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Outlet />
        </Box>
      </Fragment>
      <Box
        sx={{
          position: 'absolute',
          top: '400px',
          borderRadius: '50%',
          left: '-300px',
          height: '1000px',
          width: '1000px',
          background: '#F8EE5A',
          zIndex: -100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
      <Fab
        onClick={() => {
          setCurrent((c) => c + 1);
          setCounter(0);

          navigate(steps[current + 1] ? `/onboarding/${steps[current + 1]}` : PATH_HOME);
        }}
        aria-label='add'
        sx={{
          position: 'absolute',
          right: '30px',
          bottom: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#000',
          backgroundColor: 'transparent',
          border: '2px solid white',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ArrowForwardIosIcon />
      </Fab>
      <Fab
        onClick={() => {
          setCurrent((c) => c - 1);
          setCounter(0);

          navigate(steps[current - 1] ? `/onboarding/${steps[current - 1]}` : PATH_HOME);
        }}
        aria-label='add'
        sx={{
          position: 'absolute',
          left: '30px',
          bottom: '50px',
          color: '#000',
          backgroundColor: 'transparent',
          border: '2px solid white',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
      </Fab>
    </ContainerLayout>
  );
}
