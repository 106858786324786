import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import {
  EFieldInputType,
  ERequestedDataType,
  IBCRequestCreate,
  TRequestedData,
} from 'api/services/businessCredit/businessCredit.propose.dto';
import businessCreditService from 'api/services/businessCredit/businessCredit.service';
import { EUserRole } from 'api/services/user.dto';
import DadataSuggestion from 'components/base/DadataSuggestion';
import DatePickerInput from 'components/base/DatePickerInput';
import UnitSlider from 'components/base/UnitSlider';
import { Accessible } from 'components/providers/RoleAccessProvider';
import useTypedParams from 'hooks/useTypedParams';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { PATH_BUSINESS_CREDIT_PROPOSE } from 'routes/paths';
import utils from 'utils';

import ProposeRequestField from '../RequestField';
import ProposeRequestItem from '../RequestItem';

interface Props {
  uuidPropose: string;
}

const ProposeRequest = ({ uuidPropose }: Props) => {
  const { handleSubmit, control, getValues, watch } = useForm<IBCRequestCreate>({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });

  const [isOpen, setOpen] = useState(false);
  const [requestType, setRequestType] = useState<ERequestedDataType>();
  const [requestInputType, setRequestInputType] = useState<EFieldInputType>();
  const [proposeRequestList, setProposeRequestList] = useState<TRequestedData[]>([]);
  const [selectValueArr, setSelectValueArr] = useState<string[]>([]);

  const { uuidEntry } = useTypedParams<typeof PATH_BUSINESS_CREDIT_PROPOSE>();

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isAddNewSelectValueButtonDisabled = () => {
    const values = getValues();

    //@ts-ignore
    if (!values?.data?.inputType) {
      return true;
    }

    if (
      //@ts-ignore
      values.data.inputType === EFieldInputType.textField ||
      //@ts-ignore
      values.data.inputType === EFieldInputType.date ||
      //@ts-ignore
      values.data.inputType === EFieldInputType.number
    ) {
      return true;
    }

    //@ts-ignore
    if (values.data.inputType === EFieldInputType.checkbox && selectValueArr.length > 0) {
      return true;
    }

    //@ts-ignore
    if (values.data.inputType === EFieldInputType.radio && selectValueArr.length > 0) {
      return true;
    }

    //@ts-ignore
    if (values.data.inputType === EFieldInputType.slider && selectValueArr.length > 1) {
      return true;
    }

    return false;
  };

  const isSubmitButtonDisabled = () => {
    const values = getValues();

    if (!values.type) {
      return true;
    }

    // @ts-ignore
    if (values.type === ERequestedDataType.field && !values?.data?.inputType) {
      return true;
    }

    return false;
  };

  const onSubmit = () => {
    putNewProposeRequest();
    handleClose();
    getProposeRequests();
  };

  const putNewProposeRequest = async () => {
    const proposeRequest = getValues();
    await businessCreditService.putProposeRequest(uuidEntry, uuidPropose, [
      { type: proposeRequest.type, data: { ...proposeRequest.data, selectValue: selectValueArr } },
    ]);
  };

  const getProposeRequests = async () => {
    const res = await businessCreditService.getProposeRequest(uuidEntry, uuidPropose);
    setProposeRequestList(res.body);
  };

  const deleteRequestItem = async (uuidRequest: string) => {
    await businessCreditService.deleteProposeRequestField(uuidEntry, uuidPropose, uuidRequest);
    getProposeRequests();
  };

  useEffect(() => {
    getProposeRequests();
  }, []);

  useEffect(() => {
    getProposeRequests();
  }, [isOpen]);

  useEffect(() => {
    const watcher = watch(() => {
      const formValues = getValues();
      setRequestType(formValues.type);
      if (formValues.type === ERequestedDataType.field) {
        // @ts-ignore
        setRequestInputType(formValues.data.inputType);
      }
    });
    return watcher.unsubscribe;
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            {proposeRequestList?.map((request) => (
              <ProposeRequestItem props={request} onDelete={() => deleteRequestItem(request.uuid)} />
            ))}
          </Box>
        </Accessible>

        <Accessible requireRoles={[EUserRole.client]}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            {proposeRequestList?.map((request) => (
              <ProposeRequestField data={request} uuidPropose={uuidPropose} />
            ))}
          </Box>
        </Accessible>

        <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
          <Button data-testid='propose.sendbank' size='large' variant='contained' color='primary' fullWidth onClick={handleOpen}>
            {t('interface:base.ProposeRequest.request')}
          </Button>
        </Accessible>

        <Dialog
          sx={{
            '.MuiPaper-root': {
              maxWidth: '1200px',
            },
          }}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby='propose-selector-title'
          aria-describedby='propose-selector-description'
          maxWidth='xl'
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id='propose-selector-title'>
              <Typography sx={{ fontWeight: 500, fontSize: '26px' }}>{t('interface:base.ProposeRequest.title')}</Typography>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', width: { xs: '100%', lg: '500px' } }}>
                <Controller
                  control={control}
                  name='type'
                  render={({ field, fieldState }) => (
                    <TextField
                      select
                      label={t('interface:base.ProposeRequest.fieldType')}
                      variant='standard'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      {...field}
                    >
                      {Object.values(ERequestedDataType).map((option) => (
                        <MenuItem key={option} value={option}>
                          {t(`interface:base.ProposeRequest.types.${option}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                {/* Document fields start */}
                {requestType === ERequestedDataType.document && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Controller
                      control={control}
                      name='data.type'
                      render={({ field, fieldState }) => (
                        <TextField
                          select
                          label={t('interface:base.ProposeRequest.document.type')}
                          variant='standard'
                          fullWidth
                          required
                          error={!!fieldState.error}
                          {...field}
                        >
                          <MenuItem key={'other'} value={'other'}>
                            {t('interface:base.ProposeRequest.documentType.other')}
                          </MenuItem>
                        </TextField>
                      )}
                    />

                    <Controller
                      control={control}
                      name='data.otherType'
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('interface:base.ProposeRequest.document.otherType')}
                          variant='standard'
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                )}
                {/* Document fields end */}

                {/* Field fields start */}
                {requestType === ERequestedDataType.field && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <Controller
                      control={control}
                      name='data.inputType'
                      render={({ field, fieldState }) => (
                        <TextField
                          select
                          label={t('interface:base.ProposeRequest.fieldType')}
                          variant='standard'
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        >
                          {Object.values(EFieldInputType).map((option) => (
                            <MenuItem key={option} value={option}>
                              {t(`interface:base.ProposeRequest.${option}`)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name='data.type'
                      render={({ field, fieldState }) => (
                        <TextField
                          select
                          label={t('interface:base.ProposeRequest.field.fieldTitle')}
                          variant='standard'
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        >
                          <MenuItem key={'other'} value={'other'}>
                            {t('interface:base.ProposeRequest.documentType.other')}
                          </MenuItem>
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name='data.otherType'
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('interface:base.ProposeRequest.field.fieldTitle')}
                          variant='standard'
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                )}

                {requestType === ERequestedDataType.field && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '26px' }}>
                      {t('interface:base.ProposeRequest.field.fieldSelectValue')}
                    </Typography>

                    {selectValueArr.map((selectValue, index) => (
                      <Box>
                        {selectValue}
                        <IconButton
                          onClick={() => {
                            setSelectValueArr(selectValueArr.filter((_, i) => i !== index));
                          }}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      </Box>
                    ))}

                    <Controller
                      control={control}
                      name='data.selectValue'
                      render={({ field, fieldState }) => (
                        <TextField
                          // label={t('interface:base.ProposeRequest.fieldType')}
                          variant='standard'
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        />
                      )}
                    />

                    <Button
                      variant='contained'
                      type='button'
                      fullWidth
                      disabled={isAddNewSelectValueButtonDisabled()}
                      //@ts-ignore
                      onClick={() => setSelectValueArr((old) => [...old, getValues().data.selectValue])}
                    >
                      {t('interface:base.ProposeRequest.field.addNewSelectValue')}
                    </Button>
                  </Box>
                )}

                {requestType === ERequestedDataType.field && (
                  <Typography sx={{ fontWeight: 500, fontSize: '26px' }}>
                    {t('interface:base.ProposeRequest.field.fieldExapmple')}
                  </Typography>
                )}

                {requestInputType === EFieldInputType.suggestion && requestType !== ERequestedDataType.document && (
                  <DadataSuggestion
                    variant='standard'
                    // onInputChange={field.onChange}
                    // onQuery={suggestSearchCompany}
                    path={['name']}
                    label={t(
                      'interface:base.ProposeRequest.input.suggestion',
                    )}
                    // onSelect={handleSelect}
                    // value={field?.value}
                  />
                )}

                {requestInputType === EFieldInputType.textField && requestType !== ERequestedDataType.document && (
                  <TextField label={t('interface:base.ProposeRequest.input.textField')} variant='standard' fullWidth />
                )}

                {requestInputType === EFieldInputType.number && requestType !== ERequestedDataType.document && (
                  <NumberFormat
                    variant='standard'
                    fullWidth
                    label={t('interface:base.ProposeRequest.input.number')}
                    customInput={TextField}
                  />
                )}

                {requestInputType === EFieldInputType.checkbox && requestType !== ERequestedDataType.document && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} />
                    <Typography display='block' variant='h3m'>
                      {selectValueArr[0]}
                    </Typography>
                  </Box>
                )}

                {requestInputType === EFieldInputType.selector && requestType !== ERequestedDataType.document && (
                  <TextField select label={t('interface:base.ProposeRequest.input.select')} variant='standard' fullWidth>
                    {selectValueArr.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                {requestInputType === EFieldInputType.radio && requestType !== ERequestedDataType.document && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Radio />
                    <Typography display='block' variant='h3m'>
                      {selectValueArr[0]}
                    </Typography>
                  </Box>
                )}

                {requestInputType === EFieldInputType.date && requestType !== ERequestedDataType.document && (
                  <DatePickerInput label='Дата окончания' />
                )}

                {requestInputType === EFieldInputType.slider && requestType !== ERequestedDataType.document && (
                  <Box sx={{ width: '100%' }}>
                    <UnitSlider
                      inputMask='currency'
                      unitLabel='₽'
                      label={t('interface:base.ProposeRequest.input.slider')}
                      threshold={1}
                      min={selectValueArr ? +selectValueArr[0] : 0}
                      max={selectValueArr ? +selectValueArr[1] : 1000000}
                      marks={[
                        {
                          value: selectValueArr ? +selectValueArr[0] : 0,
                          label: <Box>от {selectValueArr ? +selectValueArr[0] : 0} ₽</Box>,
                        },
                        {
                          value: selectValueArr ? +selectValueArr[1] : 1000000,
                          label: <Box>до {selectValueArr ? +selectValueArr[1] : 1000000} ₽</Box>,
                        },
                      ]}
                      step={100_000}
                      period={100}
                      format={(n) => utils.format.price(n)}
                      handleChangePeriod={() => {}}
                    />
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' type='submit' disabled={isSubmitButtonDisabled()} fullWidth>
                {t('interface:base.ProposeRequest.add')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default ProposeRequest;
