import { EProfileDocumentPart } from 'api/services/realEstate/realEstate.enum';

import { EProfileWorkspaceType } from '../incomes/incomes.enum';
import { EMortgageEntryRole } from '../mortgage.dto';
import { EProfileEducationType, EProfileMarriageContractStatus, EProfileWorkspaceJobSchema } from '../profile/profile.enum';

export interface IExperimentQueryParams {
  uuidEntry: string;
  uuidUser: string;
}

export interface DocumentProgress {
  quarter: string;
  year: string;
  type: EProfileDocumentType;
  part?: EProfileDocumentPart;
  isPresent: true;
  parts?: DocumentProgress[];
}

export interface DocumentExperimentPart {
  type: string;
  order: number;
  uploaded: boolean;
}

export interface DocumentSource {
  uuid: string;
  recognizedAtPage: string;
  url: string;
  previewImg: string;
  recognitionStatus: EDocRecognitionStatus;
  classificationStatus: EDocRecognitionStatus;
  type: EProfileDocumentType;
  data?: string;
  isParent: true;
  part?: ERequiredPassportParts;
  year: string;
  quarter: string;
}

export interface QuestionarieResponse {
  data: IFillingQuestionnaireDTO;
  isFull: boolean;
}

export enum EDocRecognitionStatus {
  awaiting = 'awaiting',
  success = 'success',
  failed = 'failed',
  skipped = 'skipped',
}

export interface IFillingQuestionnaireDTO {
  altLastname?: string;
  altFirstname?: string;
  altPatronymic?: string;
  haveAlterationOfName: boolean;
  primaryEducation: EProfileEducationType;
  marriageContractStatus: EProfileMarriageContractStatus;
  isMarried: boolean;
  haveFamilyChildren: boolean;
  childrenBirthDate: string[];
  jobType: EProfileWorkspaceType;
  jobSchema: EProfileWorkspaceJobSchema;
  companyName?: string;
  companyInn?: string;
  companyPhone?: number;
  companyAddress?: string;
  contractStartDate?: string;
  companySharePercent?: number;
  salaryAmount: number;
}

export interface IExperimentUserInvite {
  lastname: string;
  firstname: string;
  patronymic: string;
  email: string;
  phone: number;
  entryRole: EMortgageEntryRole;
}

export enum EProfileDocumentType {
  accountStatement51 = 'accountStatement51',
  bankStatement = 'bankStatement',
  unknown = 'unknown',
  foreignPassport = 'foreignPassport',
  passport = 'passport',
  drivingLicense = 'drivingLicense',
  internationalPassport = 'internationalPassport',
  militaryID = 'militaryID',
  balanceSheet = 'balanceSheet',
  inn = 'inn',
  employmentRecordBook = 'employmentRecordBook',
  snils = 'snils',
  pfr = 'pfr',
  alterationOfName = 'alterationOfName',
  other = 'other',
  incomeReport = 'incomeReport',
  incomeFormNdfl = 'incomeFormNdfl',
  incomeAccountStatement = 'incomeAccountStatement',
  incomeTaxDeclaration = 'incomeTaxDeclaration',
  incomeEnquiryEmployer = 'incomeEnquiryEmployer',
  // experiment
  egrnip = 'egrnip',
  egrul = 'egrul',
  formCertificate = 'formCertificate',
}

export enum ERequiredPassportParts {
  unknown = 'unknown',
  blank = 'blank',
  passportP2_P3 = 'passportP2_P3',
  passportP4_P5 = 'passportP4_P5',
  passportP6_P7 = 'passportP6_P7',
  passportP8_P9 = 'passportP8_P9',
  passportP10_P11 = 'passportP10_P11',
  passportP12_P13 = 'passportP12_P13',
  passportP14_P15 = 'passportP14_P15',
  passportP16_P17 = 'passportP16_P17',
  passportP18_P19 = 'passportP18_P19',
}

export interface IPreloadDocument {
  name: string;
  uuid: string;
  loaded: boolean;
}
