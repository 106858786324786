/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const ArrowUpIcon = createSvgIcon(
  <svg viewBox='-6 -12 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M17 7L9 1L1 7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'ArrowUpIcon',
);

export default ArrowUpIcon;
