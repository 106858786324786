import { Done } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { EContractStatus, EContractType } from 'api/services/contract.dto';
import { IProfileProgressMortgage, IProfileProgressUserData } from 'api/services/mortgage.dto';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import StatusDot from 'components/base/StatusDot';
import { useAuth } from 'components/providers/AuthProvider';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { approveContract, declineContract } from 'api/services/contract';
import { useMortgage } from 'components/providers/MortageEntryProvider';
import { Accessible, useRoleAccess } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';
import { useSnackbar } from 'notistack';
import useCopy from 'hooks/useCopy';
import MortgageEntryExperimentPage from '../../../Experiment/Profile';

interface IProps {
  expanded: boolean;
  profile: IProfileProgressUserData;
  children?: React.ReactNode;
  handleClick: (step: string, substep: string, profile: IProfileProgressUserData) => () => void;
  handleExpand: (_event: React.SyntheticEvent, isExpanded: boolean) => void;
  disabled?: boolean;
  sucesssSx?: SxProps;
}

const ProfileStepProgress = ({ expanded, profile, children, handleClick, handleExpand, disabled, sucesssSx }: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const mortgage = useMortgage();
  const [isCopiedCreditProfile, copyCreditProfile, setCopiedCreditProfile] = useCopy(
    `${import.meta.env.REACT_APP_HOST}/mortgage/${mortgage?.uuid}/sign/entry?step=preview`,
  );
  const { accessible: hasAccess } = useRoleAccess([EUserRole.admin, EUserRole.agent, EUserRole.manager]);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);

  const contract = useMemo(() => {
    const contract = profile?.contracts.find((x) => x.type === EContractType.creditProfileContract);
    return contract;
  }, [profile]);

  const getSubStepProgressColor = (substep: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const p = (profile.progress as any)[substep];
    if (p === 100) return theme.palette.success.main;
    if (p > 0) return theme.palette.primary.main;
    return theme.palette.text.secondary;
  };

  const calcStepProgress = (progress: IProfileProgressMortgage): number => {
    if (!progress) return 0;
    const maxSum = Object.values(progress).length * 100;
    const sum = Object.values(progress).reduce<number>((partialSum, a) => partialSum + (a as number), 0);
    const result = (sum / maxSum) * 100;
    return Math.round(result);
  };

  const isDisabledStep = (substep: string) => {
    const isOwner = auth?.user?.uuid === profile?.ids?.uuidUser;

    switch (substep) {
      case 'verification': {
        if (contract?.status === EContractStatus.verification) {
          return false;
        }
        return contract?.status !== EContractStatus.draft || !isOwner;
      }
      case 'signing':
        return contract?.status !== EContractStatus.ready || !isOwner;
      default:
        return contract?.status !== EContractStatus.draft;
    }
  };
  const isSuccess = calcStepProgress(profile.progress) === 100;

  const creditProfileContract = useMemo(() => {
    return profile.contracts.find((contract) => contract.type === EContractType.creditProfileContract);
  }, [profile.contracts]);
  const creditHistoryContract = useMemo(
    () => profile.contracts.find((contract) => contract.type === EContractType.creditHistoryContract),
    [profile.contracts],
  );

  const handleApproveContract = () => {
    setApproveModalOpen(true);
  };

  const acceptApproveContract = async () => {
    try {
      await approveContract(creditProfileContract!.uuid);
      await mortgage.getEntryRequest?.();
      enqueueSnackbar('Анкета проверифицирована');
    } catch (err) {
    } finally {
      handleCloseApproveModal();
    }
  };

  const handleDeclineContract = () => {
    setDeclineModalOpen(true);
  };

  const acceptDeclineContract = async () => {
    try {
      await declineContract(creditProfileContract!.uuid);
      await mortgage.getEntryRequest?.();
      enqueueSnackbar('Анкета отклонена');
    } catch (err) {
    } finally {
      handleCloseDeclineModal();
    }
  };

  const handleCloseApproveModal = () => {
    setApproveModalOpen(false);
  };

  const handleCloseDeclineModal = () => {
    setDeclineModalOpen(false);
  };

  const handleRedirectSign = () => {
    copyCreditProfile();
    setCopiedCreditProfile(true);
  };

  const hasExperiment = useMemo(() => {
    if (auth.user?.roles.includes(EUserRole.manager) || auth.user?.roles.includes(EUserRole.agent)) {
      return false;
    }
    if (!profile?.experiments) {
      return false;
    }
    const smartFormExperiment = profile?.experiments?.find((x) => x?.name === 'smartForm');
    if (smartFormExperiment) {
      return smartFormExperiment?.status;
    }
    return false;
  }, [auth.user, profile]);
  return (
    <>
      <Accordion
        disabled={disabled}
        data-testid={`step-profile-accordion`}
        sx={{
          border: '1px solid #D2D2CF',
          borderRadius: '8px',
          boxShadow: 'none',
          '&:before': { height: 0 },
          ...(isSuccess ? sucesssSx : {}),
        }}
        expanded={expanded}
        onChange={handleExpand}
      >
        <AccordionSummary sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0' } }}>
          {isSuccess ? (
            <Fragment>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '12px' }}>
                <Typography variant='h4m'>
                  {t(`interface:page.ClientMortgageProgressPage.progress.profile.title`)} —&nbsp;
                  {utils.format.nameWithInitials(profile)}
                </Typography>
                <SvgIcon sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }} color={'success'} component={Done} />
              </Box>
              {!hasExperiment ? (
                <Typography variant='h4' data-testid={`step-profile-${profile.uuid}-percent`}>
                  {calcStepProgress(profile.progress)}%
                </Typography>
              ) : null}
            </Fragment>
          ) : (
            <Fragment>
              <Typography variant='h4m'>
                {t(`interface:page.ClientMortgageProgressPage.progress.profile.title`)} —&nbsp;
                {utils.format.nameWithInitials(profile)}
              </Typography>
              {!hasExperiment ? (
                <Typography variant='h4' data-testid={`step-profile-${profile.uuid}-percent`}>
                  {calcStepProgress(profile.progress)}%
                </Typography>
              ) : null}
            </Fragment>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {!hasExperiment && (
            <LinearProgress sx={{ height: '1px', marginBottom: '20px' }} variant='determinate' value={calcStepProgress(profile.progress)} />
          )}
          {hasExperiment ? (
            <MortgageEntryExperimentPage uuidEntry={mortgage.uuid!} uuidUser={profile.ids.uuidUser} />
          ) : (
            <>
              {Object.keys(profile.progress).map((substep) => (
                <Button
                  title={hasAccess ? JSON.stringify(profile) : ''}
                  data-testid={`substep-profile-${substep}`}
                  key={`substep-profile-${profile.uuid}-${substep}`}
                  fullWidth
                  sx={{
                    color: `${theme.palette.text.primary}`,
                    justifyContent: 'space-between',
                  }}
                  endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
                  disabled={hasAccess ? !hasAccess : isDisabledStep(substep)}
                  onClick={handleClick('profile', substep, profile)}
                >
                  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    {t(`interface:page.ClientMortgageProgressPage.progress.profile.${substep}`)}
                    <StatusDot color={getSubStepProgressColor(substep)} />
                  </Box>
                </Button>
              ))}
            </>
          )}

          {children}
          <Accessible requireRoles={[EUserRole.admin, EUserRole.manager]}>
            <Fragment>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: { sm: 'column', lg: 'row', xs: 'column' },
                  gap: { xs: '12px', md: '16px' },
                  width: 1,
                }}
              >
                <List sx={{ width: 1 }}>
                  <ListItem>
                    <ListItemText
                      primary={t('interface:page.ClientMortgageProgressPage.contract.title', {
                        type: t(`interface:page.ClientMortgageProgressPage.contract.${creditHistoryContract?.type}`),
                      })}
                      secondary={t(`interface:page.ClientMortgageProgressPage.contract.status.${creditHistoryContract?.status}`)}
                    />
                    <ListItemButton />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={t('interface:page.ClientMortgageProgressPage.contract.title', {
                        type: t(`interface:page.ClientMortgageProgressPage.contract.${creditProfileContract?.type}`),
                      })}
                      secondary={t(`interface:page.ClientMortgageProgressPage.contract.status.${creditProfileContract?.status}`)}
                    />
                    <ListItemButton
                      onClick={handleRedirectSign}
                      disabled={creditProfileContract?.status === 'ready' || isCopiedCreditProfile}
                    >
                      {isCopiedCreditProfile ? t('interface:page.AgentInsurancePropertyOrderSharePage.copiedLbl') : 'Ссылка на подписание'}
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: { sm: 'column', lg: 'row', xs: 'column' },
                  gap: { xs: '12px', md: '16px' },
                  alignItems: { sm: 'center' },
                }}
              >
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  sx={{ lg: { width: '225px' }, sm: { width: '100%' }, xs: { width: '100%' } }}
                  onClick={handleApproveContract}
                  disabled={creditProfileContract!.status !== 'verification'}
                >
                  {t('interface:page.ClientMortgageProgressPage.progress.profile.approve')}
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  sx={{ lg: { width: '225px' }, sm: { width: '100%' }, xs: { width: '100%' } }}
                  onClick={handleDeclineContract}
                  disabled={creditProfileContract!.status !== 'verification'}
                >
                  {t('interface:page.ClientMortgageProgressPage.progress.profile.decline')}
                </Button>
                <Button
                  fullWidth
                  href={creditHistoryContract!.signedFileLink}
                  variant='contained'
                  color='primary'
                  sx={{ lg: { width: '225px' }, sm: { width: '100%' }, xs: { width: '100%' } }}
                  disabled={!creditHistoryContract?.signedFileLink}
                >
                  {t('interface:page.ClientMortgageProgressPage.progress.profile.downloadCertificate')}
                </Button>
                <Button
                  fullWidth
                  disabled={creditProfileContract?.status === 'draft'}
                  href={creditProfileContract!.signedFileLink}
                  variant='contained'
                  color='primary'
                  sx={{ lg: { width: '225px' }, sm: { width: '100%' }, xs: { width: '100%' } }}
                >
                  {t('interface:page.ClientMortgageProgressPage.progress.profile.generateContractPDF')}
                </Button>
              </Box>
            </Fragment>
          </Accessible>
        </AccordionDetails>
        <Modal
          open={isApproveModalOpen}
          onClose={handleCloseApproveModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              justifyContent: 'space-evenly',
              flexDirection: 'column',
              gap: '16px',
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 336,
              bgcolor: 'background.paper',
              borderRadius: '16px',
              padding: '24px',
            }}
          >
            <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
              {t('interface:page.ClientMortgageProgressPage.progress.profile.approveContractModal')}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '24px' }}>
              <Button fullWidth variant='contained' size='large' onClick={acceptApproveContract}>
                {t('interface:page.HomeClientPage.supportModal.apply')}
              </Button>
              <Button fullWidth color='secondary' variant='contained' size='large' onClick={handleCloseApproveModal}>
                {t('interface:page.HomeClientPage.supportModal.cancel')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={isDeclineModalOpen}
          onClose={handleCloseDeclineModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              justifyContent: 'space-evenly',
              flexDirection: 'column',
              gap: '16px',
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 336,
              bgcolor: 'background.paper',
              borderRadius: '16px',
              padding: '24px',
            }}
          >
            <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
              {t('interface:page.ClientMortgageProgressPage.progress.profile.declineContractModal')}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '24px' }}>
              <Button fullWidth variant='contained' size='large' onClick={acceptDeclineContract}>
                {t('interface:page.HomeClientPage.supportModal.apply')}
              </Button>
              <Button fullWidth color='secondary' variant='contained' size='large' onClick={handleCloseDeclineModal}>
                {t('interface:page.HomeClientPage.supportModal.cancel')}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Accordion>
    </>
  );
};

export default ProfileStepProgress;
