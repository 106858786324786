import { EFormInputType } from 'api/services/form.dto';
import { HookForm } from 'components/base/FormGenerator/types';
import { Fragment, useState } from 'react';

import ComputedContainer from '../Elements/ComputedContainer';
import { useFormLayoutContext } from '../context/FormGeneratorProvider';
import { FormSchemeData } from '../form.types';
import useFormGroup from '../hooks/useFormGroup';

import RenderableCardGroup from './components/RenderableCardGroup';
import RenderableList from './components/RenderableList';
interface Props {
  node: Required<FormSchemeData>;
  path?: string[];
  hookForm: HookForm;
  filterByName?: string;
}
const WHITE_LIST = [String(EFormInputType.repeatableGroup), String(EFormInputType.inlinegroup)];

/**
 * settings
  useRadio?: true, // определяет будет использоваться radio выбор элементов группы
  single?: true, // определяет количество элементов для repeatableGroup
  hasButtonAdd?: true, //определяет будет ли использоваться кнопка добавить экране добавления элемента repeatableGroup
  unique?: true, // определяет будут ли использовать только уникальные элементов для показа карточек и выбора в радио селекторе
  children?: [{ type: 'button', href: 'incomes' }], // определяет контент который будет показываться сверху или снизу для данной групппы
  ** interface children  {
    type:HTMLElementTagNameMap тип элемента который будет использоваться
    ...другие пропсы наследующиеся от базового элемента

  }

 */

export default function FormInlineGroup({ node, hookForm: parentHookForm, path = [] }: Props) {
  const formLayoutContext = useFormLayoutContext();

  const [option, selectOption] = useState<{ type: string; name?: string }>({ type: node.type });
  const formGroup = useFormGroup(node, parentHookForm, {
    path: [...path, node.name],
  });

  const [activeRadio, setRadio] = useState<boolean>(false);

  const handleSelectRadio = (type: string, name: string, options: Partial<{ append: boolean; showRadio: boolean }> = { append: false }) => {
    formGroup.filter(name);
    selectOption({ type, name });
    if (options.append) {
      const getFiltered = formGroup.formFields.filter((i) => i.name === name);
      if (!getFiltered.length) {
        formGroup.append(name);
      }
    }
  };

  const disableRadio = () => {
    setRadio(false);
  };

  const handleSelectOption = (
    type: string,
    name: string,
    options: Partial<{ append: boolean; showRadio: boolean }> = { append: false },
  ) => {
    selectOption({ type, name });
    formGroup.filter(name);
    if (options.showRadio !== undefined) setRadio(options.showRadio);

    if (options.append) {
      const getFiltered = formGroup.formFields.filter((i) => i.name === name);
      if (!getFiltered.length) {
        formGroup.append(name);
      }
    }
  };

  const isRenderList = WHITE_LIST.includes(option.type) || node.settings.islist;
  const isRenderCard = !isRenderList;

  return (
    <ComputedContainer
      hhiden={formLayoutContext.formControls.hidden}
      dependedFields={[]}
      fieldValues={formGroup.fieldValues}
      hookForm={parentHookForm}
      path={path}
      node={node}
    >
      <Fragment>
        {isRenderList && <RenderableList {...formGroup} node={node} path={path} onSelect={handleSelectOption} />}
        {isRenderCard && (
          <RenderableCardGroup
            {...formGroup}
            path={path}
            node={node}
            onSelect={handleSelectOption}
            isShowRadio={activeRadio}
            onSelectRadio={handleSelectRadio}
            showRadio={() => {
              setRadio(!activeRadio);
            }}
            disableRadio={disableRadio}
          />
        )}
      </Fragment>
      {/* {!formLayoutContext.formControls.hidden &&
        node?.settings?.children
          ?.filter((x) => x.position === 'before')
          ?.map((child: ObjectType) => (
            <ComputableElement key={computedKeys(child.props)} path={path} fieldValues={formGroup.fieldValues} renderOption={child} />
          ))} */}

      {/*
      {!formLayoutContext?.formControls?.hidden &&
        afterChildren?.map((child) => (
          <ComputableElement key={child.props.value} path={path} fieldValues={formGroup.fieldValues} renderOption={child} />
        ))} */}
    </ComputedContainer>
  );
}
