import { Box, SxProps, Theme } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import Header from 'components/layouts/Header';
import WithNotificationHeader from 'components/layouts/WithNotificationHeader';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export interface IPageProps {
  children: React.ReactNode;
  title: string;
  meta?: Array<JSX.IntrinsicElements['meta']>;
  header?: {
    type?: string;
    title?: string;
    subtitle?: string;
    backRoute?: string;
    sx?: SxProps<Theme>;
    onBackEvent?: () => void;
  };
  hideHeader?: boolean;
  sx?: SxProps<Theme>;
}

// TODO: Other devs make this component like a piece of shit, need to rewrite all (I just move this page to TS)

const Page = ({ children, title = '', meta, header, hideHeader = false, sx, ...other }: IPageProps) => {
  const { t } = useTranslation();

  const mapHeaderData: Record<string, { component: any; props: { [x: string]: unknown } }> = {
    default: {
      component: Header,
      props: { title: header?.title, sx: header?.sx },
    },
    backButton: {
      component: BaseButtonBack,
      props: { title: header?.title, subtitle: header?.subtitle, sx: header?.sx, onBackEvent: header?.onBackEvent },
    },
    withNotification: {
      component: WithNotificationHeader,
      props: { title: header?.title, sx: header?.sx },
    },
  };

  const headerData = mapHeaderData[header?.type || 'default'];
  return (
    <>
      <Helmet title={`${t('helmet:title')} - ${title}`} meta={meta} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 20px',
          margin: '0 auto',
          width: { xs: '100%', md: '900px', lg: '1200px' },
          overflowX: 'hidden',
          ...sx,
        }}
        {...other}
      >
        {!hideHeader && React.createElement(headerData?.component, headerData?.props)}
        {children}
      </Box>
    </>
  );
};

export default Page;
