import { Box, Skeleton, Typography } from '@mui/material';
import { ApiError } from 'api/errors';
import { getMortgageInsurance } from 'api/services/insurance/insurance';
import { MortgageInsurance } from 'api/services/insurance/insurance.dto';
import SentData from 'components/Icons/SentData';
import ErrorMessage from 'components/base/ErrorMessage';
import useTypedParams from 'hooks/useTypedParams';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE } from 'routes/paths';

import { Proposal } from './proposal/Proposal';

export default function MortgageEntryInsuranceCompanyForm() {
  const [data, setData] = useState<MortgageInsurance | null>(null);
  const [error, setError] = useState<ApiError>();
  const [ok, setOk] = useState(false);

  const { uuidEntry } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE>();

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceProposalWaiting';
  const { t } = useTranslation();
  const fetchData = async () => {
    try {
      const data = await getMortgageInsurance(uuidEntry!);

      setData(data.body);
      setOk(data.ok);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!ok) {
    return <Skeleton variant='rectangular' width={'100%'} height={128} />;
  }

  if (data !== null) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch' }}>
        {data.proposes.length === 0 ? (
          <Box
            sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch', marginTop: '130px' }}
          >
            <SentData sx={{ margin: '0 auto', width: '160px', height: '160px' }} />
            <Typography variant={'h1'} component={'p'} sx={{ fontWeight: 700 }}>
              {t(`${prefix}.sent`)}
            </Typography>
            <Typography variant={'h3'} component={'p'}>
              {t(`${prefix}.description`)}
            </Typography>
          </Box>
        ) : (
          data.proposes.map(({ company, price, description, uuid }) => (
            <Proposal key={company} company={company} price={price} description={description} uuidPropose={uuid} />
          ))
        )}
      </Box>
    );
  }

  return <></>;
}
