import { ApiError } from 'api/errors';
import { getAllClientsAgent, getClientAgentFilters } from 'api/services/agent';
import {
  EEntryAgentClientStageStatusFilter,
  IMortgageEntryAgentClientQueryDTO,
  IMortgageEntryCLientListResult,
} from 'api/services/agent.dto';
import useNavigateParams from 'hooks/useNavigateParams';
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { PATH_BUSINESS_CREDIT_ENTRY_CREATE, PATH_BUSINESS_CREDIT_ENTRY_PROGRESS } from 'routes/paths';

import { uniqBy } from 'lodash';
interface ClientContextType {
  clients: IMortgageEntryCLientListResult[];
  selectedClient: IMortgageEntryCLientListResult;
  apiError?: ApiError;
  requestAllClients: (filters?: IMortgageEntryAgentClientQueryDTO) => Promise<void>;
  onSelectClient: (uuidUser?: string) => void;
  loading: boolean;
  requstLoadScroll: (index: number) => void;
  clientNavigateProgress: (client: Partial<IMortgageEntryCLientListResult>) => void;
  statusFilters: Record<string, number>;
  selectFilter: (...filters: EEntryAgentClientStageStatusFilter[]) => void;

  activeFilter: EEntryAgentClientStageStatusFilter[];
  page: number;
  size: number;
  updatePagination: (page: number, size: number) => void;
  count: number;
}

const ClientContext = createContext<Partial<ClientContextType>>({});

export default function ClientProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigateParams();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [count, setCount] = useState<number>();
  const [activeFilter, setActiveFilter] = useState<EEntryAgentClientStageStatusFilter[]>([]);
  const [statusFilters, setStatusFilters] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<IMortgageEntryCLientListResult[]>([]);
  const [apiError, setError] = useState<ApiError>();
  const ref = useRef(new AbortController());

  const cancelAll = () => {
    ref.current.abort();
  };

  const clientNavigateProgress = (client: Partial<IMortgageEntryCLientListResult>) => {
    if (client.entry?.uuid) {
      navigate(PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, { params: { uuidEntry: client.entry.uuid as string } });
      return;
    }
    navigate(PATH_BUSINESS_CREDIT_ENTRY_CREATE, { params: { uuidUser: client?.user?.uuid! } });
  };

  const requestAllFilters = async () => {
    setLoading(true);
    try {
      const res = await getClientAgentFilters(ref.current.signal);
      setStatusFilters(res.body);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const selectFilter = (...filterlist: EEntryAgentClientStageStatusFilter[]) => {
    let filters = [...activeFilter];
    for (const filterStatus of filterlist) {
      if (filters.includes(filterStatus)) {
        filters = filters.filter((x) => x !== filterStatus);
      } else {
        filters.push(filterStatus);
      }
    }
    setActiveFilter(filters);
    requestAllClients({ filters });
  };

  const updatePagination = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    requestAllClients({ page, size, filters: activeFilter });
  };
  const requstLoadScroll = async (index: number) => {
    try {
      if (count! > index) {
        const p = Math.ceil(index / size);
        const clients = await getAllClientsAgent({ filters: activeFilter, page: p, size }, ref.current.signal);
        setPage(p);
        setClients((prev) => {
          const arr = [...prev, ...clients.body.results];
          return uniqBy(arr, (x) => x.user.uuid);
        });
      }
    } catch (err) {}
  };

  const requestAllClients = async (filters?: IMortgageEntryAgentClientQueryDTO) => {
    const filtersList = {
      ...filters,
      size: filters?.size || 10,
    };
    setLoading(true);
    try {
      const res = await getAllClientsAgent(filtersList, ref.current.signal);
      setClients(res.body.results);
      setCount(res.body.settings.count);
    } catch (err) {
      setError(err as ApiError);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    requestAllClients();
    requestAllFilters();
    return cancelAll;
  }, []);

  return (
    <ClientContext.Provider
      value={{
        requestAllClients,
        clients,
        loading,
        apiError,
        clientNavigateProgress,
        statusFilters,
        selectFilter,
        activeFilter,
        requstLoadScroll,
        page,
        size,
        updatePagination,
        count,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

export function useAgentClients() {
  const ctx = useContext(ClientContext) as ClientContextType;
  if (!ctx) {
    throw new Error('ClientProvider not initialized');
  }
  return ctx;
}
