import { Components } from '@mui/material';
import { Theme } from '@mui/system';
import typography from 'theme/typography';

const MuiLoadingButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    // TODO: Hack, fix with indicator color
    root: ({ ownerState: { disabled, loading }, theme }) => ({
      ...(loading
        ? {
            background: `${theme.palette.primary.main}`,
          }
        : {}),
      ...(disabled
        ? {
            background: `${theme.palette.text.secondary}`,
          }
        : {}),
    }),
  },
};

const components: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState: { size } }) => ({
        fontSize: '18px',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        ':active': {
          boxShadow: 'none',
        },
        ...(size === 'small' && { minHeight: '28px' }),
        ...(size === 'medium' && { minHeight: '48px' }),
        ...(size === 'large' && { minHeight: '56px' }),
        // TODO: Reactoring
        ...(size === 'superlarge' && { minHeight: '72px', paddingLeft: '45px', paddingRight: '45px', ...typography.h2m }),
      }),
    },
    variants: [
      {
        props: { variant: 'contained-landing' },
        style: ({ theme }) => ({
          boxShadow: '0px 7px 60px rgba(255, 229, 0, 0.3)',
          borderRadius: '16px',
          background: `${theme.palette.primary.main}`,
          '&:hover': {
            background: `${theme.palette.common.btnHover}`,
          },
          '&.Mui-disabled': {
            color: `${theme.palette.common.greyLight}`,
            background: `${theme.palette.secondary.main}`,
          },
        }),
      },
      {
        props: { variant: 'outlined-landing' },
        style: ({ theme }) => ({
          borderRadius: '16px',
          boxShadow: 'none',
          ...typography.h3,
          background: `rgba(238, 245, 255, 0.5)`,
          backdropFilter: 'blur(12px)',
          '&:hover': {
            background: `${theme.palette.secondary.light}`,
          },
        }),
      },
      {
        props: { variant: 'contained-landing', size: 'large' },
        style: () => ({
          ...typography.h3m,
        }),
      },
      {
        props: { variant: 'contained-round', color: 'primary' },
        style: ({ theme }) => ({
          boxShadow: '0px 7px 60px rgba(255, 229, 0, 0.3)',
          borderRadius: '16px',
          background: `${theme.palette.primary.main}`,
          '&:hover': {
            background: `${theme.palette.common.btnHover}`,
          },
          '&.Mui-disabled': {
            color: `${theme.palette.common.greyLight}`,
            background: `${theme.palette.secondary.main}`,
          },
        }),
      },
      {
        props: { variant: 'contained', color: 'success' },
        style: ({ theme }) => ({
          background: `${theme.palette.success.main}`,
          color: theme.palette.common.white,
          '&:hover': {
            background: `${theme.palette.success.dark} !important`,
            // color: `${theme.palette.common.black} !important`,
          },
          '&.Mui-disabled': {
            color: `${theme.palette.common.greyLight}`,
            background: `${theme.palette.secondary.main}`,
          },
        }),
      },
      // {
      //   props: { variant: 'contained', color: 'info' },
      //   style: ({ theme }) => ({
      //     background: `${theme.palette.success.main}`,
      //     color: theme.palette.common.white,
      //     '&:hover': {
      //       background: `${theme.palette.success.dark} !important`,
      //       // color: `${theme.palette.common.black} !important`,
      //     },
      //     '&.Mui-disabled': {
      //       color: `${theme.palette.common.greyLight}`,
      //       background: `${theme.palette.secondary.main}`,
      //     },
      //   }),
      // },
      {
        props: { variant: 'contained' },
        style: ({ theme }) => ({
          '&:hover': {
            background: `${theme.palette.common.btnHover}`,
          },
          '&.Mui-disabled': {
            color: `${theme.palette.common.greyLight}`,
            background: `${theme.palette.secondary.main}`,
          },
        }),
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: ({ theme }) => ({
          '&:hover': {
            background: `${theme.palette.secondary.light}`,
          },
        }),
      },
      {
        props: { variant: 'text' },
        style: ({ theme }) => ({
          color: `${theme.palette.primary.contrastText}`,
        }),
      },
      {
        props: { variant: 'outlined' },
        style: ({ theme }) => ({
          color: `${theme.palette.primary.contrastText}`,
          borderColor: `${theme.palette.primary.dark}`,
        }),
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: ({ theme }) => ({
          '&:hover': {
            background: `${theme.palette.secondary.light}`,
          },
          color: `${theme.palette.secondary.contrastText}`,
          borderColor: `${theme.palette.secondary.dark}`,
        }),
      },
    ],
  },
  // TODO: Typing for lab components
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  MuiLoadingButton,
};

export default components;
