import { Box, Breadcrumbs, Grid, Link, Typography, useTheme } from '@mui/material';
import { IMortgageEntryPropose } from 'api/services/mortgage.dto';
import { supportSend } from 'api/services/support';
import { ESupportReqType } from 'api/services/support.dto';
import BaseButtonBack from 'components/base/Button/Back';
import BaseInsuranceListSearchAndFilter from 'components/base/Insurance/List/SearchAndFilter';
import BaseInsuranceListSorting from 'components/base/Insurance/List/Sorting';
import StatusDot from 'components/base/StatusDot';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useMortgage } from 'components/providers/MortageEntryProvider';
import { ProposeModalTypes, useProposeModal } from 'components/providers/ProposeModalProvider';
import _ from 'lodash';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_HOME } from 'routes/paths';
import utils from 'utils';
import { urlReplace } from 'utils/url';

import ProposeCard from './components/ProposeCard';
import ProposeSelector from './components/ProposeSelector';
import ProposeProvider from './ProposeProvider';
import { Accessible } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';

export default function ClientMortgageProposePage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    loading,
    proposes,
    selectedPropose,
    entry,
    selectProposeRequest,
    handleChangeProposeRequest,
    cancelProposeRequest,
    acceptProposeRequest,
    primaryPropose,
  } = useMortgage();

  const { handleModal } = useProposeModal();
  const [sortedBy, setSortedBy] = useState<'asc' | 'desc'>('asc');
  const [queryFilter, setQueryFilter] = useState('');

  const handleSort = useCallback(() => {
    setSortedBy(sortedBy === 'asc' ? 'desc' : 'asc');
  }, [sortedBy]);

  const canAppovePropose = useCallback(
    (propose: IMortgageEntryPropose) => {
      if (entry?.primaryPropose?.decision && propose.uuid === entry.primaryPropose.uuid) {
        return entry.primaryPropose.status === 'approved' && !!entry.primaryPropose.uuid;
      }
      return false;
    },
    [entry],
  );

  // filter methods
  const handleChangeQuery = (e: any) => {
    setQueryFilter(e.target.value);
  };
  const debounceFn = useCallback(_.debounce(handleChangeQuery, 1000), []);

  const sortedProposes = useMemo(() => {
    return _(proposes)
      .filter((x) => {
        if (!entry?.excludedProviders) {
          return true;
        }
        return !entry.excludedProviders.includes(x.bank);
      })
      .orderBy(
        (propose) => {
          if (propose.uuid === selectedPropose?.uuid) {
            return sortedBy === 'asc' ? Number.MIN_VALUE : Number.MAX_VALUE;
          }
          return propose.decision?.rate;
        },
        [sortedBy],
      )

      .value();
  }, [proposes, sortedBy]);

  const handleCancelPropose = useCallback((propose: IMortgageEntryPropose | null) => {
    handleModal?.(ProposeModalTypes.CancelPropose, (status) => {
      if (status)
        cancelProposeRequest?.(propose).then(() => {
          handleModal?.(ProposeModalTypes.SuccessCancelPropose);
        });
    });
  }, []);

  const handleSelectPropose = useCallback(
    (propose: IMortgageEntryPropose | null) => {
      selectProposeRequest?.(propose).then((hasError) => {
        if (!hasError) handleModal?.(ProposeModalTypes.Confirmation);
      });
    },
    [primaryPropose],
  );

  const handleConfirmPropose = useCallback(
    (propose: IMortgageEntryPropose | null) => {
      handleModal?.(ProposeModalTypes.FinalConfirmation, (status) => {
        if (status)
          acceptProposeRequest?.(propose).then((hasError) => {
            if (!hasError) navigate(urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: entry?.uuid }));
          });
      });
    },
    [entry],
  );
  const filteredProposes = useMemo(() => {
    return _.filter(sortedProposes, (propose) => {
      if (!queryFilter) {
        return true;
      }
      return t(`common:mortgage.banks.${propose?.bank}`).toLowerCase().indexOf(queryFilter.toLowerCase()) !== -1;
    });
  }, [sortedProposes, queryFilter]);

  const handleOpenPopover = useCallback((propose) => {
    handleModal?.(ProposeModalTypes.BottomPopover, (status) => {
      if (status) {
        handleModal(ProposeModalTypes.CallbackPropose, (handlerStatus) => {
          if (handlerStatus) {
            supportSend({ reqType: ESupportReqType.call });
            handleChangePropose(propose);
          }
        });
      }
    });
  }, []);

  const handleChangePropose = useCallback(
    (propose) => {
      if (!!primaryPropose) {
        handleModal?.(ProposeModalTypes.ChangePropose, () => {
          handleChangeProposeRequest?.(propose).then((hasError) => {
            if (!hasError) {
              handleModal?.(ProposeModalTypes.SuccessChangePropose);
            }
          });
        });
      } else {
        handleSelectPropose(propose);
      }
    },
    [primaryPropose],
  );

  return (
    <ProposeProvider>
      <ContainerLayout
        title={t('helmet:ClientMortgageProposePage.title')}
        meta={[{ name: 'description', content: t('helmet:ClientMortgageProposePage.description') }]}
        header={{
          xs: (
            <BaseButtonBack
              title={t(`interface:page.ClientMortgageProposePage.${loading ? 'loadingLabel' : 'title'}`)}
              subtitle={t(`interface:page.ClientMortgageProposePage.${loading ? 'loadingLabel' : 'subtitle'}`)}
            />
          ),
          lg: <TopMenu />,
        }}
        maxWidth='xl'
        sx={{ height: 1, justifyContent: 'space-evently', gap: '16px', paddingBottom: '20px' }}
      >
        <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: { xs: 'none', lg: 'flex' }, flexDirection: 'row', gap: '20px' }}>
            <Breadcrumbs separator={<StatusDot color='#D2D2CF' sx={{ width: '4px', height: '4px' }} />} aria-label='breadcrumb'>
              <Link sx={{ color: theme.palette.common.link }} underline='none' href={PATH_HOME}>
                {t('interface:page.ClientMortgageProposePage.breadcrumbs.main')}
              </Link>
              <Link
                sx={{ color: theme.palette.common.link }}
                underline='none'
                href={utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: entry?.uuid })}
              >
                {t('interface:page.ClientMortgageProposePage.breadcrumbs.progress')}
              </Link>
              <Link sx={{ color: theme.palette.text.secondary, userSelect: 'none' }} underline='none'>
                {t('interface:page.ClientMortgageProposePage.breadcrumbs.selectBank')}
              </Link>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: { xs: 'flex-start', lg: 'space-between' },
              alignItems: 'center',
              width: '100%',
              gap: '24px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '24px', width: { xs: 1, lg: 0.7 } }}>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Typography component='span' variant='h1b'>
                  {t('interface:page.ClientMortgageProposePage.title')}
                </Typography>
              </Box>
              <BaseInsuranceListSearchAndFilter sx={{ marginTop: 0, width: { lg: '361px', xs: 1 } }} NoFilter onChangeQuery={debounceFn} />
            </Box>

            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <BaseInsuranceListSorting
                sx={{ marginTop: 0 }}
                handleSort={handleSort}
                title={t('interface:page.ClientMortgageProposePage.sortByRate')}
              />
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <BaseInsuranceListSorting handleSort={handleSort} title={t('interface:page.ClientMortgageProposePage.sortByRate')} />
          </Box>

          <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
            <ProposeSelector excludedList={entry?.excludedProviders} />
          </Accessible>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '32px', paddingBottom: '48px' }}>
            <Grid container spacing={{ xs: '16px' }} alignItems='stretch'>
              {filteredProposes?.map((propose) => {
                const canApprove = canAppovePropose(propose);
                return (
                  <Grid item xs={12} lg={12} key={propose?.uuid}>
                    <ProposeCard
                      canApprove={canApprove}
                      entryParams={entry?.params}
                      selected={entry?.primaryPropose?.uuid === propose.uuid}
                      onOpenPopover={() => {
                        handleOpenPopover(propose);
                      }}
                      propose={propose}
                      onConfirmPropose={handleConfirmPropose}
                      onCancelPropose={handleCancelPropose}
                      onChangePropose={handleChangePropose}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </ContainerLayout>
    </ProposeProvider>
  );
}
