import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { userGetContractByType, userSetContractDetails } from 'api/services/user';
import UploadButton from 'components/common/UploadButton';
import Page from 'components/layouts/Page';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';

const CONTRACT_TYPE = 'agentContract';

// TODO: Refactor all
const UserDocsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [passportMainPage, setPassportMainPage] = useState(null);
  const [passportRegistrationPage, setPassportRegistrationPage] = useState(null);

  const { data } = useFetch(() => userGetContractByType(CONTRACT_TYPE));

  useEffect(() => {
    if (!data) return;
    setPassportMainPage(data?.details?.documents?.passportMainPage || null);
    setPassportRegistrationPage(data?.details?.documents?.passportRegistrationPage || null);
  }, [data]);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handlePassportMainChange = (fileUrl) => {
    setPassportMainPage(fileUrl);
    if (!isEditMode) setIsEditMode(true);
  };

  const handlePassportRegistrationChange = (fileUrl) => {
    setPassportRegistrationPage(fileUrl);
    if (!isEditMode) setIsEditMode(true);
  };

  const handleSubmitClick = async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await userSetContractDetails(CONTRACT_TYPE, {
        documents: {
          passportMainPage,
          passportRegistrationPage,
        },
      });
      if (res.ok) {
        setIsEditMode(false);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title={t('helmet:UserDocsPage.title')}
      meta={[{ name: 'description', content: t('helmet:UserDocsPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.UserDocsPage.headerTitle'), backRoute: PATH_USER }}
      sx={{ height: 1, justifyContent: 'space-between' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={'16px'}>
        <Typography component='div' variant='h4' sx={{ marginBottom: '16px' }}>
          {t('interface:page.UserDocsPage.passportInfo')}
        </Typography>
        <Typography component='div' variant='h4m'>
          {t('interface:page.UserDocsPage.passportMainPageLabel')}
        </Typography>
        <UploadButton
          data-testid='page.UserDocsPage.passportMainPageLabel'
          fileLink={passportMainPage}
          onChange={handlePassportMainChange}
          allowDelete={isEditMode}
        />
        <Typography component='div' variant='h4m'>
          {t('interface:page.UserDocsPage.passportRegistrationPageLabel')}
        </Typography>
        <UploadButton
          data-testid='page.UserDocsPage.passportRegistrationPageLabel'
          fileLink={passportRegistrationPage}
          onChange={handlePassportRegistrationChange}
          allowDelete={isEditMode}
        />
      </Box>

      <Stack sx={{ margin: '20px 0' }} spacing={2}>
        {!isEditMode && (
          <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
            {t('interface:page.UserDocsPage.editBtn')}
          </Button>
        )}

        {isEditMode && (
          <Button variant='contained' size='large' onClick={handleSubmitClick}>
            {t('interface:page.UserDocsPage.submitBtn')}
          </Button>
        )}

        {isEditMode && (
          <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
            {t('interface:page.UserDocsPage.cancelBtn')}
          </Button>
        )}
      </Stack>

      {error && (
        <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.red.main}` }} variant='h5'>
          {t(`errors:${error}`)}
        </Typography>
      )}
    </Page>
  );
};

export default UserDocsPage;
