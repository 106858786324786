import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M39.9998 24.3051C39.9831 22.8684 39.6633 21.4515 39.0613 20.147C38.4593 18.8424 37.5887 17.6797 36.5065 16.7347C36.8568 15.7796 37.0349 14.7699 37.0324 13.7525C37.0324 13.194 36.9761 12.6429 36.8739 12.1126C36.1094 8.06665 32.5628 5 28.3124 5H18.0043V23.994C18.0043 27.6502 14.8784 30.628 11.0355 30.628C10.9145 30.6271 10.7935 30.6241 10.6725 30.6191C6.99848 30.4413 4.06516 27.5362 4.06516 23.9969V10.6074C4.06516 9.72145 4.79553 9.00146 5.69182 9.00146H9.72735C9.99219 9.0038 10.2549 8.95391 10.5004 8.85463C10.746 8.75535 10.9695 8.60864 11.1583 8.42288C11.3471 8.23713 11.4974 8.01597 11.6006 7.77207C11.7039 7.52817 11.758 7.26631 11.7599 7.00147C11.7599 5.89777 10.8488 5.00148 9.72735 5.00148H5.6933C2.55258 5.00148 0 7.51554 0 10.6074V12.4029H0.00148147H0V23.9969L0.00148147 24.1436C0.0888884 29.9391 5.00145 34.6295 11.037 34.6295C17.1214 34.6295 22.0725 29.8591 22.0725 23.9969V8.99998H28.3139C28.3554 8.99998 28.3954 9.00146 28.4324 9.00738C30.9435 9.07109 32.9657 11.1748 32.9657 13.7525C32.9715 14.7005 32.6928 15.6283 32.1657 16.4162C31.5554 17.3584 31.7094 18.3955 32.6472 18.9199C33.6429 19.454 34.4749 20.2486 35.0541 21.2187C35.6334 22.1888 35.9382 23.2981 35.9361 24.428C35.9361 27.6176 33.5568 30.2502 30.5139 30.5924C30.2919 30.6157 30.0689 30.6276 29.8457 30.628H23.2739C22.1539 30.628 21.2443 31.5258 21.2443 32.6295C21.2443 33.7361 22.1539 34.6295 23.2739 34.6295H29.8443C35.4398 34.6295 39.9968 30.0517 39.9968 24.4295C40.0012 24.3866 39.9998 24.3466 39.9998 24.3051Z'
      fill='#CC163F'
    />
  </svg>,
  'Ubrir',
);
