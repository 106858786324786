import { Box } from '@mui/material';
import ClientMortgageEntryCreatePage from 'pages/Client/Mortgage/Entry/Create/Form';

export default function ClientCreditParams() {
  return (
    <Box>
      <ClientMortgageEntryCreatePage />
    </Box>
  );
}
