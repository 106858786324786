import { Skeleton, useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React from 'react';
import utils from 'utils';
type Props = {
  title: string;
  amount: number | null;
  loading?: boolean;
  direction: 'row' | 'column';
  additional?: { title: string; ammount: string; loading?: boolean }[];
  sx?: SxProps;
};
const UnitBillet: React.FC<Props> = ({ title, amount, loading = false, direction, additional, sx }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: direction || 'column',
        justifyContent: 'center',
        width: '100%',
        padding: '24px',
        borderRadius: '16px',
        ...(sx || {}),
      }}
    >
      <Box>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h4'>
          {title}
        </Typography>
        <Typography variant='h1b'>
          {!loading && amount !== null ? utils.format.price(amount) : <Skeleton sx={{ width: '60%', borderRadius: '10px' }} />}
        </Typography>
      </Box>
      {additional?.map((info, idx) => (
        <Box key={`${idx}`}>
          <Typography sx={{ color: theme.palette.text.secondary }} variant='h4'>
            {info.title}
          </Typography>
          <Typography variant='h1b'>
            {!info.loading && amount !== null ? info.ammount : <Skeleton sx={{ width: '60%', borderRadius: '10px' }} />}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default UnitBillet;
