export enum EProfileEducationType {
  belowSec = 'belowSecondary',
  sec = 'secondary',
  secSpecial = 'secondarySpecial',
  incompHigher = 'incompHigher',
  higherOrMag = 'higherOrMag',
  candSciencesOrMba = 'candSciencesOrMba',
}
export enum EProfileMarriageContractStatus {
  exist = 'exist',
  missing = 'missing',
  planned = 'planned',
}
export enum EProfileWorkspaceJobSchema {
  white = 'white',
  grey = 'grey',
}
