import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Button, Container, SvgIcon, Typography, useTheme } from '@mui/material';
import DrawSign from 'components/base/DrawSign';
import { useEffect, useState } from 'react';
export default function () {
  const theme = useTheme();
  const [step, setStep] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => step + 1);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const fadeAnimation = (v: number) => ({
    '@keyframes myAnim': {
      '0%': { opacity: 0, transform: 'translateY(50px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    animation: `myAnim ${v}s ease-in 0s 1 normal forwards`,
    width: 1,
  });
  const converted = {
    '@keyframes btnAnim': {
      '0%': {
        animationTimingFunction: 'ease-out',
        transform: 'scale(1)',
        transformOrigin: 'center center',
      },
      '10%': { animationTimingFunction: 'ease-in', transform: 'scale(0.91)' },
      '17%': { animationTimingFunction: 'ease-out', transform: 'scale(0.98)' },
      '33%': { animationTimingFunction: 'ease-in', transform: 'scale(0.87)' },
      '45%': { animationTimingFunction: 'ease-out', transform: 'scale(1)' },
    },
    animation: 'btnAnim 2s linear 0s 1 normal forwards',
  };

  return (
    <Box sx={{ height: 1, width: 1, marginTop: '40px' }}>
      <Container sx={{ width: 1 }}>
        {/* <Box>
          <Typography sx={{ color: theme.palette.text.secondary }}>Покупатель</Typography>
          <Typography>Иванов Александр Сергеевич</Typography>
        </Box> */}

        {step >= 2 && (
          <Box sx={fadeAnimation(0.5)}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, margin: '10px 0' }}>Документы на подписание</Typography>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, gap: '8px' }}>
          {step >= 3 && (
            <Box sx={fadeAnimation(1)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  background: theme.palette.secondary.main,
                  padding: '16px',
                  borderRadius: '16px',
                  gap: '20px',
                }}
              >
                <SvgIcon sx={{ height: '31px', width: '25px', color: '#787770' }} component={InsertDriveFileOutlinedIcon} />
                <Typography sx={{ fontSize: '14px' }}>Анкета-заявка</Typography>
              </Box>
            </Box>
          )}
          {step >= 4 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                background: theme.palette.secondary.main,
                padding: '16px',
                borderRadius: '16px',
                gap: '20px',

                ...fadeAnimation(1),
              }}
            >
              <SvgIcon sx={{ height: '31px', width: '25px', color: '#787770' }} component={InsertDriveFileOutlinedIcon} />
              <Typography sx={{ fontSize: '14px' }}>Согласие на обработку данных</Typography>
            </Box>
          )}
        </Box>

        {step >= 5 && (
          <Box sx={{ marginTop: '8px', ...fadeAnimation(1) }}>
            <DrawSign
              sx={{ height: '220px', borderRadius: '16px', overflow: 'hidden' }}
              handleSignBtnClick={() => {
                return undefined;
              }}
              styleLottie={{ width: '220px', height: '220px' }}
            />
            <Button sx={{ marginTop: '30px', ...(step >= 10 ? converted : {}) }} variant='contained' size='large' fullWidth>
              Подписать
            </Button>
          </Box>
        )}
        {step >= 6 && <Typography sx={{ marginTop: '40px' }}>Подписано простой электронной подписью</Typography>}
      </Container>
    </Box>
  );
}
