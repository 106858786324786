import CommonCheckCode from 'components/common/CheckCode/CheckCode';

const GuiCommonCheckCode = () => {
  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const randomNumber = [...Array(7)].map(() => String(randomIntFromInterval(0, 9))).join('');
  const phone = `7917${randomNumber}`;
  const email = `some@example${randomNumber}.com`;

  return <CommonCheckCode userData={{ firstname: 'tony', lastname: 'jackson', patronymic: 'batkovich', phone, email }} />;
};

export default GuiCommonCheckCode;
