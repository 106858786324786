import { Box, Typography } from '@mui/material';
import { postMortgageSelect } from 'api/services/insurance/insurance';
import SentData from 'components/Icons/SentData';
import useTypedParams from 'hooks/useTypedParams';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE } from 'routes/paths';

export default function ProposalWaiting() {
  const { t } = useTranslation();

  const { uuidEntry } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE>();

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceProposalWaiting';

  useEffect(() => {
    postMortgageSelect(uuidEntry!);
  }, []);

  return (
    <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch', marginTop: '130px' }}>
      <SentData sx={{ margin: '0 auto', width: '160px', height: '160px' }} />
      <Typography variant={'h1'} component={'p'} sx={{ fontWeight: 700 }}>
        {t(`${prefix}.sent`)}
      </Typography>
      <Typography variant={'h3'} component={'p'}>
        {t(`${prefix}.description`)}
      </Typography>
    </Box>
  );
}
