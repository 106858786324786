import { IRequestResult, req } from 'api/http';

import { DocumentProgress, DocumentSource } from '../experiment/experiment.dto';
import { EBCBank, ECBCEntryPropose, TRequestedData } from './businessCredit.propose.dto';

import {
  IBusinessCreditCompanyEntryCreatingDataCompany,
  IBusinessCreditCompanyUuidDTO,
  IBusinessCreditEntryEntity,
  IBusinessCreditEntryResponse,
} from './businessCredit.dto';
import { BusinessCreditSchema } from './businessCredit.schema';

function getEntryList() {
  return req<null, IBusinessCreditEntryEntity[]>(`businessCredit`, { method: 'GET' });
}
/**
 * create BusinessCredit entry with company
 * @param formData
 * @returns
 */
const postCreateEntryWithCompany = (formData: BusinessCreditSchema) =>
  req<BusinessCreditSchema, IBusinessCreditEntryResponse>(`businessCredit`, {
    method: 'POST',
    body: formData,
  });
/**
 *
 * @param params
 * @returns
 */
const getDocuments = (params: IBusinessCreditCompanyUuidDTO, signal: AbortSignal) =>
  req<null, DocumentSource[]>(`businessCredit/${params.uuidEntry}/document`, { method: 'GET', signal });

const deleteDocument = (params: IBusinessCreditCompanyUuidDTO, { uuidDocument }: { uuidDocument: string }) =>
  req<null, DocumentSource>(`businessCredit/${params.uuidEntry}/document/${uuidDocument}`, {
    method: 'DELETE',
  });

const getRequeiredDocuments = (params: IBusinessCreditCompanyUuidDTO, signal: AbortSignal) => {
  return req<null, DocumentProgress[]>(`businessCredit/${params.uuidEntry}/document/progress`, { method: 'GET', signal });
};

function putUpdateDocument(params: IBusinessCreditCompanyUuidDTO, payload: DocumentSource) {
  return req<any, DocumentSource[]>(`businessCredit/${params.uuidEntry}/document/${payload.uuid}`, {
    method: 'PUT',
    body: payload,
  });
}

function postRecognizeDocument(params: IBusinessCreditCompanyUuidDTO, file: File) {
  return req<{ link: string }, DocumentSource[]>(`businessCredit/${params.uuidEntry}/uploadDocument`, {
    method: 'POST',
    body: file,
  });
}
function postUploadDocument(params: IBusinessCreditCompanyUuidDTO, file: File) {
  return req<{ link: string }, DocumentSource[]>(`businessCredit/${params.uuidEntry}/uploadDocument`, {
    method: 'POST',
    body: { file },
  });
}

function getEntry(params: Partial<IBusinessCreditCompanyUuidDTO>) {
  return req<null, IBusinessCreditEntryResponse>(`businessCredit/${params.uuidEntry}`, { method: 'GET' });
}
function putUpdateEntry(params: Partial<IBusinessCreditCompanyUuidDTO>, payload: IBusinessCreditEntryEntity) {
  return req(`businessCredit/entry/${params.uuidEntry}`, { method: 'PUT', body: payload });
}

function putUpdateCompany(params: Partial<IBusinessCreditCompanyUuidDTO>, payload: IBusinessCreditCompanyEntryCreatingDataCompany) {
  return req(`businessCredit/${params.uuidEntry}/company`, { method: 'PUT', body: payload });
}

function deletePassport(params: Partial<IBusinessCreditCompanyUuidDTO>, payload: DocumentSource) {
  return req<any, DocumentSource[]>(`businessCredit/${params.uuidEntry}/documents/passport/${payload.uuid}`, {
    method: 'DELETE',
    body: payload,
  });
}

const putExcludeBanks = (uuidEntry: string, banklist: Array<EBCBank>) =>
  req(`businessCredit/${uuidEntry}/excludeBanks`, { method: 'PUT', body: banklist });

const getProposeList = (uuidEntry: string) => req<null, ECBCEntryPropose[]>(`businessCredit/${uuidEntry}/propose`, { method: 'GET' });

const getProposeRequest = (uuidEntry: string, uuidPropose: string) =>
  req<null, TRequestedData[]>(`businessCredit/${uuidEntry}/propose/${uuidPropose}/request`, { method: 'GET' });

const putProposeRequest = (uuidEntry: string, uuidPropose: string, formData: any) =>
  req<null, unknown[]>(`businessCredit/${uuidEntry}/propose/${uuidPropose}/request`, { body: formData, method: 'PUT' });

const putProposeRequestField = (uuidEntry: string, uuidPropose: string, uuidRequest: string, formData: any) =>
  req<null, TRequestedData[]>(`businessCredit/${uuidEntry}/propose/${uuidPropose}/request/${uuidRequest}/field`, {
    body: formData,
    method: 'PUT',
  });

const setProposeRequestDocument = (uuidEntry: string, uuidPropose: string, uuidRequest: string, file: any) =>
  req<File, any[]>(`businessCredit/${uuidEntry}/propose/${uuidPropose}/request/${uuidRequest}/document`, {
    method: 'POST',
    body: { file },
  });

const deleteProposeRequestField = (uuidEntry: string, uuidPropose: string, uuidRequest: string) =>
  req<null, unknown[]>(`businessCredit/${uuidEntry}/propose/${uuidPropose}/request/${uuidRequest}`, {
    method: 'DELETE',
  });

const putUpdatePropose = (uuidEntry: string, payload: Partial<ECBCEntryPropose>) =>
  req<Partial<ECBCEntryPropose>, void>(`businessCredit/${uuidEntry}/propose/${payload.uuid!}`, { method: 'PUT', body: payload });

const postSelectPropose = (
  { uuidEntry, uuidPropose }: { uuidEntry: string; uuidPropose: string },
  propose: any,
): Promise<IRequestResult<void>> =>
  req<Partial<ECBCEntryPropose>, void>(`businessCredit/${uuidEntry}/propose/${uuidPropose}/select`, { method: 'PUT', body: propose });

export default {
  postCreateEntryWithCompany,
  putUpdateCompany,
  putUpdateEntry,
  postRecognizeDocument,
  getRequeiredDocuments,
  deleteDocument,
  putUpdateDocument,
  postUploadDocument,
  getDocuments,
  getEntry,
  getEntryList,
  deletePassport,
  putExcludeBanks,
  getProposeList,
  putUpdatePropose,
  getProposeRequest,
  putProposeRequest,
  putProposeRequestField,
  setProposeRequestDocument,
  postSelectPropose,
  deleteProposeRequestField,
};
