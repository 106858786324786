export enum ECompanyAddressType {
  factAddress = 'factAddress',
  legalAddress = 'legalAddress',
}
export enum ECompanyStatus {
  block = 'block',
  active = 'active',
  deleted = 'deleted',
  pending = 'pending',
}

export enum ECompanyRole {
  owner = 'owner',
  employee = 'employee',
  agent = 'agent',
}

export enum ECompanyInviteType {
  agent = 'agent',
}

export enum ECompanyTaxPaymentType {
  osno = 'osno',
  usnFifteen = 'usn-15',
  usnSix = 'usn-6',
}

export enum ECompanyRequisiteType {
  bic = 'bic',
  bankAccount = 'bankAccount',
  inn = 'inn',
  kpp = 'kpp',
  ogrn = 'ogrn',
}

export enum EDocumentType {
  offer = 'offer',
  other = 'other',
}

export enum ECompanyContactType {
  phone = 'phone',
  email = 'email',
}
export enum EDocumentStatus {
  active = 'active',
  previous = 'previous',
}
export enum EBusinessCreditEntryDocumentType {
  other = 'other',
  // Паспорт владельца
  passport = 'ownerPassport',
  // Бухгалтерский баланс - последний квартал
  balanceSheetLastQuartal = 'balanceSheetLastQuartal',
  // Бухгалтерский баланс - последний год
  balanceSheetLastYear = 'balanceSheetLastYear',
  // Банковская выписка
  bankStatement = 'bankStatement',
  // Налоговая декларация
  taxReturnLastYear = 'taxReturnLastYear',
}

export enum ECompanyDataType {
  revenueFigureLastQuartal = 'revenueFigureLastQuartal',
  revenueFigureLastYear = 'revenueFigureLastYear',
}
