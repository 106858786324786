import { z } from 'zod';
import { EProfileEducationType, EProfileMarriageContractStatus, EProfileWorkspaceJobSchema } from '../profile/profile.enum';
import { EProfileWorkspaceType } from '../incomes/incomes.enum';

const validation = {
  required_error: 'errors:validation.required',
  invalid_type_error: 'errors:validation.required',
};
enum BooleanVariant {
  yes = 'yes',
  no = 'no',
}

export const experimentSchema = z
  .object({
    haveAlterationOfName: z.nativeEnum(BooleanVariant),
    primaryEducation: z.nativeEnum(EProfileEducationType, {
      errorMap: (issue, _ctx) => {
        switch (issue.code) {
          case 'invalid_type':
            return { message: 'errors:validation.required' };
          case 'invalid_enum_value':
            return { message: 'errors:validation.required' };
          default:
            return { message: 'errors:validation.required' };
        }
      },
    }),
    marriageContractStatus: z.nativeEnum(EProfileMarriageContractStatus, validation).nullish(),
    isMarried: z.nativeEnum(BooleanVariant, validation),
    haveFamilyChildren: z.nativeEnum(BooleanVariant, validation),
    childrenBirthDate: z.array(z.string()),
    jobType: z.nativeEnum(EProfileWorkspaceType, validation),
    jobSchema: z.nativeEnum(EProfileWorkspaceJobSchema, validation),
    salaryAmount: z.number(validation),
  })
  .required({
    haveAlterationOfName: true,
    primaryEducation: true,
    isMarried: true,
    haveFamilyChildren: true,
    jobSchema: true,
    jobType: true,
    salaryAmount: true,
  });
