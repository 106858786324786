import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.45505 8.5L7.21401 14.6472H38.259L40.5 8.5H9.45505ZM6.09479 17.7182L3.85375 23.8642H34.8987L37.1397 17.7182H6.09479ZM2.74104 26.9365L0.5 33.0811H31.545L33.7834 26.9352C33.7847 26.9365 2.74104 26.9365 2.74104 26.9365Z'
      fill='#009FDF'
    />
  </svg>,
  'Vtb',
);
