import { req } from 'api/http';
import { IIncomesDataDTO } from './incomes.interface';

export function getIncomes({ uuidEntry, uuidUser }: { uuidEntry: string; uuidUser: string }) {
  return req<null, IIncomesDataDTO[]>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/incomes`, { method: 'GET' });
}

export function postIncomes({ uuidEntry, uuidUser }: { uuidEntry: string; uuidUser: string }, payload: IIncomesDataDTO[]) {
  return req<IIncomesDataDTO[], IIncomesDataDTO[]>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/incomes`, {
    method: 'POST',
    body: payload,
  });
}
