/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const PinIcon = createSvgIcon(
  <svg viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.6'>
      <path
        d='M7.22507 0.621223L4.0635 3.7856C4.0635 3.7856 2.65779 3.07959 1.15059 4.29861C1.10624 4.33453 1.06996 4.3794 1.0441 4.4303C1.01824 4.4812 1.00339 4.53699 1.00052 4.59402C0.997642 4.65105 1.00681 4.70805 1.02742 4.76129C1.04804 4.81454 1.07963 4.86284 1.12015 4.90304L6.59072 10.3785C6.63167 10.4202 6.68114 10.4526 6.73573 10.4734C6.79032 10.4942 6.84876 10.503 6.90706 10.4991C6.96535 10.4953 7.02213 10.4789 7.07351 10.451C7.12489 10.4232 7.16966 10.3846 7.20477 10.3378C7.63105 9.77405 8.30091 8.60582 7.71732 7.44267L10.8789 4.27829C10.9172 4.24048 10.9477 4.19541 10.9685 4.14571C10.9893 4.096 11 4.04266 11 3.98877C11 3.93489 10.9893 3.88154 10.9685 3.83184C10.9477 3.78213 10.9172 3.73706 10.8789 3.69926L7.80359 0.621223C7.76581 0.58283 7.72078 0.55234 7.67112 0.531529C7.62146 0.510718 7.56816 0.5 7.51433 0.5C7.46049 0.5 7.40719 0.510718 7.35753 0.531529C7.30787 0.55234 7.26284 0.58283 7.22507 0.621223Z'
        fill='#0C0B02'
      />
      <path
        d='M3.86049 7.64589L1.83059 9.67759M7.22507 0.621223L4.0635 3.7856C4.0635 3.7856 2.65779 3.07959 1.15059 4.29861C1.10624 4.33453 1.06996 4.3794 1.0441 4.4303C1.01824 4.4812 1.00339 4.53699 1.00052 4.59402C0.997642 4.65105 1.00681 4.70805 1.02742 4.76129C1.04804 4.81454 1.07963 4.86284 1.12015 4.90304L6.59072 10.3785C6.63167 10.4202 6.68114 10.4526 6.73573 10.4734C6.79032 10.4942 6.84876 10.503 6.90706 10.4991C6.96535 10.4953 7.02213 10.4789 7.07351 10.451C7.12489 10.4232 7.16966 10.3846 7.20477 10.3378C7.63105 9.77405 8.30091 8.60582 7.71732 7.44267L10.8789 4.27829C10.9172 4.24048 10.9477 4.19541 10.9685 4.14571C10.9893 4.096 11 4.04266 11 3.98877C11 3.93489 10.9893 3.88154 10.9685 3.83184C10.9477 3.78213 10.9172 3.73706 10.8789 3.69926L7.80359 0.621223C7.76581 0.58283 7.72078 0.55234 7.67112 0.531529C7.62146 0.510718 7.56816 0.5 7.51433 0.5C7.46049 0.5 7.40719 0.510718 7.35753 0.531529C7.30787 0.55234 7.26284 0.58283 7.22507 0.621223Z'
        stroke='#0C0B02'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>,
  'PinIcon',
);

export default PinIcon;
