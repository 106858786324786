import { Button, Paper, Typography, useTheme } from '@mui/material';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';

interface ProductCardProps {
  onClickCreate: () => void;
}

export default function ProductCard({ onClickCreate }: ProductCardProps) {
  const theme = useTheme();
  const businessCredit = useBusinessCredit();
  const hasOneOf = businessCredit.hasEntries;

  if (hasOneOf) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      // data-urlname={pg.urlname}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evently',
        gap: '8px',
        cursor: 'pointer',
        padding: '16px',
        background: theme.palette.common.softYellow,

        borderRadius: '16px',
      }}
    >
      {hasOneOf ? (
        <>
          <Typography variant={'h3b'} sx={{ lineHeight: '130%' }}>
            Еще продукты
          </Typography>
          <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
            откройте новые возможности
          </Typography>
        </>
      ) : (
        <>
          <Typography variant={'h3b'} sx={{ lineHeight: '130%' }}>
            Мои продукты
          </Typography>
          <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
            Вы пока ничего не открыли
          </Typography>
        </>
      )}
      {/* TODO: lineHeight -> Problems with typography theme override */}

      <Button onClick={onClickCreate} variant='contained' size='large' data-testid={'btnNewEntry'}>
        Открыть продукт
      </Button>
    </Paper>
  );
}
