/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const Zenit = createSvgIcon(
  <svg viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' fill='white' />
    <path
      d='M17 38.9119L31.7323 38.8953L31.7247 24.3105C28.6587 30.1278 22.9083 33.9131 17.0076 35.3598C17.0241 36.4616 17 38.9119 17 38.9119Z'
      fill='#008FB4'
    />
    <path
      d='M21.7106 31.5693C27.2704 28.7613 29.9513 23.6258 29.9513 23.6258C29.9513 23.6258 29.9321 21.9397 29.9321 21.9039C26.8685 20.8277 24.0913 19.2515 21.7161 16.8917C21.7286 20.894 21.7106 31.5693 21.7106 31.5693Z'
      fill='#008FB4'
    />
    <path
      d='M22.5034 9.5C22.5034 9.5 22.502 13.9595 22.4965 15.0718C23.9566 16.4287 24.6907 17.0636 26.209 18.0339L26.221 12.0555C24.4857 11.1018 23.8164 10.5948 22.5034 9.5Z'
      fill='#008FB4'
    />
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' stroke='#F5F5F5' />
  </svg>,
  'Zenit',
);
export default Zenit;
