export const setCookie = (name: string, value: string, seconds?: number, path?: string, domain?: string): void => {
  const cookie: string[] = [`${name}=${value}`];

  if (seconds) {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    cookie.push(`expires=${date.toUTCString()}`);
  }
  cookie.push(`path=${path || '/'}`);
  if (domain) cookie.push(`domain=${domain}`);
  document.cookie = cookie.join(';');
};

export const getCookie = (name: string) => {
  let matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[0]) : undefined;
};
