/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const SuccessTimeIcon = createSvgIcon(
  <svg viewBox='0 0 153 153' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M76.5 153C118.75 153 153 118.75 153 76.5C153 34.2502 118.75 0 76.5 0C34.2502 0 0 34.2502 0 76.5C0 118.75 34.2502 153 76.5 153Z'
      fill='#F8E11A'
    />
    <path
      d='M133.571 152.996C144.301 152.996 153 144.297 153 133.567C153 122.837 144.301 114.139 133.571 114.139C122.841 114.139 114.143 122.837 114.143 133.567C114.143 144.297 122.841 152.996 133.571 152.996Z'
      fill='url(#paint0_radial_1_15)'
    />
    <g filter='url(#filter0_d_1_15)'>
      <path
        d='M143.285 126.28L130.157 140.851L122.642 132.669'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <g clipPath='url(#clip0_1_15)'>
      <path
        d='M76.9483 24.3333C48.4283 24.3333 25.3333 47.48 25.3333 76C25.3333 104.52 48.4283 127.667 76.9483 127.667C105.52 127.667 128.667 104.52 128.667 76C128.667 47.48 105.52 24.3333 76.9483 24.3333ZM77 117.333C54.1633 117.333 35.6667 98.8367 35.6667 76C35.6667 53.1633 54.1633 34.6667 77 34.6667C99.8367 34.6667 118.333 53.1633 118.333 76C118.333 98.8367 99.8367 117.333 77 117.333ZM79.5833 50.1667H71.8333V81.1667L98.9583 97.4417L102.833 91.0867L79.5833 77.2917V50.1667Z'
        fill='black'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_1_15'
        x='117.642'
        y='121.28'
        width='32.6429'
        height='26.5714'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx='1' dy='1' />
        <feGaussianBlur stdDeviation='2.5' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_15' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_15' result='shape' />
      </filter>
      <radialGradient
        id='paint0_radial_1_15'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(126.506 126.856) rotate(53.924) scale(30.5941)'
      >
        <stop stopColor='#44E529' />
        <stop offset='1' stopColor='#26B70E' />
      </radialGradient>
      <clipPath id='clip0_1_15'>
        <rect width='124' height='124' fill='white' transform='translate(15 14)' />
      </clipPath>
    </defs>
  </svg>,
  'SuccessTimeIcon',
);
export default SuccessTimeIcon;
