import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20 0C8.94009 0 0 8.94009 0 20C0 31.0599 8.94009 40 20 40C31.0599 40 40 31.0599 40 20C40 8.94009 31.0599 0 20 0ZM17.235 28.4793L15.7604 32.8111C10.2304 30.9677 6.54378 25.7143 6.54378 19.8157C6.54378 13.917 10.2304 8.75576 15.8525 6.82028L17.3272 11.1521C13.6406 12.3502 11.2442 15.8525 11.2442 19.7235C11.0599 23.7788 13.5484 27.2811 17.235 28.4793ZM24.0553 32.9032L22.6728 28.4793C26.4516 27.2811 28.9401 23.7788 28.9401 19.8157C28.9401 15.8525 26.4516 12.4424 22.765 11.2442L24.2396 6.82028C29.8617 8.66359 33.6406 13.917 33.6406 19.8157C33.6406 25.8986 29.7696 31.1521 24.0553 32.9032Z'
      fill='#00BEF0'
    />
  </svg>,
  'Otkiritie',
);
