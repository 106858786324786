import { FormScheme } from 'components/base/FormGenerator/form.types';
import queryString from 'query-string';
import { req } from '../http';

import { ESupportedForms, GetFormRequestQuery, IFormFillInput } from './form.dto';

export const MODE = {
  FILL_AGENT_CLIENT: 'fill',
  SUBS_AGENT_CLIENT: 'fill-empty-whole',
};

/**
 * get profile form
 * @param {ESupportedForms} formName
 * @param q
 * @returns
 */
export const getForm = (formName: ESupportedForms | string, q: Partial<GetFormRequestQuery> = {}) =>
  req<null, FormScheme>(`user/form/${formName}?${queryString.stringify(q)}`, { method: 'GET' });

/**
 * post form data
 * @param {ESupportedForms} formName
 * @param {IFormFillInput} body
 * @returns
 */
export const postForm = (formName: ESupportedForms | string, body: IFormFillInput) =>
  req(`user/form/${formName}/fill`, { method: 'POST', body });
