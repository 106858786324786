import { Button, SvgIcon, useTheme } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import ArrowDownIcon from 'components/Icons/ArrowDown';
import ArrowUpIcon from 'components/Icons/ArrowUp';
import PropTypes from 'prop-types';
import React from 'react';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const BaseAccordion = ({
  uuidPackage,
  disabledButton = false,
  disabledButtonText = '',
  defaultExpanded = false,
  children,
  btnTitle,
  handleBtnClick,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Accordion expanded={expanded}>{children}</Accordion>
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
        {handleBtnClick && (
          <>
            <Button value={uuidPackage} disabled={disabledButton} variant='contained' sx={{ width: 1 }} onClick={handleBtnClick}>
              {disabledButton ? disabledButtonText : btnTitle}
            </Button>
            <Button sx={{ padding: '6px', minWidth: '48px' }} onClick={handleExpand} variant='contained' color='secondary'>
              <SvgIcon component={expanded ? ArrowUpIcon : ArrowDownIcon} stroke={theme.palette.text.secondary} />
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

BaseAccordion.propTypes = {
  uuidPackage: PropTypes.string,
  disabledButton: PropTypes.object,
  disabledButtonText: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.any,
  btnTitle: PropTypes.string,
  handleBtnClick: PropTypes.func,
};

export default BaseAccordion;
