import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useFormLayoutContext } from 'components/base/FormGenerator/context/FormGeneratorProvider';
import { FormSchemeData } from 'components/base/FormGenerator/form.types';
import useFormGroup from 'components/base/FormGenerator/hooks/useFormGroup';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ft } from '../../../utils';
import InlineGroupWrapper from '../InlineGroupWrapper';

interface RenderableListProps extends ReturnType<typeof useFormGroup> {
  path: string[];
  node: Required<FormSchemeData>;
}
//@ts-ignore
interface RenderableCardGroup extends RenderableListProps {
  onSelect: (type: string, name: string, options?: Partial<{ append: boolean; showRadio: boolean }>) => void;
  showRadio: (value: boolean) => void;
}

export default function RenderableList({
  fieldValues,
  formFields,
  nodeFields,
  append,
  update,
  remove,
  path,
  node,
  onSelect,
}: RenderableCardGroup) {
  const { t } = useTranslation();
  const formLayoutContext = useFormLayoutContext();

  const handleBackEvent = () => {
    formLayoutContext.cleanState();
    onSelect(node.type, node.name, { append: false, showRadio: false });
  };

  return (
    <Fragment>
      {nodeFields.map((field) => (
        <Fragment key={`${field.name}__${field.type}`}>
          {formFields
            .filter((x) => x.name === field.name)
            .map((valueField, ddx) => (
              <InlineGroupWrapper
                key={valueField.uuid}
                item={valueField}
                path={[...path, node.name]}
                index={ddx}
                noIndex={node.settings.single || field?.settings?.single}
                defaultValue={fieldValues.find((df) => df.uuid === valueField.uuid)}
                onValueUpdate={update}
                onRemoveValue={remove}
              />
            ))}

          {!node.settings.single && !field?.settings?.single && (
            <Button
              startIcon={<AddIcon />}
              data-testid={[...path, node.name, field.name].join('.')}
              variant='contained'
              fullWidth
              color='secondary'
              onClick={() => append(field.name as string)}
            >
              {t(`${ft([...path, node.name, field.name] as string[])}.btnAddTitle`)}
            </Button>
          )}
          {node.settings.hasButtonAdd && (
            <Button variant='contained' size='large' data-testid='addButton' fullWidth color='primary' onClick={handleBackEvent}>
              {t('common:save')}
            </Button>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
}
