import { Grid, SxProps, Typography, useTheme } from '@mui/material';
import { IMortgageEntryProposeDecision } from 'api/services/mortgage.dto';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

interface Props extends IMortgageEntryProposeDecision {
  percentColor: string;
  isShowPayment: boolean;
}
const typographySx: SxProps = { fontSize: { md: '22px', xs: '18px' }, fontWeight: { xs: 500, md: 400 } };

export default function ProposeCardContent({
  rate,
  initialFee,
  approvedSum,
  monthlyPayment,
  percentColor,
  creditDuration,
  isShowPayment,
}: Partial<Props>) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid container direction='row' spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6} md={2} sx={{ order: { xs: 1, lg: 1 } }}>
        <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
          {t('interface:base.ProposeCard.rate')}
        </Typography>
        <Typography sx={{ ...typographySx }} variant='h4'>
          {rate ? utils.format.percent(rate as number) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3} sx={{ order: { xs: 4, lg: 2 } }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
          {t('interface:base.ProposeCard.creditDuration')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          {t('common:date.month', { count: creditDuration })}
        </Typography>
      </Grid>
      <Grid item xs={6} md={4} sx={{ order: { xs: 2, lg: 3 } }}>
        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }} variant='h6'>
          {t('interface:base.ProposeCard.initialContribution')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          {initialFee ? utils.format.price((initialFee as number) / 100) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3} sx={{ order: { xs: 3, lg: 4 } }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
          {t('interface:base.ProposeCard.creditAmount')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          {approvedSum ? utils.format.price((approvedSum as number) / 100) : '-'}
        </Typography>
      </Grid>

      {isShowPayment && (
        <Grid item xs={12} lg={3} sx={{ order: { xs: 5, lg: 5 } }}>
          <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
            {t('interface:base.ProposeCard.monthlyPayment')}
          </Typography>
          <Typography
            sx={{
              fontSize: { md: '22px', xs: '26px' },
              fontWeight: { md: 500, xs: 500 },
              color: percentColor,
            }}
            variant='h2'
          >
            {t('common:from')} {monthlyPayment ? utils.format.price((monthlyPayment as number) / 100) : '-'}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
