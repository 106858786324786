import { getCookie, setCookie } from '.';
import storePlatform from 'utils/platform';
import queryString from 'query-string';
import { getApiUrl } from 'utils/env/env';
// const COOKIE_DOMAIN = new URL(`${import.meta.env.REACT_APP_HTTP_API_BASEURL}`).hostname.split('.').slice(-2).join('.') || undefined;
const COOKIE_DOMAIN = new URL(`${getApiUrl()}`).hostname.split('.').slice(-2).join('.') || undefined;

const COOKIE_PREFIX = '_uc_';
const COOKIE_EXPIRE = 60 * 60 * 24 * 30; // 30 days
const UTM_PARAMS = [
  // UTM params
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'affiliate_id',
  'source',
  // CPA params
  'transaction_id',
];

const alias: { [key: string]: string } = { source: 'utm_source' };

export const storeUTM = (): void => {
  const p = new URLSearchParams(window.location.search);
  // save params
  UTM_PARAMS.forEach((prm) => {
    if (!p.get(prm)) return;
    const name = alias[prm] || prm;
    // setCookie(`${COOKIE_PREFIX}${name}`, `${p.get(prm)}`, COOKIE_EXPIRE, '/', COOKIE_DOMAIN);
    setCookie(`${COOKIE_PREFIX}${name}`, `${p.get(prm)}`, COOKIE_EXPIRE, '/', !import.meta.env.DEV ? COOKIE_DOMAIN : 'localhost');
  });

  // save referrer
  setCookie(`${COOKIE_PREFIX}referrer`, document.referrer || 'direct', COOKIE_EXPIRE, '/', COOKIE_DOMAIN);

  storePlatform().then((data) => {
    Object.entries(data).forEach(([targetKey, targetValue]) => {
      setCookie(`${COOKIE_PREFIX}${targetKey}`, targetValue, COOKIE_EXPIRE, '/', COOKIE_DOMAIN);
    });
  });
};

/**
 * get all of utm cookies as regular js object
 * @returns
 */
export const getAllUTMCookies = () => {
  const params: Record<string, string> = {};
  UTM_PARAMS.forEach((prm) => {
    const val = getCookie(`${COOKIE_PREFIX}${prm}`);
    if (!val) return;
    params[prm] = val;
  });
  return params;
};

export const getUtmCookieQuery = () => queryString.stringify(getAllUTMCookies());
