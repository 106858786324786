import { genEsiaUrl } from 'utils/format/esiaURL';
import { getFullName, getPhoneNumber, hidePhoneNumber, nameWithInitials, percent, price } from 'utils/format/format';

import formatter from './formatter';
export default {
  nameWithInitials,
  genEsiaUrl,
  getFullName,
  percent,
  price,
  getPhoneNumber,
  hidePhoneNumber,
  ...formatter,
};
