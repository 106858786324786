export enum EBank {
  absolut = 'absolut',
  rosbank = 'rosbank',
  domrf = 'domrf',
  sovcom = 'sovcom',
  alfa = 'alfa',
  transkapital = 'transkapital',
  raiffeisen = 'raiffeisen',
  zenit = 'zenit',
  surgutneftegas = 'surgutneftegas',
  rusnarbank = 'rusnarbank',
  uralsib = 'uralsib',
  metallinvestbank = 'metallinvestbank',
  bgfbank = 'bgfbank',
  pskb = 'pskb',
  sberbank = 'sberbank',
  gazprombank = 'gazprombank',
  mkb = 'mkb',
}
