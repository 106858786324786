import { ObjectType } from 'utils/iots';

import { req } from '../http';

import { SuggestionAddress, SuggestionCompanyResponse } from './suggestions.dto';

export const suggestAddress = (q: string) => {
  return req<null, Array<SuggestionAddress>>(`suggest/address?query=${q}`, { method: 'GET' });
};

export const suggestBank = (q: ObjectType) => {
  return req<null, any[]>(`suggest/bankByQuery?query=${q}`, { method: 'GET' });
};

/**
 * get suggestion of search company
 * @param q name of company
 * @returns
 */
export const suggestSearchCompany = (q: string) =>
  req<null, SuggestionCompanyResponse[]>(`suggest/companyByName?query=${q}`, { method: 'GET' });

/**
 * get full data of company
 *
 * @param q
 * @returns
 */
export const suggestFullCompany = (q: string) => req<null, SuggestionCompanyResponse>(`suggest/companyByInn?query=${q}`, { method: 'GET' });

export const suggestBankByName = (q: string) => req<null, any[]>(`suggest/bankByName?query=${q}`, { method: 'GET' });
