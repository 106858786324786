import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IMortgageEntryCLientListResult } from 'api/services/agent.dto';
import utils from 'utils';
import { useTranslation } from 'react-i18next';
import { Button, LinearProgress } from '@mui/material';
import ClientTableToolbar from './components/ClientToolbar';
import ClientTableHead from './components/ClientTableHead';
import { Fragment, MouseEvent, useMemo, useState } from 'react';
import ClientTableDialog from './components/ClientTableDialog';
import { ClientTableModalType, headCells, stageProccess } from './utils';
import { formatDateUnix } from 'utils/format/format';
import { orderBy } from 'lodash';
import { useAgentClients } from 'components/providers/ClientProvider';
import { useNavigate } from 'react-router-dom';
import { PATH_AGENT_MORTGAGE_PROPERTY_INVITE } from 'routes/paths';
import { ChangeClientManager } from 'pages/Client/Mortgage/Entry/Progress/components/ChangeClientManager';
import { EUserRole } from 'api/services/user.dto';
import { Accessible } from 'components/providers/RoleAccessProvider';

export default function ClientTable({ clients }: { clients: IMortgageEntryCLientListResult[] }) {
  const navigate = useNavigate();
  const { loading, clientNavigateProgress } = useAgentClients();
  const { t } = useTranslation();
  const [order, setOrder] = useState<string | undefined>(undefined);
  const [orderColumn, setOrderColumn] = useState<string>();
  const [selectedClient, selectClient] = useState<IMortgageEntryCLientListResult>();
  const [modalShown, showModal] = useState(false);
  const [modalType] = useState<ClientTableModalType>(ClientTableModalType.creditParams);
  const [isManagersListModalOpen, setManagersListModalOpen] = useState(false);

  // const handleSelectAction = (uuidUser: string, modalType: ClientTableModalType) => (e: MouseEvent<unknown>) => {
  //   e.stopPropagation();
  //   showModal(!modalShown);
  //   setModalType(modalType);
  //   const client = clients.find((x) => x.user.uuid === uuidUser);
  //   selectClient(client);
  // };

  const handleSort = (propName: string) => () => {
    if (orderColumn !== propName) {
      setOrderColumn(propName);
      setOrder('asc');
    } else {
      setOrderColumn((prev) => {
        if (order === 'desc') {
          return undefined;
        }
        return prev;
      });
      setOrder((prev) => {
        if (!prev) {
          return 'asc';
        }
        if (prev === 'asc') {
          return 'desc';
        }
        return undefined;
      });
    }
  };

  const handleAddClient = () => {
    navigate(PATH_AGENT_MORTGAGE_PROPERTY_INVITE);
  };

  const visibleClients = useMemo(() => {
    const column = headCells.find((x) => x.id === orderColumn);
    if (!orderColumn && !order) {
      return clients;
    }
    return orderBy(clients, column?.getValue, [order as 'asc' | 'desc']);
  }, [clients, order, orderColumn]);

  const handleSelectClient = (_e: MouseEvent<unknown>, client: IMortgageEntryCLientListResult) => {
    clientNavigateProgress(client);
  };

  const handleAssignClick = (client: IMortgageEntryCLientListResult) => (_e: MouseEvent<unknown>) => {
    _e.stopPropagation();
    selectClient(client);
    setManagersListModalOpen(true);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ClientTableToolbar onAddClient={handleAddClient} />
      <TableContainer>
        <Table size='small' sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
          <ClientTableHead orderColumn={orderColumn} order={order} onSort={handleSort} />
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress sx={{ width: 1 }} />
                </TableCell>
              </TableRow>
            )}
            {visibleClients?.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const stage = row?.entry?.currentStage;
              const status = !!stage ? stageProccess?.[stage]?.(row.entry!) : undefined;
              return (
                <TableRow
                  onClick={(e) => handleSelectClient(e, row)}
                  hover
                  tabIndex={-1}
                  key={`${row?.user?.uuid!}`}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell component='th' id={labelId} scope='row' padding='none'>
                    {utils.format.getFullName(row?.user!)}
                  </TableCell>
                  {stage ? (
                    <Fragment>
                      <TableCell align='right'>{t(`common:businessCredit.stage.${stage}`)}</TableCell>
                      <TableCell align='right'>
                        {t([`common:businessCredit.status.${status}`, `common:businessCredit.status.filling`])}
                      </TableCell>
                    </Fragment>
                  ) : (
                    <TableCell colSpan={4} align='right'>
                      <Button
                        variant='outlined'
                        fullWidth
                        onClick={(_e) => {
                          handleSelectClient(_e, row);
                        }}
                      >
                        {t('interface:base.ClientTable.actions.create')}
                      </Button>
                    </TableCell>
                  )}
                  <TableCell align='right'>
                    {row.entry?.dates?.updatedAtTs && <>{formatDateUnix(row.entry?.dates?.updatedAtTs!)} </>}
                  </TableCell>
                  {stage ? (
                    <Accessible requireRoles={[EUserRole.admin, EUserRole.manager]}>
                      <TableCell align='right'>
                        <Button size='small' variant='outlined' fullWidth onClick={handleAssignClick(row)}>
                          Переназначить
                        </Button>
                      </TableCell>
                    </Accessible>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ClientTableDialog
        modalType={modalType}
        onClose={() => {
          showModal(false);
        }}
        client={selectedClient}
        open={modalShown}
      />
      <ChangeClientManager
        uuidUser={selectedClient?.user.uuid!}
        isOpen={isManagersListModalOpen}
        onClose={() => setManagersListModalOpen(false)}
      />
    </Box>
  );
}
