import { Box, LinearProgress, Typography } from '@mui/material';
import { ApiError } from 'api/errors';
import { LS_INVITE_LINK } from 'api/http';
import BaseButtonBack from 'components/base/Button/Back';
import ErrorMessage from 'components/base/ErrorMessage';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useAuth } from 'components/providers/AuthProvider';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH_SIGNIN, PATH_HOME, PATH_SHORTLINK_ACCEPT } from 'routes/paths';
import utils from 'utils';

import SigninPhoneCodeInput from './components/PhoneCodeInput';

const AuthSignInByPhoneCodePage = () => {
  const hasInvite = sessionStorage.getItem(LS_INVITE_LINK);
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [error, setError] = useState<ApiError>();
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  useEffect(() => {
    const clearCode = code.replace(/\D/g, '');

    // Check code length
    if (clearCode.length !== 4) return;

    // Try to log in
    const signin = async () => {
      setLoading(true);
      try {
        const res = await auth?.signin?.(clearCode);
        if (!res?.ok) {
          setError(res?.body?.name || 'logic.somethingWrong');
          return;
        }
        if (hasInvite) {
          sessionStorage.removeItem(LS_INVITE_LINK);
          navigate(utils.url.urlReplace(PATH_SHORTLINK_ACCEPT, { shortlink: hasInvite as string }));
        } else {
          navigate(PATH_HOME);
        }
      } catch (err: unknown) {
        setError(err as ApiError);
      } finally {
        setLoading(false);
      }
    };

    signin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  // TODO: onPaste resolver

  return (
    <ContainerLayout
      disableGutters
      sx={{ height: 1, width: 1 }}
      title={t('helmet:AuthSignInByPhoneCodePage.title')}
      meta={[{ name: 'description', content: t('helmet:AuthSignInByPhoneCodePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.AuthSignInByPhoneCodePage.inpCode')} backRoute={PATH_AUTH_SIGNIN} />,
        lg: <TopMenu.BackButton />,
      }}
      maxWidth='xl'
    >
      {loading && <LinearProgress sx={{ margin: '0 -20px', width: 1, position: 'absolute' }} />}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '57px',
          gap: { xs: '0px', lg: '40px' },
        }}
      >
        <Typography variant='h2' sx={{ display: { xs: 'none', lg: 'block' }, fontWeight: 400, width: 'fit-content' }}>
          {t('interface:page.AuthSignInByPhoneCodePage.inpCode')}
        </Typography>
        <SigninPhoneCodeInput onChange={handleChange} value={code} />
      </Box>

      <ErrorMessage error={error} />
    </ContainerLayout>
  );
};

export default AuthSignInByPhoneCodePage;
