/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const RusNar = createSvgIcon(
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='48' rx='24' fill='white' />
    <path
      d='M33.265 20.7226V16.8613C33.265 14.7628 31.565 13 29.355 13C27.23 13 25.53 14.7628 25.53 16.8613V20.7226H22.385C22.385 16.5255 18.9 13.1679 14.735 13.1679C10.4 13.0839 7 16.5255 7 20.7226C7 24.9197 10.485 28.2774 14.65 28.2774V32.1387C14.65 34.2372 16.35 36 18.56 36C20.77 36 22.47 34.3212 22.47 32.1387V28.2774H25.615C25.615 32.4745 29.1 35.8321 33.265 35.8321C37.515 35.8321 40.915 32.3905 40.915 28.2774C40.915 24.1642 37.43 20.7226 33.265 20.7226ZM13.12 20.7226C13.12 19.8832 13.8 19.2117 14.65 19.2117C15.5 19.2117 16.265 19.7993 16.265 20.7226C16.265 21.646 15.585 22.2336 14.735 22.2336C13.8 22.2336 13.12 21.562 13.12 20.7226ZM33.265 29.7883C32.415 29.7883 31.735 29.1168 31.735 28.2774C31.735 27.438 32.415 26.7664 33.265 26.7664C34.115 26.7664 34.795 27.438 34.795 28.2774C34.795 29.1168 34.115 29.7883 33.265 29.7883ZM37.855 19.2117C39.555 19.2117 41 17.8686 41 16.1058C41 14.3431 39.64 13 37.855 13C36.155 13 34.71 14.3431 34.71 16.1058C34.71 17.8686 36.155 19.2117 37.855 19.2117Z'
      fill='#0999D5'
    />
  </svg>,
  'RusNar',
);

export default RusNar;
