import { createSvgIcon } from '@mui/material';

const CoborrowerIcon = createSvgIcon(
  <svg viewBox="0 0 153 153" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M76.5 153C118.75 153 153 118.75 153 76.5C153 34.2502 118.75 0 76.5 0C34.2502 0 0 34.2502 0 76.5C0 118.75 34.2502 153 76.5 153Z"
      fill="#F8E11A"
    />
    <g filter="url(#filter0_d_1_15)">
      <path
        d="M67.3249 82.3891C67.2168 82.3905 67.1085 82.3913 67 82.3913C66.8915 82.3913 66.7833 82.3905 66.6751 82.3891C59.2875 82.4459 52.0386 84.4399 45.6375 88.1789C41.1237 90.8155 37.142 94.2551 33.8744 98.3138C31.949 100.705 33.8176 104 36.8878 104H67L97.1122 104C100.182 104 102.051 100.705 100.126 98.3138C96.8581 94.2551 92.8763 90.8154 88.3625 88.1788C81.9614 84.4399 74.7125 82.4459 67.3249 82.3891Z"
        fill="url(#paint0_radial_1_15)"
        shape-rendering="crispEdges"
      />
      <path
        d="M91.5 60.5C91.5 74.031 80.531 85 67 85C53.469 85 42.5 74.031 42.5 60.5C42.5 46.969 53.469 36 67 36C80.531 36 91.5 46.969 91.5 60.5Z"
        fill="url(#paint1_radial_1_15)"
        shape-rendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_d_1_15)">
      <path
        d="M86.3249 95.3891C86.2168 95.3905 86.1085 95.3913 86 95.3913C85.8915 95.3913 85.7833 95.3905 85.6751 95.3891C78.2875 95.4459 71.0386 97.4399 64.6375 101.179C60.1237 103.815 56.142 107.255 52.8744 111.314C50.949 113.705 52.8176 117 55.8878 117H86L116.112 117C119.182 117 121.051 113.705 119.126 111.314C115.858 107.255 111.876 103.815 107.363 101.179C100.961 97.4399 93.7125 95.4459 86.3249 95.3891Z"
        fill="url(#paint2_radial_1_15)"
        shape-rendering="crispEdges"
      />
      <path
        d="M110.5 73.5C110.5 87.031 99.531 98 86 98C72.469 98 61.5 87.031 61.5 73.5C61.5 59.969 72.469 49 86 49C99.531 49 110.5 59.969 110.5 73.5Z"
        fill="url(#paint3_radial_1_15)"
        shape-rendering="crispEdges"
      />
    </g>
    <path
      d="M133.571 152.996C144.301 152.996 153 144.297 153 133.567C153 122.837 144.301 114.139 133.571 114.139C122.841 114.139 114.143 122.837 114.143 133.567C114.143 144.297 122.841 152.996 133.571 152.996Z"
      fill="url(#paint4_radial_1_15)"
    />
    <g filter="url(#filter2_d_1_15)">
      <path
        d="M143.285 126.28L130.157 140.851L122.642 132.669"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_15"
        x="25.0925"
        y="25"
        width="99.815"
        height="100"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="8" dy="5" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_15" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_15" result="shape" />
      </filter>
      <filter
        id="filter1_d_1_15"
        x="44.0925"
        y="38"
        width="99.815"
        height="100"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="8" dy="5" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_15" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_15" result="shape" />
      </filter>
      <filter
        id="filter2_d_1_15"
        x="117.642"
        y="121.28"
        width="32.6429"
        height="26.5714"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_15" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_15" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_1_15"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(39.7736 45.9603) rotate(57.3212) scale(118.371 119.094)"
      >
        <stop offset="0.416533" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1_15"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(39.7736 45.9603) rotate(57.3212) scale(118.371 119.094)"
      >
        <stop offset="0.416533" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1_15"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(58.7736 58.9603) rotate(57.3212) scale(118.371 119.094)"
      >
        <stop offset="0.416533" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_1_15"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(58.7736 58.9603) rotate(57.3212) scale(118.371 119.094)"
      >
        <stop offset="0.416533" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_1_15"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(126.506 126.856) rotate(53.924) scale(30.5941)"
      >
        <stop stop-color="#44E529" />
        <stop offset="1" stop-color="#26B70E" />
      </radialGradient>
    </defs>
  </svg>,
  'CoborrowerIcon',
);

export default CoborrowerIcon;
