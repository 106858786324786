import { req } from 'api/http';
import { IMortgageEntryEntity, IMortgageEntryPropose, IMortgageEntryProposeDecision } from '../mortgage.dto';
import { EBank } from '../bank.dto';

/**
 * method to update propose decision
 * {@link https://gate.ip.mindcode.ru/documentation/static/index.html#/user-entry/updateProposeDecision}
 * @param uuidPropose uuid of propose
 * @param payload form data of edited propose
 * @returns
 */
export function updateProposeDecision(uuidPropose: string, payload: Partial<IMortgageEntryPropose>) {
  return req<Partial<IMortgageEntryPropose>, IMortgageEntryPropose>(`user/mortgage-entry/${uuidPropose}`, { method: 'PUT', body: payload });
}

export function excludeBanks({ uuidEntry, banks }: { uuidEntry: string; banks: EBank[] }) {
  return req(`user/mortgage-entry/${uuidEntry}/exclude-banks`, { method: 'PUT', body: banks.map((bank) => String(bank)) });
}

export function approveEntry(uuidEntry: string) {
  return req(`user/mortgage-entry/${uuidEntry}/approve`, { method: 'POST', body: {} });
}

/**
 * method to confirm final decision
 *
 * role: [manager] or [borrower]
 *
 * {@link https://gate.ip.mindcode.ru/documentation/static/index.html#/user-entry/updatePrimaryProposeDecision}
 * @param uuidEntry uuid of entry
 * @param uuidPropose uuid of propose
 * @param body  decision data
 * @returns
 */
export function postFinalProposeDecision(uuidEntry: string, uuidPropose: string, body: IMortgageEntryProposeDecision) {
  return req<IMortgageEntryProposeDecision, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/${uuidPropose}/finalDecision`, {
    method: 'PUT',
    body,
  });
}

export function approveRealEstate(uuidEntry: string) {
  return req(`${uuidEntry}/realEstate/approve`, { method: 'PUT' });
}
export function rejectRealEstate(uuidEntry: string) {
  return req(`${uuidEntry}/realEstate/reject`, { method: 'PUT' });
}
