import { TableHead, TableRow, TableCell, TableSortLabel, SortDirection } from '@mui/material';
import { headCells } from '../utils';
import { MouseEvent } from 'react';

export default function ClientTableHead({
  order,
  onSort,
  orderColumn,
}: {
  order?: string;
  orderColumn?: string;
  onSort: (propName: string) => (e: MouseEvent<unknown>) => void;
}) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={order as SortDirection}
            onClick={onSort(headCell.id)}
          >
            <TableSortLabel
              direction={orderColumn === headCell.id ? (order as 'asc' | 'desc') : 'asc'}
              active={orderColumn === headCell.id}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
