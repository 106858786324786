import { req } from 'api/http';
import { IShortLinkEntity } from 'api/services/short-link.dto';

import { Insurance, InsurancePerils, MortgageInsurance } from './insurance.dto';

export function getMortgageInsurance(uuidEntry: string) {
  return req<Insurance, MortgageInsurance>(`user/mortgage-entry/${uuidEntry}/insurance`, {
    method: 'GET',
  });
}

export function getMortgageInsurancePerils(uuidEntry: string) {
  return req<Insurance, InsurancePerils>(`user/mortgage-entry/${uuidEntry}/insurance/perils`, {
    method: 'GET',
  });
}

export function postMortgageSelect(uuidEntry: string) {
  return req<Insurance, IShortLinkEntity>(`user/mortgage-entry/${uuidEntry}/insurance/select`, {
    method: 'POST',
  });
}

export function postMortgageSelectPropose(uuidEntry: string, uuidPropose: string) {
  return req<Insurance, IShortLinkEntity>(`user/mortgage-entry/${uuidEntry}/insurance/proposes/${uuidPropose}/select`, {
    method: 'POST',
  });
}

export function postUpdateMortgageInsurance(uuidEntry: string, body: any) {
  return req<Insurance, IShortLinkEntity>(`user/mortgage-entry/${uuidEntry}/insurance`, {
    method: 'PATCH',
    body,
  });
}

export function postUpdateMortgagePerils(uuidEntry: string, body: Insurance) {
  return req<Insurance, IShortLinkEntity>(`user/mortgage-entry/${uuidEntry}/insurance/perils`, {
    method: 'PATCH',
    body,
  });
}
