import { IMortgageEntryProgress, IProfileProgressMortgage } from 'api/services/mortgage.dto';

/**
 * Calculate mortgage by annuity payment
 * @param {number} price  amount of credit
 * @param {number} pay first pay
 * @param {number} percent interest (%*100 for digit)
 * @param {number} period in months
 * @return {string} monthly payment
 */
export function mortgageMonthlyPayment(price: number, pay: number, percent: number, period: number): number {
  const i = percent / 100 / 100 / 12;
  const n = period;
  const r = (price - pay) * ((i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
  return parseInt((r * 100).toFixed(0), 10);
}

/**
 * calc percent of completed
 * @param progress {IMortgageEntryProgress}
 * @param step {keyof IMortgageEntryProgress}
 * @returns {number}
 */
export function stepEntryProgress(progress: IMortgageEntryProgress, step: keyof IMortgageEntryProgress) {
  if (!progress || !progress[step]) return 0;

  if (step === 'bank') {
    return progress[step].complete;
  }
  if (step === 'profile') {
    const values = progress[step].map((pg) => pg.progress)[0];
    const maxSum = Object.values(values).length * 100;

    const sum = Object.values(values).reduce<number>((partialSum, a) => partialSum + (a as number), 0);
    const result = (sum / maxSum) * 100;
    return Math.round(result);
  }

  const maxSum = Object.values(progress[step]).length * 100;

  const sum = Object.values(progress[step]).reduce<number>((partialSum, a) => partialSum + (a as number), 0);
  const result = (sum / maxSum) * 100;
  return Math.round(result);
}

function profileProgress(profileProgres: IProfileProgressMortgage) {
  const maxSum = Object.keys(profileProgres).length * 100;

  const sum = Object.values(profileProgres).reduce<number>((partialSum, a) => partialSum + (a as number), 0);
  const result = (sum / maxSum) * 100;
  return Math.round(result);
}

export default { mortgageMonthlyPayment, stepEntryProgress, profileProgress };
