import { createSvgIcon } from '@mui/material/utils';
// TODO: Provide colors from theme
const CheckboxIcon = createSvgIcon(
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='#B9B9B2' />
  </svg>,
  'CheckboxIcon',
);

export default CheckboxIcon;
