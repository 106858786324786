import UploadButton from 'components/common/UploadButton';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const FormFileField = ({ node, hookForm, path = [] }) => {
  const { control, watch, setValue } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  const handleFileUpload = (fileUrl) => {
    // TODO: Temp hack for form fill nulls
    setValue(path.join('.'), fileUrl === null ? '' : fileUrl);
  };

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={() => (
        <UploadButton
          path={path}
          data-testid={path.join('.')}
          fileLink={node?.value}
          onChange={handleFileUpload}
          allowDownload
          allowDelete
          infoDescription={node.settings.info}
          allowInfo={!!node.settings.info}
        />
      )}
    />
  );
};

FormFileField.propTypes = {
  node: PropTypes.object.isRequired,
  hookForm: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired,
};

export default FormFileField;
