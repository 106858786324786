import { HTTP_AUTH_SECRET, req } from '../http';

import { EAuthProvider, IAuthByPassword, IAuthBySmsCode, IIssueSmsCode, IRegisterUser, ISessionTokenPair } from './auth.dto';

export const authRequestSms = (phone: number) => {
  return req<IIssueSmsCode, null>('auth/confirmation/phone', { method: 'POST', body: { phone } });
};

export const authLoginEmail = (email: string, password: string, clientFingerprint: string) => {
  return req<{ provider: EAuthProvider.password; payload: IAuthByPassword }, ISessionTokenPair>('auth/login', {
    method: 'POST',
    body: {
      provider: EAuthProvider.password,
      payload: {
        email,
        password,
        secret: HTTP_AUTH_SECRET as string,
        device: {
          clientFingerprint,
        },
      },
    },
  });
};

export const authLoginBySmsCode = (phone: number, code: string, clientFingerprint: string) => {
  return req<{ provider: EAuthProvider.sms; payload: IAuthBySmsCode }, ISessionTokenPair>('auth/login', {
    method: 'POST',
    body: {
      provider: EAuthProvider.sms,
      payload: {
        phone,
        code,
        secret: HTTP_AUTH_SECRET,
        device: {
          clientFingerprint,
        },
      },
    },
  });
};

export const authLogout = () => req<null, unknown>('auth/logout', { method: 'POST' });

export const authRegisterBySmsCode = ({
  email,
  phone,
  password,
  lastname,
  firstname,
  patronymic,
  code,
  autoLogin,
  roles,
  inviteLink,
  clientFingerprint,
}: IRegisterUser & { clientFingerprint: string }) => {
  return req<{ provider: string; payload: IRegisterUser }, ISessionTokenPair>('auth/register', {
    method: 'POST',
    body: {
      provider: 'app',
      payload: {
        roles,
        email,
        phone,
        password,
        lastname,
        firstname,
        patronymic,
        code,
        autoLogin,
        inviteLink,
        device: {
          clientFingerprint,
        },
        secret: HTTP_AUTH_SECRET,
      },
    },
  });
};
