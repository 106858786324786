import { InputProps, TextField, Typography } from '@mui/material';
import { FormSchemeData } from 'components/base/FormGenerator/form.types';
import { HookForm } from 'components/base/FormGenerator/types';
import _ from 'lodash';
import { FC, Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ft } from '../../utils';
import { MASK, maskComponent } from '../inputMask';

interface FormNumberFieldProps {
  node: FormSchemeData;
  hookForm: HookForm;
  path: string[];
}

const FormNumberField: FC<FormNumberFieldProps> = ({ node, hookForm, path = [] }) => {
  const { t } = useTranslation();
  const { control, watch } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={({ field, fieldState }) => {
        const f = {
          ...field,
          value: field?.value ? String(field.value) : '',
        };

        const inputProps = maskComponent(node?.settings?.mask || MASK.ONLY_NUMBERS, {
          ...f,
          'data-testid': path.join('.'),
        }) as Partial<InputProps>;

        return (
          <Fragment>
            <TextField
              {...f}
              id={path.join('.')}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message}
              variant='standard'
              label={t(`${ft(path)}.title`)}
              InputProps={inputProps}
            />
            {node?.settings?.description && (
              <Typography component='span' sx={{ color: 'text.secondary', lineHeight: '140%', whiteSpace: 'normal', fontSize: '14px' }}>
                {t(`${ft(path)}.description`)}
              </Typography>
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default FormNumberField;
