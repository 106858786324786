import { createSvgIcon } from '@mui/material';

const DocumentSuccess = createSvgIcon(
  <svg viewBox="0 0 256 257" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="128" cy="128.488" r="128" fill="#FEFCE8" />
    <circle cx="128" cy="128.488" r="106" fill="#F8E11A" />
    <path
      d="M146 81.5283H168.5C169.693 81.5283 170.838 82.0045 171.682 82.8522C172.526 83.6998 173 84.8495 173 86.0483V180.968C173 182.167 172.526 183.317 171.682 184.164C170.838 185.012 169.693 185.488 168.5 185.488H87.5C86.3065 185.488 85.1619 185.012 84.318 184.164C83.4741 183.317 83 182.167 83 180.968V86.0483C83 84.8495 83.4741 83.6998 84.318 82.8522C85.1619 82.0045 86.3065 81.5283 87.5 81.5283H110"
      fill="#F8E11A"
    />
    <path
      d="M105.5 99.6083V95.0883C105.5 89.0944 107.871 83.346 112.09 79.1077C116.31 74.8693 122.033 72.4883 128 72.4883C133.967 72.4883 139.69 74.8693 143.91 79.1077C148.129 83.346 150.5 89.0944 150.5 95.0883V99.6083H105.5Z"
      fill="#F8E11A"
    />
    <path
      d="M110 144.808H146M110 126.728H146M146 81.5283H168.5C169.693 81.5283 170.838 82.0045 171.682 82.8522C172.526 83.6998 173 84.8495 173 86.0483V180.968C173 182.167 172.526 183.317 171.682 184.164C170.838 185.012 169.693 185.488 168.5 185.488H87.5C86.3065 185.488 85.1619 185.012 84.318 184.164C83.4741 183.317 83 182.167 83 180.968V86.0483C83 84.8495 83.4741 83.6998 84.318 82.8522C85.1619 82.0045 86.3065 81.5283 87.5 81.5283H110M105.5 99.6083V95.0883C105.5 89.0944 107.871 83.346 112.09 79.1077C116.31 74.8693 122.033 72.4883 128 72.4883C133.967 72.4883 139.69 74.8693 143.91 79.1077C148.129 83.346 150.5 89.0944 150.5 95.0883V99.6083H105.5Z"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>,
  'DocumentSuccess',
);
export default DocumentSuccess;
