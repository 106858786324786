import { Coordinates } from 'types/draggable/index';

const preventDefault = (event: Event): void => {
  if (event.cancelable) {
    event.preventDefault();
  }
};

const options = {
  passive: false,
};

// Prevent scrolling of window
export function preventTouchScrolling(): void {
  window.addEventListener('touchmove', preventDefault, options as EventListenerOptions);
}

// Release scrolling of window
export function releaseTouchScrolling(): void {
  window.removeEventListener('touchmove', preventDefault, options as EventListenerOptions);
}

// Get the x, y position from event (cursor, touch)
export function getPositionFromEvent(event: TouchEvent & MouseEvent): Coordinates {
  return event.touches
    ? {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      }
    : {
        x: event.clientX,
        y: event.clientY,
      };
}
