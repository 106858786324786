import { Box, TextareaAutosize } from '@mui/material';
import { IMortgageEntryPropose } from 'api/services/mortgage.dto';
import { useFormContext, Controller } from 'react-hook-form';

export default function AdditionalForm() {
  const { control } = useFormContext<IMortgageEntryPropose>();

  return (
    <Box>
      <Controller
        control={control}
        name="decision.additionalConditions"
        defaultValue=""
        render={({ field }) => (
          // @ts-ignore
          <TextareaAutosize style={{ width: '100%' }} minRows={3} {...field} />
        )}
      />
    </Box>
  );
}
