import { Grid, SxProps, TextField, Typography, useTheme } from '@mui/material';
import { IMortgageEntryPropose } from 'api/services/mortgage.dto';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const typographySx: SxProps = { fontSize: { md: '22px', xs: '18px' }, fontWeight: { xs: 500, md: 400 } };

export default function ProposeEditForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = useFormContext<IMortgageEntryPropose>();

  return (
    <Grid container direction='row' spacing={{ xs: 2, md: 3 }}>
      <Grid item md={2} sx={{ order: { xs: 1, lg: 1 } }}>
        <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
          {t('interface:base.ProposeCard.rate')}
        </Typography>
        <Typography variant='h4'>
          <Controller
            control={control}
            name="decision.rate"
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                variant="standard"
                data-testid={field.name}
                thousandSeparator=' '
                suffix=' %'
                fullWidth
                customInput={TextField}
                value={field.value}
                onValueChange={(values) => {
                  field.onChange(values.floatValue);
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Typography>
      </Grid>
      <Grid item md={2} sx={{ order: { xs: 4, lg: 2 } }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
          {t('interface:base.ProposeCard.creditDuration')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          <Controller
            control={control}
            name="decision.creditDuration"
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                variant="standard"
                data-testid={field.name}
                suffix=' мес.'
                fullWidth
                customInput={TextField}
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Typography>
      </Grid>
      <Grid item md={2} sx={{ order: { xs: 2, lg: 3 } }}>
        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }} variant='h6'>
          {t('interface:base.ProposeCard.initialContribution')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          <Controller
            control={control}
            name="decision.initialFee"
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                variant="standard"
                data-testid={field.name}
                thousandSeparator=' '
                suffix=' ₽'
                fullWidth
                customInput={TextField}
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Typography>
      </Grid>
      <Grid item md={2} sx={{ order: { xs: 3, lg: 4 } }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
          {t('interface:base.ProposeCard.creditAmount')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          <Controller
            control={control}
            name="decision.approvedSum"
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                variant="standard"
                data-testid={field.name}
                thousandSeparator=' '
                suffix=' ₽'
                fullWidth
                customInput={TextField}
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Typography>
      </Grid>
      <Grid item md={2} sx={{ order: { xs: 3, lg: 4 } }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
          {t('interface:base.ProposeCard.monthlyPayment')}
        </Typography>

        <Controller
          control={control}
          name="decision.monthlyPayment"
          render={({ field, fieldState }) => (
            <NumberFormat
              required
              variant="standard"
              data-testid={field.name}
              thousandSeparator=' '
              suffix=' ₽'
              fullWidth
              customInput={TextField}
              value={field.value}
              onChange={(e: ObjectType) => {
                field.onChange(utils.format.digitNumber(e.target.value));
              }}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
