// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectType = any;
export type EnumKeysAsString<T> = keyof T;
export type AssertionTypes = 'number' | 'boolean' | 'string' | 'object';
export type TMakeFieldsOptional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};

export type Nullable<T> = T | null;
export type Fn<T> = () => T;
export type FnArg<T> = (...args: ObjectType) => T;
export type EnumToRecord<TEnum extends string> = Record<TEnum, string>;

export type PathSegments<Path extends string> = Path extends `${infer SegmentA}/${infer SegmentB}`
  ? ParamOnly<SegmentA> | PathSegments<SegmentB>
  : ParamOnly<Path>;
export type ParamOnly<Segment extends string> = Segment extends `:${infer Param}` ? Param : never;
export type RouteParams<Path extends string> = {
  [Key in PathSegments<Path>]?: string;
};

export type TLocationParams<T extends string> = RouteParams<T>;

function assertObject<TOut>(value: ObjectType, prop: keyof ObjectType): TOut | null {
  if (typeof value === 'object') {
    if (value[prop]) {
      return value;
    }
  }
  return null;
}

function isPrimitiveType(value: unknown) {
  return typeof value === 'string' || typeof value === 'number';
}

export default { isPrimitiveType, assertObject };
