import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClientTableModalType } from '../utils';
import ScoringPanel from 'pages/Client/Mortgage/Entry/Progress/components/Scoring';
import { IMortgageEntryCLientListResult } from 'api/services/agent.dto';
import ClientCreditParams from './ClientCreditParams';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  open: boolean;
  modalType: ClientTableModalType;
  onClose: () => void;
  client?: IMortgageEntryCLientListResult;
}

export default function ClientTableDialog({ open, modalType, onClose, client }: Props) {
  const { t } = useTranslation();
  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h3">{t(`interface:base.ClientTable.Dialog.${modalType}`)}</Typography>{' '}
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: 1 }}>
          {modalType === ClientTableModalType.scoringParams && (
            <ScoringPanel expanded={true} uuidUser={client?.user.uuid!} uuidEntry={client?.entry?.uuid!} />
          )}
          {modalType === ClientTableModalType.creditParams && <ClientCreditParams />}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
