import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    light: '#FAEA64',
    main: '#F8E11A',
    dark: '#DFC801',
    contrastText: '#0C0B02',
  },
  secondary: {
    light: '#F4F3EB',
    main: '#F5F5F5',
    dark: '#EEEDE1',
    contrastText: '#0C0B02',
  },
  error: {
    light: '#FFEAEA',
    main: '#E81E1E',
  },
  warning: {
    main: '#F4A611',
  },
  info: {
    light: '#EAF3FF ',
    main: '#1A6CE7',
  },
  success: {
    light: '#EAFAE8',
    main: '#31C918',
    dark: '#29a814',
  },
  // TODO: Need to undestand how it's work
  // grey: {...}
  text: {
    primary: '#0C0B02',
    secondary: '#787770',
    disabled: '#B9B9B2',
  },
  common: {
    softGreen: '#EAFAE8',
    green: '#31C918',
    softGrey: '#D2D2CF',
    tariffBages: {
      premium: '#1AE7A9',
      premiumText: '#000000',
      basic: '#F4A611',
      basicText: '#FFFFFF',
      ultra: '#BE1AE7',
      ultraText: '#FFFFFF',
    },
    delete: '#E81E1E',
    link: '#1A6CE7',
    white: '#FFFFFF',
    softYellow: '#FEFCE8',
    guestRegisterBtn: '#FFF7B1',
    guestSigninBtn: '#FFFFFF',
    btnHover: '#EFD916',
    greyLight: '#B9B9B2',
    black: '#0C0B02',
  },
  background: {
    default: '#FFFFFF',
  },
};

export default palette;
