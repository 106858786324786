//@ts-nocheck

import { useState } from 'react';
import { DocumentExperiment, EProfileDocumentType } from 'api/services/experiment/experiment.dto';
import { ApiError } from 'api/errors';
import { useTranslation } from 'react-i18next';
import { userUploadFile } from 'api/services/user';
import _ from 'lodash';

import { IDocumentService } from './types';
import { EBusinessCreditEntryDocumentType } from 'api/services/businessCredit/businessCredit.enum';

export enum DocumentLoadingState {
  loading = 'loading',
  loaded = 'loaded',
  recognition = 'recognition',
}

const { REACT_APP_STORAGE_URL: STORAGE_URL } = import.meta.env;

const ALLOW_PASSPORTS = [String(EProfileDocumentType.passport), String(EBusinessCreditEntryDocumentType.passport)];

function useRequiredDocuments<T>(apiParams: T, service: IDocumentService<T>) {
  const [apiError, setApiError] = useState<ApiError>();
  const [requiredDocuments, setRequiredDocuments] = useState<DocumentExperiment[]>([]);

  const [canSubmit, setCanSubmit] = useState(false);
  const loadAsync = async () => {
    try {
      const { body: documentResponse } = await service.requiredDocumentsRequest(apiParams);
      // setRequiredDocuments(documentResponse.requiredDocs);
      // setCanSubmit(documentResponse.isFull);
    } catch (err) {
      setApiError(err);
    }
  };

  return { loadAsync, requiredDocuments, apiError, canSubmit };
}

/**
 * hook to provide file management and execution methods for these files
 * @param apiParams
 * @returns
 */
function useFileManager<T>(apiParams: T, updateCallback: () => Promise<void>, service: IDocumentService<T>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<DocumentLoadingState>();
  const [, setApiError] = useState<ApiError>();
  const [documents, setDocuments] = useState<DocumentSourceMortgage[]>([]);

  const cleanError = () => {
    setApiError(undefined);
  };
  /**
   * execute load async to preload all documents and transform to descriptor
   */
  const loadAsync = async (): Promise<void> => {};
  /**
   * execute delete of file descriptor
   *
   * but has 2 cases:
   * 1. if delete document with some links and EProfileDocumentType===other will be execute update method
   * 2.
   * @param uuidFile uuid file descriptor
   * @returns {Promise<void>}
   */
  const deleteFile = async (uuidFile: string): Promise<void> => {};

  const passportUpdateEffect = async (descriptor: unknown): Promise<boolean | void> => {
    console.log(descriptor);
  };

  /**
   * update file method to update file type or file type
   * @param descriptor
   * @returns
   */
  const updateFile = async (_uuidFile: string, descriptor: FileDescriptor): Promise<void> => {};
  /**
   * execute method for classification and recognition data from image
   *
   * @param link to document
   */
  const recognizeDocument = async (src: string): Promise<void> => {};

  /**
   *
   * @param file  file to upload document
   * @returns
   */
  const uploadFile = async (file: File): Promise<void> => {};
  const processFiles = async (files: File[]) => {};

  return { loadAsync, deleteFile, updateFile, recognizeDocument, uploadFile, documents, processFiles, loading };
}

export default { useFileManager, useRequiredDocuments };
