function dataURLtoFile(dataurl: string, filename: string, mimeType = 'image/png') {
  return fetch(dataurl)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const getExtension = (filename: string) => filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;

export default { dataURLtoFile, getExtension };
