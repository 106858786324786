import { ApiError } from 'api/errors';
import { EContractStatus, EContractType } from 'api/services/contract.dto';
import { entryProgress } from 'api/services/mortgage';
import { useCallback, useState } from 'react';

interface UseContractLogicParams {
  uuidEntry?: string;
  uuidUser?: string;
}
export type UseContractLogic = ReturnType<typeof useContractLogic>;

/**
 *contract logic is used to call methods for working with contracts
 *
 * @returns {UseContractLogic}
 */
export default function useContractLogic({ uuidEntry, uuidUser }: UseContractLogicParams) {
  const [error, setError] = useState<ApiError>();

  /**
   *
   * @description
   *
   * checking the contract for compliance with the {contractType} and {contractStatus
   *
   * @example sample usage
   *```ts
   * (async()=>{
   * const res= await hasContractRequest({
   *    contractType: EContractType.creditHistoryContract,
   *    contractStatus: EContractStatus.ready
   * });
   *
   * if(!!res){
   * //do something logic
   * }
   *
   * })();
   * ```
   */
  const hasContractRequest = useCallback(
    async ({ contractType, contractStatus }: { contractType: EContractType; contractStatus: EContractStatus }) => {
      try {
        if (!uuidEntry) {
          return;
        }
        const response = await entryProgress(uuidEntry);
        const { profile } = response.body;

        if (profile) {
          const user = profile.find((x) => x.ids.uuidUser === uuidUser);
          const contract = user?.contracts.find((x) => x.type === contractType && x.status === contractStatus);

          if (contract) {
            return contract;
          }
        }
      } catch (err: unknown) {
        setError(err as ApiError);
        return;
      }

      return;
    },
    [uuidEntry, uuidUser],
  );

  return { hasContractRequest, error };
}
