import { Box, Button, Chip, Typography } from '@mui/material';
import { StoryModel } from '../types';

type StoryItemProps = StoryModel;

export default function StoryBox(props: StoryItemProps) {
  if (props.children) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '40px 24px',
          width: 1,
          // background: 'radial-gradient(at left top, #20E1FA, #0D1466)',
          // ...(sx || {}),
          ...(props.sx || {}),
        }}
      >
        {props.children}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '40px 24px',
        width: 1,
        // background: 'radial-gradient(at left top, #20E1FA, #0D1466)',
        // ...(sx || {}),
        ...(props.sx || {}),
      }}
    >
      {props.bannerImage && (
        <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            src={props.bannerImage.src}
            alt={props.bannerImage.alt}
            sx={{ width: { lg: '50%', xs: '100%' }, ...(props.bannerImage.sx || {}) }}
            component='img'
          />
        </Box>
      )}
      {props.title && (
        <Typography variant='h2b' sx={{ fontSize: '26px' }}>
          {props.title}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '16px', ...(props.actionElement ? { marginBottom: '16px' } : {}) }}>
        {props.description.map((content, idx) => (
          <Typography key={idx} sx={{ fontSize: '18px', flexGrow: 1, ...(content.sx || {}) }}>
            {content.content}
          </Typography>
        ))}
      </Box>
      {props.actionElement && (
        <Button component='a' {...props.actionElement}>
          {props.actionElement?.children}
        </Button>
      )}

      {props.tags && (
        <Box sx={{ display: 'inline-flex', gap: '8px', marginTop: '20px' }}>
          {props.tags.map((tag) => (
            <Chip label={tag.title} component='a' href={tag.href} sx={{ color: '#1A6CE7', backgroundColor: '#fff', ...(tag.sx || {}) }} />
          ))}
        </Box>
      )}
    </Box>
  );
}
