import { Button, Container, Grid, Typography } from '@mui/material';
import { LS_SIGN_CONTEXT } from 'api/http';
import { EContractStatus, EContractType } from 'api/services/contract.dto';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useAuth } from 'components/providers/AuthProvider';
import useContractLogic from 'hooks/logic/useContractLogic';
import useNavigateParams from 'hooks/useNavigateParams';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY } from 'routes/paths';

export type TLocationParams = {
  uuid: string;
};

const ClientMortgageEntrySignInfoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigateParams();
  const auth = useAuth();
  const [, setHasContract] = useState<boolean | null>(null);

  const { uuid } = useParams<keyof TLocationParams>() as TLocationParams;
  const logic = useContractLogic({ uuidEntry: uuid, uuidUser: auth.user?.uuid });
  const handleNextBtnClick = () => {
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, { params: { uuid }, query: { step: 'form' } });
  };
  const handleCanceBtnClick = () => {
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid } });
  };

  useEffect(() => {
    localStorage.removeItem(LS_SIGN_CONTEXT);
    localStorage.removeItem('ls-esea-key');

    logic
      .hasContractRequest({
        contractType: EContractType.creditHistoryContract,
        contractStatus: EContractStatus.ready,
      })
      .then((res) => {
        setHasContract(!!res);
      });
  }, []);

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntrySignInfoPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntrySignInfoPage.description') }]}
      header={{ xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntrySignInfoPage.title')} />, lg: <TopMenu /> }}
      maxWidth='xl'
      sx={{ height: 0.85 }}
    >
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          height: 1,
          gap: '24px',
        }}
      >
        <Typography variant='h1b' sx={{ fontSize: { md: '48px', xs: '26px' } }}>
          {t('interface:page.ClientMortgageEntrySignInfoPage.lblTitleReady')}
        </Typography>
        <Typography variant='h3'>{t('interface:page.ClientMortgageEntrySignInfoPage.lblDescriptionReady')}</Typography>
        {/* Controls */}
        <Grid container direction='row' spacing={{ md: 2, xs: 2 }}>
          <Grid item md={6} xs={12}>
            <Button onClick={handleCanceBtnClick} fullWidth variant='contained' color='secondary' size='large'>
              {t('interface:page.ClientMortgageEntrySignInfoPage.btnCancel')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button onClick={handleNextBtnClick} fullWidth variant='contained' size='large'>
              {t('interface:page.ClientMortgageEntrySignInfoPage.btnNext')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
};

export default ClientMortgageEntrySignInfoPage;
