import { ApiError } from 'api/errors';
import { IBusinessCreditCompanyUuidDTO } from 'api/services/businessCredit/businessCredit.dto';
import businessCreditService from 'api/services/businessCredit/businessCredit.service';
import { DocumentProgress, DocumentSource, EProfileDocumentType, IPreloadDocument } from 'api/services/experiment/experiment.dto';
import { useSnackbar } from 'notistack';
import { ReactChild, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { genUuid } from 'utils/crypto/uuid';

interface BusinessDocumentContextProps {
  apiError: ApiError;
  documents: DocumentSource[];
  documentProgress: DocumentProgress[];
  fetchState: undefined;
  uploadRequest(file: File): Promise<void>;
  updateRequest(document: DocumentSource, callback: (err: ApiError) => void): Promise<void>;
  deleteRequest(document: DocumentSource): Promise<void>;
  typesOfFiles: Array<{ type: EProfileDocumentType; subType: string; label: string; year?: string; quarter?: string }>;
  queue: IPreloadDocument[];
  canSubmit: boolean;
}

const BusinessDocumentContext = createContext<Partial<BusinessDocumentContextProps>>({});

export default function BusinessDocumentProvider({ children }: { children: ReactChild }) {
  const { t } = useTranslation();
  const params = useParams() as unknown as IBusinessCreditCompanyUuidDTO;
  const [documents, setDocuments] = useState<DocumentSource[]>([]);
  const [documentProgress, setDocumentProgress] = useState<DocumentProgress[]>([]);
  const [apiError, setApiError] = useState<ApiError>();
  const [queue, updateQueue] = useState<IPreloadDocument[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const [fetchState, setFetchState] = useState();
  const controller = useRef<AbortController>(new AbortController());
  const [mutex, updateMutex] = useState<boolean>(false);

  const cleanError = () => {
    setApiError(undefined);
    setFetchState(undefined);
  };

  const lockMutex = () => updateMutex(true);
  const unlockMutext = () => updateMutex(false);

  const loadAll = useCallback(
    async (blockable: boolean = true) => {
      if (mutex && blockable) {
        return;
      }
      cleanError();
      try {
        const [progressRes, documentRes] = await Promise.all([
          businessCreditService.getRequeiredDocuments(params, controller.current.signal),
          businessCreditService.getDocuments(params, controller.current.signal),
        ]);
        setDocuments(documentRes.body);
        setDocumentProgress(progressRes.body);
      } catch (err) {
        setApiError(err);
      }
    },
    [mutex],
  );

  const uploadRequest = async (file: File): Promise<void> => {
    lockMutex();
    const uuid = genUuid();
    updateQueue([...queue, { name: file.name, uuid, loaded: false }]);
    cleanError();
    if (!file) {
      return;
    }
    try {
      await businessCreditService.postUploadDocument(params, file);
    } catch (err) {
      setApiError(err);
      enqueueSnackbar(`Неверный формат файла ${file.name}`, { variant: 'error', autoHideDuration: 3000 });
    } finally {
      updateQueue(queue.filter((x) => x.uuid !== uuid));
      unlockMutext();
      await loadAll();
    }
  };
  const deleteRequest = async (document: DocumentSource): Promise<void> => {
    lockMutex();
    cleanError();
    try {
      await businessCreditService.deleteDocument(params, { uuidDocument: document.uuid });
    } catch (err) {
      setApiError(err);
    } finally {
      unlockMutext();
      await loadAll();
    }
  };
  const updateRequest = async (document: DocumentSource, callback: (error: ApiError) => void): Promise<void> => {
    lockMutex();
    try {
      await businessCreditService.putUpdateDocument(params, document);
    } catch (err) {
      err.uuid = document.uuid;
      callback(err);
      setApiError(err);
    } finally {
      unlockMutext();
      await loadAll();
    }
  };

  useEffect(() => {
    const timer = setInterval(loadAll, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const typesOfFiles = useMemo(() => {
    const result = [];

    for (const document of documentProgress) {
      const doc = documents.find((x) => x.type === document.type);

      if (document.type === EProfileDocumentType.passport) {
        continue;
      }

      if (doc?.year === document.year) {
        if (doc.quarter === document.quarter) {
          continue;
        }
      }

      const label =
        document?.quarter === null
          ? t(`common:documents.${document.type}.title`, document)
          : t(`common:documents.${document.type}.title_quarter`, document);
      result.push({
        type: document.type,
        label: label,
        subType: `${document.type}_${document.quarter ? 'quarter' : 'year'}`,
        year: document.year,
        quarter: document.quarter,
      });
    }
    result.push({
      type: EProfileDocumentType.passport,
      subType: 'passport_',
      label: t('common:documents.passport.title'),
    });

    return result;
  }, [documentProgress]);

  const canSubmit = useMemo(() => {
    if (documentProgress?.length) return documentProgress.every((x) => x.isPresent);
    return false;
  }, [documentProgress]);

  return (
    <BusinessDocumentContext.Provider
      value={{
        documents,
        fetchState,
        documentProgress,
        deleteRequest,
        uploadRequest,
        updateRequest,
        typesOfFiles,
        queue,
        apiError,
        canSubmit,
      }}
    >
      {children}
    </BusinessDocumentContext.Provider>
  );
}

export function useBusinessDocuments() {
  return useContext(BusinessDocumentContext) as BusinessDocumentContextProps;
}
