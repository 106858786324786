import { Button, Stack, SvgIcon, useTheme } from '@mui/material';
import { EUserRole, IUserEntity } from 'api/services/user.dto';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as p from 'routes/paths';

// TODO: Refactor for one role system
// TODO: Move text to i18n files
// TODO: Use themes for colors

type MenuItem = { code: string; visible: (user: IUserEntity | null | undefined) => boolean };
const MENU_ITEMS = [
  {
    code: 'general',
    visible: (user) => user?.roles.includes(EUserRole.agent),
  },
  {
    code: 'passport',
    visible: (user) => user?.roles.includes(EUserRole.agent) && !user?.company,
  },
  {
    code: 'address',
    visible: (user) => user?.roles.includes(EUserRole.agent) && !user?.company,
  },
  {
    code: 'requisites',
    visible: (user) => user?.roles.includes(EUserRole.agent) && !user?.company,
  },
  {
    code: 'docs',
    visible: (user) => user?.roles.includes(EUserRole.agent) && !user?.company,
  },
  {
    code: 'contract',
    visible: (user) => user?.roles.includes(EUserRole.agent) && !user?.company,
  },
] as MenuItem[];

const UserPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = useCallback((button: MenuItem) => {
    return () => {
      if (button.visible(user)) {
        navigate(`${p.PATH_USER}/${button.code}`);
      }
    };
  }, []);

  return (
    <Page
      title={t('helmet:UserPage.title')}
      meta={[{ name: 'description', content: t('helmet:UserPage.description') }]}
      header={{ type: 'withNotification', title: t('interface:page.UserPage.headerTitle') }}
      sx={{ gap: 3 }}
    >
      <Stack>
        {MENU_ITEMS.filter((button) => button.visible(user)).map((button) => (
          <Button
            key={button.code}
            data-testid={`userPage-${button.code}`}
            sx={{
              color: theme.palette.text.primary,
              borderBottom: '1px solid #D2D2CF',
              borderColor: theme.palette.common.greyLight,
              borderRadius: 0,
              justifyContent: 'space-between',
            }}
            endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
            onClick={handleNavigate(button)}
          >
            {t(`interface:page.UserPage.menuItems.${button.code}`)}
          </Button>
        ))}
      </Stack>
    </Page>
  );
};

export default UserPage;
