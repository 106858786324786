import { Box, Typography } from '@mui/material';
import { EProfilePassportPart } from 'api/services/realEstate/realEstate.enum';
import { RealEstateFormSchema } from 'api/services/realEstate/realEstate.schema';
import UploadButton from 'components/common/UploadButton';
import { Control, FieldPath, useController } from 'react-hook-form';

interface UploadWrapperProps<TEnum> {
  onClickInfo: (name: string) => () => void;
  label: string;
  control: Control<RealEstateFormSchema>;
  name: FieldPath<RealEstateFormSchema>;
  type: TEnum;
  disabled?: boolean;
}

interface FileInterface {
  links: Array<{ src: string; type: string }>;
}

export default function UploadWrapper<TEnum>({ label, name, control, type, disabled }: UploadWrapperProps<TEnum>) {
  const { field } = useController({ control, name });

  const handleChange = (link: string | null) => {
    const links = link?.split(';');
    field.onChange({ type, links: links?.map((link) => ({ src: link, part: EProfilePassportPart.firstPage })) });
  };

  const fileValue = (field.value as FileInterface)?.links?.map((link) => link.src).join(';');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '130%', width: '80%' }}>{label}</Typography>
        {/* <IconButton sx={{ color: '#DFC801' }} onClick={onClickInfo(name)}>
          <InfoOutlinedIcon />
        </IconButton> */}
      </Box>

      <UploadButton disabled={disabled} allowDownload allowDelete fileLink={fileValue} onChange={handleChange} />
    </Box>
  );
}
