export enum EFormBindingType {
  jsonpath = 'jsonpath',
  equality = 'equality',
}

export enum EFormBoolean {
  yes = 'yes',
  no = 'no',
}

export enum EFormVisibility {
  public = 'public',
  private = 'private',
}

export interface IForm {
  name: string;
  visibility: EFormVisibility;
  description?: string;
  data: IFormItem[];
}

export enum EFormItemType {
  group = 'group',
  subgroup = 'subgroup',
  cardgroup = 'cardgroup',
  repeatableGroup = 'repeatableGroup',
  input = 'input',
}

export enum EFormInputType {
  group = 'group',
  repeatableGroup = 'repeatableGroup',
  subgroup = 'subgroup',
  cardgroup = 'cardgroup',
  textField = 'input.textField',
  number = 'input.number',
  checkbox = 'input.checkbox',
  selector = 'input.select',
  file = 'input.file',
  multiFile = 'input.multiFile',
  radio = 'input.radio',
  date = 'input.date',
  slider = 'input.slider',
  inlinegroup = 'inlinegroup',
  listgroup = 'listgroup',
}

export interface INewObjectBase {
  name: string;
  value: Record<string, any>;
}

export interface ICustomBind {
  method?: () => void;
  meta?: { [key: string]: string | number | boolean };
  isDisabled?: boolean;
}

export type TFormItemValue = string | number | boolean | null | Record<string, any>[] | Array<any> | IFormItem[];

export interface IFormItem {
  uuid?: string;
  type: EFormInputType;
  name: string;
  settings?: IFormItemSettings;
  data?: IFormItem[];
  value?: TFormItemValue;
  bind?: string;
  setterBind?: ICustomBind;
  newObjectBases?: INewObjectBase[];
}

export interface IPublicForm extends IForm {
  data: IPublicFormItem[];
}

export interface IPublicFormItem {
  uuid?: string;
  type: EFormInputType;
  name: string;
  settings?: IFormItemSettings;
  data?: IPublicFormItem[];
  value?: TFormItemValue;
}

export enum EFormItemSettingsFormat {
  digitString = 'digitString',
  digitNumber = 'digitNumber',
  dateString = 'dateString',
  moneyNumber = 'moneyNumber',
}
export enum EFormItemSettingsValidatorType {
  length = 'length',
  required = 'required',
  isDate = 'isDate',
  isEmail = 'isEmail',
}
export interface IFormItemSettingsValidator {
  type: EFormItemSettingsValidatorType;
  params?: { [key: string]: string | number | boolean };
}
export interface IFormItemSettings {
  mask?: string;
  format?: EFormItemSettingsFormat;
  validations?: IFormItemSettingsValidator[];
  options?: string[];
  display?: 'inline' | 'block' | 'display';
  inlineGroup?: string;
  required?: boolean;
  dependsOn?: IFormItemSettingsDependencies[];
  hiddenTitle?: boolean;
  nextStep?: string;
  defaultValue?: string | boolean | number;
  hasCardTitle?: boolean;
  hasCardSubtitle?: boolean;
  hasCardDescription?: boolean;
}

export interface IFormItemSettingsDependencies {
  name: string;
  requiredValues?: string[];
}

export type TFormInput = Record<string, null | number | string>;

export interface IFormInputOptions {
  uuidEntryUser?: string;
  uuidEntry?: string;
  uuidProfile?: string;
}

export interface IFormQuery {
  mode?: 'fill' | 'fill-empty-only' | 'fill-empty-whole';
  // todo: temp solution, remove
  uuidResource?: string;
}
export enum ESupportedForms {
  profile = 'profile',
  object = 'object',
  agentInsurance = 'agent-insurance',
  insuranceMorgage = 'insurance-mortgage',
  mortgageEntry = 'mortgage-entry',
  common = 'common',
}

export interface IFormFillInput {
  data: TFormInput;
  options?: IFormInputOptions;
}

export interface IAgentFormInputHandleResult {
  uuidProfile?: string;
}

export interface IInsMortgageFormInputHandleResult {
  uuidProfile: string;
  uuidUser: string;
  uuidRealEstate: string;
  uuidInsMortgageOrder: string;
  uuidEntry: string;
}

export interface IClientProfileFormInputHandleResult {
  uuidProfile: string;
  uuidUser: string;
}

export interface IRealEstateFormInputHandleResult {
  uuidRealEstate: string;
  uuidUser: string;
}

export enum EFormFilterMode {
  emptyItems = 'emptyItems',
  emptyItemsWithSubgroup = 'emptyItemsWithSubgroup',
}

export interface IFormAbstractObjWithAddresses {
  addresses: IFormAbstractAddress[];
}

export interface IFormAbstractAddress {
  uuid: string;
  type: string;
}
export interface GetFormRequestQuery {
  mode?: 'fill' | 'fill-empty-only' | 'fill-empty-whole';
  uuidProfile?: string;
  uuidUser?: string;
  uuidEntry?: string;
  uuidEntryUser?: string;
}
export interface IFormFillInput {
  data: TFormInput;
  options?: IFormInputOptions;
}

export type EmptyFields = Array<{ path: string; label: string; index?: number }>;
