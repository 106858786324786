/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const ArrowRightIcon = createSvgIcon(
  <svg width='8' height='17' viewBox={'0 0  9 17'} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.999999 16L8 8.5L1 1' stroke='#B9B9B2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'ArrowRightIcon',
);

export default ArrowRightIcon;
