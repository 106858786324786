import { Toolbar, Box, Button, Badge, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EEntryAgentClientStageStatusFilter } from 'api/services/agent.dto';
import { useAgentClients } from 'components/providers/ClientProvider';

interface Props {
  onAddClient(): void;
}

export default function ClientTableToolbar(_props: Props) {
  const { t } = useTranslation();
  const { statusFilters, selectFilter, activeFilter } = useAgentClients();

  const handleFilter = (filter: EEntryAgentClientStageStatusFilter) => () => {
    selectFilter(filter);
  };
  return (
    <Toolbar disableGutters sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
      {/* <Button variant='contained' startIcon={<Add />} fullWidth onClick={onAddClient}>
        {t('interface:base.ClientTable.addClient')}
      </Button> */}
      <Divider variant='fullWidth' />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
        {Object.entries(statusFilters).map(([key, value]) => (
          <Badge badgeContent={value} color="primary">
            <Button
              fullWidth
              variant={activeFilter.includes(key as EEntryAgentClientStageStatusFilter) ? 'contained' : 'outlined'}
              size="small"
              onClick={handleFilter(key as EEntryAgentClientStageStatusFilter)}
            >
              {t(`common:businessCredit.filters.${key.replace(':', '.')}`)}
            </Button>
          </Badge>
        ))}
      </Box>
    </Toolbar>
  );
}
