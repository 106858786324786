/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const TransKapital = createSvgIcon(
  <svg viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' fill='white' />
    <path
      d='M8 21.01C14.6762 21.01 14.6762 21.01 14.6762 21.01C17.6656 26.0003 21.9841 30.1908 27.1979 33.0699C27.1979 39.5 27.1979 39.5 27.1979 39.5C18.1971 36.5569 11.0558 29.6789 8 21.01Z'
      fill='#00ABAA'
    />
    <path
      d='M27.1979 21.01C27.1979 33.0698 27.1979 33.0699 27.1979 33.0699C21.9841 30.1908 17.6656 26.0003 14.6762 21.01L27.1979 21.01Z'
      fill='#005599'
    />
    <path
      d='M27.1979 21.01C14.6762 21.01 14.6762 21.01 14.6762 21.01C13.1151 18.3864 11.9195 15.5393 11.1554 12.5C18.3964 12.5 18.3964 12.5 18.3964 12.5C23.2789 12.5 27.1979 16.3069 27.1979 21.01Z'
      fill='#00ABAA'
    />
    <path
      d='M36 29.487C36 36.4609 36 36.4609 36 36.4609C32.8781 35.7251 29.9219 34.5735 27.1979 33.0699C27.1979 21.01 27.1979 21.01 27.1979 21.01C32.081 21.01 36 24.7845 36 29.487Z'
      fill='#F59D15'
    />
    <path d='M27.1318 12.5323C28.0947 16.7863 31.5833 20.1138 36 21.0737C36 12.5323 36 12.5323 36 12.5323H27.1318Z' fill='#00ABAA' />
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' stroke='#F5F5F5' />
  </svg>,
  'TransKapital',
);

export default TransKapital;
