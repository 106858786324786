import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import { EUserRole } from 'api/services/user.dto';
import { GroupSelector } from 'components/base/Auth/GroupSelector';
import { RequireAuth } from 'components/base/Auth/RequireAuth';
import FormGeneratorProvider from 'components/base/FormGenerator/context/FormGeneratorProvider';
import MortgageAccessProvider from 'components/providers/AccessProviders/MortgageAccessProvider';
import BusinessCreditProvider from 'components/providers/BusinessCreditProvider';
import ClientProvider from 'components/providers/ClientProvider';
import DocumentProvider from 'components/providers/DocumentProvider';
import MortageEntryProvider from 'components/providers/MortageEntryProvider';
import ProposeModalProvider from 'components/providers/ProposeModalProvider';
import AgentClientDetailsPage from 'pages/Agent/Client/Details';
import AgentClientFormPage from 'pages/Agent/Client/Form';
import AgentClientListPage from 'pages/Agent/Client/List';
// import AgentHomePage from 'pages/Agent/Home';
import AgentInsurance from 'pages/Agent/Insurance';
import AgentInsurancePropertyIncomesPage from 'pages/Agent/Insurance/Property/Incomes';
import AgentInsurancePropertyOrderCreatePage from 'pages/Agent/Insurance/Property/Order/Create';
import AgentInsurancePropertyOrderSelectClientPage from 'pages/Agent/Insurance/Property/Order/SelectClient';
import AgentInsurancePropertyOrderSharePage from 'pages/Agent/Insurance/Property/Order/Share';
import AgentInsurancePropertyPolicyDetailsPage from 'pages/Agent/Insurance/Property/Policy/Details';
import AgentInsurancePropertyPolicyListPage from 'pages/Agent/Insurance/Property/Policy/List';
import AgentInsurancePropertyPolicyOrderPage from 'pages/Agent/Insurance/Property/Policy/Order';
import AgentInsurancePropertyPolicySharePage from 'pages/Agent/Insurance/Property/Policy/Share';
import AgentInsurancePropertyProductListPage from 'pages/Agent/Insurance/Property/Product/List';
import AgentInsurancePropertyStatisticPage from 'pages/Agent/Insurance/Property/Statistic';
import AgentInsurancePropertyTutorialPage from 'pages/Agent/Insurance/Property/Tutorial';
import AgentInsurancePropertyTutorialContentPage from 'pages/Agent/Insurance/Property/Tutorial/Content';
import AgentMortgageHome from 'pages/Agent/Mortgage/Home/AgentMortgageHome';
import AgentMortgagePropertyPage from 'pages/Agent/Mortgage/Property';
import AgentMortgagePropertyClientCreatePage from 'pages/Agent/Mortgage/Property/Client/Create';
import AgentMortgagePropertyClientPage from 'pages/Agent/Mortgage/Property/Client/List';
import AgentMortgagePropertyIncomesPage from 'pages/Agent/Mortgage/Property/Incomes';
import AgentMortgagePropertyInvitePage from 'pages/Agent/Mortgage/Property/Invite';
import GosuslugiPage from 'pages/Auth/Gosuslugi';
import AuthInviteRegisterPage from 'pages/Auth/Invite';
import AuthRegisterPage from 'pages/Auth/Register';
import AuthRolePage from 'pages/Auth/Role';
import RoleInviteAccept from 'pages/Auth/Role/components/InviteAccept';
import AuthSignInByEmailPage from 'pages/Auth/Signin/ByEmail';
import AuthSignInByPhonePage from 'pages/Auth/Signin/ByPhone';
import AuthSignInByPhoneCodePage from 'pages/Auth/Signin/ByPhone/Code';
import BusinessCreditCompanyPage from 'pages/BusinessCredit/Company';
import BusinessCreditCreatePage from 'pages/BusinessCredit/Entry/Create';
import BusinessCreditDocumentPage from 'pages/BusinessCredit/Entry/Documents';
import BusinessCreditProgressPage from 'pages/BusinessCredit/Progress';
import BusinessCreditProposePage from 'pages/BusinessCredit/Propose';
import BusinessCreditSignPage from 'pages/BusinessCredit/Sign';
import BusinessCreditWelcomePage from 'pages/BusinessCredit/Welcome';
import HomeClientPage from 'pages/Client/Home';
import ParticipantInvite from 'pages/Client/Home/components/ParticipantInvite';
import ClientMortgageEntryCreatePage from 'pages/Client/Mortgage/Entry/Create/Form';
import ClientMortgageEntryCreateInvitePage from 'pages/Client/Mortgage/Entry/Create/Invite';
import ClientMortgageEntryRolePage from 'pages/Client/Mortgage/Entry/Create/Role';
import ClientMortgageEntryCreateSuccessPage from 'pages/Client/Mortgage/Entry/Create/Success';
import MortgageEntryDocumentExperimentPage from 'pages/Client/Mortgage/Entry/Experiment/Document';
import ClientMortgageEntryIncomesForm from 'pages/Client/Mortgage/Entry/Incomes';
import MortgageEntryInsurancePage from 'pages/Client/Mortgage/Entry/Insurance';
import MortgageEntryInsuranceBaseDataForm from 'pages/Client/Mortgage/Entry/Insurance/ui/BaseData';
import MortgageEntryInsuranceCompanyForm from 'pages/Client/Mortgage/Entry/Insurance/ui/Company';
import CreditContract from 'pages/Client/Mortgage/Entry/Insurance/ui/CreditContract';
import InsuranceProgramForm from 'pages/Client/Mortgage/Entry/Insurance/ui/InsuranceProgramForm';
import MortgageEntryInsuranceLifeForm from 'pages/Client/Mortgage/Entry/Insurance/ui/Life';
import MortgageEntryInsurancePaymentForm from 'pages/Client/Mortgage/Entry/Insurance/ui/Payment';
import MortgageEntryInsurancePropertyForm from 'pages/Client/Mortgage/Entry/Insurance/ui/Property';
import ProposalWaiting from 'pages/Client/Mortgage/Entry/Insurance/ui/ProposalWaiting';
import MortgageEntryInsuranceTitleForm from 'pages/Client/Mortgage/Entry/Insurance/ui/Title';
import ClientMortgageEntryInvitePage from 'pages/Client/Mortgage/Entry/Invite';
import ClientMortgageProgressPage from 'pages/Client/Mortgage/Entry/Progress';
import ClientMortgageProposePage from 'pages/Client/Mortgage/Entry/Propose';
import { MortgageEntryRealEstatePage } from 'pages/Client/Mortgage/Entry/RealEstate';
import MortgageEntryRealEstateAddObjectForm from 'pages/Client/Mortgage/Entry/RealEstate/forms/AddObject';
import MortgageEntryRealEstateApproveForm from 'pages/Client/Mortgage/Entry/RealEstate/forms/Approve';
import MortgageEntryRealEstateDocumentForm from 'pages/Client/Mortgage/Entry/RealEstate/forms/Document';
import ClientMortgageEntrySignEntry from 'pages/Client/Mortgage/Entry/SignEntry';
import ClientMortgageEntrySignInfo from 'pages/Client/Mortgage/Entry/SignInfo';
import ClientMortgageEntrySignPrivacy from 'pages/Client/Mortgage/Entry/SignPrivacy/SignPrivacy';
import ClientMortgageEntryVerificationPage from 'pages/Client/Mortgage/Entry/Verification';
import ClientMortgageFormPage from 'pages/Client/Mortgage/Form/ClientMortgageFormPage';
import ClientMortgageRemoteContentPage from 'pages/Client/Mortgage/RemoteContent';
import GuiPage from 'pages/Developer/Gui';
import ErrorNotFoundPage from 'pages/Error/NotFound';
import ErrorServerPage from 'pages/Error/Server';
import GetAppPage from 'pages/GetApp';
import HomeGuestPage from 'pages/Guest/Home';
import PersonalManager from 'pages/Landing/PersonalManager';
import NewTutorial from 'pages/NewTutorial';
import BankPropose from 'pages/NewTutorial/components/BankPropose';
import ChatPersonalManager from 'pages/NewTutorial/components/ChatPersonalManager';
import DocumentSign from 'pages/NewTutorial/components/DocumentSign';
import EstimationObject from 'pages/NewTutorial/components/EstimationObject';
import InsuranceCard from 'pages/NewTutorial/components/InsuranceCard';
import OnboardingProgress from 'pages/NewTutorial/components/OnboardingProgress';
import NotificationsPage from 'pages/Notifications';
import ShortLinkPage from 'pages/ShortLink';
import TutorialPage from 'pages/Tutorial';
import UserPage from 'pages/User';
import UserAddressPage from 'pages/User/Address';
import UserContractPage from 'pages/User/Contract';
import UserDocsPage from 'pages/User/Docs';
import UserGeneralPage from 'pages/User/General';
import UserPassportPage from 'pages/User/Passport';
import UserRequisitesForRewardPage from 'pages/User/RequisitesForReward';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import * as p from './paths';
import { InsuranceGroupNames } from './paths';
import BusinessDocumentProvider from 'components/providers/Document/BusinessDocumentProvider';
import BankGuaranteeCompanyPage from 'pages/BankGuarantee/Company';
import BankGuaranteeCreatePage from 'pages/BankGuarantee/Entry/Create';
import BankGuaranteeDocumentPage from 'pages/BankGuarantee/Entry/Documents';
import BankGuaranteeProgressPage from 'pages/BankGuarantee/Progress';
import BankGuaranteeProposePage from 'pages/BankGuarantee/Propose';
import BankGuaranteeSignPage from 'pages/BankGuarantee/Sign';
import BankGuaranteeWelcomePage from 'pages/BankGuarantee/Welcome';
import BankGuaranteeProvider from 'components/providers/BankGuaranteeProvider';

const Router = () => {
  return useRoutes([
    // Home page
    {
      path: '/',
      element: (
        <GroupSelector
          match={[
            {
              role: EUserRole.agent,
              component: (
                <ClientProvider>
                  <AgentMortgageHome />
                </ClientProvider>
              ),
            },
            {
              role: EUserRole.manager,
              component: (
                <ClientProvider>
                  <AgentMortgageHome />
                </ClientProvider>
              ),
            },
            {
              role: EUserRole.client,
              component: <HomeClientPage />,
            },
            { role: EUserRole.ghost, component: <AuthRolePage /> },
            { role: EUserRole.unknown, component: <HomeGuestPage /> },
          ]}
        />
      ),
      index: true,
    },

    // Shortlink page
    { path: p.PATH_SHORTLINK, element: <ShortLinkPage /> },
    { path: p.PATH_SHORTLINK_ACCEPT, element: <RequireAuth component={<RoleInviteAccept />} /> },
    {
      path: p.PATH_LANDING_PERSONAL_MANAGER,
      element: <PersonalManager />,
    },
    // { path: p.PATH_LANDING_PERSONAL_MANAGER_PAID, element: <PersonalManagerPurchasePage /> },

    // Getapp page
    { path: p.PATH_GETAPP, element: <GetAppPage /> },

    // Auth pages
    { path: p.PATH_AUTH_SIGNIN, element: <RequireAuth needAuth={false} component={<AuthSignInByPhonePage />} /> },
    { path: p.PATH_AUTH_SIGNIN_CODE, element: <RequireAuth needAuth={false} component={<AuthSignInByPhoneCodePage />} /> },
    { path: p.PATH_AUTH_REGISTER, element: <RequireAuth needAuth={false} component={<AuthRegisterPage />} /> },
    //{ path: p.PATH_AUTH_ROLE, element: <RequireAuth component={<AuthRolePage />} /> },
    { path: p.PATH_AUTH_GOS, element: <RequireAuth component={<GosuslugiPage />} /> },
    { path: p.PATH_AUTH_INVITE_REGISTER, element: <AuthInviteRegisterPage /> },
    { path: p.PATH_AUTH_SIGNIN_EMAIL, element: <AuthSignInByEmailPage /> },
    // TODO: { path: '/auth/code-change', element: <RequireAuth component={<ChangeCodePage />} /> },

    // Notifications pages
    { path: p.PATH_NOTIFICATIONS, element: <NotificationsPage /> },

    // tutorial
    { path: p.PATH_TUTORIAL, element: <RequireAuth component={<TutorialPage />} /> },
    // User settings pages
    { path: p.PATH_USER, element: <RequireAuth component={<UserPage />} /> },
    { path: p.PATH_USER_GENERAL, element: <RequireAuth component={<UserGeneralPage />} /> },
    { path: p.PATH_USER_PASSPORT, element: <RequireAuth component={<UserPassportPage />} /> },
    { path: p.PATH_USER_ADDRESS, element: <RequireAuth component={<UserAddressPage />} /> },
    { path: p.PATH_USER_REQUISITES, element: <RequireAuth component={<UserRequisitesForRewardPage />} /> },
    { path: p.PATH_USER_DOCS, element: <RequireAuth component={<UserDocsPage />} /> },
    { path: p.PATH_USER_CONTRACT, element: <RequireAuth component={<UserContractPage />} /> },

    // agent property mortgage pages
    { path: p.PATH_AGENT_MORTGAGE_PROPERTY_INVITE, element: <RequireAuth component={<AgentMortgagePropertyInvitePage />} /> },
    { path: p.PATH_AGENT_MORTGAGE_HOME, element: <RequireAuth component={<AgentMortgageHome />} /> },
    { path: p.PATH_AGENT_MORTGAGE_PROPERTY, element: <RequireAuth component={<AgentMortgagePropertyPage />} /> },
    {
      path: p.PATH_AGENT_MORTGAGE_PROPERTY_CLIENTS,
      element: (
        <RequireAuth
          component={
            <ClientProvider>
              <AgentMortgagePropertyClientPage />
            </ClientProvider>
          }
        />
      ),
    },
    { path: p.PATH_AGENT_MORTGAGE_PROPERTY_INCOMES, element: <RequireAuth component={<AgentMortgagePropertyIncomesPage />} /> },
    { path: p.PATH_AGENT_MORTGAGE_PROPERTY_POLICY, element: <RequireAuth component={<AgentMortgagePropertyPage />} /> },
    { path: p.PATH_AGENT_MORTGAGE_PROPERTY_TUTORIAL, element: <RequireAuth component={<AgentMortgagePropertyPage />} /> },
    { path: p.PATH_AGENT_MORTGAGE_PROPERTY_TUTORIAL_CONTENT, element: <RequireAuth component={<AgentMortgagePropertyPage />} /> },

    { path: p.PATH_AGENT_MORTGAGE_CLIENT_CREATE, element: <RequireAuth component={<AgentMortgagePropertyClientCreatePage />} /> },
    // Agent property insurance product pages
    // TODO: Replace HomeAgentPage to InsuranceDashboardPage in future
    { path: p.PATH_AGENT_INSURANCE_LIST, element: <RequireAuth component={<AgentInsurance />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY, element: <RequireAuth component={<AgentInsurance />} /> },
    { path: `${p.PATH_AGENT_INSURANCE_PROPERTY}/:company`, element: <RequireAuth component={<AgentInsurancePropertyProductListPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_CLIENT, element: <RequireAuth component={<AgentClientListPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE, element: <RequireAuth component={<AgentClientFormPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_UPDATE, element: <RequireAuth component={<AgentClientFormPage />} /> },
    { path: `${p.PATH_AGENT_INSURANCE_PROPERTY_CLIENT}/:uuid`, element: <RequireAuth component={<AgentClientDetailsPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_POLICY, element: <RequireAuth component={<AgentInsurancePropertyPolicyListPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_ORDER, element: <RequireAuth component={<AgentInsurancePropertyPolicyOrderPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_ORDER_SHARE, element: <RequireAuth component={<AgentInsurancePropertyOrderSharePage />} /> },

    {
      path: p.PATH_AGENT_INSURANCE_PROPERTY_ORDER_SELECT,
      element: <RequireAuth component={<AgentInsurancePropertyOrderSelectClientPage />} />,
    },
    {
      path: p.PATH_AGENT_INSURANCE_PROPERTY_ORDER_CREATE,
      element: <RequireAuth component={<AgentInsurancePropertyOrderCreatePage />} />,
    },

    {
      path: `${p.PATH_AGENT_INSURANCE_PROPERTY_ORDER}/:uuid`,
      element: <RequireAuth component={<AgentInsurancePropertyPolicySharePage />} />,
    },
    {
      path: `${p.PATH_AGENT_INSURANCE_PROPERTY_POLICY}/:uuid`,
      element: <RequireAuth component={<AgentInsurancePropertyPolicyDetailsPage />} />,
    },
    {
      path: p.PATH_AGENT_INSURANCE_PROPERTY_INCOMES,
      element: <RequireAuth component={<AgentInsurancePropertyIncomesPage enabled={false} />} />,
    },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_STATISTIC, element: <RequireAuth component={<AgentInsurancePropertyStatisticPage />} /> },
    { path: p.PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL, element: <RequireAuth component={<AgentInsurancePropertyTutorialPage />} /> },
    {
      path: p.PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL_CONTENT,
      element: <RequireAuth component={<AgentInsurancePropertyTutorialContentPage />} />,
    },

    // Client pages

    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_EXPERIMENT,
      element: (
        <RequireAuth
          component={
            <DocumentProvider.DocumentProviderMortgage>
              <MortgageEntryDocumentExperimentPage />
            </DocumentProvider.DocumentProviderMortgage>
          }
        />
      ),
    },

    { path: p.PATH_CLIENT_MORTGAGE_PARTICIPANT_INVITE, element: <RequireAuth component={<ParticipantInvite />} /> },
    {
      path: p.PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN,
      element: (
        <RequireAuth
          component={
            <MortageEntryProvider>
              <FormGeneratorProvider>
                <MortgageAccessProvider protectedRoles={[EMortgageEntryRole.borrower, EMortgageEntryRole.agent]}>
                  <ClientMortgageFormPage />
                </MortgageAccessProvider>
              </FormGeneratorProvider>
            </MortageEntryProvider>
          }
        />
      ),
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_FORM_INCOMES,
      element: (
        <RequireAuth
          component={
            <MortageEntryProvider>
              <FormGeneratorProvider>
                <MortgageAccessProvider protectedRoles={[EMortgageEntryRole.borrower, EMortgageEntryRole.agent]}>
                  <ClientMortgageEntryIncomesForm />
                </MortgageAccessProvider>
              </FormGeneratorProvider>
            </MortageEntryProvider>
          }
        />
      ),
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_FORM_VERIFICATION,
      element: <RequireAuth component={<ClientMortgageEntryVerificationPage />} />,
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_CREATE_FORM,
      element: <RequireAuth component={<ClientMortgageEntryCreatePage formType='create' />} />,
    },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_CREATE_INVITE, element: <RequireAuth component={<ClientMortgageEntryCreateInvitePage />} /> },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_CREATE_SUCCESS, element: <RequireAuth component={<ClientMortgageEntryCreateSuccessPage />} /> },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_CREATE, element: <RequireAuth component={<ClientMortgageEntryRolePage />} /> },
    // create mortgage entry for user

    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_UUID_CREATE,
      element: (
        <RequireAuth
          component={
            <MortageEntryProvider>
              <ClientMortgageEntryCreatePage formType='create' />
            </MortageEntryProvider>
          }
        />
      ),
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_EDIT,
      element: (
        <RequireAuth
          component={
            <MortageEntryProvider>
              <ClientMortgageEntryCreatePage formType='edit' />
            </MortageEntryProvider>
          }
        />
      ),
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS,
      element: (
        <RequireAuth
          component={
            <MortageEntryProvider>
              <MortgageAccessProvider protectedRoles={[EMortgageEntryRole.borrower, EMortgageEntryRole.agent]}>
                <ProposeModalProvider>
                  <ClientMortgageProgressPage />
                </ProposeModalProvider>
              </MortgageAccessProvider>
            </MortageEntryProvider>
          }
        />
      ),
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_PROPOSE,
      element: (
        <RequireAuth
          component={
            <MortageEntryProvider>
              <MortgageAccessProvider protectedRoles={[EMortgageEntryRole.borrower, EMortgageEntryRole.agent]}>
                <ProposeModalProvider>
                  <ClientMortgageProposePage />
                </ProposeModalProvider>
              </MortgageAccessProvider>
            </MortageEntryProvider>
          }
        />
      ),
    },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_SIGN, element: <RequireAuth component={<ClientMortgageEntrySignInfo />} /> },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, element: <RequireAuth component={<ClientMortgageEntrySignPrivacy />} /> },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY, element: <RequireAuth component={<ClientMortgageEntrySignEntry />} /> },
    { path: p.PATH_CLIENT_MORTGAGE_ENTRY_INVITE, element: <RequireAuth component={<ClientMortgageEntryInvitePage />} /> },
    { path: p.PATH_CLIENT_MORTGAGE_REMOTE_CONTENT, element: <RequireAuth component={<ClientMortgageRemoteContentPage />} /> },
    {
      path: p.PATH_ONBOARDING,
      element: <RequireAuth component={<NewTutorial />} />,
      children: [
        {
          path: 'manager',
          element: <ChatPersonalManager />,
        },
        {
          path: 'progress',
          element: <OnboardingProgress />,
        },
        {
          path: 'object',
          element: <EstimationObject />,
        },
        {
          path: 'propose',
          element: <BankPropose />,
        },
        {
          path: 'insurance',
          element: <InsuranceCard />,
        },
        {
          path: 'sign',
          element: <DocumentSign />,
        },
      ],
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_FORM_REAL_ESTATE,
      element: <RequireAuth component={<MortgageEntryRealEstatePage />} />,
      children: [
        { path: 'info', element: <MortgageEntryRealEstateAddObjectForm /> },
        // { path: 'valuation', element: <MortgageEntryRealEstateValuationForm /> },
        { path: 'documents', element: <MortgageEntryRealEstateDocumentForm /> },
        { path: 'approvals', element: <MortgageEntryRealEstateApproveForm /> },
      ],
    },
    {
      path: p.PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE,
      element: <RequireAuth component={<MortgageEntryInsurancePage />} />,
      children: [
        { path: InsuranceGroupNames.PROGRAM, element: <InsuranceProgramForm /> },
        { path: InsuranceGroupNames.BASE_DATA, element: <MortgageEntryInsuranceBaseDataForm /> },
        { path: InsuranceGroupNames.CREDIT_CONTRACT, element: <CreditContract /> },
        { path: InsuranceGroupNames.PROPERTY, element: <MortgageEntryInsurancePropertyForm /> },
        { path: InsuranceGroupNames.PERSONAL, element: <MortgageEntryInsuranceLifeForm /> },
        { path: InsuranceGroupNames.TITLE, element: <MortgageEntryInsuranceTitleForm /> },
        { path: InsuranceGroupNames.PROPOSAL_WAITING, element: <ProposalWaiting /> },
        { path: InsuranceGroupNames.COMPANY, element: <MortgageEntryInsuranceCompanyForm /> },
        { path: InsuranceGroupNames.PAYMENT, element: <MortgageEntryInsurancePaymentForm /> },
      ],
    },
    {
      path: p.PATH_BUSINESS_CREDIT_ROOT,
      element: (
        <RequireAuth
          component={
            <BusinessCreditProvider>
              <Outlet />
            </BusinessCreditProvider>
          }
        />
      ),
      children: [
        { path: p.PATH_BUSINESS_CREDIT_WELCOME, element: <BusinessCreditWelcomePage /> },
        { path: p.PATH_BUSINESS_CREDIT_ENTRY_EDIT, element: <BusinessCreditCreatePage /> },
        { path: p.PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, element: <BusinessCreditProgressPage /> },
        { path: p.PATH_BUSINESS_CREDIT_ENTRY_CREATE, element: <BusinessCreditCreatePage /> },
        { path: p.PATH_BUSINESS_CREDIT_ENTRY_COMPANY, element: <BusinessCreditCompanyPage /> },
        {
          path: p.PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS,
          element: (
            <BusinessDocumentProvider>
              <BusinessCreditDocumentPage />
            </BusinessDocumentProvider>
          ),
        },
        {
          path: p.PATH_BUSINESS_CREDIT_PROPOSE,
          element: (
            <ProposeModalProvider>
              <BusinessCreditProposePage />
            </ProposeModalProvider>
          ),
        },
        { path: p.PATH_BUSINESS_CREDIT_ENTRY_SIGN, element: <BusinessCreditSignPage /> },
      ],
    },
    {
      path: p.PATH_BANK_GARANTEE_ROOT,
      element: (
        <RequireAuth
          component={
            <BankGuaranteeProvider>
              <Outlet />
            </BankGuaranteeProvider>
          }
        />
      ),
      children: [
        { path: p.PATH_BANK_GARANTEE_WELCOME, element: <BankGuaranteeWelcomePage /> },
        { path: p.PATH_BANK_GARANTEE_ENTRY_EDIT, element: <BankGuaranteeCreatePage /> },
        { path: p.PATH_BANK_GARANTEE_ENTRY_PROGRESS, element: <BankGuaranteeProgressPage /> },
        { path: p.PATH_BANK_GARANTEE_ENTRY_CREATE, element: <BankGuaranteeCreatePage /> },
        { path: p.PATH_BANK_GARANTEE_ENTRY_COMPANY, element: <BankGuaranteeCompanyPage /> },
        {
          path: p.PATH_BANK_GARANTEE_ENTRY_DOCUMENTS,
          element: (
            <BusinessDocumentProvider>
              <BankGuaranteeDocumentPage />
            </BusinessDocumentProvider>
          ),
        },
        { path: p.PATH_BANK_GARANTEE_PROPOSE, element: <BankGuaranteeProposePage /> },
        { path: p.PATH_BANK_GARANTEE_ENTRY_SIGN, element: <BankGuaranteeSignPage /> },
      ],
    },
    // Develop pages
    { path: p.PATH_GUI, element: <GuiPage /> },

    // Error pages
    { path: '404', element: <ErrorNotFoundPage /> },
    { path: '500', element: <ErrorServerPage /> },
    { path: '*', element: <Navigate to='404' /> },
  ]);
};

export default Router;
