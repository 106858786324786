import { EPurchaseName } from 'api/services/purchases.dto';

export const isDev = (): boolean => import.meta.env.DEV;
export const isProd = (): boolean => import.meta.env.PROD;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isTesting = (): boolean => !!window.Cypress;

export const is = (val: string | undefined): boolean => val === 'true';

export const isIOS = (): boolean => {
  const uagent = navigator.userAgent.toLowerCase();
  return uagent.search('iphone') > -1 || uagent.search('ipod') > -1 || uagent.search('ipad') > -1 || uagent.search('appletv') > -1;
};

export const getPersonalManagerPurchaseName = (): EPurchaseName => {
  // Started by npm
  if (isDev()) return EPurchaseName.personalManagerLocal;
  // Builded, dev
  if (import.meta.env.REACT_APP_VERSION === 'vd.v.l') return EPurchaseName.personalManagerDev;
  // Builded, prod
  if (isProd()) return EPurchaseName.personalManager;
  // Default always prod
  return EPurchaseName.personalManager;
};

export const getApiUrl = () => {
  const hostname = window?.location.hostname;
  const baseUrl = import.meta.env.REACT_APP_HTTP_API_BASEURL;

  if (hostname === import.meta.env.REACT_APP_EWATENDER_HOST_APP) {
    return import.meta.env.REACT_APP_EWATENDER_HOST_API;
  } else {
    return baseUrl;
  }
};

export const isEwaTender = () => {
  const hostname = window?.location.hostname;
  const ewatenderAppHost = import.meta.env.REACT_APP_EWATENDER_HOST_APP;

  return hostname === ewatenderAppHost;
};
