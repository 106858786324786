import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { verfiyContract } from 'api/services/contract';
import { EContractStatus, EContractType } from 'api/services/contract.dto';
import { EmptyFields } from 'api/services/form.dto';
import { entryProgress, getEntryUserEmptyFields } from 'api/services/mortgage';
import { IProfileProgressContract, IProfileProgressMortgage } from 'api/services/mortgage.dto';
import SuccessIcon from 'components/Icons/Success';
import SuccessDoc from 'components/Icons/SuccessDoc';
import BaseButtonBack from 'components/base/Button/Back';
import NoticeScreen from 'components/base/NoticeScreen';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useNavigateParams from 'hooks/useNavigateParams';
import useTypedParams from 'hooks/useTypedParams';
import _, { isArray } from 'lodash';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { Fragment, useLayoutEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import * as p from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

import Partition from './components/Partition';

const EXCLUDE_PROGRESS_PARTS = ['verification', 'signing'];

enum VerificationStates {
  overlay = 'overlay',
  success = 'success',
  wait = 'wait',
  none = 'none',
  approved = 'approved',
}

/**
 * progress:
 * 1. if progress 100% show verification overlay with [none]
 * 2. if entry has sent on verification must show 'wait'
 * 3. if progress not 100% show overlay with [overlay]
 * @returns
 */

function mapStruct(list: EmptyFields): Record<string, ObjectType | ObjectType[]> {
  const container = {};

  list.forEach((it) => {
    _.set(container, it.path, it.label);
  });
  return container;
}

export default function ClientMortgageEntryVerificationPage() {
  const { t } = useTranslation();
  const navigate = useNavigateParams();
  const theme = useTheme();

  const [verifyState, setVerifyState] = useState(VerificationStates.overlay);
  const [currentProgress, setCurrentProgress] = useState<IProfileProgressMortgage>();
  const [contract, setContract] = useState<IProfileProgressContract>();
  const [enabledProgress, setEnabledProgres] = useState<boolean>(false);
  const [emptyFields, setEmptyFields] = useState<EmptyFields>([]);

  const params = useTypedParams<typeof p.PATH_CLIENT_MORTGAGE_FORM_VERIFICATION>();

  /** */

  /**
   *  check valid progress if all parts is 100%
   */
  const calculateProgress = (currentProgress: IProfileProgressMortgage) => {
    if (!currentProgress) {
      return false;
    }

    const parts = Object.keys(currentProgress).filter((x) => !EXCLUDE_PROGRESS_PARTS.includes(x)) as Array<keyof IProfileProgressMortgage>;

    let sum = 0;

    for (const pt of parts) {
      sum += currentProgress[pt];
    }

    return sum / parts.length === 100;
  };

  const verifyContractRequest = async () => {
    if (contract?.status === EContractStatus.draft)
      if (contract?.uuid) {
        const response = await verfiyContract(contract.uuid as string);

        if (response.ok) {
          setVerifyState(VerificationStates.success);
        }
      }
  };
  const loadEmptyFields = async (uuidEntry: string, uuidUser: string) => {
    try {
      const response = await getEntryUserEmptyFields(uuidEntry, uuidUser);

      if (response.ok) {
        const fields = response.body;

        if (isArray(fields)) {
          setEmptyFields(fields);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @param uuidEntry
   * @param uuidUser
   * @returns
   */
  const initStateAsync = async (uuidEntry: string, uuidUser: string) => {
    const { body: progress } = await entryProgress(uuidEntry);

    if (!progress) {
      return;
    }
    const profileProgress = progress?.profile.find((x) => x.ids.uuidUser === uuidUser);
    const _contract = profileProgress?.contracts.find((x) => x.type === EContractType.creditProfileContract);
    const isFullProgress = calculateProgress(profileProgress?.progress as IProfileProgressMortgage);

    // set contract status
    if (_contract) {
      let status;

      switch (_contract.status) {
        case EContractStatus.verification: {
          status = VerificationStates.wait;
          break;
        }
        case EContractStatus.ready: {
          status = VerificationStates.approved;
          break;
        }

        // case EContractStatus.signed:
        //   status = VerificationStates.success;
        //   break;

        case EContractStatus.draft: {
          status = isFullProgress ? VerificationStates.none : VerificationStates.overlay;
          break;
        }

        default:
          status = VerificationStates.none;
          break;
      }

      setVerifyState(status);
    }
    if (profileProgress) setCurrentProgress(profileProgress.progress);
    setContract(_contract);
    setEnabledProgres(isFullProgress);
  };

  /**
   * load progress hook
   */
  useLayoutEffect(() => {
    loadEmptyFields(params.uuidEntry as string, params.uuidUser as string);
    initStateAsync(params.uuidEntry as string, params.uuidUser as string).then();
  }, [params]);

  const handleBackProgress = () => {
    navigate(p.PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: params.uuidEntry } });
  };

  const handleSign = () => {
    navigate(p.PATH_CLIENT_MORTGAGE_ENTRY_SIGN, { params: { uuid: params.uuidEntry } });
  };
  const mappedStruct = useMemo(() => mapStruct(emptyFields), [emptyFields]);

  const progressParts = useMemo(() => {
    const updateProgress = { ...currentProgress };
    return updateProgress as unknown as Record<string, number>;
  }, [currentProgress]);

  const screenOverlay = useMemo(() => {
    if (verifyState === VerificationStates.success) {
      return (
        <Box sx={{ height: 1, overflow: 'hidden', backgroundColor: 'primary.main', padding: '0 20px' }}>
          <NoticeScreen
            buttonProps={{ color: 'primary' }}
            sx={{ background: theme.palette.common.white }}
            successIcon={SuccessIcon}
            successTitle={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeSuccess.title`)}
            successSubtitle={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeSuccess.description`)}
            successBtnText={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeSuccess.btnOk`)}
          />
        </Box>
      );
    }
    if (verifyState === VerificationStates.wait) {
      return (
        <Box sx={{ height: 1, overflow: 'hidden', backgroundColor: 'primary.main', padding: '0 20px' }}>
          <NoticeScreen
            buttonProps={{ color: 'primary' }}
            sx={{ background: theme.palette.common.white }}
            successIcon={SuccessIcon}
            successTitle={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeSuccess.title`)}
            successSubtitle={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeSuccess.description`)}
            successBtnText={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeSuccess.btnOk`)}
          />
        </Box>
      );
    }
    if (verifyState === VerificationStates.approved) {
      return (
        <Box sx={{ position: 'absolute', height: 1, overflow: 'hidden', backgroundColor: 'primary.main', padding: '0 20px' }}>
          <NoticeScreen
            successIcon={SuccessIcon}
            successTitle={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeApproved.title`)}
            successSubtitle={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeApproved.description`)}
            successBtnText={t(`interface:page.ClientMortgageEntryVerificationPage.NoticeApproved.btnOk`)}
            onBtnClick={handleSign}
          >
            <Button fullWidth size='large' sx={{ marginTop: '8px' }} onClick={handleBackProgress}>
              {t(`interface:page.ClientMortgageEntryVerificationPage.NoticeApproved.btnBack`)}
            </Button>
          </NoticeScreen>
        </Box>
      );
    }
    return null;
  }, [verifyState, emptyFields]);

  if (!!screenOverlay) {
    return (
      <Fragment>
        <Helmet
          title={`${t('helmet:title')} - ${t('helmet:ClientMortgageEntryVerificationPage.title')}`}
          meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryVerificationPage.description') }]}
        />
        {screenOverlay}
      </Fragment>
    );
  }

  const handleSelectItem = (path: string) => {
    const splited = path.split('.');
    const url = utils.url.urlReplace(p.PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, {
      uuidEntry: params.uuidEntry,
      uuidUser: params.uuidUser,
      formName: splited[0],
      groupName: splited[1],
    });

    navigate(`${url}?focus=${path}`);
  };
  const hasFeilds = Object.keys(mappedStruct).length > 0;

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryVerificationPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryVerificationPage.description') }]}
      header={{
        xs: <BaseButtonBack title={t(`interface:page.ClientMortgageEntryVerificationPage.title`)} />,
        lg: (
          <TopMenu
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: p.PATH_HOME },
              {
                title: t('interface:base.TopMenu.routes.progress'),
                href: utils.url.urlReplace(p.PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: params.uuidEntry }),
              },
              { title: t('interface:base.TopMenu.routes.verification'), activeLink: true },
            ]}
          />
        ),
      }}
      sx={{ height: 1 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 0.89 }}>
        {!enabledProgress ? (
          <Box>
            <Typography variant='h1b'>{t('interface:page.ClientMortgageEntryVerificationPage.UnReconciledForm.title')}</Typography>

            <Box sx={{ paddingTop: '24px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {progressParts &&
                hasFeilds &&
                Object.keys(progressParts)
                  .filter((x) => !EXCLUDE_PROGRESS_PARTS.includes(x))
                  .map((part) => (
                    <Partition
                      onSelect={handleSelectItem}
                      fields={mappedStruct['profile'][part]}
                      value={`${progressParts?.[part]}/100`}
                      status={progressParts?.[part]}
                      path={['profile', part]}
                      key={part}
                      name={t(`common:mortgage.progress.${part}`)}
                    />
                  ))}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: 1,
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '40px',
            }}
          >
            <SvgIcon sx={{ height: '200px', width: '200px' }} component={SuccessDoc} />

            <Typography sx={{ textAlign: 'center', fontSize: '26px', fontWeight: 700 }}>
              Отлично!
              <br />
              Ваша анкета готова
            </Typography>
          </Box>
        )}

        <Button
          variant='contained'
          onClick={() => {
            verifyContractRequest().then();
          }}
          disabled={!enabledProgress}
          size='large'
          fullWidth
        >
          {t('interface:page.ClientMortgageEntryVerificationPage.btnShape')}
        </Button>
      </Box>
    </ContainerLayout>
  );
}
