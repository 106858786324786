import formatRules from 'utils/format/formatter';
import { ObjectType } from 'utils/iots';

export enum EFormInputType {
  group = 'group',
  repeatableGroup = 'repeatableGroup',
  subgroup = 'subgroup',
  cardgroup = 'cardgroup',
  textField = 'input.textField',
  number = 'input.number',
  checkbox = 'input.checkbox',
  selector = 'input.select',
  file = 'input.file',
  multiFile = 'input.multiFile',
  radio = 'input.radio',
  date = 'input.date',
  slider = 'input.slider',
  inlinegroup = 'inlinegroup',
  listgroup = 'listgroup',
  form = 'form',
  page = 'page',
}
export interface FormScheme {
  name: string;
  visibility: string;
  description: string;
  data: FormSchemeData[];
}

export interface FormSchemeData {
  name: string;
  type: EFormInputType;
  settings?: SchemeSettings;
  data?: FormSchemeData[];
  value?: ObjectType;
}

export enum EFormChildrenComputedType {
  button = 'Button',
  box = 'Box',
  listView = 'ListView',
  typography = 'typography',
  cardButton = 'CardButton',
  summary = 'Summary',
}
export type ComputedFunctionType = 'i18next' | 'sum' | 'valueOf';

export type ComputedEvents = {
  onClick?: ComputedMethod<EventActionNames>;
  onMount?: ComputedMethod<EventActionNames>;
};

export type EventActionNames = 'formSave' | 'navigate';

export type ComputedContent = {
  title?: ComputedMethod<ComputedFunctionType>;
  value?: ComputedMethod<ComputedFunctionType>;
};

export type ComputedMethod<TAction> = {
  method: TAction;
  args?: string[] | string;
  format?: 'price';
};

export interface ComputedElement {
  type: EFormChildrenComputedType;
  props: ComputedEvents & ComputedContent;
}

export interface SchemeSettingsChildren extends ComputedElement {
  position: 'before' | 'after';
  dependsOn?: FormSchemeDependencies[];
}
export interface SchemeSettings {
  nextStep: string;
  mask: string;
  inlineGroup: string;
  options: string[];
  defaultValue: string;
  format: keyof typeof formatRules;
  validations: Array<FormSchemeValdaitons>;
  required?: boolean;
  dependsOn: FormSchemeDependencies[];
  hiddenTitle?: boolean;
  hasCardTitle?: boolean;
  hasCardSubtitle?: boolean;
  hasCardDescription?: boolean;
  useRadio?: boolean;
  children: SchemeSettingsChildren[];
  [x: string]: ObjectType;
}

export interface FormSchemeDependencies {
  name: string;
  requiredValues: string[];
}
export interface FormSchemeValdaitons {
  type: string;
  params: {
    max: number;
    eq?: number;
    min: number;
  };
}
