import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { ApiError } from 'api/errors';
import { HTTP_AUTH_SECRET } from 'api/http';
import { postClientCreate } from 'api/services/agent';
import ErrorMessage from 'components/base/ErrorMessage';
import { useAuth } from 'components/providers/AuthProvider';
import useNavigateParams from 'hooks/useNavigateParams';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { PATH_CLIENT_MORTGAGE_ENTRY_UUID_CREATE } from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';
import { z } from 'zod';

const PhoneMaskCustom = (props: ObjectType) => {
  return (
    <NumberFormat
      allowEmptyFormatting
      customInput={TextField}
      {...props}
      onValueChange={(values: ObjectType) => {
        props?.onChange?.({ target: { value: values?.value as string } } as ChangeEvent<HTMLInputElement>);
      }}
      format='+7 (###) ###-##-##'
      mask='_'
    />
  );
};
export const formSchema = z
  .object({
    phone: z.string({ required_error: 'errors:validation.required' }).refine(
      (v) => {
        const value = utils.format.digitString(v) || '';
        return value?.length > 10;
      },
      { message: 'errors:validation.phone' },
    ),
    firstname: z.string({ required_error: 'errors:validation.required' }),
    patronymic: z.string({ required_error: 'errors:validation.required' }).optional(),
    email: z.string({ required_error: 'errors:validation.required' }).email('errors:validation.email').optional().or(z.literal('')),
    lastname: z.string({ required_error: 'errors:validation.required' }),
  })
  .required({ phone: true, firstname: true, lastname: true });
export type FormSchema = z.infer<typeof formSchema>;

export default function ClientRegisterForm() {
  const [error, setError] = useState<ApiError>();
  const { t } = useTranslation();
  const navigate = useNavigateParams();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const { handleSubmit, control, formState, watch } = useForm<FormSchema>({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (_values: FormSchema) => {
    try {
      const { body } = await postClientCreate({
        ..._values,
        phone: utils.format.digitNumber(_values.phone) as number,
        uuidAgent: auth?.user?.uuid as string,
        secret: HTTP_AUTH_SECRET as string,
      });
      enqueueSnackbar(t('page.AgentMortgagePropertyClientCreatePage.clientRegisteredSuccess'), { variant: 'success' });

      setTimeout(() => {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_UUID_CREATE, { params: { uuidUser: body.uuid } });
      }, 1000);
    } catch (err: unknown) {
      setError(err as ApiError);
    }
  };

  useEffect(() => {
    const watcher = watch(() => {
      setError((prev) => {
        if (!!prev) {
          return undefined;
        }
        return prev;
      });
    });
    return () => {
      watcher.unsubscribe();
    };
  }, [error]);

  const hasErrors = Object.keys(formState.errors).length > 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Form */}
      <Grid container direction='row' spacing={5} sx={{ paddingTop: { xs: 0, lg: '40px' } }}>
        <Grid item xs={12} md={12}>
          <Controller
            name='lastname'
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('interface:page.AuthRegisterPage.Form.profile.lastname')}
                inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.lastname' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name='firstname'
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('interface:page.AuthRegisterPage.Form.profile.firstname')}
                inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.firstname' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name='patronymic'
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label={t('interface:page.AuthRegisterPage.Form.profile.patronymic')}
                inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.patronymic' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name='phone'
            control={control}
            rules={{ required: true, minLength: 11 }}
            render={({ field, fieldState }) => (
              <PhoneMaskCustom
                required
                label={t('interface:page.AuthRegisterPage.Form.profile.phoneNumber')}
                inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.phone' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label={t('interface:page.AuthRegisterPage.Form.profile.Email')}
                inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.email' }}
                variant='standard'
                fullWidth
                error={!!fieldState.error}
                helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ width: 1, padding: '29px 0' }}>
        <Button
          data-testid='submitButton'
          disabled={hasErrors || !!error}
          fullWidth
          variant='contained'
          color='primary'
          type='submit'
          size='large'
        >
          {t('page.AgentMortgagePropertyClientCreatePage.submit')}
        </Button>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
}
