import { HookForm } from 'components/base/FormGenerator/types';
import _, { findIndex } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { genUuid } from 'utils/crypto/uuid';
import { ObjectType } from 'utils/iots';

import { WrappedSchemeData } from '../FormInlineGroup/types';
import { FormSchemeData } from '../form.types';

type WrappedFormValue = { uuid: string; name: string; value: ObjectType; index: number };

interface FormGroupOptions {
  filter?: string;
  path: string[];
}

export default function useFormGroup(node: Required<FormSchemeData>, parentHookForm: HookForm, _options?: FormGroupOptions) {
  const control = useController({
    name: _options?.path?.join('.') as string,
    defaultValue: node.value,
    control: parentHookForm.control,
  });

  useEffect(() => {
    control.field.onChange(JSON.stringify(node.value));
  }, []);

  //filter section
  const [filteredBy, setFilteredBy] = useState<string>();
  const filterByOptions = useCallback(
    (groupItem: { name?: string }) => {
      return filteredBy ? groupItem.name === filteredBy : true;
    },
    [filteredBy],
  );

  // end filter section

  const groupStore: Record<string, Required<FormSchemeData>> = useMemo(
    () => _.zipObject(node.data.map((i) => i.name) as string[], node.data) as Record<string, Required<FormSchemeData>>,
    [],
  );

  // prepare fields before mapped to useState
  const preparedFields = useMemo(() => {
    const fields: Required<WrappedSchemeData>[] = [];
    const values: WrappedFormValue[] = [];
    const hasValue = node.value && node.value.length > 0;

    const children: Array<Required<FormSchemeData> | WrappedFormValue> = hasValue
      ? node.value
      : node.data.filter((item) => filterByOptions(item));

    for (let index = 0; index < children.length; index++) {
      const nodeValue = children[index];
      const uuid = genUuid();

      fields.push({ uuid, ...groupStore[nodeValue.name], index: index + 1 });
      const valueObject = Object.assign({ uuid, name: nodeValue.name, index: index + 1 }, nodeValue);
      values.push(valueObject);
    }
    return { fields, values };
  }, []);

  const [formFields, setFormFields] = useState<Array<Required<WrappedSchemeData>>>(preparedFields.fields);
  const [fieldValues, setFieldValues] = useState<Array<WrappedFormValue>>(preparedFields.values);
  const [isShowRadio, showRadio] = useState<boolean>(false);

  /**
   *  generate mapped fields and their values
   *
   */

  const filterByDepends = (groupItem: FormSchemeData) => {
    const depends = groupItem.settings?.dependsOn;
    if (!depends) {
      return true;
    }
    for (const dep of depends) {
      return dep.requiredValues.includes(_.get(parentHookForm.getValues(), dep.name));
    }
    return true;
  };

  /**
   *  methods for groups array
   */
  const updateHookForm = (values?: typeof fieldValues) => {
    const valuesArgument = _(values)
      .filter((x) => !!x.value)
      .value();
    const valuesFromFields = _(fieldValues)
      .filter((x) => !!x.value)
      .value();

    control.field.onChange(JSON.stringify(valuesArgument || valuesFromFields));
  };

  const update = useCallback((inputValues: ObjectType, ppath: string[], _index: number, uuid: string) => {
    const dataValue = _.get(inputValues, ppath);

    setFieldValues((values) => {
      values[findIndex(values, (x) => x.uuid === uuid)].value = dataValue;
      updateHookForm(values);

      return values;
    });
  }, []);

  const append = useCallback((key: string) => {
    const uuid = genUuid();

    setFormFields((prevFields) => prevFields.concat([{ uuid, index: (fieldValues.length as number) + 1, ...groupStore[key] }]));
    setFieldValues((prevFields) => prevFields.concat([{ uuid, index: prevFields.length + 1, name: key, value: {} }]));
  }, []);

  const remove = useCallback((uuid: string) => {
    setFormFields((values) => values.filter((_x) => _x.uuid !== uuid));
    setFieldValues((values) =>
      _(values)
        .filter((_x) => _x.uuid !== uuid)
        .tap(updateHookForm)
        .value(),
    );
  }, []);
  /**
   * end methods for groups array
   */

  const filteredNodes = useMemo(() => {
    return node.data.filter((item) => filterByOptions(item) && filterByDepends(item));
  }, [filterByDepends]);

  return {
    nodeFields: filteredNodes,
    formFields: formFields,
    filteredBy,
    fieldValues,
    update,
    append,
    remove,
    filter: (name: string) => setFilteredBy(name),
    isShowRadio,
    showRadio,
  };
}
