import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import i18n from 'i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

import { ft } from '../../utils';

const localeMap = {
  en: enLocale,
  ru: ruLocale,
};

// TOOD:Localize it
const RU_PLACEHODLED = 'дд.мм.гггг';

const DatePickerField = ({ node, hookForm, path = [] }) => {
  const { t } = useTranslation();
  const { control, watch } = hookForm;
  const locale = i18n.language;

  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  return (
    <Controller
      name={path.join('.')}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
          <DesktopDatePicker
            label={t(`${ft(path)}.title`)}
            inputFormat='dd.MM.yyyy'
            disableOpenPicker={true}
            mask='__.__.____'
            renderInput={(params) => (
              <TextField
                id={path.join('.')}
                sx={{ width: 1 }}
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': path.join('.'),
                  placeholder: RU_PLACEHODLED,
                }}
              />
            )}
            onChange={(date, inputStr) => {
              // TODO: Rewrite this schit
              if (!date || `${date}` === 'Invalid Date' || inputStr.length !== '__.__.____'.length) return;
              const formatDate = utils.date.format(date, 'YYYY-MM-DD');
              onChange(formatDate);
            }}
            value={value ? dayjs(value) : null}
          />
        </LocalizationProvider>
      )}
    />
  );
};

DatePickerField.propTypes = {
  node: PropTypes.object.isRequired,
  hookForm: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired,
};

export default DatePickerField;
