/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const SearchIcon = createSvgIcon(
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.30312 13.6968L1 18M3.12503 8.43756C3.12503 12.5452 6.45491 15.8751 10.5625 15.8751C14.6701 15.8751 18 12.5452 18 8.43756C18 4.32991 14.6701 1 10.5625 1C6.45491 1 3.12503 4.32991 3.12503 8.43756Z'
      stroke='#787770'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'SearchIcon',
);

export default SearchIcon;
