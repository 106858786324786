import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import { getProfileClientById } from 'api/services/agent';
import AgentClientDetails from 'components/base/AgentClientDetails';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useFetch from 'hooks/useFetch';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import utils from 'utils';
import { nameWithInitials } from 'utils/format/format';
import { getClientTag } from 'utils/maps/tag';
const AgentClientDetailsPage = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { data: profile } = useFetch(() => getProfileClientById(uuid)) ?? {};
  const theme = useTheme();
  const { user } = useAuth() ?? {};

  // const getText = useCallback(entity => t(getDictionaryPath('pages.Agent.Client.Details', entity)), [t]);
  const bountyBlockVisible = useMemo(() => user?.group === 'agent' && !user?.company && profile?.order, [user, profile]);
  const { bounty, price } = profile?.order?.data ?? {};
  const preparedBounty = ((bounty / 100) * price) / 100;

  return (
    <Page
      title={t('helmet:AgentClientDetailsPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentClientDetailsPage.description') }]}
      header={{
        title: nameWithInitials(profile ?? {}, t('common:noName')),
        subtitle: t('interface:page.AgentClientDetailsPage.title'),
        type: 'backButton',
      }}
    >
      <Stack spacing={1} direction='row' sx={{ margin: '24px 0' }}>
        {profile?.tags
          .map((tag) => getClientTag(tag))
          .map((tag) => (
            <Chip
              key={tag.title}
              label={tag.title}
              variant={'outlined'}
              sx={{ color: theme.palette.common.white, backgroundColor: tag.color, border: 'none', fontWeight: 400, fontSize: '14px' }}
            />
          ))}
      </Stack>

      {bountyBlockVisible && (
        <Box
          sx={{
            width: 1,
            mb: 4,
            background: theme.palette.secondary.main,
            borderRadius: '16px',
          }}
        >
          <Stack direction='row' spacing={2} sx={{ padding: '24px' }}>
            <Box>
              <Typography variant='h5' sx={{ mb: '4px' }}>
                {t('interface:page.AgentClientDetailsPage.bounty')}
              </Typography>

              <Typography variant='h4b'>{utils.format.price(preparedBounty / 100)}</Typography>
            </Box>

            <Box sx={{ color: theme.palette.text.secondary }}>
              <Typography variant='h5' sx={{ mb: '4px' }}>
                {t('interface:page.AgentClientDetailsPage.price')}
              </Typography>

              <Typography variant='h4b'>{utils.format.price(price / 100)}</Typography>
            </Box>
          </Stack>
        </Box>
      )}

      <Typography variant='h2b' mb={3}>
        {t('interface:page.AgentClientDetailsPage.details')}
      </Typography>

      <AgentClientDetails profile={profile} />
    </Page>
  );
};

export default AgentClientDetailsPage;
