import { Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { getBankByName } from 'api/services/creditProduct/creditProduct';
import { Bank } from 'api/services/creditProduct/creditProduct.dto';
import { entryGet } from 'api/services/mortgage';
import ErrorMessage from 'components/base/ErrorMessage';
import { InfoField } from 'pages/Client/Mortgage/Entry/Insurance/components/InfoField';
import { Accordion } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/Accordion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const AccordionBeneficiary = () => {
  const [data, setData] = useState<Bank>();
  const [error, setError] = useState<ApiError>();
  const [ok, setOk] = useState(false);

  const { uuidEntry } = useParams();

  const { t } = useTranslation();

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceBaseDataForm';

  const fetchData = async () => {
    try {
      const entry = await entryGet(uuidEntry!);

      const bank = entry.body.primaryPropose?.bank;

      if (bank) {
        const data = await getBankByName(bank);

        setData(data.body);
        setOk(data.ok);
      } else {
        throw Error(`Incorrect bank value: ${bank}`);
      }
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!ok) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  return (
    <Accordion title={t(`${prefix}.beneficiary.title`)} helperText={t(`${prefix}.helperText`)}>
      <InfoField title={data?.fullName} helperText={t(`${prefix}.pledge.name`)} />
      <InfoField title={data?.address} helperText={t(`${prefix}.pledge.address`)} />
      <InfoField title={data?.ogrn} helperText={t(`${prefix}.pledge.orgn`)} />
      <InfoField title={data?.inn} helperText={t(`${prefix}.pledge.inn`)} />
      <InfoField title={data?.kpp} helperText={t(`${prefix}.pledge.kpp`)} />
    </Accordion>
  );
};
