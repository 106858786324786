import { MenuItem, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SelectFieldProps {
  prefix: string;
  label: string;
  answers: string[];
  required?: boolean;
  defaultValue?: string;
}

export const SelectField: FC<SelectFieldProps> = ({ prefix, label, answers, defaultValue, required = false }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const items = answers.map((answer, index) => (
    <MenuItem sx={{ whiteSpace: 'normal' }} key={index} value={answer}>
      {t(`${prefix}.${label}.options.${answer}`)}
    </MenuItem>
  ));

  return (
    <Controller
      rules={{ required }}
      control={control}
      name={label}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField variant='standard' select {...field} label={t(`${prefix}.${label}.title`)}>
          {items}
        </TextField>
      )}
    />
  );
};
