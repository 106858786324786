import { Theme } from '@mui/material';
import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';
import { get, set } from 'lodash';

const blacklist = [
  EMortgageEntryProposeStatus.declined,
  EMortgageEntryProposeStatus.failed,
  EMortgageEntryProposeStatus.pending,
  EMortgageEntryProposeStatus.withoutSuitablePrograms,
  EMortgageEntryProposeStatus.reworkRequired,
];

/**
 *
 * @param banks  excluded banks list
 * @returns {EBank[]} bank list included
 */
export function getBankListImplicit(sourceList: string[], banks?: string[]) {
  if (!banks) {
    return [];
  }
  if (banks.length === 0) {
    return sourceList;
  }
  const allBanks = sourceList;
  const result = [];
  for (const bank of allBanks) {
    if (!banks?.includes(bank)) {
      result.push(bank);
    }
  }
  return result;
}

export function isPendingStatus(status: EMortgageEntryProposeStatus) {
  return blacklist.includes(status);
}

export function mapStatus(status?: string, selected?: boolean) {
  if (selected) {
    return 'interface:base.ProposeCard.proposeStatus.approved';
  }
  return [`interface:base.ProposeCard.proposeStatus.${status}`, 'interface:base.ProposeCard.proposeStatus.pending'];
}

export function mapStatusColor({
  status,
  selectionStatus,
  finalDecision,
}: {
  status?: string;
  selectionStatus?: boolean;
  finalDecision?: boolean;
}): { color: string; backgroundColor: string } {
  switch (status) {
    case 'dataRequested': {
      return { color: '#0C0B02', backgroundColor: '#E7F0FE' };
    }
    case 'preliminary': {
      return { color: '#0C0B02', backgroundColor: '#E7F8EE' };
    }
    case EMortgageEntryProposeStatus.approved: {
      if (finalDecision) {
        return { color: '#31C918', backgroundColor: '#EAFAE8' };
      }
      if (selectionStatus) {
        return {
          color: '#0C0B02',
          backgroundColor: '#F8E11A',
        };
      }
      return {
        color: '#0C0B02',
        backgroundColor: '#F8E11A',
      };
    }
    case EMortgageEntryProposeStatus.declined:
      return {
        backgroundColor: '#FFEAEA',
        color: '#E81E1E',
      };
    case EMortgageEntryProposeStatus.withoutSuitablePrograms:
      return {
        backgroundColor: '#F5F5F5',
        color: '#787770',
      };

    default:
      return { backgroundColor: '#FFF5E3', color: '#F4A611' };
  }
}

export function getProposeButtonStatus({
  theme,
  onChangePropose,
  canApprove,
  selected,
  t,
}: {
  t: (data: string) => string;
  theme: Theme;
  onChangePropose: () => void;
  onCancelPropose: () => void;
  onConfirmPropose: () => void;
  selected?: boolean;
  canApprove?: boolean;
}) {
  const mp = {
    selected: {
      text: t('interface:base.ProposeCard.cancelBank'),
      action: onChangePropose,
      styles: { background: theme.palette.error.light },
    },
    default: {
      text: t('interface:base.ProposeCard.selectBank'),
      action: onChangePropose,
      styles: {},
    },
    approved: {
      text: t('interface:base.ProposeCard.accept'),
      action: onChangePropose,
      styles: { background: theme.palette.success.main, color: theme.palette.common.white },
    },
  } as const;

  if (canApprove) {
    return mp.approved;
  }
  if (selected) {
    return mp.selected;
  }
  return mp.default;
}

export function getPercentColor<TPropose extends object>({
  propose,
  canApprove,
  theme,
}: {
  propose: TPropose;
  canApprove?: boolean;
  theme: Theme;
}) {
  if (!canApprove) {
    return theme.palette.text.primary;
  }
  if ('status' in propose) {
    switch (propose?.status) {
      case 'approved':
        return theme.palette.success.main;
      default:
        return theme.palette.text.primary;
    }
  }
  return theme.palette.text.primary;
}
export function mapProposeToDecision(propose: Record<string, any>) {
  let decision = {};
  if (!propose.decision) {
    decision = {
      period: get(propose, 'decision.period', 0),
      amount: get(propose, 'decision.amount', 1),
      rates: [{ value: 0, commission: 0 }],
      additionalConditions: '',
    };
  } else {
    decision = {
      period: get(propose, 'decision.period', 0),
      amount: get(propose, 'decision.amount', 1) / 100,
      rates: (propose.decision.rates as any[]).map((_, index) => ({
        value: get(propose, `decision.rates.${index}.value`, 1) / 100,
        commission: get(propose, `decision.rates.${index}.commission`, 1) / 100,
      })),
      additionalConditions: get(propose, 'decision.additionalConditions', ''),
    };
  }

  return { status: propose.status, decision: decision };
}

export function mapProposeDecisionForm(values: any) {
  console.log(values);
  const source = { ...values };
  if (values.decision) {
    const rates = get(values, 'decision.rates') as { commission: number; value: number }[];
    const decision: Record<string, any> = {
      period: get(values, 'decision.period', 0),
      amount: get(values, 'decision.amount', 1) * 100,
      rates:
        rates.length > 0
          ? rates.map((_, index) => ({
              value: get(values, `decision.rates.${index}.value`, 1) * 100,
              commission: get(values, `decision.rates.${index}.commission`, 1) * 100,
            }))
          : [{ value: 0, commission: 0 }],
      additionalConditions: get(values, 'decision.additionalConditions', ''),
    };
    set(source, 'decision', decision);
    console.log(source);
    return source;
  }
}
