import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Resolver } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';
import ValidationRule from 'utils/validation';

function flatObject(obj: ObjectType, path = ''): ObjectType[] | { path: string; settings: ObjectType } {
  if (_.isArray(obj)) {
    return obj?.map((i: ObjectType) => flatObject(i, path)).flat();
  }
  path += `.${obj['name']}`;
  if (obj.data) {
    return flatObject(obj.data, path);
  }
  return { path: path.replace('.', ''), settings: obj.settings };
}

export function toSchema(formStructure: ObjectType) {
  if (!formStructure) {
    return null;
  }

  return (flatObject(formStructure) as Array<Record<string, ObjectType>>)
    .filter((x) => !!x.path)
    .reduce((prev, current) => ({ ...prev, [current.path]: new ValidationRule(current.settings) }), {});
}

interface ResolverReturnProps {
  resolver: Resolver;
  format: (values: Record<string, ObjectType>) => Record<string, ObjectType>;
}

interface ValidationShema {
  [x: string]: ValidationRule;
}

export function useResolver(_formName: string, formStructure: ObjectType, path?: string): ResolverReturnProps {
  const { t } = useTranslation();

  const [schema, setSchema] = useState<ValidationShema | null>({});

  useEffect(() => {
    if (formStructure) {
      setSchema(toSchema(formStructure));
    }
  }, [formStructure]);

  const resolver = (values: object, _context: ObjectType, options: { names?: string[]; fields: ObjectType }) => {
    const errors = {};

    options?.names?.forEach((f) => {
      const val = _.get(options.fields, `${f}.value`);
      const validator = schema?.[f.replace(`${path}.`, '')];

      if (validator) {
        const res = validator.validate(val);

        if (res?.message) _.set(errors, f, { type: 'required', message: t(res?.message, res?.options) });
      }
    });

    return { values, errors };
  };

  const format = (values: Record<string, ObjectType>) => {
    const updated = { ...values };

    if (schema) {
      Object.keys(values).forEach((vval) => {
        const val = _.get(values, vval);
        const formatter = schema[vval];
        if (formatter) {
          _.set(updated, vval, formatter.format(val));
        }
      });
    }

    return updated;
  };

  return { resolver, format };
}
