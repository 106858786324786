/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const BackIcon = createSvgIcon(
  <svg viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9 17L1 9L9 1' stroke='#787770' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'BackIcon',
);

export default BackIcon;
