/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

export const Role = createSvgIcon(
  <svg viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127C98.7939 127 127 98.7939 127 64C127 29.2061 98.7939 1 64 1C29.2061 1 1 29.2061 1 64C1 98.7939 29.2061 127 64 127Z'
      fill='#F5F5F5'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M52.8204 76.2461C63.5183 76.2461 72.1907 67.6844 72.1907 57.1231C72.1907 46.5617 63.5183 38 52.8204 38C42.1225 38 33.4501 46.5617 33.4501 57.1231C33.4501 67.6844 42.1225 76.2461 52.8204 76.2461Z'
      fill='#F5F5F5'
    />
    <path
      d='M69.9272 38.6987C71.6396 38.2402 73.4054 38.0053 75.1796 38C80.3169 38 85.2438 40.0147 88.8764 43.601C92.5091 47.1873 94.5499 52.0513 94.5499 57.1231C94.5499 62.1948 92.5091 67.0588 88.8764 70.6451C85.2438 74.2314 80.3169 76.2461 75.1796 76.2461'
      fill='#F5F5F5'
    />
    <path
      d='M26 89.9999C29.0245 85.7508 33.0405 82.2827 37.7086 79.8886C42.3768 77.4945 47.5599 76.2461 52.8204 76.2461C58.0809 76.2461 63.264 77.4945 67.9322 79.8886C72.6003 82.2827 76.6163 85.7508 79.6408 89.9999'
      fill='#F5F5F5'
    />
    <path d='M75.1796 76.2461C80.4405 76.2429 85.6247 77.4912 90.2934 79.8853C94.962 82.2795 98.9775 85.7489 102 90' fill='#F5F5F5' />
    <path
      d='M52.8204 76.2461C63.5183 76.2461 72.1907 67.6844 72.1907 57.1231C72.1907 46.5617 63.5183 38 52.8204 38C42.1225 38 33.4501 46.5617 33.4501 57.1231C33.4501 67.6844 42.1225 76.2461 52.8204 76.2461ZM52.8204 76.2461C47.5599 76.2461 42.3768 77.4945 37.7086 79.8886C33.0405 82.2827 29.0245 85.7508 26 89.9999M52.8204 76.2461C58.0809 76.2461 63.264 77.4945 67.9322 79.8886C72.6003 82.2827 76.6163 85.7508 79.6408 89.9999M69.9272 38.6987C71.6396 38.2402 73.4054 38.0053 75.1796 38C80.3169 38 85.2438 40.0147 88.8764 43.601C92.5091 47.1873 94.5499 52.0513 94.5499 57.1231C94.5499 62.1948 92.5091 67.0588 88.8764 70.6451C85.2438 74.2314 80.3169 76.2461 75.1796 76.2461C80.4405 76.2429 85.6247 77.4912 90.2934 79.8853C94.962 82.2795 98.9775 85.7489 102 90'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>,
  'Role',
);
