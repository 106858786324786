import { RadioWrapper } from 'components/base/RadioWrapper';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface RadioChoiceProps {
  prefix: string;
  label: string;
  answers: string[];
  row?: boolean;
  defaultValue?: string;
}

export const CustomRadioChoice: FC<RadioChoiceProps> = ({ prefix, label, answers, defaultValue, row = false }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const options = answers.map((answer) => ({
    label: t(`${prefix}.${label}.options.${answer}`),
    value: answer,
  }));

  return (
    <RadioWrapper
      defaultValue={defaultValue}
      row={row}
      name={label}
      control={control}
      options={options}
      label={t(`${prefix}.${label}.title`)}
      data-testid={label}
    />
  );
};
