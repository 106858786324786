import { Box, Button, Modal, SvgIcon, Typography } from '@mui/material';
import SuccessIcon from 'components/Icons/Success';
import { t } from 'i18next';
import { useState } from 'react';
import { rehit } from '../Counters';

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm(): void;
}
export default function ModalConfirmation(props: IProps) {
  const [isShowConfirmation, setShowConfirmation] = useState(true);
  const { onConfirm } = props;

  const handleClose = () => {
    setShowConfirmation(true);
    props.onClose();
  };
  return (
    <Modal open={props.open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      {isShowConfirmation ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.HomeClientPage.supportModal.confirm')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
            <Button
              fullWidth
              onClick={() => {
                rehit();
                setShowConfirmation(false);
              }}
              variant='contained'
              size='large'
              data-testid={'btnGetConsultation'}
            >
              {t('interface:page.HomeClientPage.supportModal.apply')}
            </Button>
            <Button fullWidth onClick={handleClose} size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.HomeClientPage.supportModal.cancel')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <SvgIcon sx={{ width: 1, fontSize: 256, marginBottom: '24px' }} component={SuccessIcon} />
          <Typography variant='h2b' component='h2' sx={{ textAlign: 'center' }}>
            {t('interface:page.AgentMortgageHome.supportModal.title')}
          </Typography>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.AgentMortgageHome.supportModal.description')}
          </Typography>
          <Button
            fullWidth
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            variant='contained'
            size='large'
            data-testid={'btnGetConsultation'}
          >
            {t('interface:page.AgentMortgageHome.supportModal.btnOk')}
          </Button>
        </Box>
      )}
    </Modal>
  );
}
