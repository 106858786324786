export enum EBankGuaranteeEntryStatus {
  active = 'active',
  success = 'success',
  failed = 'failed',
}

export enum EBankGuaranteeEntryParamsType {
  executionObligations = 'executionObligations',
  bidGuarantee = 'bidGuarantee',
  advanceRefund = 'advanceRefund',
  warranty = 'warranty',
}

export enum EBankGuaranteeEntryParamsEnactment {
  contractSystemFieldPurchase44 = 'contractSystemFieldPurchase44',
  reformAssistFund185 = 'reformAssistFund185',
  purchase223 = 'purchase223',
  overhaul615PP = 'overhaul615PP',
}
export enum EOcrGender {
  male = 'male',
  female = 'female',
}
export enum EBankGuaranteeEntryLinkedDocHolderType {
  beneficiary = 'beneficiary',
  owner = 'owner',
}
export enum EBankGuaranteeEntryEventType {
  entryCreated = 'bgEntry.created',
}

export enum EBankGuaranteeEntryDocumentType {
  other = 'other',
  ownerPassport = 'ownerPassport',
  // TODO: add more
}
