import { Components } from '@mui/material';
import { Theme } from '@mui/system';
import CheckboxIcon from 'components/Icons/Checkbox';
import CheckboxCheckedIcon from 'components/Icons/CheckboxChecked';

const components: Components<Theme> = {
  MuiCheckbox: {
    defaultProps: {
      icon: <CheckboxIcon sx={{ width: '16px' }} />,
      // TODO: Fix problems with icon size
      checkedIcon: <CheckboxCheckedIcon />,
    },
  },
};

export default components;
