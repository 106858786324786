import { InsuranceGroupNames } from 'routes/paths';

export interface Step {
  nextStep: InsuranceGroupNames;
  nextButton: boolean;
  backButton: boolean;
}

export interface StepMap {
  program: Step;
  baseData: Step;
  creditContract: Step;
  propertyInsurance: Step;
  personalInsurance?: Step;
  titleInsurance?: Step;
  proposalWaiting: Step;
  company: Step;
  payment: Step;
}

export const stepMap: StepMap = {
  program: {
    nextStep: InsuranceGroupNames.BASE_DATA,
    nextButton: true,
    backButton: true,
  },
  baseData: {
    nextStep: InsuranceGroupNames.CREDIT_CONTRACT,
    nextButton: true,
    backButton: false,
  },
  creditContract: {
    nextStep: InsuranceGroupNames.PROPERTY,
    nextButton: true,
    backButton: true,
  },
  propertyInsurance: {
    nextStep: InsuranceGroupNames.PERSONAL,
    nextButton: true,
    backButton: true,
  },
  personalInsurance: {
    nextStep: InsuranceGroupNames.TITLE,
    nextButton: true,
    backButton: true,
  },
  titleInsurance: {
    nextStep: InsuranceGroupNames.PROPOSAL_WAITING,
    nextButton: true,
    backButton: true,
  },
  proposalWaiting: {
    nextStep: InsuranceGroupNames.COMPANY,
    nextButton: true,
    backButton: false,
  },
  company: {
    nextStep: InsuranceGroupNames.PAYMENT,
    nextButton: false,
    backButton: false,
  },
  payment: {
    nextStep: InsuranceGroupNames.PAYMENT,
    nextButton: true,
    backButton: false,
  },
};
