import { Button, Grid, Paper, Skeleton, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_CREATE_FORM, PATH_CLIENT_MORTGAGE_ENTRY_CREATE_INVITE, PATH_HOME } from 'routes/paths';

import RoleSelector from './components/RoleSelector';

const ClientMortgageEntryCreateRolePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedRole, setSelectedRole] = useState<null | EMortgageEntryRole>(null);

  const onSelectRole = (role: string): void => {
    setSelectedRole(role as EMortgageEntryRole);
  };
  const handleRoleChangeBtnClick = (): void => {
    setSelectedRole(null);
  };
  const handleRoleSelectBtnClick = (): void => {
    if (selectedRole === EMortgageEntryRole.borrower) {
      navigate(PATH_CLIENT_MORTGAGE_ENTRY_CREATE_FORM);
      return;
    }

    // Other roles -> got to invite page
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_CREATE_INVITE);
  };

  const isDesktop = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreateRolePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreateRolePage.description') }]}
      maxWidth='xl'
      header={{
        xs: (
          <BaseButtonBack
            title={
              selectedRole ? t(`common:mortgage.roles.${selectedRole}`) : t('interface:page.ClientMortgageEntryCreateRolePage.headerTitle')
            }
            onBackEvent={() => {
              if (selectedRole) {
                setSelectedRole(null);
              } else {
                navigate(-1);
              }
            }}
            subtitle={selectedRole ? t('interface:page.ClientMortgageEntryCreateRolePage.headerTitle') : undefined}
          />
        ),
        lg: (
          <TopMenu
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },
              { title: t('interface:base.TopMenu.routes.selectRole'), activeLink: true },
            ]}
          />
        ),
      }}
      sx={{ height: 1, justifyContent: 'space-evently', gap: '16px', paddingBottom: '20px' }}
    >
      <Grid container direction='row' spacing={{ md: 2, xs: 6 }}>
        {!isDesktop ? (
          !selectedRole && (
            <Grid item xs={12}>
              <Stack gap='8px'>
                <Typography variant='h2b' sx={{ width: 1, fontSize: { md: '32px', xs: '26px' } }}>
                  {t('interface:page.ClientMortgageEntryCreateRolePage.RoleSelector.title')}
                </Typography>
                <Typography variant='h5' sx={{ fontSize: { xs: '16px', md: '18px' } }}>
                  {t('interface:page.ClientMortgageEntryCreateRolePage.RoleSelector.subtitle')}
                </Typography>
              </Stack>
            </Grid>
          )
        ) : (
          <Grid item xs={12}>
            <Stack gap='8px'>
              <Typography variant='h2b' sx={{ width: 1, fontSize: { md: '32px', xs: '26px' } }}>
                {t('interface:page.ClientMortgageEntryCreateRolePage.RoleSelector.title')}
              </Typography>
              <Typography variant='h5' sx={{ fontSize: { xs: '16px', md: '18px' } }}>
                {t('interface:page.ClientMortgageEntryCreateRolePage.RoleSelector.subtitle')}
              </Typography>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {isDesktop ? (
            <Fragment>
              <RoleSelector
                roles={{ borrower: 'borrower', coBorrower: 'coBorrower', guarantor: 'guarantor' }}
                onSelectRole={onSelectRole}
              />

              <Button size='large' disabled={!selectedRole} variant='contained' fullWidth onClick={handleRoleSelectBtnClick}>
                {t(`interface:page.ClientMortgageEntryCreateRolePage.btnSelectRole.${selectedRole}`, { defaultValue: t('common:confirm') })}
              </Button>
            </Fragment>
          ) : (
            !selectedRole && (
              <RoleSelector
                roles={{ borrower: 'borrower', coBorrower: 'coBorrower', guarantor: 'guarantor' }}
                onSelectRole={onSelectRole}
              />
            )
          )}
        </Grid>

        <Grid item xs={12} md={8}>
          {isDesktop && (
            <Paper
              elevation={0}
              sx={{
                backgroundColor: 'secondary.main',
                padding: '16px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Typography component='span' color='text.secondary'>
                {t(`interface:page.ClientMortgageEntryCreateRolePage.roleCardTitle`, { defaultValue: '' })}
              </Typography>
              {!selectedRole && <Skeleton variant='text' sx={{ fontSize: '1rem' }} />}
              {!selectedRole && <Skeleton variant='rectangular' sx={{ fontSize: '10rem' }} />}
              <Typography variant='h4' sx={{ whiteSpace: 'pre-line' }}>
                {t(`interface:page.ClientMortgageEntryCreateRolePage.roleDescription.${selectedRole}.title`, { defaultValue: '' })}
              </Typography>
              <Typography variant='h4' sx={{ whiteSpace: 'pre-line', fontSize: '14px', color: theme.palette.text.secondary }}>
                {t(`interface:page.ClientMortgageEntryCreateRolePage.roleDescription.${selectedRole}.description`, { defaultValue: '' })}
              </Typography>
            </Paper>
          )}

          {selectedRole && !isDesktop && (
            <Stack gap='16px' sx={{ justifyContent: 'space-between', height: 1 }}>
              <Typography variant='h4' sx={{ whiteSpace: 'pre-line' }}>
                {t(`interface:page.ClientMortgageEntryCreateRolePage.roleDescription.${selectedRole}.title`)}
              </Typography>
              <Typography variant='h6' sx={{ whiteSpace: 'pre-line', fontSize: '14px', color: theme.palette.text.secondary }}>
                {t(`interface:page.ClientMortgageEntryCreateRolePage.roleDescription.${selectedRole}.description`)}
              </Typography>
              <Stack gap='8px'>
                <Button fullWidth variant='contained' size='large' onClick={handleRoleSelectBtnClick}>
                  {t(`interface:page.ClientMortgageEntryCreateRolePage.btnSelectRole.${selectedRole}`)}
                </Button>
                <Button fullWidth variant='contained' color='secondary' size='large' onClick={handleRoleChangeBtnClick}>
                  {t(`interface:page.ClientMortgageEntryCreateRolePage.btnChangeRole`)}
                </Button>
              </Stack>
            </Stack>
          )}
        </Grid>
      </Grid>
      {/* Role selector */}

      {/* On role selected */}
    </ContainerLayout>
  );
};

export default ClientMortgageEntryCreateRolePage;
