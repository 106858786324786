import { Button, Box } from '@mui/material';
import BottomPopover from 'components/base/BottomPopover';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import { usePopover } from 'components/providers/PopoverProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_BANK_GARANTEE_ENTRY_CREATE, PATH_BUSINESS_CREDIT_ENTRY_CREATE } from 'routes/paths';
import utils from 'utils';

export default function ProductPopover() {
  const { t } = useTranslation();
  const businessCreditService = useBusinessCredit();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_BUSINESS_CREDIT_ENTRY_CREATE);
  };
  const handleCreateGuarantee = () => {
    navigate(PATH_BANK_GARANTEE_ENTRY_CREATE);
  };

  const popoverService = usePopover();
  return (
    <BottomPopover isOpen={popoverService.open} handleClose={popoverService.closePopover}>
      <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Button
          fullWidth
          variant='contained'
          size='large'
          data-testid={'businessCredit.create'}
          onClick={handleNavigate}
          disabled={businessCreditService.hasEntries}
        >
          {t('interface:page.HomeClientPage.BottomMenu.businessCredit')}
        </Button>
        <Button
          fullWidth
          variant='contained'
          size='large'
          data-testid={'guarantee.create'}
          onClick={handleCreateGuarantee}
          disabled={!utils.env.is(import.meta.env.REACT_APP_GUARANTEE_ENABLED)}
        >
          {t('interface:page.HomeClientPage.BottomMenu.bankGuarantee')}
        </Button>
        <Button
          fullWidth
          variant='contained'
          size='large'
          data-testid={'btnGetConsultation'}
          // onClick={handleEntryCreateClick}
          disabled={true}
        >
          {t('interface:page.HomeClientPage.BottomMenu.mortgage')}
        </Button>
      </Box>
    </BottomPopover>
  );
}
