import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { EMortgageEntryPurpose } from 'api/services/mortgage.dto';
import UnitSlider from 'components/base/UnitSlider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

type Props = {
  percent: number;
  onCalculate: (amount: number, rate?: number) => void;
  hasInput?: boolean;
};

const mockPercentRate = {
  finishedRealEstate: 1020,
  realEstateUnderConstruction: 1020,
  realEstateEquityLoan: 1220,
  refinancing: 1020,
  mortgageWith2Documents: 50,
  stateProgramForFamiliesWithChildren: 480,
  stateProgramForPurchasingNewBuildings: 580,
  stateProgramForITSSpecialists: 410,
  stateProgramFarEasternMortgage: 90,
  stateProgramsRuralMortgage: 200,
  stateProgramMortgageForMilitary: 670,
};

const Calculator = ({ onCalculate, hasInput }: Props) => {
  const { t } = useTranslation();
  const [region, setRegion] = useState<EMortgageEntryPurpose>(EMortgageEntryPurpose.finishedRealEstate);

  const [price, setPrice] = useState<number>(10_000_000);
  const [firstPay, setFirstPay] = useState<number>(2_000_000);
  const [period, setPeriod] = useState<number>(120);

  const onChangeRegion = (e: ObjectType) => {
    setRegion(e.target.value);
  };

  useEffect(() => {
    onCalculate(utils.calc.mortgageMonthlyPayment(price, firstPay, mockPercentRate[region], period), mockPercentRate[region]);
  }, [price, firstPay, period, region, onCalculate]);

  return (
    <Stack sx={{ width: 1, marginTop: { xs: '16px', lg: 0 } }} gap={2}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: { xs: '16px', lg: '24px' }, alignItems: 'center' }}>
        <Box sx={{ width: { xs: '100%', lg: '50%' }, paddingBottom: { xs: '10px', lg: '40px' } }}>
          <FormControl variant='standard' sx={{ width: '100%' }}>
            <InputLabel variant='standard' htmlFor='uncontrolled-native'>
              {t('interface:page.HomeClientPage.Calculator.mortgagePurpose.title')}
            </InputLabel>
            <Select onChange={onChangeRegion} variant='standard' value={region}>
              {Object.values(EMortgageEntryPurpose)
                .filter((x) => x !== EMortgageEntryPurpose.mortgageWith2Documents)
                .map((option) => (
                  <MenuItem key={option} value={option} sx={{ whiteSpace: 'normal' }}>
                    {t(`interface:page.HomeClientPage.Calculator.mortgagePurpose.options.${option}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <UnitSlider
          data-testid='lblPrice'
          hasInput={hasInput}
          inputMask='currency'
          sx={{ width: { xs: '100%', lg: '50%' } }}
          unitLabel='₽'
          label={t('interface:page.HomeClientPage.Calculator.lblPrice')}
          threshold={1}
          min={1_000_000}
          max={100_000_000}
          marks={[
            {
              value: 1_000_000,
              label: <Box>от 1 000 000 ₽</Box>,
            },
            {
              value: 80_000_000,
              label: '100 000 000 ₽',
            },
          ]}
          step={100_000}
          period={price}
          format={(n) => utils.format.price(n)}
          handleChangePeriod={(p: number) => {
            setPrice(p);
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: { xs: 0, lg: '24px' },
          alignItems: 'center',
          paddingTop: { sx: '16px' },
        }}
      >
        <UnitSlider
          data-testid='lblFirstPay'
          hasInput={hasInput}
          inputMask='currency'
          unitLabel='₽'
          label={t('interface:page.HomeClientPage.Calculator.lblFirstPay')}
          threshold={1}
          min={1_000_000}
          max={100_000_000}
          step={100_000}
          marks={[
            {
              value: 1_000_000,
              label: <Box>от 1 000 000 ₽</Box>,
            },
            {
              value: 100_000_000,
              label: <Box>100 000 000 ₽</Box>,
            },
          ]}
          period={firstPay}
          format={(n) => utils.format.price(n)}
          handleChangePeriod={(p: number) => {
            setFirstPay(p);
          }}
        />
        <UnitSlider
          data-testid='lblPeriod'
          hasInput={hasInput}
          unitLabel={(val: number) => t('interface:page.HomeClientPage.Calculator.years', { count: val })}
          label={t('interface:page.HomeClientPage.Calculator.lblPeriod')}
          threshold={12}
          min={12 * 1}
          max={12 * 35}
          step={1}
          marks={[
            {
              value: 1,
              label: <Box>1 Год</Box>,
            },
            {
              value: 35,
              label: <Box>35 лет</Box>,
            },
          ]}
          period={period}
          handleChangePeriod={(p: number) => {
            setPeriod(p);
          }}
        />
      </Box>
    </Stack>
  );
};

export default Calculator;
