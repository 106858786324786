import { Box, IconButton, Link, Paper, SvgIcon, Theme, Typography } from '@mui/material';
import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';
import ProposeStatusPanel from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard/components/ProposeStatusPanel';
import { useTranslation } from 'react-i18next';
import { Fn, ObjectType } from 'utils/iots';
import EditIcon from '@mui/icons-material/Edit';
import { usePropose } from '../../../ProposeProvider';
import { useRoleAccess } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';
interface ProposeCardPending {
  bank?: string;
  icon: ObjectType;
  onClickPopover?: Fn<void>;
  selected?: boolean;
  status?: EMortgageEntryProposeStatus;
  theme: Theme;
  uuidPropose: string;
}

export default function ProposeCardPending(props: ProposeCardPending) {
  const proposeHook = usePropose();
  const { accessible: hasAccess } = useRoleAccess([EUserRole.agent, EUserRole.manager]);
  const { theme, bank, selected, onClickPopover, status, icon } = props;
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        padding: '24px',
        borderRadius: '12px',
        height: '100%',
      }}
      variant='outlined'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '12px' },
          alignItems: { xs: 'flex-start', md: 'center' },
          justifyContent: 'space-between',
          width: 1,
        }}
      >
        <Box
          sx={{
            order: { xs: 2, md: 1 },
            width: { md: 0.35, xs: 1 },
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            alignItems: { md: 'center', xs: 'center' },
          }}
        >
          <SvgIcon component={icon} sx={{ width: 48, height: 48 }} />
          <Typography variant='h4'>{t(`common:mortgage.banks.${bank}`)}</Typography>
        </Box>

        {status && (
          <ProposeStatusPanel
            sx={{
              width: { md: '224px', xs: 'fit-content' },
              justifyContent: { xs: 'flex-start', md: 'center' },
              order: { xs: 1, md: 2 },
            }}
            isSelected={selected}
            theme={theme}
            proposeStatus={status}
          />
        )}

        <Box sx={{ order: 3, display: 'flex', justifyContent: { xs: 'space-around', md: 'flex-end' }, width: { xs: 1, md: 0.3 } }}>
          {hasAccess && proposeHook ? (
            <IconButton
              aria-label='edit-manager'
              data-testid={`${bank}.edit-button`}
              onClick={() => {
                proposeHook.editPropose(props.uuidPropose);
              }}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <Link variant='h5' onClick={onClickPopover} sx={{ color: theme.palette.common.link, textDecoration: 'none' }}>
              {t('interface:base.ProposeCard.discussConsultant')}
            </Link>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
