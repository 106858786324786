import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  SvgIcon,
  useTheme,
  IconButton,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CoborrowerIcon from 'components/Icons/Coborrower';
import ClearIcon from '@mui/icons-material/Clear';
import { Fragment, useState } from 'react';
import PhoneMaskCustom from 'components/base/PhoneMaskCustom';
import { ApiError } from 'api/errors';
import { IExperimentUserInvite } from 'api/services/experiment/experiment.dto';
import { postInviteUserByUser } from 'api/services/experiment/experiment.service';
import { useParams } from 'react-router-dom';
import utils from 'utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';

export const inviteFormSchema = z
  .object({
    phone: z.string({ required_error: 'errors:validation.required' }).refine(
      (v) => {
        const value = utils.format.digitString(v) || '';
        return value?.length === 10;
      },
      { message: 'errors:validation.phone' },
    ),
    firstname: z.string({ required_error: 'errors:validation.required' }),
    patronymic: z.string({ required_error: 'errors:validation.required' }).optional(),
    email: z.string({ required_error: 'errors:validation.required' }).email('errors:validation.email').optional().or(z.literal('')),
    lastname: z.string({ required_error: 'errors:validation.required' }),
  })
  .required({
    phone: true,
    firstname: true,
    lastname: true,
    email: true,
  });
type InviteFormSchema = z.infer<typeof inviteFormSchema>;

function CoborrowerExit({ onBackClick, onClose }: { onBackClick: () => void; onClose: () => void }) {
  const theme = useTheme();
  return (
    <Fragment>
      <DialogTitle
        id='alert-dialog-title'
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: '20px' }}
      >
        <Typography variant="h3b">Вы уверены?</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <DialogContentText variant='body1' sx={{ color: theme.palette.text.primary, fontSize: '16px' }}>
          Если вы не хотите добавлять супруга/супругу в заявку, вам придется оформить брачный договор
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' fullWidth onClick={onBackClick}>
          Назад
        </Button>
        <Button variant='contained' color="secondary" fullWidth onClick={onClose} sx={{ color: theme.palette.error.main }}>
          Закрыть
        </Button>
      </DialogActions>
    </Fragment>
  );
}

function CoborrowerSuccess({ onAddClick }: { onAddClick: () => void }) {
  const theme = useTheme();
  return (
    <Fragment>
      <DialogTitle
        id='alert-dialog-title'
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: '20px' }}
      >
        <SvgIcon sx={{ width: 0.7, height: 0.7 }}>
          <CoborrowerIcon />
        </SvgIcon>
        <Typography variant="h3b">Отлично! Созаемщик добавлен в заявку</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <DialogContentText variant='body1' sx={{ color: theme.palette.text.primary, fontSize: '16px' }}>
          Вы можете заполнить его анкету после своей или пригласить его в личный кабинет
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' fullWidth onClick={onAddClick}>
          Далее
        </Button>
      </DialogActions>
    </Fragment>
  );
}

function CoborrowerForm({ onAddClick }: { onAddClick: () => void }) {
  const { t } = useTranslation();
  const [, setApiError] = useState<ApiError>();
  const { uuid } = useParams();

  const onSubmit = async (values: InviteFormSchema) => {
    try {
      const data: IExperimentUserInvite = {
        entryRole: EMortgageEntryRole.coBorrower,
        lastname: values.lastname,
        firstname: values.firstname,
        patronymic: values.patronymic!,
        email: values.email!,
        phone: utils.format.digitNumber(`7${values.phone!}`) as number,
      };
      await postInviteUserByUser({ uuidEntry: uuid! }, data);
      onAddClick();
    } catch (err) {
      setApiError(err);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InviteFormSchema>({
    defaultValues: {},
    resolver: zodResolver(inviteFormSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const theme = useTheme();
  const hasErrors = Object.values(errors).length > 0;

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: '20px',
          }}
        >
          <SvgIcon sx={{ width: 0.7, height: 0.7 }}>
            <CoborrowerIcon />
          </SvgIcon>
          <Typography variant="h3b">Добавьте созаемщика в заявку</Typography>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <DialogContentText variant='body1' sx={{ color: theme.palette.text.primary, fontSize: '16px' }}>
            Заёмщикам, находящимся в браке без брачного договора необходимо указать супруга/супругу в качестве созаемщика. Вы можете
            заполнить его анкету после своей, или же пригласить созаемщика в ЛК для самостоятельного заполнения
          </DialogContentText>
          {/* Form */}
          <Grid container direction='row' spacing={5} sx={{ paddingTop: { xs: 0, lg: '40px' } }}>
            <Grid item xs={12} md={12}>
              <Controller
                name='lastname'
                control={control}
                rules={{ required: true, maxLength: 20 }}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('interface:page.AuthRegisterPage.Form.profile.lastname')}
                    inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.lastname' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name='firstname'
                control={control}
                rules={{ required: true, maxLength: 20 }}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('interface:page.AuthRegisterPage.Form.profile.firstname')}
                    inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.firstname' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name='patronymic'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('interface:page.AuthRegisterPage.Form.profile.patronymic')}
                    inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name='phone'
                control={control}
                rules={{ required: true, minLength: 11 }}
                render={({ field, fieldState }) => (
                  <PhoneMaskCustom
                    required
                    label={t('interface:page.AuthRegisterPage.Form.profile.phoneNumber')}
                    inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.phone' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                    {...field}
                    inputRef={field.ref}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name='email'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('interface:page.AuthRegisterPage.Form.profile.Email')}
                    inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.email' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant='contained' fullWidth disabled={hasErrors}>
            Добавить созаемщика
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
}

enum CoborrowerDialogSteps {
  welcome,
  form,
  success,
  exit,
}

export function CoborrowerWelcome({ onAddClick }: { onAddClick: () => void }) {
  const theme = useTheme();
  return (
    <Fragment>
      <DialogTitle
        id='alert-dialog-title'
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: '20px' }}
      >
        <SvgIcon sx={{ width: 0.7, height: 0.7 }}>
          <CoborrowerIcon />
        </SvgIcon>
        <Typography variant="h3b">Добавьте созаемщика в заявку</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <DialogContentText variant='body1' sx={{ color: theme.palette.text.primary, fontSize: '16px' }}>
          Заёмщикам, находящимся в браке без брачного договора необходимо указать супруга/супругу в качестве созаемщика. Вы можете заполнить
          его анкету после своей, или же пригласить созаемщика в ЛК для самостоятельного заполнения
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' fullWidth onClick={onAddClick}>
          Добавить созаемщика
        </Button>
      </DialogActions>
    </Fragment>
  );
}

export default function CoborrowerDialog({
  open,
  handleClose,
  handleSuccessSubmit,
}: { open: boolean; handleClose: () => void; handleSuccessSubmit: () => void }) {
  const [step, setStep] = useState<CoborrowerDialogSteps>(() => CoborrowerDialogSteps.welcome);

  const handleCloseDialog = () => {
    if (step === CoborrowerDialogSteps.exit || step === CoborrowerDialogSteps.success) {
      handleClose();
    } else {
      setStep(CoborrowerDialogSteps.exit);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '24px',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: 0 }}>
        <IconButton size="small" onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
      </Box>
      {step === CoborrowerDialogSteps.welcome && (
        <CoborrowerWelcome
          onAddClick={() => {
            setStep(CoborrowerDialogSteps.form);
          }}
        />
      )}
      {step === CoborrowerDialogSteps.form && (
        <CoborrowerForm
          onAddClick={() => {
            setStep(CoborrowerDialogSteps.success);
          }}
        />
      )}
      {step === CoborrowerDialogSteps.success && (
        <CoborrowerSuccess
          onAddClick={() => {
            handleSuccessSubmit();
          }}
        />
      )}
      {step === CoborrowerDialogSteps.exit && (
        <CoborrowerExit
          onClose={handleClose}
          onBackClick={() => {
            setStep(CoborrowerDialogSteps.form);
          }}
        />
      )}
    </Dialog>
  );
}
