import { Button, Paper, SvgIcon, SxProps, Typography, useTheme } from '@mui/material';
import { ApiError } from 'api/errors';
import sendSmsGreeting from 'api/services/agent';
import { EGreetingSmsRecipientTypeDTO } from 'api/services/agent.dto';
import { EMortgageEntryRole, IMortgageEntryProgress } from 'api/services/mortgage.dto';
import { useUserGetInfo } from 'api/services/user';
import { useTimer } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Done } from '@mui/icons-material';
import { useAuth } from 'components/providers/AuthProvider';
import { EUserRole } from 'api/services/user.dto';

interface SmsGreetProps {
  progress: IMortgageEntryProgress;
}

export default function SmsGreet({ progress }: SmsGreetProps) {
  const auth = useAuth();
  const theme = useTheme();
  const [, setError] = useState<ApiError>();
  const { t } = useTranslation();
  const timer = useTimer(30, 'desc');
  const successSx: SxProps = {
    background: theme.palette.success.light,
    borderRadius: '8px',
    boxShadow: 'none',
    '&:before': { height: 0 },
    pointerEvents: 'none',
  };

  const profile = useMemo(() => {
    return progress.profile.find((x) => x.role === EMortgageEntryRole.borrower);
  }, [progress]);

  const { data: userData, refetch } = useUserGetInfo(profile?.ids.uuidUser);

  const sendSmsRequest = async () => {
    refetch();
    timer.handleReset();
    setError(undefined);
    try {
      await sendSmsGreeting({ type: EGreetingSmsRecipientTypeDTO.phone, recipient: String(userData?.phone) });
    } catch (err) {
      setError(err);
    } finally {
      timer.handleStart();
    }
  };

  const notEnoughAuth = userData?.verifications?.phone === false;

  if (!auth.hasRole(EUserRole.agent) && userData?.ids?.uuidAgent !== auth.user?.uuid) {
    return null;
  }

  useEffect(() => {
    if (timer.timer === 0) {
      timer.handleReset();
    }
  }, [timer.timer]);

  return !notEnoughAuth ? (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '8px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        ...successSx,
      }}
    >
      <SvgIcon sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }} color={'success'} component={Done} />
      <Typography variant="h5">{t('interface:base.SmsGreet.success')}</Typography>
    </Paper>
  ) : (
    <Button variant="contained" color="secondary" onClick={sendSmsRequest} disabled={timer.isActive}>
      {!timer.isActive ? t('interface:base.SmsGreet.sendSms') : t('interface:base.SmsGreet.sendSmsTime', { count: timer.timer })}
    </Button>
  );
}
