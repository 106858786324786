/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';
//@ts-ignore
const SuccessDoc = createSvgIcon(
  <svg viewBox='0 0 160 160' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z'
      fill='#F8E11A'
    />
    <g filter='url(#filter0_d_9069_104347)'>
      <path
        d='M90.7304 40.6328V60.9661C90.7304 62.623 92.0736 63.9661 93.7304 63.9661H114.286'
        fill='url(#paint0_radial_9069_104347)'
        shapeRendering='crispEdges'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.3495 120.633H110.921C111.813 120.633 112.669 120.282 113.3 119.657C113.931 119.031 114.286 118.184 114.286 117.299V65.8291H92.8891C90.68 65.8291 88.8891 64.0383 88.8891 61.8291V40.6328H50.3495C49.457 40.6328 48.6011 40.984 47.97 41.6091C47.3389 42.2342 46.9844 43.0821 46.9844 43.9661V117.299C46.9844 118.184 47.3389 119.031 47.97 119.657C48.6011 120.282 49.457 120.633 50.3495 120.633ZM114.286 63.9661L90.7304 40.6328V60.9661C90.7304 62.623 92.0736 63.9661 93.7304 63.9661H114.286Z'
        fill='url(#paint1_radial_9069_104347)'
        shapeRendering='crispEdges'
      />
    </g>
    <path
      d='M139.683 159.994C150.904 159.994 160 150.898 160 139.677C160 128.456 150.904 119.359 139.683 119.359C128.462 119.359 119.365 128.456 119.365 139.677C119.365 150.898 128.462 159.994 139.683 159.994Z'
      fill='url(#paint2_radial_9069_104347)'
    />
    <g filter='url(#filter1_d_9069_104347)'>
      <path
        d='M149.841 132.055L136.113 147.293L128.254 138.737'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_9069_104347'
        x='38.9844'
        y='29.6328'
        width='99.3018'
        height='112'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx='8' dy='5' />
        <feGaussianBlur stdDeviation='8' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9069_104347' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9069_104347' result='shape' />
      </filter>
      <filter
        id='filter1_d_9069_104347'
        x='123.254'
        y='127.055'
        width='33.5874'
        height='27.2383'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx='1' dy='1' />
        <feGaussianBlur stdDeviation='2.5' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9069_104347' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9069_104347' result='shape' />
      </filter>
      <radialGradient
        id='paint0_radial_9069_104347'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(55.8733 55.236) rotate(62.6263) scale(126.42 114.273)'
      >
        <stop offset='0.416533' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_9069_104347'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(55.8733 55.236) rotate(62.6263) scale(126.42 114.273)'
      >
        <stop offset='0.416533' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint2_radial_9069_104347'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(132.295 132.658) rotate(53.924) scale(31.9938)'
      >
        <stop stopColor='#44E529' />
        <stop offset='1' stopColor='#26B70E' />
      </radialGradient>
    </defs>
  </svg>,
  'SuccessDoc',
);

export default SuccessDoc;
