import { ApiError } from 'api/errors';
import { supportSend } from 'api/services/support';
import { ESupportReqType } from 'api/services/support.dto';
import { rehit } from 'components/base/Counters';
import ModalConfirmation from 'components/base/ModalConfirmation';
import { createContext, ReactNode, useContext, useState } from 'react';

interface SupportContextProps {
  requestConsultation: () => Promise<void>;
  openModal: () => void;
}

const SupportContext = createContext<Partial<SupportContextProps>>({});

interface Props {
  children: ReactNode;
}

export function SupportProvider({ children }: Props) {
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [, setError] = useState<ApiError>();
  const openModal = () => {
    rehit();
    setShowSupportModal(true);
  };
  const requestConsultation = async () => {
    try {
      await supportSend({ reqType: ESupportReqType.call });
    } catch (err: unknown) {
      setError(err as ApiError);
    }
  };
  const handleClose = () => {
    rehit();
    setShowSupportModal(false);
  };
  return (
    <SupportContext.Provider value={{ requestConsultation, openModal }}>
      <>
        {children}
        <ModalConfirmation onConfirm={requestConsultation} open={showSupportModal} onClose={handleClose} />
      </>
    </SupportContext.Provider>
  );
}

/**
 *
 * @returns
 */
export default function useSupportModal() {
  const ctx = useContext(SupportContext) as Required<SupportContextProps>;
  if (!ctx) {
    throw new Error('SupportContext not initialized');
  }
  return ctx;
}
