import { FormControl, MenuItem, TextField } from '@mui/material';
import { HookForm } from 'components/base/FormGenerator/types';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSchemeData } from '../../form.types';
import { ft } from '../../utils';

const FormSelectField = ({ node, hookForm, path = [] }: { node: FormSchemeData; path?: string[]; hookForm: HookForm }) => {
  const { t } = useTranslation();
  const { control, watch } = hookForm;

  const p = path.join('.');

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  return (
    <Controller
      name={p}
      control={control}
      defaultValue={node.settings?.defaultValue || ''}
      render={({ field, fieldState }) => (
        <FormControl>
          <TextField
            variant='standard'
            {...field}
            value={field.value || ''}
            label={t(`${ft(path)}.title`)}
            select
            data-testid={p}
            id={p}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            inputProps={{ 'data-testid': p }}
          >
            {node?.settings?.options.map((option) => (
              <MenuItem key={`${p}-${option}`} value={option} sx={{ whiteSpace: 'normal' }}>
                {t(`${ft(path)}.options.${option}`)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}
    />
  );
};

export default FormSelectField;
