import { Box, Skeleton } from '@mui/material';
import { DocumentProgress, EProfileDocumentType } from 'api/services/experiment/experiment.dto';
import CheckItemDocument from 'components/base/SmartForm/CheckListDocuments/components/CheckItemDocument';
import { useMemo } from 'react';
import { useBusinessDocuments } from 'components/providers/Document/BusinessDocumentProvider';

export default function CheckListDocuments() {
  const { documentProgress } = useBusinessDocuments();

  const passport = useMemo(() => {
    const passportFiles = documentProgress?.filter((x) => x.type === EProfileDocumentType.passport);
    return { type: EProfileDocumentType.passport, parts: passportFiles } as DocumentProgress;
  }, [documentProgress]);

  if (!documentProgress?.length) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <Skeleton variant='rounded' width='100%' height={40} />
        <Skeleton variant='rounded' width='100%' height={40} />
        <Skeleton variant='rounded' width='100%' height={40} />
        <Skeleton variant='rounded' width='100%' height={40} />
        <Skeleton variant='rounded' width='100%' height={60} sx={{ marginTop: '20px' }} />
        <Skeleton variant='rounded' width='100%' height={60} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      {(passport?.parts?.length || 0) > 0 && <CheckItemDocument document={passport} />}
      {documentProgress
        ?.filter((x) => x.type !== EProfileDocumentType.passport)
        .map((document) => (
          <CheckItemDocument
            document={document}
            key={`${document.type}_${document.part}_${document.quarter || ''}_${document.year || ''}`}
          />
        ))}
    </Box>
  );
}
