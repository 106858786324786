import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { suggestBank } from 'api/services/suggestions';
import { userGetContractByType, userSetContractDetails } from 'api/services/user';
import { MASK, maskComponent } from 'components/base/FormGenerator/Input/inputMask';
import Page from 'components/layouts/Page';
import useFetch from 'hooks/useFetch';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';

const CONTRACT_TYPE = 'agentContract';
const DEFAULT_VALUES = {
  bankAccountBik: '',
  bankAccount: '',
  bankName: '',
  bankINN: '',
  bankKPP: '',
  correspondentBankAccount: '',
};

const UserRequisitesForRewardPage = () => {
  const { t } = useTranslation();
  const { isSuccess, data } = useFetch(() => userGetContractByType(CONTRACT_TYPE));
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankInfo, setBankInfo] = useState(false);
  const { handleSubmit, reset, control, getValues } = useForm({ defaultValues: DEFAULT_VALUES });

  const requestBankByBic = async (bic) => {
    // Sber: 044525225 | Alfa: 044525593
    // TODO: Error processing
    setLoading(true);
    try {
      const {
        body: { suggestions: [bank] = [] } = {},
      } = await suggestBank(bic);
      if (!bank) {
        // TODO: Bank not found'
        return;
      } else {
        setBankInfo(bank);
      }
    } catch (err) {
      // TODO: Error processing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) reset(data?.details?.requisites || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // Update bank info in form
  useEffect(() => {
    if (bankInfo) {
      reset({
        ...getValues(),
        bankName: bankInfo?.value,
        bankINN: bankInfo?.data?.inn,
        bankKPP: bankInfo?.data?.kpp,
        correspondentBankAccount: bankInfo?.data?.correspondent_account,
      });
    }
  }, [bankInfo, getValues, reset]);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSubmitClick = () => {
    handleSubmit((data) => {
      // TODO: Error processing
      userSetContractDetails(CONTRACT_TYPE, { requisites: data });
      setIsEditMode(false);
    })();
  };

  const handleBikInputBlur = () => {
    const bic = getValues('bankAccountBik');
    if (bankInfo?.data?.bic !== bic) requestBankByBic(bic);
  };

  return (
    <Page
      title={t('helmet:UserRequisitesForRewardPage.title')}
      meta={[{ name: 'description', content: t('helmet:UserRequisitesForRewardPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.UserRequisitesForRewardPage.headerTitle'), backRoute: PATH_USER }}
    >
      <Box sx={{ display: 'flex', height: 1, flexDirection: 'column', justifyContent: 'space-between', gap: 4 }}>
        {loading}
        <Typography component='div' variant='h4m'>
          {t('interface:page.UserRequisitesForRewardPage.notice')}
        </Typography>
        <Stack spacing={2}>
          <Controller
            name='bankAccountBik'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isEditMode}
                variant='standard'
                onBlur={handleBikInputBlur}
                label={t('interface:page.UserRequisitesForRewardPage.bankAccountBik')}
                InputProps={{
                  ...maskComponent(MASK.BIC, field),
                  // TODO: remove Fragment?????>>>>>>>>>
                  endAdornment: <Fragment>{loading ? <CircularProgress color='inherit' size={20} /> : null}</Fragment>,
                }}
              />
            )}
          />
          <Controller
            name='bankAccount'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isEditMode}
                variant='standard'
                InputProps={maskComponent(MASK.BANK_ACCOUNT, field)}
                inputMode='numeric'
                label={t('interface:page.UserRequisitesForRewardPage.bankAccount')}
              />
            )}
          />
          <Controller
            name='bankName'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                disabled={!isEditMode}
                variant='standard'
                onChange={onChange}
                value={value}
                label={t('interface:page.UserRequisitesForRewardPage.bankName')}
              />
            )}
          />
          <Controller
            name='bankINN'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                disabled={!isEditMode}
                variant='standard'
                onChange={onChange}
                value={value}
                inputMode='numeric'
                label={t('interface:page.UserRequisitesForRewardPage.bankINN')}
              />
            )}
          />
          <Controller
            name='bankKPP'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                disabled={!isEditMode}
                variant='standard'
                onChange={onChange}
                value={value}
                inputMode='numeric'
                label={t('interface:page.UserRequisitesForRewardPage.bankKPP')}
              />
            )}
          />
          <Controller
            name='correspondentBankAccount'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                disabled={!isEditMode}
                variant='standard'
                onChange={onChange}
                value={value}
                inputMode='numeric'
                label={t('interface:page.UserRequisitesForRewardPage.correspondentBankAccount')}
              />
            )}
          />
        </Stack>

        <Stack sx={{ margin: '20px 0' }} spacing={2}>
          {!isEditMode && (
            <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
              {t('interface:page.UserRequisitesForRewardPage.editBtn')}
            </Button>
          )}

          {isEditMode && (
            <Button variant='contained' size='large' onClick={handleSubmitClick}>
              {t('interface:page.UserRequisitesForRewardPage.submitBtn')}
            </Button>
          )}

          {isEditMode && (
            <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
              {t('interface:page.UserRequisitesForRewardPage.cancelBtn')}
            </Button>
          )}
        </Stack>
      </Box>
    </Page>
  );
};

export default UserRequisitesForRewardPage;
