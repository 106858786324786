import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'api/errors';
import { RealEstateFormSchema } from 'api/services/realEstate/realEstate.schema';
import { getRealEstate, postRealEstate, putRealEstate, putRealEstateVerification } from 'api/services/realEstate/realEstate.service';
import useNavigateParams from 'hooks/useNavigateParams';
import { createContext, ReactNode, useContext, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_CLIENT_MORTGAGE_FORM_PERSONAL } from 'routes/paths';
import RealEstateOverlayIcon from 'components/Icons/RealEstateOverlayIcon';
import { EMortgageEntryRealEstateStatus } from 'api/services/realEstate/realEstate.enum';
import { RealEstateUtils } from './utils';
import { useRoleAccess } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';
import { useSnackbar } from 'notistack';
import SkeletonForm from '../../Form/components/SkeletonForm';

interface RealEstateFormProps {
  forwardNext: () => Promise<void>;
  saveForm: () => Promise<void>;
  backProgress: () => void;
  onSendVerifcation: () => Promise<void>;
  overlayError: ApiError;
  isFormDisabled: boolean;
}

export const RealEstateContext = createContext<Partial<RealEstateFormProps>>({});

const stepMap: Record<string, string> = {
  info: 'documents',
  documents: 'approvals',
};

export default function RealEstateForm({ children, groupName }: { children: ReactNode; groupName: string }) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const access = useRoleAccess([EUserRole.manager, EUserRole.admin]);

  const [isFormDisabled, setFormDisabled] = useState(false);
  const [, setShowOverlay] = useState<boolean>();
  const [, setError] = useState<ApiError>();

  const navigate = useNavigateParams();
  const snackbar = useSnackbar();
  const [requestMethod, setRequestMethod] = useState<'POST' | 'PUT'>('POST');
  const mutation = useMutation(requestMethod === 'POST' ? postRealEstate : putRealEstate);
  const { uuidEntry, uuidProfile } = useParams();
  const form = useForm<RealEstateFormSchema>({ defaultValues: {} });

  /**
   *
   */
  const onSendVerifcation = async () => {
    try {
      await putRealEstateVerification({ uuidEntry: uuidEntry! });
      await loadRealEstateAsync();
    } catch (err) {
      const apiError = err as ApiError;
      snackbar.enqueueSnackbar(t(`errors:${apiError.name}`), { variant: 'error' });
    }
  };

  const handleStatus = (status?: EMortgageEntryRealEstateStatus) => {
    switch (status) {
      case EMortgageEntryRealEstateStatus.process: {
        if (groupName === 'approvals') {
          setShowOverlay(true);
        }
        return;
      }
      case EMortgageEntryRealEstateStatus.verification: {
        setFormDisabled(true);
        return;
      }
      case EMortgageEntryRealEstateStatus.approved: {
        setFormDisabled(true);
        return;
      }
      default: {
        setShowOverlay(false);
        return;
      }
    }
  };

  /**
   * load real estate
   */
  const loadRealEstateAsync = async () => {
    setLoading(false);
    setError(undefined);
    try {
      const res = await getRealEstate({ uuidEntry });
      const data = res.body;

      if (access.accessible === false) handleStatus(data?.mortgage?.status);

      form.reset(RealEstateUtils.serialize(data));
      setRequestMethod('PUT');
    } catch (err: unknown) {
      form.reset({});
      setRequestMethod('POST');
    } finally {
      setLoading(false);
    }
  };
  useLayoutEffect(() => {
    if (uuidEntry) loadRealEstateAsync();
  }, [uuidEntry, access]);

  const backProgress = () => {
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: uuidEntry } });
  };

  /**
   * handle click save and continue button
   */
  const saveForm = async () => {
    try {
      const formValues = RealEstateUtils.deserialize(form.getValues());

      if (!isFormDisabled) {
        await mutation.mutateAsync({ uuidEntry: uuidEntry as string, formValues });
      }
      navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: uuidEntry } });
    } catch (err: unknown) {
      setError(err as ApiError);
    }
  };
  /**
   * navigate to next step of real estate
   */
  const forwardNext = async () => {
    try {
      const formValues = RealEstateUtils.deserialize(form.getValues());
      if (!isFormDisabled) {
        await mutation.mutateAsync({ uuidEntry: uuidEntry as string, formValues });
      }
      const nextStep = stepMap[groupName];
      if (!nextStep) {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: uuidEntry } });
        return;
      }
      navigate(PATH_CLIENT_MORTGAGE_FORM_PERSONAL, { params: { uuidEntry, uuidProfile, formName: 'realEstate', groupName: nextStep } });
    } catch (err: unknown) {
      console.log(err);
      setError(err as ApiError);
    }
  };

  return (
    <RealEstateContext.Provider value={{ forwardNext, saveForm, backProgress, isFormDisabled, onSendVerifcation }}>
      <FormProvider {...form}>{isLoading ? <SkeletonForm /> : children}</FormProvider>
    </RealEstateContext.Provider>
  );
}

export function useRealEstate() {
  return useContext(RealEstateContext);
}

RealEstateForm.OverlayWindow = function OverlayWindow() {
  const realEstate = useRealEstate();
  const { t } = useTranslation();

  return (
    <Stack sx={{ minHeight: '75vh' }} direction='column' justifyContent='center' alignItems='center'>
      <SvgIcon
        sx={{
          width: '256px',
          height: '256px',
          mb: 10,
        }}
        component={RealEstateOverlayIcon}
      />

      <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', gap: '12px', justifyContent: 'center', alignItems: 'center' }}>
        <Box>
          <Typography fontWeight={400} lineHeight='150%' fontSize='18px' textAlign='center'>
            {t('interface:page.MortgageEntryRealEstatePage.overlay.title')}
          </Typography>
        </Box>
        <Box sx={{ width: 1, marginTop: 'auto 0', display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Button fullWidth variant='contained' size='large' type='submit' data-testid='nextStep' onClick={realEstate.onSendVerifcation}>
            {t('interface:page.MortgageEntryRealEstatePage.overlay.send')}
          </Button>

          <Button
            fullWidth
            variant='contained'
            color="secondary"
            size='large'
            type='submit'
            data-testid='nextStep'
            onClick={realEstate.backProgress}
          >
            {t('interface:page.MortgageEntryRealEstatePage.returnProgress')}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

RealEstateForm.SubmitButton = function SubmitButton(props: { groupName: string }) {
  const form = useFormContext();
  const { t } = useTranslation();
  const realEstate = useRealEstate();

  const invalid = Object.keys(form.formState.errors).length > 0;

  if (props.groupName === 'approvals') {
    return null;
  }

  return (
    <Button
      disabled={invalid}
      fullWidth
      variant='contained'
      size='large'
      type='submit'
      data-testid='nextStep'
      onClick={realEstate.forwardNext}
    >
      {t('interface:page.MortgageEntryRealEstatePage.nextStep', {
        groupName: t(`interface:page.MortgageEntryRealEstatePage.form.${props.groupName}.nextStep`),
      })}
    </Button>
  );
};
RealEstateForm.SaveButton = function SaveButton({ groupName }: { groupName: string }) {
  const realEstateForm = useRealEstate();
  const { t } = useTranslation();

  if (groupName === 'approvals') {
    return null;
  }

  return (
    <Button fullWidth variant='contained' color='secondary' size='large' data-testid='saveAndContinueBtn' onClick={realEstateForm.saveForm}>
      {t('interface:page.MortgageEntryRealEstatePage.saveAndContinueBtn')}
    </Button>
  );
};
