import { ApiError } from 'api/errors';
import { IRegisterUser } from 'api/services/auth.dto';
import { useAuth } from 'components/providers/AuthProvider';
import { useCallback, useState } from 'react';

export function useRegisterLogic() {
  const auth = useAuth();

  const [smsInputAllow, setSmsInputAllow] = useState(false);
  const [errors, setError] = useState<ApiError>();
  const [loading, setLoading] = useState(false);

  /**
   * @name sendSmsRequest
   * @description use to get sms code
   *
   * @example
   *
   * ```ts
   * const logic=useRegisterLogic();
   *
   * const res=await logic.sendSmsRequest('7998887766');
   *
   *
   * ```
   */
  const sendSmsRequest = useCallback(
    async (phoneNumber: number) => {
      setError(undefined);
      setLoading(true);
      try {
        const res = await auth.requestSmsCode(phoneNumber);

        if (res?.ok) {
          setSmsInputAllow(true);
        }
      } catch (err: unknown) {
        setError(err as ApiError);
      } finally {
        setLoading(false);
      }
    },
    [smsInputAllow, errors, loading],
  );
  /**
   * @name registerRequest
   * @description send register request
   *
   * @example
   *
   * ```ts
   * const logic=useRegisterLogic();
   * const registerResponse= await logic.registerRequest('9990',{});
   *
   *
   *
   * ```
   */
  const registerRequest = useCallback(
    async (smsCode: string, formData: IRegisterUser) => {
      setError(undefined);
      setLoading(true);
      try {
        const res = await auth.register({ ...formData, code: smsCode });

        return res?.ok;
      } catch (err: unknown) {
        setSmsInputAllow(true);
        setError(err as ApiError);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [smsInputAllow, errors, loading],
  );

  return {
    setSmsInputAllow,
    smsInputAllow,
    errors,
    setError,
    loading,
    setLoading,
    sendSmsRequest,
    registerRequest,
  };
}

export type RegisterLogic = ReturnType<typeof useRegisterLogic>;
