import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Container, Grid, Typography } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import _ from 'lodash';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import CardDocuments from 'components/base/SmartForm/CheckListDocuments';
import UploaderView from 'components/base/SmartForm/UploaderView';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_BUSINESS_CREDIT_ENTRY_SIGN } from 'routes/paths';
import utils from 'utils';
import { useBusinessDocuments } from 'components/providers/Document/BusinessDocumentProvider';

const T_PREFIX = 'interface:base.DocumentSmartForm';

export default function BusinessCreditDocumentPage() {
  const documentService = useBusinessDocuments();
  const { uuidEntry } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBackEvent = () => {
    navigate(-1);
    return false;
  };

  const handleSave = async () => {
    navigate(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_SIGN, { uuidEntry: uuidEntry! }));
  };

  return (
    <ContainerLayout
      maxWidth='xl'
      title={t('helmet:ClientMortgageFormPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageFormPage.description') }]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.ClientMortgageFormPage.forms.documents.title`)}
            subtitle={t(`interface:page.ClientMortgageFormPage.forms.profile.title`)}
            onBackEvent={handleBackEvent}
          />
        ),
        lg: <TopMenu sx={{ mx: 4 }} />,
      }}
    >
      <Container
        maxWidth='md'
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',

          alignItems: 'center',
          height: 1,
          gap: '24px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2b'>{t(`${T_PREFIX}.title`)}</Typography>
            {/* <IconButton color={'primary'}>
              <InfoIcon />
            </IconButton> */}
          </Grid>

          <Grid item xs={12}>
            <Alert severity='info' sx={{ backgroundColor: '#EAF3FF', borderRadius: '8px' }}>
              <AlertTitle>Просто загрузите файл, и мы сделаем всё остальное. 🚀</AlertTitle>
              Мы собрали необходимые документы для наших банков-партнеров. Просто загрузите свои файлы сюда, и наш ИИ автоматически
              обработает их!
              <br />
              Независимо от формата или содержания, мы заботимся о всем! 😊📁
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <CardDocuments />
          </Grid>
          <Grid item xs={12}>
            <UploaderView
              fetchState={documentService.fetchState}
              files={documentService.documents}
              onDeleteFile={documentService.deleteRequest}
              onUpdateFile={documentService.updateRequest}
              onUploadFile={documentService.uploadRequest}
              queue={documentService.queue}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '20px' }}>
            <LoadingButton
              data-testid='submitDocuments'
              variant='contained'
              fullWidth
              onClick={handleSave}
              disabled={!documentService.canSubmit}
            >
              {t(`${T_PREFIX}.submitButton`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
}
