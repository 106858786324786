import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.5 25.4H11.4L4.5 8L0.5 25.4Z' fill='#D3D5D6' />
    <path d='M1.3999 0L4.4999 8L11.3999 25.4L15.3999 8L12.2999 0H1.3999Z' fill='#ADAFB2' />
    <path d='M13 25.4L17.1 8L23.9 25.4H13Z' fill='#75BEE9' />
    <path d='M27.8999 8L23.8999 25.4L17.0999 8L13.8999 0H24.7999L27.8999 8Z' fill='#0072BC' />
    <path d='M25.6001 25.4L29.6001 8L36.5001 25.4H25.6001Z' fill='#F37164' />
    <path d='M40.5 8L36.5 25.4L29.6 8L26.5 0H37.3L40.5 8Z' fill='#ED1B35' />
    <path
      d='M7.6 35.0004L4.4 28.3004H0.5V39.5004H3.3V31.7004L6.9 39.5004H8.1L11.8 31.7004V39.5004H14.6V28.3004H10.7L7.6 35.0004ZM22.4 30.1004C23.7 30.1004 24.8 30.8004 25.4 31.8004L27.7 30.8004C26.8 29.4004 25.2 27.9004 22.4 27.9004C18.6 27.9004 15.7 30.4004 15.7 34.0004C15.7 37.7004 18.7 40.1004 22.4 40.1004C25.1 40.1004 26.7 38.7004 27.7 37.2004L25.4 36.2004C24.8 37.2004 23.7 37.9004 22.4 37.9004C20.2 37.9004 18.5 36.3004 18.5 34.1004C18.5 31.7004 20.1 30.1004 22.4 30.1004ZM29.1 28.1004V39.9004H31.8V30.3004H37.7V39.9004H40.5V28.1004H29.1Z'
      fill='#4D4D4F'
    />
  </svg>,
  'Msp',
);
