import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useAuth } from 'components/providers/AuthProvider';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';
import { ObjectType } from 'utils/iots';

export default function AuthSignInByEmailPage() {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();

  const submitForm = async (values: ObjectType) => {
    const res = await auth.signInEmail?.(values);
    if (res?.ok) {
      navigate(PATH_HOME);
    } else {
      setError(res?.error?.name);
    }
  };

  return (
    <ContainerLayout
      title={t('helmet:AuthSignInByEmailPage.title')}
      header={{
        xs: <BaseButtonBack title={t('helmet:AuthSignInByEmailPage.title')} backRoute='/' />,
        lg: <TopMenu.BackButton backRoute='/' />,
      }}
      maxWidth='sm'
      sx={{ height: 0.7 }}
    >
      <Box
        component='form'
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 1 }}
        onSubmit={handleSubmit(submitForm)}
      >
        <Grid container direction='column' spacing={5}>
          <Grid item>
            <Controller
              name='email'
              control={control}
              rules={{ required: true, pattern: /^\S+@\S+$/i }}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('interface:page.AuthRegisterPage.Form.profile.Email')}
                  data-testid={'RegisterPage.Form.profile.Email'}
                  variant='standard'
                  fullWidth
                  error={!!fieldState.error}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name='password'
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('interface:page.AuthRegisterPage.Form.profile.password')}
                  data-testid={'RegisterPage.Form.profile.password'}
                  variant='standard'
                  fullWidth
                  type='password'
                  error={!!fieldState.error}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button disabled={!formState.isValid} type='submit' fullWidth variant='contained' size='superlarge'>
              {t('interface:page.AuthSignInByEmailPage.submitBtn')}
            </Button>

            {error && (
              <Typography textAlign='center' sx={{ padding: '16px 0', color: (theme) => `${theme.palette.error.main}` }} variant='h5'>
                {t(`errors:${error}`)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </ContainerLayout>
  );
}
