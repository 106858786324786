import { useTheme } from '@mui/material';
import { getContent } from 'api/services/content';
import Page from 'components/layouts/Page';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

interface IContentResponse {
  title: string;
  description: string;
  thumbnailImg?: string;
  thumbnailFeatureImg?: string;
  date: string;
  content: string;
}

type TLocationParams = {
  urlname: string;
};

const ClientMortgageRemoteContentPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { urlname } = useParams<keyof TLocationParams>() as TLocationParams;
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState<IContentResponse | null>(null);
  // const [error, setError] = useState<string | null>(null);

  const loadContent = async (name: string, force: boolean) => {
    try {
      const res = await getContent(name, force);
      const body = res.body as IContentResponse;
      // Remove fucking Tilda copy
      // eslint-disable-next-line no-useless-escape
      body.content = body.content.replace(/\<div class=\".*?\" id=\"tildacopy\" data-tilda-sign=\".*?"\>/gm, '<div style="display:none">');

      setContent(body);
    } catch (err) {
      // TODO:
      // setError('logic.badConnection');
    }
  };

  useEffect(() => {
    if (!content) loadContent(urlname, searchParams.get('force') === '1');
  }, [content, urlname, searchParams]);

  // Provide scripts from Tilda
  useEffect(() => {
    if (!content) return;
    const scripts: HTMLScriptElement[] = [];

    const createElementFromHTML = (htmlString: string): ChildNode | null => {
      const div = document.createElement('div');
      div.innerHTML = htmlString.trim();
      // Change this to div.childNodes to support multiple top-level nodes.
      return div.firstChild;
    };

    // eslint-disable-next-line no-useless-escape
    const scriptTags = content.content.match(/\<script.*?\<\/script\>/gm);
    if (scriptTags)
      scriptTags.forEach((s) => {
        const el = createElementFromHTML(s) as HTMLScriptElement;
        if (!el) return;
        const script = document.createElement('script');
        script.async = true;
        if (el.src) script.src = el.src;
        if (el.type) script.type = el.type;
        if (el.charset) script.charset = el.charset;
        if (el.onerror) script.onerror = el.onerror;
        if (el.innerHTML) script.innerHTML = el.innerHTML;
        scripts.push(script);
      });

    scripts.forEach((s) => document.body.appendChild(s));
    return () => {
      scripts.forEach((s) => document.body.removeChild(s));
    };
  }, [content]);

  return (
    <Page
      title={content?.title ?? t('helmet:ClientMortgageRemoteContentPage.title')}
      meta={[{ name: 'description', content: content?.description ?? t('helmet:ClientMortgageRemoteContentPage.description') }]}
      header={{
        type: 'backButton',
        title: content?.title ?? t('interface:page.ClientMortgageRemoteContentPage.headerTitle'),
        sx: {
          width: 'calc(100%-40px)',
          zIndex: 2,
          background: theme.palette.background.default,
          margin: 0,
          padding: '16px 20px',
        },
      }}
      sx={{ padding: 0 }}
    >
      <Helmet />
      {content && <div style={{ marginTop: '-80px' }} dangerouslySetInnerHTML={{ __html: content.content }} />}
    </Page>
  );
};

export default ClientMortgageRemoteContentPage;
