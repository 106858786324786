import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from 'components/Icons/Close';
import { isDesktop } from 'react-device-detect';
import Stories from 'stories-react';
import 'stories-react/dist/index.css';
import { staticStories } from '../staticStories';

export default function StoryViewer({
  isViewMode,
  onCloseView,
  startFrom,
}: {
  isViewMode: boolean;
  onCloseView: () => void;
  startFrom: number;
}) {
  return (
    <Modal open={isViewMode} onClose={onCloseView}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 1,
          // // position: 'absolute',
          // // zIndex: 2,
          // display: 'flex',
          // justifyContent: 'center',
          // flexDirection: 'column',
          // alignItems: 'center',
          // alignContent: 'center',
          // // width: '100%',
          // marginBottom: '16px',
          // height: 1,
          // background: '#000',
          // // overflow: 'hidden',
          // paddingTop: '3px',
        }}
      >
        <IconButton
          size='large'
          onClick={onCloseView}
          sx={{ zIndex: 999, position: 'absolute', right: isDesktop ? '25%' : '0', top: '5px' }}
        >
          <CloseIcon />
        </IconButton>
        {isViewMode && (
          <Stories
            currentIndex={startFrom}
            onAllStoriesEnd={onCloseView}
            height='100%'
            width={isDesktop ? '50%' : '100%'}
            stories={staticStories}
          />
        )}
      </Box>
    </Modal>
  );
}
