import { Box, Grid } from '@mui/material';
import ContainerLayout from 'components/layouts/ContainerLayout';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_BANK_GARANTEE_ENTRY_PROGRESS, PATH_BUSINESS_CREDIT_ENTRY_PROGRESS } from 'routes/paths';
import utils from 'utils';

import MobileMenu from './components/MobileMenu';
import TopMenu from './components/TopMenu/TopMenu';
import CompanyBanner from './components/Company/Banner';
import { useAuth } from 'components/providers/AuthProvider';
import PersonalManager from './components/PersonalManager';
import { EUserPersonalManagerStatus } from 'api/services/user.dto';
import { getAds } from 'api/services/ad';
import { IAdEntity } from 'api/services/ad.dto';
import ProductCard from './components/ProductCard';
import BusinessCreditCard from 'pages/BusinessCredit/components/BusinessCreditCard';
import BusinessCreditProvider from 'components/providers/BusinessCreditProvider';
import { isEwaTender } from 'utils/env/env';
import Stories from 'components/base/Stories';
import PopoverProvider, { usePopover } from 'components/providers/PopoverProvider';
import SupportButtons from './components/SupportButtons';
import ProductPopover from './components/ProductPopover';
import BankGuaranteeCard from 'pages/BankGuarantee/components/BankGaranteeCard';
import BankGuaranteeProvider from 'components/providers/BankGuaranteeProvider';

const HomeClientPage = () => {
  const popoverService = usePopover();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [, setLoading] = useState<boolean>(true);
  const [, setError] = useState<string | null>(null);
  const isEwaTenderHidden = useMemo(isEwaTender, []);
  const [ads, setAds] = useState<IAdEntity[]>([]);
  const loadAds = async () => {
    setLoading(true);
    try {
      const res = await getAds();
      const body = res.body as Array<any>;
      if (body) {
        setAds(body);
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadAsync = async () => {
      await loadAds();
    };
    loadAsync().catch(console.log);
  }, []);

  const handleCreateEntry = useCallback(
    (product: 'guarantee' | 'business') => (uuidEntry: string) => {
      if (product === 'guarantee') {
        navigate(utils.url.urlReplace(PATH_BANK_GARANTEE_ENTRY_PROGRESS, { uuidEntry }));
      } else {
        navigate(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, { uuidEntry }));
      }
    },
    [],
  );

  return (
    <ContainerLayout
      title={t('helmet:HomeClientPage.title')}
      meta={[{ name: 'description', content: t('helmet:HomeClientPage.description') }]}
      header={{ lg: <TopMenu />, xs: <MobileMenu /> }}
      maxWidth='xl'
      sx={{ overflowX: 'hidden' }}
    >
      <Grid container rowSpacing={{ xs: '24px', lg: '16px' }} spacing={{ xs: '8px', lg: '32px' }}>
        <Grid
          item
          sx={{ order: { xs: 4, lg: 3 }, display: 'flex', flexDirection: 'column', gap: { xs: '24px', lg: '16px' } }}
          xs={12}
          lg={6}
        >
          <Box sx={{ order: { xs: 2, lg: 1 }, display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <BusinessCreditCard onClick={handleCreateEntry('business')} />
            <BankGuaranteeCard onClick={handleCreateEntry('guarantee')} />
            <ProductCard onClickCreate={popoverService.openPopover} />
          </Box>
          {ads.map((ad) => (
            <CompanyBanner {...ad} />
          ))}
        </Grid>

        <Grid container item xs={12} lg={6} sx={{ order: { lg: 4, xs: 1 } }} spacing={1}>
          <Stories />
          {!isEwaTenderHidden && (
            <Grid item xs={12} sx={{ order: { lg: 5, xs: 4 }, marginTop: '10px' }}>
              <PersonalManager
                status={auth.user?.settings?.statusManager || EUserPersonalManagerStatus.none}
                manager={auth.user?.manager}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <ProductPopover />
      {!isEwaTenderHidden && <SupportButtons />}
      {/* Support modal */}
    </ContainerLayout>
  );
};

export default () => (
  <PopoverProvider>
    <BankGuaranteeProvider>
      <BusinessCreditProvider>
        <HomeClientPage />
      </BusinessCreditProvider>
    </BankGuaranteeProvider>
  </PopoverProvider>
);
