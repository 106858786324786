import { Box, Slider, SxProps, TextField, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Fragment, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { ObjectType } from 'utils/iots';

type Props = {
  min: number;
  max: number;
  step: number;
  threshold: number;
  period: number;
  label: string;
  unitLabel: string | ((val: number) => string);
  handleChangePeriod: (period: number) => void;
  format?: (n: number) => string;
  marks?: ObjectType[];
  sx?: SxProps;
  sliderSx?: SxProps;
  inputMask?: 'currency' | string;
  hasInput?: boolean;
  'data-testid'?: string;
};
// TODO: It's not my code, I just typed it... Need to rewrite
const UnitSlider: React.FC<Props> = ({
  format,
  min,
  max,
  step,
  threshold,
  label,
  unitLabel,
  period,
  handleChangePeriod,
  'data-testid': testId,
  sliderSx,
  marks,
  sx,
  inputMask,
  hasInput,
}: Props) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(period / threshold);

  const valueLabelFormat = (value: number): string => {
    return format ? format(value) : typeof unitLabel === 'function' ? unitLabel(value) : `${value} ${unitLabel}`;
  };

  const calculateValue = (value: number): number => {
    return value;
  };

  useEffect(() => {
    if (!hasInput) setValue(period / threshold);
  }, [period, threshold]);

  // TODO:
  //   const marks = [
  //     {
  //       value: 10,
  //       label: (
  //         <Typography sx={{ marginLeft: '5px' }} variant='h6'>
  //           {min / threshold} {unitLabel}
  //         </Typography>
  //       ),
  //     },
  //     {
  //       value: 30,
  //       label: (
  //         <Typography sx={{ marginRight: '5x' }} variant='h6'>
  //           {max / threshold} {unitLabel}
  //         </Typography>
  //       ),
  //     },
  //   ];
  const settedValue = valueLabelFormat(calculateValue(value));

  const handleChange = (_event: Event, newValue: number | number[]): void => {
    if (typeof newValue === 'number') {
      setValue(newValue);
      handleChangePeriod(newValue * threshold);
    }
  };
  const handleInput = (event: ObjectType) => {
    const formated = parseInt(event, 10);

    if (formated) {
      setValue(formated);

      handleChangePeriod(formated);
    }
  };

  return (
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'column',

        ...(sx || {}),
      }}
    >
      {!hasInput ? (
        <Fragment>
          <Typography sx={{ color: `${theme.palette.text.secondary}` }} variant='h7' gutterBottom>
            {label}
          </Typography>
          <Typography id='non-linear-slider' variant='h4' gutterBottom>
            {settedValue}
          </Typography>
        </Fragment>
      ) : (
        <NumberFormat
          inputProps={{ ['data-testid']: testId }}
          isAllowed={(values) => {
            const { value } = values;
            const v = parseInt(value, 10);
            if (isNaN(v)) {
              return true;
            }
            return v <= max / threshold;
          }}
          allowEmptyFormatting
          customInput={TextField}
          onValueChange={(values: ObjectType) => {
            handleInput(values.value);
          }}
          thousandSeparator={inputMask && ' '}
          label={label}
          value={value}
          variant='filled'
          color='secondary'
          focused
          suffix={inputMask && ' ₽'}
          sx={{
            '& .Mui-focused': {
              backgroundColor: `${theme.palette.secondary.main} !important`,
            },
          }}
        />
      )}

      <Slider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          color: `${theme.palette.common.greyLight}`,
          padding: '0 !important',
          marginTop: '-3px',
          '& .MuiSlider-track': {
            border: 'none',
            color: `${theme.palette.primary.main}`,
          },
          '& .MuiSlider-thumb': {
            marginRight: '11px',
            width: 21,
            height: 21,
            background: `${theme.palette.common.white}`,
            border: '4px solid ',
            borderColor: `${theme.palette.primary.main}`,
            '&:before': {
              boxShadow: 'none',
            },
            '&:hover, &.Mui-focusVisible, &.Mui-active': {
              boxShadow: 'none',
            },
          },
          '& .MuiSlider-markLabel': {
            color: `${theme.palette.text.secondary}`,
          },
          '& .MuiSlider-rail': {
            height: '0px',
            ...(!hasInput && {
              border: '1px solid',
            }),
          },
          '& .MuiSlider-mark': {
            width: '0',
            height: '0',
          },

          ...(sliderSx || {}),
        }}
        value={value}
        min={min / threshold}
        step={step}
        max={max / threshold}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay='off'
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '20px',
          marginTop: '11px',
        }}
      >
        {marks?.map((mark) => (
          <Typography key={mark.value} variant='h6' component='span' sx={{ color: '#767676' }}>
            {mark.label}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default UnitSlider;
