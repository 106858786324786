import PropTypes from 'prop-types';
import React from 'react';
import { IMaskInput } from 'react-imask';

// TODO: rename TextMask to BaseFormMaskInput
const TextMask = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputMode={other.inputMode}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

TextMask.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
TextMask.defaultProps = {
  name: '',
};

export default TextMask;
