import { createTheme, ThemeProvider } from '@mui/material';
import './styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Counters from 'components/base/Counters';
import AuthProvider from 'components/providers/AuthProvider';
import { BaseMenuProvider } from 'components/providers/BaseMenuProvider'; // TODO: move in components/providers
import { NotificationProvider } from 'components/providers/Notification';
import { SupportProvider } from 'components/providers/SupportProvider';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import utils from 'utils';
import { storeUTM } from 'utils/cookie/utm';

import Router from './routes';
import getDesignTokens from './theme';
import RoleAccessProvider from 'components/providers/RoleAccessProvider';
import ServiceWorker from './ServiceWorker';

// We save this for tests and css import replacement
// import '@fontsource/roboto';

// TODO: Create lng mechanism with user settings
const { REACT_APP_I18N_DEFAULT_LANGUAGE: DEFAULT_LANGUAGE, REACT_APP_SENTRY_DSN } = import.meta.env;

if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing(), new SentryRRWeb({})],
    tracesSampleRate: 1.4,
  });
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const i18n = utils.i18n.configureI18n(DEFAULT_LANGUAGE as string);

export const App = () => {
  const [mode] = React.useState<'light' | 'dark'>((localStorage.getItem('theme') as 'light' | 'dark') || 'light');

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  // Save UTM metrics in cookies for provide them to backend
  useEffect(() => {
    storeUTM();
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <Helmet htmlAttributes={{ lang: i18n.language || DEFAULT_LANGUAGE }} />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
              <RoleAccessProvider>
                <NotificationProvider>
                  <BaseMenuProvider>
                    <SnackbarProvider maxSnack={3}>
                      <Counters />
                      <ServiceWorker />
                      <SupportProvider>
                        <Router />
                      </SupportProvider>
                    </SnackbarProvider>
                  </BaseMenuProvider>
                </NotificationProvider>
              </RoleAccessProvider>
            </AuthProvider>
          </ThemeProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
