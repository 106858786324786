import { ECBCEntryPropose } from 'api/services/businessCredit/businessCredit.propose.dto';
import { IProposeService } from '../types';
import businessCreditService from 'api/services/businessCredit/businessCredit.service';

export const BusinessCreditProposeService: IProposeService<ECBCEntryPropose, ECBCEntryPropose> = {
  updateProposeList: businessCreditService.putUpdatePropose,
  updateProposeDecision: businessCreditService.putUpdatePropose,
  postFinalProposeDecision: function (_params: Record<string, string>, _propose: ECBCEntryPropose<unknown>): Promise<void> {
    throw new Error('Function not implemented.');
  },
  excludeBanks: businessCreditService.putExcludeBanks,
  getProposeList: businessCreditService.getProposeList,
  selectPropose: businessCreditService.postSelectPropose,
};
