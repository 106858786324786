import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

interface PhoneMaskCustomProps extends NumberFormatProps<TextFieldProps> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function PhoneMaskCustom(props: PhoneMaskCustomProps) {
  const { onChange, ...otherProps } = props;
  return (
    <NumberFormat
      allowEmptyFormatting
      customInput={TextField}
      {...otherProps}
      InputProps={{ inputRef: otherProps.inputRef }}
      onValueChange={(values: ObjectType) => {
        onChange?.({ target: { value: utils.format.digitString(values.value) } } as ChangeEvent<HTMLInputElement>);
      }}
      format='+7 (###) ###-##-##'
      mask='_'
    />
  );
}
