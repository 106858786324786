import { getPolicy } from 'api/services/agent';
import SuccessIcon from 'components/Icons/SuccessLigth';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';

import SuccessTemplate from './components/Success';

const AgentInsurancePropertyPolicySharePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [policy, setPolicy] = React.useState(null);
  const { uuid } = useParams();

  const loadPolicy = () => {
    // TODO: Rewrite fetch query
    // TODO: Move translations to i18n files
    // TODO: Remove atomic components

    getPolicy(uuid)
      .then((res) => {
        const { body: result = {} } = res;
        setPolicy(result);
      })
      .catch((err) => {
        // TODO: Error handling
        console.error(err);
      });
  };

  const handleNextClick = () => {
    navigate(PATH_HOME);
  };

  React.useEffect(() => {
    if (!policy) {
      loadPolicy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  return (
    <>
      <Helmet
        title={t('helmet:AgentInsurancePropertyPolicySharePage.title')}
        meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyPolicySharePage.description') }]}
      />
      {/*
      TODO: QrPolicy share link for payment:
      {policy && policy.purchase.type === 'onlineLink' && (<QrPolicy ... />)} 
      */}
      {policy && (
        <SuccessTemplate
          onNextClick={handleNextClick}
          icon={SuccessIcon}
          title={t('interface:page.AgentInsurancePropertyPolicySharePage.issuedPolicy')}
          subtitle={t('interface:page.AgentInsurancePropertyPolicySharePage.paymentLink')}
        />
      )}
    </>
  );
};

export default AgentInsurancePropertyPolicySharePage;
