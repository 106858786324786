import {
  Typography,
  TextField,
  IconButton,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Grid,
  useTheme,
  SvgIcon,
  InputAdornment,
} from '@mui/material';
import { EBankGuaranteeEntryParamsType, EBankGuaranteeEntryParamsEnactment } from 'api/services/bankGuarantee/bankGuarantee.enum';
import DatePickerInput from 'components/base/DatePickerInput';
import { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import utils from 'utils';
import useBankGuaranteeCreateLogic from '../../hook';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';

interface Props {
  disabledFields: boolean;
}

export default function BankGuaranteeCreateFormMobile(props: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    hookForm: { handleSubmit, control, watch },
    fieldStatus,
    hasErrors,
    dispatch,
    submitForm,
    isPreloadEntryData,
  } = useBankGuaranteeCreateLogic();

  const haveAudience = watch('entry.params.haveAdvance');

  return (
    <Grid container spacing={2} component='form' onSubmit={handleSubmit(submitForm)}>
      <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='entry.params.purchaseId'
          render={({ field, fieldState }) => (
            <Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                <NumberFormat
                  label='Номер закупки'
                  id={field.name}
                  variant='standard'
                  fullWidth
                  customInput={TextField}
                  required
                  disabled={props.disabledFields ? !fieldStatus[field.name] : undefined}
                  value={field.value}
                  onChange={(e: ObjectType) => {
                    field.onChange(utils.format.digitNumber(e.target.value));
                  }}
                  error={!!fieldState.error}
                  helperText={t(`${fieldState.error?.message || ''}`)}
                  inputProps={{ ['data-testid']: field.name }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <SvgIcon component={CheckCircleOutlineOutlined} sx={{ fill: theme.palette.common.green }} />
                      </InputAdornment>
                    ),
                  }}
                />
                {props.disabledFields && (
                  <IconButton
                    onClick={() => {
                      dispatch({ type: field.name });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              {isPreloadEntryData && <Typography sx={{ color: theme.palette.common.green, fontSize: '14px' }}>Данные получены</Typography>}
            </Fragment>
          )}
        />
      </Grid>

      <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='company.inn'
          render={({ field, fieldState }) => (
            <Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                <NumberFormat
                  id={field.name}
                  label='ИНН организации-заявителя'
                  variant='standard'
                  fullWidth
                  customInput={TextField}
                  required
                  disabled={props.disabledFields ? !fieldStatus[field.name] : undefined}
                  value={field.value}
                  onChange={(e: ObjectType) => {
                    field.onChange(utils.format.digitNumber(e.target.value));
                  }}
                  error={!!fieldState.error}
                  helperText={t(`${fieldState.error?.message || ''}`)}
                  inputProps={{ ['data-testid']: field.name }}
                />
                {props.disabledFields && (
                  <IconButton
                    onClick={() => {
                      dispatch({ type: field.name });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              {isPreloadEntryData && <Typography sx={{ color: theme.palette.common.green, fontSize: '14px' }}>Данные получены</Typography>}
            </Fragment>
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='entry.params.type'
          render={({ field, fieldState }) => (
            <TextField
              id={field.name}
              variant='standard'
              fullWidth
              required
              label='Тип банковской гарантии'
              {...field}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ ['data-testid']: field.name }}
              select
            >
              {Object.values(EBankGuaranteeEntryParamsType).map((paramType) => (
                <MenuItem key={paramType} value={paramType}>
                  {t(`common:bankGuarantee.type.${paramType}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='entry.params.enactment'
          render={({ field, fieldState }) => (
            <TextField
              id={field.name}
              variant='standard'
              fullWidth
              required
              {...field}
              label='Закон исполнения торгов'
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ ['data-testid']: field.name }}
              select
            >
              {Object.values(EBankGuaranteeEntryParamsEnactment).map((paramType) => (
                <MenuItem key={paramType} value={paramType}>
                  {t(`common:bankGuarantee.enactment.${paramType}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='company.inn'
          render={({ field, fieldState }) => (
            <Fragment>
              <NumberFormat
                id={field.name}
                variant='standard'
                suffix=' '
                label='ИНН организации заказчика'
                fullWidth
                customInput={TextField}
                required
                thousandSeparator=' '
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
                inputProps={{ ['data-testid']: field.name }}
              />
              {isPreloadEntryData && <Typography sx={{ color: theme.palette.common.green, fontSize: '14px' }}>Данные получены</Typography>}
            </Fragment>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='entry.contract.startPrice'
          render={({ field, fieldState }) => (
            <NumberFormat
              id={field.name}
              variant='standard'
              suffix=' ₽'
              label='Начальная цена контракта'
              fullWidth
              customInput={TextField}
              required
              thousandSeparator=' '
              value={field.value}
              onChange={(e: ObjectType) => {
                field.onChange(utils.format.digitNumber(e.target.value));
              }}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ ['data-testid']: field.name }}
            />
          )}
        />
      </Grid>
      {haveAudience && (
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 700 }} />
          <Controller
            control={control}
            name='entry.contract.price'
            render={({ field, fieldState }) => (
              <Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                  <NumberFormat
                    id={field.name}
                    variant='standard'
                    suffix=' ₽'
                    label='Предложенная цена контракта'
                    fullWidth
                    customInput={TextField}
                    required
                    disabled={props.disabledFields ? !fieldStatus[field.name] : undefined}
                    thousandSeparator=' '
                    value={field.value}
                    onChange={(e: ObjectType) => {
                      field.onChange(utils.format.digitNumber(e.target.value));
                    }}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                  />
                  {props.disabledFields && (
                    <IconButton
                      onClick={() => {
                        dispatch({ type: field.name });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
              </Fragment>
            )}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name='entry.params.amount'
          render={({ field, fieldState }) => (
            <Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                <NumberFormat
                  id={field.name}
                  variant='standard'
                  label='Требуемая сумма'
                  suffix=' ₽'
                  fullWidth
                  customInput={TextField}
                  required
                  disabled={props.disabledFields ? !fieldStatus[field.name] : undefined}
                  thousandSeparator=' '
                  value={field.value}
                  onChange={(e: ObjectType) => {
                    field.onChange(utils.format.digitNumber(e.target.value));
                  }}
                  error={!!fieldState.error}
                  helperText={t(`${fieldState.error?.message || ''}`)}
                  inputProps={{ ['data-testid']: field.name }}
                />
                {props.disabledFields && (
                  <IconButton
                    onClick={() => {
                      dispatch({ type: field.name });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </Fragment>
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          name={'entry.params.expirationDate'}
          control={control}
          render={({ field }) => (
            <Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                <DatePickerInput
                  label='Дата окончания'
                  data-testid={field.name}
                  // label={'Дата  окончания'}
                  disabled={props.disabledFields ? !fieldStatus[field.name] : undefined}
                  {...field}
                />
                {props.disabledFields && (
                  <IconButton
                    onClick={() => {
                      dispatch({ type: field.name });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </Fragment>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name='entry.params.haveAuctions'
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  {...field}
                  onChange={(_e, checked) => {
                    field.onChange(checked);
                  }}
                  inputProps={{ 'data-testid': field.name } as any}
                />
              )}
            />
          }
          label={
            <Typography
              variant='h5'
              sx={{
                color: theme.palette.text.primary,
              }}
            >
              В контракте предусмотрен аванс
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Controller
              control={control}
              name='entry.params.haveAdvance'
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  {...field}
                  onChange={(_e, checked) => {
                    field.onChange(checked);
                  }}
                  inputProps={{ 'data-testid': field.name } as any}
                />
              )}
            />
          }
          label={
            <Typography
              variant='h5'
              sx={{
                color: theme.palette.text.primary,
              }}
            >
              Торги еще не проведены
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Button disabled={hasErrors} fullWidth variant='contained' size='large' type='submit' data-testid='mortgage.create'>
          {t('interface:page.ClientMortgageEntryCreatePage.btnCreate')}
        </Button>
      </Grid>
    </Grid>
  );
}
