import { Box, Button, MenuItem, Paper, SvgIcon, TextField, Typography, useTheme } from '@mui/material';
import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ProposeStatusPanel from '../StatusPanel';
import AdditionalForm from './components/AdditionalForm';
import ProposeEditForm from './components/ProposeEditForm';
import { useProposeCommon } from '../../context';
import { MapIcons } from 'modules/Propose/icons';
import { mapProposeDecisionForm, mapProposeToDecision } from 'modules/Propose/utils';
import ProposeRequest from '../Request';

interface ProposeEditorProps {
  propose: any;
  selected?: boolean;
}

export default function ProposeEditor(props: ProposeEditorProps) {
  const proposeHook = useProposeCommon();
  const { propose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const hookForm = useForm<any>({
    defaultValues: mapProposeToDecision(propose),
    mode: 'all',
    reValidateMode: 'onChange',
    // resolver: zodResolver(proposeSchema),
    criteriaMode: 'all',
  });

  const handleSubmit = async (values: any) => {
    const mapped = mapProposeDecisionForm(values);
    if (props.selected) {
      await proposeHook.managerUpdateDecision({ uuidPropose: propose.uuid as string, propose: mapped });
    } else {
      await proposeHook.updateProposeParams(mapped);
    }
    proposeHook.editPropose(undefined);
  };

  return (
    <>
      <FormProvider {...hookForm}>
        <Paper
          component='form'
          onSubmit={hookForm.handleSubmit(handleSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '24px',
            borderRadius: '12px',
            height: '100%',
            width: 1,
            justfiyContent: 'space-between',
          }}
          variant='outlined'
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', flexGrow: 0.7, width: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                <SvgIcon component={MapIcons[propose.bank]} sx={{ width: 48, height: 48 }} />
                <Typography variant='h4'>{t(`common:banks.${propose.bank}`)}</Typography>
              </Box>
              <Controller
                name='status'
                render={({ field }) => (
                  <TextField fullWidth disabled={props.selected} variant='standard' select data-testid='propose.status' {...field}>
                    {[
                      EMortgageEntryProposeStatus.approved,
                      EMortgageEntryProposeStatus.pending,
                      EMortgageEntryProposeStatus.withoutSuitablePrograms,
                      EMortgageEntryProposeStatus.declined,
                      'dataRequested',
                      'preliminary',
                    ].map((status) => (
                      <MenuItem key={String(status)} value={status}>
                        <ProposeStatusPanel canApprove={false} proposeStatus={status} theme={theme} />
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <ProposeEditForm />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Typography
                  sx={{
                    color: theme.palette.common.link,
                  }}
                >
                  {t('interface:base.ProposeEditor.additionalParams')}
                </Typography>
                <AdditionalForm />
              </Box>
              <Box sx={{ marginTop: '10px', width: '100%' }}>
                {propose.status === 'dataRequested' && <ProposeRequest uuidPropose={propose.uuid} />}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px' }}>
                <Button variant='contained' color='primary' type='submit'>
                  {t('common:save')}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    proposeHook.editPropose(undefined);
                  }}
                >
                  {t('common:cancel')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </FormProvider>
    </>
  );
}
