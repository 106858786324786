import { EInsMortgagePackageCompany, EInsPropPackageCompany } from './mortgage.dto';

export enum EUserStatus {
  active = 'active',
  unconfirmed = 'unconfirmed',
  blocked = 'blocked',
  deleted = 'deleted',
}

export enum EExperimentName {
  ocr = 'ocr',

  ad = 'ad',
  smartForm = 'smartForm',
}

export interface IUserSettingsExperiment {
  name: EExperimentName;
  status: boolean;
  params: Record<string, string | number | boolean>;
}
export interface IUserVerifications {
  phone?: boolean;
  password?: boolean;
  email?: boolean;
  esia?: boolean;
  personalData?: boolean;
  agentContract?: boolean;
  esiaDigitalProfile?: boolean;
}

export interface IUserDevice {
  uuid: string;
  addedAt: number;
  lastAppearAt: number;
  userAgent?: string;
  clientFingerprint?: string;
  notificationProviders: IUserDeviceNotificationProvider[];
}

export enum EUserDeviceNotificationProvider {
  firebase = 'firebase',
}

export interface IUserDeviceNotificationProvider {
  type: EUserDeviceNotificationProvider;
  token: string;
  isPrimary: boolean;
}

export enum EUserRole {
  ghost = 'ghost',
  developer = 'developer',
  admin = 'admin',
  agent = 'agent',
  client = 'client',
  manager = 'manager',
  unknown = '*',
}

export interface IUserEntity {
  ids: {
    uuidProfile: string;
    uuidAgent: string;
    uuidPersonalManager: string;
    crmLeadId: number;
    crmContactId: number;
    [x: string]: unknown;
  };
  uuid: string;
  createdAt: number;
  updatedAt: number;
  email?: string;
  phone: number;
  firstname?: string;
  lastname?: string;
  // otchestvo
  patronymic?: string;
  password?: string;
  settings?: IUserSettings;
  verifications?: IUserVerifications;
  avatar?: string;
  status: EUserStatus;
  devices: IUserDevice[];
  company?: IUserRelatedCompanyInfo;
  manager?: IUserManagerInfo;
  roles: EUserRole[];
}

export interface IUserManagerInfo {
  firstname: string;
  lastname: string;
  patronymic: string;
  phone: number;
  email: string;
  avatar: string;
}

export interface IUserRelatedCompanyInfo {
  name: string;
  status: ECompanyStatus;
  uuid: string;
  role?: ECompanyRole;
}

export enum ECompanyStatus {
  block = 'block',
  active = 'active',
  deleted = 'deleted',
  pending = 'pending',
}

export enum ECompanyRole {
  owner = 'owner',
  employee = 'employee',
  agent = 'agent',
}
export interface IUserIds {
  uuidProfile?: string;
}

export interface IUpdateAuthorizedUserDTO {
  firstname?: string;

  email?: string;
  lastname?: string;
  patronymic?: string;
  avatar?: string;
  settings?: IUserSettings;
}

export enum EUserPersonalManagerStatus {
  none = 'none',
  waiting = 'waiting',
  active = 'active',
}

export interface IUserSettings {
  statusManager?: EUserPersonalManagerStatus;
  lng?: EUserSettingsLng;
  theme?: EUserSettingsTheme;
  timezone?: string;
  services?: IUserSettingsService[];
  experiments?: IUserSettingsExperiment[];
}

export type IUserSettingsService =
  | { name: EServiceName.insProp; options: IUserSettingsInsPropOptions }
  | { name: EServiceName.mortgage; options: IUserSettingsMortgageOptions };

export interface IUserSettingsInsPropOptions {
  region?: string;
  availableCompanies: EInsPropPackageCompany[];
}

export interface IUserSettingsMortgageOptions {
  region?: string;
  availableCompanies: EInsMortgagePackageCompany[];
}

export enum EUserSettingsLng {
  ru = 'ru',
  en = 'en',
}

export enum EUserSettingsTheme {
  light = 'light',
  dark = 'dark',
}

export enum EServiceName {
  insProp = 'insProp',
  mortgage = 'mortgage',
}

export interface IUserAddNotificationToken {
  uuidDevice?: string;
  providerType: EUserDeviceNotificationProvider;
}
export interface IUserChangeGroupParamsDTO {
  uuidUser: string;
  roles: EUserRole[];
}

export interface ECUserIds {
  uuidProfile?: string;
  uuidAgent?: string;
  uuidPersonalManager?: string;
  crmLeadId?: number;
  crmContactId?: number;
}

export type IPublicUser = Pick<IUserEntity, 'uuid' | 'firstname' | 'lastname' | 'patronymic' | 'avatar' | 'verifications'>;

export type IFullUser = Omit<IUserEntity, 'password'> & {
  ids: ECUserIds & { cqUserHash?: string };
  company?: IUserRelatedCompanyInfo;
  manager?: IUserManagerInfo;
};

export type IPublicUserEntityDTO = IPublicUser;
export type IFullUserDTO = IFullUser;
