/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const UralSib = createSvgIcon(
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='48' rx='24' fill='white' />
    <path
      d='M40 24.7619C40 19.3106 37.0308 14.5177 32.5701 11.7875L32.63 15.4892C35.2815 17.7683 36.9617 21.0831 36.9617 24.7619C36.9617 26.4423 36.6026 28.0446 35.972 29.5086C34.0524 27.6853 32.6851 25.5399 31.8335 23.0076C30.8069 19.9413 30.5676 16.585 30.5308 12.4827L30.5353 10.7194C29.6146 10.3234 28.6526 10.0104 27.6675 9.78937L27.672 12.4781C27.7687 26.6402 30.2224 32.4783 33.8728 36.8658C37.6062 34.0482 40 29.6744 40 24.7619C40 24.7664 40 24.7664 40 24.7619Z'
      fill='#3B175C'
    />
    <path
      d='M20.3326 12.481L20.3372 9.79222C19.352 10.0132 18.3899 10.3263 17.4692 10.7222L17.4738 12.4856C17.4324 16.5833 17.1976 19.9442 16.1711 23.0105C15.324 25.5427 13.9522 27.6836 12.0326 29.5114C11.4019 28.0474 11.0429 26.4451 11.0429 24.7646C11.0429 21.0814 12.7185 17.771 15.3747 15.4921L15.4345 11.7904C10.9692 14.516 8 19.3089 8 24.7601V24.7646C8 29.6772 10.3984 34.051 14.1272 36.8688C17.7869 32.4857 20.2359 26.6431 20.3326 12.481Z'
      fill='#3B175C'
    />
    <path
      d='M25.4365 7.05986C24.9164 7.01841 24.4469 7 24.0048 7C23.563 7 23.0933 7.01841 22.5686 7.05986V10.2413C22.5686 24.1824 22.03 30.2413 19.9447 39.6521C22.587 40.3197 25.3952 40.3197 28.0421 39.6566C25.9705 30.315 25.4365 24.256 25.4365 10.2413V7.05986Z'
      fill='#3B175C'
    />
  </svg>,
  'UralSib',
);

export default UralSib;
