import { Collapse, Container, Grid, IconButton, Typography } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import UploaderView from 'components/base/SmartForm/UploaderView';
import utils from 'utils';
import { PATH_CLIENT_MORTGAGE_ENTRY_SIGN } from 'routes/paths';
import _ from 'lodash';
import { postGoalAnalysis } from 'api/services/analytics/analytics.service';
import CardDocuments from 'components/base/SmartForm/CheckListDocuments';
import InfoIcon from 'components/Icons/Info';
import { useDocumentService } from 'components/providers/DocumentProvider';

export default function MortgageEntryDocumentExperimentPage() {
  const documentService = useDocumentService();
  const uuidParams = useParams() as { uuidEntry: string; uuidUser: string };
  const [isShowInfo, setShowInfo] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBackEvent = () => {
    navigate(-1);
    return false;
  };

  const handleSave = async () => {
    try {
      await postGoalAnalysis({
        uuidEntry: uuidParams.uuidEntry,
        uuidUser: uuidParams.uuidUser,
        goalName: 'entryQuestionnaireFullyFilled',
      });
    } catch (err) {
    } finally {
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_SIGN, { uuid: uuidParams.uuidEntry }));
    }
  };

  useEffect(() => {
    documentService.loadAsync().then();
  }, []);

  return (
    <ContainerLayout
      maxWidth='xl'
      title={t('helmet:ClientMortgageFormPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageFormPage.description') }]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.ClientMortgageFormPage.forms.documents.title`)}
            subtitle={t(`interface:page.ClientMortgageFormPage.forms.profile.title`)}
            onBackEvent={handleBackEvent}
          />
        ),
        lg: <TopMenu sx={{ mx: 4 }} />,
      }}
    >
      <Container
        maxWidth='md'
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',

          alignItems: 'center',
          height: 1,
          gap: '24px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2b'>Прикрепите документы</Typography>
            <IconButton
              color={'primary'}
              onClick={() => {
                setShowInfo((prev) => !prev);
              }}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          {isShowInfo && (
            <Grid item xs={12}>
              <Collapse in={isShowInfo}>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Мы сформировали список необходимых документов для вашей заявки. На их основе система доформирует анкету и направит их в
                  банки
                </Typography>
              </Collapse>
            </Grid>
          )}

          <Grid item xs={12}>
            <CardDocuments />
          </Grid>
          <Grid item xs={12}>
            <UploaderView
              queue={documentService.queue}
              files={documentService.documents}
              onUploadFile={documentService.uploadFile as any}
              onDeleteFile={documentService.deleteFile as any}
              onUpdateFile={documentService.updateFile as any}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '20px' }}>
            <LoadingButton
              data-testid='submitDocuments'
              variant='contained'
              fullWidth
              onClick={handleSave}
              disabled={!documentService.canSubmit}
            >
              Сформировать заявку
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
}
