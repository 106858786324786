import React from 'react';

import MenuDrawer from '../base/Menu/MenuDrawer';

interface MenuContextProps {
  showMenu: () => void;
  toggleMenu: () => void;
}

const MenuContext = React.createContext<MenuContextProps | null>(null);

// TODO: rename BaseMenuProvider to BaseMenu
// or rebase this file into Provider Folder and rename const BaseMenuProvider to BaseMenuProvider
export const BaseMenuProvider = ({ children }: { children: React.ReactChild }) => {
  const [visible, setVisible] = React.useState(false);

  const showMenu = (show?: boolean) => {
    setVisible(show !== undefined ? show : !show);
  };
  const toggleMenu = () => showMenu(!visible);
  const onCloseMenu = () => showMenu(false);
  const onOpenMenu = () => showMenu(true);

  const value = { showMenu, toggleMenu };

  return (
    <MenuContext.Provider value={value}>
      <MenuDrawer menuVisible={visible} onCloseMenu={onCloseMenu} onOpenMenu={onOpenMenu} />
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  return React.useContext(MenuContext);
};
