import { Checkbox, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ft } from '../../utils';

const FormCheckboxField = ({ node, hookForm, path = [] }) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  const handleChecked = (e) => {
    setValue(path.join('.'), e.target.checked ? 'yes' : 'no');
  };

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={({ field }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                id={path.join('.')}
                sx={{ width: '16px', height: '16px', marginRight: '16px' }}
                {...field}
                onChange={handleChecked}
                checked={field?.value === 'yes'}
                data-testid={path.join('.')}
              />
            }
            label={t(`${ft(path)}.title`)}
          />
        );
      }}
    />
  );
};

FormCheckboxField.propTypes = {
  node: PropTypes.object.isRequired,
  hookForm: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired,
};

export default FormCheckboxField;
