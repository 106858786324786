import { Box, Button, Container, SvgIcon, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LS_TUTORIAL } from 'api/http';
import Logo from 'components/Icons/Logo';
import ContainerLayout from 'components/layouts/ContainerLayout';
import React, { Fragment, useMemo } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';

const Steps = ({ activeId, stepWidth }: { activeId: number; stepWidth: string }) => {
  const theme = useTheme();
  const steps = new Array(7).fill(null).map((_, idx) => ({ id: idx }));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', justifyContent: 'center', width: '100%' }}>
      {steps.map((step) => (
        <Box
          key={step.id}
          sx={{
            width: stepWidth,
            height: `2px`,
            background: theme.palette.common.black,
            opacity: activeId === step.id ? 1.0 : 0.3,
            borderRadius: '102px',
          }}
        />
      ))}
    </Box>
  );
};

function StepOne() {
  const { t } = useTranslation();

  return (
    <Container maxWidth='sm' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 1 }}>
      <Box
        sx={{
          padding: '20px 20px 20px 20px',

          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: 1,
          gap: { xs: '20px', md: '32px' },
        }}
      >
        <SvgIcon sx={{ width: { xs: '93px', md: '120px' }, height: { xs: '93px', md: '120px' } }} component={Logo} />
        <Typography variant='h2b' sx={{ paddingLeft: '10px', fontSize: '48px' }}>
          {t('common:companyName')}
        </Typography>
        <Box sx={{ display: 'flex', gap: { xs: '8px', md: '16px' }, flexDirection: 'column' }}>
          <Typography variant='h1b'>{t('page.TutorialPage.step0.title')}</Typography>
          <Typography variant='h4'>{t('page.TutorialPage.step0.description')}</Typography>
        </Box>
      </Box>
    </Container>
  );
}

function StepTwo() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return matches ? (
    <Box sx={{ height: 1, position: 'relative' }}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignitems: 'center',
          height: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant='h1b' sx={{ fontSize: '52px' }}>
            {t('page.TutorialPage.step1.title')}
          </Typography>
          <Typography variant='h4' sx={{ width: '500px' }}>
            {t('page.TutorialPage.step1.description')}
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          top: { xl: '-223px', lg: '-50px' },
          right: { lg: '-540px', xl: '-1076px' },
          background: `url(${'/img/tutorial/tutorial-step-1.png'}) no-repeat`,
          height: { lg: '1200px', xl: '2000px' },
          width: '100%',
          zIndex: -1,
          backgroundSize: '100%',
        }}
      />
    </Box>
  ) : (
    <Fragment>
      <Box
        sx={{
          padding: '20px 20px 20px 20px',

          display: { xs: 'flex', lg: 'none' },
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          flex: 1,
          gap: '20px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
          <Typography variant='h1b'>{t('page.TutorialPage.step1.title')}</Typography>
          <Typography variant='h4'>{t('page.TutorialPage.step1.description')}</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              position: 'absolute',
              top: '81px',
              left: '-16px',
              background: `url(${'/img/tutorial/anketa_photo.png'}) no-repeat`,
              height: '600px',
              width: '100%',
              zIndex: 999,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              background: ' radial-gradient(55.07% 55.07% at 50% 50%, #F8EE5A 0%, #FCFBAB 100%)',
              width: '789.49px',
              height: '800.49px',

              borderRadius: '50%',
              left: '-181px',
              top: '306px',
            }}
          />
        </Box>
      </Box>
    </Fragment>
  );
}
function StepThree() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return matches ? (
    <Box sx={{ height: 1, position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: { xl: '-330px', lg: '-180px' },
          left: { xl: '-800px', lg: '-400px' },
          background: `url(${'/img/tutorial/step-2.png'}) no-repeat`,
          height: { lg: '1200px', xl: '2000px' },
          width: '100%',
          zIndex: -1,
          backgroundSize: { lg: '92%', xl: '90%' },
        }}
      />
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignitems: 'center',
          height: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginLeft: { lg: '700px', xl: '825px' } }}>
          <Typography variant='h1b' sx={{ fontSize: '52px' }}>
            {t('page.TutorialPage.step2.title')}
          </Typography>
          <Typography variant='h4' sx={{ width: '500px', fontSize: '22px' }}>
            {t('page.TutorialPage.step2.description')}
          </Typography>
        </Box>
      </Container>
    </Box>
  ) : (
    <Box
      sx={{
        padding: '20px 20px 20px 20px',

        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        flex: 1,
        gap: '20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography variant='h1b'>{t('page.TutorialPage.step2.title')}</Typography>
        <Typography variant='h4'>{t('page.TutorialPage.step2.description')}</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            position: 'absolute',
            top: '180px',
            left: '-100px',
            background: `url(${'/img/tutorial/calc.png'}) no-repeat`,
            height: '800px',
            width: '200%',
            zIndex: 999,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            background: ' radial-gradient(55.07% 55.07% at 50% 50%, #F8EE5A 0%, #FCFBAB 100%)',
            width: '789.49px',
            height: '800.49px',

            borderRadius: '50%',
            left: '-181px',
            top: '406px',
          }}
        />
      </Box>
    </Box>
  );
}
function StepFour() {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return matches ? (
    <Box sx={{ height: 1, position: 'relative' }}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignitems: 'center',
          height: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant='h1b' sx={{ fontSize: '52px' }}>
            {t('page.TutorialPage.step3.title')}
          </Typography>
          <Typography variant='h4' sx={{ width: '500px', fontSize: '22px' }}>
            {t('page.TutorialPage.step3.description')}
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          top: { xl: '-223px', lg: '-144px' },
          right: { xl: '-400px', lg: '-270px' },
          background: `url(${'/img/tutorial/step-3.png'}) no-repeat`,
          height: { lg: '1200px', xl: '2000px' },
          width: '100%',
          zIndex: -1,
          backgroundSize: '100%',
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        padding: '20px 20px 20px 20px',

        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        flex: 1,
        gap: '20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography variant='h1b'>{t('page.TutorialPage.step3.title')}</Typography>
        <Typography variant='h4'>{t('page.TutorialPage.step3.description')}</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            position: 'absolute',
            background: ' radial-gradient(55.07% 55.07% at 50% 50%, #F8EE5A 0%, #FCFBAB 100%)',
            width: '789.49px',
            height: '800.49px',

            borderRadius: '50%',
            left: '-181px',
            top: '536px',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '81px',
            left: '-231px',
            background: `url(${'/img/tutorial/home_trade.png'}) no-repeat`,
            height: '700px',
            width: '200%',
            zIndex: 999,
          }}
        />
      </Box>
    </Box>
  );
}
function StepFive() {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return matches ? (
    <Box sx={{ height: 1, position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '-67px',
          left: '-300px',
          background: `url(${'/img/tutorial/step-4.png'}) no-repeat`,
          height: { lg: '1200px', xl: '1600px' },
          width: '100%',
          zIndex: -1,
          backgroundSize: '68%',
        }}
      />
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignitems: 'center',
          height: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingLeft: { lg: '700px', xl: '1200px' } }}>
          <Typography variant='h1b' sx={{ fontSize: '52px' }}>
            {t('page.TutorialPage.step4.title')}
          </Typography>
          <Typography variant='h4' sx={{ width: '500px', fontSize: '22px' }}>
            {t('page.TutorialPage.step4.description')}
          </Typography>
        </Box>
      </Container>
    </Box>
  ) : (
    <Box
      sx={{
        padding: '20px 20px 20px 20px',

        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        flex: 1,
        gap: '20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography variant='h1b'>{t('page.TutorialPage.step4.title')}</Typography>
        <Typography variant='h4'>{t('page.TutorialPage.step4.description')}</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            position: 'absolute',
            top: '150px',
            left: '-40px',
            background: `url(${'/img/tutorial/support.png'}) no-repeat`,
            backgroundRepeat: 'no-repeat',
            height: '800px',
            width: '200%',
            zIndex: 999,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            background: ' radial-gradient(55.07% 55.07% at 50% 50%, #F8EE5A 0%, #FCFBAB 100%)',
            width: '789.49px',
            height: '800.49px',
            zIndex: 100,

            borderRadius: '50%',
            left: '-181px',
            top: '436px',
          }}
        />
      </Box>
    </Box>
  );
}
function StepSix() {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return matches ? (
    <Box sx={{ height: 1, position: 'relative' }}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignitems: 'center',
          height: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant='h1b' sx={{ fontSize: '52px' }}>
            {t('page.TutorialPage.step5.title')}
          </Typography>
          <Typography variant='h4' sx={{ width: '500px', fontSize: '22px' }}>
            {t('page.TutorialPage.step5.description')}
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          top: '-71px',
          right: { lg: '-567px', xl: '-1300px' },
          background: `url(${'/img/tutorial/step-5.png'}) no-repeat`,
          height: { lg: '1200px', xl: '1600px' },
          width: '100%',
          zIndex: -1,
          backgroundSize: { xl: '68%', lg: '85%' },
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        padding: '20px 20px 20px 20px',

        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        flex: 1,
        gap: '20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography variant='h1b'>{t('page.TutorialPage.step5.title')}</Typography>
        <Typography variant='h4'>{t('page.TutorialPage.step5.description')}</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            position: 'absolute',
            top: '160px',
            left: '-156px',
            background: `url(${'/img/tutorial/how_work.png'}) no-repeat`,
            height: '700px',
            width: '200%',
            zIndex: 999,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            background: ' radial-gradient(55.07% 55.07% at 50% 50%, #F8EE5A 0%, #FCFBAB 100%)',
            width: '789.49px',
            height: '800.49px',

            borderRadius: '50%',
            left: '-181px',
            top: '406px',
          }}
        />
      </Box>
    </Box>
  );
}
function StepFinish() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return (
    <Container maxWidth='sm' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 1 }}>
      <Box
        sx={{
          padding: '20px 20px 20px 20px',

          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: 1,
          gap: '20px',
        }}
      >
        <SvgIcon sx={{ width: { xs: '93px', md: '120px' }, height: { xs: '93px', md: '120px' } }} component={Logo} />
        <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
          <Typography variant='h1b' sx={{ fontSize: { md: '52px', xs: '32px' } }}>
            {t('page.TutorialPage.step6.title')}
          </Typography>
          <Typography variant='h4'>{t('page.TutorialPage.step6.description')}</Typography>
          {!matches && (
            <Button
              fullWidth
              variant='contained'
              size='large'
              onClick={() => {
                localStorage.setItem(LS_TUTORIAL, 'true');
                navigate('/');
              }}
              sx={{ marginTop: '17px' }}
            >
              {t('page.TutorialPage.step6.action')}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default function TutorialPage() {
  const { t } = useTranslation();
  const [activeStep, goNext] = React.useState(0);
  const theme = useTheme();

  const getStep = (step: number) => {
    switch (step) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepThree />;
      case 3:
        return <StepFour />;
      case 4:
        return <StepFive />;
      case 5:
        return <StepSix />;
      case 6:
        return <StepFinish />;

      default:
        return null;
    }
  };

  const currentStep = useMemo(() => {
    return getStep(activeStep);
  }, [activeStep]);

  const navigate = useNavigate();

  if (isMobile) {
    return <Navigate to='/onboarding/progress' replace />;
  }

  return (
    <ContainerLayout
      title={t('helmet:AgentHomePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentHomePage.description') }]}
      sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      noContainer
      header={{
        xs: (
          <Fragment>
            <Box
              sx={{
                paddingTop: '16px',
                paddingBottom: '16px',
                paddingRight: '16px',
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                component='span'
                sx={{ color: theme.palette.text.secondary }}
                onClick={() => {
                  localStorage.setItem(LS_TUTORIAL, 'true');
                  navigate(PATH_HOME);
                }}
              >
                {t('page.TutorialPage.skip')}
              </Button>
            </Box>
            <Steps activeId={activeStep} stepWidth={'50px'} />
          </Fragment>
        ),
        lg: (
          <Box
            sx={{
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingRight: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              component='span'
              sx={{ color: theme.palette.text.secondary }}
              onClick={() => {
                localStorage.setItem(LS_TUTORIAL, 'true');
                navigate(PATH_HOME);
              }}
            >
              {t('page.TutorialPage.skip')}
            </Button>
          </Box>
        ),
      }}
    >
      <Box sx={{ paddingTop: '16px', flexGrow: 1 }}>{currentStep}</Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: { xs: 0, lg: '24px' },
          marginBottom: '50px',
        }}
      >
        {activeStep < 7 && (
          <Button
            variant='contained'
            size={isDesktop ? 'superlarge' : 'large'}
            color={activeStep > 0 && activeStep < 4 && !isDesktop ? 'secondary' : 'primary'}
            sx={{
              zIndex: 9999,
              width: { md: '320px', xs: '90%', xl: '500px' },
              fontWeight: 400,
              display: { xs: activeStep === 6 ? 'none' : 'block', lg: 'block' },
            }}
            onClick={() => {
              if (activeStep === 6) {
                localStorage.setItem(LS_TUTORIAL, 'true');
                navigate('/');
                return;
              }
              goNext(activeStep + 1);
            }}
          >
            {t('page.TutorialPage.step0.action')}
          </Button>
        )}
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <Steps activeId={activeStep} stepWidth={'40px'} />
        </Box>
      </Box>
    </ContainerLayout>
  );
}
