import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SuccessTemplate = ({ onNextClick, icon, title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        padding: '0 20px',
      }}
    >
      <Box sx={{ padding: '30px 0' }}>
        <SvgIcon sx={{ width: 1, fontSize: 256, marginBottom: '24px' }} component={icon} />
        <Typography textAlign='center' sx={{ display: 'block', marginBottom: '16px' }} variant='h1b'>
          {title}
        </Typography>
        <Typography textAlign='center' sx={{ display: 'block' }} variant='h4'>
          {subtitle}
        </Typography>
      </Box>
      <Box sx={{ width: { xs: 1, sm: 500 }, textAlign: 'center' }}>
        <Button fullWidth variant='contained' size='large' color='secondary' onClick={() => onNextClick()}>
          {t('interface:base.SuccessTemplate.btnOk')}
        </Button>
      </Box>
    </Box>
  );
};

SuccessTemplate.propTypes = {
  onNextClick: PropTypes.func,
  icon: PropTypes.component,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default SuccessTemplate;
