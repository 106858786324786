import { req } from 'api/http';
import { IScoringParams, IScoringRow } from './scoring.dto';

/**
 * init scoring process
 * @param param0
 * @returns
 */
export const runScoring = ({ uuidEntry, uuidUser, scoringTypes }: IScoringParams) =>
  req(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/scoring`, { method: 'POST', body: { scoringTypes } });

/**
 * get result of scoring request
 * @param param0
 * @returns
 */
export const getScoringResult = ({ uuidEntry, uuidUser }: { uuidEntry: string; uuidUser: string }) =>
  req<any, IScoringRow[]>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/scoring`, { method: 'GET' });
