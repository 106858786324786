import { IExperimentQueryParams } from 'api/services/experiment/experiment.dto';
import { IDocumentService } from './types';
import { deleteDocument, getAllDocuments, getListRequeiredDocuments, putUpdateDocuments } from 'api/services/experiment/experiment.service';
import { IBusinessCreditCompanyUuidDTO } from 'api/services/businessCredit/businessCredit.dto';
import businessCreditService from 'api/services/businessCredit/businessCredit.service';

export const MortgageDocumentService: IDocumentService<IExperimentQueryParams> = {
  requiredDocumentsRequest: getListRequeiredDocuments,
  allDocumentsRequest: getAllDocuments,
  deleteDocumentRequest: deleteDocument,
  putDocumentRequest: putUpdateDocuments,
  deletePassportRequest: putUpdateDocuments,
  uploadDocument: function (_params: IExperimentQueryParams, _payload: File): Promise<any> {
    throw new Error('Function not implemented.');
  },
};

export const MsbDocumentService: IDocumentService<IBusinessCreditCompanyUuidDTO> = {
  requiredDocumentsRequest: businessCreditService.getRequeiredDocuments as any,
  allDocumentsRequest: businessCreditService.getDocuments as any,
  deleteDocumentRequest: businessCreditService.deleteDocument,
  putDocumentRequest: businessCreditService.putUpdateDocument,

  deletePassportRequest: businessCreditService.deletePassport,
  uploadDocument: businessCreditService.postUploadDocument,
};
