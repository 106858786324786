import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup } from '@mui/material';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'components/base/DatePickerInput';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IFillingQuestionnaireDTO } from 'api/services/experiment/experiment.dto';

export default function FamilyExperimentForm({ hookForm }: { hookForm: UseFormReturn<IFillingQuestionnaireDTO> }) {
  const { t } = useTranslation();

  const haveFamilyChildren = hookForm.watch('haveFamilyChildren');
  const isMarried = hookForm.watch('isMarried');
  //@ts-ignore
  const { fields, append, remove } = useFieldArray({ control: hookForm.control, name: 'childrenBirthDate' });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h3' sx={{ fontSize: '22px', fontWeight: '700' }}>
          Семья
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="isMarried"
          control={hookForm.control}
          render={({ field, fieldState }) => (
            <FormControl error={!!fieldState.error}>
              <FormLabel id={field.name} sx={{ typography: 'h4m', color: 'common.black' }}>
                Вы в браке ?
              </FormLabel>
              <FormHelperText>{t(`${fieldState.error?.message || ''}`)}</FormHelperText>
              <RadioGroup row {...field} aria-labelledby={field.name} value={`${field.value}`} defaultValue={`${field.value}`}>
                <FormControlLabel value="yes" control={<Radio inputProps={{ ['data-testid']: `${field.name}.yes` } as any} />} label="Да" />
                <FormControlLabel value="no" control={<Radio inputProps={{ ['data-testid']: `${field.name}.no` } as any} />} label="Нет" />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
      {(isMarried as unknown as string) === 'yes' && (
        <Grid item xs={12}>
          <Controller
            name="marriageContractStatus"
            control={hookForm.control}
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error}>
                <FormLabel id={field.name} sx={{ typography: 'h4m', color: 'common.black' }}>
                  У вас есть брачный договор ?
                </FormLabel>
                <FormHelperText>{t(`${fieldState.error?.message || ''}`)}</FormHelperText>
                <RadioGroup row {...field} aria-labelledby={field.name} value={`${field.value}`} defaultValue={`${field.value}`}>
                  <FormControlLabel
                    value="exist"
                    control={<Radio inputProps={{ ['data-testid']: `${field.name}.exist` } as any} />}
                    label="Да"
                  />
                  <FormControlLabel
                    value="missing"
                    control={<Radio inputProps={{ ['data-testid']: `${field.name}.missing` } as any} />}
                    label="Нет"
                  />
                  <FormControlLabel
                    value="planned"
                    control={<Radio inputProps={{ ['data-testid']: `${field.name}.planned` } as any} />}
                    label="Планируется"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Controller
          name="haveFamilyChildren"
          control={hookForm.control}
          render={({ field }) => (
            <FormControl>
              <FormLabel id={field.name} sx={{ typography: 'h4m', color: 'common.black' }}>
                У вас есть несовершеннолетние дети?
              </FormLabel>
              <RadioGroup row {...field} aria-labelledby={field.name} value={`${field.value}`} defaultValue={`${field.value}`}>
                <FormControlLabel value="yes" control={<Radio inputProps={{ ['data-testid']: `${field.name}.yes` } as any} />} label="Да" />
                <FormControlLabel value="no" control={<Radio inputProps={{ ['data-testid']: `${field.name}.no` } as any} />} label="Нет" />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
      {(haveFamilyChildren as unknown as string) === 'yes' && (
        <>
          {fields.map((_child, index) => (
            <>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography>Ребенок {index + 1}</Typography>
                <IconButton
                  size='small'
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={`childrenBirthDate.${index}`}
                  control={hookForm.control}
                  render={({ field }) => <DatePickerInput label={'Дата рождения'} {...field} />}
                />
              </Grid>
            </>
          ))}
          <Grid item xs={12}>
            <Button
              variant='outlined'
              size="small"
              onClick={() => {
                append({});
              }}
            >
              Добавить ребенка
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
