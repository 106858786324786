import { Box, Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { RealEstateEntity } from 'api/services/realEstate/realEstate.interface';
import { getRealEstate } from 'api/services/realEstate/realEstate.service';
import ErrorMessage from 'components/base/ErrorMessage';
import { RadioChoice } from 'pages/Client/Mortgage/Entry/Insurance/components/RadioChoice';
import { AccordionBeneficiary } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/components/AccordionBeneficiary';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function MortgageEntryInsurancePropertyForm() {
  const [data, setData] = useState<RealEstateEntity>();
  const [error, setError] = useState<ApiError>();

  const { uuidEntry } = useParams();

  const prefix = `interface:page.MortgageEntryInsurancePage.InsurancePropertyForm`;

  const fetchData = async () => {
    try {
      const data = await getRealEstate({ uuidEntry });
      setData(data.body);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (data === undefined) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  const { insuranceQuestionnaire } = data;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <AccordionBeneficiary />
      <RadioChoice defaultValue={insuranceQuestionnaire?.timberFloor} prefix={prefix} label={'property.timberFloor'} />
      <RadioChoice
        defaultValue={insuranceQuestionnaire?.unregisteredRebuilding}
        prefix={prefix}
        label={'property.unregisteredRebuilding'}
      />
      <RadioChoice defaultValue={insuranceQuestionnaire?.fireSafety} prefix={prefix} label={'property.fireSafety'} />
      <RadioChoice defaultValue={insuranceQuestionnaire?.safetyDevice} prefix={prefix} label={'property.safetyDevice'} />
      <RadioChoice defaultValue={insuranceQuestionnaire?.leasing} prefix={prefix} label={'property.leasing'} />
      <RadioChoice defaultValue={insuranceQuestionnaire?.policyAnotherCompany} prefix={prefix} label={'property.policyAnotherCompany'} />
      <RadioChoice
        defaultValue={insuranceQuestionnaire?.requestToOtherInsCompany}
        prefix={prefix}
        label={'property.requestToOtherInsCompany'}
      />
      <RadioChoice defaultValue={insuranceQuestionnaire?.damage} prefix={prefix} label={'property.damage'} />
    </Box>
  );
}
