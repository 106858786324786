import { Box, Button } from '@mui/material';
import { getForm, postForm } from 'api/services/form';
import { ESupportedForms } from 'api/services/form.dto';
import { EExperimentName } from 'api/services/user.dto';
import BaseFormGenerator from 'components/base/FormGenerator';
import { buildValues, getFlatKeyValue, getObjValues, setNullForDepended } from 'components/base/FormGenerator/utils';
import UploadPassport from 'components/base/UploadPassport';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useFetch from 'hooks/useFetch';
import { useResolver } from 'hooks/useResolver';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY, PATH_AGENT_INSURANCE_PROPERTY_CLIENT } from 'routes/paths';

// FORM_MODE
// export enum EFormMode {
//   CREATE = 'create-agent-client',
//   UPDATE = 'update-agent-client',
// }

export type TLocationParams = {
  uuidProfile?: string;
};

// TODO: Refactor atomic components
// TODO: Error processing & request lifecycle
// TODO: Need refactoring with i18n
const AgentClientFormPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuidProfile } = useParams<keyof TLocationParams>() as TLocationParams;

  const { isSuccess, data: scheme } = useFetch(() =>
    getForm(ESupportedForms.agentInsurance, {
      mode: uuidProfile ? 'fill' : undefined,
      uuidProfile,
    }),
  );
  const validator = useResolver(ESupportedForms.agentInsurance, scheme);
  const { hasExperiment } = useAuth();
  const hookForm = useForm({ defaultValues: {}, resolver: validator.resolver, mode: 'onBlur', reValidateMode: 'onBlur' });

  useEffect(() => {
    if (isSuccess) hookForm.reset(buildValues(scheme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // TODO: Rewrite this ugly mapper
  const handlePassportOCR = useCallback(
    (doc) => {
      const transformData = (dateStr: string): string => {
        const [day, month, year] = dateStr.split('.');
        return `${year}-${month}-${day}`;
      };
      const update = {
        ...hookForm.getValues(),
        'agent-insurance': {
          ..._.get(hookForm.getValues(), 'agent-insurance', {}),
          'add-client': {
            ..._.get(hookForm.getValues(), 'agent-insurance.add-client', {}),
            passport: {
              ..._.get(hookForm.getValues(), 'agent-insurance.add-client.passport', {}),
              docNo: (doc?.data?.seriesNumber || '').split(' ')[1],
              docSeries: (doc?.data?.seriesNumber || '').split(' ')[0],
              issueDate: transformData(doc?.data?.issueDate),
              issueUnit: doc?.data?.issueUnit,
              issuedBy: doc?.data?.issueBy,
            },
            insurant: {
              ..._.get(hookForm.getValues(), 'agent-insurance.add-client.insurant', {}),
              birthDate: transformData(doc?.data?.birthDate),
              birthPlace: doc?.data?.birthPlace,
              gender: doc?.data?.gender,
              firstname: doc?.data?.firstname,
              lastname: doc?.data?.lastname,
              patronymic: doc?.data?.patronymic,
            },
          },
        },
      };
      hookForm.reset(update);
    },
    [hookForm],
  );

  const saveForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cb: any): void => {
      // TODO: Types
      // TODO: Error processing
      const data = setNullForDepended(getFlatKeyValue(getObjValues(hookForm.getValues())), scheme, [ESupportedForms.agentInsurance]);
      const hasErrors = Object.keys(hookForm.formState.errors);
      if (hasErrors.length === 0) {
        postForm(ESupportedForms.agentInsurance, {
          data: validator.format(data),
          options: { uuidProfile },
        }).then((res) => cb(res));
      }
    },
    [hookForm, uuidProfile],
  );

  const handlePrimaryBtnClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveForm((res: any) => {
      navigate(`${PATH_AGENT_INSURANCE_PROPERTY}/all?uuidClient=${res?.body?.uuidProfile}`);
    });
  }, [navigate, saveForm]);

  const handleSecondaryBtnClick = useCallback(() => {
    saveForm(() => {
      navigate(`${PATH_AGENT_INSURANCE_PROPERTY_CLIENT}`);
    });
  }, [navigate, saveForm]);

  return (
    <Page
      title={t('helmet:AgentClientFormPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentClientFormPage.description') }]}
      header={{ title: t('interface:page.AgentClientFormPage.headerTitle') }}
      sx={{ height: 'none', padding: '20px', gap: 4 }}
    >
      {/* Passport OCR */}
      {hasExperiment(EExperimentName.ocr) && <UploadPassport onChange={handlePassportOCR} />}
      {/* Form */}
      {isSuccess && scheme && <BaseFormGenerator scheme={scheme} hookForm={hookForm} path={[]} />}
      {/* Form controlls */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button onClick={handlePrimaryBtnClick} fullWidth variant='contained' size='large' data-testid={'saveAndGotoProductsBtn'}>
          {t('interface:page.AgentClientFormPage.saveAndGotoProductsBtn')}
        </Button>
        <Button
          onClick={handleSecondaryBtnClick}
          fullWidth
          variant='contained'
          color='secondary'
          size='large'
          data-testid={'saveAndContinueBtn'}
        >
          {t('interface:page.AgentClientFormPage.saveAndContinueBtn')}
        </Button>
      </Box>
    </Page>
  );
};

export default AgentClientFormPage;
