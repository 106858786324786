import { entryGet, entryList, entryProgress, postCancelPropose, postSelectPropose, putProposeAccept } from 'api/services/mortgage';
import {
  IMortgageEntryEntity,
  IMortgageEntryProgress,
  IMortgageEntryPropose,
  IMortgageEntryProposeDecision,
} from 'api/services/mortgage.dto';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export type ProposeHandler<T> = (propose: IMortgageEntryPropose<unknown> | null) => T;

interface MortageEntryContextProps {
  loadProgress: (uuid: string) => Promise<void>;
  loading: boolean;
  error: string | null;
  entry: IMortgageEntryEntity | null;
  entries: IMortgageEntryEntity[];
  progress: IMortgageEntryProgress | null;
  calcStepProgress: (step: keyof IMortgageEntryProgress) => number;
  uuid: string;
  proposes: Array<IMortgageEntryPropose<unknown>> | null;
  selectedPropose: IMortgageEntryPropose<unknown> | null;
  selectPropose: ProposeHandler<void>;
  acceptProposeRequest: ProposeHandler<Promise<boolean>>;
  selectProposeRequest: ProposeHandler<Promise<boolean>>;
  cancelProposeRequest: ProposeHandler<Promise<boolean>>;
  handleChangeProposeRequest: ProposeHandler<Promise<boolean>>;
  primaryPropose?: Partial<IMortgageEntryPropose> | null;
  loadEntries: ProposeHandler<void>;
  getEntryRequest: () => Promise<IMortgageEntryEntity | null>;
}

export type TLocationParams = {
  uuid: string;
  uuidEntry?: string;
};

const MortageEntryContext = createContext<Partial<MortageEntryContextProps>>({});

export default function MortageEntryProvider(props: { children: React.ReactChild }) {
  const [progress, setProgress] = useState<IMortgageEntryProgress | null>(null);
  const [entry, setEntry] = useState<IMortgageEntryEntity | null>(null);
  const [entries, setEntries] = useState<Array<IMortgageEntryEntity>>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { uuid, uuidEntry } = useParams<keyof TLocationParams>() as TLocationParams;
  const [selectedPropose, selectPropose] = useState<IMortgageEntryPropose<unknown> | null>(null);

  const proposes = useMemo(() => entry?.proposes, [entry]);
  const primaryPropose = useMemo(() => entry?.primaryPropose, [entry]);

  const loadEntries = async () => {
    setLoading(true);
    try {
      const res = await entryList();
      const body = res.body;

      if (body) setEntries(body);
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  const loadProgress = useCallback(
    async (uuidEntry?: string) => {
      if (!uuid) {
        return;
      }
      setLoading(true);
      try {
        const res = await entryProgress(uuidEntry || uuid);
        const { body } = res;
        setProgress(body);
      } catch (err) {
        console.log(err);
        setError('logic.badConnection');
      } finally {
        setLoading(false);
      }
    },
    [uuid],
  );

  const getEntryRequest = useCallback(async () => {
    let response: IMortgageEntryEntity | null = null;
    if (!uuid && !uuidEntry) {
      return response;
    }
    setLoading(true);
    try {
      const res = await entryGet(uuid ?? uuidEntry);
      setEntry(res.body);
      response = res.body;
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
    return response;
  }, [uuid]);

  const acceptProposeRequest = async () => {
    let hasError = false;
    setLoading(true);
    try {
      const res = await putProposeAccept(
        uuid,
        entry?.primaryPropose?.uuid as string,
        entry?.primaryPropose?.decision as IMortgageEntryProposeDecision,
      );
      if (res.ok) setEntry(res.body);
    } catch (err) {
      console.log(err);
      hasError = true;
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
    return hasError;
  };

  const selectProposeRequest = async (propose: IMortgageEntryPropose<unknown> | null) => {
    setLoading(true);
    let hasError = false;
    try {
      if (propose) {
        const res = await postSelectPropose(uuid, propose?.uuid);
        if (res.ok) {
          setEntry(res.body);
        } else {
          hasError = true;
        }
      }
    } catch (err) {
      console.log(err);
      setError('logic.badConnection');
      hasError = true;
    } finally {
      setLoading(false);
    }
    return hasError;
  };
  const cancelProposeRequest = async (propose: IMortgageEntryPropose<unknown> | null) => {
    setLoading(true);
    try {
      if (propose) {
        selectPropose(null);
        const res = await postCancelPropose(uuid, propose?.uuid);
        if (res.ok) setEntry(res.body);
      }
    } catch (err) {
      console.log(err);
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
    return false;
  };

  const calcStepProgress = React.useCallback(
    (step: keyof IMortgageEntryProgress): number => {
      if (!progress || !progress[step]) return 0;

      if (step === 'bank') {
        return progress[step].complete;
      }

      const maxSum = Object.values(progress[step]).length * 100;

      const sum = Object.values(progress[step]).reduce<number>((partialSum, a) => partialSum + (a as number), 0);
      const result = (sum / maxSum) * 100;
      return Math.round(result);
    },
    [progress],
  );

  const handleChangeProposeRequest = useCallback(
    async (propose) => {
      let _hasError = false;
      if (primaryPropose) {
        _hasError = await cancelProposeRequest(primaryPropose as IMortgageEntryPropose);
        if (!_hasError) {
          _hasError = await selectProposeRequest(propose);
        }
      } else {
        return true;
      }
      return _hasError;
    },
    [primaryPropose],
  );

  useEffect(() => {
    if (!uuid && !uuidEntry) {
      return;
    }
    if (!progress) loadProgress();

    if (!entry) {
      getEntryRequest();
    }
    if (!entries.length) {
      loadEntries();
    }
  }, [uuid, uuidEntry]);

  return (
    <MortageEntryContext.Provider
      value={{
        primaryPropose,
        entry,
        loadProgress,
        loading,
        error,
        progress,
        calcStepProgress,
        uuid,
        proposes,
        selectedPropose,
        selectPropose,
        handleChangeProposeRequest,
        loadEntries,
        entries,
        getEntryRequest,

        acceptProposeRequest,
        selectProposeRequest,
        cancelProposeRequest,
      }}
    >
      {props.children}
    </MortageEntryContext.Provider>
  );
}

export function useMortgage() {
  const ctx = useContext(MortageEntryContext);
  if (!ctx) {
    throw new Error('MortageEntryContext not provided');
  }
  return ctx;
}
