// TODO: internationalization **URGENT**
import { Box, Chip, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import InsuranceAlfaIcon from 'components/Icons/InsuranceAlfa';
import InsuranceIngosIcon from 'components/Icons/InsuranceIngos';
import InsuranceResoIcon from 'components/Icons/InsuranceReso';
import { useAuth } from 'components/providers/AuthProvider';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { getFullName } from 'utils/format/format';

const companyMock = {
  resoGarant: {
    companyName: 'РЕСО-Гарантия',
    icon: InsuranceResoIcon,
  },
  ingosstrah: {
    companyName: 'Ингосстрах',
    icon: InsuranceIngosIcon,
  },
  alfastrah: {
    companyName: 'АльфаСтрахование',
    icon: InsuranceAlfaIcon,
  },
};

const detailsTags = {
  failing: {
    color: '#0FC2EB',
    title: 'заполнение данных',
  },
  processing: {
    color: '#F4A611',
    title: 'ожидание оплаты',
  },
  paid: {
    color: '#31C918',
    title: 'полис оплачен',
  },
};

const InsurancePoliciesDetails = ({ bounty, client, price, policyNumber, perils = [], status, company, createdAt, condition }) => {
  const auth = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();
  const { address } = client ?? {};
  const { companyName, icon } = companyMock[company] ?? {};
  const { color, title } = detailsTags[status] ?? {};
  const { daysBeforeStart } = condition ?? {};
  const policiesPrice = perils.reduce((acc, { amount }) => amount + acc, 0) / 100;
  const preparedBounty = ((bounty / 100) * price) / 100;
  const bountyBlockVisible = auth?.user?.group === 'agent' && !auth?.user?.company;

  return (
    <>
      <Box sx={{ display: 'flex', marginTop: '24px', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SvgIcon component={icon} sx={{ width: '40px', height: '40px', marginRight: '16px' }} />
          <Typography variant='h5'>{companyName}</Typography>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '24px', justifyContent: 'space-between', alignItems: 'center' }}>
          <Chip
            label={title}
            variant={'outlined'}
            sx={{ color: theme.palette.common.white, backgroundColor: color, border: 'none', fontWeight: 400, fontSize: '14px' }}
          />
          {policyNumber && <Typography variant={'h4b'}>{policyNumber}</Typography>}
        </Box>
        {bountyBlockVisible && (
          <Box
            sx={{
              marginTop: '24px',
              width: 1,
              background: '#F9F8F4',
              borderRadius: '16px',
            }}
          >
            <Stack direction='row' spacing={2} sx={{ padding: '24px' }}>
              <Box>
                <Typography variant='h5' sx={{ mb: '4px' }}>
                  Вознаграждение
                </Typography>
                <Typography variant='h4b'>{utils.format.price(preparedBounty / 100)}</Typography>
              </Box>
              <Box sx={{ color: theme.palette.text.secondary }}>
                <Typography variant='h5' sx={{ mb: '4px' }}>
                  Стоимость
                </Typography>
                <Typography variant='h4b'>{utils.format.price(price / 100)}</Typography>
              </Box>
            </Stack>
          </Box>
        )}
      </Box>
      <Box sx={{ marginTop: '32px' }}>
        <Typography variant={'h2b'}>Детали</Typography>
        <Box sx={{ marginTop: '24px' }}>
          <Box sx={{ paddingBottom: '16px' }}>
            <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
              ФИО страхователя
            </Typography>
            <Typography variant={'h4'}>{getFullName(client ?? {}, 'Имя не указано')}</Typography>
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
              Адрес объекта
            </Typography>
            <Typography variant={'h4'}>{!address ? '—' : address}</Typography>
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
              Общая страховая сумма
            </Typography>
            <Typography variant={'h4'}>{utils.format.price(policiesPrice)}</Typography>
          </Box>
          {perils.map((item) => {
            const { type, amount } = item;
            return (
              <Box sx={{ paddingBottom: '16px' }} key={type}>
                <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
                  Страховая сумма по риску <br /> {`${t(`common:insurance.perils.${type}`)}`}
                </Typography>
                <Typography variant={'h4'}>{utils.format.price(amount / 100)}</Typography>
              </Box>
            );
          })}
          <Box sx={{ paddingBottom: '16px' }}>
            <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
              Дата заключения
            </Typography>
            <Typography variant={'h4'}>{utils.date.unixToDate(createdAt)}</Typography>
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
              Дата начала действия
            </Typography>
            <Typography variant={'h4'}>{utils.date.addDays(createdAt, daysBeforeStart)}</Typography>
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary }}>
              Стоимость
            </Typography>
            <Typography variant={'h4'}>{utils.format.price(price / 100)}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

InsurancePoliciesDetails.propTypes = {
  bounty: PropTypes.number,
  price: PropTypes.number,
  policyNumber: PropTypes.string,
  perils: PropTypes.array,
  status: PropTypes.string,
  company: PropTypes.string,
  createdAt: PropTypes.number,
  condition: PropTypes.object,
  client: PropTypes.object,
};

export default InsurancePoliciesDetails;
