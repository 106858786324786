import { IMortgageEntryProgress } from 'api/services/mortgage.dto';
import { get, isNumber, sum } from 'lodash';
import { FieldPath } from 'react-hook-form';
import utils from 'utils';

export class ProgressUtils {
  constructor(private progress?: IMortgageEntryProgress | null) {}

  /**
   * get progress color by value
   * progress===100 = success.main
   * progress>0 primary.main
   * progress=0 text.secondary
   * @param step
   */
  progressColor = (step: string) => {
    const p = get(this?.progress, step);

    if (isNumber(p)) {
      if (p === 100) return 'success.main';
      if (p > 0) return 'primary.main';
    }
    return 'text.secondary';
  };

  /**
   * get progress value by single field path
   * @param  path
   */
  progressValueField = (path: FieldPath<IMortgageEntryProgress>) => {
    const p = get(this.progress, path, 0);
    if (typeof p === 'string' || typeof p === 'number') return parseInt(`${p}`, 10);
    return 0;
  };

  progressValue = (path: FieldPath<IMortgageEntryProgress>) => {
    const p = get(this?.progress, path);

    if (!p) {
      return 0;
    }
    const values = Object.values(p);

    const res = Math.floor(sum(values) / values.length);

    if (Number.isNaN(res)) {
      return 0;
    } else {
      return Math.floor(sum(values) / values.length);
    }
  };

  getProfileProgressValue = () => {
    const sum =
      this.progress?.profile?.reduce((acc, cur) => {
        return acc + utils.calc.profileProgress(cur.progress);
      }, 0) || 0;
    return Math.floor(sum / (this.progress?.profile?.length || 1));
  };
}
