import BaseSuggestionAddressInput from 'components/base/Suggestion/AddressInput';
import _ from 'lodash';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';

import { FormSchemeData } from '../../form.types';
import { ft } from '../../utils';

interface Props {
  node: FormSchemeData;
  path?: string[];
  hookForm: UseFormReturn<FieldValues, ObjectType>;
}

const FormAddressField = ({ node, hookForm, path = [] }: Props) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  const handleSelectAddress = ({ fullAddress }: { fullAddress: string }) => {
    if (fullAddress) setValue(path.join('.'), fullAddress);
  };

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={({ fieldState, field }) => (
        <BaseSuggestionAddressInput
          error={!!fieldState.error}
          helperText={fieldState?.error?.message}
          id={path.join('.')}
          data-testid={path.join('.')}
          label={t(`${ft(path)}.title`)}
          onSelect={handleSelectAddress}
          value={field?.value}
        />
      )}
    />
  );
};

export default FormAddressField;
