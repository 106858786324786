/* eslint-disable react/prop-types */
import { Button, CircularProgress, SvgIcon, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { recognizePassport } from 'api/services/ocr';
import CloseIcon from 'components/Icons/Close';
import ScanIcon from 'components/Icons/Scan';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const UploadPassport = ({ onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleHidden = () => {
    setHidden(!hidden);
  };

  const handleChange = async (e) => {
    setError(null);
    setLoading(true);
    try {
      const res = await recognizePassport(e.target.files[0]);
      if (res.ok) {
        onChange(res.body);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        background: theme.palette.secondary.main,
        borderRadius: '8px',
      }}
    >
      <Box hidden={hidden} sx={{ padding: '24px 24px 0 24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SvgIcon component={ScanIcon} viewBox='0 0 43 47' sx={{ width: '43px', height: '47px' }} />
          <SvgIcon component={CloseIcon} sx={{ width: '15px', height: '15px' }} onClick={handleHidden} />
        </Box>
        <Typography variant='h3' sx={{ margin: '8px 0 24px', fontSize: '22px', fontWeight: '700' }}>
          {t('interface:base.UploadPassport.title')}
        </Typography>
      </Box>
      <Box hidden={hidden}>
        <Button
          sx={{
            marginBottom: '0px',
            width: 1,
          }}
          disabled={loading}
          variant='contained'
          size='large'
          component='label'
        >
          {loading ? <CircularProgress size={24} /> : t('interface:base.UploadPassport.btnScan')}
          <input onChange={handleChange} type='file' accept='image/*' hidden data-testid={'base.UploadPassport.btnScan'} />
        </Button>

        {error && (
          <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
            {t(`errors:${error}`)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UploadPassport;
