import { Button, Skeleton } from '@mui/material';
import { useGetProgressQuery } from 'api/services/mortgage/mortage.queries';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import { EUserRole } from 'api/services/user.dto';
import BannerGosuslugi from 'components/base/BannerGosuslugi';
import BaseButtonBack from 'components/base/Button/Back';
import ErrorMessage from 'components/base/ErrorMessage';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { Protectable } from 'components/providers/AccessProviders/MortgageAccessProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { Accessible } from 'components/providers/RoleAccessProvider';
import useTypedParams from 'hooks/useTypedParams';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_HOME } from 'routes/paths';

import ProgressContainer from './components/ProgressContainer';
import SmsGreet from './components/SmsGreet';
import { ChangeClientManager } from './components/ChangeClientManager';
import AddNewUserToEntry from './components/AddNewUserToEntry';

// TODO: Need refactroing
const ClientMortgageProgressPage = () => {
  const navigate = useNavigate();
  const { uuid } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS>();
  const { data: progress, isLoading, error, refetch } = useGetProgressQuery(uuid);
  const [isManagersListModalOpen, setManagersListModalOpen] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth();

  return (
    <ContainerLayout
      maxWidth="xl"
      title={t('helmet:ClientMortgageProgressPage.title')}
      meta={[
        {
          name: 'description',
          content: t('helmet:ClientMortgageProgressPage.description'),
        },
      ]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.ClientMortgageProgressPage.${isLoading ? 'loadingLabel' : 'title'}`)}
            onBackEvent={() => {
              navigate(PATH_HOME);
              return false;
            }}
          />
        ),
        lg: (
          <TopMenu
            breadcrumbs={[
              {
                title: t('interface:page.ClientMortgageProgressPage.routes.home'),
                href: PATH_HOME,
              },
              {
                title: t(`interface:page.ClientMortgageProgressPage.${isLoading ? 'loadingLabel' : 'title'}`),
                activeLink: true,
              },
            ]}
          />
        ),
      }}
      sx={{
        justifyContent: 'space-evently',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingBottom: '40px',
      }}
    >
      {progress && (
        <Accessible requireRoles={[EUserRole.manager, EUserRole.admin, EUserRole.agent]}>
          <SmsGreet progress={progress} />
        </Accessible>
      )}

      <Accessible requireRoles={[EUserRole.manager, EUserRole.admin, EUserRole.developer]}>
        <>
          <Button
            variant='contained'
            size='large'
            onClick={() => {
              setManagersListModalOpen(true);
            }}
            fullWidth
          >
            {t('interface:page.ChangeClientManager.button')}
          </Button>
          <ChangeClientManager
            uuidEntry={uuid}
            isOpen={isManagersListModalOpen}
            onClose={() => setManagersListModalOpen(false)}
            isUuidRequest={true}
          />
        </>
      </Accessible>

      <Accessible requireRoles={[EUserRole.manager, EUserRole.admin, EUserRole.agent, EUserRole.developer]}>
        <AddNewUserToEntry refetch={refetch} />
      </Accessible>

      <Protectable optionalRoles={[EMortgageEntryRole.borrower, EMortgageEntryRole.coBorrower, EMortgageEntryRole.guarantor]}>
        <BannerGosuslugi hidden={auth?.hasVerify?.('esiaDigitalProfile') || auth?.hasRole?.(EUserRole.agent)} />
      </Protectable>
      {isLoading && !progress && !auth?.user?.uuid ? (
        <Fragment>
          <Skeleton />
        </Fragment>
      ) : (
        <ProgressContainer refetch={refetch} uuidUser={auth?.user?.uuid} uuidEntry={uuid} progress={progress} />
      )}

      <ErrorMessage error={error} />
    </ContainerLayout>
  );
};

export default ClientMortgageProgressPage;
