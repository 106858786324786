import { Box, Skeleton, Typography } from '@mui/material';
import { ApiError } from 'api/errors';
import { getMortgageInsurance } from 'api/services/insurance/insurance';
import ErrorMessage from 'components/base/ErrorMessage';
import QRCode from 'components/base/QRCode';
import useTypedParams from 'hooks/useTypedParams';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE } from 'routes/paths';

export default function MortgageEntryInsurancePaymentForm() {
  const [link, setLink] = useState<string | null>(null);
  const [error, setError] = useState<ApiError>();
  const { uuidEntry } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE>();
  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      const data = await getMortgageInsurance(uuidEntry!);

      setLink(data.body.primaryPropose.payment.data.url);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsurancePaymentForm';

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch' }}>
      <QRCodeLoaded link={link} />
      <Typography variant={'h3b'} component={'p'} sx={{ textAlign: 'center' }}>
        {t(`${prefix}.send`)}
      </Typography>
    </Box>
  );
}

const QRCodeLoaded = ({ link }: { link: string | null }) => {
  if (link === null) {
    return (
      <Skeleton
        variant='rectangular'
        sx={{
          padding: '24px',
          borderRadius: '48px',
          background: '#F5F5F5',
          minWidth: '248px',
          minHeight: '248px',
          margin: '50px auto 0 auto',
        }}
      />
    );
  }

  return <QRCode url={link} sx={{ margin: '50px auto 0 auto' }} />;
};
