import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, SxProps, TextField } from '@mui/material';
import { ERealEstateCategory, ERealEstateCurrentOwnerType, ERealEstatePropOwnershipType } from 'api/services/realEstate/realEstate.enum';
import { RealEstateFormSchema } from 'api/services/realEstate/realEstate.schema';
import BaseSuggestionAddressInput from 'components/base/Suggestion/AddressInput';
import { Control, Controller, FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

import { useRealEstate } from '../../RealEstateProvider';

const tprefix = 'interface:base.MortgageEntryRealEstateAddObjectForm';

function RadioGroupWrapper({
  name,
  label,
  control,
  optionEnum,
  optionPrefix,
  sx,
  disabled,
  isBoolean,
}: {
  disabled?: boolean;
  isBoolean?: boolean;
  name: FieldPath<RealEstateFormSchema>;
  label: string;
  optionPrefix: string;
  control: Control<RealEstateFormSchema>;
  optionEnum: Record<string, string>;
  sx?: SxProps;
}) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      render={({ field }) => (
        <FormControl sx={{ width: 1 }}>
          <FormLabel sx={{ color: 'text.primary', fontWeight: 500 }}>{label}</FormLabel>
          <RadioGroup
            {...field}
            onChange={(e) => {
              field.onChange(isBoolean ? e.target.value : e.target.value);
            }}
            sx={sx}
          >
            {Object.values(optionEnum).map((category) => (
              <FormControlLabel
                disabled={disabled}
                data-testid={`${field.name}.${category}`}
                key={category}
                value={category}
                control={<Radio />}
                label={t(`${optionPrefix}.${category}`)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

export default function MortgageEntryRealEstateAddObjectForm() {
  const { t } = useTranslation();
  const hookForm = useFormContext<RealEstateFormSchema>();
  const { isFormDisabled } = useRealEstate();
  return (
    <Box>
      <Grid container direction='column' sx={{ display: 'flex', gap: '24px', width: '100%' }}>
        <Grid item>
          <RadioGroupWrapper
            disabled={isFormDisabled}
            control={hookForm.control}
            label={t(`${tprefix}.category`)}
            optionPrefix='common:realEstate.category'
            name='category'
            optionEnum={ERealEstateCategory}
          />
        </Grid>
        <Grid item>
          <RadioGroupWrapper
            disabled={isFormDisabled}
            sx={{ display: 'flex', flexDirection: 'row' }}
            control={hookForm.control}
            label={t(`${tprefix}.propOwnership`)}
            optionPrefix='common:realEstate.propOwnership'
            name='ownership.propOwnershipType'
            optionEnum={ERealEstatePropOwnershipType}
          />
        </Grid>

        <Grid item>
          <RadioGroupWrapper
            disabled={isFormDisabled}
            sx={{ display: 'flex', flexDirection: 'row' }}
            control={hookForm.control}
            label={t(`${tprefix}.owner`)}
            optionPrefix='common:realEstate.owner'
            name='ownership.currentOwnerType'
            optionEnum={ERealEstateCurrentOwnerType}
          />
        </Grid>
        <Grid item>
          <Controller
            name='data.address.fullAddress'
            control={hookForm.control}
            render={({ field }) => {
              return (
                <BaseSuggestionAddressInput
                  disabled={isFormDisabled}
                  data-testid='data.address.fullAddress'
                  fullWidth
                  label={t(`${tprefix}.address`)}
                  onSelect={(address) => {
                    if (address?.fullAddress) {
                      field.onChange(address?.fullAddress);
                    }
                  }}
                  value={field?.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
          <Controller
            name='data.address.floor'
            control={hookForm.control}
            render={({ field }) => {
              return (
                <TextField
                  disabled={isFormDisabled}
                  data-testid={field.name}
                  type='number'
                  fullWidth
                  variant='standard'
                  label={t(`${tprefix}.floor`)}
                  {...field}
                  onChange={(e) => {
                    field.onChange(utils.format.digitNumber(e.target.value));
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
          <Controller
            name='data.rooms'
            control={hookForm.control}
            render={({ field }) => {
              return (
                <TextField
                  disabled={isFormDisabled}
                  data-testid={field.name}
                  type='number'
                  fullWidth
                  variant='standard'
                  label='Кол-во комнат'
                  {...field}
                  onChange={(e) => {
                    field.onChange(utils.format.digitNumber(e.target.value));
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
        </Grid>
        <Grid item sx={{ width: 1 }}>
          <Controller
            name='price'
            control={hookForm.control}
            render={({ field }) => {
              return (
                <NumberFormat
                  disabled={isFormDisabled}
                  data-testid={field.name}
                  thousandSeparator=' '
                  suffix=' ₽'
                  fullWidth
                  customInput={TextField}
                  variant='standard'
                  label={t(`${tprefix}.price`)}
                  helperText={t(`${tprefix}.priceHelper`)}
                  value={field.value}
                  onChange={(e: ObjectType) => {
                    field.onChange(utils.format.digitNumber(e.target.value));
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
        </Grid>
        <Grid item>
          <RadioGroupWrapper
            disabled={isFormDisabled}
            isBoolean
            sx={{ display: 'flex', flexDirection: 'row' }}
            control={hookForm.control}
            label={t(`${tprefix}.hasInteriorDecoration`)}
            optionPrefix='common:realEstate.hasInteriorDecoration'
            name='data.hasInteriorDecoration'
            optionEnum={{ yes: 'true', no: 'false' }}
          />
        </Grid>
        <Grid item>
          <RadioGroupWrapper
            disabled={isFormDisabled}
            isBoolean
            sx={{ display: 'flex', flexDirection: 'row' }}
            control={hookForm.control}
            label={t(`${tprefix}.isObjectInOwnership`)}
            optionPrefix='common:realEstate.isObjectInOwnership'
            name='ownership.isObjectInOwnership'
            optionEnum={{ yes: 'true', no: 'false' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
