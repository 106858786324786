import { CustomRadioChoice } from 'pages/Client/Mortgage/Entry/Insurance/components/CustomRadioChoice';
import { InputField } from 'pages/Client/Mortgage/Entry/Insurance/components/InputField';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface RadioChoiceProps {
  prefix: string;
  label: string;
  answers: string[];
  row?: boolean;
  otherLabel: string;
  defaultOtherValue?: string;
  defaultValue?: string;
}

export const CustomRadioChoiceOther: FC<RadioChoiceProps> = ({
  prefix,
  label,
  answers,
  otherLabel,
  defaultValue,
  defaultOtherValue,
  row = false,
}) => {
  const hookForm = useFormContext();

  const otherValue = hookForm.watch(label);

  const answersWithOther = [...answers, 'other'];

  return (
    <>
      <CustomRadioChoice prefix={prefix} answers={answersWithOther} defaultValue={defaultValue} row={row} label={label} />
      {otherValue === 'other' && <InputField prefix={prefix} label={otherLabel} defaultValue={defaultOtherValue} />}
    </>
  );
};
