import { IRequestResult, LS_SESS_KEY, LS_SESS_TOKEN_ACCS, LS_SESS_TOKEN_REFR } from 'api/http';
import { authLoginBySmsCode, authLoginEmail, authLogout, authRegisterBySmsCode, authRequestSms } from 'api/services/auth';
import { IRegisterUser, ISessionTokenPair } from 'api/services/auth.dto';
import { userGetInfo, userUpdateRole } from 'api/services/user';
import {
  EExperimentName,
  EServiceName,
  EUserRole,
  IUserEntity,
  IUserSettingsInsPropOptions,
  IUserSettingsMortgageOptions,
  IUserVerifications,
} from 'api/services/user.dto';
import useSupportChat from 'hooks/useSupportChat';
import { JivoWindow } from 'models/jivoWindow.model';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { PATH_HOME } from 'routes/paths';
import utils from 'utils';
import { genUuid } from 'utils/crypto/uuid';
import { isEwaTender } from 'utils/env/env';
import { ObjectType } from 'utils/iots';

interface AuthProviderProps {
  children: React.ReactElement | JSX.Element | React.ReactChild;
}

type IGetServiceSettingsReturnType = IUserSettingsInsPropOptions & IUserSettingsMortgageOptions;

interface AuthContextProps {
  isAuth: boolean;
  user: IUserEntity | null;
  hasRole: (role: EUserRole) => boolean;
  hasVerify: (name: keyof IUserVerifications) => boolean;
  hasExperiment: (name: EExperimentName) => boolean;
  setUserRole: (value: EUserRole) => Promise<void>;
  requestSmsCode: (phoneNumber: number) => Promise<IRequestResult<null> | null>;
  hasService: (service: EServiceName) => boolean;
  getServiceSettings: (serviceName: string) => IGetServiceSettingsReturnType | unknown;
  signin: (code: string) => Promise<IRequestResult<ObjectType> | null>;
  signInEmail: (data: { email: string; password: string }) => Promise<IRequestResult<ObjectType> | null>;
  getUserInfo: () => void;
  register: (params: IRegisterUser) => Promise<IRequestResult<ISessionTokenPair> | undefined>;
  logout: () => void;
  deviceFingerprint: string | null;
}
const { REACT_APP_YM_ACC, REACT_APP_JC_ACC } = import.meta.env;

export const deviceFingerprintKey = 'device';

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

AuthContext.displayName = 'AuthProvider';

export const hasRedirectToken = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get('redirectToken') === import.meta.env.REACT_APP_REDIRECT_SECRET) {
    localStorage.setItem(LS_SESS_KEY, params.get('redirectToken') as string);
  }
  return !!localStorage.getItem(LS_SESS_KEY);
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const supportChat = useSupportChat();
  const [user, setUser] = useState<IUserEntity | null>(null);
  const [phone, setPhone] = useState<number | null>(null);
  const [isAuthorized, setAuthorized] = useState<boolean>(hasRedirectToken() || isEwaTender());
  const [deviceFingerprint, setDeviceFingerprint] = React.useState<string | null>(null);
  const navigate = useNavigate();

  // ---- common effects ----

  const logout = async () => {
    try {
      await authLogout();

      setUser(null);
      setAuthorized(false);
      localStorage.clear();
      navigate(PATH_HOME);
    } catch (err) {
      //handle error
    }
  };
  React.useEffect(() => {
    let savedFprint = localStorage.getItem(deviceFingerprintKey) as string;
    if (!savedFprint) {
      savedFprint = genUuid();
      localStorage.setItem(deviceFingerprintKey, savedFprint);
    }
    setDeviceFingerprint(savedFprint);
  }, []);

  React.useEffect(() => {
    if (!user) return;

    // Provide user info to Carrot Quest
    if (supportChat.isReady()) {
      supportChat.prepareData(user);
    }

    // Provide user info to Jivo Chat
    if (REACT_APP_JC_ACC) {
      const jivoWindow = window as unknown as JivoWindow;
      if (jivoWindow.jivo_ready) {
        const contactInfo = jivoWindow.jivo_api.getContactInfo() || {};
        if (
          contactInfo.phone !== `+${user.phone}` ||
          contactInfo.email !== user.email ||
          contactInfo.client_name !== `${user.lastname} ${user.firstname}`
        ) {
          jivoWindow.jivo_api.setContactInfo({
            name: `${user.lastname} ${user.firstname}`,
            email: user.email,
            phone: `+${user.phone}`,
          });
          jivoWindow.jivo_api.setCustomData([
            { title: 'Group', content: user.roles.join(',') },
            { title: 'User uuid', content: user.uuid },
          ]);
        }
      }
    }

    // Provide user info to Yandex Metrika
    if (REACT_APP_YM_ACC) {
      ym('userParams', { uuid: user.uuid, email: user.email, name: `${user.lastname} ${user.firstname}`, group: user.roles });
    }
  }, []);

  //----- http effects -----

  const getUserInfo = async () => {
    try {
      const res = await userGetInfo('me');

      if (res.ok) {
        setUser(res.body);
      } else {
        // Something going wrong with response
        logout();
      }
    } catch (err) {
      // TODO: need to change from logout to another action because it can throw user from app because of connection error
      // Something going wrong with connection
      logout();
      throw err;
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      getUserInfo();
    }
  }, [isAuthorized]);

  //---- ------

  const getServiceSettings = (serviceName: string) => {
    const services = user?.settings?.services;
    if (!services) return {};
    const settings = services.find(({ name }) => name === serviceName);
    return settings?.options || ({} as unknown);
  };

  const requestSmsCode = async (phoneNumber: number) => {
    const res = await authRequestSms(phoneNumber);
    if (res.ok) {
      setPhone(phoneNumber);
    }
    return res;

    // TODO: Something going wrong with response
  };

  const signInEmail = async ({ email, password }: { email: string; password: string }) => {
    const res = await authLoginEmail(email, password, deviceFingerprint as string);
    if (res.ok) {
      localStorage.setItem(LS_SESS_KEY, String(utils.date.genUnixTs()));
      if (utils.env.isDev()) {
        localStorage.setItem(LS_SESS_TOKEN_ACCS, res.body.access.token);
        localStorage.setItem(LS_SESS_TOKEN_REFR, res.body.refresh.token);
      }
      await getUserInfo();
      setAuthorized(true);
    }
    return res;
  };
  const signin = async (code: string): Promise<IRequestResult<ObjectType> | null> => {
    // TODO: wrap into try catch
    if (phone && deviceFingerprint) {
      const res = await authLoginBySmsCode(phone, code, deviceFingerprint);
      if (res.ok) {
        localStorage.setItem(LS_SESS_KEY, String(utils.date.genUnixTs()));
        if (utils.env.isDev()) {
          localStorage.setItem(LS_SESS_TOKEN_ACCS, res.body.access.token);
          localStorage.setItem(LS_SESS_TOKEN_REFR, res.body.refresh.token);
        }
        await getUserInfo();
        setAuthorized(true);
      }

      return res;
    }
    return null;
  };

  const setUserRole = async (role: EUserRole) => {
    try {
      await userUpdateRole(role);
      setUser((user) => {
        if (user) {
          user.roles = [...(user?.roles || []).filter((x) => x !== role), role];
          if (user.roles.length > 1) {
            user.roles = user.roles.filter((role) => role !== EUserRole.ghost);
          }
          return user;
        }
        return user;
      });
    } catch (err) {
      console.log('handle errror');
    }
  };
  const register = async ({
    roles,
    email,
    phone,
    password,
    lastname,
    firstname,
    patronymic,
    code,
    autoLogin = true,
    inviteLink,
    secret,
  }: IRegisterUser) => {
    const res = await authRegisterBySmsCode({
      roles,
      email,
      phone,
      password,
      lastname,
      firstname,
      patronymic,
      code,
      autoLogin,
      inviteLink,
      clientFingerprint: deviceFingerprint as string,
      secret,
    });

    if (!res.ok) return res;

    if (autoLogin) {
      localStorage.setItem(LS_SESS_KEY, String(utils.date.genUnixTs()));
      if (utils.env.isDev()) {
        localStorage.setItem(LS_SESS_TOKEN_ACCS, res.body.access.token);
        localStorage.setItem(LS_SESS_TOKEN_REFR, res.body.refresh.token);
      }
      await getUserInfo();
      setAuthorized(true);
      return res;
    }
    return res;

    // TODO: wrap into try catch
  };

  const hasRole = (role: EUserRole): boolean => !!user && user.roles.includes(role);
  const hasVerify = (name: keyof IUserVerifications) => !!user && !!user?.verifications?.[name];
  const hasExperiment = (name: EExperimentName) => !!user?.settings?.experiments?.find((e) => e.name === name);
  const hasService = useCallback(
    (...services: EServiceName[]) => {
      if (!services || services.length) {
        return true;
      }

      if (!user) {
        return false;
      }
      const userServices = user?.settings?.services?.filter((x) => {
        return services.includes(x.name);
      });
      return (userServices?.length || 0) > 0;
    },
    [user],
  );

  const value: AuthContextProps = {
    isAuth: isAuthorized,
    user,
    hasRole,
    hasVerify,
    hasService,
    hasExperiment,
    setUserRole,
    getUserInfo,
    requestSmsCode,
    getServiceSettings,
    signin,
    signInEmail,
    register,
    logout,
    deviceFingerprint,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext) as Required<AuthContextProps>;
};

export default AuthProvider;
