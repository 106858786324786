/* eslint-disable */
import { createSvgIcon } from '@mui/material';

const RealEstateOverlayIcon = createSvgIcon(
  <svg viewBox='0 0 160 160' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z'
      fill='#F8E11A'
    />
    <g filter='url(#filter0_d_9601_120772)'>
      <path
        d='M80.3424 93.1179C80.2285 93.1194 80.1143 93.1202 80 93.1202C79.8857 93.1202 79.7715 93.1194 79.6576 93.1179C71.8706 93.1781 64.2299 95.2893 57.4827 99.2483C52.6069 102.109 48.32 105.863 44.8287 110.301C42.9324 112.711 44.8057 116 47.8724 116H80L112.128 116C115.194 116 117.068 112.711 115.171 110.3C111.68 105.863 107.393 102.109 102.517 99.2482C95.7701 95.2893 88.1294 93.1781 80.3424 93.1179Z'
        fill='url(#paint0_radial_9601_120772)'
        shapeRendering='crispEdges'
      />
      <path
        d='M105.824 69.9412C105.824 84.2681 94.2624 95.8824 80 95.8824C65.7376 95.8824 54.1757 84.2681 54.1757 69.9412C54.1757 55.6143 65.7376 44 80 44C94.2624 44 105.824 55.6143 105.824 69.9412Z'
        fill='url(#paint1_radial_9601_120772)'
        shapeRendering='crispEdges'
      />
    </g>
    <path
      d='M139.682 159.995C150.903 159.995 160 150.899 160 139.678C160 128.457 150.903 119.36 139.682 119.36C128.461 119.36 119.365 128.457 119.365 139.678C119.365 150.899 128.461 159.995 139.682 159.995Z'
      fill='url(#paint2_radial_9601_120772)'
    />
    <g filter='url(#filter1_d_9601_120772)'>
      <path
        d='M149.841 132.057L136.112 147.295L128.254 138.739'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_9601_120772'
        x='36.0659'
        y='33'
        width='103.868'
        height='104'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx='8' dy='5' />
        <feGaussianBlur stdDeviation='8' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9601_120772' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9601_120772' result='shape' />
      </filter>
      <filter
        id='filter1_d_9601_120772'
        x='123.254'
        y='127.057'
        width='33.5874'
        height='27.2381'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx='1' dy='1' />
        <feGaussianBlur stdDeviation='2.5' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9601_120772' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9601_120772' result='shape' />
      </filter>
      <radialGradient
        id='paint0_radial_9601_120772'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(51.3019 54.5462) rotate(57.4387) scale(125.17 125.696)'
      >
        <stop offset='0.416533' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_9601_120772'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(51.3019 54.5462) rotate(57.4387) scale(125.17 125.696)'
      >
        <stop offset='0.416533' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint2_radial_9601_120772'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(132.294 132.659) rotate(53.924) scale(31.9938)'
      >
        <stop stopColor='#44E529' />
        <stop offset='1' stopColor='#26B70E' />
      </radialGradient>
    </defs>
  </svg>,
  'RealEstateOverlayIcon',
);
export default RealEstateOverlayIcon;
