import { Button, Modal, SvgIcon, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import Spin from 'components/Icons/Spin';
import SuccessIcon from 'components/Icons/Success';
import BottomPopover from 'components/base/BottomPopover';
import useSupportChat from 'hooks/useSupportChat';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ProposeModalContextProps {
  handleClose?: () => void;
  handleModal?: (type: ProposeModalTypes, action?: (status: boolean) => void) => void;
}

const ProposeModalContext = createContext<ProposeModalContextProps>({});

export enum ProposeModalTypes {
  None,
  BottomPopover,
  FinalConfirmation, // modal for final confirmation
  CancelPropose,
  Confirmation,
  SelectPropose,
  SuccessCancelPropose,
  SuccessChangePropose,
  ChangePropose,
  CallbackPropose,
}

export default function ProposeModalProvider({ children }: { children: React.ReactChild }) {
  const { t } = useTranslation();
  const supportChat = useSupportChat();
  const [selectedModal, showModal] = useState<ProposeModalTypes>(ProposeModalTypes.None);
  const [method, setMethod] = useState<{ action?: (status: boolean) => void }>();

  const handleModal = (modalType: ProposeModalTypes, action?: (status: boolean) => void) => {
    showModal(modalType);

    setMethod({ action });
  };
  const handleClose = () => {
    showModal(ProposeModalTypes.None);
  };

  const handleExecute = () => {
    handleClose();
    method?.action?.(true);
  };

  const handleSupportClick = () => {
    supportChat.open();
    handleClose();
  };

  const theme = useTheme();
  return (
    <ProposeModalContext.Provider value={{ handleModal, handleClose }}>
      {children}
      {/* Cancel selection */}

      <Modal
        open={selectedModal === ProposeModalTypes.CancelPropose}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.cancelModal.description')}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '24px' }}>
            <Button color='secondary' fullWidth onClick={handleClose} variant='contained' size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.ClientMortgageProposePage.cancelModal.no')}
            </Button>
            <Button fullWidth onClick={handleExecute} variant='contained' size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.ClientMortgageProposePage.cancelModal.yes')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* final confirmation */}
      <Modal
        open={selectedModal === ProposeModalTypes.FinalConfirmation}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Typography variant='h2b' component='h2' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.finalConfirmModal.title')}
          </Typography>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.finalConfirmModal.description')}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '24px' }}>
            <Button color='secondary' fullWidth onClick={handleClose} variant='contained' size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.ClientMortgageProposePage.finalConfirmModal.thinkAbout')}
            </Button>
            <Button fullWidth onClick={handleExecute} variant='contained' size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.ClientMortgageProposePage.finalConfirmModal.accept')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation */}
      <Modal
        open={selectedModal === ProposeModalTypes.Confirmation}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Typography variant='h2b' component='h2' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.supportModal.title')}
          </Typography>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.supportModal.description')}
          </Typography>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.supportModal.subDescription')}
          </Typography>
          <Button fullWidth onClick={handleClose} variant='contained' size='large' data-testid={'btnGetConsultation'}>
            {t('interface:page.HomeClientPage.supportModal.btnOk')}
          </Button>
        </Box>
      </Modal>

      {/* Modal Success contact */}
      <Modal
        open={selectedModal === ProposeModalTypes.CallbackPropose}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <SvgIcon sx={{ width: 1, fontSize: 128, marginBottom: '24px' }} component={SuccessIcon} />
          <Typography variant='h2b' component='h2' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.supportCallback.title')}
          </Typography>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.supportCallback.description')}
          </Typography>

          <Button fullWidth onClick={handleExecute} variant='contained' size='large' data-testid={'btnGetConsultation'}>
            {t('interface:page.ClientMortgageProposePage.supportCallback.btnOk')}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={selectedModal === ProposeModalTypes.SuccessChangePropose}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          {/* //TODO: change icon to arrow right circle */}
          <SvgIcon sx={{ width: 1, fontSize: 128, marginBottom: '24px' }} component={Spin} />
          <Typography variant='h2b' component='h2' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.changeProposeModalSuccess.title')}
          </Typography>
          <Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.changeProposeModalSuccess.description')}
          </Typography>
          <Button fullWidth onClick={handleExecute} variant='contained' size='large' data-testid={'btnGetConsultation'}>
            {t('interface:page.ClientMortgageProposePage.changeProposeModalSuccess.btnOk')}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={selectedModal === ProposeModalTypes.SuccessCancelPropose}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <SvgIcon sx={{ width: 1, fontSize: 128, marginBottom: '24px' }} component={SuccessIcon} />
          <Typography variant='h2b' component='h2' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.cancelModalSuccess.title')}
          </Typography>

          <Button fullWidth onClick={handleExecute} variant='contained' size='large' data-testid={'btnGetConsultation'}>
            {t('interface:page.ClientMortgageProposePage.changeProposeModalSuccess.btnOk')}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={selectedModal === ProposeModalTypes.ChangePropose}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            gap: '16px',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 336,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Typography variant='h4' component='span' sx={{ textAlign: 'center' }}>
            {t('interface:page.ClientMortgageProposePage.chnageProposeModal.title')}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '24px' }}>
            <Button color='secondary' fullWidth onClick={handleClose} variant='contained' size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.ClientMortgageProposePage.chnageProposeModal.cancel')}
            </Button>
            <Button fullWidth onClick={handleExecute} variant='contained' size='large' data-testid={'btnGetConsultation'}>
              {t('interface:page.ClientMortgageProposePage.chnageProposeModal.accept')}
            </Button>
          </Box>
        </Box>
      </Modal>

      <BottomPopover isOpen={selectedModal === ProposeModalTypes.BottomPopover} handleClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '16px' }}>
          <Button variant='contained' size='large' sx={{ width: 1 }} onClick={handleSupportClick}>
            {t('interface:page.ClientMortgageProposePage.bottomPopover.writeInChat')}
          </Button>
          <Button onClick={handleExecute} size='large' sx={{ width: 1, backgroundColor: theme.palette.secondary.main }}>
            {t('interface:page.ClientMortgageProposePage.bottomPopover.callMe')}
          </Button>
        </Box>
      </BottomPopover>
    </ProposeModalContext.Provider>
  );
}

export function useProposeModal() {
  return useContext(ProposeModalContext);
}
