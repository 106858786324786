import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  SvgIcon,
  TextField,
  MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CoborrowerIcon from 'components/Icons/Coborrower';
import { Fragment, useState } from 'react';
import PhoneMaskCustom from 'components/base/PhoneMaskCustom';
import { ApiError } from 'api/errors';
import { IExperimentUserInvite } from 'api/services/experiment/experiment.dto';
import utils from 'utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useTypedParams from 'hooks/useTypedParams';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS } from 'routes/paths';
import { addNewUserToEntry } from 'api/services/mortgage';
import { useSnackbar } from 'notistack';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import CloseIcon from 'components/Icons/Close';

export const inviteFormSchema = z
  .object({
    phone: z.string({ required_error: 'errors:validation.required' }).refine(
      (v) => {
        const value = utils.format.digitString(v) || '';
        return value?.length === 10;
      },
      { message: 'errors:validation.phone' },
    ),
    firstname: z.string({ required_error: 'errors:validation.required' }),
    patronymic: z.string({ required_error: 'errors:validation.required' }).optional(),
    email: z.string({ required_error: 'errors:validation.required' }).email('errors:validation.email').optional().or(z.literal('')),
    lastname: z.string({ required_error: 'errors:validation.required' }),
    entryRole: z.nativeEnum(EMortgageEntryRole),
  })
  .required({
    phone: true,
    firstname: true,
    lastname: true,
    email: true,
    entryRole: true,
  });
type InviteFormSchema = z.infer<typeof inviteFormSchema>;

interface Props {
  refetch: () => void;
}

const AddNewUserToEntry = ({ refetch }: Props) => {
  const [isModalActive, setModalActive] = useState(false);

  return (
    <Box>
      <Button variant="contained" fullWidth onClick={() => setModalActive(true)}>
        {/* {t(desktop ? 'interface:base.BannerGosuslugi.btnAuth' : 'interface:base.BannerGosuslugi.btnAuthMobile')} */}
        Добавить участника заявки
      </Button>
      {isModalActive && (
        <AddNewUserToEntryForm
          isOpen={isModalActive}
          onClose={() => {
            refetch();
            setModalActive(false);
          }}
        />
      )}
    </Box>
  );
};

function AddNewUserToEntryForm({ onClose, isOpen }: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const [, setApiError] = useState<ApiError>();
  const { uuid } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS>();
  const { enqueueSnackbar } = useSnackbar();

  const addNewUser = async (data: IExperimentUserInvite) => {
    await addNewUserToEntry(uuid, {
      ...data,
    });
  };

  const onSubmit = async (values: InviteFormSchema) => {
    try {
      const data: IExperimentUserInvite = {
        entryRole: values.entryRole,
        lastname: values.lastname,
        firstname: values.firstname,
        patronymic: values.patronymic!,
        email: values.email!,
        phone: utils.format.digitNumber(`7${values.phone!}`) as number,
      };
      await addNewUser(data);
      enqueueSnackbar(t('base.addNewUserToEntryRequestSuccess'), { variant: 'info' });
      onClose();
    } catch (err) {
      setApiError(err);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InviteFormSchema>({
    defaultValues: {},
    resolver: zodResolver(inviteFormSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const hasErrors = Object.values(errors).length > 0;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '24px',
        },
      }}
    >
      <Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id='alert-dialog-title'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: '20px',
            }}
          >
            <SvgIcon sx={{ width: 0.3, height: 0.3 }}>
              <CoborrowerIcon />
            </SvgIcon>
            <SvgIcon
              component={CloseIcon}
              sx={{ position: 'absolute', right: '15px', top: '15px', width: '15px', height: '15px', cursor: 'pointer' }}
              onClick={onClose}
            />
            <Typography variant="h3b">Добавьте участника заявки</Typography>
          </DialogTitle>

          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {/* Form */}
            <Grid container direction='row' spacing={5} sx={{ paddingTop: { xs: 0, lg: '40px' } }}>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name="entryRole"
                  render={({ field }) => (
                    <TextField
                      select
                      label={t('interface:page.AuthRegisterPage.Form.profile.role')}
                      id="entryRole"
                      variant='standard'
                      fullWidth
                      required
                      {...field}
                      value={field.value || ''}
                      inputProps={{ ['data-testid']: field.name }}
                    >
                      {Object.values(EMortgageEntryRole)
                        .slice(1, 3)
                        .map((option) => (
                          <MenuItem key={option} value={option} data-testid={option}>
                            {t(`interface:base.UserRole.${option}`)}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='lastname'
                  control={control}
                  rules={{ required: true, maxLength: 20 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      required
                      label={t('interface:page.AuthRegisterPage.Form.profile.lastname')}
                      inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.lastname' }}
                      variant='standard'
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='firstname'
                  control={control}
                  rules={{ required: true, maxLength: 20 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      required
                      label={t('interface:page.AuthRegisterPage.Form.profile.firstname')}
                      inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.firstname' }}
                      variant='standard'
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='patronymic'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={t('interface:page.AuthRegisterPage.Form.profile.patronymic')}
                      inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.patronymic' }}
                      variant='standard'
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='phone'
                  control={control}
                  rules={{ required: true, minLength: 11 }}
                  render={({ field, fieldState }) => (
                    <PhoneMaskCustom
                      required
                      label={t('interface:page.AuthRegisterPage.Form.profile.phoneNumber')}
                      inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.phone' }}
                      variant='standard'
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                      {...field}
                      inputRef={field.ref}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='email'
                  rules={{ required: true }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={t('interface:page.AuthRegisterPage.Form.profile.Email')}
                      inputProps={{ ['data-testid']: 'ClientRegisterForm.Form.profile.email' }}
                      variant='standard'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message as string, { defaultValue: '' })}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button type="submit" variant='contained' fullWidth disabled={hasErrors}>
              {/* Добавить участника */}
              {t('interface:base.AddNewUserToEntry.button')}
            </Button>
          </DialogActions>
        </form>
      </Fragment>
    </Dialog>
  );
}

export default AddNewUserToEntry;
