import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  SvgIcon,
  Box,
  Grid,
  SxProps,
  Button,
  Link,
  Alert,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMemo } from 'react';
import { DocumentProgress, EProfileDocumentType } from 'api/services/experiment/experiment.dto';
import { PassportDocument } from 'hooks/logic/useDocuments/types';
import { useTranslation } from 'react-i18next';
import GosuslugiIcon from 'components/Icons/Gosuslugi';
function getPartCounter(document: unknown) {
  const doc = document as PassportDocument;
  if (!('parts' in doc)) {
    return '';
  }
  return `(${doc.parts.filter((x) => x.isPresent).length}/${doc.parts.length})`;
}

export default function CheckItemDocument({ document }: { document: DocumentProgress }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isSuccess: SxProps = {
    background: theme.palette.common.softGreen,
    color: `${theme.palette.common.green} `,
  };

  const statString = useMemo(() => getPartCounter(document), [document]);

  const isPresent = useMemo(() => {
    if (document.parts) {
      return document.parts.every((x) => x.isPresent);
    }
    return document.isPresent;
  }, [document]);

  return (
    <Accordion
      data-testid={`${document.type}_${document.part || ''}`}
      disableGutters
      elevation={0}
      sx={{
        borderRadius: '8px',
        '&:before': { display: 'none' },
        backgroundColor: theme.palette.secondary.main,
        ...(isPresent ? isSuccess : {}),
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between', width: 1 }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
            <Typography>{t(`common:documents.${document.type}.${document?.quarter ? 'title_quarter' : 'title'}`, document)}</Typography>
            {document.isPresent && <SvgIcon width={64} height={64} component={CheckCircleOutlineIcon} />}
            <Typography sx={{ color: theme.palette.common.softGrey }}>{statString}</Typography>
          </Box>
          {/* <SvgIcon width={64} height={64} component={InfoOutlinedIcon} /> */}
        </Box>
      </AccordionSummary>

      {document.type === EProfileDocumentType.formCertificate && (
        <AccordionDetails>
          <Button
            component='a'
            href={import.meta.env.REACT_APP_INCOME_REPORT}
            target='_blank'
            fullWidth
            sx={{ backgroundColor: '#EAF3FF', color: theme.palette.common.link }}
          >
            Ссылка на скачивание
          </Button>
        </AccordionDetails>
      )}
      {document.type === EProfileDocumentType.pfr && (
        <AccordionDetails>
          <Button
            component='a'
            href={'https://www.gosuslugi.ru/structure/10000002796'}
            target='_blank'
            fullWidth
            sx={{ backgroundColor: '#EAF3FF', color: theme.palette.common.link }}
            endIcon={<GosuslugiIcon />}
          >
            Запросить
          </Button>
        </AccordionDetails>
      )}
      {document.type === EProfileDocumentType.balanceSheet && (
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Alert severity='success' icon={false} sx={{ backgroundColor: 'common.softGreen' }}>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              Загрузите фотографии или сканы всех разворотов паспорта генерального директора/владельца ИП. Каждый разворот должен идти как
              отдельная фотография/файл Это самый важный документ при обращении в любой банк, без него мы не сможем продолжить работу по
              заявке
            </Typography>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              Мы подготовили для вас подробную{' '}
              <Link component='a' href={import.meta.env.REACT_APP_BALANCE_SHEET_DOCUMENTATION} target='_blank'>
                инструкцию по формированию баланса из 1С тут
              </Link>
            </Typography>
          </Alert>
          <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>Допустимые форматы:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Chip label='PDF' variant='outlined' />
          </Box>
        </AccordionDetails>
      )}
      {document.type === EProfileDocumentType.bankStatement && (
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Alert severity='success' icon={false} sx={{ backgroundColor: 'common.softGreen' }}>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              Выписка по расчетному счёту отражает движения по счёту за определённый период, а также итоговый баланс - это обязательный
              документ для обращения во все банки. Вы можете получить выписку, придя в банк с паспортом и картой (договором), или же
              заказать ее в онлайн-кабинете банка
            </Typography>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              <Link component='a' href={import.meta.env.REACT_APP_BANK_STATEMENT_SBER_DOCUMENTATION} target='_blank'>
                Подробная инструкция о том, как выгрузить выписку в Сбере
              </Link>
            </Typography>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              <Link component='a' href={import.meta.env.REACT_APP_BANK_STATEMENT_VTB_DOCUMENTATION} target='_blank'>
                Подробная инструкция о том, как выгрузить выписку в ВТБ
              </Link>
            </Typography>
          </Alert>
          <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>Допустимые форматы:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Chip label='TXT' variant='outlined' />
          </Box>
        </AccordionDetails>
      )}
      {document.type === EProfileDocumentType.accountStatement51 && (
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Alert severity='success' icon={false} sx={{ backgroundColor: 'common.softGreen' }}>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              Счет 51 в бухгалтерском учете — это расчетный регистр. Его используют для отражения денежных поступлений, списаний и переводов
              в рублях через банк. Карточка счета 51 (обязательно в формате txt) используется банками для понимания состояния счета, а так
              же движения средств по нему. Вы можете сформировать ее через 1С или же запросить у бухгалтера
            </Typography>
            <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
              <Link component='a' href={import.meta.env.REACT_APP_ACCOUNT_STATEMENT_51_DOCUMENTATION} target='_blank'>
                Подробная инструкция по формированию карточки счета в 1С
              </Link>
            </Typography>
          </Alert>
          <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>Допустимые форматы:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Chip label='XML' variant='outlined' />
          </Box>
        </AccordionDetails>
      )}
      {document.type === EProfileDocumentType.passport && (
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Grid container gap='16px'>
            {document.parts?.map((part) => (
              <Grid item xs={12} key={part.part}>
                <Box
                  sx={{
                    background: theme.palette.common.white,
                    border: `1px solid ${theme.palette.common.softGrey}`,
                    padding: '8px',
                    borderRadius: '8px',
                    ...(part.isPresent ? isSuccess : { color: theme.palette.text.primary }),
                  }}
                >
                  <Typography>{t(`common:documents.${part.type}.parts.${part.part}`)}</Typography>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Alert severity='success' icon={false} sx={{ backgroundColor: 'common.softGreen' }}>
                <Typography sx={{ fontSize: '14px' }}>
                  Загрузите фотографии или сканы всех разворотов паспорта генерального директора/владельца ИП - отельными фотографиями для
                  каждого разворота, или же pdf-файлом со всеми разворотами. Это самый важный документ при обращении в любой банк, без него
                  мы не сможем продолжить работу по заявке
                </Typography>
              </Alert>
              <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>Допустимые форматы:</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                {/* <Chip label='PDF' variant='outlined' /> */}
                <Chip label='PNG/JPEG' variant='outlined' />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
