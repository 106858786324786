import { LoadingButton } from '@mui/lab';
import { List } from '@mui/material';
import { DocumentSource, IPreloadDocument } from 'api/services/experiment/experiment.dto';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FileDescriptorView from './components/FileCard';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileQueue from './components/FileQueue';
import { ApiError } from 'api/errors';
interface UploaderViewProps {
  fetchState?: boolean;
  files: DocumentSource[];
  onUploadFile(file: File): Promise<void>;
  onUpdateFile(document: DocumentSource, callback: (error: ApiError) => void): Promise<void>;
  onDeleteFile(document: DocumentSource): Promise<void>;
  queue: IPreloadDocument[];
}

export default function UploaderView(props: UploaderViewProps) {
  const { t } = useTranslation();
  const { files, onDeleteFile, onUpdateFile, onUploadFile } = props;

  const [toggledFile] = useState<string>('');

  const handleUploadFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        onUploadFile(file);
      }
    }
    event.target.value = '';
  }, []);

  return (
    <>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {props.queue?.map((queueDocument) => (
          <FileQueue key={queueDocument.uuid} {...queueDocument} />
        ))}
        {files?.map((fileData, index) => (
          <FileDescriptorView
            onDeleteFile={onDeleteFile}
            onUpdateFile={onUpdateFile}
            key={fileData.uuid}
            expanded={fileData.uuid === toggledFile}
            file={fileData}
            index={index}
          />
        ))}
      </List>
      <LoadingButton
        data-testid={'uploadDocuments'}
        disabled={props.fetchState}
        fullWidth
        variant='contained'
        color='secondary'
        component='label'
        loadingPosition='start'
        loading={props.fetchState}
        sx={{ mt: 2 }}
        startIcon={<AttachFileIcon />}
      >
        {t(`interface:base.UploaderView.loaded`, {
          defaultValue: t('interface:base.UploaderView.title'),
        })}
        <input onChange={handleUploadFile} type='file' accept={'*'} hidden />
      </LoadingButton>
    </>
  );
}
