import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import Page from 'components/layouts/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as p from 'routes/paths';
import utils from 'utils';
import { getUtmCookieQuery } from 'utils/cookie/utm';
import { ObjectType } from 'utils/iots';
const {
  REACT_APP_SORE_NASHSTORE,
  REACT_APP_SORE_APPSTORE,
  REACT_APP_SORE_RUSTORE,
  REACT_APP_SORE_GALAXYSTORE,
  REACT_APP_SORE_APPGALLERY,
  REACT_APP_SORE_GOOGLEPLAY,
} = import.meta.env;

const GetAppPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [showStores, setShowStores] = useState(false);
  const [promptInstall, setPromptInstall] = useState<ObjectType>(null);

  // Check iOS
  useEffect(() => {
    if (searchParams.get('force') === '1' && utils.env.isIOS()) {
      window.location.href = REACT_APP_SORE_APPSTORE as string;
    }
  }, []);

  // Check PWA
  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      console.log('we are being triggered :D');
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const handleNotNow = () => {
    navigate(p.PATH_HOME);
  };

  const handleShowStores = () => {
    setShowStores(true);
  };

  const handleInstallPWA = (e: ObjectType) => {
    e.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall?.prompt();
  };

  const handleAppStore = () => {
    window.location.href = `${REACT_APP_SORE_APPSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleRuStore = () => {
    window.location.href = `${REACT_APP_SORE_RUSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleNashStore = () => {
    window.location.href = `${REACT_APP_SORE_NASHSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleGalaxyStore = () => {
    window.location.href = `${REACT_APP_SORE_GALAXYSTORE as string}?${getUtmCookieQuery()}`;
  };

  const handleAppGallery = () => {
    window.location.href = `${REACT_APP_SORE_APPGALLERY as string}?${getUtmCookieQuery()}`;
  };
  const handleGooglePlay = () => {
    window.location.href = `${REACT_APP_SORE_GOOGLEPLAY as string}?${getUtmCookieQuery()}`;
  };

  const Stores: Record<string, JSX.Element> = {
    appStore: <Box component='img' style={{ width: '80%' }} src='/img/getapp/store-appstore.png' alt='' onClick={handleAppStore} />,
    ruStore: <Box component='img' style={{ width: '80%' }} src='/img/getapp/store-rustore.png' alt='' onClick={handleRuStore} />,
    nashStore: <Box component='img' style={{ width: '80%' }} src='/img/getapp/store-nashstore.png' alt='' onClick={handleNashStore} />,
    appGallery: <Box component='img' style={{ width: '80%' }} src='/img/getapp/app-gallery.png' alt='' onClick={handleAppGallery} />,
    galaxyStore: <Box component='img' style={{ width: '80%' }} src='/img/getapp/galaxy-store.png' alt='' onClick={handleGalaxyStore} />,
    googlePlay: <Box component='img' style={{ width: '80%' }} src='/img/getapp/google-play.png' alt='' onClick={handleGooglePlay} />,
  };

  return (
    <Page
      title={t('helmet:GetAppPage.title')}
      hideHeader={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${theme.breakpoints.values.md}px`,
        justifyContent: 'space-evenly',
        height: '100vh',
      }}
    >
      <Typography variant='h1b' textAlign='center' sx={{ whiteSpace: 'pre-line' }}>
        {t('page.GetAppPage.title')}
      </Typography>

      {!showStores && (
        <Stack gap='48px'>
          <Box
            sx={{
              margin: '0 auto',
              background: 'url(/img/getapp/getapp.png) no-repeat',
              backgroundSize: 'cover',
              height: { xs: '240px', md: '480px' },
              width: { xs: '240px', md: '480px' },
            }}
          >
            <Box
              sx={{
                margin: { xs: '190px 145px', md: '380px 290px' },
                width: { xs: '115px', md: '230px' },
                height: { xs: '56px', md: '112px' },
                backgroundColor: '#FAEA64',
                transform: 'rotate(-12deg)',
                borderRadius: '16px',
                padding: { xs: '10px 0', md: '20px 0' },
                textAlign: 'center',
                fontWeight: 400,
                fontSize: { xs: '16px', md: '32px' },
                lineHeight: '110%',
              }}
            >
              Финансы — <br />
              это просто
            </Box>
          </Box>
          <Typography variant='h5' textAlign='center'>
            {t('page.GetAppPage.subtitle')}
          </Typography>
        </Stack>
      )}

      {showStores && (
        <Stack sx={{ width: 1, alignItems: 'center', gap: '16px' }}>{Object.keys(Stores).map((store) => Stores[store])}</Stack>
      )}

      <Stack gap='8px'>
        {!showStores && (
          <Button color='primary' variant='contained' size='large' onClick={handleShowStores}>
            {t('page.GetAppPage.btnStores')}
          </Button>
        )}
        {supportsPWA && (
          <Button onClick={handleInstallPWA} color='secondary' variant='contained'>
            {t('page.GetAppPage.btnPWA')}
          </Button>
        )}
        <Button onClick={handleNotNow}>{t('page.GetAppPage.btnNotNow')}</Button>
      </Stack>
    </Page>
  );
};

export default GetAppPage;
