import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, RadioGroupProps } from '@mui/material';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface RadioOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props<T extends FieldValues> {
  label?: string;
  name: FieldPath<T>;
  control: Control<T>;
  options: Array<RadioOption>;
  radioProps?: RadioGroupProps;
}

export default function RadioField<T extends FieldValues>(props: Props<T>) {
  const { t } = useTranslation();
  const { field, fieldState } = useController(props);
  return (
    <FormControl error={!!fieldState.error}>
      {props.label && (
        <FormLabel id={field.name} sx={{ typography: 'h4m', color: 'common.black' }}>
          {props.label}
        </FormLabel>
      )}
      {fieldState.error?.message && <FormHelperText>{t(fieldState.error.message)}</FormHelperText>}

      <RadioGroup
        {...field}
        value={field.value || ''}
        row
        aria-labelledby={field.name}
        defaultValue={field.value || ''}
        {...props.radioProps}
      >
        {props.options.map((option) => (
          <FormControlLabel
            disabled={option.disabled}
            key={option.value}
            value={option.value}
            control={<Radio inputProps={{ ['data-testid']: `${field.name}.${option.value}` } as any} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
