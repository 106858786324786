import { DTOCreateBankGuaranteeEntryAndCompanyBodyReq } from 'api/services/bankGuarantee/bankGuarantee.dto';
import { useBankGuarantee } from 'components/providers/BankGuaranteeProvider';
import { useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const fieldReducer = (state: Record<string, boolean>, action: { type: string }) => {
  return {
    ...state,
    [action.type]: !state[action.type],
  };
};

export default function useBankGuaranteeCreateLogic() {
  const params = useParams();
  const guarantee = useBankGuarantee();
  const [fieldStatus, dispatch] = useReducer(fieldReducer, { purchaseId: false, companyOwner: false });
  const hookForm = useForm<DTOCreateBankGuaranteeEntryAndCompanyBodyReq>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      entry: {
        params: { haveAdvance: false, haveAuctions: false },
      },
    },
  });

  useEffect(() => {
    if (params.uuidEntry) {
      guarantee.loadEntryRequest({ uuidEntry: params.uuidEntry! }, (entry) => {
        hookForm.reset(entry);
      });
    }
  }, []);

  const isPreloadEntryData = false;

  const hasErrors = Object.values(hookForm.formState.errors).length > 0;

  const submitForm = (formData: DTOCreateBankGuaranteeEntryAndCompanyBodyReq) => guarantee.createEntryCompanyRequest(formData);

  return { hookForm, fieldStatus, dispatch, hasErrors, submitForm, isPreloadEntryData };
}
