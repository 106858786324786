/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const Raiffeisen = createSvgIcon(
  <svg viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' fill='#FEE600' />
    <path
      d='M29.5804 22.5654V18.8802L30.9511 17.5225V20.7228L32.1259 21.8865L36.8252 17.2316L38 18.3953C38 17.5225 37.8042 15.0011 35.6504 12.8676C33.3007 10.6371 30.7552 11.6069 29.972 12.4797L25.2727 17.1346L26.4476 18.2984L24 20.8198L21.5524 18.3953L22.7273 17.2316L18.028 12.5767C17.1469 11.7039 14.6993 10.7341 12.3497 12.9646C10.1958 15.0981 10 17.6195 10 18.4923L11.1748 17.3286L15.8741 21.9835L17.049 20.8198V17.6195L18.4196 18.9772V22.6624L20.2797 24.505L10.8811 33.8148L14.6014 37.5L24 28.1901L33.3986 37.5L37.1189 33.8148L27.7203 24.505L29.5804 22.5654Z'
      fill='#2B2D34'
    />
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' stroke='#F5F5F5' />
  </svg>,
  'Raiffeisen',
);

export default Raiffeisen;
