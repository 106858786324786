/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const SuccIcon = createSvgIcon(
  <svg viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16 1L5.6875 11L1 6.45455' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'SuccIcon',
);

export default SuccIcon;
