import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const getInitialState = () => {
  return {
    status: 'idle',
    data: null,
    error: null,
  };
};

const useFetch = (method) => {
  const [state, setState] = useState(getInitialState());
  const clearState = () => setState(getInitialState());

  useEffect(() => {
    const sendRequest = async () => {
      if (state.status !== 'idle') {
        return;
      }

      setState((state) => ({
        status: 'loading',
        data: state.data,
        error: null,
      }));

      try {
        const res = await method();

        if (res.ok) {
          setState({ status: 'success', data: res.body, error: null });
        } else {
          const error = { code: res.status, message: res.statusText, isHttp: true };

          throw error;
        }
      } catch (error) {
        // if (error.code === 401) {
        //   navigate()
        // }
        setState((state) => ({ status: 'fail', data: state.data, error }));
      }
    };

    sendRequest();
  }, [method, state.status]);

  const statuses = {
    isLoading: state.status === 'loading',
    isSuccess: state.status === 'success',
    isFailed: state.status === 'failed',
    isFetching: state.status === 'loading' && !state.data,
    isFetchingFailed: state.status === 'failed' && !state.data,
  };

  return { ...state, ...statuses, reload: clearState };
};

useFetch.propTypes = {
  method: PropTypes.func,
};

export default useFetch;
