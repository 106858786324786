/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material/utils';

const ScanIcon = createSvgIcon(
  <svg width='43' height='47' viewBox='0 0 43 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M28.1724 7.34375H35.5862V14.6875' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.3794 39.6562H2.96558V32.3125' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M35.5862 32.3125V39.6562H28.1724' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.96558 14.6875V7.34375H10.3794' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M26.6897 14.6875H11.8622C11.0432 14.6875 10.3794 15.3451 10.3794 16.1562V30.8438C10.3794 31.6549 11.0432 32.3125 11.8622 32.3125H26.6897C27.5086 32.3125 28.1725 31.6549 28.1725 30.8438V16.1562C28.1725 15.3451 27.5086 14.6875 26.6897 14.6875Z'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'ScanIcon',
);

export default ScanIcon;
