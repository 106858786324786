import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M39.7647 19.5848C39.1935 18.1386 37.7831 17.351 36.2567 17.6266L33.12 18.1915C32.5992 18.2852 32.2536 18.7836 32.3464 19.3044C32.44 19.8252 32.9376 20.1713 33.4576 20.0784L36.5943 19.5135C37.3407 19.3789 37.8015 19.826 37.9839 20.2883C38.1671 20.7514 38.1351 21.3932 37.4991 21.8059L32.8952 24.7937C32.4672 25.0709 31.9464 25.0901 31.5008 24.8474C31.0544 24.6046 30.7872 24.1551 30.7872 23.6463V13.9649C30.7872 13.4353 30.3592 13.0059 29.8304 13.0059C29.3016 13.0059 28.8736 13.4353 28.8736 13.9649V23.6479C28.8736 24.8706 29.5136 25.9491 30.5864 26.5332C31.0848 26.804 31.6216 26.9394 32.156 26.9394C32.7728 26.9394 33.3872 26.7607 33.936 26.4042L38.5399 23.4163C39.8439 22.5702 40.3359 21.0311 39.7647 19.5848ZM23.5704 17.6274L20.4336 18.1923C19.9136 18.286 19.568 18.7844 19.6608 19.3052C19.7544 19.826 20.252 20.1721 20.772 20.0792L23.9088 19.5143C24.656 19.3797 25.116 19.8268 25.2992 20.2891C25.4816 20.7522 25.4504 21.394 24.8136 21.8067L20.2096 24.7945C19.7824 25.0717 19.2616 25.0909 18.8152 24.8482C18.3688 24.6054 18.1024 24.1559 18.1024 23.6471V16.2917C18.1024 15.0682 17.4624 13.9898 16.3896 13.4057C15.3168 12.8224 14.0648 12.8705 13.04 13.5347L1.45928 21.0495C0.156089 21.8948 -0.335908 23.434 0.235289 24.8802C0.805687 26.3256 2.21528 27.1125 3.74327 26.8376L13.8544 25.0172C14.3744 24.9235 14.7208 24.4251 14.6272 23.9043C14.5336 23.3835 14.036 23.0366 13.516 23.1303L3.40488 24.9507C2.65768 25.0853 2.19768 24.6382 2.01528 24.1759C1.83208 23.7128 1.86328 23.071 2.50008 22.6576L14.0784 15.1444C14.5048 14.8679 15.0264 14.8479 15.4728 15.0907C15.9192 15.3343 16.1856 15.7838 16.1856 16.2925V23.6479C16.1856 24.8706 16.8256 25.9491 17.8984 26.5332C18.3968 26.804 18.9344 26.9394 19.4688 26.9394C20.0856 26.9394 20.6992 26.7607 21.248 26.4042L25.8528 23.4163C27.1552 22.571 27.6472 21.0319 27.0768 19.5856C26.508 18.1386 25.0968 17.3526 23.5704 17.6274Z'
      fill='#009C32'
    />
  </svg>,
  'Akbars',
);
