import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useTranslation } from 'react-i18next';
import { PATH_HOME } from 'routes/paths';
import ClientTable from './components/ClientTable';
import { useAgentClients } from 'components/providers/ClientProvider';
import { isDesktop } from 'react-device-detect';
import { Box, Button, LinearProgress } from '@mui/material';
import BaseListEmpty from 'components/base/List/Empty';
import ClientList from './components/ClientList/ClientList';
import { Fragment } from 'react';
import { Add } from '@mui/icons-material';

export default function AgentMortgagePropertyClientPage() {
  const { t } = useTranslation();

  const { clients, loading, requstLoadScroll, clientNavigateProgress } = useAgentClients();
  return (
    <ContainerLayout
      title={t('helmet:AgentMortgagePropertyClientPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentMortgagePropertyClientPage.description') }]}
      header={{
        lg: (
          <TopMenu
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },
              { title: t('interface:base.TopMenu.routes.clients'), activeLink: true },
            ]}
          />
        ),
        xs: <BaseButtonBack title={t('helmet:AgentMortgagePropertyClientPage.title')} />,
      }}
      maxWidth='xl'
      sx={{ height: 1 }}
    >
      {isDesktop ? (
        <ClientTable clients={clients} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: 1,
            height: 0.75,
            flexDirection: 'column',
            ...(!clients.length && {
              alignItems: 'center',
              justifyContent: 'center',
            }),
          }}
        >
          {loading ? (
            <LinearProgress />
          ) : (
            <Fragment>
              {!clients.length ? (
                <BaseListEmpty title={t('interface:page.AgentMortgagePropertyClientPage.ClientList.empty')} />
              ) : (
                <ClientList clients={clients} onScroll={requstLoadScroll} onClickClient={clientNavigateProgress} />
              )}
              <Box sx={{ padding: '24px 0' }}>
                <Button variant='contained' fullWidth size='large' startIcon={<Add />}>
                  {t('interface:page.AgentMortgagePropertyClientPage.addClient')}
                </Button>
              </Box>
            </Fragment>
          )}
        </Box>
      )}
    </ContainerLayout>
  );
}
