import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  EMortgageEntryParamsPurpose,
  EMortgageEntryParamsSpecials,
  EMortgageEntryParamsSubjectSubtype,
  EMortgageEntryParamsSubjectType,
  ERfRegion,
} from 'api/services/mortgage/mortgage.enum';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';

import { useMemo, useState } from 'react';
import { supportSend } from 'api/services/support';
import { useAuth } from 'components/providers/AuthProvider';
import { ESupportReqType } from 'api/services/support.dto';
import { useSnackbar } from 'notistack';
import MortgageEntryCalculation from '../MortgageEntryCalculation';
import type { ECMortgageEntryParams } from 'api/services/mortgage/mortgage.schema';

export default function MortgageEntryParamsForm() {
  const hookForm = useFormContext<ECMortgageEntryParams>();
  const theme = useTheme();
  const auth = useAuth();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSupportProccess] = useState(false);

  const { control, watch, setValue } = hookForm;

  const purpose = watch('purpose');
  const subjectType = watch('subject.type');

  const handleRequestSupport = () => async () => {
    setSupportProccess(true);
    try {
      await supportSend({ mobilePhone: auth.user?.phone, reqType: ESupportReqType.call });
      enqueueSnackbar(t('page.ClientMortgageEntryCreatePage.supportRequestSend'), { variant: 'info' });
    } catch (err) {
    } finally {
      setSupportProccess(false);
    }
  };

  const isShowStatePrograms = useMemo(() => {
    return (
      ![EMortgageEntryParamsPurpose.homeownerLoan].includes(purpose) &&
      ![EMortgageEntryParamsSubjectType.commercialProperty].includes(subjectType)
    );
  }, [purpose, subjectType]);
  const isShowSubjectSubType = useMemo(() => {
    return ![EMortgageEntryParamsPurpose.homeownerLoan, EMortgageEntryParamsPurpose.abuildingHome].includes(purpose);
  }, [purpose]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            width: '100%',
            direction: 'column',
            padding: '24px',
            borderRadius: '16px',
          }}
        >
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="h4m">{t('interface:page.ClientMortgageEntryCreatePage.form.entryParams')}</Typography>
              <Tooltip title={t('interface:page.ClientMortgageEntryCreatePage.form.helpWithFill')}>
                <IconButton sx={{ color: theme.palette.primary.dark }} onClick={handleRequestSupport()}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Controller
                control={control}
                name="purpose"
                render={({ field, fieldState }) => (
                  <TextField
                    id="purpose"
                    select
                    label={t('interface:page.ClientMortgageEntryCreatePage.form.purpose.title')}
                    variant='standard'
                    fullWidth
                    ref={field.ref}
                    value={field.value || ''}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                  >
                    {Object.values(EMortgageEntryParamsPurpose).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`common:mortgage.purpose.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="subject.type"
                render={({ field, fieldState }) => (
                  <TextField
                    id="subject.type"
                    select
                    label={t('interface:page.ClientMortgageEntryCreatePage.form.subject.title')}
                    variant='standard'
                    fullWidth
                    {...field}
                    value={field.value || ''}
                    onChange={(e) => {
                      const value = e.target.value as EMortgageEntryParamsSubjectType;
                      field.onChange(value);
                      if (value === EMortgageEntryParamsSubjectType.commercialProperty) setValue('special', undefined);
                    }}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                  >
                    {Object.values(EMortgageEntryParamsSubjectType).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`common:mortgage.subject.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="subject.subtype"
                      render={({ field }) => (
                        <Checkbox
                          disabled={!isShowSubjectSubType}
                          checked={field.value === EMortgageEntryParamsSubjectSubtype.primary}
                          {...field}
                          onChange={(_e, checked) => {
                            if (checked) {
                              field.onChange(EMortgageEntryParamsSubjectSubtype.primary);
                              return;
                            }
                            field.onChange(EMortgageEntryParamsSubjectSubtype.secondary);
                          }}
                          inputProps={{ 'data-testid': field.name } as any}
                        />
                      )}
                    />
                  }
                  label={
                    <Typography
                      variant="h5"
                      sx={{
                        color: isShowSubjectSubType ? theme.palette.text.primary : theme.palette.text.secondary,
                      }}
                    >
                      {t('interface:page.ClientMortgageEntryCreatePage.form.buildProperty')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="isMortgageWithTwoDocs"
                      render={({ field }) => (
                        <Checkbox checked={field.value} {...field} inputProps={{ 'data-testid': field.name } as any} />
                      )}
                    />
                  }
                  label={
                    <Typography variant="h5">{t('interface:page.ClientMortgageEntryCreatePage.form.isMortgageWithTwoDocs')}</Typography>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="subject.region"
                render={({ field, fieldState }) => (
                  <TextField
                    id="subject.region"
                    select
                    label={t('interface:page.ClientMortgageEntryCreatePage.form.subjectRegion.title')}
                    variant='standard'
                    fullWidth
                    {...field}
                    value={field.value || ''}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ 'data-testid': field.name } as any}
                  >
                    {Object.values(ERfRegion).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`interface:page.ClientMortgageEntryCreatePage.form.subjectRegion.options.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="mortgageRegion"
                render={({ field, fieldState }) => (
                  <TextField
                    id="mortgageRegion"
                    select
                    label={t('interface:page.ClientMortgageEntryCreatePage.form.mortgageRegion.title')}
                    variant='standard'
                    fullWidth
                    {...field}
                    value={field.value || ''}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ 'data-testid': field.name } as any}
                  >
                    {Object.values(ERfRegion).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`interface:page.ClientMortgageEntryCreatePage.form.mortgageRegion.options.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {isShowStatePrograms && (
              <Grid item xs={12}>
                <Typography variant="h5m">{t('interface:page.ClientMortgageEntryCreatePage.form.statePrograms')}</Typography>
                {/* <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>
                                 В мае 2022 года стартовала программа льготного жилищного кредитования ИТ-специалистов. Сотрудники аккредитованных ИТ-компаний могут получить ипотеку по ставке до 5% на покупку или строительство собственного жилья
                             </Typography> */}
              </Grid>
            )}
            {isShowStatePrograms && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="special"
                  render={({ field, fieldState }) => (
                    <Select
                      id="special"
                      variant='standard'
                      fullWidth
                      {...field}
                      value={field.value || ''}
                      error={!!fieldState.error}
                      renderValue={(value) => (!value ? '' : t(`common:mortgage.specials.${value}`))}
                      inputProps={{ ['data-testid']: field.name }}
                    >
                      <MenuItem value="">
                        <em>Выберите</em>
                      </MenuItem>
                      {Object.values(EMortgageEntryParamsSpecials).map((option) => (
                        <MenuItem key={option} value={option} data-testid={option}>
                          {t(`common:mortgage.specials.${option}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            width: '100%',
            direction: 'column',
            padding: '24px',
            borderRadius: '16px',
          }}
        >
          <MortgageEntryCalculation />
        </Paper>
      </Grid>
    </Grid>
  );
}
