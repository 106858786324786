import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { getInsuranceOrderStats } from 'api/services/agent';
import WalletEmpty from 'components/Icons/WalletEmpty';
import BaseListEmpty from 'components/base/List/Empty';
import BaseStatisticChart from 'components/base/StatisticChart';
import Page from 'components/layouts/Page';
import dayjs from 'dayjs';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const AgentInsurancePropertyIncomesPage = ({ enabled }: { enabled?: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data } = useFetch(getInsuranceOrderStats) as { data: ObjectType };

  // TODO: I'ts just temp mock, need to refactor all page
  const mockGetReportDate = (dayjs().get('date') >= 15 ? dayjs().add(1, 'month').set('date', 15) : dayjs().set('date', 15)).format(
    'DD.MM.YYYY',
  );

  const { allTimeBounty = 0, currentMonthStats = 0 } = data || {};
  const {
    awaitingPaymentBounty = 0,
    awaitingPaymentSales = 0,
    toBeWithdrawnBounty = 0,
    toBeWithdrawnSales = 0,
    withdrawnBounty = 0,
    withdrawnSales = 0,
  } = currentMonthStats || {};

  const paymentDetails = [
    {
      title: 'paid',
      bounty: withdrawnBounty,
      sales: withdrawnSales,
    },
    {
      title: 'toBePaid',
      bounty: toBeWithdrawnBounty,
      sales: toBeWithdrawnSales,
    },
    {
      title: 'awaitingPayment',
      bounty: awaitingPaymentBounty,
      sales: awaitingPaymentSales,
    },
  ];

  if (!enabled)
    return (
      <Page
        title={t('helmet:AgentInsurancePropertyIncomesPage.title')}
        meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyIncomesPage.description') }]}
        header={{
          type: 'withNotification',
          title: t('interface:page.AgentInsurancePropertyIncomesPage.headerTitle'),
        }}
        sx={{ height: 1 }}
      >
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <BaseListEmpty
            customIcon={<WalletEmpty sx={{ width: '128px', height: '128px', mb: 3 }} />}
            title={t('interface:page.AgentInsurancePropertyIncomesPage.title')}
          />
        </Box>
      </Page>
    );

  // TODO: refactor helmet internationalization
  return (
    <Page
      title={t('helmet:AgentInsurancePropertyIncomesPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyIncomesPage.description') }]}
      header={{
        type: 'withNotification',
        title: t('interface:page.AgentInsurancePropertyIncomesPage.headerTitle'),
      }}
    >
      <Box
        sx={{
          marginTop: '24px',
          marginBottom: '32px',
          width: 1,
          background: `${theme.palette.secondary.main}`,
          borderRadius: '8px',
        }}
      >
        <Box sx={{ padding: '24px 24px 0 24px' }}>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t('interface:page.AgentInsurancePropertyIncomesPage.allTimeBounty')}
          </Typography>

          <Typography variant='h3' sx={{ margin: '8px 0 24px', fontSize: '22px', fontWeight: '700' }}>
            {utils.format.price(allTimeBounty / 100)}
          </Typography>
        </Box>
      </Box>

      <BaseStatisticChart timeline={data?.timeline} />

      {paymentDetails.map(({ title, bounty, sales }) => (
        <Box key={title} sx={{ mb: '24px' }}>
          <Typography variant='h3b'>{t(`interface:page.AgentInsurancePropertyIncomesPage.${title}`)}</Typography>

          <Box
            sx={{
              marginTop: '16px',
              width: 1,
              background: `${theme.palette.secondary.main}`,
              borderRadius: '8px',
            }}
          >
            <Stack direction='row' spacing={2} sx={{ padding: '24px' }}>
              <Box>
                <Typography variant='h5' sx={{ mb: '4px' }}>
                  {t('interface:page.AgentInsurancePropertyIncomesPage.bounty')}
                </Typography>

                <Typography variant='h4b'>{utils.format.price(bounty / 100)}</Typography>
              </Box>

              <Box sx={{ color: theme.palette.text.secondary }}>
                <Typography variant='h5' sx={{ mb: '4px' }}>
                  {t('interface:page.AgentInsurancePropertyIncomesPage.sales')}
                </Typography>

                <Typography variant='h4b'>{utils.format.price(sales / 100)}</Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
      ))}

      <Box sx={{ mb: '40px' }}>
        <Typography variant='h3b'>{t('interface:page.AgentInsurancePropertyIncomesPage.resultsTheMonth')}</Typography>

        <Box
          sx={{
            marginTop: '16px',
            width: 1,
            background: `${theme.palette.secondary.main}`,
            borderRadius: '8px',
          }}
        >
          <Box sx={{ padding: '24px' }}>
            <Typography variant='h4' sx={{ mb: '8px', color: theme.palette.text.secondary }}>
              {t('interface:page.AgentInsurancePropertyIncomesPage.paymentForMonth', { month: dayjs().format('MMMM') })}
            </Typography>

            <Typography variant='h4' sx={{ color: theme.palette.common.greyLight }}>
              {`${t('interface:page.AgentInsurancePropertyIncomesPage.dataWillApear')} ${mockGetReportDate}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button variant='contained'>{t('interface:page.AgentInsurancePropertyIncomesPage.downloadDetails')}</Button>
    </Page>
  );
};

export default AgentInsurancePropertyIncomesPage;
