import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ERequestedDataType, TRequestedData } from 'api/services/businessCredit/businessCredit.propose.dto';
import { useTranslation } from 'react-i18next';

interface Props {
  props: TRequestedData;
  onDelete: any;
}

const ProposeRequestItem = ({ props, onDelete }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column' },
        justifyContent: 'space-evently',
        gap: '8px',
        minHeight: '94px',
        borderRadius: '16px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography variant={'h3b'}>{t(`interface:base.ProposeRequest.types.${props.type}`)}</Typography>

          {/* Field block */}
          {props.type === ERequestedDataType.field && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '12px', lg: '8px' } }}>
              <Typography variant={'h3b'} sx={{ fontSize: '18px' }}>
                {props.data.otherType}
              </Typography>
              <Typography variant={'h3b'} sx={{ fontSize: '16px' }}>
                {t(`interface:base.ProposeRequest.${props.data.inputType}`)}
              </Typography>
              <Box>
                {props.data.selectValue?.map((value) => (
                  <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
                    {value}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}

          {/* Document block */}
          {props.type === ERequestedDataType.document && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '12px', lg: '8px' } }}>
              <Typography variant={'h3b'} sx={{ fontSize: '18px' }}>
                {props.data.otherType}
              </Typography>
              <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
                {t(`interface:base.ProposeRequest.documentType.${props.data.type}`)}
              </Typography>
            </Box>
          )}
        </Box>

        <IconButton sx={{ height: '40px', borderRadius: '25%' }} onClick={() => onDelete()}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default ProposeRequestItem;
