import { Box, Divider, Typography } from '@mui/material';
import { AccordionBail } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/components/AccordionBail';
import { AccordionBeneficiary } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/components/AccordionBeneficiary';
import { AccordionPolicyHolder } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/components/AccordionPolicyHolder';
import { useTranslation } from 'react-i18next';

export default function MortgageEntryInsuranceBaseDataForm() {
  const { t } = useTranslation();

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceBaseDataForm';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Typography variant='h4'>{t(`${prefix}.title`)}</Typography>
      <AccordionPolicyHolder />
      <Divider />
      <AccordionBeneficiary />
      <Divider />
      <AccordionBail />
    </Box>
  );
}
