/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const ArrowRightRoundIcon = createSvgIcon(
  <svg viewBox="0 0 58 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_37_3492)">
      <rect x="14" y="14" width="44" height="44" rx="22" fill="#F8E11A" shape-rendering="crispEdges" />
      <path d="M37 31L42 36M42 36L37 41M42 36L30 36" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <filter id="filter0_dd_37_3492" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.776471 0 0 0 0 0.776471 0 0 0 0 0.776471 0 0 0 0.11 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37_3492" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-4" dy="-4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.775 0 0 0 0 0.775 0 0 0 0 0.775 0 0 0 0.11 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_37_3492" result="effect2_dropShadow_37_3492" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_37_3492" result="shape" />
      </filter>
    </defs>
  </svg>,
  'ArrowRightRoundIcon',
);

export default ArrowRightRoundIcon;
