import { Box, Input } from '@mui/material';
import TextMask from 'components/base/Form/MaskInput';
import { ObjectType } from 'utils/iots';

const SignInPhoneInput = ({ onChange, value }: ObjectType) => {
  // TODO: I'm not sure about input & svg sizing
  // TODO: Colors from theme

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 1 }}>
      <Input
        variant='signin-phone'
        sx={{ width: '330px', marginBottom: '-16px', fontSize: { xs: '40px' } }}
        value={value}
        onChange={onChange}
        inputComponent={TextMask as ObjectType}
        inputProps={{
          inputMode: 'numeric',
          mask: '+7 000 000 00 00',
          overwrite: true,
          autoFocus: true,
          'data-testid': 'signin-phone',
        }}
      />
      <svg style={{ width: '275px', marginLeft: '55px' }} height='2' viewBox='0 0 275 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
        - <path d='M67,1 L0,1' strokeWidth='4' stroke={value.length > 5 ? '#F8E11A' : '#B9B9B2'} />
        - <path d='M148,1 L81,1' strokeWidth='4' stroke={value.length > 9 ? '#F8E11A' : '#B9B9B2'} />
        - <path d='M203,1 L160,1' strokeWidth='4' stroke={value.length > 12 ? '#F8E11A' : '#B9B9B2'} />
        - <path d='M258,1 L215,1' strokeWidth='4' stroke={value.length === 16 ? '#F8E11A' : '#B9B9B2'} />-{' '}
      </svg>
    </Box>
  );
};

export default SignInPhoneInput;
