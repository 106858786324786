/**
 *   * [x] get      /:uuidEntry/user/:uuidUser/profile/documents/required          Get list of required documents
 * [x] get      /:uuidEntry/user/:uuidUser/profile/documents                   Get all documents
 * [x] post     /:uuidEntry/user/:uuidUser/profile/documents                   Recognizing and sending documents to profile
 */

import { req } from 'api/http';
import {
  DocumentProgress,
  DocumentSource,
  IExperimentQueryParams,
  IExperimentUserInvite,
  IFillingQuestionnaireDTO,
  QuestionarieResponse,
} from './experiment.dto';
import { IUserEntity } from '../user.dto';

export function getListRequeiredDocuments({ uuidUser, uuidEntry }: IExperimentQueryParams) {
  return req<null, DocumentProgress[]>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/documents/required`, { method: 'GET' });
}

export function getAllDocuments(params: IExperimentQueryParams) {
  return req<unknown, DocumentSource[]>(`mortgageEntry/${params.uuidEntry}/user/${params.uuidUser}/profile/documents`, { method: 'GET' });
}

export function postRecognizeDocument(params: IExperimentQueryParams, src: string) {
  return req<{ link: string }, DocumentSource[]>(`mortgageEntry/${params.uuidEntry}/user/${params.uuidUser}/profile/documents`, {
    method: 'POST',
    body: { link: src },
  });
}

export function postSendQuestionarie({ uuidUser, uuidEntry }: IExperimentQueryParams, payload: IFillingQuestionnaireDTO) {
  return req<any, unknown>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/questionnaire`, { method: 'POST', body: payload });
}

export function getQuestionarie({ uuidUser, uuidEntry }: IExperimentQueryParams) {
  return req<any, QuestionarieResponse>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/questionnaire`, { method: 'GET' });
}
/**
 * Add new user to entry
 * {@link https://gate.kd.badassorange.tech/documentation/static/index.html#/auth/addNewUserToEntry}
 * @param param
 * @param payload
 * @returns
 */
export function postInviteUserByUser({ uuidEntry }: Partial<IExperimentQueryParams>, payload: IExperimentUserInvite) {
  return req<any, IUserEntity>(`mortgageEntry/${uuidEntry}/user`, { method: 'POST', body: payload });
}

export function putUpdateDocuments({ uuidEntry, uuidUser }: IExperimentQueryParams, payload: DocumentSource) {
  return req<any, DocumentSource[]>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/documents`, { method: 'PUT', body: payload });
}

export function deleteDocument({ uuidEntry, uuidUser }: IExperimentQueryParams, { uuidDocument }: { uuidDocument: string }) {
  return req<any, DocumentSource>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/profile/documents/${uuidDocument}`, {
    method: 'DELETE',
    body: {},
  });
}
