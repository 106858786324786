import { Button, Paper, Radio, Stack, Typography, useTheme } from '@mui/material';
import { FormSchemeData } from 'components/base/FormGenerator/form.types';
import { ft } from 'components/base/FormGenerator/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CardGroupSelectorProps {
  node: FormSchemeData;
  path: string[];
  onSelect: (name?: string) => void;
  onFilter: (node: FormSchemeData) => boolean;
  onCancel: () => void;
  hasCancel?: boolean;
}

export default function CardGroupSelector({ node, path, onSelect, onCancel, hasCancel, onFilter }: CardGroupSelectorProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState<string>();

  const handleNextClick = () => onSelect(selected);
  const handleCancelClick = () => onCancel();
  const handleClick = (name?: string) => () => setSelected(name);

  return (
    <>
      {node?.data?.filter(onFilter).map((formNode) => {
        const p = ft([...path, formNode?.name]);
        return (
          <Paper
            elevation={0}
            onClick={handleClick(formNode?.name)}
            key={p}
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: selected === formNode?.name ? theme.palette.primary.dark : theme.palette.common.greyLight,
              borderRadius: '8px',
              padding: '24px 24px 24px 6px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Radio data-testid={[...path, formNode?.name].join('.')} checked={selected === formNode?.name} />
            <div>
              <Typography display='block' variant='h3m'>
                {t(`${p}.title`)}
              </Typography>
              <Typography variant='h5'>{t(`${p}.description`)}</Typography>
            </div>
          </Paper>
        );
      })}
      <Stack gap={'6px'}>
        <Button
          sx={{ marginBottom: '20px' }}
          data-testid='CardGroupSelectorNextBtn'
          disabled={!selected}
          onClick={handleNextClick}
          fullWidth
          variant='contained'
          size='medium'
        >
          {t(`${ft(path)}.settings.btnSelectTitle`)}
        </Button>
        {hasCancel && (
          <Button
            data-testid='CardGroupSelectorCancelBtn'
            onClick={handleCancelClick}
            fullWidth
            variant='contained'
            color='secondary'
            size='medium'
          >
            {t(`${ft(path)}.settings.btnCancelTitle`)}
          </Button>
        )}
      </Stack>
    </>
  );
}
