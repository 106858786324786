import { getPersonalManagerPurchaseName, is, isDev, isIOS, isProd, isTesting } from './env';

export default {
  isDev,
  isProd,
  isTesting,
  getPersonalManagerPurchaseName,
  isIOS,
  is,
};
