import { z } from 'zod';
import {
  EMortgageEntryInitialPaySourceType,
  EMortgageEntryParamsPurpose,
  EMortgageEntryParamsSpecials,
  EMortgageEntryParamsSubjectSubtype,
  EMortgageEntryParamsSubjectType,
  ERfRegion,
} from './mortgage.enum';

const ecMortgageEntryParamsSubject = z
  .object({
    type: z.nativeEnum(EMortgageEntryParamsSubjectType, { required_error: 'errors:validation.required' }),
    subtype: z.nativeEnum(EMortgageEntryParamsSubjectSubtype).nullish(),
    region: z.nativeEnum(ERfRegion, { required_error: 'errors:validation.required' }),
    price: z.number({
      required_error: 'errors:validation.required',
      invalid_type_error: 'errors:validation.required',
    }),
  })
  .required({
    type: true,
    region: true,
    price: true,
  });

const ecMortgageEntryParamsIns = z.object({
  life: z.boolean().nullish(),
  property: z.boolean().nullable(),
  title: z.boolean().nullish(),
});

export const ecMortgagePaymentSource = z.object({
  type: z.nativeEnum(EMortgageEntryInitialPaySourceType).nullish(),
  amount: z.number().nullish(),
});

export const ecMortgageEntryParams = z
  .object({
    subject: ecMortgageEntryParamsSubject,
    special: z.nativeEnum(EMortgageEntryParamsSpecials, { required_error: 'errors:validation.required' }).nullish().or(z.string()),
    purpose: z.nativeEnum(EMortgageEntryParamsPurpose, { required_error: 'errors:validation.required' }),
    otherPurpose: z.string().nullish(),
    insurance: ecMortgageEntryParamsIns,
    mortgageRegion: z.nativeEnum(ERfRegion, { required_error: 'errors:validation.required' }),
    initialPayment: z.number({ invalid_type_error: 'errors:validation.required' }),
    initialPaymentSources: z.array(ecMortgagePaymentSource).nullish(),
    mortgagePeriod: z.number({ required_error: 'errors:validation.required' }),
    isMortgageWithTwoDocs: z.boolean(),
  })
  .required({
    mortgagePeriod: true,
    subject: true,
  })
  .refine(
    ({ subject, initialPayment }) => {
      return subject.price > initialPayment;
    },
    {
      path: ['subject.price'],
      message: 'errors:pages.ClientMortgageEntryCreateForm.lessThan',
    },
  );

export type ECMortgageEntryParamsSubject = z.infer<typeof ecMortgageEntryParamsSubject>;
export type ECMortgageEntryParams = z.infer<typeof ecMortgageEntryParams>;
