import { useQuery } from '@tanstack/react-query';

import { req } from '../http';

import { EContractType, IContractContentResponse, IContractDetails, IContractEntity, TSignContract } from './contract.dto';
import { IFormFillInput } from './form.dto';
import { EUserRole, IUpdateAuthorizedUserDTO, IUserChangeGroupParamsDTO, IUserEntity } from './user.dto';

export const userGetInfo = (uuid = 'me') => {
  return req<never, IUserEntity>(`user/${uuid}`, { method: 'GET' });
};

export const userUpdateInfo = (data: IUpdateAuthorizedUserDTO) => {
  return req<IUpdateAuthorizedUserDTO, any>(`user`, { method: 'PUT', body: { ...data } });
};

/**
 *
 * {@link https://gate.ip.mindcode.ru/documentation/static/index.html#/user/addUserRole}
 * select user role
 * @param {EUserRole} role
 * @returns
 */
export const userUpdateRole = (role: EUserRole) => {
  return req<Partial<IUserChangeGroupParamsDTO>, null>(`user/me/role`, {
    method: 'PUT',
    body: {
      roles: [role],
    },
  });
};

export const userGetProfile = () => {
  return req(`user/profile`, { method: 'GET' });
};

export const userGetContractByType = (type: EContractType) => {
  return req<null, IContractEntity>(`user/contract/${type}`, { method: 'GET' });
};

export const userSetContractDetails = (type: EContractType, details: IContractDetails) => {
  return req<IContractDetails, IContractEntity>(`user/contract/${type}/details`, { method: 'POST', body: details });
};

export const userSetContractContent = (type: EContractType) => {
  return req<null, IContractContentResponse>(`user/contract/${type}/content`, { method: 'GET' });
};

export const userIssueContractSmsCode = (type: EContractType) => {
  return req(`user/contract/${type}/issue-sign-code`, {
    method: 'POST',
  });
};

export const userCheckContractCode = <T extends TSignContract>(body: T) => {
  return req<TSignContract, IContractEntity>(`user/contract/sign`, {
    method: 'POST',
    body,
  });
};

export const userGetProfileProgress = () => {
  return req(`user/profile/progress`, { method: 'GET' });
};

export const userGetNotificationCount = () => {
  return req(`notify/count`, { method: 'GET' });
};

export const userGetNotificationList = () => {
  return req(`notify/`, { method: 'GET' });
};

export const userGetProfileForm = () => {
  return req(`user/form/profile?mode=fill`, { method: 'GET' });
};

export const userGetProfileFilledForm = () => {
  return req(`user/form/profile?mode=fill`, { method: 'GET' });
};

export const userGetAgentForm = (uuidProfile: string) => {
  const query = `?mode=fill-agent-client${uuidProfile ? `&uuidProfile=${uuidProfile}` : ''}`;
  return req(`user/form/agent-insurance${query}`, { method: 'GET' });
};

export const userGetObjectFilledForm = () => {
  return req(`user/form/object?mode=fill`, { method: 'GET' });
};

export const userPostProfileForm = (role: string, data: IFormFillInput) => {
  return req<IFormFillInput, { uuidProfile: string; uuidUser: string }>(`user/form/${role}-profile/fill`, {
    method: 'POST',
    body: data,
  });
};

export const userPostAgentForm = (data: string) => {
  return req(`user/form/agent-insurance/fill`, { method: 'POST', body: data });
};

export const userEsiaFill = (token: string) => {
  return req(`user/profile/esia/fill`, { method: 'POST', body: { token } });
};

export const userEntryListProposes = (uuidEntry: string) => {
  return req(`user/entry/${uuidEntry}/proposes`, { method: 'GET' });
};

export const userUploadFile = ({ resourceType, file }: { resourceType?: string; file: File }) => {
  const query = new URLSearchParams();
  if (resourceType) query.append('resource', resourceType);
  return req<File, { link: string }>(`user/upload-document?${query.toString()}`, { method: 'POST', body: { file } });
};

export const userSetClientNotificationToken = (token: string, provider = 'firebase') => {
  return req(`user/notify/token`, { method: 'POST', body: { token, provider } });
};

export function useUserGetInfo(uuidUser?: string) {
  return useQuery(
    ['progress', uuidUser],
    async () => {
      const data = await userGetInfo(uuidUser);
      return data.body;
    },
    { enabled: !!uuidUser },
  );
}
