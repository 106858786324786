import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.5 0.5V40.5H40.5V0.5H0.5ZM33.9 33.8H7.2V7.2H34L33.9 33.8Z' fill='url(#paint0_radial_68_427)' />
    <path d='M28.6998 12.5996H15.3998L12.2998 17.8996H25.5998L28.6998 12.5996Z' fill='url(#paint1_radial_68_427)' />
    <path d='M28.6998 23.2002H15.3998L12.2998 28.5002H25.5998L28.6998 23.2002Z' fill='url(#paint2_radial_68_427)' />
    <defs>
      <radialGradient
        id='paint0_radial_68_427'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(-14.2009 34.4745) scale(57.069)'
      >
        <stop stopColor='#0093D8' />
        <stop offset='0.3' stopColor='#006CB7' />
        <stop offset='1' stopColor='#08296C' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_68_427'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(6.2841 17.0133) scale(23.271 23.271)'
      >
        <stop stopColor='#0093D8' />
        <stop offset='0.3' stopColor='#006CB7' />
        <stop offset='1' stopColor='#08296C' />
      </radialGradient>
      <radialGradient
        id='paint2_radial_68_427'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(6.2841 27.7246) scale(23.271 23.271)'
      >
        <stop stopColor='#0093D8' />
        <stop offset='0.3' stopColor='#006CB7' />
        <stop offset='1' stopColor='#08296C' />
      </radialGradient>
    </defs>
  </svg>,
  'RealistBank',
);
