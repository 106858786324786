import { Grid } from '@mui/material';
import { StoryModel } from '../../types';
import StoryListItem from './StoryListItem';

export default function StoryList({ stories, onClickStory }: { stories: StoryModel[]; onClickStory: (index: number) => void }) {
  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      sx={{
        marginLeft: { xs: '8px', lg: '0px' },
        marginTop: { xs: '8px', lg: '0px' },
        backgroundColor: { lg: 'transparent', xs: '#ececec', padding: '8px', borderRadius: '8px' },
      }}
    >
      {stories.map((story, index) => (
        <Grid item xs={3} lg={3} key={story.uuid}>
          <StoryListItem
            background={{ background: (story?.sx as any)?.background! || ''! }}
            onClickStory={() => {
              onClickStory(index);
            }}
            key={story.uuid}
            title={story.shortTitle}
            src={story.bannerImage.src}
          />
        </Grid>
      ))}
    </Grid>
  );
}
