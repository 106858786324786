import { Box, Button, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import BaseCheckbox from 'components/base/Checkbox';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// TODO: Remove styled
const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
});

// TODO: Remove styled
const TypographyHeading = styled(Typography)({
  fontWeight: 600,
  marginBottom: '16px',
});

const BaseInsuranceFilter = ({ applyFilters, defaultValues, filtersConfig, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { control, watch, handleSubmit } = useForm({
    mode: 'all',
    defaultValues,
  });

  const onSubmit = (formValues) => {
    applyFilters(formValues);
  };

  const watchValues = watch();

  const insuranceChecked = Object.values(watchValues.companies).filter(Boolean).length > 0;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {Object.keys(filtersConfig).map((key, idx) => (
        <Box key={key} sx={{ marginBottom: idx === 0 ? '32px' : 0 }}>
          <TypographyHeading variant={'h2'}>{t(`interface:base.BaseInsuranceFilter.filterType.${key}`)}</TypographyHeading>
          {filtersConfig[key].map((field) => (
            <Controller
              key={field.fieldName}
              name={`${key}.${field.fieldName}`}
              control={control}
              render={({ field: formField }) => (
                <BaseCheckbox
                  label={<Typography variant='h5'>{t(`common:insurance.${field.translationKey}`)}</Typography>}
                  checked={formField.value}
                  onChange={(e) => formField.onChange(e.target.checked)}
                />
              )}
            />
          ))}
        </Box>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '10em' }}>
        <Button disabled={!insuranceChecked} variant='contained' type='submit' sx={{ marginBottom: '16px' }}>
          {t('interface:base.BaseInsuranceFilter.buttons.apply')}
        </Button>
        <Button variant='contained' color='secondary' sx={{ color: `${theme.palette.primary.contrastText}` }} onClick={onClose}>
          {t('interface:base.BaseInsuranceFilter.buttons.cancel')}
        </Button>
      </Box>
    </Form>
  );
};

BaseInsuranceFilter.propTypes = {
  applyFilters: PropTypes.func,
  filtersConfig: PropTypes.object,
  defaultValues: PropTypes.string,
  onClose: PropTypes.func,
};

export default BaseInsuranceFilter;
