import { Button, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import { getInsurancePackage, makeInsurancePropertyOrder } from 'api/services/agent';
import InsuranceProductCard from 'components/base/InsuranceProductCard';
import Page from 'components/layouts/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY_ORDER } from 'routes/paths';

export interface ILocationState {
  uuidInsurancePackage: string;
  uuidProfile: string;
}

const AgentInsurancePropertyPolicyOrderPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  // TODO: API Typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [product, setProduct] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const navigate = useNavigate();

  const makeOrder = async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await makeInsurancePropertyOrder(locationState?.uuidInsurancePackage, locationState?.uuidProfile);
      if (res.ok) {
        // TODO: API Typing
        const order = res.body as { uuid: string };
        navigate(`${PATH_AGENT_INSURANCE_PROPERTY_ORDER}/${order?.uuid}`);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  const loadPackages = async () => {
    try {
      const res = await getInsurancePackage(locationState.uuidInsurancePackage);
      const { body } = res;
      setProduct(body);
    } catch (err) {
      setError('logic.badConnection');
    }
  };

  useEffect(() => {
    if (!product && locationState?.uuidInsurancePackage) loadPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, locationState]);

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyPolicyOrderPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyPolicyOrderPage.description') }]}
      header={{ title: t('interface:page.AgentInsurancePropertyPolicyOrderPage.headerTitle') }}
    >
      <Typography variant='h3b' sx={{ marginBottom: '24px' }}>
        {t('interface:page.AgentInsurancePropertyPolicyOrderPage.product')}
      </Typography>
      <InsuranceProductCard button={{}} item={product} />
      <Container sx={{ position: 'fixed', left: 0, bottom: '20px', width: 1 }}>
        {error && (
          <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
            {t(`errors:${error}`)}
          </Typography>
        )}
        <Button disabled={loading} variant='contained' size='large' type='submit' fullWidth onClick={makeOrder}>
          {loading ? <CircularProgress size={24} /> : t('interface:page.AgentInsurancePropertyPolicyOrderPage.drawUpBtn')}
        </Button>
      </Container>
    </Page>
  );
};

export default AgentInsurancePropertyPolicyOrderPage;
