import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { EMortgageEntryProposeStatus, IMortgageEntryPropose } from 'api/services/mortgage.dto';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import StatusDot from 'components/base/StatusDot';
import { useMortgage } from 'components/providers/MortageEntryProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROPOSE } from 'routes/paths';
import utils from 'utils';
import { urlReplace } from 'utils/url';

const BankStepProgress = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { proposes, uuid, selectPropose, entry } = useMortgage();

  const handleNavigate = () => {
    navigate(urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROPOSE, { uuid }));
  };

  const filteredProposes = useMemo(() => {
    return proposes?.filter((x) => {
      if (!entry?.excludedProviders) {
        return true;
      }
      return !entry.excludedProviders.includes(x.bank);
    });
  }, [entry]);

  const getStatusColor = (propose: IMortgageEntryPropose): string => {
    switch (propose.status) {
      case EMortgageEntryProposeStatus.approved:
        return theme.palette.success.main;
      case EMortgageEntryProposeStatus.declined:
        return theme.palette.error.main;
      case EMortgageEntryProposeStatus.withoutSuitablePrograms:
        return theme.palette.text.secondary;
      // Hidden this status from user
      // case EMortgageEntryProposeStatus.reworkRequired:
      // return theme.palette.secondary.main;
      // case EMortgageEntryProposeStatus.failed:
      // return theme.palette.error.main;
      default:
      case EMortgageEntryProposeStatus.pending:
        return theme.palette.primary.main;
    }
  };

  const getPercentColor = (propose: IMortgageEntryPropose): string => {
    if (entry?.primaryPropose?.uuid === propose?.uuid) {
      return theme.palette.success.main;
    }
    return theme.palette.text.secondary;
  };

  const handleSelectPropose = (propose: IMortgageEntryPropose) => {
    selectPropose?.(propose);
    handleNavigate();
  };

  return (
    <>
      <Button
        data-testid={`substep-bank-all`}
        key={`substep-bank-all`}
        fullWidth
        sx={{ color: `${theme.palette.text.primary}`, justifyContent: 'space-between' }}
        endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
        onClick={handleNavigate}
      >
        {t(`interface:page.ClientMortgageProgressPage.progress.bank.btnAllProposes`)}
      </Button>
      {filteredProposes?.map((propose) => (
        <Button
          data-testid={`substep-bank-${propose.bank}`}
          key={`substep-bank-${propose.bank}`}
          fullWidth
          sx={{ color: `${theme.palette.text.primary}`, justifyContent: 'space-between' }}
          endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
          onClick={() => {
            handleSelectPropose(propose);
          }}
        >
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {t(`common:mortgage.banks.${propose.bank}`)}
            {propose?.decision?.rate ? (
              <>
                {entry?.primaryPropose?.uuid === propose?.uuid ? <StatusDot color={getStatusColor(propose)} /> : null}
                <Typography variant='h5' display='inline' color={getPercentColor(propose)}>
                  {utils.format.percent(propose?.decision?.rate)}
                </Typography>
              </>
            ) : (
              <StatusDot color={getStatusColor(propose)} />
            )}
          </Box>
        </Button>
      ))}
    </>
  );
};

export default BankStepProgress;
