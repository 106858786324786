import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

interface InfoFieldProps {
  title?: string | number;
  helperText?: string;
}

export const InfoField: FC<InfoFieldProps> = ({ title, helperText = '' }) => {
  const theme = useTheme();

  return (
    <Box sx={{ paddingBottom: '	0.5rem' }}>
      <Typography component={'p'} variant='h7' color={theme.palette.text.secondary}>
        {helperText}
      </Typography>
      <Typography component={'p'} variant='h4'>
        {title}
      </Typography>
    </Box>
  );
};
