import { EMortgageEntryStage, IMortgageEntryCLientListResult } from 'api/services/agent.dto';
import { IMortgageEntryEntity } from 'api/services/mortgage.dto';

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  getValue?: (row: IMortgageEntryCLientListResult) => unknown;
}

export enum ClientTableModalType {
  creditParams = 'creditParams',
  scoringParams = 'scoringParams',
}

export const stageProccess: Record<EMortgageEntryStage, (entry: IMortgageEntryEntity) => string | string[] | undefined> = {
  [EMortgageEntryStage.profile]: (entry) => entry.stagesStatus?.profile?.users.map((e) => e.status),
  [EMortgageEntryStage.bank]: (entry) => entry.stagesStatus?.bank?.status,
  [EMortgageEntryStage.realEstate]: (entry) => entry.stagesStatus?.realEstate?.status,
  [EMortgageEntryStage.insurance]: (entry) => entry.stagesStatus?.insurance?.status,
  [EMortgageEntryStage.deal]: (entry) => entry.stagesStatus?.deal?.status,
  // TODO: remove this
  [EMortgageEntryStage.questionnaire]: (_entry) => undefined,
  [EMortgageEntryStage.primaryPropose]: (_entry) => undefined,
};

export const headCells: readonly HeadCell[] = [
  {
    id: 'lastname',
    numeric: false,
    disablePadding: true,
    label: 'ФИО',
    getValue: (row) => row.user.lastname,
  },
  {
    id: 'entry.stage',
    numeric: true,
    disablePadding: false,
    label: 'Этап прогресса',
    getValue: (row) => row?.entry?.stage,
  },
  {
    id: 'entry.status',
    numeric: true,
    disablePadding: false,
    label: 'Статус заявки',
    getValue: (row) => row?.entry?.status,
  },
  {
    id: 'entry.dates.updatedAtTs',
    numeric: true,
    disablePadding: false,
    label: 'Дата последнего изменения',
    getValue: (row) => row?.entry?.dates.updatedAtTs,
  },
  {
    id: 'entry.dates.change',
    numeric: false,
    disablePadding: false,
    label: 'Переназначить',
  },
  // {
  //   id: 'additional',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Дополнительная информация',
  //   getValue: () => '',
  // },
];
