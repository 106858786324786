import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, MenuItem, Paper, SvgIcon, TextField, Typography, useTheme } from '@mui/material';
import { EMortgageEntryProposeStatus, IMortgageEntryPropose, IMortgageEntryProposeDecision } from 'api/services/mortgage.dto';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePropose } from '../../../../ProposeProvider';
import { MapIcons } from '../icons';
import ProposeStatusPanel from '../ProposeStatusPanel';
import AdditionalForm from './components/AdditionalForm';
import ProposeEditForm from './components/ProposeEditForm';
import { proposeSchema } from './schema';
import { get, set } from 'lodash';

interface ProposeEditorProps {
  propose: IMortgageEntryPropose<unknown>;
  selected?: boolean;
}

function getValuePropose(propose: IMortgageEntryPropose, path: string) {
  const value = get(propose, path);
  if (value && value > 0) {
    return get(propose, path);
  }
  return null;
}

function getProposeDefaultValues(propose: IMortgageEntryPropose) {
  let decision = {};
  if (!propose.decision) {
    decision = {
      creditDuration: getValuePropose(propose, 'decision.creditDuration'),
      rate: getValuePropose(propose, 'decision.rate'),
      initialFee: getValuePropose(propose, 'decision.initialFee'),
      additionalConditions: get(propose, 'decision.additionalConditions', ''),
      approvedSum: getValuePropose(propose, 'decision.approvedSum'),
      monthlyPayment: getValuePropose(propose, 'decision.monthlyPayment'),
    };
  } else {
    decision = {
      creditDuration: get(propose, 'decision.creditDuration', 0),
      rate: get(propose, 'decision.rate', 0) / 100,
      initialFee: get(propose, 'decision.initialFee', 0) / 100,
      additionalConditions: get(propose, 'decision.additionalConditions', ''),
      approvedSum: get(propose, 'decision.approvedSum', 0) / 100,
      monthlyPayment: get(propose, 'decision.monthlyPayment', 0) / 100,
    };
  }

  return { status: propose.status, decision: decision };
}

export default function ProposeEditor(props: ProposeEditorProps) {
  const proposeHook = usePropose();
  const { propose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const hookForm = useForm<IMortgageEntryPropose>({
    defaultValues: getProposeDefaultValues(propose),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(proposeSchema),
    criteriaMode: 'all',
  });

  const handleSubmit = async () => {
    const values = hookForm.getValues();
    if (values.decision) {
      const decision: IMortgageEntryProposeDecision = {
        creditDuration: get(values, 'decision.creditDuration', 0),
        rate: get(values, 'decision.rate', 1) * 100,
        initialFee: get(values, 'decision.initialFee', 1) * 100,
        additionalConditions: get(values, 'decision.additionalConditions', ''),
        approvedSum: get(values, 'decision.approvedSum', 1) * 100,
        monthlyPayment: get(values, 'decision.monthlyPayment', 1) * 100,
      };
      set(values, 'decision', decision);
      if (props.selected) {
        await proposeHook.managerUpdateDecision({ uuidPropose: propose.uuid as string, propose: values });
      } else {
        await proposeHook.updateProposeParams(values);
      }

      proposeHook.editPropose(undefined);
    }
  };
  return (
    <>
      <FormProvider {...hookForm}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '24px',
            borderRadius: '12px',
            height: '100%',
            width: 1,
            justfiyContent: 'space-between',
          }}
          variant='outlined'
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', flexGrow: 0.7, width: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                <SvgIcon component={MapIcons[propose.bank]} sx={{ width: 48, height: 48 }} />
                <Typography variant='h4'>{t(`common:mortgage.banks.${propose.bank}`)}</Typography>
                <Controller
                  name="status"
                  render={({ field }) => (
                    <TextField disabled={props.selected} variant='standard' select data-testid="propose.status" {...field}>
                      <MenuItem value={EMortgageEntryProposeStatus.approved}>
                        <Box
                          sx={{
                            padding: '5px 10px 5px 10px',
                            width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            borderRadius: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant='h6' component='span'>
                            {t('interface:base.ProposeEditor.statusApproved')}
                          </Typography>
                        </Box>
                      </MenuItem>
                      {[
                        EMortgageEntryProposeStatus.pending,
                        EMortgageEntryProposeStatus.withoutSuitablePrograms,
                        EMortgageEntryProposeStatus.declined,
                      ].map((status) => (
                        <MenuItem key={String(status)} value={status}>
                          <ProposeStatusPanel canApprove={false} proposeStatus={status} theme={theme} />
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <ProposeEditForm />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Typography
                  sx={{
                    color: theme.palette.common.link,
                  }}
                >
                  {t('interface:base.ProposeEditor.additionalParams')}
                </Typography>
                <AdditionalForm />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px' }}>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={props.selected ? false : !hookForm.formState.isDirty}
                  onClick={handleSubmit}
                >
                  {t('common:save')}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    proposeHook.editPropose(undefined);
                  }}
                >
                  {t('common:cancel')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </FormProvider>
    </>
  );
}
