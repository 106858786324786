import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import { Role } from 'components/Icons/Role';
import Page from 'components/layouts/Page';
import React from 'react';
import { ElementType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_INVITE } from 'routes/paths';
import utils from 'utils';

interface Props<TRole> {
  onSelectRole: (role: TRole) => void;
  roles: Record<string, TRole>;
  icon: ElementType;
  title: string;
  blacklist?: TRole[];
  renderButton?: (params: { roles: TRole[]; onSelectRole: (role: TRole) => void }) => React.ReactChild;
}

export function ParticipantRoleSelector<T extends string>({ onSelectRole, roles, icon, title, renderButton, blacklist }: Props<T>) {
  const { t } = useTranslation();

  const handleRoleBtnClick = (role: T) => () => {
    onSelectRole(role);
  };

  return (
    <Stack gap='20px' paddingTop='50px'>
      {/* Header */}
      <Stack gap='8px' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SvgIcon sx={{ height: '128px', width: '128px' }} viewBox='0 0 128 128' component={icon} />
      </Stack>

      <Stack gap='24px'>
        <Typography variant='h1' sx={{ width: 1, textAlign: 'center', fontSize: '32px', fontWeight: 700 }}>
          {title}
        </Typography>
        <Stack gap='16px'>
          {renderButton
            ? renderButton({ roles: Object.values(roles), onSelectRole: handleRoleBtnClick })
            : Object.values(roles)
                .filter((role) => !blacklist?.includes(role))
                .map((role) => (
                  <Button
                    fullWidth
                    key={role as unknown as string}
                    name={role}
                    variant='contained'
                    color='secondary'
                    size='large'
                    onClick={handleRoleBtnClick(role)}
                  >
                    {t(`common:mortgage.roles.${role}`)}
                  </Button>
                ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function ParticipantInvite() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid?: string }>();
  const [role, selectRole] = useState<string | null>(null);
  const handleNavigate = () => {
    if (uuid && role)
      // PATH_CLIENT_MORTGAGE_ENTRY_INVITE.replace(':uuid', uuid).replace(':role', EMortgageEntryRole.guarantor)
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_INVITE, { uuid, role }));
  };

  return (
    <Page
      title={t('helmet:ParticipantInvite.title')}
      meta={[{ name: 'description', content: t('helmet:HomeClientPage.ParticipantInvite.description') }]}
      header={{
        title: t('interface:page.HomeClientPage.ParticipantInvite.headerTitle'),
        type: 'backButton',
      }}
      sx={{ height: 1, justifyContent: 'space-evently', gap: '16px', paddingBottom: '20px' }}
    >
      {!role ? (
        <ParticipantRoleSelector
          blacklist={[EMortgageEntryRole.borrower]}
          title={t('interface:page.HomeClientPage.ParticipantInvite.invite')}
          icon={Role}
          roles={{ ...EMortgageEntryRole, agent: 'agent' }}
          onSelectRole={(e) => {
            selectRole(e);
          }}
        />
      ) : (
        <>
          <Stack gap='16px' sx={{ justifyContent: 'space-between', height: 1 }}>
            <Typography variant='h4' sx={{ whiteSpace: 'pre-line' }}>
              {t(`interface:page.HomeClientPage.ParticipantInvite.roleDescription.${role}.title`)}
            </Typography>

            <Stack gap='8px'>
              <Button fullWidth variant='contained' size='large' onClick={handleNavigate}>
                {t(`interface:page.HomeClientPage.ParticipantInvite.btnSelectRole.${role}`)}
              </Button>
              <Button
                fullWidth
                variant='contained'
                color='secondary'
                size='large'
                onClick={() => {
                  selectRole(null);
                }}
              >
                {t(`interface:page.ClientMortgageEntryCreateRolePage.btnChangeRole`)}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </Page>
  );
}
