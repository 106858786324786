import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { EMortgageEntryRealEstateStatus } from 'api/services/realEstate/realEstate.enum';
import { RealEstateFormSchema } from 'api/services/realEstate/realEstate.schema';
import useNavigateParams from 'hooks/useNavigateParams';
import useTypedParams from 'hooks/useTypedParams';
import ProposeCard from 'pages/Client/Mortgage/Entry/Propose/components/ProposeCard';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_REAL_ESTATE, PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS } from 'routes/paths';
import RealEstateForm from '../../RealEstateProvider';

export default function MortgageEntryRealEstateApproveForm() {
  const { watch } = useFormContext<RealEstateFormSchema>();
  const navigate = useNavigateParams();
  const { uuidEntry } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_REAL_ESTATE>();

  const { t } = useTranslation();

  const status = watch('mortgage.status');
  const propose = watch('mortgage.primaryPropose');

  const text =
    status === EMortgageEntryRealEstateStatus.approved
      ? t('base.MortgageEntryRealEstateApproveForm.status.approved')
      : t('base.MortgageEntryRealEstateApproveForm.status.waiting');

  const approved = status === EMortgageEntryRealEstateStatus.approved;

  const handleNavigate = () => {
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: uuidEntry } });
  };

  if (status === EMortgageEntryRealEstateStatus.process) {
    return <RealEstateForm.OverlayWindow />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('base.MortgageEntryRealEstateApproveForm.bankSolution')}</Typography>
      <Button
        disabled={!approved}
        variant={!approved ? 'contained' : 'outlined'}
        color='secondary'
        fullWidth
        sx={{ display: 'flex' }}
        endIcon={approved && <CheckIcon htmlColor='#31C918' />}
      >
        <Typography sx={{ color: !approved ? '' : '#31C918' }}>{text}</Typography>
      </Button>
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 500,
        }}
      >
        {t('base.MortgageEntryRealEstateApproveForm.finalApprovement')}
      </Typography>

      {propose && approved ? (
        <ProposeCard layout='mobile' hiddenButtons propose={propose} canApprove={false} />
      ) : (
        <Skeleton variant='rectangular' width={'100%'} height={'300px'} sx={{ borderRadius: '24px' }} />
      )}

      {approved ? (
        <Button sx={{ marginTop: 'auto' }} onClick={handleNavigate} fullWidth variant='contained' size='large' data-testid='backProgress'>
          {t('interface:page.MortgageEntryRealEstatePage.backProgress')}
        </Button>
      ) : (
        <Button sx={{ marginTop: 'auto' }} onClick={handleNavigate} fullWidth variant='contained' size='large' data-testid='backProgress'>
          {t('interface:page.MortgageEntryRealEstatePage.returnProgress')}
        </Button>
      )}
    </Box>
  );
}
