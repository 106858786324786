import { Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { getEsiaCPG } from 'api/services/esia';
import CloseIcon from 'components/Icons/Close';
import GosuslugiIcon from 'components/Icons/Gosuslugi';
import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

interface IProps {
  hidden?: boolean;
}

const BannerGosuslugi = ({ hidden = false }: IProps) => {
  const { t } = useTranslation();

  const desktop = isDesktop;

  const [esiaUrl, setEsiaUrl] = useState<string>();
  const theme = useTheme();
  const [isHidden, setIsHidden] = useState<boolean>(hidden);
  const handleCloseClick = (): void => {
    setIsHidden(!isHidden);
  };

  const loadAsync = async () => {
    try {
      const res = await getEsiaCPG();
      setEsiaUrl(res.body.url);
    } catch (err) {}
  };

  useEffect(() => {
    loadAsync();
  }, []);

  if (isHidden) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',

        width: 1,
        background: theme.palette.secondary.main,
        borderRadius: '8px',
        padding: '16px',
        gap: '8px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SvgIcon component={GosuslugiIcon} sx={{ width: '42px', height: '46px' }} />
        <SvgIcon component={CloseIcon} sx={{ width: '15px', height: '15px' }} onClick={handleCloseClick} />
      </Box>
      <Box>
        <Typography variant='h4m'>{t('interface:base.BannerGosuslugi.lblTitle')}</Typography>
        <Typography variant='h5'>
          {t(desktop ? 'interface:base.BannerGosuslugi.lblSubtitle' : 'interface:base.BannerGosuslugi.lblSubtitleMobile')}
        </Typography>
      </Box>
      <Button disabled={!esiaUrl} variant='contained' sx={{ marginTop: '8px' }} href={esiaUrl}>
        {t(desktop ? 'interface:base.BannerGosuslugi.btnAuth' : 'interface:base.BannerGosuslugi.btnAuthMobile')}
      </Button>
    </Box>
  );
};

export default BannerGosuslugi;
