import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, Grid, Paper, TextField, Typography, useTheme } from '@mui/material';
import { ECompanyAddressType, ECompanyDataType, ECompanyRequisiteType } from 'api/services/businessCredit/businessCredit.enum';
import { BusinessCompanyProfileSchema, companyProfileFormSchema } from 'api/services/businessCredit/businessCredit.schema';
import { userUpdateInfo } from 'api/services/user';
import BaseButtonBack from 'components/base/Button/Back';
import { MASK, maskComponent } from 'components/base/FormGenerator/Input/inputMask';
import BicInputSuggestion from 'components/base/Suggestion/BicInput';
import WithSkeleton from 'components/base/WithSkeleton';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useAuth } from 'components/providers/AuthProvider';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import useTypedParams from 'hooks/useTypedParams';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { Fragment, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  PATH_BUSINESS_CREDIT_ENTRY_COMPANY,
  PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS,
  PATH_BUSINESS_CREDIT_ENTRY_EDIT,
  PATH_HOME,
} from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

export default function BusinessCreditCompanyPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useTypedParams<typeof PATH_BUSINESS_CREDIT_ENTRY_COMPANY>();
  const { t } = useTranslation();

  const { control, handleSubmit, getValues, reset, formState, watch } = useForm<BusinessCompanyProfileSchema>({
    reValidateMode: 'onChange',
    resolver: zodResolver(companyProfileFormSchema),
    mode: 'all',
  });

  const theme = useTheme();
  const businessCredit = useBusinessCredit();

  const submitData = async () => {
    const values = getValues();

    const balance = Object.values(values.company.balance);

    const [firstname, lastname, patronymic] = (values.user.name as unknown as string).split(' ');
    const updatedCompany: any = {
      ...values.company,
    };

    updatedCompany.balance = balance;

    try {
      await Promise.all([
        businessCredit.updateCompanyRequest(businessCredit.entry?.entry.uuid!, updatedCompany as any),
        userUpdateInfo({ firstname, lastname, patronymic, email: values.user.email! }),
      ]);
      navigate(
        utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS, {
          uuidEntry: businessCredit.entry?.entry.uuid!,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth.user) {
      businessCredit.loadEntryRequest({ uuidEntry: params.uuidEntry! }, (entryData) => {
        //transform balance from array to object
        const balance = Object.keys(ECompanyDataType).reduce((acc, cur) => {
          const founded = entryData.company?.balance?.find((x) => x.type === cur);
          return { ...acc, [cur]: founded || { value: '', type: cur } };
        }, {});

        reset({
          user: { email: auth.user?.email!, name: utils.format.getFullName(auth.user) as any },
          company: {
            ...entryData.company,
            balance,
          },
        });
      });
    }
  }, [auth.user]);

  const company = useMemo(() => {
    const companyData = businessCredit.entry?.company;
    const requisites = companyData?.requisite.reduce((acc, cur) => ({ ...acc, [cur.type]: cur.data }), {}) as Record<
      ECompanyRequisiteType,
      string
    >;
    const legalAddress = companyData?.addresses?.find((address) => address.type === ECompanyAddressType.legalAddress) as unknown as {
      fullAddress: string;
    };
    const balance = Object.keys(ECompanyDataType).reduce((acc, cur) => {
      const founded = companyData?.balance?.find((x) => x.type === cur);
      return { ...acc, [cur]: founded || { value: '', type: cur } };
    }, {}) as Record<ECompanyDataType, { value: string; type: ECompanyDataType }>;

    return { ...companyData, requisites, balance, legalAddress } as const;
  }, [businessCredit.loading, businessCredit.entry]);

  const bankAccountValue = watch('company.bankAccount');

  const errors = Object.values(formState.errors).length > 0;

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreatePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreatePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.BusinessCreditCompanyPage.title')} />,
        lg: (
          <TopMenu
            sx={{ zIndex: 9999 }}
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },
              {
                title: t('interface:page.BusinessCreditCompanyPage.creditParams'),
                href: utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_EDIT, { uuidEntry: params.uuidEntry! }),
              },
              { title: t('interface:page.BusinessCreditCompanyPage.title'), activeLink: true },
            ]}
          />
        ),
      }}
      sx={{ height: 1, width: 1 }}
      maxWidth='xl'
    >
      <Container
        maxWidth='md'
        component='form'
        onSubmit={handleSubmit(submitData)}
        sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>{t(`interface:page.BusinessCreditCompanyPage.contactData`)}</Typography>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='user.email'
                control={control}
                rules={{ required: true, pattern: /^\S+@\S+$/i }}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('interface:page.AuthRegisterPage.Form.profile.Email')}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.email' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  />
                )}
              />
            </WithSkeleton>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='user.name'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t(`interface:page.BusinessCreditCompanyPage.fullName`)}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  />
                )}
              />
            </WithSkeleton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                display: 'flex',
                width: '100%',
                direction: 'column',
                padding: '24px',
                borderRadius: '16px',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>{t(`interface:page.BusinessCreditCompanyPage.company.title`)} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.companyName')}
                  </Typography>
                  <WithSkeleton loading={!company.name}>
                    <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                      {company.name}
                    </Typography>
                  </WithSkeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.inn')}
                  </Typography>
                  <WithSkeleton loading={!company.requisites?.inn}>
                    <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                      {company.requisites?.inn}
                    </Typography>
                  </WithSkeleton>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.address')}
                  </Typography>
                  <WithSkeleton loading={!company?.legalAddress?.fullAddress}>
                    <Typography variant='h5m' sx={{ fontSize: '14px' }}>
                      {company.legalAddress?.fullAddress}
                    </Typography>
                  </WithSkeleton>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <WithSkeleton loading={!company.requisites?.inn}>
                    <Fragment>
                      <Controller
                        name='company.bankAccount'
                        control={control}
                        render={({ field, fieldState }) => {
                          return (
                            <BicInputSuggestion
                              label={t(`interface:page.BusinessCreditCompanyPage.company.bicSuggestion`)}
                              variant='standard'
                              data-testid={'bicSuggestion'}
                              error={!!fieldState.error}
                              helperText={t(`${fieldState.error?.message || ''}`)}
                              onSelect={(optionData) => {
                                field.onChange({
                                  bankName: optionData?.value,
                                  bic: optionData?.data?.bic,
                                  bankAddress: optionData?.data?.address?.value,
                                });
                              }}
                            />
                          );
                        }}
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                            {t(`interface:page.BusinessCreditCompanyPage.company.bankName`)}
                          </Typography>

                          <WithSkeleton loading={!bankAccountValue?.bankName}>
                            <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                              {bankAccountValue?.bankName}
                            </Typography>
                          </WithSkeleton>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                            {t(`interface:page.BusinessCreditCompanyPage.company.bic`)}
                          </Typography>

                          <WithSkeleton loading={!bankAccountValue?.bic}>
                            <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                              {bankAccountValue?.bic!}
                            </Typography>
                          </WithSkeleton>
                        </Grid>
                      </Grid>
                    </Fragment>
                  </WithSkeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <WithSkeleton loading={!company.requisites?.inn}>
                    <Controller
                      name='company.bankAccount.bankAccount'
                      control={control}
                      render={({ field, fieldState }) => {
                        const f = {
                          ...field,
                          value: field?.value ? String(field.value) : '',
                          onChange: (e: ObjectType) => {
                            const v = e.target.value;
                            field.onChange(v?.replaceAll(' ', ''));
                          },
                        };
                        return (
                          <TextField
                            label={t('interface:page.BusinessCreditCompanyPage.company.bankAccount')}
                            InputProps={
                              {
                                ...maskComponent(MASK.BANK_ACCOUNT, {
                                  ...f,
                                  inputMode: 'numeric',
                                }),
                              } as any
                            }
                            variant='standard'
                            fullWidth
                            error={!!fieldState.error}
                            {...field}
                            helperText={t(`${fieldState.error?.message || ''}`)}
                          />
                        );
                      }}
                    />
                  </WithSkeleton>
                </Grid>
                {company.type !== 'legalEntity' && (
                  <Fragment>
                    <Grid item xs={12} md={6}>
                      <WithSkeleton loading={!company.balance.revenueFigureLastYear}>
                        <Controller
                          name='company.balance.revenueFigureLastYear.value'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <NumberFormat
                                customInput={TextField}
                                label={t(`common:documents.revenueFigureLastYear.title`, company.balance.revenueFigureLastYear)}
                                id='mortgage.subject.subjectPrice'
                                variant='standard'
                                suffix=' ₽'
                                fullWidth
                                required
                                thousandSeparator=' '
                                value={field.value}
                                onChange={(e: ObjectType) => {
                                  field.onChange(utils.format.digitNumber(e.target.value));
                                }}
                                error={!!fieldState.error}
                                helperText={t(`${fieldState.error?.message || ''}`)}
                                inputProps={{ ['data-testid']: field.name }}
                              />
                            );
                          }}
                        />
                      </WithSkeleton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <WithSkeleton loading={!company.balance.revenueFigureLastQuartal}>
                        <Controller
                          name='company.balance.revenueFigureLastQuartal.value'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <NumberFormat
                                customInput={TextField}
                                label={t(`common:documents.revenueFigureLastQuartal.title`, company.balance.revenueFigureLastQuartal)}
                                id='mortgage.subject.subjectPrice'
                                variant='standard'
                                suffix=' ₽'
                                fullWidth
                                required
                                thousandSeparator=' '
                                value={field.value}
                                onChange={(e: ObjectType) => {
                                  field.onChange(utils.format.digitNumber(e.target.value));
                                }}
                                error={!!fieldState.error}
                                helperText={t(`${fieldState.error?.message || ''}`)}
                                inputProps={{ ['data-testid']: field.name }}
                              />
                            );
                          }}
                        />
                      </WithSkeleton>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              size='large'
              type='submit'
              // onClick={submitData}
              data-testid='mortgage.create'
              disabled={errors}
            >
              {t('interface:page.BusinessCreditCompanyPage.continue')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
}
