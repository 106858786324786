/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const MenuIcon = createSvgIcon(
  <svg viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 1H17M1 15H17M1 8H17' stroke='#0C0B02' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'MenuIcon',
);

export default MenuIcon;
