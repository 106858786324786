import { Grid, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import utils from 'utils';
import { ObjectType } from 'utils/iots';
import type { ECMortgageEntryParams } from 'api/services/mortgage/mortgage.schema';
import { EMortgageEntryInitialPaySourceType } from 'api/services/mortgage.dto';
import { EMortgageEntryParamsPurpose } from 'api/services/mortgage/mortgage.enum';
import { useMemo } from 'react';

export default function MortgageEntryCalculation() {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<ECMortgageEntryParams>();

  const purpose = watch('purpose');

  const isShowStatePrograms = useMemo(() => {
    return ![EMortgageEntryParamsPurpose.homeownerLoan].includes(purpose);
  }, [purpose]);

  const isShowMotherCapital = useMemo(() => {
    return ![EMortgageEntryParamsPurpose.homeownerLoan, EMortgageEntryParamsPurpose.refinanceMortgage].includes(purpose);
  }, [purpose]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4m">{t('interface:page.ClientMortgageEntryCreatePage.form.creditParams')}</Typography>
      </Grid>
      <Grid item xs={6} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          name="subject.price"
          control={control}
          render={({ field, fieldState }) => (
            <NumberFormat
              id="mortgage.subject.subjectPrice"
              label={t('interface:page.ClientMortgageEntryCreatePage.form.subjectPrice')}
              variant='standard'
              suffix=' ₽'
              fullWidth
              customInput={TextField}
              required
              thousandSeparator=' '
              value={field.value}
              onChange={(e: ObjectType) => {
                field.onChange(utils.format.digitNumber(e.target.value));
              }}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ ['data-testid']: field.name }}
            />
          )}
        />
      </Grid>
      {isShowStatePrograms && (
        <Grid item xs={6} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Controller
            name="initialPayment"
            control={control}
            render={({ field, fieldState }) => (
              <NumberFormat
                id="mortgage.initialPayment"
                label={t('interface:page.ClientMortgageEntryCreatePage.form.initialPayment')}
                variant='standard'
                suffix=' ₽'
                fullWidth
                customInput={TextField}
                required
                thousandSeparator=' '
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
                inputProps={{ ['data-testid']: field.name }}
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Controller
          name="mortgagePeriod"
          control={control}
          render={({ field, fieldState }) => (
            <NumberFormat
              required
              label={t('interface:page.ClientMortgageEntryCreatePage.form.mortgagePeriod')}
              variant="standard"
              inputProps={{ ['data-testid']: field.name }}
              suffix=' лет.'
              fullWidth
              customInput={TextField}
              value={field.value}
              onChange={(e: ObjectType) => {
                field.onChange(utils.format.digitNumber(e.target.value));
              }}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
            />
          )}
        />
      </Grid>

      {isShowStatePrograms ||
        (isShowMotherCapital && (
          <Grid container item xs={12} md={6}>
            <Grid item>
              <Controller
                name="initialPaymentSources.0"
                control={control}
                render={({ field, fieldState }) => (
                  <NumberFormat
                    variant='standard'
                    suffix=' ₽'
                    fullWidth
                    customInput={TextField}
                    label={t('interface:page.ClientMortgageEntryCreatePage.form.maternityCapital')}
                    thousandSeparator=' '
                    value={field.value?.amount}
                    onChange={(e: ObjectType) => {
                      field.onChange({
                        type: EMortgageEntryInitialPaySourceType.maternityCapital,
                        amount: utils.format.digitNumber(e.target.value),
                      });
                    }}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}
