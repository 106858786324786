import { Box, SvgIcon, Typography, useTheme } from '@mui/material';
import MenuIcon from 'components/Icons/Menu';
import { useMenu } from 'components/providers/BaseMenuProvider';
import PropTypes from 'prop-types';

const Header = ({ title, sx = {} }) => {
  const { toggleMenu } = useMenu();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexShrink: 0,
        minHeight: '48px',
        alignItems: 'center',
        height: '48px',
        margin: '16px 0',
        position: 'relative',
        ...sx,
      }}
    >
      <SvgIcon
        fontSize='small'
        sx={{ position: 'absolute' }}
        stroke={`${theme.palette.text.primary}`}
        component={MenuIcon}
        onClick={toggleMenu}
      />
      <Typography sx={{ width: 1 }} variant='h5' textAlign='center' noWrap component='p'>
        {title}
      </Typography>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.any,
};

export default Header;
