import { Button, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { userGetContractByType, userSetContractDetails } from 'api/services/user';
import { EExperimentName } from 'api/services/user.dto';
import { MASK, maskComponent } from 'components/base/FormGenerator/Input/inputMask';
import UploadPassport from 'components/base/UploadPassport';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';

const CONTRACT_TYPE = 'agentContract';
const DEFAULT_VALUES = {
  docSeries: '',
  docNo: '',
  issueDate: '',
  issueUnit: '',
  issuedBy: '',
  birthPlace: '',
  birthDate: '',
};

const UserPassportPage = () => {
  const { t } = useTranslation();
  const { isSuccess, data } = useFetch(() => userGetContractByType(CONTRACT_TYPE));
  const [isEditMode, setIsEditMode] = useState(false);
  const { handleSubmit, reset, control, getValues } = useForm({ defaultValues: DEFAULT_VALUES });
  const { hasExperiment } = useAuth();

  useEffect(() => {
    if (isSuccess) reset(data?.details?.passport || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };
  const handleSubmitClick = () => {
    handleSubmit((data) => {
      // TODO: Error processing
      userSetContractDetails(CONTRACT_TYPE, { passport: data });
      setIsEditMode(false);
    })();
  };

  // TODO: Rewrite this ugly mapper
  const handlePassportOCR = (doc) => {
    reset({
      ...getValues(),
      docSeries: (doc?.data?.seriesNumber || '').split(' ')[0],
      docNo: (doc?.data?.seriesNumber || '').split(' ')[1],
      issueDate: doc?.data?.issueDate,
      issueUnit: doc?.data?.issueUnit,
      issuedBy: doc?.data?.issueBy,
      birthDate: doc?.data?.birthDate,
      birthPlace: doc?.data?.birthPlace,
    });
  };

  return (
    <Page
      title={t('helmet:UserPassportPage.title')}
      meta={[{ name: 'description', content: t('helmet:UserPassportPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.UserPassportPage.headerTitle'), backRoute: PATH_USER }}
    >
      <Box sx={{ display: 'flex', height: 1, flexDirection: 'column', justifyContent: 'space-between', gap: 4 }}>
        {hasExperiment(EExperimentName.ocr) && <UploadPassport onChange={handlePassportOCR} />}
        <Stack spacing={2}>
          <Stack direction='row' spacing={2}>
            <Controller
              name='docSeries'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isEditMode}
                  variant='standard'
                  label={t('interface:page.UserPassportPage.docSeries')}
                  InputProps={maskComponent(MASK.PASSPORT_SERIES, field)}
                />
              )}
            />
            <Controller
              name='docNo'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isEditMode}
                  variant='standard'
                  label={t('interface:page.UserPassportPage.docNo')}
                  InputProps={maskComponent(MASK.PASSPORT_NUMBER, field)}
                />
              )}
            />
          </Stack>

          <Stack direction='row' spacing={2}>
            <Controller
              name='issueDate'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isEditMode}
                  variant='standard'
                  label={t('interface:page.UserPassportPage.issueDate')}
                  InputProps={maskComponent(MASK.DATE_STRING, field)}
                />
              )}
            />
            <Controller
              name='issueUnit'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isEditMode}
                  variant='standard'
                  label={t('interface:page.UserPassportPage.issueUnit')}
                  InputProps={maskComponent(MASK.PASSPORT_ISSUE_UNIT, field)}
                />
              )}
            />
          </Stack>

          <Controller
            name='issuedBy'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isEditMode}
                variant='standard'
                label={t('interface:page.UserPassportPage.issuedBy')}
                InputProps={maskComponent(MASK.UPPERCASE, field)}
              />
            )}
          />
          <Controller
            name='birthPlace'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isEditMode}
                variant='standard'
                label={t('interface:page.UserPassportPage.birthPlace')}
                InputProps={maskComponent(MASK.UPPERCASE, field)}
              />
            )}
          />
          <Controller
            name='birthDate'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isEditMode}
                variant='standard'
                label={t('interface:page.UserPassportPage.birthDate')}
                InputProps={maskComponent(MASK.DATE_STRING, field)}
              />
            )}
          />
        </Stack>

        <Stack sx={{ margin: '20px 0' }} spacing={2}>
          {!isEditMode && (
            <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
              {t('interface:page.UserPassportPage.editBtn')}
            </Button>
          )}

          {isEditMode && (
            <Button variant='contained' size='large' onClick={handleSubmitClick}>
              {t('interface:page.UserPassportPage.submitBtn')}
            </Button>
          )}

          {isEditMode && (
            <Button variant='contained' color='secondary' size='large' onClick={handleEditClick}>
              {t('interface:page.UserPassportPage.cancelBtn')}
            </Button>
          )}
        </Stack>
      </Box>
    </Page>
  );
};

export default UserPassportPage;
