import { ApiError } from 'api/errors';
import { DocumentProgress, DocumentSource } from 'api/services/experiment/experiment.dto';
import _ from 'lodash';
import { useState } from 'react';

import { IDocumentService } from './types';

export enum DocumentLoadingState {
  loading = 'loading',
  loaded = 'loaded',
  recognition = 'recognition',
}

function useRequiredDocuments<T>(apiParams: T, service: IDocumentService<T>) {
  const [apiError, setApiError] = useState<ApiError>();
  const [requiredDocuments, setRequiredDocuments] = useState<DocumentProgress[]>([]);

  const [canSubmit] = useState(false);
  const loadAsync = async () => {
    try {
      const { body: documentResponse } = await service.requiredDocumentsRequest(apiParams);
      setRequiredDocuments(documentResponse);
    } catch (err) {
      setApiError(err);
    }
  };

  return { loadAsync, requiredDocuments, apiError, canSubmit };
}

/**
 * hook to provide file management and execution methods for these files
 * @param apiParams
 * @returns
 */
function useFileManager<T>(apiParams: T, updateCallback: () => Promise<void>, service: IDocumentService<T>) {
  const [loading, setLoading] = useState<DocumentLoadingState>();
  const [, setApiError] = useState<ApiError>();
  const [documents, setDocuments] = useState<DocumentSource[]>([]);

  const cleanError = () => {
    setApiError(undefined);
  };
  /**
   * execute load async to preload all documents and transform to descriptor
   */
  const loadAsync = async (): Promise<void> => {
    cleanError();
    try {
      const { body: resDocuments } = await service.allDocumentsRequest(apiParams);
      setDocuments(resDocuments);
      await updateCallback();
    } catch (err) {
      setApiError(err);
    } finally {
      setLoading(DocumentLoadingState.loaded);
    }
  };
  /**
   * execute delete of file descriptor
   *
   * but has 2 cases:
   * 1. if delete document with some links and EProfileDocumentType===other will be execute update method
   * 2.
   * @param uuidFile uuid file descriptor
   * @returns {Promise<void>}
   */
  const deleteFile = async (uuidFile: string): Promise<void> => {
    cleanError();
    try {
      await service.deleteDocumentRequest(apiParams, { uuidDocument: uuidFile });
    } catch (err) {
    } finally {
      await loadAsync();
      setLoading(DocumentLoadingState.loaded);
    }
  };

  /**
   * update file method to update file type or file type
   * @param descriptor
   * @returns
   */
  const updateFile = async (_uuidFile: string, source: DocumentSource): Promise<void> => {
    setLoading(DocumentLoadingState.loading);

    try {
      await service.putDocumentRequest(apiParams, source);
    } catch (err) {
      setLoading(DocumentLoadingState.loaded);
    } finally {
      await loadAsync();
      setLoading(DocumentLoadingState.loaded);
    }
  };

  /**
   *
   * @param file  file to upload document
   * @returns
   */
  const uploadFile = async (file: File): Promise<void> => {
    if (!file) {
      return;
    }
    setLoading(DocumentLoadingState.loading);
    try {
      await service.uploadDocument(apiParams, file);
    } catch (err) {
      setApiError(err);
      await loadAsync();
    } finally {
      setLoading(DocumentLoadingState.loaded);
      await loadAsync();
    }
  };

  return { loadAsync, deleteFile, updateFile, uploadFile, documents, loading };
}

export default { useFileManager, useRequiredDocuments };
