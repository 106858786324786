//@ts-ignore
import { registerSW } from 'virtual:pwa-register';
//@ts-ignore
const intervalMS = 60 * 60 * 1000;

function ServiceWorker() {
  registerSW({
    onRegisteredSW(swUrl: string, r?: ServiceWorkerRegistration) {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return;
          if ('connection' in navigator && !navigator.onLine) return;
          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });
          if (resp?.status === 200) await r.update();
        }, intervalMS);
    },
  });
  return null;
}

export default ServiceWorker;
