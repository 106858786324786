import { Box, Button } from '@mui/material';
import { ApiError } from 'api/errors';
import { getMortgageInsurance, postUpdateMortgageInsurance, postUpdateMortgagePerils } from 'api/services/insurance/insurance';
import { InsuranceSchema } from 'api/services/insurance/insurance.dto';
import { useGetProgressQuery } from 'api/services/mortgage/mortage.queries';
import BaseButtonBack from 'components/base/Button/Back';
import ErrorMessage from 'components/base/ErrorMessage';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useNavigateParams from 'hooks/useNavigateParams';
import useTypedParams from 'hooks/useTypedParams';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useLocation } from 'react-router-dom';
import {
  InsuranceGroupNames,
  PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE,
  PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE_GROUP,
  PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS,
  PATH_HOME,
} from 'routes/paths';
import utils from 'utils';

import { Step, StepMap } from './constants/stepMap';
import { useGetStepMap } from './lib/useGetStepMap';
import { useTranslation } from 'react-i18next';

export default function MortgageEntryInsurancePage() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<Step>();
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [error, setError] = useState<ApiError>();
  const [progressDisabled, setProgressDisabled] = useState<boolean>(false);
  const { uuidEntry, uuidUser } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE>();
  const navigate = useNavigateParams();

  const stepMap = useGetStepMap();

  const location = useLocation();
  const groupName = location.pathname.split('/').slice(-1)[0] as unknown as InsuranceGroupNames;

  const hookForm = useForm<InsuranceSchema>({ shouldUnregister: true });
  const { isValid } = hookForm.formState;

  const { data: progress } = useGetProgressQuery(uuidEntry, groupName);

  useEffect(() => {
    if (progress?.insuranceMortgage) {
      const findStepWithProposalWaiting = (stepMap: StepMap): string | undefined => {
        for (const [key, value] of Object.entries(stepMap)) {
          if (value.nextStep === InsuranceGroupNames.PROPOSAL_WAITING) {
            return key;
          }
        }
        return undefined;
      };

      const stepWithProposalWaiting = findStepWithProposalWaiting(stepMap);

      const disabled =
        Object.keys(stepMap)
          .map((step) => {
            if (
              step === InsuranceGroupNames.COMPANY ||
              step === InsuranceGroupNames.PAYMENT ||
              step === InsuranceGroupNames.PROPOSAL_WAITING
            ) {
              return;
            }
            // @ts-ignore
            return progress.insuranceMortgage[step] < 100;
          })
          .filter((item) => item !== undefined)
          .find((item) => item === true) && groupName === stepWithProposalWaiting;

      setProgressDisabled(!!disabled);
    }
  }, [progress?.insuranceMortgage, groupName]);

  const handleNavigateHome = () => {
    if (Object.keys(hookForm.getValues()).length !== 0) {
      handleSubmit(hookForm.getValues());
    }
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: uuidEntry } });
  };

  const handleNavigateNext = () => {
    const newStep = stepMap[groupName];
    setCurrentStep(newStep);
    if (newStep !== undefined) {
      navigate(PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE_GROUP, { params: { uuidEntry, uuidUser, groupName: newStep.nextStep } });
    }
  };

  useEffect(() => {
    setCurrentStep(stepMap[groupName]);
  }, [stepMap, groupName]);

  const fetchPost = (values: any) => {
    if (groupName === InsuranceGroupNames.BASE_DATA) {
      return;
    }

    if (groupName === InsuranceGroupNames.PROGRAM || groupName === InsuranceGroupNames.CREDIT_CONTRACT) {
      postUpdateMortgageInsurance(uuidEntry!, values);
    } else {
      postUpdateMortgagePerils(uuidEntry!, values);
    }
  };

  const handleSubmit = (values: any) => {
    if (groupName === InsuranceGroupNames.PROPOSAL_WAITING) {
      handleNavigateHome();
    } else {
      fetchPost(values);
      handleNavigateNext();
    }
  };

  const fetchAndSetPaymentLink = async () => {
    try {
      const data = await getMortgageInsurance(uuidEntry!);

      setPaymentLink(data.body.primaryPropose.payment.data.url);
    } catch (error) {
      setError(error as ApiError);
      console.error(error);
    }
  };

  useEffect(() => {
    const currentStep = stepMap[groupName];

    if (groupName === InsuranceGroupNames.PAYMENT) {
      fetchAndSetPaymentLink();
    }

    setCurrentStep(currentStep);
  }, [groupName]);

  const breadcrumbs = [
    { title: t('interface:page.MortgageEntryInsurancePage.breadcrumbs.main'), href: PATH_HOME },
    {
      title: t('interface:page.MortgageEntryInsurancePage.breadcrumbs.progress'),
      href: utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: uuidEntry }),
    },
    { title: t(`interface:page.MortgageEntryInsurancePage.title`), activeLink: true },
    { title: t(`interface:page.MortgageEntryInsurancePage.form.${groupName}.title`), activeLink: true },
  ];

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <ContainerLayout
      title={t('helmet:MortgageEntryInsurancePage.title')}
      meta={[{ name: 'description', content: t('helmet:MortgageEntryInsurancePage.description') }]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.MortgageEntryInsurancePage.form.${groupName}.title`)}
            subtitle={t(`interface:page.MortgageEntryInsurancePage.title`)}
          />
        ),
        lg: <TopMenu breadcrumbs={breadcrumbs} />,
      }}
      sx={{ height: 1 }}
    >
      <FormProvider {...hookForm}>
        <Box
          component='form'
          sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column' }}
          onSubmit={hookForm.handleSubmit(handleSubmit)}
        >
          <Box sx={{ flexGrow: 0.8 }}>
            <Outlet />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              gap: '16px',
              marginBottom: '24px',
              alignSelf: 'flex-end',
              marginTop: '24px',
            }}
          >
            {currentStep?.nextButton && !paymentLink && (
              <Button
                disabled={!isValid || progressDisabled}
                fullWidth
                variant='contained'
                size='large'
                type='submit'
                data-testid='nextStep'
              >
                {t(`interface:page.MortgageEntryInsurancePage.form.${currentStep?.nextStep}.step`)}
              </Button>
            )}
            {paymentLink && (
              <Button href={paymentLink} fullWidth variant='contained' size='large' type='button' data-testid='nextStep'>
                {t(`interface:page.MortgageEntryInsurancePage.form.${groupName}.step`)}
              </Button>
            )}
            {currentStep?.backButton && (
              <Button
                onClick={handleNavigateHome}
                type={'submit'}
                fullWidth
                variant='contained'
                color='secondary'
                size='large'
                data-testid='saveAndContinueBtn'
              >
                {t('interface:page.MortgageEntryInsurancePage.saveAndContinueBtn')}
              </Button>
            )}
          </Box>
        </Box>
      </FormProvider>
    </ContainerLayout>
  );
}
