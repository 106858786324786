import { req } from 'api/http';
import { Bank, BankList } from 'api/services/creditProduct/creditProduct.dto';

export const getBankList = () => {
  return req<null, BankList>('creditProduct/bank/list', { method: 'GET' });
};

export const getBankByName = (name: string) => {
  return req<null, Bank>(`creditProduct/bank/name/${name}`, { method: 'GET' });
};
