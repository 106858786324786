import { Box, Chip, Container, Paper, Skeleton, SvgIcon, Typography, useTheme } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import WraningIcon from 'components/Icons/Warning';
import BaseAccordion from 'components/base/Accordion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import WorkIcon from '@mui/icons-material/Work';
import { ICONS_COMPANY } from '../../../utils/iconsCompany';
import BottomPopup from '../BottomPopup';

// TODO: Refactor markup, positions, sx
const InsuranceProductCard = ({ item, button, ...props }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = useState(false);

  const handleDescription = () => setShowDescription(!showDescription);

  // TODO: Plurals
  const humanizedPeriod = (m) => {
    let str = '';
    const { years, months } = utils.date.yearsCalculate(m);
    str += years ? `${years} год` : '';
    str += months ? ` ${months} месяц` : '';
    return str;
  };

  const hasDescription = !!item?.description;
  const insurancePrice = item?.perils.reduce((acc, { amount }) => amount + acc, 0);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '200px',
        padding: '24px',
        borderRadius: '16px',
        // width: { md: 1, lg: 0.5 },
      }}
      variant='outlined'
      {...props}
    >
      <BaseAccordion
        handleBtnClick={button.handleClick}
        uuidPackage={item?.uuid}
        btnTitle={t('interface:base.InsuranceProductCard.btnOrder')}
      >
        <MuiAccordionSummary sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Product header */}
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {item ? (
                <SvgIcon component={ICONS_COMPANY[item.company]} sx={{ width: '50px', height: '50px' }} />
              ) : (
                <Skeleton animation='wave' variant='circular' width={50} height={50} />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {item ? (
                  <Typography variant='h5m'>{t(`common:insurance.companies.${item.company}`)}</Typography>
                ) : (
                  <Skeleton animation='wave' height={20} width='150px' />
                )}
                {item ? (
                  <Typography variant='h6' sx={{ color: `${theme.palette.text.secondary}` }}>
                    {item?.perils?.length > 1
                      ? t('common:insurance.perils.complex')
                      : t(`common:insurance.perils.${item?.perils[0]?.type}`)}
                  </Typography>
                ) : (
                  <Skeleton animation='wave' height={20} width='80%' />
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', gap: '6px' }}>
              {/* Product name */}
              <Typography sx={{ marginTop: '16px' }} variant='h5'>
                {item ? item.title : <Skeleton animation='wave' height={25} width='150px' />}
              </Typography>

              {/* Product tags */}
              {(item?.tags ?? []).map((name) => (
                <Chip
                  key={name}
                  label={name}
                  sx={{
                    // TODO: Use ChipPropsColorOverrides
                    borderRadius: '8px',
                    background: theme.palette.common.tariffBages[name],
                    color: theme.palette.common.tariffBages[`${name}Text`],
                    textTransform: 'capitalize',
                  }}
                />
              ))}
            </Box>

            {/* Main condition */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
              <Typography variant='span' sx={{ marginRight: '24px' }}>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='h6' data-testid={'btnOrder'}>
                  {t('interface:base.InsuranceProductCard.insuranceCost')}
                </Typography>
                <Typography variant='h2m' sx={{ fontSize: '26px' }}>
                  {item ? utils.format.price(item?.price / 100) : <Skeleton animation='wave' height={30} width='100px' />}
                </Typography>
              </Typography>
              <Typography variant='span'>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                  {t('interface:base.InsuranceProductCard.insuranceAmount')}
                </Typography>
                <Typography variant='h2m' sx={{ fontSize: '26px' }}>
                  {item ? utils.format.price(insurancePrice / 100) : <Skeleton animation='wave' height={30} width='100px' />}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </MuiAccordionSummary>
        <MuiAccordionDetails sx={{ padding: 0 }}>
          {item?.perils.map(({ type, amount }) => (
            <Box key={type} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
              <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
                {t(`common:insurance.perils.${type}`)}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography variant='h6' sx={{ color: theme.palette.text.primary }}>
                  {utils.format.price(amount / 100)}
                </Typography>
                {/* TODO: <SvgIcon component={Warning} /> */}
              </Box>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
            <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
              {t(`interface:base.InsuranceProductCard.beginningContract`)}
            </Typography>
            <Typography variant='h6' sx={{ color: theme.palette.text.primary }}>
              {t('interface:base.InsuranceProductCard.dayOfTheAgreement', { daysStart: item?.condition?.daysBeforeStart })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
            <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
              {t(`interface:base.InsuranceProductCard.validityPeriod`)}
            </Typography>
            <Typography variant='h6' sx={{ color: theme.palette.text.primary }}>
              {humanizedPeriod(item?.condition?.policyPeriodMonths || 0)}
            </Typography>
          </Box>
          {hasDescription && (
            <Container
              onClick={handleDescription}
              elevation={0}
              sx={{
                width: 1,
                background: theme.palette.common.softYellow,
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px 16px',
              }}
            >
              <Typography variant='h6' sx={{ color: theme.palette.primary.contrastText }}>
                {item?.description?.title}
              </Typography>
              <SvgIcon sx={{ height: '20px', width: '20px' }} component={WraningIcon} />
            </Container>
          )}
        </MuiAccordionDetails>
      </BaseAccordion>
      <BottomPopup
        title={item?.description?.title}
        body={item?.description?.body}
        visible={showDescription}
        onCloseClick={handleDescription}
      />
    </Paper>
  );
};

InsuranceProductCard.propTypes = {
  item: PropTypes.shape({
    company: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    perils: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      }),
    ),
    price: PropTypes.number.isRequired,
    condition: PropTypes.shape({
      daysBeforeStart: PropTypes.number.isRequired,
      policyPeriodMonths: PropTypes.number.isRequired,
    }),
    description: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),

  button: PropTypes.shape({
    handleClick: PropTypes.func,
  }),
};

export default InsuranceProductCard;
