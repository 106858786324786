import AddIcon from '@mui/icons-material/Add';
import { Button, TextField } from '@mui/material';
import { getClients } from 'api/services/agent';
import SearchIcon from 'components/Icons/Search';
import BaseButtonListItem from 'components/base/Button/ListItem';
import Page from 'components/layouts/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE, PATH_AGENT_INSURANCE_PROPERTY_ORDER_CREATE } from 'routes/paths';
import { getFilteredArrayOfOjects } from 'utils/filter';

export interface IAgentClient {
  uuid: string;
  firstname?: string;
  lastname?: string;
  middlename?: string;
  tags: string[];
}

export type TLocationParams = {
  uuidPackage: string;
};

// TODO: Need refactoring with i18n
// TODO: Need refactoring in this component

const AgentInsurancePropertyOrderSelectClientPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuidPackage } = useParams<keyof TLocationParams>() as TLocationParams;

  const [clients, setClients] = useState<null | IAgentClient[]>(null);
  const [filtered, setFiltered] = useState<null | IAgentClient[]>([]);
  const [queryFilter, setQueryFilter] = useState('');
  const onAddUserClick = () => navigate(PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE);

  const loadClients = () => {
    getClients([], 'createdAt:desc')
      .then((res) => {
        // TODO: Typescript for API
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const {
          body: { results = [] } = {},
        } = res;
        setClients(results);
        setFiltered(results);
      })
      .catch((err) => {
        // TODO: Error handling
        console.error(err);
      });
  };

  // Effect for load clients
  useEffect(() => {
    if (!clients) {
      loadClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  // Effect for query str change
  useEffect(() => {
    if (clients) {
      setFiltered(getFilteredArrayOfOjects(clients, ['address', 'firstname', 'lastname', 'patronymic'], queryFilter) as IAgentClient[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilter]);

  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryFilter(e.target.value);
  };

  const handleClientClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedClientUuid = e.currentTarget.value;
    const client = clients?.find(({ uuid }) => uuid === selectedClientUuid);
    if (!client) return; // TODO: Error processing?
    navigate(PATH_AGENT_INSURANCE_PROPERTY_ORDER_CREATE.replace(':uuidPackage', uuidPackage).replace(':uuidProfile', selectedClientUuid));
  };

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyOrderSelectClientPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyOrderSelectClientPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.AgentInsurancePropertyOrderSelectClientPage.headerTitle') }}
      sx={{ height: 'none', paddingBottom: '100px' }}
    >
      <TextField placeholder={'Поиск'} onChange={handleChangeQuery} className='filter' InputProps={{ startAdornment: <SearchIcon /> }} />
      {filtered
        // eslint-disable-next-line max-len
        ?.filter(({ firstname, lastname, tags }) => firstname && lastname && !tags.includes('waitingPayment') && !tags.includes('paid')) // TODO: Find better solution
        .map((client) => (
          <BaseButtonListItem key={client.uuid} onClick={handleClientClick} value={client.uuid}>
            {client.lastname} {client.firstname} {client.middlename}
          </BaseButtonListItem>
        ))}
      <div style={{ position: 'fixed', left: 0, bottom: '20px', width: '100%', padding: '0 20px' }}>
        <Button variant='contained' size='large' type='submit' fullWidth startIcon={<AddIcon />} onClick={onAddUserClick}>
          {t('interface:page.AgentInsurancePropertyOrderSelectClientPage.addClientBtn')}
        </Button>
      </div>
    </Page>
  );
};

export default AgentInsurancePropertyOrderSelectClientPage;
