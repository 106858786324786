import { useCallback, useState } from 'react';

export default function useModal() {
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return { isOpen, open };
}
