import { Autocomplete, Box, SxProps, TextField, Typography } from '@mui/material';
import { IRequestResult } from 'api/http';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { ObjectType } from 'utils/iots';

interface Props<T> {
  path: string[];
  label?: string;
  value?: T;
  id?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  sx?: SxProps;
  disabled?: boolean;
  ['data-testid']?: string;
  onQuery?(q: string): Promise<IRequestResult<Array<T>>>;
  onInputChange?(value: string): void;
  onSelect?(option: T): void;
  variant: 'contained' | 'outlined' | 'filled' | 'standard';
}

export default function DadataSuggestion<T>(props: Props<T>) {
  const { path = [], error, value, helperText, onQuery, onSelect, ...otherProps } = props;

  const [query, setQuery] = useState<string>(value as string);
  const [options, setOptions] = useState<T[]>([]);

  const requestSuggestions = async (qw: string) => {
    try {
      const q = await onQuery?.(qw);
      setOptions(q?.body || []);
    } catch (err) {
      console.log(err);
    }
  };
  const throttled = useCallback(debounce(requestSuggestions, 300), []);

  const handleInputChange = useCallback(
    (_, val: unknown) => {
      if (typeof val === 'string') {
        setQuery(val);
        props.onInputChange?.(val);

        if (val.length > 3) {
          throttled(val);
        }
      }
    },
    [query],
  );

  const handleOnChange = useCallback((_, val: unknown) => {
    onSelect?.(val as T);
  }, []);

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      disablePortal
      getOptionLabel={(option) => ((option as any)?.name as string) || ''}
      filterOptions={(option) => option}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      freeSolo
      renderOption={(props, option: ObjectType) => {
        return (
          <li key={option.inn} {...props} data-testid={`${otherProps['data-testid']}.${option.inn}`}>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                padding: '8px',
                overflow: 'hidden',
                // whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography variant='h6'>
                {option.inn}&nbsp;&nbsp;{option.name}&nbsp;&nbsp;{option.address}
              </Typography>
            </Box>
          </li>
        );
      }}
      inputValue={query}
      renderInput={(params: ObjectType) => {
        return (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            placeholder={otherProps.placeholder}
            label={props.label}
            variant={props.variant}
            inputProps={{ ...params.inputProps, 'data-testid': `${otherProps['data-testid']}.input` }}
          />
        );
      }}
      options={options}
    />
  );
}
