import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Collapse, IconButton, PaletteColor, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ft } from 'components/base/FormGenerator/utils';
import StatusDot from 'components/base/StatusDot';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import iots, { ObjectType } from 'utils/iots';

function PartitionItem({
  fields,
  onSelect,
  path,
}: {
  fields: Record<string, ObjectType>;
  onSelect: (path: string) => void;
  path: string[];
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const values = useMemo(() => {
    return Object.entries(fields);
  }, []);
  const isArray = useCallback((key: string) => [...path, key].some((i) => parseInt(i, 10) >= 0), [path]);

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: (theme) => theme.palette.secondary.main,
        flexDirection: 'column',
        padding: '16px',
        borderRadius: '16px',
        gap: '12px',
      }}
    >
      {values.map(([key, item]) => {
        if (iots.isPrimitiveType(item)) {
          return (
            <Box
              key={item}
              onClick={() => onSelect(item)}
              sx={{
                width: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 8px',
                ':hover': {
                  background: '#efefef',
                  borderRadius: '16px',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', cursor: 'pointer' }}>
                <StatusDot color={theme.palette.error.main} />
                <Typography key={item} variant='h5'>
                  {t(`${ft(item.split('.'))}.title`)}
                </Typography>
              </Box>

              <IconButton>
                <ArrowForwardIosIcon fontSize='small' />
              </IconButton>
            </Box>
          );
        } else {
          return (
            <Fragment key={key}>
              {isArray(key) && (
                <Typography variant='h4'>
                  {t(`${ft([...path])}.title`)} {parseInt(key, 10) + 1}
                </Typography>
              )}
              {!isArray(key) && (
                <Typography variant='h4' fontWeight='bold'>
                  {t(`${ft([...path, key])}.title`)}
                </Typography>
              )}
              <PartitionItem onSelect={onSelect} fields={item} path={[...path, key]} />
            </Fragment>
          );
        }
      })}
      {/* <Typography variant='h4'>{t(`${ft(path.split('.'))}.title`)}</Typography>

       */}
    </Box>
  );
}

export default function Partition({
  name,
  status,
  value,
  fields,
  onSelect,
  path,
}: {
  name: string;
  status: unknown;
  value: string;
  fields: Record<string, object | string | []>;
  onSelect: (path: string) => void;
  path: string[];
}) {
  const theme = useTheme();
  const handleStatus = (type: keyof PaletteColor) => {
    if (status === 100) {
      return theme.palette.success[type];
    }
    return theme.palette.error[type];
  };

  const [expanded, expand] = useState(false);

  return (
    <Fragment>
      <Box
        sx={{
          cursor: 'pointer',

          padding: '14px',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 1,
          alignItems: 'center',
        }}
        onClick={() => {
          if (!!fields) expand(!expanded);
        }}
      >
        <Typography variant='h3b' sx={{ fontSize: '22px', fontWeight: 500 }}>
          {name}
        </Typography>
        <Typography variant='h4' sx={{ color: handleStatus('main') }}>
          {value}
        </Typography>
      </Box>
      <TransitionGroup>
        {expanded && fields && (
          <Collapse>
            <PartitionItem path={path} onSelect={onSelect} fields={fields} />
          </Collapse>
        )}
      </TransitionGroup>
    </Fragment>
  );
}
