import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { EMortgageEntryStatus } from 'api/services/mortgage.dto';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapIcons } from '../ProposeCard/components/icons';
import { approveEntry, excludeBanks } from 'api/services/manager/manager.service';
import useTypedParams from 'hooks/useTypedParams';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROPOSE } from 'routes/paths';
import { useMortgage } from 'components/providers/MortageEntryProvider';
import { EBank } from 'api/services/bank.dto';

/**
 *
 * @param banks  excluded banks list
 * @returns {EBank[]} bank list included
 */
function getBankListImplicit(banks?: EBank[]) {
  if (!banks) {
    return [];
  }
  if (banks.length === 0) {
    return Object.values(EBank);
  }
  const allBanks = Object.values(EBank);
  const result = [];
  for (const bank of allBanks) {
    if (!banks?.includes(bank)) {
      result.push(bank);
    }
  }
  return result;
}

function ProposeCheckbox({ bank, onChange, value }: { bank: EBank; onChange: (bank: EBank, checked: boolean) => void; value: EBank[] }) {
  const [checked, setChecked] = useState(value.includes(bank));
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(bank, e.target.checked);
    setChecked(e.target.checked);
  };

  return (
    <FormControlLabel
      name={bank}
      label=""
      control={
        <Box
          sx={{
            width: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            padding: '8px',
          }}
        >
          <Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
              alignItems: { md: 'center', xs: 'center' },
            }}
          >
            <SvgIcon component={MapIcons[bank]} sx={{ width: 48, height: 48, borderRadius: '50%' }} />
            <Typography variant='h4' sx={{ whiteSpace: 'nowrap' }}>
              {t(`common:mortgage.banks.${bank}`)}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
}

interface ProposeSelectorProps {
  excludedList?: EBank[];
}

export default function ProposeSelector(props: ProposeSelectorProps) {
  const theme = useTheme();

  const { uuid } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_PROPOSE>();

  const mortgage = useMortgage();
  const [bankList, setBankList] = useState<EBank[]>(() => getBankListImplicit(props.excludedList));
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (bank: EBank, checked: boolean) => {
    setBankList((prev) => {
      const list = [...prev];
      if (checked) {
        list.push(bank);
        return [...new Set(list)];
      } else {
        const updated = list.filter((x) => x !== bank);
        return [...new Set(updated)];
      }
    });
  };

  const handleExcludeBank = async () => {
    try {
      const implicitList = Object.values(EBank).filter((x) => !bankList.includes(x));

      await excludeBanks({ uuidEntry: uuid as string, banks: implicitList });
    } catch (err) {
    } finally {
      handleClose();
      if (mortgage.entry?.status === EMortgageEntryStatus.verification) await approveEntry(uuid as string);
      await mortgage.getEntryRequest?.();
    }
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Button data-testid="propose.sendbank" size="large" variant='contained' color="primary" fullWidth onClick={handleOpen}>
          {t('interface:base.ProposeSelector.sendBank')}
        </Button>
        <Dialog
          sx={{
            '.MuiPaper-root': {
              maxWidth: '1200px',
            },
          }}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="propose-selector-title"
          aria-describedby="propose-selector-description"
        >
          <DialogTitle id="propose-selector-title">
            <Typography sx={{ fontWeight: 500, fontSize: '26px' }}>{t('interface:base.ProposeSelector.title')}</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container gap="24px">
              {Object.values(EBank).map((bank) => (
                <Grid
                  key={bank}
                  item
                  xs={12}
                  lg={3}
                  sx={{
                    background: theme.palette.secondary.main,
                    borderRadius: '8px',
                  }}
                >
                  <ProposeCheckbox value={bankList} bank={bank} onChange={handleChange} />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: { md: '30px', xs: 0 } }}>
            <Button onClick={handleClose} color="secondary" sx={{ color: theme.palette.common.link }}>
              {t('interface:base.ProposeSelector.cancel')}
            </Button>
            <Button onClick={handleExcludeBank} variant="contained">
              {t('interface:base.ProposeSelector.apply')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
