import { req } from 'api/http';

import { IGenEntryInviteDTO, IShortLinkEntity } from './short-link.dto';
import { IUserEntity } from './user.dto';

export function postInviteByRole(uuidEntry: string, body: IGenEntryInviteDTO) {
  return req<IGenEntryInviteDTO, IShortLinkEntity>(`user/mortgage-entry/${uuidEntry}/invite/generate`, {
    method: 'POST',
    body,
  });
}

export function postJoinByShortLink(inviteCode: string) {
  return req<{ inviteCode: string }, IShortLinkEntity>(`user/mortgage-entry/invite/use`, {
    method: 'POST',
    body: { inviteCode },
  });
}

export const userInviteInfo = (shortlinkUrl: string) =>
  req<{ shortlinkUrl: string }, IUserEntity>(`user/mortgage-invite/redeem`, { method: 'POST', body: { shortlinkUrl } });

export const getLinkInfo = <T>(code: string) => req<null, IShortLinkEntity<T>>(`s/${code}`, { method: 'GET' });
