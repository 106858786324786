/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const WraningIcon = createSvgIcon(
  <svg viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.5 11.5V8.5M8.5 5.5H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z'
      stroke='#DFC801'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'WraningIcon',
);

export default WraningIcon;
