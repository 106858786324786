import { Button, Container, Grid, MenuItem, Paper, TextField, Typography, useTheme } from '@mui/material';
import { DTOCreateBankGuaranteeEntryAndCompanyBodyReq } from 'api/services/bankGuarantee/bankGuarantee.dto';
import { ECompanyRequisiteType, ECompanyAddressType } from 'api/services/businessCredit/businessCredit.enum';
import { ERfRegion } from 'api/services/mortgage/mortgage.enum';
import { userUpdateInfo } from 'api/services/user';
import BaseButtonBack from 'components/base/Button/Back';
import { MASK, maskComponent } from 'components/base/FormGenerator/Input/inputMask';
import BaseSuggestionAddressInput from 'components/base/Suggestion/AddressInput';
import BicInputSuggestion from 'components/base/Suggestion/BicInput';
import WithSkeleton from 'components/base/WithSkeleton';
import RadioField from 'components/common/Forms/RadioField';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useAuth } from 'components/providers/AuthProvider';
import { useBankGuarantee } from 'components/providers/BankGuaranteeProvider';
import useTypedParams from 'hooks/useTypedParams';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { Fragment, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  PATH_BUSINESS_CREDIT_ENTRY_COMPANY,
  PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS,
  PATH_BUSINESS_CREDIT_ENTRY_EDIT,
  PATH_HOME,
} from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

type BankGuaranteeCompanyFormSchema = DTOCreateBankGuaranteeEntryAndCompanyBodyReq & { user: { name: string; email: string } };

export default function BankGuaranteeCompanyPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useTypedParams<typeof PATH_BUSINESS_CREDIT_ENTRY_COMPANY>();
  const { t } = useTranslation();

  const { control, handleSubmit, getValues, reset, formState, watch } = useForm<BankGuaranteeCompanyFormSchema>({
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const theme = useTheme();
  const bankGuaranteeService = useBankGuarantee();

  const submitData = async () => {
    const values = getValues();

    const [firstname, lastname, patronymic] = (values.user.name as unknown as string).split(' ');
    const updatedCompany: any = {
      ...values.company,
    };

    try {
      await Promise.all([
        bankGuaranteeService.updateCompanyRequest(bankGuaranteeService.entry?.company?.uuid!, updatedCompany as any),
        userUpdateInfo({ firstname, lastname, patronymic, email: values.user.email! }),
      ]);
      navigate(
        utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS, {
          uuidEntry: bankGuaranteeService.entry?.entry.uuid!,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth.user) {
      bankGuaranteeService.loadEntryRequest({ uuidEntry: params.uuidEntry! }, (entryData) => {
        reset({
          user: { email: auth.user?.email!, name: utils.format.getFullName(auth.user) as any },
          company: {
            ...entryData.company,
          },
        });
      });
    }
  }, [auth.user]);

  const company = useMemo(() => {
    const companyData = bankGuaranteeService.entry?.company;
    const requisites = companyData?.requisite.reduce((acc: any, cur: any) => ({ ...acc, [cur.type]: cur.data }), {}) as Record<
      ECompanyRequisiteType,
      string
    >;
    const legalAddress = companyData?.addresses?.find((address: any) => address.type === ECompanyAddressType.legalAddress) as unknown as {
      fullAddress: string;
    };

    return { ...companyData, requisites, legalAddress } as const;
  }, [bankGuaranteeService.loading, bankGuaranteeService.entry]);

  const bankAccountValue = watch('company.bankAccount') as any;

  const errors = Object.values(formState.errors).length > 0;

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreatePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreatePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.BankGuaranteeCompanyPage.title')} />,
        lg: (
          <TopMenu
            sx={{ zIndex: 9999 }}
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },
              {
                title: t('interface:page.BankGuaranteeCompanyPage.creditParams'),
                href: utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_EDIT, { uuidEntry: params.uuidEntry! }),
              },
              { title: t('interface:page.BankGuaranteeCompanyPage.title'), activeLink: true },
            ]}
          />
        ),
      }}
      sx={{ height: 1, width: 1 }}
      maxWidth='xl'
    >
      <Container
        maxWidth='md'
        component='form'
        onSubmit={handleSubmit(submitData)}
        sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>Информация по заявке</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='entry.contract.procurementObject'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={'Предмет контракта (объект закупки)'}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  />
                )}
              />
            </WithSkeleton>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='entry.contract.region'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    select
                    label={'Регион оказания услуu/выполнения работ в контракте'}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  >
                    {Object.values(ERfRegion).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`interface:page.ClientMortgageEntryCreatePage.form.subjectRegion.options.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </WithSkeleton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <WithSkeleton loading={!auth.user}>
            <Controller
              name='user.name'
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={t(`interface:page.BankGuaranteeCompanyPage.fullName`)}
                  inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                  variant='standard'
                  fullWidth
                  error={!!fieldState.error}
                  helperText={t(`${fieldState.error?.message || ''}`)}
                  {...field}
                />
              )}
            />
          </WithSkeleton>
        </Grid>
        <Grid item xs={12} md={12}>
          <WithSkeleton loading={!auth.user}>
            <Controller
              name='entry.contract.subjectName'
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={'Наименование субъекта РФ заказчика'}
                  inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                  variant='standard'
                  fullWidth
                  error={!!fieldState.error}
                  helperText={t(`${fieldState.error?.message || ''}`)}
                  {...field}
                >
                  {Object.values(ERfRegion).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`interface:page.ClientMortgageEntryCreatePage.form.subjectRegion.options.${option}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </WithSkeleton>
        </Grid>
        <Grid item xs={12} md={12}>
          <WithSkeleton loading={!auth.user}>
            <Controller
              name='entry.contract.subjectName'
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={'Регион оказания услуг/выполнения работ в контракте'}
                  inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                  variant='standard'
                  fullWidth
                  error={!!fieldState.error}
                  helperText={t(`${fieldState.error?.message || ''}`)}
                  {...field}
                >
                  {Object.values(ERfRegion).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`interface:page.ClientMortgageEntryCreatePage.form.subjectRegion.options.${option}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </WithSkeleton>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                display: 'flex',
                width: '100%',
                direction: 'column',
                padding: '24px',
                borderRadius: '16px',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>{t(`interface:page.BankGuaranteeCompanyPage.company.title`)} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.companyName')}
                  </Typography>
                  <WithSkeleton loading={!company.name}>
                    <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                      {company.name}
                    </Typography>
                  </WithSkeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.inn')}
                  </Typography>
                  <WithSkeleton loading={!company.requisites?.inn}>
                    <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                      {company.requisites?.inn}
                    </Typography>
                  </WithSkeleton>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.address')}
                  </Typography>
                  <WithSkeleton loading={!company?.legalAddress?.fullAddress}>
                    <Typography variant='h5m' sx={{ fontSize: '14px' }}>
                      {company.legalAddress?.fullAddress}
                    </Typography>
                  </WithSkeleton>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <WithSkeleton loading={!company.requisites?.inn}>
                    <Fragment>
                      <Controller
                        name='company.bankAccount'
                        control={control}
                        render={({ field, fieldState }) => {
                          return (
                            <BicInputSuggestion
                              label={t(`interface:page.BankGuaranteeCompanyPage.company.bicSuggestion`)}
                              variant='standard'
                              data-testid={'bicSuggestion'}
                              error={!!fieldState.error}
                              helperText={t(`${fieldState.error?.message || ''}`)}
                              onSelect={(optionData) => {
                                field.onChange({
                                  bankName: optionData?.value,
                                  bic: optionData?.data?.bic,
                                  bankAddress: optionData?.data?.address?.value,
                                });
                              }}
                            />
                          );
                        }}
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                            {t(`interface:page.BankGuaranteeCompanyPage.company.bankName`)}
                          </Typography>

                          <WithSkeleton loading={!bankAccountValue?.bankName}>
                            <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                              {bankAccountValue?.bankName}
                            </Typography>
                          </WithSkeleton>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                            {t(`interface:page.BankGuaranteeCompanyPage.company.bic`)}
                          </Typography>

                          <WithSkeleton loading={!bankAccountValue?.bic}>
                            <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                              {bankAccountValue?.bic!}
                            </Typography>
                          </WithSkeleton>
                        </Grid>
                      </Grid>
                    </Fragment>
                  </WithSkeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <WithSkeleton loading={!company.requisites?.inn}>
                    <Controller
                      name='company.bankAccount.bankAccount'
                      control={control}
                      render={({ field, fieldState }) => {
                        const f = {
                          ...field,
                          value: field?.value ? String(field.value) : '',
                          onChange: (e: ObjectType) => {
                            const v = e.target.value;
                            field.onChange(v?.replaceAll(' ', ''));
                          },
                        };
                        return (
                          <TextField
                            label={t('interface:page.BankGuaranteeCompanyPage.company.bankAccount')}
                            InputProps={
                              {
                                ...maskComponent(MASK.BANK_ACCOUNT, {
                                  ...f,
                                  inputMode: 'numeric',
                                }),
                              } as any
                            }
                            variant='standard'
                            fullWidth
                            error={!!fieldState.error}
                            {...field}
                            helperText={t(`${fieldState.error?.message || ''}`)}
                          />
                        );
                      }}
                    />
                  </WithSkeleton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <RadioField
                control={control}
                name='company.additionalData.haveBusinessAssetsRealEstate'
                label={'Наличие недвижимости в активах бизнеса'}
                options={[
                  { value: String(true), label: 'Да' },
                  { value: String(false), label: 'Нет' },
                ]}
              />
            </WithSkeleton>
          </Grid>

          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                control={control}
                name='company.additionalData.employeeAmount'
                render={({ field, fieldState }) => (
                  <TextField
                    id={field.name}
                    label={'Количество сотрудников'}
                    variant='standard'
                    fullWidth
                    required
                    {...field}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                    select
                  >
                    <MenuItem key={'1'} value='1'>
                      Меньше 10 человек
                    </MenuItem>
                    <MenuItem key={'2'} value='2'>
                      10-50 человек
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      Больше 50 человек
                    </MenuItem>
                  </TextField>
                )}
              />
            </WithSkeleton>
          </Grid>

          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <RadioField
                control={control}
                name='company.additionalData.employeeAmount'
                label={'Количество работников в штате не более 100 человек'}
                options={[
                  { value: 'yes', label: 'Да' },
                  { value: 'no', label: 'Нет' },
                ]}
              />
            </WithSkeleton>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                control={control}
                name='company.additionalData.accounting'
                render={({ field, fieldState }) => (
                  <TextField
                    id={field.name}
                    label={'Ведение бухгалтерского учета'}
                    variant='standard'
                    fullWidth
                    required
                    {...field}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                    select
                  >
                    <MenuItem key={'1'} value='1'>
                      Лично
                    </MenuItem>
                    <MenuItem key={'2'} value='2'>
                      Бухгалтер
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      Аудиторская фирма
                    </MenuItem>
                    <MenuItem key={'3'} value='3'>
                      Частный аудитор
                    </MenuItem>
                  </TextField>
                )}
              />
            </WithSkeleton>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                control={control}
                name='company.additionalData.wageFundSize'
                render={({ field, fieldState }) => (
                  <NumberFormat
                    id={field.name}
                    label={'Размер фонда оплаты труда'}
                    variant='standard'
                    suffix=' ₽'
                    fullWidth
                    customInput={TextField}
                    required
                    thousandSeparator=' '
                    value={field.value}
                    onChange={(e: ObjectType) => {
                      field.onChange(utils.format.digitNumber(e.target.value));
                    }}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    inputProps={{ ['data-testid']: field.name }}
                  />
                )}
              />
            </WithSkeleton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 700 }}>Исполнительный орган</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='company.executiveElement.name'
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={'ФИО исполнительного органа (передано)'}
                    inputProps={{ ['data-testid']: 'RegisterPage.Form.profile.patronymic' }}
                    variant='standard'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    {...field}
                  />
                )}
              />
            </WithSkeleton>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='company.executiveElement.snils'
                control={control}
                render={({ field, fieldState }) => (
                  <NumberFormat
                    customInput={TextField}
                    pattern='###-###-### ##'
                    allowEmptyFormatting
                    mask='_'
                    label={'СНИЛС'}
                    variant='standard'
                    inputMode='numeric'
                    fullWidth
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                    value={field.value}
                    onValueChange={(values) => {
                      field.onChange(values.formattedValue);
                    }}
                  />
                )}
              />
            </WithSkeleton>
          </Grid>
          <Grid item xs={12} md={12}>
            <WithSkeleton loading={!auth.user}>
              <Controller
                name='company.executiveElement.factAddress'
                control={control}
                render={({ field }) => {
                  return (
                    <BaseSuggestionAddressInput
                      data-testid='data.address.fullAddress'
                      fullWidth
                      label={'Фактический адрес проживания'}
                      onSelect={(address) => {
                        if (address?.fullAddress) {
                          field.onChange(address?.fullAddress);
                        }
                      }}
                      value={field?.value}
                    />
                  );
                }}
              />
            </WithSkeleton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              size='large'
              type='submit'
              // onClick={submitData}
              data-testid='mortgage.create'
              disabled={errors}
            >
              {t('interface:page.BankGuaranteeCompanyPage.continue')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
}
