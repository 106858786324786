import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_BUSINESS_CREDIT_ENTRY_COMPANY, PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS } from 'routes/paths';
import utils from 'utils';

interface IProps {
  expanded: boolean;

  handleClick: (path: string) => void;
  handleExpand: (_event: React.SyntheticEvent, isExpanded: boolean) => void;
  disabled?: boolean;
  sucesssSx?: SxProps;
  uuidEntry: string;
}

const BusinessCreditProfile = ({ expanded, handleClick, handleExpand, disabled, uuidEntry }: IProps) => {
  const theme = useTheme();
  const businessCredit = useBusinessCredit();
  const { t } = useTranslation();

  return (
    <>
      <Accordion
        disabled={disabled}
        data-testid={`step-profile-accordion`}
        sx={{
          border: '1px solid #D2D2CF',
          borderRadius: '8px',
          boxShadow: 'none',
          '&:before': { height: 0 },
        }}
        expanded={expanded}
        onChange={handleExpand}
      >
        <AccordionSummary sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0' } }}>
          <Fragment>
            <Typography variant='h4m'>
              {t(`interface:page.BusinessCreditProgressPage.profile.title`, businessCredit.entry?.company)}
            </Typography>
          </Fragment>
        </AccordionSummary>
        <AccordionDetails>
          <LinearProgress sx={{ height: '1px', marginBottom: '20px' }} variant='determinate' value={10} />
          <>
            <Button
              fullWidth
              sx={{
                color: `${theme.palette.text.primary}`,
                justifyContent: 'space-between',
              }}
              endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
              onClick={() => {
                handleClick(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_COMPANY, { uuidEntry }));
              }}
            >
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {t(`interface:page.BusinessCreditProgressPage.profile.companyData`)}
              </Box>
            </Button>
            <Button
              fullWidth
              sx={{
                color: `${theme.palette.text.primary}`,
                justifyContent: 'space-between',
              }}
              endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
              onClick={() => {
                handleClick(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS, { uuidEntry }));
              }}
            >
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {t(`interface:page.BusinessCreditProgressPage.profile.documents`)}
              </Box>
            </Button>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BusinessCreditProfile;
