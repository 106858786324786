import { ApiError } from 'api/errors';
import { EBank } from 'api/services/bank.dto';
import { EBCBank, ECBCEntryPropose } from 'api/services/businessCredit/businessCredit.propose.dto';
import { IMortgageEntryPropose } from 'api/services/mortgage.dto';
import { IProposeService } from 'modules/Propose/types';
import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface ProposeCommonContextProps {
  updateProposeParams(proposeFormData: Partial<IMortgageEntryPropose>): Promise<void>;
  editPropose(uuidPropose?: string): void;
  editablePropose: string;
  managerUpdateDecision(data: { uuidPropose: string; propose: any }): Promise<void>;
  managerExcludeBanks(banklist: EBank[]): Promise<void>;
  proposes: ECBCEntryPropose[];
  getProposeList(): Promise<void>;
  primaryPropose?: ECBCEntryPropose;
  selectProposeClient(propose: any): Promise<void>;
  loading: boolean;
}

export const ProposeCommonContext = createContext({});

export default function ProposeCommonProvider<TPropose extends object>({
  children,
  service,
  primaryPropose,
  updateCallback,
}: {
  children: React.ReactChild;
  service: IProposeService<TPropose, TPropose>;
  primaryPropose?: TPropose;
  updateCallback(): Promise<void>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [proposes, setProposes] = useState<TPropose[]>([]);
  const params = useParams() as Record<string, string>;
  const [, setError] = useState<ApiError>();
  const [editablePropose, setEditPropose] = useState<string>();

  const getProposeList = async () => {
    setLoading(true);
    try {
      const res = await service.getProposeList(params.uuidEntry!);
      setProposes(res.body);
    } catch (err) {
    } finally {
      await updateCallback();
      setLoading(false);
    }
  };

  /**
   * link to update propose params
   */
  const updateProposeParams = async (proposeFormData: Partial<TPropose>) => {
    if (!editablePropose) {
      return;
    }
    try {
      //@ts-ignore
      proposeFormData.uuid = editablePropose;
      await service.updateProposeDecision(params.uuidEntry!, proposeFormData);
    } catch (err) {
      setError(err as ApiError);
    } finally {
      await getProposeList();
    }
  };

  /**
   * select propose by uuid to mount editable form
   * @param uuidPropose uuid of propose
   */
  const editPropose = (uuidPropose: string | undefined) => {
    setEditPropose(uuidPropose);
  };
  /**
   * manager approve update proposeDecision to final stage
   * @param
   * @returns
   */
  const managerUpdateDecision = async ({ propose }: { propose: TPropose }) => {
    if ('decision' in propose) {
      return;
    }
    try {
      await service.postFinalProposeDecision(params, propose);
    } catch (err) {
    } finally {
      await getProposeList();
    }
  };

  const managerExcludeBanks = async (banklist: EBCBank[]) => {
    setLoading(true);
    try {
      await service.excludeBanks(params.uuidEntry!, banklist as any);
    } catch (err) {
    } finally {
      await getProposeList();
    }
    setLoading(false);
  };

  const selectProposeClient = async (propose: any) => {
    try {
      console.log({ uuidEntry: params.uuidEntry!, uuidPropose: propose.uuid! });
      await service.selectPropose({ uuidEntry: params.uuidEntry!, uuidPropose: propose.uuid! }, propose);
    } catch (err) {
    } finally {
      await getProposeList();
    }
  };

  const storeValue = useMemo(
    () => ({
      getProposeList,
      proposes,
      managerExcludeBanks,
      primaryPropose,
      editPropose,
      editablePropose,
      updateProposeParams,
      managerUpdateDecision,
      selectProposeClient,
      loading,
    }),
    [editablePropose, proposes, primaryPropose],
  );

  return <ProposeCommonContext.Provider value={storeValue}>{children}</ProposeCommonContext.Provider>;
}

export function useProposeCommon() {
  const ctx = useContext(ProposeCommonContext) as ProposeCommonContextProps;
  if (!ctx) {
    throw new Error('ProposeProvider not initialized at parent level');
  }
  return ctx;
}
