import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='40' height='40' fill='#009846' />
    <mask id='mask0_2706_8368' maskUnits='userSpaceOnUse' x='0' y='0' width='40' height='40'>
      <rect width='40' height='40' fill='#CDD1CF' />
    </mask>
    <g mask='url(#mask0_2706_8368)'>
      <path
        d='M39.7681 16.1465C39.358 16.1438 38.9479 16.1405 38.5384 16.1372C36.6349 16.1229 34.6979 16.0634 32.8241 16.4539C30.8694 16.8471 29.0788 17.763 27.8221 19.3425C27.4467 19.8139 27.1235 20.3272 26.8643 20.8713C26.3832 21.8792 26.1828 22.9768 25.885 24.0452C25.8437 24.1945 25.8145 24.347 25.7733 24.4963C25.6885 24.802 25.5999 25.1065 25.5118 25.4116C25.4689 25.5609 25.416 25.7079 25.3808 25.8588C25.2828 26.2823 25.0571 26.4839 24.6355 26.3985C22.0213 25.8671 19.0439 25.828 16.6075 27.0594C13.073 28.8455 11.9715 33.003 10.777 36.4567C10.3163 37.7889 9.81813 39.1085 9.37666 40.4479C9.35684 40.5085 9.3651 40.5856 9.33042 40.6329C9.08822 40.9634 9.03317 41.3539 8.94675 41.7383C8.88345 42.0208 8.73647 42.045 8.51959 41.8501C8.29445 41.648 8.17115 41.3979 8.16069 41.1022C8.13207 40.2959 6.68666 44.51 6.67125 43.7031C6.66189 43.1986 5.69353 44.4545 5.68362 43.95C5.67372 43.4312 8.14528 36.1505 8.32583 35.657C9.10969 33.5091 9.79446 31.2528 10.7748 29.1633C11.611 27.3816 12.751 25.6297 14.4728 24.5954C16.8987 23.1382 19.967 23.3139 22.6692 23.5854C22.8074 23.5991 22.9516 23.6124 23.0826 23.565C23.6127 23.3722 23.7184 22.48 23.8169 22.0163C23.9639 21.3229 24.1489 20.667 24.3883 19.9973C24.9575 18.4029 25.7259 16.8553 27.0057 15.7098C30.1769 12.8696 35.081 13.4215 39.0063 13.4556C40.7491 13.4711 42.4924 13.487 44.2357 13.4683C45.0212 13.46 45.3355 13.8241 45.6294 14.5108C45.6971 14.6695 45.8034 14.8165 45.8447 14.9812C45.9195 15.2769 45.9927 15.5804 46.001 15.8827C46.007 16.0959 45.8078 16.1708 45.6019 16.1697C45.2441 16.168 44.8863 16.168 44.5285 16.168C42.9415 16.168 41.3551 16.1576 39.7681 16.146V16.1465Z'
        fill='white'
      />
      <path
        d='M35.9147 11.2794C35.5046 11.2766 35.0945 11.2733 34.685 11.27C32.7815 11.2557 30.8444 11.1962 28.9706 11.5867C27.0159 11.9799 25.2253 12.8958 23.9686 14.4753C23.5932 14.9467 23.27 15.46 23.0108 16.0041C22.5297 17.012 22.3293 18.1096 22.0315 19.1781C21.9902 19.3273 21.961 19.4799 21.9198 19.6291C21.835 19.9348 21.7464 20.2393 21.6583 20.5444C21.6154 20.6937 21.5625 20.8407 21.5273 20.9916C21.4293 21.4152 21.2036 21.6167 20.782 21.5314C18.1678 20.9999 15.1904 20.9608 12.754 22.1922C9.21951 23.9783 8.11805 28.1358 6.92355 31.5895C6.46281 32.9217 5.96464 34.2413 5.52317 35.5807C3.20925 41.2337 2.9566 42.7152 2.2216 42.4683C1.97939 42.7987 3.94999 40.7399 3.70308 41.4806C2.46278 46.172 2.78927 40.441 2.57239 40.246C2.34725 40.0439 2.73162 41.0356 2.72116 40.7399C2.69254 39.9336 1.49627 42.2874 1.48085 41.4806C1.4715 40.9761 -0.978358 45.4419 -0.988266 44.9374C-0.998174 44.4186 3.77521 32.083 3.95577 31.5895C4.73962 29.4416 5.94097 26.3856 6.92134 24.2961C7.75749 22.5144 8.89749 20.7625 10.6193 19.7282C13.0452 18.271 16.1135 18.4467 18.8157 18.7182C18.9539 18.732 19.0981 18.7452 19.2291 18.6978C19.7592 18.505 19.8649 17.6128 19.9634 17.1491C20.1104 16.4557 20.2954 15.7998 20.5348 15.1301C21.104 13.5357 21.8724 11.9882 23.1522 10.8426C26.3234 8.00246 31.2275 8.5543 35.1529 8.58844C36.8956 8.60386 38.6389 8.61984 40.3822 8.60111C41.1677 8.59285 41.482 8.95689 41.776 9.64366C41.8437 9.80227 41.9499 9.94932 41.9912 10.114C42.0661 10.4097 42.1393 10.7132 42.1476 11.0155C42.1536 11.2287 41.9543 11.3036 41.7485 11.3025C41.3907 11.3008 41.0329 11.3008 40.6751 11.3008C39.0881 11.3008 37.5017 11.2904 35.9147 11.2788V11.2794Z'
        fill='white'
      />
      <path
        d='M32.6716 6.21295C32.2615 6.21019 31.8514 6.20689 31.4419 6.20358C29.5384 6.18927 27.6013 6.12979 25.7276 6.52026C23.7729 6.91349 21.9822 7.82937 20.7255 9.40888C20.3501 9.88032 20.027 10.3936 19.7677 10.9377C19.2866 11.9456 19.0863 13.0432 18.7885 14.1116C18.7472 14.2609 18.718 14.4134 18.6767 14.5627C18.5919 14.8684 18.5033 15.1729 18.4152 15.478C18.3723 15.6273 18.3195 15.7743 18.2842 15.9252C18.1862 16.3487 17.9606 16.5503 17.5389 16.465C14.9248 15.9335 11.9473 15.8944 9.51099 17.1258C5.97648 18.9119 4.87502 23.0694 3.68052 26.5231C3.21978 27.8553 2.72162 29.1749 2.28015 30.5143C2.26033 30.5749 2.26859 30.652 2.23391 30.6994C1.99171 31.0298 -0.90128 38.3813 -0.987703 38.7657C-1.05101 39.0482 -2.2523 39.9483 -2.46918 39.7533C-2.69432 39.5512 -3.81434 40.0491 -3.8248 39.7533C-3.85342 38.947 -4.67593 45.9923 -4.69134 45.1854C-4.7007 44.6809 -3.68731 39.2702 -3.69722 38.7657C-3.70713 38.2469 1.04877 26.2169 1.22932 25.7234C2.01318 23.5755 2.69794 21.3192 3.67831 19.2297C4.51446 17.448 5.65446 15.6961 7.3763 14.6618C9.80218 13.2046 12.8705 13.3803 15.5727 13.6518C15.7108 13.6655 15.855 13.6788 15.9861 13.6314C16.5162 13.4386 16.6218 12.5464 16.7204 12.0827C16.8673 11.3893 17.0523 10.7334 17.2918 10.0637C17.8609 8.46932 18.6294 6.92175 19.9092 5.77621C23.0804 2.93605 27.9845 3.48789 31.9098 3.52204C33.6526 3.53746 39.7381 3.54065 41.4814 3.52192C42.2669 3.51366 42.1751 3.75791 42.4691 4.44469C42.5368 4.6033 42.4278 5.02076 42.4691 5.18543C42.5439 5.48117 42.9547 5.62381 42.9629 5.92617C42.969 6.1393 38.7113 6.23718 38.5054 6.23608C38.1476 6.23443 37.7898 6.23443 37.432 6.23443C35.845 6.23443 34.2586 6.22396 32.6716 6.2124V6.21295Z'
        fill='white'
      />
    </g>
  </svg>,
  'Kamkom',
);
