import { useTheme } from '@mui/material';
import { deleteIncuranceOrderById } from 'api/services/agent';
import TrashIcon from 'components/Icons/Trash';
import BaseSwipeable from 'components/base/Swipeable';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Statuses, { FAILED, IDLE, LOADING } from 'types/statuses';

import AgentInsurancePropertyPolicyListItem from '../ListItem';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Record<string, any>[];
  onDeleted: (uuid: string) => void;
}

const AgentInsurancePropertyPolicyList = ({ items, onDeleted }: IProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [fetchingStatus, setFetchingStatus] = useState<Statuses>(IDLE);
  const { enqueueSnackbar } = useSnackbar() ?? {};
  const hasItems: boolean = items ? items?.length > 0 : false;

  const getText = (entity: string) => t('errors:components.views.Agent.Insurance.Property.Policy.List', entity);
  const errorHadle = () => enqueueSnackbar(getText('delete'), { variant: 'error' });

  const onDelete = async (uuid: string) => {
    setFetchingStatus(LOADING);

    try {
      const res = await deleteIncuranceOrderById(uuid);

      if (res.ok) {
        onDeleted(uuid);
      } else {
        setFetchingStatus(FAILED);
        errorHadle();
      }
    } catch (error) {
      setFetchingStatus(FAILED);
      errorHadle();
    } finally {
      setFetchingStatus(IDLE);
    }
  };

  return (
    <>
      {hasItems &&
        items?.map((policy) => {
          const { uuid, client, product, status, policyNumber } = policy ?? {};
          const { firstname, lastname, patronymic, address } = client ?? {};
          const { company, price } = product?.data ?? {};
          const itemProps = { uuid, policyNumber, address, price, lastname, firstname, patronymic, company, status };

          return (
            <BaseSwipeable
              key={uuid}
              distanceToFix='0.3'
              swipeLeftConfig={{
                active: true,
                button: {
                  styles: {
                    backgroundColor: theme.palette.error.light,
                  },
                  onClick: () => onDelete(uuid),
                },
                icon: {
                  component: TrashIcon,
                  styles: { width: '22px', height: '25px', color: theme.palette.common.delete },
                },
              }}
              containerStyle={{
                marginTop: '16px',
                overflow: 'visible',
              }}
              status={fetchingStatus}
            >
              <AgentInsurancePropertyPolicyListItem {...itemProps} />
            </BaseSwipeable>
          );
        })}
    </>
  );
};

export default AgentInsurancePropertyPolicyList;
