import { format, fromUnixTime, parseISO } from 'date-fns';
import i18next from 'i18next';

export interface IDataNameFormat {
  lastname: string;
  firstname: string;
  patronymic: string;
}

const price = (price: number, opts = { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }, locale = 'ru-RU') => {
  const r = new Intl.NumberFormat(locale, opts).format(price);
  return r;
};

const percent = (digitP: number, opts = { style: 'percent', maximumFractionDigits: 2 }, locale = 'ru-RU') => {
  const digitPercent = new Intl.NumberFormat(locale, opts).format(digitP / 10000);
  return digitPercent.replace(/\s+/g, '').trim();
};

const nameWithInitials = <T extends Partial<IDataNameFormat>>(data?: T, noname?: string): string => {
  if (!data) return noname || i18next.t('common:noName');

  return `${data?.lastname} ${data?.firstname && `${data?.firstname[0]}. `}${data?.patronymic ? `${data?.patronymic[0] || ''}. ` : ''}`;
};

export const getFullName = (data: IDataNameFormat | any, noname?: string) => {
  const fullname = [data.lastname, data.firstname, data.patronymic].join(' ').trim();

  return fullname || noname || i18next.t('common:noName');
};

export const getPhoneNumber = (number: number | string) => {
  return `${number}`.replace(/^\d(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4');
};

export const hidePhoneNumber = (number: number | string) => {
  return `${number}`.replace(/^\d(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 ••• ••• $4');
};

export const formatDateUnix = (number: number) => {
  const date = fromUnixTime(number);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

export const formatDateString = (dateString: string, dateFormat: string = 'yyyy-MM-dd') => {
  const date = parseISO(dateString);
  return format(date, dateFormat);
};

export { price, percent, nameWithInitials };
