export enum EScoringResultColor {
  red = 'red',
  yellow = 'yellow',
  green = 'green',
}

// @see https://docs.google.com/spreadsheets/d/1sDzYru9lNRwilLEums2nRym8ExyEhI55/edit#gid=1531603439
export enum EScoringTypeName {
  // TODO: Refactor this by spreadsheet
  arrearages = 'arrearages',
  bankrupt = 'bankrupt',
  canceledInn = 'canceledInn',
  canceledPassport = 'canceledPassport',
  hasTerrorist = 'hasTerrorist',
  employerFiledForBankruptcy = 'employerFiledForBankruptcy',
  employerBankrupt = 'employerBankrupt',
  loanToValue = 'loanToValue',
  nbki = 'nbki',
  BKI01 = 'BKI01', // The current delay is more than X rubles
  BKI02 = 'BKI02', // Bad debt
  BKI03 = 'BKI03', // More than 30 days overdue in the last Y months
  BKI04 = 'BKI04', // More than 60 days overdue in the last Y months
  BKI05 = 'BKI05', // More than 90 days overdue in the last Y months
  BKI06 = 'BKI06', // Number of requests to BKI for the last Z days
  BKI07 = 'BKI07', // Number of active loans
  BKI08 = 'BKI08', // Lack of credit history
  BKI09 = 'BKI09', // The amount of debt on obligations
  BKI10 = 'BKI10', // The amount of overdue debt on obligations
  BKI11 = 'BKI11', // Age of credit history
}
export enum EScoringStatus {
  waiting = 'waiting',
  notEnoughData = 'notEnoughData',
  done = 'done',
  error = 'error',
}

export interface IScoringEntityCondition {
  key: string;
  value: string;
}

export enum EScoringValueType {
  percent = 'percent',
  price = 'price',
  number = 'number',
  days = 'days',
  months = 'months',
  years = 'years',
}

export interface IScoringParams {
  uuidEntry: string;
  uuidUser: string;
  scoringTypes: EScoringTypeName[];
}
export interface IScoringRowIds {
  uuidProfile: string;
  uuidEntry: string;
}
export interface IScoringRowDates {
  createdAtTs: number;
  updatedAtTs: number;
  startAtTs?: number;
  endAtTs?: number;
}

export interface IScoringRow {
  ids: IScoringRowIds;
  dates: IScoringRowDates;
  status?: EScoringStatus;
  type?: EScoringTypeName;
  result?: EScoringResultColor;
  conditions: IScoringEntityCondition[];
  data?: string;
  value?: number | null;
  valueType?: EScoringValueType | null;
}
