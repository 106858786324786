import { Components, Theme } from '@mui/material';

const components: Components<Theme> = {
  MuiLink: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        color: theme.palette.common.link,
        cursor: 'pointer',
        textDecoration: 'none',
      }),
    },
  },
};
export default components;
