import { Box, Checkbox, FormControlLabel, Grid, IconButton, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatDateString } from 'utils/format/format';
import { useTranslation } from 'react-i18next';
import { EProfileWorkspaceType } from 'api/services/incomes/incomes.enum';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import Employee from './Employee';
import BusinessOwner from './BusinessOwner';
import { IndividualEntrepreneur } from './IndividualEntrepreneur';
import SelfEmployee from './SelfEmployee';
import { createElement } from 'react';
import { IIncomesDataDTO } from 'api/services/incomes/incomes.interface';
import { MASK, maskComponent } from 'components/base/FormGenerator/Input/inputMask';

interface Props {
  onRemove(index: number): void;
  fields: Array<IIncomesDataDTO & { id: string }>;
  control: Control<{ companies: IIncomesDataDTO[] }>;
  watch: UseFormWatch<{ companies: IIncomesDataDTO[] }>;
}

const form = {
  [EProfileWorkspaceType.employee]: Employee,
  [EProfileWorkspaceType.owner]: BusinessOwner,
  [EProfileWorkspaceType.individualEntrepreneur]: IndividualEntrepreneur,
  [EProfileWorkspaceType.selfEmployed]: SelfEmployee,
};

export default function IncomesWorkForm({ fields, onRemove, control, watch }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="stretch" sx={{ paddingTop: '16px' }}>
      {fields?.map((workplace, index) => {
        const workplaceType = watch(`companies.${index}.type`);
        return (
          <Grid item xs={12} md={6} key={workplace.id}>
            <Paper
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: theme.palette.common.greyLight,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '200px',
                maxHeight: '100%',
                padding: '24px',
                borderRadius: '16px',
                // width: { md: 1, lg: 0.5 },
                position: 'relative',
              }}
              elevation={0}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name={`companies.${index}.isMain`}
                      render={({ field }) => <Checkbox checked={field.value} {...field} />}
                    />
                  }
                  label={t('page.IncomesForm.commonPlace')}
                />
                <IconButton
                  onClick={() => {
                    onRemove(index);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Box>
              <Box sx={{ margin: '12px 0' }}>
                <Controller
                  control={control}
                  name={`companies.${index}.type`}
                  render={({ field }) => (
                    <Select fullWidth size="small" {...field}>
                      {Object.values(EProfileWorkspaceType)
                        .filter((x) => x !== EProfileWorkspaceType.selfEmployed)
                        .map((workplaceType) => (
                          <MenuItem value={workplaceType} key={workplaceType}>
                            {t(`page.IncomesForm.workplaceType.${workplaceType}`)}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Box>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.companyName')}
                  </Typography>
                  <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                    {workplace.company.name}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.inn')}
                  </Typography>
                  <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                    {workplace.company.inn}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.address')}
                  </Typography>
                  <Typography variant='h5m' sx={{ fontSize: '14px' }}>
                    {workplace.company.address}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.phone')}
                  </Typography>
                  <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                    <Controller
                      name={`companies.${index}.company.phone`}
                      control={control}
                      render={({ field }) => {
                        //@ts-ignore
                        field.value = field.value ? `${field.value}` : '';
                        return (
                          <TextField
                            variant='standard'
                            //@ts-ignore
                            InputProps={maskComponent(MASK.PHONE, { ...field, 'data-testid': 'incomes-phone' })}
                            sx={{
                              '&.MuiInputBase-input': {
                                fontSize: '12px',
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                    {t('page.IncomesForm.info.dateCreated')}
                  </Typography>
                  <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                    {workplace.company.registrationDate ? formatDateString(workplace.company.registrationDate) : '-'}
                  </Typography>
                </Grid>
              </Grid>
              {createElement(form[workplaceType], { watch: watch, control, index })}
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
