import { EUserRole } from 'api/services/user.dto';
import { useAuth } from 'components/providers/AuthProvider';
import { Navigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';

interface GroupSelectorProps {
  match: Array<{ role: EUserRole; component: JSX.Element }>;
}

export const GroupSelector = ({ match }: GroupSelectorProps) => {
  const auth = useAuth();

  if (auth.isAuth && !auth.user) return null;

  const userGroup = auth.isAuth ? auth?.user?.roles : [EUserRole.unknown];
  const matched = match.find(({ role }) => userGroup?.includes(role));

  if (matched) {
    return matched.component;
  } else {
    return match.find(({ role }) => role === EUserRole.unknown)?.component || <Navigate to={PATH_HOME} replace />;
  }
};
