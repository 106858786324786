/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const FilterIcon = createSvgIcon(
  <svg viewBox='-5 -4 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M1.76655 1H18.2334C18.3822 1.00005 18.5277 1.04343 18.6523 1.12488C18.7768 1.20632 18.8751 1.3223 18.935 1.45869C18.9949 1.59508 19.014 1.74599 18.9898 1.89303C18.9657 2.04008 18.8994 2.17692 18.799 2.28687L12.5017 9.23019C12.3706 9.37075 12.2985 9.55644 12.3004 9.74878V15.1844C12.3016 15.312 12.2705 15.4379 12.2102 15.5504C12.1499 15.6629 12.0622 15.7583 11.9553 15.8278L8.88815 17.8733C8.77309 17.9493 8.63975 17.9927 8.50213 17.9992C8.36452 18.0056 8.22771 17.9748 8.1061 17.9099C7.98448 17.8451 7.88255 17.7486 7.81103 17.6306C7.7395 17.5126 7.70101 17.3775 7.69962 17.2395V9.74878C7.70147 9.55644 7.6294 9.37075 7.49833 9.23019L1.20104 2.28687C1.10064 2.17692 1.03433 2.04008 1.01018 1.89303C0.986025 1.74599 1.00508 1.59508 1.06501 1.45869C1.12495 1.3223 1.22318 1.20632 1.34774 1.12488C1.4723 1.04343 1.61781 1.00005 1.76655 1Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'FilterIcon',
);

export default FilterIcon;
