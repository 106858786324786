import { ErrorMessage } from './http';

export type ErrorStruct = {
  [x: string]: ErrorMessage | undefined;
};

export type ErrorResponse = { name: string; message: string };

export const API_ERROR = 'api.error';
export const CLIENT_ERROR = 'client.error';

export const API_LOGIC_ERROR = 'logic.somethingWrong';

export const createError = (type: string = API_ERROR): ErrorMessage => ({
  name: type,
});

export class ApiError extends Error {
  //@ts-ignore
  constructor(private err: ErrorResponse) {
    super(err.message);
  }
  get message() {
    return this.err.message;
  }
  get name() {
    return this.err.name;
  }
}
