import InsuranceAlfaIcon from 'components/Icons/InsuranceAlfa';
import InsuranceIngosIcon from 'components/Icons/InsuranceIngos';
import InsuranceResoIcon from 'components/Icons/InsuranceReso';

export const ICONS_COMPANY = {
  resoGarant: InsuranceResoIcon,
  ingosstrah: InsuranceIngosIcon,
  alfastrah: InsuranceAlfaIcon,
};

export type IconsCompany = keyof typeof ICONS_COMPANY;
