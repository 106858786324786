import { req } from 'api/http';

interface EntryQuestionarieParams {
  goalName: string;
  uuidUser: string;
  uuidEntry: string;
}

export function postGoalAnalysis({ goalName, uuidEntry, uuidUser }: EntryQuestionarieParams) {
  return req(`/user/goal/${goalName}`, { method: 'POST', body: { uuidUser, uuidEntry } });
}
