import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import StatusDot from 'components/base/StatusDot';
import { useBankGuarantee } from 'components/providers/BankGuaranteeProvider';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick(uuidEntry: string): void;
}

export default function BankGuaranteeCard(props: Props) {
  const { onClick } = props;
  const { t } = useTranslation();
  const guaranteeService = useBankGuarantee();
  const theme = useTheme();

  useEffect(() => {
    guaranteeService.loadEntries();
  }, []);

  if (!guaranteeService.hasEntries) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      // data-urlname={pg.urlname}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evently',
        padding: '16px',
        gap: { xs: '16px', lg: '24px' },
        background: theme.palette.secondary.main,
        borderRadius: '16px',
      }}
    >
      <Box sx={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
        <Typography variant={'h3b'} sx={{ lineHeight: '130%', fontSize: { xs: '22px', lg: '26px' } }}>
          {t('interface:base.BankGuaranteeCard.title')}
        </Typography>
      </Box>

      <Box>
        {guaranteeService.entries?.map((_item, idx) => (
          <Box>
            <Box sx={{ display: 'inline-flex', width: '100%', gap: '8px', alignItems: 'center' }}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6' component='span'>
                {idx + 1} Заявка на гарантию
              </Typography>
              <StatusDot sx={{ width: '4px', height: '4px' }} color={theme.palette.text.secondary} />
              <Typography sx={{ flexGrow: 1, color: theme.palette.text.secondary }} variant='h6' component='span'>
                {/* {utils.format.nameWithInitials(auth.user!)} */}
                ООО "Мамкины Пивовары"
              </Typography>
            </Box>
            <Button
              data-testid='entry-info-btn'
              size='small'
              fullWidth
              sx={{
                padding: 0,
                heigth: '21px',
                color: `${theme.palette.text.primary}`,
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
                <StatusDot sx={{ width: '11px', height: '11px' }} color={'#F8E11A'} />
                <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
                  {idx > 2 ? <Fragment>Ожидание ответа от банка Казани </Fragment> : <Fragment> Заполнение заявки</Fragment>}
                </Typography>
              </Box>
            </Button>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between', gap: '8px' }}>
        <Button
          onClick={() => {
            onClick(guaranteeService?.entries?.[0].uuid!);
          }}
          fullWidth
          variant='contained'
          color='primary'
          size='large'
          data-testid={'additional'}
        >
          {t('interface:page.HomeClientPage.EntryCard.additional')}
        </Button>
      </Box>
    </Paper>
  );
}
