import { Autocomplete, CircularProgress, SxProps, TextField, Theme } from '@mui/material';
import { suggestAddress } from 'api/services/suggestions';
import { SuggestionAddress } from 'api/services/suggestions.dto';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ObjectType } from 'utils/iots';

interface Props<T> {
  disabled: boolean;
  id: string;
  label: string;
  value: string;
  onSelect: (value: T) => void;
  error: boolean;
  ['data-testid']: string;
  helperText: string;
  sx: SxProps<Theme>;
  fullWidth?: boolean;
}

export default function BaseSuggestionAddressInput<T extends SuggestionAddress>({
  disabled = false,
  id,
  onSelect,
  value,
  label,
  sx = {},
  ['data-testid']: testId,
  error,
  helperText,
  fullWidth,
}: Partial<Props<T>>) {
  const [fieldValue, setFieldValue] = useState<T>(useCallback(() => ({ fullAddress: value || '' }) as T, [value]));
  const [suggestions, setSuggestions] = React.useState<Array<T>>([]);

  const [loading, setLoading] = React.useState(false);

  // handle suggestion
  const requestSuggestions = async (q: string) => {
    setLoading(true);
    try {
      const { body: suggestions = [] } = await suggestAddress(q);
      setSuggestions(suggestions as T[]);
    } catch (err) {
      // TODO: Error processing
    } finally {
      setLoading(false);
    }
  };

  const throttled = useCallback(_.debounce(requestSuggestions, 300), []);

  const handleOnChage = useCallback((_event: unknown, val: unknown) => {
    onSelect?.(val as T);
  }, []);

  const handleInputChange = useCallback((_, val: string) => {
    onSelect?.({ fullAddress: val } as T);
    setFieldValue({ fullAddress: val } as T);
    if (val.length >= 5) throttled(val);
  }, []);

  useEffect(() => {
    setFieldValue({ fullAddress: value || '' } as T);
  }, [value]);

  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) => (option as T).fullAddress ?? ''}
      sx={sx}
      filterOptions={(x) => x}
      options={suggestions}
      disabled={disabled}
      loading={loading}
      inputValue={fieldValue?.fullAddress}
      freeSolo
      fullWidth={fullWidth}
      onChange={handleOnChage}
      renderOption={(props, option) => <li {...props}>{option.fullAddress}</li>}
      onInputChange={handleInputChange}
      value={fieldValue}
      renderInput={(params) => {
        (params as ObjectType).inputProps['data-testid'] = testId;
        return (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            label={label}
            variant='standard'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}

// BaseSuggestionAddressInput.propTypes = {
//   onSelect: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   sx: PropTypes.object,
//   label: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired,
//   id: PropTypes.string.isRequired,
//   ['data-testid']: PropTypes.string,
//   error: PropTypes.bool,
//   helperText: PropTypes.string,
// };
