/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const SadEmojiIcon = createSvgIcon(
  <svg height='100%' width='100%' fill='none' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127C98.7939 127 127 98.7939 127 64C127 29.2061 98.7939 1 64 1C29.2061 1 1 29.2061 1 64C1 98.7939 29.2061 127 64 127Z'
      fill='#F9F8F4'
      stroke='#B9B9B2'
      strokeWidth='2'
    />
    <path d='M87 94C81 87.0333 72.2843 83 64 83C55.7157 83 47 87.0333 41 94' stroke='#B9B9B2' strokeLinecap='round' strokeWidth='2' />
    <path
      d='M40 57C43.3137 57 46 54.3137 46 51C46 47.6863 43.3137 45 40 45C36.6863 45 34 47.6863 34 51C34 54.3137 36.6863 57 40 57Z'
      fill='#B9B9B2'
    />
    <path
      d='M88 57C91.3137 57 94 54.3137 94 51C94 47.6863 91.3137 45 88 45C84.6863 45 82 47.6863 82 51C82 54.3137 84.6863 57 88 57Z'
      fill='#B9B9B2'
    />
  </svg>,
  'SadEmojiIcon',
);

export default SadEmojiIcon;
