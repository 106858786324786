import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { DocumentSource } from 'api/services/experiment/experiment.dto';

interface FilePreviewProps {
  descriptor: DocumentSource;
  open: boolean;
  onClose: () => void;
  fileName: string;
}
export default function FilePreview(props: FilePreviewProps) {
  return (
    <Dialog {...props} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: 0 }}>
        <IconButton size='small' onClick={props.onClose}>
          <ClearIcon />
        </IconButton>
      </Box>
      <DialogTitle
        id='alert-dialog-title'
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: '20px' }}
      >
        <Typography variant='h3b'>{props.fileName}</Typography>
        {/* <Typography variant='body2'>{props.descriptor.description}</Typography> */}
      </DialogTitle>
      <DialogContent dividers sx={{ paddingTop: '40px' }}>
        <Box component='img' sx={{ width: 1, height: 1 }} src={props.descriptor.url} alt={props.fileName} loading='lazy' />
      </DialogContent>
      <DialogActions>
        <Button autoFocus fullWidth onClick={props.onClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}
