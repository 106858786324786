export enum EMortgageEntryParamsSubjectType {
  flat = 'flat',
  apartment = 'apartment',
  townhouse = 'townhouse',
  houseWithSomeLand = 'houseWithSomeLand',
  landParcel = 'landParcel',
  carport = 'carport',
  storage = 'storage',
  building = 'building',
  room = 'room',
  commercialProperty = 'commercialProperty',
}

export enum EMortgageEntryParamsSpecials {
  privilege = 'privilege',
  family = 'family',
  it = 'it',
  military = 'military',
  farEastern = 'farEastern',
}

export enum EMortgageEntryParamsSubjectSubtype {
  primary = 'primary',
  secondary = 'secondary',
}

export enum ERfRegion {
  moscow = 'moscow',
  moscowRegion = 'moscowRegion',
  stPetersburg = 'stPetersburg',
  leningradObl = 'leningradObl',
  otherRegions = 'otherRegions',
  adygeya = 'adygeya',
  bashkor = 'bashkor',
  buryatiya = 'buryatiya',
  respAltai = 'respAltai',
  kalmikia = 'kalmikia',
  respKarelia = 'respKarelia',
  komi = 'komi',
  mariyAl = 'mariyAl',
  mordoviya = 'mordoviya',
  saha = 'saha',
  kazan = 'kazan',
  tyva = 'tyva',
  udmurtia = 'udmurtia',
  hakasia = 'hakasia',
  chuvashia = 'chuvashia',
  barnaul = 'barnaul',
  krasnodarskiyKray = 'krasnodarskiyKray',
  krasnoyarsk = 'krasnoyarsk',
  primorskiiKray = 'primorskiiKray',
  stavropolskiyKray = 'stavropolskiyKray',
  habarovsk = 'habarovsk',
  amurskObl = 'amurskObl',
  arkhangelskObl = 'arkhangelskObl',
  astrakhanskObl = 'astrakhanskObl',
  belgorodObl = 'belgorodObl',
  bryanObl = 'bryanObl',
  vladimirObl = 'vladimirObl',
  volgograd = 'volgograd',
  volgogradObl = 'volgogradObl',
  voroneg = 'voroneg',
  ivanObl = 'ivanObl',
  irkutsk = 'irkutsk',
  kaliningrad = 'kaliningrad',
  kaluzhskObl = 'kaluzhskObl',
  kamchatKray = 'kamchatKray',
  kemerObl = 'kemerObl',
  kirovObl = 'kirovObl',
  kostromObl = 'kostromObl',
  kurganObl = 'kurganObl',
  kurskObl = 'kurskObl',
  lenObl = 'lenObl',
  lipetskObl = 'lipetskObl',
  magadanObl = 'magadanObl',
  murmansk = 'murmansk',
  nNovObl = 'nNovObl',
  novObl = 'novObl',
  novsibObl = 'novsibObl',
  omsk = 'omsk',
  orenburgObl = 'orenburgObl',
  orlovskObl = 'orlovskObl',
  penzObl = 'penzObl',
  permKray = 'perm',
  pskovObl = 'pskovObl',
  rostovObl = 'rostovObl',
  ryazanObl = 'ryazanObl',
  samarObl = 'samarObl',
  saratObl = 'saratObl',
  sakhalinObl = 'sakhalinObl',
  sverdlovskObl = 'sverdlovskObl',
  smolenskObl = 'smolenskObl',
  tambovObl = 'tambovObl',
  tverObl = 'tverObl',
  tomskObl = 'tomskObl',
  tylskObl = 'tylskObl',
  tymenObl = 'tymenObl',
  ulyanovObl = 'ulyanovObl',
  chelObl = 'chelObl',
  zabaykalKray = 'zabaykalKray',
  yarosObl = 'yarosObl',
  evrObl = 'evrObl',
  nenetsk = 'nenetsk',
  hantMan = 'hantMan',
  chukotsk = 'chukotsk',
  crim = 'crim',
  sevastopol = 'sevastopol',
}
export enum EMortgageEntryParamsPurpose {
  purchaseProperty = 'purchaseProperty',
  refinanceMortgage = 'refinanceMortgage',
  homeownerLoan = 'homeownerLoan',
  abuildingHome = 'abuildingHome',
}
export enum EMortgageEntryInitialPaySourceType {
  savings = 'savings',
  maternityCapital = 'maternityCapital',
  subsidium = 'subsidium',
  loan = 'loan',
  other = 'other',
}
