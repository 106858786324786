import { Box, Button, SvgIcon, TextField, useTheme } from '@mui/material';
import FilterIcon from 'components/Icons/Filter';
import SearchIcon from 'components/Icons/Search';
import PropTypes from 'prop-types';

const BaseInsuranceListSearchAndFilter = ({ NoFilter = false, toggleFilters, onChangeQuery, sx }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '24px', ...(sx || {}) }}>
      <TextField
        sx={{ width: '100%' }}
        placeholder={'Поиск'}
        inputProps={{ 'data-testid': 'search' }}
        onChange={onChangeQuery}
        className='filter'
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />

      {!NoFilter && (
        <Button sx={{ padding: '6px', minWidth: '48px' }} onClick={toggleFilters} variant='contained' color='secondary'>
          <SvgIcon component={FilterIcon} stroke={`${theme.palette.text.primary}`} />
        </Button>
      )}
    </Box>
  );
};

BaseInsuranceListSearchAndFilter.propTypes = {
  toggleFilters: PropTypes.func,
  NoFilter: PropTypes.bool,
  onChangeQuery: PropTypes.func,
  sx: PropTypes.object,
};

export default BaseInsuranceListSearchAndFilter;
