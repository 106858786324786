/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const ArrowLeftRoundIcon = createSvgIcon(
  <svg viewBox="0 0 58 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_37_4167)">
      <rect width="44" height="44" rx="22" transform="matrix(-1 0 0 1 44 14)" fill="#F8E11A" shape-rendering="crispEdges" />
      <path d="M21 31L16 36M16 36L21 41M16 36L28 36" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <filter id="filter0_dd_37_4167" x="-14" y="0" width="72" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.776471 0 0 0 0 0.776471 0 0 0 0 0.776471 0 0 0 0.11 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37_4167" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-4" dy="-4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.775 0 0 0 0 0.775 0 0 0 0 0.775 0 0 0 0.11 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_37_4167" result="effect2_dropShadow_37_4167" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_37_4167" result="shape" />
      </filter>
    </defs>
  </svg>,
  'ArrowLeftRoundIcon',
);

export default ArrowLeftRoundIcon;
