/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ComputedContainer from './Elements/ComputedContainer';
import FormInlineGroup from './FormInlineGroup';
import FormAddressField from './Input/AddressField';
import FormCheckboxField from './Input/CheckboxField';
import DateTextField from './Input/DateField';
import FormFileField from './Input/FileField';
import FormMultiFileField from './Input/MutilFileField';
import FormNumberField from './Input/NumberField';
import FormRadioField from './Input/RadioField';
import FormSelectField from './Input/SelectField';
import SuggestionField from './Input/SuggestionField';
import FormTextField from './Input/TextField';
import { EFormInputType, FormSchemeData } from './form.types';
import { BaseFormGeneratorProps, RenderInputProps, RenderNodeProps } from './types';
import { ft, getAllDependedKeys } from './utils';

const RederInput = memo(
  function ({ node, hookForm, path = [] }: RenderInputProps) {
    const { type } = node;

    const [, fieldSubtype] = type.split('.');

    switch (fieldSubtype) {
      case 'multiFile':
        return <FormMultiFileField node={node} hookForm={hookForm} path={path} />;
      case 'textField': {
        // TODO: Create new type - input.address
        if (node?.settings?.mask === 'suggestionAddress') {
          return <FormAddressField node={node} hookForm={hookForm} path={path} />;
        }
        return <FormTextField node={node} hookForm={hookForm} path={path} />;
      }
      case 'number':
        return <FormNumberField node={node} hookForm={hookForm} path={path} />;
      case 'radio':
        return <FormRadioField node={node} hookForm={hookForm} path={path} />;
      case 'date':
        return <DateTextField node={node} hookForm={hookForm} path={path} />;
      // case 'datepciker':
      // return <DatePickerField node={node} hookForm={hookForm} path={path} />;
      case 'select':
        return <FormSelectField node={node} hookForm={hookForm} path={path} />;
      case 'file':
        return <FormFileField node={node} hookForm={hookForm} path={path} />;
      case 'checkbox':
        return <FormCheckboxField node={node} hookForm={hookForm} path={path} />;
      case 'suggestion':
        return <SuggestionField node={node} hookForm={hookForm} path={path} />;

      default:
        return <Fragment />;
    }
  },
  (_prevProps, nextProps) => {
    // TODO: Check if value in depend filed has been changed
    if (!nextProps.path) return false;
    const nodeDepends = getAllDependedKeys(nextProps?.node);
    if (!nodeDepends.length) return true;
    const hasDependNode = nodeDepends.some((el) => nextProps?.dependedFields?.includes(el));

    // Lines abowe can help with debugging
    //const nodeFieldKey = nextProps.path.join('.');
    // eslint-disable-next-line max-len
    //console.log('RederInput:RERENDER:', `[${nodeFieldKey}]`, `|${nextProps?.dependedFields}|`, `|${nodeDepends}|`, ' -> ', hasDependNode);

    if (hasDependNode) {
      return false;
    }
    return true;
  },
);
type ListGroupNodeProps = RenderNodeProps;

export const ListGroupNode = memo(
  function ({ node, hookForm, options = {}, path = [], dependedFields, group }: ListGroupNodeProps) {
    const { name, type } = node;

    if (type === 'listgroup') {
      return (
        <>
          {node?.data?.map((item) => (
            <RenderNode
              key={item.name}
              node={item}
              hookForm={hookForm}
              path={[...path] as string[]}
              dependedFields={dependedFields}
              group={group}
              options={options}
            />
          ))}
        </>
      );
    }
    return (
      <RenderNode
        node={node}
        path={[...path, name] as string[]}
        hookForm={hookForm}
        dependedFields={dependedFields}
        group={group}
        options={options}
      />
    );
  },
  (_prevProps, nextProps) => {
    // TODO: Check if value in depend filed has been changed
    if (!nextProps.path) return false;
    const nodeDepends = getAllDependedKeys(nextProps?.node);
    if (!nodeDepends.length) return true;
    const hasDependNode = nodeDepends.some((el) => nextProps?.dependedFields?.includes(el));

    // Lines abowe can help with debugging
    // const nodeFieldKey = [...nextProps.path, nextProps.node?.name].join('.');
    // eslint-disable-next-line max-len
    // console.log('RenderNode:RERENDER:', `[${nodeFieldKey}]`, `|${nextProps?.dependedFields}|`, `|${nodeDepends}|`, ' -> ', hasDependNode);

    if (hasDependNode) {
      return false;
    }
    return true;
  },
);

export const RenderNode = memo(
  function ({ node, hookForm, options = {}, path = [], dependedFields, group }: RenderNodeProps) {
    const { t } = useTranslation();
    const { name, type } = node;

    if (type === 'form') {
      return (
        <Fragment>
          {node?.data?.map((item) => (
            <RenderNode
              key={item.name}
              node={item}
              hookForm={hookForm}
              path={[...path, name] as string[]}
              dependedFields={dependedFields}
              group={group}
              options={options}
            />
          ))}
        </Fragment>
      );
    }

    if (type === 'group') {
      if (group && node.name !== group) return <Fragment />;
      return (
        <>
          {(!options.hideGroupTitle || !node?.settings?.hiddenTitle) && (
            <Typography sx={{ width: 1 }} variant='h2b'>
              {t(`${ft([...path, name])}.title`)}
            </Typography>
          )}
          <ComputedContainer path={['profile']} node={node} hookForm={hookForm} dependedFields={dependedFields}>
            <Fragment>
              {node?.data?.map((item) => (
                <RenderNode
                  key={item.name}
                  node={item}
                  hookForm={hookForm}
                  path={[...path, name] as string[]}
                  dependedFields={dependedFields}
                  group={group}
                  options={options}
                />
              ))}
            </Fragment>
          </ComputedContainer>
        </>
      );
    }
    if (type === 'repeatableGroup' || type === EFormInputType.listgroup) {
      return <ListGroupNode node={node} hookForm={hookForm} path={[...path, name] as string[]} dependedFields={dependedFields} />;
    }
    if (type === 'cardgroup' || type === EFormInputType.inlinegroup) {
      if (node?.settings?.dependsOn?.length) {
        const watchAllFields = hookForm.watch();
        for (const depends of node.settings.dependsOn) {
          const { name, requiredValues } = depends;
          if (!requiredValues.includes(_.get(watchAllFields, name))) {
            return <Fragment />;
          }
        }
      }

      return <FormInlineGroup node={node as Required<FormSchemeData>} path={path} hookForm={hookForm} />;
    }

    if (type === 'subgroup') {
      if (node?.settings?.dependsOn?.length) {
        const watchAllFields = hookForm.watch();
        for (const depends of node.settings.dependsOn) {
          const { name, requiredValues } = depends;
          if (!requiredValues.includes(_.get(watchAllFields, name))) {
            return <Fragment />;
          }
        }
      }
      return (
        <Stack spacing={2} sx={{ width: 1 }}>
          {!node?.settings?.hiddenTitle && (
            <Typography sx={{ width: 1 }} variant='h3b'>
              {t(`${ft([...path, name])}.title`)}
            </Typography>
          )}
          {node?.data?.map((item) => (
            <RenderNode
              key={item.name}
              node={item}
              hookForm={hookForm}
              path={[...path, name] as string[]}
              dependedFields={dependedFields}
              group={group}
              options={options}
            />
          ))}
        </Stack>
      );
    }

    if (type.split('.')[0] === 'input') {
      return <RederInput node={node} hookForm={hookForm} path={[...path, name] as string[]} dependedFields={dependedFields} />;
    }

    return (
      <code>
        Undefined node [{node?.type}]: {path.join('.')}
      </code>
    );
  },
  (_prevProps, nextProps) => {
    // TODO: Check if value in depend filed has been changed
    if (!nextProps.path) return false;
    const nodeDepends = getAllDependedKeys(nextProps?.node);
    if (!nodeDepends.length) return true;
    const hasDependNode = nodeDepends.some((el) => nextProps?.dependedFields?.includes(el));

    // Lines abowe can help with debugging
    // const nodeFieldKey = [...nextProps.path, nextProps.node?.name].join('.');
    // eslint-disable-next-line max-len
    // console.log('RenderNode:RERENDER:', `[${nodeFieldKey}]`, `|${nextProps?.dependedFields}|`, `|${nodeDepends}|`, ' -> ', hasDependNode);

    if (hasDependNode) {
      return false;
    }
    return true;
  },
);

function BaseFormGenerator({ scheme, hookForm, group, path = [], options = {} }: BaseFormGeneratorProps) {
  const dependedFields = useMemo(() => {
    return getAllDependedKeys(scheme);
  }, [scheme]);

  return (
    <Stack spacing={4} sx={{ flexGrow: 1 }}>
      <RenderNode
        node={{ type: EFormInputType.form, ...scheme } as FormSchemeData}
        path={path}
        hookForm={hookForm}
        dependedFields={dependedFields}
        group={group}
        options={options}
      />
    </Stack>
  );
}

export default BaseFormGenerator;
