import { IUserEntity } from 'api/services/user.dto';
import { JivoWindow } from 'models/jivoWindow.model';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import utils from 'utils';
import { IDataNameFormat } from 'utils/format/format';
import { ObjectType } from 'utils/iots';

interface ICarrotQuest {
  open(): void;
  identify(obj: ObjectType): void;
  onReady(callback: ObjectType): void;
  removeChat(): void;
}

interface CarrotQuestWindow extends Window {
  carrotquest: ICarrotQuest;
}

const { REACT_APP_WP_ID: widgetProfeatToken, REACT_APP_WP_PHONE: waPhone } = import.meta.env;

export default function useSupportChat() {
  const w = window as unknown as CarrotQuestWindow;
  const carrotquest = useRef<ICarrotQuest>();
  const isReady = () => !!carrotquest.current;

  useEffect(() => {
    const interval = setInterval(() => {
      if ('carrotquest' in w) {
        carrotquest.current = w.carrotquest;
        if (isMobile) {
          w.carrotquest.onReady(function () {
            w.carrotquest.removeChat();
            clearInterval(interval);
          });
        } else {
          clearInterval(interval);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const prepareData = (user: IUserEntity) => {
    carrotquest?.current?.identify({
      $name: utils.format.getFullName(user as unknown as IDataNameFormat),
      $email: user?.email,
      $phone: String(user?.phone),
      uuidUser: user?.uuid,
      group: user.roles.join(' '),
    });
  };

  const open = () => {
    if ((window as unknown as JivoWindow).jivo_api) {
      (window as unknown as JivoWindow).jivo_api.open();
      return;
    }

    if (widgetProfeatToken) {
      window.location.href = `https://api.whatsapp.com/send/?phone=${waPhone}&text&type=phone_number&app_absent=0`;
      return;
    }
  };
  return { open, isReady, prepareData };
}
