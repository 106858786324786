import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomLink = styled((props) => <Link {...props} />)(() => ({
  display: 'flex',
  lineHeight: '150%',
  cursor: 'pointer',
}));

const BaseInsuranceListSorting = ({ handleSort, title, sx }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const handleExpand = (e) => {
    e.nativeEvent.preventDefault();

    setExpanded((state) => !state);
    handleSort();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px', gap: '8px', ...(sx || {}) }}>
      <Typography sx={{ cursor: 'default' }}>{t('interface:base.BaseInsuranceListSorting.sortLabel')}</Typography>
      <CustomLink href='/' variant='h4' color={theme.palette.common.link} underline='none' onClick={handleExpand}>
        {title}
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </CustomLink>
    </Box>
  );
};

BaseInsuranceListSorting.propTypes = {
  handleSort: PropTypes.func,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default BaseInsuranceListSorting;
