/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const Rosbank = createSvgIcon(
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='48' rx='24' fill='white' />
    <path d='M32.8485 6L15 8.36156V13.7119L32.8485 12.0989V6Z' fill='#ED1443' />
    <path
      d='M24.4148 30.8522C21.2745 30.8522 20.2958 29.6757 20.2958 26.2891C20.2958 22.8856 21.2745 21.7258 24.4148 21.7258C27.5716 21.7258 28.5175 22.8856 28.5175 26.2891C28.5175 29.6757 27.5716 30.8522 24.4148 30.8522ZM26.2984 17.2712C23.3172 17.2712 20.8932 19.1687 20.2917 19.9565V17.6737H15V41H20.2591V32.7067C20.8768 33.5113 23.2846 35.3749 26.282 35.3749C31.2626 35.3749 34 32.588 34 26.3147C34 20.0582 31.2626 17.2712 26.2984 17.2712Z'
      fill='#00234D'
    />
  </svg>,
  'Rosbank',
);

export default Rosbank;
