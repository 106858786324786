import { Paper, Typography, useTheme } from '@mui/material';
import { suggestFullCompany, suggestSearchCompany } from 'api/services/suggestions';
import { SuggestionCompanyResponse } from 'api/services/suggestions.dto';
import DadataSuggestion from 'components/base/DadataSuggestion';
import { useTranslation } from 'react-i18next';

interface Props {
  onAddCompany(companyData: any): void;
}
export default function CompanySearch({ onAddCompany }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const suggestionCompany = async (data: SuggestionCompanyResponse) => {
    if (!data || !data.inn) {
      return;
    }
    try {
      const res = await suggestFullCompany(String(data.inn));
      onAddCompany(res.body);
    } catch (err) {
      //todo
      console.log(err);
    }
  };
  const handleSelect = (selectedValue: SuggestionCompanyResponse) => {
    suggestionCompany(selectedValue);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        borderRadius: '16px',
      }}
    >
      <Typography variant='h3' sx={{ margin: '8px 0 24px', fontSize: '22px', fontWeight: '700' }}>
        {t('base.CompanySearch.title')}
      </Typography>
      <Typography variant='h5' sx={{ margin: '8px 0 24px', fontSize: '18px', color: theme.palette.text.secondary }}>
        {t('base.CompanySearch.subtitle')}
      </Typography>
      <DadataSuggestion
        variant='outlined'
        onSelect={handleSelect}
        path={['name']}
        onQuery={suggestSearchCompany}
        placeholder={t('base.CompanySearch.searchPlaceholder')}
      />
    </Paper>
  );
}
