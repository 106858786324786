export enum EApplicationPlatform {
  android = 'android',
  ios = 'ios',
  mac = 'mac',
  windows = 'windows',
  linux = 'linux',
  unknown = 'unknown',
}

export enum EApplicationSource {
  app = 'app',
  pwa = 'pwa',
  web = 'web',
  unknown = 'unknown',
}
/**
 *
 * @returns application_source
 */
async function detectApplication(): Promise<EApplicationSource> {
  try {
    if (!window) {
      return EApplicationSource.web;
    }
    const isTwa = document.referrer.includes('twa');
    if (isTwa) {
      return EApplicationSource.app;
    }
    if ('getInstalledRelatedApps' in navigator) {
      //@ts-ignore
      const relatedApps = await navigator.getInstalledRelatedApps();
      if (relatedApps.length > 0) return EApplicationSource.pwa;
    }
  } catch (err) {
  } finally {
    return EApplicationSource.web;
  }
}

function detectPlatform(): EApplicationPlatform {
  const userAgent = window.navigator.userAgent;
  if ('userAgentData' in window.navigator || 'platform' in window.navigator) {
    //@ts-ignore
    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (macosPlatforms.indexOf(platform) !== -1) {
      return EApplicationPlatform.mac;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return EApplicationPlatform.ios;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return EApplicationPlatform.windows;
    } else if (/Linux/.test(platform)) {
      return EApplicationPlatform.linux;
    }
  }
  if (/Android/.test(userAgent)) {
    return EApplicationPlatform.android;
  }
  return EApplicationPlatform.unknown;
}

export default async function storePlatform() {
  const platform = await detectPlatform();
  const app = await detectApplication();
  return { ['application_platform']: String(platform), application_source: String(app) } as const;
}
