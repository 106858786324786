import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { EContractSignType, EContractType } from 'api/services/contract.dto';
import { userCheckContractCode, userIssueContractSmsCode } from 'api/services/user';
import CommonCheckCode from 'components/common/CheckCode/CheckCode';
import { useAuth } from 'components/providers/AuthProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { Fn, Nullable } from 'utils/iots';

interface UserContractSignFormProps {
  text?: string;
  onSuccess: Fn<void>;
  contract: EContractType;
}

// TODO: rename const ContractSignForm to UserContractSignForm
// TODO: Makeup for conmponents positing & margins
export default function UserContractSignForm({ text, onSuccess }: UserContractSignFormProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [smsCodeInputShow, setSmsCodeInputShow] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tsSent, setTsSent] = useState<Nullable<number>>(null);

  const smsCodeRequest = async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await userIssueContractSmsCode(EContractType.agentContract);
      if (res.ok) {
        setSmsCodeInputShow(true);
        setTsSent(new Date().valueOf());
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  const checkCodeRequest = async (code: string) => {
    setError(null);
    setLoading(true);
    if (!user?.phone || !tsSent) {
      return;
    }

    const signatures: Record<EContractSignType, string> = {
      [EContractSignType.sms]: code,
      [EContractSignType.handwritten]: utils.crypto.generateSignature(user?.phone.toString(), code, tsSent),
    };
    const agreements: Record<string, boolean> = {
      contract: true,
      contractApplication1: true,
    };

    // [
    //   {
    //     name: 'contract',
    //     value: true,
    //     valueSetAt: ,
    //   },
    //   {
    //     name: 'contractApplication1',
    //     value: true,
    //     valueSetAt: utils.crypto.generateSignature(user?.phone.toString(), code, tsSent),
    //   },
    // ];

    try {
      const res = await userCheckContractCode({ type: EContractType.agentContract, agreements, signatures });
      if (res.ok) return onSuccess();
      setError('logic.wrongSmsCode');
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  const handleAgreeClick = () => {
    smsCodeRequest();
  };

  return (
    <>
      <Typography component='div' variant='h3b' sx={{ marginBottom: '16px' }}>
        {t('interface:page.UserContractPage.SignForm.title')}
      </Typography>
      {/* TODO: background: '#F9F8F4' */}
      <Box sx={{ width: 1, height: '470px', overflow: 'auto', marginBottom: '32px' }}>
        {text && <Typography dangerouslySetInnerHTML={{ __html: text }} />}
      </Box>
      <Box>
        {!smsCodeInputShow ? (
          <>
            <Button variant='contained' size='large' sx={{ width: '100%', marginBottom: '16px' }} onClick={handleAgreeClick}>
              {loading ? <CircularProgress size={24} /> : t('interface:page.UserContractPage.SignForm.acceptBtn')}
            </Button>
            <Typography component='div' textAlign='center' sx={{ color: theme.palette.text.secondary, width: 1 }} variant={'h7'}>
              {t('interface:page.UserContractPage.SignForm.acceptBtnDescription')}
            </Typography>
          </>
        ) : (
          <CommonCheckCode resendCodeRequest={smsCodeRequest} checkCodeRequest={checkCodeRequest} />
        )}
        {error && (
          <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
            {t(`errors:${error}`)}
          </Typography>
        )}
      </Box>
    </>
  );
}
