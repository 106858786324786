import { Box, Container, ContainerProps, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface ContainerLayoutProps extends ContainerProps {
  title: string;
  meta: Array<JSX.IntrinsicElements['meta']>;
  hideHeader?: boolean;
  children: React.ReactNode;
  header: { lg?: JSX.Element; xs?: JSX.Element };
  noContainer?: boolean;
  sx?: SxProps<Theme>;
}

export default function ContainerLayout({
  title,
  children,
  meta,
  noContainer,
  header,
  maxWidth,
  ...containerProps
}: Partial<ContainerLayoutProps>) {
  const { t } = useTranslation();
  const theme = useTheme();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDesktop = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return (
    <Fragment>
      {noContainer ? (
        <Box {...containerProps}>
          <Helmet title={`${t('helmet:title')} - ${title}`} meta={meta} />
          {isDesktop ? header?.lg : header?.xs}
          {children}
        </Box>
      ) : (
        <Fragment>
          <Container {...containerProps} maxWidth={maxWidth}>
            {isDesktop ? header?.lg : header?.xs}
            <Helmet title={`${t('helmet:title')} - ${title}`} meta={meta} />
            {children}
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
}
