import { Box, Button, Typography } from '@mui/material';
import Page from 'components/layouts/Page';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ErrorNotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Page title={t('helmet:ErrorNotFoundPage.title')} hideHeader={true}>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px 0 ' }}>
        <Typography variant={'h3b'} sx={{ display: 'block', marginBottom: '20px' }} align={'center'}>
          {t('errors:page.404')}
        </Typography>
        <Button fullWidth variant='contained' sx={{ width: '350px', margin: 'auto' }} onClick={() => navigate('/')}>
          {t('interface:page.ErrorNotFoundPage.goHomeBtn')}
        </Button>
      </Box>
    </Page>
  );
};

export default ErrorNotFoundPage;
