import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { ApiError } from 'api/errors';
import { LS_SIGN_CONTEXT } from 'api/http';
import { CreditHistoryContract, EContractType } from 'api/services/contract.dto';
import { getEsiaUrl } from 'api/services/esia';
import { userCheckContractCode, userUploadFile } from 'api/services/user';
import BaseButtonBack from 'components/base/Button/Back';
import DrawSign from 'components/base/DrawSign';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useBroadcastState from 'hooks/useBroadcastState';
import useNavigateParams from 'hooks/useNavigateParams';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS,
  PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY,
  PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY,
} from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

import SmsVerify from '../SignEntry/components/SmsVerify';

import Privacy from './components/Privacy';
import DocumentSuccess from 'components/Icons/DocumentSuccess';

enum ESignSteps {
  form = 'form',
  signByEsia = 'signByEsia',
  signByDraw = 'signByDraw',
  signBySMS = 'signBySMS',
}

export type TLocationParams = {
  uuid: string;
};

const ClientMortgageEntrySignPrivacyPage = () => {
  const theme = useTheme();
  const [eseaCode, setCode] = useBroadcastState('ls-esea-key');
  const { t } = useTranslation();
  const navigate = useNavigateParams();
  const [params] = useSearchParams();
  const { uuid } = useParams<keyof TLocationParams>() as TLocationParams;

  const [esiaURL, setEsiaURL] = useState<string>();
  const [, setEsiaError] = useState<ApiError>();

  const step = params.get('step');
  const loadContext = useCallback((): CreditHistoryContract => {
    const ctx = localStorage.getItem(LS_SIGN_CONTEXT);
    if (ctx) {
      return JSON.parse(ctx) as CreditHistoryContract;
    } else {
      return {
        uuidEntry: uuid,
        type: EContractType.creditHistoryContract,
      } as CreditHistoryContract;
    }
  }, []);
  const loadAsync = async () => {
    try {
      const res = await getEsiaUrl();

      setEsiaURL(res.body.url);
    } catch (err: unknown) {
      setEsiaError(err as ApiError);
    }
  };
  const hookForm = useForm<CreditHistoryContract>({ defaultValues: useMemo(() => loadContext(), []) });
  useEffect(() => {
    if (import.meta.env.PROD) loadAsync();
    else {
      setEsiaURL(`http://${window.location.host}/auth/gos?code=123`);
    }

    const watcher = hookForm.watch(() => {
      saveContext(hookForm.getValues());
    });
    return watcher.unsubscribe;
  }, []);

  /**
   *
   */
  const saveContext = useCallback((values) => {
    localStorage.setItem(LS_SIGN_CONTEXT, JSON.stringify(values));
  }, []);

  const nextStep = () => {
    switch (step) {
      case ESignSteps.form: {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, { params: { uuid }, query: { step: ESignSteps.signByEsia } });
        break;
      }
      case ESignSteps.signByEsia: {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, { params: { uuid }, query: { step: ESignSteps.signByDraw } });
        break;
      }
      case ESignSteps.signByDraw: {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY, { params: { uuid }, query: { step: ESignSteps.signBySMS } });
        break;
      }
      case ESignSteps.signBySMS:
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY, { params: { uuid }, query: { step: 'preview' } });
    }
  };

  const uploadSignature = async (bsfile: string) => {
    try {
      const signatureImage = await utils.file.dataURLtoFile(bsfile, 'signature.png');
      const resourceResponse = await userUploadFile({ file: signatureImage, resourceType: 'profile' });

      if (resourceResponse.ok) {
        const link = resourceResponse.body.link;
        hookForm.setValue('assets.handSigImageUrl', [import.meta.env.REACT_APP_STORAGE_URL, link].join(''));
        hookForm.setValue('signatures.handwritten', [import.meta.env.REACT_APP_STORAGE_URL, link].join(''));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveSignature = (signature: ObjectType) => {
    uploadSignature(signature).then(() => {
      handleNextBtnClick();
    });
  };

  const handleSaveSmsCode = (code: string) => {
    hookForm.setValue('signatures.sms', utils.format.digitString(code) as never);
    userCheckContractCode(hookForm.getValues())
      .then((res) => {
        if (res.ok) {
          handleNextBtnClick();
        }
      })
      .catch(console.log);
  };

  const handleNextBtnClick = () => {
    nextStep();
  };

  useEffect(() => {
    if (eseaCode?.eseaToken && step === ESignSteps.signByEsia) {
      hookForm.setValue('signatures.esea', eseaCode.eseaToken);
      setCode('ls-esea-key');
      nextStep();
    }
  }, [eseaCode, step]);
  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntrySignPrivacyPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntrySignPrivacyPage.description') }]}
      // header={{
      //   title:
      //   type: 'backButton',
      //   backRoute: ,
      // }}
      header={{
        xs: (
          <BaseButtonBack
            title={t('interface:page.ClientMortgageEntrySignPrivacyPage.title')}
            backRoute={utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid })}
          />
        ),
        lg: <TopMenu />,
      }}
      sx={{ height: 1, justifyContent: 'space-evently', gap: '16px', paddingBottom: '20px' }}
    >
      {step === ESignSteps.form && (
        <Privacy
          onSubmitForm={(values) => {
            hookForm.setValue('agreements', values.agreements);
            hookForm.setValue('details', values.details);

            nextStep();
          }}
        />
      )}
      {step === ESignSteps.signByEsia && (
        <Box
          sx={{
            height: 0.8,
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            gap: '40px',
          }}
        >
          <SvgIcon component={DocumentSuccess} sx={{ width: '256px', height: '256px' }} />
          <Button href={esiaURL} variant='contained' size='large'>
            {t('interface:page.ClientMortgageEntrySignPrivacyPage.esea.btnTitle')}
          </Button>
          <Box>
            <Typography sx={{ color: theme.palette.text.secondary, textAlign: 'center' }}>
              {t('interface:page.ClientMortgageEntrySignPrivacyPage.esea.info')}
            </Typography>
          </Box>
        </Box>
      )}
      {step === ESignSteps.signByDraw && (
        <DrawSign
          lables={{
            title: 'interface:page.ClientMortgageEntrySignPrivacyPage.Draw.lblTitle',
            btnSign: 'interface:page.ClientMortgageEntrySignPrivacyPage.Draw.btnSign',
          }}
          handleSignBtnClick={handleSaveSignature}
        />
      )}
      {step === ESignSteps.signBySMS && (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '40px' }}>
          <Typography variant='h2' sx={{ fontWeight: 400, width: 'fit-content', textAlign: 'center' }}>
            {t('interface:page.AuthSignInByPhoneCodePage.inpCode')}
          </Typography>
          <SmsVerify contractType={EContractType.creditHistoryContract} handleNextBtnClick={handleSaveSmsCode} />
          {/* <Button onClick={handleNextBtnClick} fullWidth variant='contained' size='large'>
            {t('btnNext ->')}
          </Button> */}
        </Box>
      )}
    </ContainerLayout>
  );
};

export default ClientMortgageEntrySignPrivacyPage;
