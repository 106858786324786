import { Typography, TextField, Grid, MenuItem } from '@mui/material';
import { EProfileIncomeConfirmDocType, EProfileWorkplaceEmplConfirmDocType } from 'api/services/incomes/incomes.enum';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IncomesFormProps } from './types';
import NumberFormat from 'react-number-format';

export function IndividualEntrepreneur({ control, index, watch }: IncomesFormProps) {
  const { t } = useTranslation();
  const confirmationDocType = watch(`companies.${index}.income.confirmationDocType`);
  const emplConfirmDocType = watch(`companies.${index}.emplConfirmDocType`);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5m">{t('page.IncomesForm.companyTitle')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`companies.${index}.emplConfirmDocType`}
          control={control}
          render={({ field }) => (
            <TextField select {...field} fullWidth variant='standard' label={t('page.IncomesForm.emplConfirmDocType.title')}>
              {Object.values(EProfileWorkplaceEmplConfirmDocType).map((scope) => (
                <MenuItem value={scope} key={scope}>
                  {t(`page.IncomesForm.emplConfirmDocType.options.${scope}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      {emplConfirmDocType === EProfileWorkplaceEmplConfirmDocType.other && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`companies.${index}.emplConfirmDocTypeOther`}
            render={({ field }) => <TextField fullWidth variant='standard' label={t('page.IncomesForm.other')} {...field} />}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name={`companies.${index}.income.confirmationDocType`}
          control={control}
          render={({ field }) => (
            <TextField select {...field} fullWidth variant='standard' label={t('page.IncomesForm.confirmationDocType.title')}>
              {Object.values(EProfileIncomeConfirmDocType).map((scope) => (
                <MenuItem value={scope} key={scope}>
                  {t(`page.IncomesForm.confirmationDocType.options.${scope}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      {confirmationDocType === EProfileIncomeConfirmDocType.other && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`companies.${index}.income.confirmationOtherDoc`}
            render={({ field }) => <TextField fullWidth variant='standard' label={t('page.IncomesForm.other')} {...field} />}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          control={control}
          name={`companies.${index}.income.amount`}
          render={({ field, fieldState }) => (
            <NumberFormat
              variant='standard'
              suffix=' ₽'
              fullWidth
              customInput={TextField}
              label={t('page.IncomesForm.incomeAmount.title')}
              thousandSeparator=' '
              value={field.value}
              onValueChange={(values) => {
                field.onChange(values.floatValue);
              }}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
