import { TypographyOptions } from '@mui/material/styles/createTypography';

const h1 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '32px',
  lineHeight: '130%',
};

const h2 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '26px',
  lineHeight: '130%',
};

const h3 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '22px',
  lineHeight: '130%',
};

const h4 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '18px',
  lineHeight: '150%',
  fontWeight: 400,
};

const h5 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '130%',
};

const h6 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '140%',
  fontWeight: 400,
};

const h7 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '140%',
};

const h8 = {
  letterSpacing: 'normal',
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '140%',
};

const typography: TypographyOptions = {
  // @see https://www.figma.com/file/hfR7FHrhwopqXTW0NjQ6Aw/iPOK---UI?node-id=6%3A442
  // *b - bold, *m - medium, without - regular
  fontSize: 16,
  // ??? fontFamily: ['Roboto'],
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
  h1m: { ...h1, fontWeight: 500 },
  h1b: { ...h1, fontWeight: 700 },
  h2m: { ...h2, fontWeight: 500 },
  h2b: { ...h2, fontWeight: 700 },
  h3m: { ...h3, fontWeight: 500 },
  h3b: { ...h3, fontWeight: 700 },
  h4m: { ...h4, fontWeight: 500 },
  h4b: { ...h4, fontWeight: 700, lineHeight: '130%' },
  h5m: { ...h5, fontWeight: 500 },
  h5b: { ...h5, fontWeight: 700 },
  body1: { ...h4 },
  body2: { ...h5 },
  button: { ...h4, textTransform: 'none', lineHeight: '130%' },
};

export default typography;
