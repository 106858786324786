import { Box, Button, useTheme } from '@mui/material';
import useSupportModal from 'components/providers/SupportProvider';
import useSupportChat from 'hooks/useSupportChat';
import { useTranslation } from 'react-i18next';

export default function SupportButtons() {
  const supportRequest = useSupportModal();
  const supportChat = useSupportChat();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box sx={{ display: { sx: 'flex', lg: 'none' }, flexDirection: 'column', gap: '8px', paddingBottom: '40px' }}>
      {/* Support btn */}
      <Button
        fullWidth
        sx={{ display: { sx: 'block', lg: 'none' }, marginTop: '16px' }}
        onClick={supportRequest.openModal}
        variant='contained'
        size='large'
        data-testid={'btnGetConsultation'}
      >
        {t('interface:page.HomeClientPage.btnGetConsultation')}
      </Button>
      <Button
        fullWidth
        sx={{ display: { sx: 'block', lg: 'none' }, marginTop: '16px', background: theme.palette.secondary.main }}
        onClick={supportChat.open}
        size='large'
        data-testid={'btnGetConsultation'}
      >
        {t('interface:page.HomeClientPage.writeSupport')}
      </Button>
    </Box>
  );
}
