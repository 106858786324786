import { EMortgageEntryProposeStatus } from 'api/services/mortgage.dto';
import { z } from 'zod';

const numberError = {
  required_error: 'errors:validation.required',
  invalid_type_error: 'errors:validation.required',
};

const proposeDecisionSchema = z
  .object({
    initialFee: z.number(numberError),
    creditDuration: z.number(numberError).max(420, { message: 'errors:components.ProposeEditor.creditDurationLess' }),
    rate: z.number(numberError).max(100, { message: 'errors:components.ProposeEditor.rateMax' }),
    approvedSum: z.number(numberError),
    monthlyPayment: z.number(numberError),
  })
  .required({
    initialFee: true,
    rate: true,
    creditDuration: true,
    approvedSum: true,
    monthlyPayment: true,
  });

export const proposeSchema = z
  .object({
    status: z.nativeEnum(EMortgageEntryProposeStatus),
    decision: proposeDecisionSchema,
  })
  .refine(
    ({ decision }) => {
      return decision.approvedSum >= decision.initialFee;
    },
    {
      path: ['decision.initialFee'],
      message: 'errors:components.ProposeEditor.approvedSumLess',
    },
  );
