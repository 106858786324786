import { Button, Grid, Typography } from '@mui/material';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_INVITE, PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_CLIENT_MORTGAGE_FORM_PERSONAL } from 'routes/paths';
import utils from 'utils';

import useTypedParams from '../../../../../../hooks/useTypedParams';
import { useAuth } from 'components/providers/AuthProvider';
import { EExperimentName } from 'api/services/user.dto';
import { Fragment, useMemo } from 'react';

export type TLocationParams = {
  uuid: string;
  uuidProfile: string;
};

const FORM_NAME = 'profile';
const FORM_GROUP = 'personal';

const ClientMortgageEntryCreateSuccessPage = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { uuid, uuidProfile } = useTypedParams<keyof TLocationParams>() as TLocationParams;

  const handleBtnInviteGuarantorClick = () => {
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_INVITE.replace(':uuid', uuid).replace(':role', EMortgageEntryRole.guarantor));
  };
  const handleBtnInviteCoBorrowerClick = () => {
    navigate(PATH_CLIENT_MORTGAGE_ENTRY_INVITE.replace(':uuid', uuid).replace(':role', EMortgageEntryRole.coBorrower));
  };

  const hasExperiment = useMemo(
    () => auth.user?.settings?.experiments?.find((x) => x.name === EExperimentName.smartForm && x.status === true),
    [auth.user],
  );

  const handleBtnNextClick = () => {
    if (hasExperiment) {
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid }));
      return;
    }
    navigate(
      utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_PERSONAL, {
        uuidEntry: uuid,
        uuidProfile: uuidProfile,
        formName: FORM_NAME,
        groupName: FORM_GROUP,
      }),
    );
  };

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreateSuccessPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreateSuccessPage.description') }]}
      maxWidth='xl'
      header={{ xs: <BaseButtonBack title='' />, lg: <TopMenu /> }}
      sx={{ height: 0.7 }}
    >
      <Grid container>
        <Grid item md={7} xs={12} spacing={2}>
          <Typography variant='h2b' sx={{ fontSize: { md: '32px', xs: '26px' } }}>
            {t(`interface:page.ClientMortgageEntryCreateSuccessPage.title`)}
          </Typography>
          <Typography variant='h4' sx={{ whiteSpace: 'pre-line' }}>
            {t(`interface:page.ClientMortgageEntryCreateSuccessPage.subtitle`)}
          </Typography>
        </Grid>
        <Grid container item md={4} xs={12} spacing={{ xs: 1, md: 3 }} sx={{ paddingTop: '20px' }}>
          {!hasExperiment && (
            <Fragment>
              <Grid item md={3} xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ width: { xs: '100%', md: '320px' } }}
                  size='large'
                  color='secondary'
                  onClick={handleBtnInviteCoBorrowerClick}
                >
                  {t(`interface:page.ClientMortgageEntryCreateSuccessPage.btnInviteCoBorrower`)}
                </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ width: { xs: '100%', md: '320px' } }}
                  size='large'
                  color='secondary'
                  onClick={handleBtnInviteGuarantorClick}
                >
                  {t(`interface:page.ClientMortgageEntryCreateSuccessPage.btnInviteGuarantor`)}
                </Button>
              </Grid>
            </Fragment>
          )}

          <Grid item md={12} xs={12}>
            <Button fullWidth sx={{ width: { xs: '100%', md: '320px' } }} variant='contained' size='large' onClick={handleBtnNextClick}>
              {t(`interface:page.ClientMortgageEntryCreateSuccessPage.btnNext`)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
};

export default ClientMortgageEntryCreateSuccessPage;
