import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN } from 'routes/paths';
import utils from 'utils';
import CompanySearch from './components/CompanySearch';
import { Box, Button, Skeleton } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import IncomesWorkForm from './Forms';
import { IIncomesDataDTO } from 'api/services/incomes/incomes.interface';
import { EProfileWorkspaceType } from 'api/services/incomes/incomes.enum';
import { getIncomes, postIncomes } from 'api/services/incomes/incomes.service';
import useNavigateParams from 'hooks/useNavigateParams';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import BaseButtonBack from 'components/base/Button/Back';

export default function ClientMortgageEntryIncomesForm() {
  const { t } = useTranslation();
  const { uuidEntry, uuidUser } = useParams();
  const navigate = useNavigateParams();
  const [loading, setLoading] = useState(false);

  const loadAsync = async () => {
    setLoading(true);
    try {
      const res = await getIncomes({ uuidEntry: uuidEntry!, uuidUser: uuidUser! });
      const data = res.body;
      return { companies: data };
    } catch (err) {
      return { companies: [] };
    } finally {
      setLoading(false);
    }
  };

  const hookForm = useForm<{ companies: IIncomesDataDTO[] }>({
    defaultValues: loadAsync,
  });
  const { fields, append, remove } = useFieldArray({ name: 'companies', control: hookForm.control });

  const handleSendIncome = async () => {
    setLoading(true);
    try {
      const data = hookForm.getValues();

      const payload = data.companies.map((company) => {
        if (company.company.phone) {
          company.company.phone = utils.format.digitNumber(company.company.phone) as number;
        }
        return company;
      });

      await postIncomes({ uuidEntry: uuidEntry!, uuidUser: uuidUser! }, payload);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    handleSendIncome().then(() => {
      navigate(PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, {
        params: {
          uuidUser: uuidUser!,
          uuidEntry: uuidEntry!,
          formName: 'profile',
          groupName: 'expenses',
        },
      });
    });
  };
  const saveAndContinueBtn = () => {
    handleSendIncome().then(() => {
      navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid: uuidEntry! } });
    });
  };
  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageFormPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageFormPage.description') }]}
      maxWidth='lg'
      header={{
        lg: (
          <TopMenu
            breadcrumbs={[
              {
                title: t(`interface:page.ClientMortgageFormPage.progressBreadcrumbs`),
                href: utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { uuid: uuidEntry }),
              },
              {
                title: t(`interface:page.ClientMortgageFormPage.forms.incomesList.title`),
                activeLink: true,
              },
            ]}
          />
        ),
        xs: (
          <BaseButtonBack
            title={t(
              loading
                ? 'interface:page.ClientMortgageFormPage.loadingLabel'
                : `interface:page.ClientMortgageFormPage.forms.incomesList.title`,
            )}
            onBackEvent={() => {
              navigate(-1 as unknown as string);
            }}
          />
        ),
      }}
      sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingBottom: '24px' }}
    >
      <CompanySearch
        onAddCompany={(company) => {
          append({
            company: company,
            isMain: false,
            type: EProfileWorkspaceType.employee,
            income: {
              type: 'job',
              amount: 0,
              period: 'month',
            },
          });
        }}
      />
      {loading ? (
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Skeleton height="400px" width="50%" />
          <Skeleton height="400px" width="50%" />
        </Box>
      ) : (
        <IncomesWorkForm
          fields={fields}
          watch={hookForm.watch}
          control={hookForm.control}
          onRemove={(companyInn) => {
            remove(companyInn);
          }}
        />
      )}

      <LoadingButton loading={loading} variant='contained' fullWidth onClick={handleSave}>
        {t('interface:page.IncomesForm.save')}
      </LoadingButton>
      <Button onClick={saveAndContinueBtn} fullWidth variant='contained' color='secondary' size='large' data-testid='saveAndContinueBtn'>
        {t('interface:page.ClientMortgageFormPage.saveAndContinueBtn')}
      </Button>
    </ContainerLayout>
  );
}
