import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  mode: 'dark',
  primary: {
    light: '#FAEA64',
    main: '#F8E11A',
    dark: '#DFC801',
  },
  secondary: {
    light: '#B9B9B2',
    main: '#3b3b3b',
    dark: '#3b3b3b',
    contrastText: '#fff',
  },
  error: {
    light: '#FFEAEA',
    main: '#E81E1E',
  },
  warning: {
    main: '#F4A611',
  },
  info: {
    light: '#EAF3FF ',
    main: '#1A6CE7',
  },
  success: {
    light: '#EAFAE8',
    main: '#31C918',
  },
  common: {
    tariffBages: {
      premium: '#1AE7A9',
      premiumText: '#000000',
      basic: '#F4A611',
      basicText: '#FFFFFF',
      ultra: '#BE1AE7',
      ultraText: '#FFFFFF',
    },
    link: '#1A6CE7',
    white: '#FFFFFF',
    softYellow: '#FEFCE8',
    guestRegisterBtn: '#FFF7B1',
    guestSigninBtn: '#FFFFFF',
    btnHover: '#EFD916',
    greyLight: '#B9B9B2',
    black: '#0C0B02',
  },
  text: {
    primary: '#fff',
    secondary: '#787770',
    disabled: '#B9B9B2',
  },
  background: {
    default: '#121212',
    paper: '#3b3b3b',
  },
};

export default palette;
