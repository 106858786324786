import { Box, Button, ButtonProps, Stack, SvgIconProps, SxProps, Theme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

interface IProps {
  sx?: SxProps<Theme>;
  successIcon: React.FC<SvgIconProps>;
  successTitle: string;
  successSubtitle: string;
  successBtnText: string;
  redirectPath?: string;
  onBtnClick?: () => void;
  buttonProps?: ButtonProps;
  children?: React.ReactChild;
}

const NoticeScreen = ({
  successIcon,
  successTitle,
  successSubtitle,
  successBtnText,
  redirectPath = '/',
  onBtnClick,
  sx,
  buttonProps,
  children,
}: IProps) => {
  const navigate = useNavigate();

  const handleOnBtnClick = () => {
    if (onBtnClick) {
      onBtnClick();
    } else {
      navigate(redirectPath);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        display: 'flex',
        height: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '0 -20px',
        padding: '20px',

        ...(sx || {}),
      }}
    >
      <Stack sx={{ gap: '16px', height: 1, justifyContent: 'center' }}>
        <SvgIcon sx={{ width: 1, fontSize: 256, marginBottom: '24px' }} component={successIcon} />
        <Typography textAlign='center' sx={{ display: 'block' }} variant='h1b'>
          {successTitle}
        </Typography>
        <Typography textAlign='center' sx={{ display: 'block' }} variant='h4'>
          {successSubtitle}
        </Typography>
      </Stack>
      <Button fullWidth variant='contained' size='large' color='secondary' onClick={handleOnBtnClick} {...buttonProps}>
        {successBtnText}
      </Button>
      {children}
    </Box>
  );
};

export default NoticeScreen;
