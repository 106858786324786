import { ApiError } from 'api/errors';
import { getMortgageInsurance } from 'api/services/insurance/insurance';
import { StepMap, stepMap } from 'pages/Client/Mortgage/Entry/Insurance/constants/stepMap';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { InsuranceGroupNames } from '../../../../../../routes/paths';

export const useGetStepMap = (): StepMap => {
  const [fetchStepMap, setFetchStepMap] = useState<StepMap>(stepMap);
  const [title, setTitle] = useState<boolean>(true);
  const [health, setHealth] = useState<boolean>(true);
  const [error, setError] = useState<ApiError>();

  const { uuidEntry } = useParams();
  const location = useLocation();

  const updated = location.pathname.includes(InsuranceGroupNames.PROGRAM);

  const fetchData = async () => {
    try {
      const data = await getMortgageInsurance(uuidEntry!);
      const insurance = data.body;
      setTitle(insurance.perils.title);
      setHealth(insurance.perils.health.length !== 0);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    if (!updated) {
      const updatedStepMap = { ...stepMap };
      if (!title && !health) {
        delete updatedStepMap.titleInsurance;
        delete updatedStepMap.personalInsurance;
        updatedStepMap.propertyInsurance.nextStep = InsuranceGroupNames.PROPOSAL_WAITING;
      } else if (!title && health) {
        delete updatedStepMap.titleInsurance;
        if (updatedStepMap.personalInsurance) {
          updatedStepMap.personalInsurance.nextStep = InsuranceGroupNames.PROPOSAL_WAITING;
          updatedStepMap.propertyInsurance.nextStep = InsuranceGroupNames.PERSONAL;
        }
      } else if (title && !health) {
        delete updatedStepMap.personalInsurance;
        updatedStepMap.propertyInsurance.nextStep = InsuranceGroupNames.TITLE;
      }
      setFetchStepMap(updatedStepMap);
    }
  }, [title, health, updated]);

  useEffect(() => {
    fetchData();
  }, [updated]);

  if (error) {
    return stepMap;
  }

  return fetchStepMap;
};
