import { Button } from '@mui/material';
import CardGroupItem from 'components/base/FormGenerator/FrormCardGroup/components/CardGroupItem';
import CardGroupSelector from 'components/base/FormGenerator/FrormCardGroup/components/CardGroupSelector';
import { useFormLayoutContext } from 'components/base/FormGenerator/context/FormGeneratorProvider';
import { FormSchemeData } from 'components/base/FormGenerator/form.types';
import useFormGroup from 'components/base/FormGenerator/hooks/useFormGroup';
import { ft } from 'components/base/FormGenerator/utils';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
interface RenderableListProps extends ReturnType<typeof useFormGroup> {
  path: string[];
  node: Required<FormSchemeData>;
}
interface RenderableCardGroup extends RenderableListProps {
  onSelect: (type: string, name: string, options?: Partial<{ append: boolean }>) => void;
  onSelectRadio?: (type: string, name: string, options?: Partial<{ append: boolean }>) => void;
  showRadio: () => void;
  disableRadio: () => void;
}
/**
 *  TODO:
 * варианты использования
 * ______________________
 * 1. Когда значений нет:
 * 1.1 показывать элементы и выбор 
 * 

 */
export default function RenderableCardGroup({
  node,
  path,
  onSelect,
  fieldValues,
  isShowRadio,
  onSelectRadio,
  showRadio,
  disableRadio,
  remove,
}: RenderableCardGroup) {
  const formLayoutContext = useFormLayoutContext();

  const { t } = useTranslation();

  const filteredNames = useMemo(() => {
    const items = [];
    for (const field of fieldValues) {
      if (field.value) {
        items.push(field.name);
      }
    }
    return items;
  }, [fieldValues]);

  const p = ft([...path, node.name]);

  /**
   * вычисляет значение
   * 1. если радио поумолчанию не включено
   * то проверить количество элементов, если количество элементов <1 то показать radio выбор
   *
   */

  const handleBackEvent = () => {
    if (node.settings.useRadio) {
      disableRadio();
    }
    formLayoutContext.cleanState();
    onSelect(node.type, node.name);
  };
  const handleShowRadio = () => {
    if (node.settings.useRadio) {
      showRadio();
    }
    formLayoutContext.cleanState();
    formLayoutContext.setBackEvent(handleBackEvent);
    formLayoutContext.setFormControls({ hidden: true });
  };

  const handleGroupItemSelect = useCallback(
    (insideGroup: FormSchemeData) => () => {
      formLayoutContext.cleanState();
      formLayoutContext.setBackEvent(handleBackEvent);
      formLayoutContext.setFormControls({ hidden: true });

      const needAppend = !insideGroup?.settings?.single || insideGroup.settings.unique || filteredNames.length < 1;

      onSelect(insideGroup.type, insideGroup.name as string, { append: needAppend });
    },
    [],
  );

  const handleFiltered = useCallback(
    (_nodeItem: FormSchemeData) => {
      if (!node?.settings?.unique) {
        return true;
      }
      if (!filteredNames.length) {
        return true;
      }
      return !filteredNames.includes(_nodeItem.name as string);
    },
    [filteredNames],
  );

  const handleDeleteItem = useCallback(
    (name: string) => () => {
      if (!node.settings.useRadio) {
        return;
      }
      for (const item of fieldValues) {
        if (item.name === name) remove(item.uuid);
      }
    },
    [fieldValues],
  );
  if (node.settings.useRadio && isShowRadio) {
    return (
      <CardGroupSelector
        node={node}
        onFilter={handleFiltered}
        path={[...path, node.name]}
        onSelect={(name?: string) => {
          formLayoutContext.setBackEvent(handleBackEvent);
          onSelectRadio?.('repeatableGroup', name as string, { append: true });
        }}
        onCancel={() => {
          formLayoutContext.cleanState();
          disableRadio();
        }}
        hasCancel
      />
    );
  }

  return (
    <Fragment>
      {node?.data
        ?.filter((x) => {
          if (!node.settings.useRadio) {
            return true;
          }
          return filteredNames.includes(x.name as string);
        })
        .map((insideGroup) => (
          <CardGroupItem
            onDelete={node.settings.useRadio ? handleDeleteItem(insideGroup.name as string) : undefined}
            values={fieldValues.filter((x) => x.name === insideGroup.name)}
            key={`card-${insideGroup.name}`}
            path={[...path, node.name]}
            item={insideGroup}
            settings={node.settings}
            onSelect={handleGroupItemSelect(insideGroup)}
          />
        ))}
      {node.settings.useRadio && (
        <Button variant='contained' data-testid='add_button' size='large' color='secondary' onClick={handleShowRadio}>
          {t(`${p}.settings.btnAddTitle`)}
        </Button>
      )}
    </Fragment>
  );
}
