import { ObjectType, RouteParams } from 'utils/iots';

export function urlReplace<Path extends string>(template: Path, replaceTo: RouteParams<Path>): string {
  const templated = template.split('/');

  return templated.map((p) => (replaceTo as Record<string, ObjectType>)[p.replace(':', '')] || p).join('/');
}
function isDesktop() {
  const regexp = /android|iphone|kindle|ipad/i;

  return !regexp.test(navigator.userAgent);
}

export default { urlReplace, isDesktop };
