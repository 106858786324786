import React, { Fragment, useMemo } from 'react';

import { RenderNodeProps } from '../types';

import ComputableElement from './ComputedElement';

interface ComputedContainerProps extends RenderNodeProps {
  children: React.ReactChild;
  fieldValues?: any;
  hhiden?: boolean;
}
export function computedKeys(obj: any) {
  return Object.entries(obj)
    .map(([k, v]: [any, any]) => `${k}_${v.method}${v.args}`)
    .join('_');
}

export default function ComputedContainer(props: ComputedContainerProps) {
  const { node, children, ...otherProps } = props;

  const [beforeItems, afterItems] = useMemo(() => {
    if (!node.settings?.children) {
      return [null, null];
    }
    const after = [];
    const before = [];
    for (const item of node.settings.children) {
      if (item.position === 'before') {
        before.push(item);
      } else {
        after.push(item);
      }
    }
    return [before, after];
  }, []);
  if (props.hhiden) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Fragment>
      {beforeItems?.map((child) => (
        <ComputableElement key={computedKeys(child.props)} renderOption={child} {...otherProps} />
      ))}
      {children}
      {afterItems?.map((child) => (
        <ComputableElement key={computedKeys(child.props)} renderOption={child} node={node} {...otherProps} />
      ))}
    </Fragment>
  );
}
