import { Paper, Radio, Typography, useTheme } from '@mui/material';

interface Props<T> {
  onSelect: (item: T) => void;
  selectedItem: T;
  item: T;
  title: string;
  description?: string;
}

export default function RadioCard<T>({ onSelect, item, selectedItem, title, description }: Props<T>) {
  const theme = useTheme();

  return (
    <Paper
      onClick={() => {
        onSelect(item);
      }}
      elevation={0}
      sx={{
        borderWidth: '1.5px',
        borderStyle: 'solid',
        borderColor: selectedItem === item ? theme.palette.primary.dark : theme.palette.common.greyLight,
        borderRadius: '8px',
        padding: '16px 16px 16px 6px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Radio checked={item === selectedItem} />
      <div>
        <Typography display='block' variant='h3m'>
          {title}
        </Typography>
        {description && (
          <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
            {description}
          </Typography>
        )}
      </div>
    </Paper>
  );
}
