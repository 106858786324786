import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Avatar, Box, Container, SvgIcon, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
export default function () {
  const theme = useTheme();
  const [step, setStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => step + 1);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const converted = {
    overflow: 'hidden',
    borderRight: '.15em solid orange',
    whiteSpace: 'nowrap',
    margin: '0 auto',

    animation: 'typing 1.5s steps(40, end),\n    blink-caret .75s step-end infinite',

    '@keyframes typing': { from: { width: '0' }, to: { width: '100%' } },
    '@keyframes blink-caret': {
      'from, to': { borderColor: 'transparent' },
      '50%': { borderColor: 'orange' },
    },
  };

  const typingDots = {
    justifyContent: 'space-around',
    display: 'flex',

    '.jump1,\n      .jump2,\n      .jump3,\n      .jump4,\n      .jump5': {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      backgroundColor: 'gray',
    },
    '.jump1': {
      animation: 'typing 1.5s linear infinite',
      animationDelay: '01.1s',
    },
    '.jump2': {
      animation: 'typing 1.5s linear infinite',
      animationDelay: '01.2s',
    },
    '.jump3': {
      animation: 'typing 1.5s linear infinite',
      animationDelay: '01.3s',
    },
    '.jump4': {
      animation: 'typing 1.5s linear infinite',
      animationDelay: '01.4s',
    },
    '.jump5': {
      animation: 'typing 1.5s linear infinite',
      animationDelay: '1.5s',
    },
    '@keyframes typing': {
      '0%': { transform: 'translateY(0px)' },
      '25%': { transform: 'translateY(0px)' },
      '35%': { transform: 'translateY(15px)' },
      '45%': { transform: 'translateY(0px)' },
      '60%': { transform: 'translateY(-15px)' },
      '75%': { backgroundColor: 'white', transform: 'translateY(0px)' },
      '100%': { transform: 'translateY(0px)' },
    },
  };

  return (
    <Box sx={{ marginTop: '40px', height: '100%' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 1 }}>
        <Box
          sx={{
            marginBottom: '16px',
            padding: '24px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '16px',
            flexDirection: 'row',
            alignItems: 'center',
            background: theme.palette.secondary.main,
            gap: '20px',
          }}
        >
          <Avatar sx={{ width: '120px', height: '120px' }} src={'/img/personalManager/pm-manager.png'} />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: 1 }}>
            <Typography variant='h3b'>Анна Кузнецова</Typography>
            <Typography sx={{ color: theme.palette.text.secondary }}>Ваш личный менеджер</Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'flex-start', overflow: 'hidden', height: '300px' }}
        >
          {/* message start */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Avatar sx={{ width: '40px', height: '40px', alignSelf: 'flex-end' }} src={'/img/personalManager/pm-manager.png'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px', width: 1 }}>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '16px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginRight: 'auto',
                  background: theme.palette.secondary.main,
                }}
              >
                {step < 8 ? (
                  <Box sx={typingDots}>
                    <div className='jump1' />
                    <div className='jump2' />
                    <div className='jump3' />
                    <div className='jump4' />
                    <div className='jump5' />
                  </Box>
                ) : (
                  <Fragment>
                    <Typography sx={{ fontSize: '18px' }}>
                      Денежные средства поступили на аккредитив. Запускаем процесс регистрации сделки.
                    </Typography>

                    <Typography sx={{ marginLeft: 'auto', fontSize: '10px', color: theme.palette.text.secondary }}>21:48</Typography>
                  </Fragment>
                )}
              </Box>
            </Box>
          </Box>
          {/* message end */}

          {step >= 13 && (
            <Box
              sx={{
                width: '200px',
                background: '#FFF9C0',
                marginLeft: 'auto',
                padding: '8px',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '16px',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              Отлично, спасибо!
              <Typography sx={{ marginLeft: 'auto', fontSize: '10px', color: theme.palette.text.secondary }}>21:48</Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', width: '100%', marginTop: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              background: theme.palette.secondary.main,
              borderRadius: '50%',
              height: '48px',
              width: '48px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SvgIcon component={AttachFileIcon} sx={{ color: '#787770' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              background: theme.palette.secondary.main,
              borderRadius: '102px',
              padding: '6px 6px 6px 16px',
              width: '100%',
              alignItems: 'center',

              fontSize: '16px',
              color: theme.palette.text.secondary,
            }}
          >
            {step > 10 && step < 13 ? <Typography sx={converted}> Отлично,спасибо!</Typography> : null}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
