import { SvgIcon, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { userGetNotificationCount } from 'api/services/user';
import NotificationIcon from 'components/Icons/Notification';
import useFetch from 'hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { PATH_NOTIFICATIONS } from 'routes/paths';

// TODO: Refactor this piece of shit
const CommonButtonNotification = ({ sxIcon, sx }: { sxIcon?: SxProps; sx?: SxProps }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const goToNotifications = () => navigate(PATH_NOTIFICATIONS);
  const { data } = useFetch(() => userGetNotificationCount());
  const count = data;
  return (
    <Box>
      {!count ? undefined : (
        <Box
          sx={{
            // shape
            minWidth: '13px',
            maxWidth: '18px',
            padding: '0 3px',
            height: '13px',
            borderRadius: '8px',
            backgroundColor: `${theme.palette.error.main}`,
            border: '1px solid #FFFFFF',
            // font
            fontSize: '9px',
            color: '#FFFFFF',
            textAlign: 'center',
            lineHeight: '12px',
            // position
            position: 'absolute',
            marginLeft: '-3px',
            ...(sx || {}),
          }}
        >
          {count}
        </Box>
      )}
      <SvgIcon
        sx={{ width: '24px', height: '26px', ...(sxIcon || {}) }}
        stroke={`${theme.palette.text.primary}`}
        onClick={goToNotifications}
        component={NotificationIcon}
      />
    </Box>
  );
};

export default CommonButtonNotification;
