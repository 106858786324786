import { Box, Breadcrumbs, Link, Typography, useTheme } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import StatusDot from 'components/base/StatusDot';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';

import useTypedParams from '../../../hooks/useTypedParams';
import { PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, PATH_BUSINESS_CREDIT_PROPOSE, PATH_HOME } from '../../../routes/paths';
import utils from '../../../utils';
import { Accessible } from 'components/providers/RoleAccessProvider';
import { EUserRole } from 'api/services/user.dto';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import ProposeSelector from 'modules/Propose/components/Selector';
import { EBCBank } from 'api/services/businessCredit/businessCredit.propose.dto';
import ProposeCommonProvider from 'modules/Propose/context';
import { BusinessCreditProposeService } from 'modules/Propose/services/businessCreditPropose';
import ProposeContainer from 'modules/Propose/components/Container';
import { useNavigate, useParams } from 'react-router-dom';

export default function BusinessCreditProposePage() {
  const theme = useTheme();
  const params = useParams();
  const { t } = useTranslation();
  const { uuidEntry } = useTypedParams<typeof PATH_BUSINESS_CREDIT_PROPOSE>();
  const loading = false;
  const businessCreditContext = useBusinessCredit();

  const navigate = useNavigate();
  const updateCallback = async () => {
    return businessCreditContext.loadEntryRequest({ uuidEntry: params.uuidEntry! });
  };
  return (
    <ProposeCommonProvider
      updateCallback={updateCallback}
      service={BusinessCreditProposeService}
      primaryPropose={businessCreditContext.entry?.entry.primaryPropose!}
    >
      <ContainerLayout
        title={t('helmet:ClientMortgageProposePage.title')}
        meta={[{ name: 'description', content: t('helmet:ClientMortgageProposePage.description') }]}
        header={{
          xs: (
            <BaseButtonBack
              title={t(`interface:page.ClientMortgageProposePage.${loading ? 'loadingLabel' : 'title'}`)}
              subtitle={t(`interface:page.ClientMortgageProposePage.${loading ? 'loadingLabel' : 'subtitle'}`)}
              onBackEvent={() => {
                navigate(-1);
              }}
            />
          ),
          lg: <TopMenu />,
        }}
        maxWidth='xl'
        sx={{ height: 1, gap: '16px', paddingBottom: '20px' }}
      >
        <Box sx={{ display: { xs: 'none', lg: 'flex' }, flexDirection: 'row', gap: '20px' }}>
          <Breadcrumbs separator={<StatusDot color='#D2D2CF' sx={{ width: '4px', height: '4px' }} />} aria-label='breadcrumb'>
            <Link sx={{ color: theme.palette.common.link }} underline='none' href={PATH_HOME}>
              {t('interface:page.ClientMortgageProposePage.breadcrumbs.main')}
            </Link>
            <Link
              sx={{ color: theme.palette.common.link }}
              underline='none'
              href={utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, { uuidEntry })}
            >
              {t('interface:page.ClientMortgageProposePage.breadcrumbs.progress')}
            </Link>
            <Link sx={{ color: theme.palette.text.secondary, userSelect: 'none' }} underline='none'>
              {t('interface:page.ClientMortgageProposePage.breadcrumbs.selectBank')}
            </Link>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: { xs: 'flex-start', lg: 'space-between' },
            alignItems: 'center',
            width: '100%',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '24px',
              width: { xs: 1, lg: 0.7 },
              marginTop: '16px',
            }}
          >
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Typography component='span' variant='h1b'>
                {t('interface:page.ClientMortgageProposePage.title')}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ paddingTop: '20px' }}>
          <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
            <ProposeSelector excludedList={businessCreditContext.bankList} sourceList={Object.values(EBCBank)} />
          </Accessible>
        </Box>
        <ProposeContainer />
      </ContainerLayout>
    </ProposeCommonProvider>
  );
}
