/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material/utils';

const InsuranceIngosIcon = createSvgIcon(
  <svg viewBox={'0 0 54 54'}>
    <path d='M46 47H35.4194V16.1601L12.1935 47H2V0H12.7097V30.8399L36.0645 0H46V47Z' fill='#0047BB' />
  </svg>,
  'InsuranceIngosIcon',
);

export default InsuranceIngosIcon;
