import SuccessTimeIcon from 'components/Icons/SuccessTimeIcon';
import NoticeScreen from 'components/base/NoticeScreen';
import { useTranslation } from 'react-i18next';
export default function WaitingNotice() {
  const { t } = useTranslation();
  return (
    <NoticeScreen
      successIcon={SuccessTimeIcon}
      successTitle={t(`interface:base.WaitingNotice.title`)}
      successSubtitle={t(`interface:base.WaitingNotice.subtitle`)}
      successBtnText={''}
      sx={{ background: 'transparent' }}
      buttonProps={{ sx: { display: 'none' } }}
    />
  );
}
