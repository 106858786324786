/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const IngosIcon = createSvgIcon(
  <path d='M42 45H31.9003V15.4724L9.73021 45H0V0H10.2229V29.5276L32.5161 0H42V45Z' fill='#0047BB' />,
  'IngosIcon',
);

export default IngosIcon;
