import { Box, Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { getEntryProfile } from 'api/services/mortgage';
import { InsuranceProfile } from 'api/services/mortgage.dto';
import ErrorMessage from 'components/base/ErrorMessage';
import { CustomRadioChoice } from 'pages/Client/Mortgage/Entry/Insurance/components/CustomRadioChoice';
import { RadioChoice } from 'pages/Client/Mortgage/Entry/Insurance/components/RadioChoice';
import { AccordionPolicyHolder } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/components/AccordionPolicyHolder';
import { useEffect, useState } from 'react';

import { InputField } from '../../components/InputField';
import { NumberField } from '../../components/NumberField';
import { SelectField } from '../../components/SelectField';
import useTypedParams from 'hooks/useTypedParams';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE } from 'routes/paths';

export default function MortgageEntryInsuranceLifeForm() {
  const [data, setData] = useState<InsuranceProfile>();
  const [error, setError] = useState<ApiError>();

  const { uuidEntry, uuidUser } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE>();

  const prefix = `interface:page.MortgageEntryInsurancePage.InsuranceLifeForm`;

  const fetchData = async () => {
    try {
      const data = await getEntryProfile(`${uuidEntry}`, `${uuidUser}`);
      setData(data.body);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (data === undefined) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  const { health } = data;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <AccordionPolicyHolder title={'baseTitle'} />
      <InputField defaultValue={health?.profession} prefix={prefix} label={'health.profession'} />
      <NumberField
        defaultValue={health?.creditAmount}
        prefix={prefix}
        thousandSeparator={' '}
        suffix={' ₽'}
        label={'health.creditAmount'}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
        <NumberField
          defaultValue={health?.physicalIndicators.height}
          prefix={prefix}
          label={'health.physicalIndicators.height'}
          helperText={true}
        />
        <NumberField
          defaultValue={health?.physicalIndicators.weight}
          prefix={prefix}
          label={'health.physicalIndicators.weight'}
          helperText={true}
        />
      </Box>
      <NumberField
        defaultValue={health?.physicalIndicators.upArteriotony}
        prefix={prefix}
        label={'health.physicalIndicators.upArteriotony'}
        helperText={true}
      />
      <NumberField
        defaultValue={health?.physicalIndicators.downArteriotony}
        prefix={prefix}
        label={'health.physicalIndicators.downArteriotony'}
        helperText={true}
      />
      <NumberField
        defaultValue={health?.badHabits.smokedCigarettes}
        prefix={prefix}
        label={'health.badHabits.smokedCigarettes'}
        helperText={true}
      />
      <NumberField
        defaultValue={health?.badHabits.alcoholLess10Percents}
        prefix={prefix}
        label={'health.badHabits.alcoholLess10Percents'}
        helperText={true}
      />
      <NumberField
        defaultValue={health?.badHabits.alcoholFrom10to40Percents}
        prefix={prefix}
        label={'health.badHabits.alcoholFrom10to40Percents'}
        helperText={true}
      />
      <NumberField
        defaultValue={health?.badHabits.alcoholOver40Percents}
        prefix={prefix}
        label={'health.badHabits.alcoholOver40Percents'}
        helperText={true}
      />
      <RadioChoice defaultValue={health?.risks?.healthProblems} prefix={prefix} label={'health.risks.healthProblems'} />
      <RadioChoice defaultValue={health?.risks?.doctorVisitsOrPlans} prefix={prefix} label={'health.risks.doctorVisitsOrPlans'} />
      <CustomRadioChoice
        defaultValue={health?.risks?.disabilityGroup}
        answers={['firstDegreeDisability', 'secondDegreeDisability', 'thirdDegreeDisability', 'missing']}
        prefix={prefix}
        label={'health.risks.disabilityGroup'}
      />
      <RadioChoice
        defaultValue={health?.risks?.cancerTumorsCardiovascularDisease}
        prefix={prefix}
        label={'health.risks.cancerTumorsCardiovascularDisease'}
      />
      <RadioChoice defaultValue={health?.risks?.dispensaryRegister} prefix={prefix} label={'health.risks.dispensaryRegister'} />
      <RadioChoice defaultValue={health?.risks?.organDefects} prefix={prefix} label={'health.risks.organDefects'} />
      <CustomRadioChoice
        defaultValue={health?.risks?.hivAndAidsTestResults}
        answers={['positive', 'negative', 'missing']}
        prefix={prefix}
        label={'health.risks.hivAndAidsTestResults'}
      />
      <RadioChoice defaultValue={health?.risks?.takingAntidepressants} prefix={prefix} label={'health.risks.takingAntidepressants'} />
      <RadioChoice defaultValue={health?.risks?.psychosocialDisabilities} prefix={prefix} label={'health.risks.psychosocialDisabilities'} />
      <RadioChoice defaultValue={health?.risks?.pestilentActivity} prefix={prefix} label={'health.risks.pestilentActivity'} />
      <RadioChoice defaultValue={health?.risks?.dangerousSports} prefix={prefix} label={'health.risks.dangerousSports'} />
      <SelectField
        defaultValue={health?.risks?.covidLastYear}
        answers={['haveDiseaseComplications', 'haveDiseaseMildIllness', 'haveNotDisease']}
        prefix={prefix}
        label={'health.risks.covidLastYear'}
      />
      <SelectField
        defaultValue={health?.risks?.covidVaccineLastYear}
        answers={['yes', 'antibodies', 'medicalExemption', 'otherReason']}
        prefix={prefix}
        label={'health.risks.covidVaccineLastYear'}
      />
    </Box>
  );
}
