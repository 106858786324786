import { Box, Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { getMortgageInsurancePerils } from 'api/services/insurance/insurance';
import { TitlePerils } from 'api/services/insurance/insurance.dto';
import ErrorMessage from 'components/base/ErrorMessage';
import { CustomRadioChoice } from 'pages/Client/Mortgage/Entry/Insurance/components/CustomRadioChoice';
import { CustomRadioChoiceOther } from 'pages/Client/Mortgage/Entry/Insurance/components/CustomRadioChoiceOther';
import { InputField } from 'pages/Client/Mortgage/Entry/Insurance/components/InputField';
import { RadioChoice } from 'pages/Client/Mortgage/Entry/Insurance/components/RadioChoice';
import { SelectField } from 'pages/Client/Mortgage/Entry/Insurance/components/SelectField';
import { SelectFieldOther } from 'pages/Client/Mortgage/Entry/Insurance/components/SelectFieldOther';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DateField } from '../../components/DateField';

export default function MortgageEntryInsuranceTitleForm() {
  const [title, setTitle] = useState<TitlePerils>();
  const [error, setError] = useState<ApiError>();

  const { uuidEntry } = useParams();

  const prefix = `interface:page.MortgageEntryInsurancePage.InsuranceTitleForm`;

  const fetchData = async () => {
    try {
      const data = await getMortgageInsurancePerils(uuidEntry!);
      const title = data.body.title;
      setTitle(title);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (title === undefined) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  return (
    <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch' }}>
      <CustomRadioChoice
        defaultValue={title?.infoPrivatizationSubjectMortgage?.value}
        answers={['requisites', 'noInfo']}
        prefix={prefix}
        label={'title.infoPrivatizationSubjectMortgage.value'}
      />
      <CustomRadioChoice
        defaultValue={title?.subjectMortgage}
        answers={['sellersProperty', 'policyholdersProperty']}
        prefix={prefix}
        label={'title.subjectMortgage'}
      />
      <SelectFieldOther
        defaultValue={title?.dealMaking?.value}
        answers={['directlyByParties', 'designatedPerson', 'realtor']}
        prefix={prefix}
        label={'title.dealMaking.value'}
        otherLabel={'title.dealMaking.details'}
        defaultOtherValue={title?.dealType?.details}
      />
      <SelectFieldOther
        defaultValue={title?.dealType?.value}
        defaultOtherValue={title?.dealType?.details}
        answers={['donation', 'buySell', 'privatization', 'hereditarySuccession', 'testateSuccession']}
        prefix={prefix}
        label={'title.dealType.value'}
        otherLabel={'title.dealType.details'}
      />
      <DateField prefix={prefix} label={'title.dealType.date'} defaultValue={title?.dealType?.date} />
      <InputField defaultValue={title?.previousDivestiture} label={'title.previousDivestiture'} prefix={prefix} />
      <CustomRadioChoiceOther
        defaultValue={title?.previouslySubmittedApplication?.value}
        defaultOtherValue={title?.previouslySubmittedApplication?.details}
        answers={['no', 'rejected']}
        prefix={prefix}
        label={'title.previouslySubmittedApplication.value'}
        otherLabel={'title.previouslySubmittedApplication.details'}
      />
      <SelectField
        defaultValue={title?.underageOwner}
        answers={['no', 'yesWithPermission', 'yesWithoutPermission']}
        prefix={prefix}
        label={'title.underageOwner'}
      />
      <SelectField
        defaultValue={title?.over60YearsOwner}
        answers={['no', 'yesWithCapacityDocument', 'yesWithoutCapacityDocument']}
        prefix={prefix}
        label={'title.over60YearsOwner'}
      />
      <CustomRadioChoice
        defaultValue={title?.incompetentOwner?.value}
        answers={['no', 'yes', 'noInfo']}
        prefix={prefix}
        label={'title.incompetentOwner.value'}
      />
      <CustomRadioChoice
        defaultValue={title?.citizenRightToUse?.value}
        answers={['no', 'yes', 'noInfo']}
        prefix={prefix}
        label={'title.citizenRightToUse.value'}
      />
      <CustomRadioChoice
        defaultValue={title?.judicialDispute?.value}
        answers={['no', 'yes', 'noInfo']}
        prefix={prefix}
        label={'title.judicialDispute.value'}
      />
      <CustomRadioChoice
        defaultValue={title?.complaintsLawThirdParty?.value}
        answers={['no', 'yes', 'noInfo']}
        prefix={prefix}
        label={'title.complaintsLawThirdParty.value'}
      />
      <RadioChoice defaultValue={title?.unregisteredAlteration?.value} prefix={prefix} label={'title.unregisteredAlteration.value'} />
      <CustomRadioChoice
        defaultValue={title?.spousalConsent}
        answers={['granted', 'notGranted', 'notRequired']}
        prefix={prefix}
        label={'title.spousalConsent'}
      />
      <SelectField
        defaultValue={title?.registeredCitizens}
        answers={['no', 'yesMinorsDependents', 'yesNotMinorsDependents']}
        prefix={prefix}
        label={'title.registeredCitizens'}
      />
      <SelectField
        defaultValue={title?.encumbrance?.value}
        answers={['no', 'mortgage', 'trustManagement', 'rental']}
        prefix={prefix}
        label={'title.encumbrance.value'}
      />
    </Box>
  );
}
