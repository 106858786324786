/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const StarsIcon = createSvgIcon(
  <svg viewBox="0 0 175 174" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="174" height="174" rx="87" fill="#FBEF81" />
    <rect x="12.5" y="12" width="150" height="150" rx="75" fill="white" />
    <path
      d="M57.75 48.75V65.75M49.25 57.25H66.25M62 108.25V125.25M53.5 116.75H70.5M91.75 48.75L101.464 77.8929L125.75 87L101.464 96.1071L91.75 125.25L82.0357 96.1071L57.75 87L82.0357 77.8929L91.75 48.75Z"
      stroke="#F8E11A"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>,
  'StarsIcon',
);

export default StarsIcon;
