/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const CloseIcon = createSvgIcon(
  <svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM16.6464 17.3536C16.8417 17.5488 17.1583 17.5488 17.3536 17.3536C17.5488 17.1583 17.5488 16.8417 17.3536 16.6464L16.6464 17.3536ZM17.3536 1.35355C17.5488 1.15829 17.5488 0.841709 17.3536 0.646447C17.1583 0.451184 16.8417 0.451184 16.6464 0.646447L17.3536 1.35355ZM0.646447 16.6464C0.451184 16.8417 0.451184 17.1583 0.646447 17.3536C0.841709 17.5488 1.15829 17.5488 1.35355 17.3536L0.646447 16.6464ZM0.646447 1.35355L8.64645 9.35355L9.35355 8.64645L1.35355 0.646447L0.646447 1.35355ZM8.64645 9.35355L16.6464 17.3536L17.3536 16.6464L9.35355 8.64645L8.64645 9.35355ZM16.6464 0.646447L8.64645 8.64645L9.35355 9.35355L17.3536 1.35355L16.6464 0.646447ZM8.64645 8.64645L0.646447 16.6464L1.35355 17.3536L9.35355 9.35355L8.64645 8.64645Z'
      fill='#787770'
    />
  </svg>,
  'CloseIcon',
);

export default CloseIcon;
