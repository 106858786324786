import { useAuth } from 'components/providers/AuthProvider';
import { createContext, useEffect } from 'react';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { firebaseConfig } from 'constants/firebase.config';
import { userSetClientNotificationToken } from 'api/services/user';

const token = import.meta.env.REACT_APP_FB_VAPID_KEY;

const firebase = initializeApp(firebaseConfig);

// both optional
const getFCMToken = async (vapidKey: string, sw?: ServiceWorkerRegistration) => {
  const messaging = getMessaging(firebase);
  const supported = await isSupported();
  if (supported) {
    const options = {
      vapidKey,
      serviceWorkerRegistration: sw,
    };
    return getToken(messaging, options);
  }
  return;
};
const mode = import.meta.env.REACT_APP_FB_MODE as string;

export const NotificationContext = createContext<{ fcmToken?: string | null }>({});

export const NotificationProvider = ({ children }: { children: React.ReactChild }) => {
  const { isAuth } = useAuth();

  const connectFirebase = async () => {
    try {
      const permission = await Notification?.requestPermission();
      if (permission === 'granted') {
        const sw = await navigator.serviceWorker.register(`/firebase-messaging-sw-${mode}.js`, { scope: '/push' });
        const fcmToken = await getFCMToken(token, sw);
        if (fcmToken) {
          console.log('[firebase:Token]:', fcmToken);
          await userSetClientNotificationToken(fcmToken);
        }
      }
    } catch (err) {
      console.log('error:', err);
    }
  };

  useEffect(() => {
    if (isAuth && 'Notification' in window) {
      connectFirebase();
    }
    // connectFirebase();
  }, [isAuth]);

  return <NotificationContext.Provider value={{}}>{children}</NotificationContext.Provider>;
};
