import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Skeleton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { EContractType, EMortgageContractFileType } from 'api/services/contract.dto';
import { entryGenerateContract } from 'api/services/mortgage';
import { useAuth } from 'components/providers/AuthProvider';
import { isString } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const { REACT_APP_STORAGE_URL: storageUrl } = import.meta.env;
export type TLocationParams = {
  uuid: string;
};
type Props = {
  handleNextBtnClick: () => void;
};
const PreviewPDF = ({ handleNextBtnClick }: Props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const theme = useTheme();
  const [htmlContent, setHtmlContent] = useState<null | string>(null);
  const [pdfLink, setPdfLink] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [pdfloading, setPdfLoading] = useState<boolean>(false);

  const { uuid: uuidEntry } = useParams<keyof TLocationParams>() as TLocationParams;

  const generateEntryPDF = async () => {
    setLoading(true);

    try {
      const res = await entryGenerateContract(
        uuidEntry,
        auth?.user?.uuid || '',
        EContractType.creditProfileContract,
        EMortgageContractFileType.html,
      );

      await handleDownload();
      const { ok, body } = res;
      if (ok) {
        if (isString(body)) setHtmlContent(body);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!htmlContent && auth?.user) generateEntryPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleDownload = async () => {
    try {
      if (!pdfLink) {
        setPdfLoading(true);
        const res = await entryGenerateContract(
          uuidEntry,
          auth?.user?.uuid || '',
          EContractType.creditProfileContract,
          EMortgageContractFileType.pdf,
        );

        if (res.body.link) setPdfLink(`${storageUrl}/${res.body.link}`);
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '90%', gap: '24px', paddingBottom: '24px', width: 1 }}>
      <Typography variant='h4b'>{t('interface:page.ClientMortgageEntrySignEntryPage.PreviewPDF.lblTitle')}</Typography>
      {loading && !auth?.user && <Skeleton sx={{ width: '350px', minHeight: '494px' }} />}

      {htmlContent && <iframe srcDoc={htmlContent} style={{ border: 'none' }} width='100%' height='100%' />}

      {/* Controls */}
      <Button onClick={handleNextBtnClick} fullWidth variant='contained' size='large'>
        {t('interface:page.ClientMortgageEntrySignEntryPage.PreviewPDF.btnSign')}
      </Button>

      <LoadingButton
        loading={pdfloading}
        disabled={pdfloading}
        onClick={handleDownload}
        href={pdfLink}
        download
        component='a'
        fullWidth
        variant='contained'
        size='large'
        color='secondary'
      >
        {t('interface:page.ClientMortgageEntrySignEntryPage.PreviewPDF.btnDonwload')}
      </LoadingButton>

      {/* Error */}
      {error && (
        <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
          {t(`errors:${error}`)}
        </Typography>
      )}
    </Box>
  );
};

export default PreviewPDF;
