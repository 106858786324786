import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { EEntryAgentClientStageStatusFilter, EMortgageEntryStage, IMortgageEntryCLientListResult } from 'api/services/agent.dto';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BaseInsuranceListSearchAndFilter from 'components/base/Insurance/List/SearchAndFilter';
import StatusDot from 'components/base/StatusDot';
import _ from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import utils from 'utils';
import { getFullName } from 'utils/format/format';
import { stageProccess } from '../ClientTable/utils';
import FilterListIcon from '@mui/icons-material/FilterList';
import { t } from 'i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { useAgentClients } from 'components/providers/ClientProvider';

function ClientFilterDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { statusFilters, activeFilter, selectFilter } = useAgentClients();
  const [filters, setFilters] = useState<EEntryAgentClientStageStatusFilter[]>(activeFilter);

  const handleFilter = (filter: EEntryAgentClientStageStatusFilter) => () => {
    setFilters((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((x) => x !== filter);
      }
      return [...prev, filter];
    });
  };

  const handleClose = () => {
    selectFilter(...filters);
    onClose();
  };
  return (
    <Dialog maxWidth='xl' open={open} onClose={handleClose}>
      <DialogTitle component='div' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h3'>{t(`interface:page.AgentMortgagePropertyClientPage.ClientList.filterTitle`)}</Typography>
        <IconButton onClick={handleClose}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '16px', gap: '16px', flexWrap: 'wrap' }}>
          {Object.entries(statusFilters).map(([key, value]) => (
            <Badge badgeContent={value} color='primary'>
              <Button
                fullWidth
                variant={filters.includes(key as EEntryAgentClientStageStatusFilter) ? 'contained' : 'outlined'}
                onClick={handleFilter(key as EEntryAgentClientStageStatusFilter)}
              >
                {t(`common:mortgageEntry.filters.${key.replace(':', '.')}`)}
              </Button>
            </Badge>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default function ClientList({
  clients,
  onClickClient,
  onScroll,
}: {
  onScroll(index: number): void;
  clients?: IMortgageEntryCLientListResult[];
  onClickClient: (client: IMortgageEntryCLientListResult) => void;
}) {
  const [modal, showModal] = useState(false);

  const { t } = useTranslation();
  const [queryFilter, setQueryFilter] = useState('');

  const handleChangeQuery = (e: any) => {
    setQueryFilter(e.target.value);
  };
  const debounceFn = useCallback(_.debounce(handleChangeQuery, 100), []);

  const filteredClients = useMemo(() => {
    if (!queryFilter) {
      return clients;
    }
    return clients?.filter((client) => getFullName(client)?.indexOf(queryFilter) !== -1);
  }, [clients, queryFilter]);

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <BaseInsuranceListSearchAndFilter sx={{ marginTop: 0, width: 1 }} NoFilter onChangeQuery={debounceFn} />
        <IconButton
          onClick={() => {
            showModal(true);
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: 1 }}>
        <Virtuoso
          endReached={onScroll}
          style={{ height: window.innerHeight / 1.4 }}
          data={filteredClients}
          itemContent={(_index, client) => {
            const progress = stageProccess?.[`${client?.entry?.stage}` as EMortgageEntryStage]?.(client.entry!) as string;
            return (
              <Fragment key={client.user.uuid}>
                <ListItem
                  data-testid={`clientlist_client_${client.user.uuid}`}
                  alignItems='center'
                  sx={{ justifyContent: 'space-between', cursor: 'pointer' }}
                  button
                  focusRipple={true}
                  onClick={() => {
                    onClickClient(client);
                  }}
                >
                  <ListItemText
                    primary={utils.format.nameWithInitials(client.user)}
                    secondary={
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          {progress && <StatusDot color={'#F8E11A'} sx={{ width: '8px', height: '8px' }} />}
                          <Typography variant={'h6'}>
                            {t(`common:mortgageEntry.filters.${client?.entry?.stage}.${progress}`, '')}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <Typography variant={'h6'}>Сумма кредита</Typography>
                          {/* @ts-ignore //TODO: fix after release */}
                          <Typography variant={'h6'}>{utils.format.price(client.entry?.params.amount!)}</Typography>
                        </Box>
                      </Box>
                    }
                  />
                  <IconButton
                    onClick={() => {
                      onClickClient(client);
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    size='small'
                  >
                    <ArrowForwardIosIcon fontSize='small' />
                  </IconButton>
                </ListItem>
                <Divider variant='fullWidth' component='div' />
              </Fragment>
            );
          }}
        />
      </Box>
      <ClientFilterDialog
        open={modal}
        onClose={() => {
          showModal(false);
        }}
      />
    </Fragment>
  );
}
