import { IProfileProgressMortgage } from './mortgage.dto';
import { EUserRole } from './user.dto';

export interface IBaseAuth {
  device?: IAuthDeviceInfo;
}

export interface IAuthByPassword extends IBaseAuth {
  email: string;
  password: string;
  secret?: string;
}

export enum EAuthProvider {
  sms = 'sms',
  password = 'password',
}

export type TLoginBodyDTO =
  | {
      provider: EAuthProvider.sms;
      payload: IAuthBySmsCode;
    }
  | {
      provider: EAuthProvider.password;
      payload: IAuthByPassword;
    };

export interface IIssueSmsCode {
  phone: number;
}

export interface IAuthBySmsCode extends IBaseAuth {
  phone: number;
  code: string;
  secret?: string;
}

export interface IAuthInterfaceData {
  useragent?: string;
  ip: string;
}

export interface IRegisterUser {
  lastname?: string;
  firstname?: string;
  patronymic?: string;
  email: string;
  password?: string;
  phone: number;
  code: string;
  roles?: EUserRole[];
  autoLogin: boolean;
  inviteLink?: string;
  device?: IAuthDeviceInfo;
  secret: string;
}

export interface IAuthDeviceInfo {
  clientFingerprint: string;
}

export interface ISessionToken {
  token: string;
  expire: number;
}

export interface ISessionTokenPair {
  access: ISessionToken;
  refresh: ISessionToken;
}
export interface IProfileProgressInsurance {
  personal: number;
  passport: number;
  object: number;
  contacts: number;
}

export enum EProfileProgressContext {
  insurance = 'insurance',
  mortgage = 'mortgage',
}
export type IProfileProgress =
  | {
      context: EProfileProgressContext.mortgage;
      data: IProfileProgressMortgage;
    }
  | {
      context: EProfileProgressContext.insurance;
      data: IProfileProgressInsurance;
    };
