import { Box, Button, Grid, IconButton, Link, Paper, Skeleton, SvgIcon, SxProps, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useProposeCommon } from '../../context';
import { MapIcons } from 'modules/Propose/icons';
import utils from 'utils';
import { EUserRole } from 'api/services/user.dto';
import Edit from '@mui/icons-material/Edit';
import { Accessible } from 'components/providers/RoleAccessProvider';
import { useMemo, useState } from 'react';
import { mapStatusColor } from 'modules/Propose/utils';
import ProposeEditor from '../Editor';
import ArrowUpIcon from 'components/Icons/ArrowUp';
import ArrowDownIcon from 'components/Icons/ArrowDown';

const typographySx: SxProps = { fontSize: { md: '22px', xs: '18px' }, fontWeight: { xs: 500, md: 400 } };

const statusMap = ['approved', 'preliminary'];

export default function ProposeCard(props: any) {
  const [expanded, setExpand] = useState(false);
  // const [proposeRequestList, setProposeRequestList] = useState<TRequestedData[]>([]);
  const theme = useTheme();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const proposeHook = useProposeCommon();
  const { t } = useTranslation();

  const colorSx = useMemo(() => mapStatusColor({ status: props.status }), [props]);

  if (proposeHook.editablePropose === props.uuid) {
    return <ProposeEditor propose={props} />;
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        borderRadius: '12px',
        height: '100%',
        width: 1,
        justfiyContent: 'space-between',
      }}
      variant='outlined'
    >
      <Box
        sx={{
          padding: '5px 10px 5px 10px',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          borderRadius: '8px',
          alignItems: 'center',

          marginBottom: '14px',
          ...colorSx,
        }}
      >
        <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
          <IconButton
            aria-label='edit-manager'
            data-testid={`${props.bank}.edit-button`}
            onClick={() => {
              proposeHook.editPropose(props.uuid);
            }}
          >
            <Edit />
          </IconButton>
        </Accessible>
        <Typography variant='h6' component='span'>
          {t(`interface:base.ProposeCard.proposeStatus.${props.status}`)}
        </Typography>
      </Box>
      <Grid container alignItems='center' display='flex' sx={{ borderX: '1px solid pink' }}>
        <Grid item lg={12} sx={{ borderX: '1px solid green' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
            <SvgIcon component={MapIcons[`${props.bank}`]} sx={{ width: 48, height: 48 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h4'>{t(`common:banks.${props.bank}`)}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={12} container sx={{ borderX: '1px solid black', marginTop: { lg: 0, xs: '12px' } }}>
          {/* Credit amount */}
          <Grid item xs={6} md={6} sx={{ borderX: '1px solid red' }}>
            <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
              {t('interface:base.ProposeCard.creditAmount')}
            </Typography>
            <Typography variant='h4' sx={typographySx}>
              {statusMap.includes(props.status) ? (
                utils.format.price(props?.decision?.amount / 100)
              ) : (
                <Skeleton variant='rounded' width='60%' height={30} />
              )}
            </Typography>
          </Grid>

          {/* Credit duration */}
          <Grid item xs={6} md={6} sx={{ borderX: '1px solid red' }}>
            <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
              {t('interface:base.ProposeCard.creditDuration')}
            </Typography>
            <Typography variant='h4' sx={typographySx}>
              {statusMap.includes(props.status) ? (
                t('common:date.month', { count: props?.decision?.period })
              ) : (
                <Skeleton variant='rounded' width='60%' height={30} />
              )}
            </Typography>
          </Grid>

          {/* Decisions */}
          {(props?.decision?.rates || [{}])?.map((rate: any) => (
            <>
              {/* Rate */}
              <Grid item xs={6} md={6} sx={{ borderX: '1px solid red' }}>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                  {t('interface:base.ProposeCard.rate')}
                </Typography>
                <Typography variant='h4' sx={typographySx}>
                  {statusMap.includes(props.status) ? (
                    rate.value ? (
                      utils.format.percent(rate?.value as number)
                    ) : (
                      '-'
                    )
                  ) : (
                    <Skeleton variant='rounded' width='50%' height={30} />
                  )}
                </Typography>
              </Grid>
              {/* Commission */}
              <Grid item xs={6} md={6} sx={{ borderX: '1px solid red' }}>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                  {t('interface:base.ProposeCard.commission')}
                </Typography>
                <Typography variant='h4' sx={typographySx}>
                  {statusMap.includes(props.status) ? (
                    rate.commission ? (
                      utils.format.percent(rate?.commission as number)
                    ) : (
                      '-'
                    )
                  ) : (
                    <Skeleton variant='rounded' width='50%' height={30} />
                  )}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>

        <Grid item xs={12} lg={12} sx={{ marginTop: '10px', borderX: '1px solid green' }}>
          <Button
            variant='contained'
            color='success'
            onClick={() => proposeHook.selectProposeClient(props)}
            fullWidth
            disabled={
              !(props.status !== 'preliminary' && proposeHook.primaryPropose?.uuid !== props.uuid && statusMap.includes(props.status))
            }
          >
            {t('interface:base.ProposeCard.selectPropose')}
          </Button>
        </Grid>

        <Grid item xs={12} lg={12} sx={{ marginTop: '10px', padding: '0 20px', borderX: '1px solid green', textAlign: 'center' }}>
          <Link sx={{ color: theme.palette.common.link, textDecoration: 'none', textAlign: 'center' }}>
            {t('interface:base.ProposeCard.discussConsultant')}
          </Link>
          {props?.decision?.additionalConditions && (
            <Link
              variant='h5'
              onClick={() => {
                setExpand(!expanded);
              }}
              sx={{
                textAlign: 'center',
                color: theme.palette.common.link,
                display: 'block',
              }}
            >
              {t('interface:base.ProposeCard.viewMore')}
              <SvgIcon
                style={{ height: '20px', paddingTop: '5px' }}
                htmlColor='inhirent'
                component={expanded ? ArrowUpIcon : ArrowDownIcon}
                stroke={theme.palette.text.secondary}
              />
            </Link>
          )}
        </Grid>

        <Box>
          {expanded && (
            <Typography sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: props?.decision?.additionalConditions || '' }} />
          )}
        </Box>
      </Grid>
    </Paper>
  );
}
