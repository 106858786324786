import { Box, Button, Checkbox, MenuItem, Paper, Radio, TextField, Typography } from '@mui/material';
import {
  ECBCEntryDocRequestedData,
  ECBCEntryFieldRequestedData,
  EFieldInputType,
  ERequestedDataType,
  IRequestFieldReq,
  TRequestedData,
} from 'api/services/businessCredit/businessCredit.propose.dto';
import businessCreditService from 'api/services/businessCredit/businessCredit.service';
import DadataSuggestion from 'components/base/DadataSuggestion';
import DatePickerInput from 'components/base/DatePickerInput';
import UnitSlider from 'components/base/UnitSlider';
import useTypedParams from 'hooks/useTypedParams';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { PATH_BUSINESS_CREDIT_PROPOSE } from 'routes/paths';
import utils from 'utils';
import UploadButton from 'components/common/UploadButton';
import { useState } from 'react';

interface RequestFieldInputProps {
  data: ECBCEntryFieldRequestedData;
  uuidPropose: string;
  uuidRequest: string;
}

interface RequestDocumentInputProps {
  data: ECBCEntryDocRequestedData;
  uuidPropose: string;
  uuidRequest: string;
}

interface ProposeRequestFieldProps {
  data: TRequestedData;
  uuidPropose: string;
}

const RequestFieldInput = ({ data, uuidPropose, uuidRequest }: RequestFieldInputProps) => {
  const { t } = useTranslation();
  const { uuidEntry } = useTypedParams<typeof PATH_BUSINESS_CREDIT_PROPOSE>();
  const { control, getValues, handleSubmit } = useForm<IRequestFieldReq>({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });

  const onSubmit = () => {
    const proposeRequest = getValues();
    putProposeRequestField(proposeRequest.value);
  };

  const putProposeRequestField = async (value: string) => {
    await businessCreditService.putProposeRequestField(uuidEntry, uuidPropose, uuidRequest, { value: value });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography variant={'h3b'} sx={{ fontSize: '18px' }}>
          {data.otherType}
        </Typography>

        {data.inputType === EFieldInputType.textField && (
          <Controller
            control={control}
            name="value"
            render={({ field, fieldState }) => (
              <TextField
                required
                error={!!fieldState.error}
                label={t(`interface:base.ProposeRequest.dataName.${data.type}`)}
                variant='standard'
                fullWidth
                {...field}
              />
            )}
          />
        )}

        {data.inputType === EFieldInputType.checkbox && (
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} {...field} />
                <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
                  {data?.selectValue ? data?.selectValue[0] : ''}
                </Typography>
              </Box>
            )}
          />
        )}

        {data.inputType === EFieldInputType.selector && (
          <Controller
            control={control}
            name="value"
            render={({ field, fieldState }) => (
              <TextField
                select
                label={t('interface:base.ProposeRequest.input.select')}
                error={!!fieldState.error}
                variant='standard'
                fullWidth
                {...field}
              >
                {data.selectValue?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}

        {data.inputType === EFieldInputType.number && (
          <Controller
            control={control}
            name="value"
            render={({ field, fieldState }) => (
              <NumberFormat
                variant='standard'
                error={!!fieldState.error}
                {...field}
                fullWidth
                label={t('interface:base.ProposeRequest.input.number')}
                customInput={TextField}
              />
            )}
          />
        )}

        {data.inputType === EFieldInputType.date && (
          <Controller control={control} name="value" render={({ field }) => <DatePickerInput {...field} />} />
        )}

        {data.inputType === EFieldInputType.slider && (
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <UnitSlider
                inputMask='currency'
                unitLabel='₽'
                label={t('interface:base.ProposeRequest.input.slider')}
                threshold={1}
                min={data.selectValue ? +data.selectValue[0] : 0}
                max={data.selectValue ? +data.selectValue[1] : 1000000}
                marks={[
                  {
                    value: data.selectValue ? +data.selectValue[0] : 0,
                    label: <Box>от {data.selectValue ? data.selectValue[0] : '0'} ₽</Box>,
                  },
                  {
                    value: data.selectValue ? +data.selectValue[1] : 1000000,
                    label: <Box>до {data.selectValue ? data.selectValue[1] : '1000000'} ₽</Box>,
                  },
                ]}
                step={100_000}
                period={100}
                format={(n) => utils.format.price(n)}
                handleChangePeriod={() => {}}
                {...field}
              />
            )}
          />
        )}

        {data.inputType === EFieldInputType.suggestion && (
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <DadataSuggestion
                variant='standard'
                // onInputChange={field.onChange}
                // onQuery={suggestSearchCompany}
                path={['name']}
                label={t('interface:base.ProposeRequest.input.suggestion')}
                // onSelect={handleSelect}
                // value={field?.value}
                {...field}
              />
            )}
          />
        )}

        {data.inputType === EFieldInputType.radio && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Controller control={control} name="value" render={({ field }) => <Radio {...field} />} />
            <Typography display='block' variant='h3m'>
              {data.selectValue ? data.selectValue[0] : ''}
            </Typography>
          </Box>
        )}

        <Button type='submit' variant='contained' fullWidth>
          {t('interface:base.ProposeRequestField.loadValue')}
        </Button>
      </Box>
    </form>
  );
};

const RequestDocumentInput = ({ data, uuidPropose, uuidRequest }: RequestDocumentInputProps) => {
  const { t } = useTranslation();
  const [uploadableDocument, _] = useState('');
  const { uuidEntry } = useTypedParams<typeof PATH_BUSINESS_CREDIT_PROPOSE>();

  const fileUpload = async (file: File) => {
    await businessCreditService.setProposeRequestDocument(uuidEntry, uuidPropose, uuidRequest, file);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column' },
        justifyContent: 'space-evently',
        minHeight: '94px',
        borderRadius: '16px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '12px', lg: '8px' } }}>
        <Typography variant={'h3b'} sx={{ fontSize: '18px' }}>
          {t(`interface:base.ProposeRequest.documentType.${data.type}`)}
        </Typography>

        <Typography variant={'h3b'} sx={{ fontSize: '18px' }}>
          {data.otherType}
        </Typography>

        <UploadButton fileLink={uploadableDocument} handleCustomUpload={fileUpload} />
      </Box>
    </Paper>
  );
};

const ProposeRequestField = ({ data, uuidPropose }: ProposeRequestFieldProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column' },
        justifyContent: 'space-evently',
        gap: '16px',
        minHeight: '94px',
        borderRadius: '16px',
      }}
    >
      {data.type === ERequestedDataType.field && <RequestFieldInput data={data.data} uuidPropose={uuidPropose} uuidRequest={data.uuid} />}

      {data.type === ERequestedDataType.document && (
        <RequestDocumentInput data={data.data} uuidPropose={uuidPropose} uuidRequest={data.uuid} />
      )}
    </Paper>
  );
};

export default ProposeRequestField;
