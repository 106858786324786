export enum EOcrDocumentType {
  passport = 'passport_main',
}

export interface IOcrDocument {
  type: EOcrDocumentType;
  data: IOcrDocumentData;
}

export interface IOcrDocumentData {
  lastname: string;
  firstname: string;
  patronymic: string;
  gender: EOcrGender;
  birthDate: string;
  birthPlace: string;
  seriesNumber: string;
  issueBy: string;
  issueDate: string;
  issueUnit: string;
}
export enum EOcrGender {
  male = 'male',
  female = 'female',
}
