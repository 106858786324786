import { req } from 'api/http';

/**
 * generate esia url
 * {@link https://gate.ip.mindcode.ru/documentation/static/index.html#/esia/getEsiaBaseAuthUrl}
 * @returns
 */
export const getEsiaUrl = () => {
  return req<null, { url: string }>(`esia/base-auth/url`, { method: 'GET' });
};

export function getEsiaCPG() {
  return req<null, { url: string }>(`esia/dp/auth/url`, { method: 'GET' });
}
