/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const NotificationIcon = createSvgIcon(
  <svg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.32066 18.6V19.48C7.32066 20.4136 7.7083 21.3089 8.39832 21.969C9.08833 22.6291 10.0242 23 11 23C11.9758 23 12.9117 22.6291 13.6017 21.969C14.2917 21.3089 14.6794 20.4136 14.6794 19.48V18.6M2.74445 8.92008C2.74292 7.87683 2.95715 6.84359 3.3748 5.87988C3.79245 4.91616 4.40528 4.04103 5.17797 3.30487C5.95067 2.56871 6.86797 1.98607 7.87704 1.59052C8.88611 1.19497 9.96701 0.994317 11.0575 1.00012C15.6107 1.03312 19.2555 4.6521 19.2555 9.01908V9.80008C19.2555 13.7381 20.1179 16.026 20.8768 17.28C20.9574 17.4136 20.9999 17.5651 21 17.7193C21.0001 17.8735 20.9579 18.025 20.8776 18.1587C20.7972 18.2924 20.6816 18.4035 20.5422 18.481C20.4028 18.5584 20.2446 18.5995 20.0834 18.6H1.9166C1.75541 18.5995 1.5972 18.5584 1.45781 18.481C1.31843 18.4035 1.20277 18.2924 1.12242 18.1587C1.04208 18.025 0.999858 17.8735 1 17.7193C1.00014 17.5651 1.04264 17.4136 1.12324 17.28C1.8821 16.026 2.74445 13.7381 2.74445 9.80008V8.92008Z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'NotificationIcon',
);

export default NotificationIcon;
