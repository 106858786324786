import { SvgIconComponent } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, SxProps } from '@mui/material';
import anime from 'animejs';
import BaseDraggable from 'components/base/Draggable';
import { Children, cloneElement, ReactChild, ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Coordinates } from 'types/draggable';
import Statuses from 'types/statuses';

export interface SwipeConfigButton {
  styles?: SxProps;
  onClick: (event: React.MouseEvent) => void;
}

export interface SwipeConfig {
  active?: boolean;
  button: SwipeConfigButton;
  icon: {
    component: SvgIconComponent;
    styles?: SxProps;
  };
}

interface IProps {
  children: ReactChild;
  swipeLeftConfig?: SwipeConfig;
  swipeRightConfig?: SwipeConfig;
  onSwipeRight?: () => void;
  onSwipeLeft?: () => void;
  containerStyle?: SxProps;
  commonButtonStyle?: SxProps;
  distanceToFix?: string;
  status?: Statuses;
}

// TODO: there's a strange bug: when you swipe on the right, page's overflow behavior is not correct
const BaseSwipeable = ({
  children,
  swipeLeftConfig,
  swipeRightConfig,
  onSwipeRight,
  onSwipeLeft,
  containerStyle,
  commonButtonStyle,
  distanceToFix = '0.5',
  status = Statuses.IDLE,
}: IProps) => {
  const containerRef = useRef<HTMLElement>();
  const childRef = useRef<HTMLElement>();

  const updatedButtonStyles = {
    ...commonButtonStyle,
    height: '100%',
    borderRadius: '16px',
  };

  const [position, setPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [Icon, setIcon] = useState<SvgIconComponent | null>(null);

  swipeLeftConfig = {
    ...(swipeLeftConfig as SwipeConfig),
    button: {
      ...(swipeLeftConfig?.button as SwipeConfigButton),
      styles: {
        right: 0,
        left: 'null',
        ...swipeLeftConfig?.button?.styles,
      },
    },
  };

  swipeRightConfig = {
    ...(swipeRightConfig as SwipeConfig),
    button: {
      ...(swipeLeftConfig?.button as SwipeConfigButton),
      styles: {
        right: 'null',
        left: 0,
        ...swipeRightConfig?.button?.styles,
      },
    },
  };

  const moveToInitialPosition = (): void => {
    anime({
      targets: childRef.current,
      translateX: 0,
      duration: 200,
      easing: 'easeOutSine',
      autoplay: true,
      complete: () => setPosition({ x: 0, y: 0 }),
    });
  };

  const moveOutOfScreen = (sign = 1): Promise<anime.AnimeInstance> => {
    return new Promise((resolve) => {
      anime({
        targets: childRef.current,
        translateX: (childRef.current?.offsetWidth ?? 0) * sign,
        duration: 150,
        easing: 'easeOutSine',
        autoplay: true,
        complete: resolve,
      });
    });
  };

  const handleSwipeLeft = (): void => {
    const width: number = childRef.current?.offsetWidth ?? 0;

    if (position.x <= width / -2) {
      if (distanceToFix) {
        moveOutOfScreen(-distanceToFix ?? -1);
        onSwipeLeft?.();
      } else {
        moveToInitialPosition();
        onSwipeLeft?.();
      }
    } else {
      moveToInitialPosition();
    }
  };

  const handleSwipeRight = (): void => {
    const width: number = childRef.current?.offsetWidth ?? 0;

    if (position.x >= width / 2) {
      if (distanceToFix) {
        moveOutOfScreen(+distanceToFix ?? 1);
        onSwipeRight?.();
      } else {
        moveToInitialPosition();
        onSwipeRight?.();
      }
    } else {
      moveToInitialPosition();
    }
  };

  const handleDrag = (pos: Coordinates): void => {
    if (!swipeLeftConfig?.active) return;
    const newX = position.x + (pos.deltaX ?? 0);

    if ((newX > 0 && swipeLeftConfig?.active) || (newX < 0 && swipeRightConfig?.active)) {
      return;
    }

    setPosition({
      ...position,
      x: newX,
    });
  };

  const handleDragStop = (): void => {
    if (position.x < 0) {
      handleSwipeLeft();
    } else if (position.x > 0) {
      handleSwipeRight();
    }
  };

  const applyHighlight = (): void => {
    if (childRef.current?.style) {
      childRef.current.style.transition = 'box-shadow 100ms linear';
      childRef.current.style.boxShadow =
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)';
    }
  };

  const removeHighlight = (): void => {
    if (childRef.current?.style) {
      childRef.current.style.boxShadow = 'null';
    }
  };

  useEffect(() => {
    if (Math.abs(position.x) >= (containerRef.current?.offsetWidth ?? 0) * Number(distanceToFix)) {
      return;
    }

    if (childRef.current?.style) {
      childRef.current.style.transform = `translateX(${position.x}px)`;
    }

    if (position.x !== 0 || position.y !== 0) {
      applyHighlight();
    } else {
      removeHighlight();
    }

    if (position.x < 0) {
      setIcon(swipeLeftConfig?.icon?.component ?? null);
    } else if (position.x > 0) {
      setIcon(swipeRightConfig?.icon?.component ?? null);
    } else {
      setIcon(null);
    }

    // eslint-disable-next-line
  }, [position, swipeRightConfig, swipeLeftConfig]);

  const buttonStyle: object = (position.x > 0 ? swipeRightConfig.button.styles : swipeLeftConfig.button.styles) ?? {};
  const iconStyle: object = (position.x > 0 ? swipeRightConfig.icon.styles : swipeLeftConfig.icon.styles) ?? {};
  const onButtonClick = position.x > 0 ? swipeRightConfig.button.onClick : swipeLeftConfig.button.onClick;

  return (
    <Box
      className='base-swipeable'
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'ew-resize',
        position: 'relative',
        overflow: 'hidden',
        ...containerStyle,
      }}
      ref={containerRef}
    >
      {Icon && (
        <LoadingButton
          sx={{
            position: 'absolute',
            width: `calc(${Number(distanceToFix) * 100}% - 8px)`,
            ...buttonStyle,
            ...updatedButtonStyles,
          }}
          variant='contained'
          loading={status === Statuses.LOADING}
          onClick={onButtonClick}
        >
          {status !== Statuses.LOADING && <Icon sx={iconStyle} />}
        </LoadingButton>
      )}

      <BaseDraggable onDrag={handleDrag} onDragStop={handleDragStop}>
        <Box sx={{ width: '100%' }}>
          {cloneElement(Children.only(children) as ReactElement, {
            ref: childRef,
          })}
        </Box>
      </BaseDraggable>
    </Box>
  );
};

export default BaseSwipeable;
