import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Paper, SvgIcon, Typography, useTheme } from '@mui/material';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import StatusDot from 'components/base/StatusDot';
import { stageProccess } from 'pages/Agent/Mortgage/Property/Client/List/components/ClientTable/utils';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

type ProgressClickHandle = ({ uuidEntry }: { uuidEntry: string }) => Promise<void>;
interface Props {
  clients: unknown[];
  count: number;
  onViewAll: () => void;
  onClickProgress: ProgressClickHandle;
  onAddClient: () => void;
}

function ClientCardItem({ profile, onClickProgress }: { profile: ObjectType; onClickProgress: ProgressClickHandle }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const stage = profile?.entry?.currentStage;
  //@ts-ignore //TODO: fix after release
  const status = !!stage ? stageProccess?.[stage]?.(profile.entry!) : undefined;
  return (
    <Box key={profile.uuid}>
      <Box sx={{ display: 'inline-flex', width: '100%', gap: '8px', alignItems: 'center' }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6' component='span'>
          {t(`interface:page.AgentMortgageHome.roles.borrower`)}
        </Typography>
        <StatusDot sx={{ width: '4px', height: '4px' }} color={theme.palette.text.secondary} />
        <Typography sx={{ flexGrow: 1, color: theme.palette.text.secondary }} variant='h6' component='span'>
          {utils.format.nameWithInitials(profile.user)}
        </Typography>
      </Box>
      <Button
        onClick={() => {
          onClickProgress({ uuidEntry: profile.entry.uuid! });
        }}
        data-testid='entry-info-btn'
        size='small'
        fullWidth
        sx={{
          padding: 0,
          heigth: '21px',
          color: `${theme.palette.text.primary}`,
          justifyContent: 'space-between',
        }}
        endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
      >
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
          <StatusDot sx={{ width: '11px', height: '11px' }} color={theme.palette.common.green} />
          <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
            {t(`common:businessCredit.stage.${stage}`)}&nbsp;
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}

export default function ClientCard({ clients, count, onViewAll, onClickProgress, onAddClient }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return !count ? (
    <Paper
      elevation={0}
      // data-urlname={pg.urlname}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evently',
        gap: '8px',
        cursor: 'pointer',
        padding: '16px',
        background: theme.palette.common.softYellow,

        borderRadius: '16px',
      }}
    >
      <Typography variant={'h3b'} sx={{ lineHeight: '130%' }}>
        {t(`interface:page.AgentMortgageHome.blkMyEntry`)}
      </Typography>
      <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
        {t(`interface:page.AgentMortgageHome.lblNoEntry`)}
      </Typography>

      <Button onClick={onAddClient} variant='contained' startIcon={<AddIcon />} size='large' data-testid={'btnNewEntry'}>
        {t('interface:page.AgentMortgageHome.btnNewEntry')}
      </Button>
    </Paper>
  ) : (
    <Paper
      elevation={0}
      // data-urlname={pg.urlname}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evently',

        padding: '16px',
        gap: { xs: '16px', lg: '24px' },
        background: theme.palette.secondary.main,

        borderRadius: '16px',
      }}
    >
      <Box sx={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
        <Typography variant={'h3b'} sx={{ lineHeight: '130%', fontSize: { xs: '16px', lg: '26px' } }}>
          {t(`interface:page.AgentMortgageHome.blkMyEntry`)}
        </Typography>
        {!!count && (
          <Typography variant='h3b' color='GrayText'>
            {count} +
          </Typography>
        )}
      </Box>

      <Fragment>
        {clients.map((client: ObjectType) => (
          <ClientCardItem key={client.uuid} profile={client} onClickProgress={onClickProgress} />
        ))}
      </Fragment>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
        {/* <Button fullWidth onClick={onAddClient} startIcon={<AddIcon />} variant='contained' size='large' data-testid={'addClient'}>
          {t('interface:page.AgentMortgageHome.ClientCard.add')}
        </Button> */}
        <Button sx={{ color: theme.palette.common.link }} fullWidth size='large' data-testid={'moreClients'} onClick={onViewAll}>
          {t('interface:page.AgentMortgageHome.ClientCard.allClients')}
        </Button>
      </Box>
    </Paper>
  );
}
