import { TextField, Typography } from '@mui/material';
import { HookForm } from 'components/base/FormGenerator/types';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSchemeData } from '../../form.types';
import { ft } from '../../utils';
import { maskComponent } from '../inputMask';

const FormTextField = ({ node, hookForm, path = [] }: { node: FormSchemeData; path?: string[]; hookForm: HookForm }) => {
  const { t } = useTranslation();
  const { control, watch } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={({ field, fieldState }) => {
        const f = {
          ...field,
          value: field?.value ? String(field.value) : '',
        };

        return (
          <Fragment>
            <TextField
              id={path.join('.')}
              {...f}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message}
              variant='standard'
              label={t(`${ft(path)}.title`)}
              //@ts-ignore
              InputProps={maskComponent(node?.settings?.mask, { ...f, 'data-testid': path.join('.') })}
              InputLabelProps={{ shrink: true }}
            />

            {node?.settings?.description && (
              <Typography component='span' sx={{ color: 'text.secondary', lineHeight: '140%', whiteSpace: 'normal', fontSize: '14px' }}>
                {t(`${ft(path)}.description`)}
              </Typography>
            )}
          </Fragment>
        );
      }}
    />
  );
};

FormTextField.propTypes = {
  node: PropTypes.object.isRequired,
  hookForm: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired,
};

export default FormTextField;
