import { LoadingButton } from '@mui/lab';
import { Collapse, Container, Grid, IconButton, Typography } from '@mui/material';
import InfoIcon from 'components/Icons/Info';
import BaseButtonBack from 'components/base/Button/Back';
import CheckListDocuments from 'components/base/SmartForm/CheckListDocuments';
import UploaderView from 'components/base/SmartForm/UploaderView';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useBusinessDocuments } from 'components/providers/Document/BusinessDocumentProvider';
import _ from 'lodash';
import TopMenu from 'pages/Client/Home/components/TopMenu';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_BUSINESS_CREDIT_ENTRY_SIGN } from 'routes/paths';
import utils from 'utils';

const T_PREFIX = 'interface:base.DocumentSmartForm';

export default function BankGuaranteeDocumentPage() {
  const documentService = useBusinessDocuments();
  const { uuidEntry } = useParams();

  const [isShowInfo, setShowInfo] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBackEvent = () => {
    navigate(-1);
    return false;
  };

  const handleSave = async () => {
    navigate(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_SIGN, { uuidEntry: uuidEntry! }));
  };

  return (
    <ContainerLayout
      maxWidth='xl'
      title={t('helmet:ClientMortgageFormPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageFormPage.description') }]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.ClientMortgageFormPage.forms.documents.title`)}
            subtitle={t(`interface:page.ClientMortgageFormPage.forms.profile.title`)}
            onBackEvent={handleBackEvent}
          />
        ),
        lg: <TopMenu sx={{ mx: 4 }} />,
      }}
    >
      <Container
        maxWidth='md'
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',

          alignItems: 'center',
          height: 1,
          gap: '24px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2b'>{t(`${T_PREFIX}.title`)}</Typography>
            <IconButton
              color={'primary'}
              onClick={() => {
                setShowInfo((prev) => !prev);
              }}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          {isShowInfo && (
            <Grid item xs={12}>
              <Collapse in={isShowInfo}>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  {t(`${T_PREFIX}.description`)}
                </Typography>
              </Collapse>
            </Grid>
          )}
          <Grid item xs={12}>
            <CheckListDocuments />
          </Grid>
          <Grid item xs={12}>
            <UploaderView
              queue={documentService.queue}
              files={documentService.documents}
              onDeleteFile={documentService.deleteRequest}
              onUpdateFile={documentService.updateRequest}
              onUploadFile={documentService.uploadRequest}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '20px' }}>
            <LoadingButton data-testid='submitDocuments' variant='contained' fullWidth onClick={handleSave}>
              {t(`${T_PREFIX}.submitButton`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </ContainerLayout>
  );
}
