import { LoadingButton } from '@mui/lab';
import { Box, SvgIcon, useTheme } from '@mui/material';
import WhiteLogoIcon from 'components/Icons/WhiteLogo';
import Page from 'components/layouts/Page';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH_REGISTER, PATH_AUTH_SIGNIN } from 'routes/paths';

const HomeGuestPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO: Less styles for components

  const handleSigninClick = () => navigate(PATH_AUTH_SIGNIN);
  const handlepRegisterClick = () => navigate(PATH_AUTH_REGISTER);

  return (
    <Page
      sx={{ padding: 0, height: 1, width: 1 }}
      hideHeader={true}
      title={t('helmet:HomeGuestPage.title')}
      meta={[{ name: 'description', content: t('helmet:HomeGuestPage.description') }]}
    >
      <Box
        sx={{
          backgroundColor: `${theme.palette.primary.main}`,
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexDirection: 'column',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            padding: '30px 0',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '114px',
              height: '114px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: `${theme.palette.common.white}`,
              borderRadius: '24px',
            }}
          >
            <SvgIcon sx={{ fontSize: 64 }} component={WhiteLogoIcon} />
          </Box>
          <Box
            sx={{
              fontSize: '64px',
              fontWeight: 700,
              marginTop: '30px',
            }}
          >
            {t('common:companyName')}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', width: { xs: 1, sm: 500 } }}>
          <LoadingButton
            sx={{
              backgroundColor: `${theme.palette.common.guestSigninBtn}`,
            }}
            variant='contained'
            onClick={handleSigninClick}
            data-testid='signinBtn'
          >
            {t('interface:page.HomeGuestPage.signinBtn')}
          </LoadingButton>
          <LoadingButton
            sx={{ backgroundColor: `${theme.palette.common.guestRegisterBtn}` }}
            variant='contained'
            onClick={handlepRegisterClick}
            data-testid='registerBtn'
          >
            {t('interface:page.HomeGuestPage.registerBtn')}
          </LoadingButton>
        </Box>
      </Box>
    </Page>
  );
};

export default HomeGuestPage;
