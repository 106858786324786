import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import { HookForm } from 'components/base/FormGenerator/types';
import { ft } from 'components/base/FormGenerator/utils';
import { get, isArray } from 'lodash';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { Fn, FnArg, ObjectType } from 'utils/iots';

import { useFormLayoutContext } from '../context/FormGeneratorProvider';
import {
  ComputedFunctionType,
  ComputedMethod,
  EFormChildrenComputedType,
  EventActionNames,
  FormSchemeData,
  SchemeSettingsChildren,
} from '../form.types';
import useFormGroup from '../hooks/useFormGroup';

import { computedKeys } from './ComputedContainer';
interface ComputedElementProps extends Partial<ReturnType<typeof useFormGroup>> {
  node?: FormSchemeData;
  renderOption: SchemeSettingsChildren;
  path: string[];
  dependedFields?: Record<string, any>;
  hookForm?: HookForm;
}

function hasValues(item: Record<string, ObjectType> | ObjectType) {
  return Object.keys(item.value).length > 0;
}

export default function ComputableElement(props: ComputedElementProps) {
  const { hookForm, renderOption, node } = props;
  const { dependsOn } = renderOption;

  const innerProps = props.renderOption.props;
  const type = props.renderOption.type;
  const formLayoutContext = useFormLayoutContext();
  const { t } = useTranslation();

  const handleClick = () => {
    const clickMethod = renderOption.props.onClick;
    if (clickMethod) {
      const form = formLayoutContext?.saveForm?.() as unknown as Promise<void>;
      form?.then(() => {
        formLayoutContext.redirect(clickMethod?.args as string);
      });
    }
  };

  const wrapMethod = (
    methodData: ComputedMethod<ComputedFunctionType | EventActionNames> | undefined,
  ): Fn<void> | FnArg<any> | undefined => {
    if (!methodData) {
      return undefined;
    }
    if (methodData.method === 'navigate') {
      return undefined;
    }
    if (methodData.method === 'sum') {
      if (isArray(methodData.args)) {
        const sum: any[] = [];

        props?.fieldValues?.forEach((item) => {
          for (const arg of methodData.args as string[]) {
            sum.push(get(item, `value.${arg}`));
          }
        });
        const sumValue = _(sum)
          .compact()
          .map((i) => utils.format.digitNumber(i))
          .sum();
        if (isNaN(sumValue)) {
          return () => null;
        }
        return () => utils.format.price(sumValue);
      }

      const items = _(props.fieldValues)
        .map((i) => get(i, `value.${methodData.args}`))
        .compact()
        .map((i) => utils.format.digitNumber(i))
        .sum();
      if (isNaN(items)) {
        return () => null;
      }
      return () => utils.format.price(items);
    }
    return (value?: any) => {
      if (methodData.method === 'i18next') {
        const isSelect = node?.data?.find((x) => x.name === methodData.args && x.type === 'input.select');
        const v = get(value, `value.${methodData.args}`);

        if (!v && !!value) {
          return null;
        }
        const fullpath = _(props.path)
          .concat(props?.node?.name as string)
          .concat(methodData.args as string[])
          .concat(isSelect ? ['options', v] : [])
          .compact()
          .value();

        return t(`${ft(fullpath)}`, { defaultValue: '' });
      }
      if (methodData.method === 'valueOf') {
        if (!value) {
          return null;
        }
        if (methodData.format) {
          const v = get(value, `value.${methodData.args}`);
          if (isNaN(v)) {
            return null;
          }
          return utils.format.price(v);
        }
        return get(value, `value.${methodData.args}`);
      }
    };
  };

  const wrapTitle = wrapMethod(innerProps.title);
  const wrapValue = wrapMethod(innerProps.value);

  if (dependsOn?.length) {
    const watchAllFields = hookForm?.watch();
    for (const depends of dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return <Fragment />;
      }
    }
  }

  if (type === EFormChildrenComputedType.box) {
    return null;
  }
  if (type === EFormChildrenComputedType.listView) {
    return (
      <Fragment>
        {props.fieldValues?.filter(hasValues).map((item) => {
          const title = wrapTitle?.(item);
          const value = wrapValue?.(item);

          return (
            <Box
              key={`${item.uuid}__${computedKeys(props.renderOption.props)}`}
              sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}
            >
              <Typography sx={{ fontSize: '16px', color: 'text.secondary' }}>{title || ''}</Typography>
              <Typography sx={{ fontSize: '16px', color: 'text.secondary' }}>{value || ''}</Typography>
            </Box>
          );
        })}
      </Fragment>
    );
  }

  if (type === EFormChildrenComputedType.button) {
    return (
      <Button variant='contained' color='primary' sx={{ marginTop: 'auto !important' }} onClick={handleClick}>
        {wrapTitle?.()}
      </Button>
    );
  }
  if (type === EFormChildrenComputedType.typography) {
    const value = wrapValue?.();
    const title = wrapTitle?.();
    const valueString = `на ${value}`;

    if (!value) {
      return null;
    }

    return <Typography> {value ? valueString : title} </Typography>;
  }

  if (type === EFormChildrenComputedType.cardButton) {
    return (
      <Paper
        elevation={0}
        sx={{
          cursor: 'pointer',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'common.greyLight',
          borderRadius: '8px',
          padding: '24px 24px 24px 24px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          '&:hover': {
            opacity: 0.8,
          },
        }}
        onClick={handleClick}
      >
        <Typography display='block' variant='h3m'>
          {wrapTitle?.()}
        </Typography>
        <IconButton>
          <ArrowForwardIosIcon />
        </IconButton>
      </Paper>
    );
  }
  if (type === EFormChildrenComputedType.summary) {
    const value = wrapValue?.();
    if (!value) {
      return null;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          backgroundColor: 'secondary.main',
          borderRadius: '16px',
        }}
      >
        <Typography sx={{ fontSize: { xs: '18px', md: '22px' }, color: 'text.secondary' }}>{wrapTitle?.()}</Typography>
        <Typography sx={{ fontSize: { xs: '26px', md: '32px' }, color: 'common.black', fontWeight: 700 }}>
          {!value ? <Skeleton /> : value}
        </Typography>
      </Box>
    );
  }

  return null;
}
