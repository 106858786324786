import { Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { getInsurancePackage, getProfileClientById, makeInsurancePropertyOrder } from 'api/services/agent';
import { getForm, postForm } from 'api/services/form';
import { ESupportedForms, TFormInput } from 'api/services/form.dto';
import AgentClientDetails from 'components/base/AgentClientDetails';
import BaseFormGenerator from 'components/base/FormGenerator';
import { buildValues, getFlatKeyValue, getObjValues, setNullForDepended } from 'components/base/FormGenerator/utils';
import InsuranceProductCard from 'components/base/InsuranceProductCard';
import Page from 'components/layouts/Page';
import { useResolver } from 'hooks/useResolver';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_AGENT_INSURANCE_PROPERTY_ORDER } from 'routes/paths';

export interface ILocationState {
  uuidInsurancePackage: string;
  uuidProfile: string;
}

export type TLocationParams = {
  uuidPackage: string;
  uuidProfile: string;
};

const AgentInsurancePropertyOrderCreatePage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  // TODO: API Typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [product, setProduct] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profile, setProfile] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [scheme, setFormScheme] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasFullProgress, setHasFullProgress] = useState<boolean>(false);
  const { uuidPackage, uuidProfile } = useParams<keyof TLocationParams>() as TLocationParams;

  const validator = useResolver(ESupportedForms.agentInsurance, scheme);
  const hookForm = useForm({ defaultValues: {}, resolver: validator.resolver, mode: 'onBlur', reValidateMode: 'onBlur' });

  const navigate = useNavigate();

  const makeOrder = async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await makeInsurancePropertyOrder(uuidPackage, uuidProfile);
      if (res.ok) {
        // TODO: API Typing
        const order = res.body as { uuid: string };
        navigate(`${PATH_AGENT_INSURANCE_PROPERTY_ORDER}/${order?.uuid}`);
      } else {
        setError('logic.somethingWrong');
      }
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };

  const loadPackage = async () => {
    try {
      const res = await getInsurancePackage(uuidPackage);
      const { body } = res;
      setProduct(body);
    } catch (err) {
      setError('logic.badConnection');
    }
  };

  const loadProfile = async () => {
    try {
      const res = await getProfileClientById(uuidProfile);
      const { body } = res;
      setProfile(body);
    } catch (err) {
      setError('logic.badConnection');
    }
  };

  const loadForm = async () => {
    try {
      const res = await getForm(ESupportedForms.agentInsurance, { mode: 'fill-empty-whole', uuidProfile });
      const { body } = res;
      setFormScheme(body);
    } catch (err) {
      setError('logic.badConnection');
    }
  };

  const handleFormSaveBtnClick = () => {
    // TODO: Types
    // TODO: Error processing
    const data = setNullForDepended(getFlatKeyValue(getObjValues(hookForm.getValues())), scheme, [
      ESupportedForms.agentInsurance,
    ]) as unknown as TFormInput;
    postForm(ESupportedForms.agentInsurance, {
      options: { uuidProfile },
      data,
    }).then(() => {
      loadProfile();
    });
  };

  useEffect(() => {
    if (!product && uuidPackage) loadPackage();
    if (!profile && uuidProfile) loadProfile();

    const dataFilling = !profile?.tags?.includes('dataFilling');
    setHasFullProgress(dataFilling);
    if (!dataFilling) loadForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, profile]);

  useEffect(() => {
    if (scheme) hookForm.reset(buildValues(scheme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheme]);

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyOrderCreatePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyOrderCreatePage.description') }]}
      header={{ title: t('interface:page.AgentInsurancePropertyOrderCreatePage.headerTitle') }}
      sx={{ gap: 2, paddingBottom: '96px' }}
    >
      {hasFullProgress && (
        <>
          <Typography variant='h3b' sx={{}}>
            {t('interface:page.AgentInsurancePropertyOrderCreatePage.product')}
          </Typography>
          <InsuranceProductCard button={{}} item={product} />

          <Typography variant='h3b' sx={{}}>
            {t('interface:page.AgentInsurancePropertyOrderCreatePage.profile')}
          </Typography>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              padding: '24px',
              borderRadius: '16px',
            }}
            variant='outlined'
          >
            {profile && <AgentClientDetails profile={profile} />}
          </Paper>

          <div style={{ position: 'fixed', left: 0, bottom: '20px', width: '100%', padding: '0 20px' }}>
            {error && (
              <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
                {t(`errors:${error}`)}
              </Typography>
            )}
            <Button disabled={loading} variant='contained' size='large' type='submit' fullWidth onClick={makeOrder}>
              {loading ? <CircularProgress size={24} /> : t('interface:page.AgentInsurancePropertyOrderCreatePage.drawUpBtn')}
            </Button>
          </div>
        </>
      )}
      {!hasFullProgress && (
        <>
          <Typography variant='h4m'>{t('interface:page.AgentInsurancePropertyOrderCreatePage.fillData')}</Typography>
          {scheme && <BaseFormGenerator scheme={scheme} hookForm={hookForm} path={[]} />}
          <Button onClick={handleFormSaveBtnClick} fullWidth variant='contained' size='large'>
            {t('interface:page.AgentInsurancePropertyOrderCreatePage.nextBtn')}
          </Button>
        </>
      )}
    </Page>
  );
};

export default AgentInsurancePropertyOrderCreatePage;
