import { TMakeFieldsOptional } from 'utils/iots';

export type TCreateContract = TMakeFieldsOptional<IContractEntity, 'uuid' | 'createdAt' | 'updatedAt' | 'signedAt'>;

export enum EMortgageContractFileType {
  pdf = 'pdf',
  html = 'html',
}

export enum EContractStatus {
  signed = 'signed',
  ready = 'ready',
  verification = 'verification',
  draft = 'draft',
  deleted = 'deleted',
}

export enum EContractCreditHistoryAgreement {
  personalDataProcessingWithCreditHistoryRequest = 'personalDataProcessingWithCreditHistoryRequest',
  personalDataTransferToThirdParties = 'personalDataTransferToThirdParties',
  productAndServicesInfoSubscribe = 'productAndServicesInfoSubscribe',
  creditHistoryForRosbank = 'creditHistoryForRosbank',
  creditHistoryForDomRf = 'creditHistoryForDomRf',
  creditHistoryForDomRfBank = 'creditHistoryForDomRfBank',
  creditHistoryForTanscapitalBank = 'creditHistoryForTanscapitalBank',
  creditHistoryForAlfaBank = 'creditHistoryForAlfaBank',
  creditHistoryForZenitBank = 'creditHistoryForZenitBank',
  creditHistoryForAbsolutBank = 'creditHistoryForAbsolutBank',
  creditHistoryForSNGBBank = 'creditHistoryForSNGBBank',
  creditHistoryForSovcomBank = 'creditHistoryForSovcomBank',
  creditHistoryForRaiffeisenBank = 'creditHistoryForRaiffeisenBank',
  transferPhoneNumberToThirdParties = 'transferPhoneNumberToThirdParties',
  transferPersonalDataToInsuranceCompanies = 'transferPersonalDataToInsuranceCompanies',
  rosbankPersonalDataProcessing = 'rosbankPersonalDataProcessing',
  absolutPersonalDataProcessing = 'absolutPersonalDataProcessing',
}

export enum EContractCreditProfileAgreement {
  personalQuestionnaireDataCorrect = 'personalQuestionnaireDataCorrect',
}

export interface IContractIds {
  uuidUser?: string;
  uuidProfile?: string;
  uuidCompany?: string;
  uuidEntry?: string;
}

export interface IContractSign<T = string, K = unknown> {
  type: EContractSignType;
  value: T;
  signedAt: number;
  details?: K;
}

export interface IContractAgreement<T = unknown> {
  type: T;
  value: boolean;
  signedBy: EContractSignType[];
}

export interface IContractEntity {
  uuid: string;
  createdAt: number;
  updatedAt: number;
  signedAt?: number;
  ids?: IContractIds;
  type: EContractType;
  status?: EContractStatus;
  agreements?: IContractAgreement[];
  details?: IContractDetails;
  signatures: IContractSign[];
}

export interface IContractDetailsPersonal {
  firstname?: string;
  lastname?: string;
  patronymic?: string;
  inn?: string;
  email?: string;
  phone?: string;
  type?: EContractDetailsPersonalType;
  ogrnip?: string;
}

export interface IContractDetailsPassport {
  docSeries?: string;
  docNo?: string;
  issueDate?: string;
  issueUnit?: string;
  issuedBy?: string;
  birthPlace?: string;
  birthDate?: string;
}

export interface IContractDetailsRegistrationPlace {
  fullAddress: string;
  flat?: string;
}

export interface IContractDetailsRequisites {
  bankAccountBik?: string;
  bankAccount?: string;
  bankName?: string;
  bankINN?: string;
  bankKPP?: string;
  correspondentBankAccount?: string;
}

export interface IContractDetailsDocuments {
  passportMainPage?: string;
  passportRegistrationPage?: string;
}

export interface IContractDetails {
  personal?: IContractDetailsPersonal;
  passport?: IContractDetailsPassport;
  registrationPlace?: IContractDetailsRegistrationPlace;
  requisites?: IContractDetailsRequisites;
  documents?: IContractDetailsDocuments;
  company?: IContractDetailsCompany;
  creditHistory?: IContractDetailsCreditHistory;
  entryQuestionnaire?: IContractDetailsMortgageEntryQuestionnaire;
}

export interface IContractDetailsMortgageEntryQuestionnaire {
  link: string;
}
export interface IContractSignAssets {
  handSigImageUrl: string;
}
export interface IContractDetailsCreditHistory {
  unresolvedJudicialDispute: boolean;
  judiciallyCondemned: boolean;
  officialPerson: {
    workPositionTitle: string;
    employerTitle: string;
    employerFullAddress: string;
  } | null;
  relationWithOfficialPerson: {
    relationType: string;
    workPositionTitle: string;
    employerTitle: string;
    employerFullAddress: string;
  } | null;
  taxResidentInUSA: boolean;
}

export interface IContractCompanyManagement {
  type: string;
  fullName: string;
}

export interface IContractCompanyRequisite {
  inn: string;
  kpp: string;
  ogrn: string;
}

export interface IContractCompanyAddresses {
  factAddress: string;
  legalAddress: string;
}

export interface IContractCompanyContacts {
  email: string;
  phone: string;
}

export interface IContractDetailsCompany {
  name?: string;
  requisite?: IContractCompanyRequisite;
  addresses?: IContractCompanyAddresses;
  contacts?: IContractCompanyContacts;
  management?: IContractCompanyManagement;
}

export interface IContractTemplate {
  data: string;
  contract?: IContractEntity;
}

export interface IContractIds {
  uuidUser?: string;
  uuidProfile?: string;
  uuidCompany?: string;
  uuidEntry?: string;
}

export enum EContractSignType {
  sms = 'sms',
  handwritten = 'handwritten',
}

export interface IContractSign<T = string, K = unknown> {
  type: EContractSignType;
  value: T;
  signedAt: number;
  details?: K;
}

export enum EContractType {
  agentContract = 'agentContract',
  agentCompanyContract = 'agentCompanyContract',
  creditHistoryContract = 'creditHistoryContract',
  creditProfileContract = 'creditProfileContract',
}

export interface IContractAgreement<T = unknown> {
  type: T;
  value: boolean;
  signedBy: EContractSignType[];
}

export interface IContractEntity {
  uuid: string;
  createdAt: number;
  updatedAt: number;
  signedAt?: number;
  ids?: IContractIds;
  type: EContractType;
  status?: EContractStatus;
  agreements?: IContractAgreement[];
  details?: IContractDetails;
  signatures: IContractSign[];
}

export interface IContractDetailsPersonal {
  firstname?: string;
  lastname?: string;
  patronymic?: string;
  inn?: string;
  email?: string;
  phone?: string;
  type?: EContractDetailsPersonalType;
  ogrnip?: string;
}

export interface IContractDetailsPassport {
  docSeries?: string;
  docNo?: string;
  issueDate?: string;
  issueUnit?: string;
  issuedBy?: string;
  birthPlace?: string;
  birthDate?: string;
}

export interface IContractDetailsRegistrationPlace {
  fullAddress: string;
  flat?: string;
}

export interface IContractDetailsRequisites {
  bankAccountBik?: string;
  bankAccount?: string;
  bankName?: string;
  bankINN?: string;
  bankKPP?: string;
  correspondentBankAccount?: string;
}

export interface IContractDetailsDocuments {
  passportMainPage?: string;
  passportRegistrationPage?: string;
}

export interface IContractDetails {
  personal?: IContractDetailsPersonal;
  passport?: IContractDetailsPassport;
  registrationPlace?: IContractDetailsRegistrationPlace;
  requisites?: IContractDetailsRequisites;
  documents?: IContractDetailsDocuments;
  company?: IContractDetailsCompany;
  creditHistory?: IContractDetailsCreditHistory;
  entryQuestionnaire?: IContractDetailsMortgageEntryQuestionnaire;
}

export interface IContractDetailsMortgageEntryQuestionnaire {
  link: string;
}

export interface IContractDetailsCreditHistory {
  unresolvedJudicialDispute: boolean;
  judiciallyCondemned: boolean;
  officialPerson: {
    workPositionTitle: string;
    employerTitle: string;
    employerFullAddress: string;
  } | null;
  relationWithOfficialPerson: {
    relationType: string;
    workPositionTitle: string;
    employerTitle: string;
    employerFullAddress: string;
  } | null;
  taxResidentInUSA: boolean;
}

export interface IContractCompanyManagement {
  type: string;
  fullName: string;
}

export interface IContractCompanyRequisite {
  inn: string;
  kpp: string;
  ogrn: string;
}

export interface IContractCompanyAddresses {
  factAddress: string;
  legalAddress: string;
}

export interface IContractCompanyContacts {
  email: string;
  phone: string;
}

export interface IContractDetailsCompany {
  name?: string;
  requisite?: IContractCompanyRequisite;
  addresses?: IContractCompanyAddresses;
  contacts?: IContractCompanyContacts;
  management?: IContractCompanyManagement;
}

export enum EContractDetailsPersonalType {
  individualEntrepreneur = 'individualEntrepreneur',
  individual = 'individual',
}

export interface IContractTemplate {
  data: string;
  contract?: IContractEntity;
}
export interface IContractContentResponse {
  content: string;
}

export interface IContractResponse {
  uuid: string;
  createdAt: number;
  updatedAt: number;
  ids?: IContractIds;
  type: EContractType;
  status?: EContractStatus;
  agreements?: IContractAgreement[];
}

export interface IContractProgress {
  personal: boolean;
  passport: boolean;
  registrationPlace: boolean;
  requisites: boolean;
  documents: boolean;
  company?: boolean;
}

export interface IContractVariables {
  key: string;
  val: string;
}

export type TSignContract =
  | CreditHistoryContract
  | CreditProfileContract
  | {
      type: EContractType.agentContract;
      signatures: Partial<Record<EContractSignType, string>>;
      agreements: Record<string, boolean>;
    }
  | {
      type: EContractType.agentCompanyContract;
      signatures: Partial<Record<EContractSignType, string>>;
      agreements: Record<string, boolean>;
    };

export interface CreditHistoryContract {
  uuidEntry: string;
  type: EContractType.creditHistoryContract;
  signatures: Partial<Record<'sms' | 'handwritten' | 'esea', string>>;
  agreements: Record<EContractCreditHistoryAgreement, boolean>;
  details: IContractDetailsCreditHistory;
  assets: IContractSignAssets;
}
export interface CreditProfileContract {
  uuidEntry: string;
  type: EContractType.creditProfileContract;
  signatures: Partial<Record<EContractSignType, string>>;
  agreements: { personalQuestionnaireDataCorrect: boolean };
  details: IContractDetailsMortgageEntryQuestionnaire;
  assets: IContractSignAssets;
}
