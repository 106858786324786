/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const Bzf = createSvgIcon(
  <svg viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M27.2 1.5L0.5 21.8V26.2L27.2 5.9L40.5 15.3V10.8L27.2 1.5Z' fill='url(#paint0_linear_68_406)' />
    <path d='M0.5 28.4001V32.8001L27.2 12.6001L40.5 21.9001V17.4001L27.2 8.1001L0.5 28.4001Z' fill='url(#paint1_linear_68_406)' />
    <path d='M0.5 35.0002V39.5002L27.2 19.2002L40.5 28.5002V24.0002L27.2 14.7002L0.5 35.0002Z' fill='url(#paint2_linear_68_406)' />
    <path d='M31.3998 30.7002H22.7998V39.3002H31.3998V30.7002Z' fill='#EC6608' />
    <defs>
      <linearGradient id='paint0_linear_68_406' x1='40.5' y1='13.85' x2='0.3939' y2='13.85' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#13A538' />
        <stop offset='1' stopColor='#0B4697' />
      </linearGradient>
      <linearGradient id='paint1_linear_68_406' x1='40.5' y1='20.4501' x2='0.3939' y2='20.4501' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#13A538' />
        <stop offset='1' stopColor='#0B4697' />
      </linearGradient>
      <linearGradient id='paint2_linear_68_406' x1='40.5' y1='27.1002' x2='0.3939' y2='27.1002' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#13A538' />
        <stop offset='1' stopColor='#0B4697' />
      </linearGradient>
    </defs>
  </svg>,
  'Bzf',
);

export default Bzf;
