import { EContractStatus, EContractType, IContractEntity } from 'api/services/contract.dto';
import { userGetContractByType, userSetContractContent } from 'api/services/user';
import SuccessIcon from 'components/Icons/SuccessLigth';
import NoticeScreen from 'components/base/NoticeScreen';
import Page from 'components/layouts/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';
import { Nullable } from 'utils/iots';

import ContractView from './components/ContractView';
import ContractSignForm from './components/SignForm';

const UserContractPage = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<'idle' | 'success'>('idle');
  const [offerText, setOfferText] = useState<Nullable<string>>(null);
  const [contract, setContract] = useState<Nullable<IContractEntity>>(null);

  const entrySuccessProps = {
    successIcon: SuccessIcon,
    successTitle: t('interface:page.UserContractPage.title'),
    successSubtitle: t('interface:page.UserContractPage.subtitle'),
    successBtnText: t('interface:page.UserContractPage.btnOk'),
  };

  const getOffer = async () => {
    try {
      // TODO: Error and request processing
      const contractContentResponse = await userSetContractContent(EContractType.agentContract);
      const contractResponse = await userGetContractByType(EContractType.agentContract);
      if (contractContentResponse.ok) {
        setOfferText(contractContentResponse.body.content || '');
      }
      if (contractResponse.ok) {
        const body = contractResponse.body;

        if (body) setContract(body);
      }
    } catch (e) {
      // TODO: handle error
      console.log(e);
    }
  };

  useEffect(() => {
    getOffer();
  }, []);

  const onSuccess = () => setStatus('success');

  return (
    <Page
      title={t('helmet:UserContractPage.title')}
      meta={[{ name: 'description', content: t('helmet:UserContractPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.UserContractPage.headerTitle'), backRoute: PATH_USER }}
      hideHeader={status === 'success'}
      sx={status === 'success' ? { width: 1, height: 1 } : {}}
    >
      {status === 'success' && <NoticeScreen {...entrySuccessProps} />}
      {status === 'idle' &&
        (contract?.status === EContractStatus.ready ? (
          <ContractSignForm onSuccess={onSuccess} text={offerText as string} contract={EContractType.agentContract} />
        ) : (
          <ContractView text={offerText as string} subtitle={contract?.status} />
        ))}
    </Page>
  );
};

export default UserContractPage;
