import { Box, Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { getMortgageInsurance } from 'api/services/insurance/insurance';
import { CreditContract as ICreditContract } from 'api/services/insurance/insurance.dto';
import ErrorMessage from 'components/base/ErrorMessage';
import { DateField } from 'pages/Client/Mortgage/Entry/Insurance/components/DateField';
import { InputField } from 'pages/Client/Mortgage/Entry/Insurance/components/InputField';
import { NumberField } from 'pages/Client/Mortgage/Entry/Insurance/components/NumberField';
import { SelectFieldOther } from 'pages/Client/Mortgage/Entry/Insurance/components/SelectFieldOther';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function CreditContract() {
  const [creditContract, setCreditContract] = useState<ICreditContract>();
  const [error, setError] = useState<ApiError>();
  const { t } = useTranslation();
  const { uuidEntry } = useParams();

  const prefix = `interface:page.MortgageEntryInsurancePage.InsuranceCreditContractForm`;

  const fetchData = async () => {
    try {
      const creditContract = await getMortgageInsurance(uuidEntry!);
      setCreditContract(creditContract.body.creditContract);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (creditContract === undefined) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <InputField defaultValue={creditContract?.creditOrg} prefix={prefix} label={'creditContract.creditOrg'} />
      <DateField defaultValue={creditContract?.insStartDate} prefix={prefix} label={'creditContract.insStartDate'} />
      <DateField defaultValue={creditContract?.insEndDate} prefix={prefix} label={'creditContract.insEndDate'} />
      <DateField defaultValue={creditContract?.insContractCreationDate} prefix={prefix} label={'creditContract.insContractCreationDate'} />
      <NumberField defaultValue={creditContract?.insContractNumber} prefix={prefix} label={'creditContract.insContractNumber'} mask={'_'} />
      <NumberField
        defaultValue={creditContract?.creditAmount}
        prefix={prefix}
        label={'creditContract.creditAmount'}
        thousandSeparator={' '}
        suffix={' ₽'}
      />
      <NumberField
        defaultValue={creditContract?.creditInterestRate}
        prefix={prefix}
        label={'creditContract.creditInterestRate'}
        suffix={t(`${prefix}.creditContract.creditInterestRate.format`)}
      />
      <SelectFieldOther
        defaultValue={creditContract?.creditPaymentPeriod}
        prefix={prefix}
        label={'creditContract.creditPaymentPeriod'}
        otherLabel={'creditContract.creditPaymentPeriodComment'}
        defaultOtherValue={creditContract?.creditPaymentPeriodComment}
        answers={['monthly', 'quarterly']}
      />
    </Box>
  );
}
