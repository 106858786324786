import { DeleteForeverRounded } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, SxProps, TextField, Typography, useTheme } from '@mui/material';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const typographySx: SxProps = { fontSize: { md: '22px', xs: '18px' }, fontWeight: { xs: 500, md: 400 } };

export default function ProposeEditForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = useFormContext<any>();
  const fieldArray = useFieldArray({ control, name: 'decision.rates' });

  return (
    <Grid container direction='row' spacing={{ xs: 2, md: 3 }}>
      <Grid item md={6} sx={{ order: { xs: 4, lg: 2 } }}>
        <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
          {t('interface:base.ProposeCard.creditDuration')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          <Controller
            control={control}
            name='decision.period'
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                variant='standard'
                data-testid={field.name}
                suffix=' мес.'
                fullWidth
                customInput={TextField}
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Typography>
      </Grid>
      <Grid item md={6} sx={{ order: { xs: 2, lg: 3 } }}>
        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }} variant='h6'>
          {t('interface:base.ProposeCard.creditAmount')}
        </Typography>
        <Typography variant='h4' sx={typographySx}>
          <Controller
            control={control}
            name='decision.amount'
            render={({ field, fieldState }) => (
              <NumberFormat
                required
                variant='standard'
                data-testid={field.name}
                thousandSeparator=' '
                suffix=' ₽'
                fullWidth
                customInput={TextField}
                value={field.value}
                onChange={(e: ObjectType) => {
                  field.onChange(utils.format.digitNumber(e.target.value));
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
              />
            )}
          />
        </Typography>
      </Grid>

      <Grid item xs={12} container sx={{ order: 10 }} gap='16px'>
        {fieldArray.fields.map((fieldItem, index) => (
          <Grid item md={12} key={fieldItem.id}>
            <Typography variant='h6' sx={{ color: theme.palette.text.secondary }} />

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
              <Controller
                control={control}
                name={`decision.rates.${index}.value`}
                render={({ field, fieldState }) => (
                  <NumberFormat
                    required
                    label={t('interface:base.ProposeCard.rate')}
                    variant='standard'
                    data-testid={field.name}
                    thousandSeparator=' '
                    suffix=' %'
                    fullWidth
                    decimalSeparator=','
                    customInput={TextField}
                    value={field.value}
                    onValueChange={(values) => {
                      field.onChange(values.floatValue);
                    }}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                  />
                )}
              />
              <Controller
                control={control}
                name={`decision.rates.${index}.commission`}
                render={({ field, fieldState }) => (
                  <NumberFormat
                    label='Комиссия'
                    variant='standard'
                    data-testid={field.name}
                    thousandSeparator=' '
                    suffix=' %'
                    decimalSeparator=','
                    fullWidth
                    customInput={TextField}
                    value={field.value}
                    onValueChange={(values) => {
                      field.onChange(values.floatValue);
                    }}
                    error={!!fieldState.error}
                    helperText={t(`${fieldState.error?.message || ''}`)}
                  />
                )}
              />
              <IconButton onClick={() => fieldArray.remove(index)}>
                <DeleteForeverRounded />
              </IconButton>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              fieldArray.append({ value: 0, comission: 0 });
            }}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
