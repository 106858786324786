import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Link, Paper, Typography, useTheme } from '@mui/material';
import { EInsPropPackageCompany } from 'api/services/mortgage.dto';
import { EUserRole, IUserEntity, IUserSettingsInsPropOptions, IUserSettingsMortgageOptions } from 'api/services/user.dto';
import Page from 'components/layouts/Page';
import { useAuth } from 'components/providers/AuthProvider';
import useSupportChat from 'hooks/useSupportChat';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  PATH_AGENT_INSURANCE_PROPERTY,
  PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE,
  PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL_CONTENT,
} from 'routes/paths';

enum ETrainigPageItemSize {
  wide = 'wide',
  regular = 'regular',
}
interface ITrainigPageItem {
  urlname: string;
  size: ETrainigPageItemSize;
  thumbnail: { src: string; color: string; size: string; logo?: boolean };
  soon: boolean;
  visible: (user?: IUserEntity | null) => boolean;
}

const insuranceCompanies = [
  {
    img: '/img/agent/insurance/Reso.svg',
    name: 'resoGarant',
    soon: false,
  },
  {
    img: '/img/agent/insurance/AlfaBank.svg',
    name: 'alfastrah',
    soon: false,
  },
  {
    img: '/img/agent/insurance/InGos.svg',
    name: 'ingosstrah',
    soon: false,
  },
];

function AgentInsurance() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const supportChat = useSupportChat();
  const auth = useAuth();
  const serviceSettings = auth.getServiceSettings?.('insProp') as IUserSettingsInsPropOptions & IUserSettingsMortgageOptions;

  const handleInsCompanyClick = (e?: any) => {
    navigate(`${PATH_AGENT_INSURANCE_PROPERTY}/${e?.target.dataset.company || 'all'}`);
  };
  const handleAddUserClick = () => navigate(PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE);

  const handleSupportClick = () => {
    supportChat.open();
  };

  const handlePageClick = (e: React.MouseEvent<HTMLElement>) => {
    const urlname = e.currentTarget.getAttribute('data-urlname');
    if (!urlname) return;
    navigate(PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL_CONTENT.replace(':urlname', urlname));
  };

  const trainingPages: ITrainigPageItem[] = [
    {
      urlname: 'tutorial-insp-kickstart',
      size: ETrainigPageItemSize.wide,
      thumbnail: { src: '/img/tutorial/tutorial-insp-kickstart.png', color: '#F8E11A', size: '108px' },
      soon: false,
      visible: (user?: IUserEntity | null): boolean => auth.hasRole(EUserRole.agent) && !user?.company,
    },
  ];

  return (
    <Page
      title={t('helmet:AgentHomePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentHomePage.description') }]}
      sx={{ height: 1, justifyContent: 'space-between' }}
    >
      <Grid container spacing={{ xs: '8px' }}>
        {trainingPages.map((pg) => (
          <Grid hidden={!pg.visible(auth?.user)} item xs={pg.size === ETrainigPageItemSize.wide ? 12 : 6} lg={12} xl={12} key={pg.urlname}>
            <Paper
              onClick={pg.soon ? undefined : handlePageClick}
              elevation={0}
              data-urlname={pg.urlname}
              sx={{
                filter: pg.soon ? 'grayscale(1)' : 'none',
                cursor: 'pointer',
                padding: '16px',
                paddingRight: pg.size === ETrainigPageItemSize.wide ? '35%' : '16px',
                background: pg.thumbnail.color,
                minHeight: pg.size === ETrainigPageItemSize.wide ? '137px' : '164px',
                borderRadius: '16px',
                backgroundImage: `url(${pg.thumbnail.src})`,
                backgroundSize: `${pg.thumbnail.size}`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: pg.size === ETrainigPageItemSize.wide ? 'right center' : 'right bottom',
                ...(pg.thumbnail.logo
                  ? { backgroundPosition: 'right bottom, 16px 16px', backgroundOrigin: 'content-box, padding-box' }
                  : {}),
              }}
            >
              {/* TODO: lineHeight -> Problems with typography theme override */}
              <Typography variant={pg.size === ETrainigPageItemSize.wide ? 'h3b' : 'h4b'} sx={{ lineHeight: '130%' }}>
                {t(`interface:page.AgentInsurancePropertyTutorialPage.pages.${pg.urlname}.title`)}
              </Typography>
              <Typography variant={pg.size === ETrainigPageItemSize.wide ? 'h5' : 'h6'}>
                {t(`interface:page.AgentInsurancePropertyTutorialPage.pages.${pg.urlname}.description`)}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {insuranceCompanies
          .filter(({ name }) => {
            if (!serviceSettings?.availableCompanies) return true;
            return serviceSettings?.availableCompanies.includes(name as EInsPropPackageCompany & EInsPropPackageCompany);
          })
          .map(({ img, name, soon }) => (
            <Paper
              key={name}
              className={soon ? 'container' : undefined}
              data-ribbon={t('interface:page.AgentHomePage.soonLabel')}
              data-company={name}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '25px',
                padding: '34px 24px',
                marginBottom: '8px',
                borderRadius: '16px',
                cursor: 'pointer',
              }}
              variant='outlined'
              onClick={handleInsCompanyClick}
            >
              <img src={img} className={soon ? 'gray' : undefined} data-company={name} alt={t(`common:insurance.companies.${name}`)} />
            </Paper>
          ))}
        <Button sx={{ cursor: 'pointer' }} color='secondary' size='large' variant='contained' onClick={handleInsCompanyClick}>
          {t('interface:page.AgentHomePage.allProductsBtn')}
        </Button>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', marginBottom: '20px' }}>
        <Button variant='contained' size='large' startIcon={<AddIcon />} onClick={handleAddUserClick} data-testid={'addClientBtn'}>
          {t('interface:page.AgentHomePage.addClientBtn')}
        </Button>
        <Link
          sx={{ cursor: 'pointer' }}
          textAlign='center'
          variant='h4'
          color={theme.palette.common.link}
          underline='none'
          onClick={handleSupportClick}
        >
          {t('interface:page.AgentHomePage.supportChatBtn')}
        </Link>
      </Box>
    </Page>
  );
}

export default AgentInsurance;
