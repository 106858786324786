import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Fade, Grid, IconButton, InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';
import { ReactChild, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface InputWrapperProps {
  prefix: string;
  label: string;
  infoText?: ReactChild;
  required?: boolean;
  defaultValue?: string;
}

export const InputField = (props: InputWrapperProps & TextFieldProps) => {
  const { infoText, prefix, label, defaultValue, required = false } = props;
  const [expanded, expand] = useState(false);

  const theme = useTheme();

  const { t } = useTranslation();

  const { control } = useFormContext();

  const handleExpand = () => {
    expand(!expanded);
  };
  const canExpand = !!infoText;
  return (
    <Box>
      <Controller
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        name={label}
        render={({ field }) => (
          <TextField
            fullWidth
            variant='standard'
            {...props}
            {...field}
            label={t(`${prefix}.${label}.title`)}
            InputProps={
              canExpand
                ? {
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton sx={{ color: theme.palette.primary.dark }} onClick={handleExpand}>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        )}
      />
      {expanded && (
        <Fade in={expanded} timeout={1000}>
          <Grid item md={12} sx={{ textAlign: 'justify' }}>
            {infoText && infoText}
          </Grid>
        </Fade>
      )}
    </Box>
  );
};
