import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import { Accordion, AccordionSummary, Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { Box, Container } from '@mui/system';
import GosUslugiText from 'components/Icons/GosUslugiText';
import ScanIcon from 'components/Icons/Scan';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function () {
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);

  const { t } = useTranslation();
  const theme = useTheme();
  const fadeAnimation = (v: number) => ({
    '@keyframes myAnim': {
      '0%': { opacity: 0, transform: 'translateY(50px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    animation: `myAnim ${v}s ease-in 0s 1 normal forwards`,
    width: 1,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => step + 1);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (step === 8) {
      window.scrollTo(0, window.innerHeight);
    }
    if (step === 9) {
      const interval = setInterval(() => {
        setProgress((progress) => {
          if (progress < 100) {
            return progress + 1;
          } else {
            clearInterval(interval);
          }
          return progress;
        });
      });
    }
  }, [step]);

  return (
    <Box sx={{ height: 1, width: 1, marginTop: '40px', zIndex: -100 }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '14px', width: 1, paddingBottom: '40px' }}>
        {step >= 1 && (
          <Box sx={fadeAnimation(1)}>
            <Box sx={{ background: theme.palette.secondary.main, padding: '16px', borderRadius: '16px' }}>
              <SvgIcon component={GosUslugiText} viewBox='0 0 163 27' sx={{ height: '25px', width: '200px' }} />
              <Typography sx={{ fontSize: '16px', fontWeight: 500, marginBottom: '13px' }}>Авторизация через Госуслуги</Typography>
              <Button variant='contained' fullWidth>
                Авторизоваться
              </Button>
            </Box>
          </Box>
        )}
        {step >= 2 && (
          <Box sx={fadeAnimation(1)}>
            <Box
              sx={{
                background: theme.palette.secondary.main,
                padding: '16px',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                <SvgIcon component={ScanIcon} viewBox='0 0 43 47' sx={{ width: '43px', height: '47px' }} />
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Отсканируйте паспорт для быстрого заполнения</Typography>
              </Box>

              <Button variant='contained' fullWidth sx={{ marginTop: '10px' }}>
                Отсканировать
              </Button>
            </Box>
          </Box>
        )}
        {step >= 3 && (
          <Box sx={fadeAnimation(1)}>
            <Accordion
              data-testid={`credit-params`}
              key={`credit-params`}
              sx={{ width: 1, background: theme.palette.success.light, borderRadius: '16px', boxShadow: 'none', '&:before': { height: 0 } }}
            >
              <AccordionSummary
                sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0', alignItems: 'center' } }}
              >
                <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '12px' }}>
                  <Typography sx={{ fontSize: '16px' }}>{t(`interface:page.ClientMortgageProgressPage.progress.credit.title`)}</Typography>
                  <SvgIcon
                    sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }}
                    color={'success'}
                    component={DoneIcon}
                  />
                </Box>

                <SvgIcon sx={{ height: '12px', width: '11px' }} htmlColor='#ABC6A7' component={ArrowForwardIosIcon} />
              </AccordionSummary>
            </Accordion>
          </Box>
        )}
        {step >= 4 && (
          <Box sx={{ ...fadeAnimation(1) }}>
            <Accordion
              sx={{
                border: progress < 100 ? '1px solid #D2D2CF' : 'none',
                background: progress >= 100 ? theme.palette.success.light : 'common.white',
                borderRadius: '16px',
                boxShadow: 'none',
                '&:before': { height: 0 },
              }}
              expanded={step >= 6}
            >
              <AccordionSummary sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0' } }}>
                <Typography variant='h4m'>{t(`interface:page.ClientMortgageProgressPage.progress.profile.title`)}</Typography>
                <Typography variant='h4' data-testid={`step-profile-percent`}>
                  {progress}%
                </Typography>
              </AccordionSummary>
            </Accordion>
          </Box>
        )}
        {step >= 4 && (
          <Box sx={fadeAnimation(1)}>
            <Accordion disabled sx={{ border: '1px solid #D2D2CF', borderRadius: '8px', boxShadow: 'none', '&:before': { height: 0 } }}>
              <AccordionSummary sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0' } }}>
                <Typography variant='h4m'>Выбор банка</Typography>
              </AccordionSummary>
            </Accordion>
          </Box>
        )}
      </Container>
    </Box>
  );
}
