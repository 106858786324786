export enum EProfileWorkspaceType {
  employee = 'employee',
  owner = 'owner',
  individualEntrepreneur = 'individualEntrepreneur',
  selfEmployed = 'selfEmployed',
}
export enum EProfileIncomePeriod {
  year = 'year',
  month = 'month',
}
export enum EProfileIncomeType {
  rent = 'rent',
  pension = 'pension',
  subsidium = 'subsidium',
  job = 'job',
  other = 'other',
}
export enum EProfileCompanyStatus {
  active = 'active',
  liquidating = 'liquidating',
  liquidated = 'liquidated',
  bankrupt = 'bankrupt',
  reorganizing = 'reorganizing',
}
export enum ECompanyOrganizationType {
  legal = 'legal',
  individual = 'individual',
}
export enum EProfileCompanyScopeOfWork {
  adminSupplyDep = 'admin-supply-dep',
  accounting = 'accounting',
  medical = 'medical',
  governmentCivil = 'government-civil',
  governmentMilitary = 'government-military',
  governmentLawEnf = 'government-law-enforcement',
  householdWorker = 'household-worker',
  enginServOrControlQuality = 'engin-serv-or-control-quality',
  individualEntrp = 'individualEntrepreneur',
  marketingOrPubAffairs = 'marketing-or-publicity-or-public-affairs',
  seagoing = 'seagoing',
  serviceOrCustomerSupport = 'service-or-customer-support',
  personalService = 'service-pers',
  teachingOrMentoring = 'teaching-or-mentoring',
  sales = 'sales',
  factory = 'factory',
  operatingPersonal = 'operating-pers',
  riskManagement = 'risk-manag-or-analytics',
  secretariatOrAdmin = 'secretariat-or-admin',
  security = 'security-guard',
  regionService = 'region-service',
  sport = 'sport',
  creationOrArt = 'creation-or-art',
  logistics = 'logistics',
  financEconomDep = 'financ-econom-dep',
  legalServant = 'legal-serv',
  hr = 'hr',
  it = 'it',
  other = 'other',
}
export enum EProfileWorkspaceContractType {
  permanently = 'permanent',
  temporary = 'temporary',
}
export enum EProfileWorkspaceWorkField {
  office = 'office',
  outside = 'outside',
}
export enum EProfileWorkspaceContractExpLevel {
  notSpecSkilled = 'not-spec-skill',
  specialist = 'specialist-or-senior',
  manager = 'main-specialist',
  topManager = 'top-manager',
  foundOrShareholder = 'founder-or-shareholder',
}
export enum EProfileCompanyTaxationSchema {
  generalTax = 'general-tax',
  simplifiedTax = 'simplified-tax',
  other = 'other',
}
export enum EProfileWorkplaceEmplConfirmDocType {
  laborContract = 'laborContract',
  laborBook = 'laborBook',
  gphContract = 'gphContract',
  noConfirmation = 'noConfirmation',
  other = 'other',
}

export enum EProfileIncomeConfirmDocType {
  ndfl = 'ndfl',
  formCertificate = 'form-certificate',
  extractFromPfr = 'extractFromPfr',
  extractFromAccount = 'extractFromAccount',
  laborContract = 'laborContract',
  gphContract = 'gphContract',
  noConfirmation = 'noConfirmation',
  taxAccounts = 'taxAccounts',
  managementAccounts = 'managementAccounts',
  other = 'other',
}
