import { Box } from '@mui/material';
import Page from 'components/layouts/Page';
import { useTranslation } from 'react-i18next';

export default function AgentMortgagePropertyPage() {
  const { t } = useTranslation();
  return (
    <Page
      title={t('helmet:AgentMortgagePropertyPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentMortgagePropertyPage.description') }]}
      sx={{ height: 1, justifyContent: 'space-between' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }} />
    </Page>
  );
}
