/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const SuccessIcon = createSvgIcon(
  <svg viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127C98.7939 127 127 98.7939 127 64C127 29.2061 98.7939 1 64 1C29.2061 1 1 29.2061 1 64C1 98.7939 29.2061 127 64 127Z'
      fill='#FEFCE8'
      stroke='#F8E11A'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path d='M95 43L56.2068 85L34 61.4171' fill='#FEFCE8' />
    <path
      d='M56.2068 85L55.4788 85.6855L56.2139 86.4662L56.9414 85.6785L56.2068 85ZM94.2654 42.3215L55.4722 84.3215L56.9414 85.6785L95.7346 43.6785L94.2654 42.3215ZM56.9349 84.3145L34.728 60.7316L33.272 62.1027L55.4788 85.6855L56.9349 84.3145Z'
      fill='#F8E11A'
    />
  </svg>,
  'SuccessIcon',
);

export default SuccessIcon;
