import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

const PopoverContext = createContext<Partial<PopoverContextProps>>({ open: false });

interface PopoverContextProps {
  open: boolean;
  openPopover: () => void;
  closePopover: () => void;
}

export default function PopoverProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);

  const closePopover = useCallback(() => {
    setOpen(false);
  }, []);
  const openPopover = useCallback(() => {
    setOpen(true);
  }, []);
  const value = { open, openPopover, closePopover };
  return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>;
}

export function usePopover() {
  return useContext(PopoverContext) as PopoverContextProps;
}
