/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const AlfaIcon = createSvgIcon(
  <svg viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' fill='#EF3124' />
    <path d='M35 39.5H14V35.2672H35V39.5Z' fill='white' />
    <path
      d='M21.5272 21.6597L24.4727 13.1678H24.5817L27.3637 21.6597H21.5272ZM28.3442 11.6002C27.7457 9.86815 27.0555 8.5 24.6909 8.5C22.3261 8.5 21.5918 9.86253 20.9615 11.6002L14.4636 29.5168H18.7726L20.2726 25.2574H28.5636L29.9545 29.5168H34.5363L28.3442 11.6002Z'
      fill='white'
    />
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' stroke='#F5F5F5' />
  </svg>,
  'AlfaIcon',
);

export default AlfaIcon;
