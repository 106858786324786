import { Box, FormControlLabel, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { ApiError } from 'api/errors';
import { getMortgageInsurance } from 'api/services/insurance/insurance';
import { Perils, PerilsKeys } from 'api/services/insurance/insurance.dto';
import Checkbox from 'components/base/Checkbox';
import ErrorMessage from 'components/base/ErrorMessage';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface Item {
  key: PerilsKeys;
  title: string;
  description: string;
  permanent?: boolean;
}

const items = [
  {
    key: 'property',
    title: `interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.program.property.title`,
    description: `interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.program.property.description`,
    permanent: true,
  },
  {
    key: 'health',
    title: `interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.program.life.title`,
    description: `interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.program.life.description`,
  },
  {
    key: 'title',
    title: `interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.program.title.title`,
    description: `interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.program.title.description`,
  },
] as Item[];

interface ProgramPerils extends Omit<Perils, 'health'> {
  health: boolean;
}

export default function InsuranceProgramForm() {
  const [perils, setPerils] = useState<ProgramPerils>();
  const [error, setError] = useState<ApiError>();
  const hookForm = useFormContext();
  const theme = useTheme();

  const { uuidEntry } = useParams();

  const fetchData = async () => {
    try {
      const data = await getMortgageInsurance(uuidEntry!);
      const health = data.body.perils?.health.length > 0;
      setPerils({ ...data.body.perils, health });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { t } = useTranslation();

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (perils === undefined) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch' }}>
      <Typography sx={{ fontSize: '26px', fontWeight: 700, lineHeight: '130%' }}>
        {t('interface:page.MortgageEntryInsurancePage.InsuranceProgramForm.title')}
      </Typography>
      {items.map((item) => (
        <Controller
          control={hookForm.control}
          key={item.key}
          name={`perils.${item.key}`}
          defaultValue={perils[item.key]}
          render={({ field }) => (
            <FormControlLabel
              checked={field.value}
              onChange={field.onChange}
              label=''
              control={
                <Paper
                  data-testid={`insurance.perils.${item.key}`}
                  elevation={0}
                  sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    pointerEvents: item.permanent ? 'none' : 'all',
                    borderColor: !field.value || item?.permanent ? theme.palette.common.greyLight : theme.palette.primary.dark,
                    borderRadius: '8px',
                    padding: '24px 24px 24px 6px',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Checkbox
                    disabled={!!item.permanent}
                    checked={field.value || item.permanent}
                    sx={{ width: '50px', filter: item.permanent ? 'grayscale(100%)' : '' }}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Typography display='block' variant='h3m'>
                      {t(item.title)}
                    </Typography>
                    <Typography variant='h5' sx={{ color: '#787770' }}>
                      {t(item.description)}
                    </Typography>
                  </Box>
                </Paper>
              }
            />
          )}
        />
      ))}
    </Box>
  );
}
