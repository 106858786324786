import { ArrowForwardIos, Done } from '@mui/icons-material';
import { Accordion, AccordionSummary, Box, Button, LinearProgress, SvgIcon, SxProps, Typography, useTheme } from '@mui/material';
import { EMortgageEntryRole, IMortgageEntryProgress, IProfileProgressUserData } from 'api/services/mortgage.dto';
import { t } from 'i18next';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PATH_CLIENT_MORTGAGE_ENTRY_EDIT,
  PATH_CLIENT_MORTGAGE_ENTRY_SIGN,
  PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN,
  PATH_CLIENT_MORTGAGE_FORM_VERIFICATION,
} from 'routes/paths';
import utils from 'utils';

import { ProgressUtils } from '../../utils';
import BankStepProgress from '../BankStepProgress';
import ProfileStepProgress from '../ProfileProgress';
import ProgressItem from '../ProgressItem';
import { EUserRole } from 'api/services/user.dto';
import { Accessible, useRoleAccess } from 'components/providers/RoleAccessProvider';
import { getRealEstate } from 'api/services/realEstate/realEstate.service';
import { RealEstateEntity } from 'api/services/realEstate/realEstate.interface';
import { EMortgageEntryRealEstateStatus } from 'api/services/realEstate/realEstate.enum';
import { approveRealEstate, rejectRealEstate } from 'api/services/manager/manager.service';
import { useSnackbar } from 'notistack';
import ScoringPanel from '../Scoring';

interface Props {
  progress?: IMortgageEntryProgress;
  uuidEntry: string;
  uuidUser?: string;
  refetch: () => void;
}

export default function ProgressContainer({ refetch, progress, uuidEntry, uuidUser }: Props) {
  const [realEstate, setRealEstate] = useState<RealEstateEntity>();
  const navigate = useNavigate();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string>('');
  const { accessible: hasAccess } = useRoleAccess([EUserRole.agent, EUserRole.manager, EUserRole.admin]);
  const { enqueueSnackbar: showSnackbar } = useSnackbar();

  const progressUtils = useMemo(() => new ProgressUtils(progress), [progress]);

  const handleExpand = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean): void => {
    setExpanded(isExpanded ? panel : '');
  };
  /**
   * handle process logic to redirect on pages
   *
   * @param  {keyof IMortgageEntryProgress} step
   * @param {keyof IMortgageEntryProgress[keyof IMortgageEntryProgress]} substep
   * @param {{uuidProfile:string,uuidUser:string}} obj use to redirect  to page by concrete user by uuidUser
   * @returns
   */
  const handleProrfileProgressClick = (step: string, substep: string, profile: IProfileProgressUserData) => (): void => {
    const uuidUserProfile = profile.ids.uuidUser;

    if (substep === 'verification') {
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_VERIFICATION, { uuidEntry, uuidUser: uuidUserProfile }));
      return;
    }
    if (substep === 'signing') {
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_SIGN, { uuid: uuidEntry }));
      return;
    }

    navigate(
      utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, {
        formName: step,
        groupName: substep,
        uuidEntry,
        uuidUser: uuidUserProfile,
      }),
    );
  };

  // TODO: We need to replace all routes with uuidProfie to uuidUser
  const handleClickProgressItem = useCallback(
    (path: string) => () => {
      const [step, substep] = path.split('.');

      const url = utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, {
        formName: step,
        groupName: substep,
        uuidEntry,
        uuidUser,
      });
      navigate(url);
    },
    [],
  );

  // TODO: Remove after replace all uuidProfile -> uuidUser
  // TODO: We need to use uuidUser of target user in entry (not current authorized user)
  const handleClickInsuranceProgressItem = useCallback(
    (path: string) => () => {
      const [step, substep] = path.split('.');
      // TODO: Now it's hack working only for editabled borrower from manager role
      let targetUser = uuidUser;
      if (progress?.profile.some((pr) => pr?.ids?.uuidUser === uuidUser)) {
        // Current user exists in entry users, then we can use current user
        targetUser = uuidUser;
      } else {
        // We will use borrower user
        const borrower = progress?.profile?.find((pr) => pr?.role === EMortgageEntryRole.borrower);
        if (borrower) {
          targetUser = borrower.ids.uuidUser;
        }
      }
      const url = utils.url.urlReplace(PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN, {
        formName: step,
        groupName: substep,
        uuidEntry,
        uuidUser: targetUser,
      });
      navigate(url);
    },
    [progress],
  );

  const navigateCreditParams = () => {
    navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_EDIT, { uuid: uuidEntry }));
  };

  const successSx: SxProps = {
    background: theme.palette.success.light,
    borderRadius: '8px',
    boxShadow: 'none',
    '&:before': { height: 0 },
    pointerEvents: hasAccess ? 'all' : 'none',
  };

  const requestRealEstate = async () => {
    try {
      const data = await getRealEstate({ uuidEntry: uuidEntry });
      setRealEstate(data.body);
    } catch (err) {}
  };
  const approveRealEstateRequest = async () => {
    if (!uuidEntry) {
      return;
    }
    try {
      await approveRealEstate(uuidEntry);
      showSnackbar(t(`interface:base.ProgressContainer.approveText`));
      refetch();
    } catch (err) {}
  };
  const rejectRealEstateRequest = async () => {
    if (!uuidEntry) {
      return;
    }
    try {
      await rejectRealEstate(uuidEntry);
      showSnackbar(t(`interface:base.ProgressContainer.rejectText`));
      refetch();
    } catch (err) {}
  };

  // const isDisabledVerification =
  //   progress?.profile
  //     ?.map((x) => x.contracts)
  //     .flat()
  //     .find((x) => x.type === EContractType.creditProfileContract)?.status !== EContractStatus.draft;

  useEffect(() => {
    if (uuidEntry && expanded === 'realEstate') requestRealEstate();
  }, [uuidEntry, progress, expanded]);

  return (
    <Fragment>
      <Accordion
        data-testid={`credit-params`}
        key={`credit-params`}
        sx={{ ...successSx, pointerEvents: 'all' }}
        expanded={expanded === `credit-params`}
        onClick={navigateCreditParams}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              justifyContent: 'space-between',
              margin: '20px 0',
              alignItems: 'center',
            },
          }}
        >
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '12px' }}>
            <Typography variant='h4m'>{t(`interface:page.ClientMortgageProgressPage.progress.credit.title`)}</Typography>
            <SvgIcon sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }} color={'success'} component={Done} />
          </Box>

          <SvgIcon sx={{ height: '12px', width: '11px' }} htmlColor='#ABC6A7' component={ArrowForwardIos} />
        </AccordionSummary>
      </Accordion>

      {progress?.profile?.map((profile) => (
        <>
          <ProfileStepProgress
            sucesssSx={successSx}
            key={`step-profile-${profile.uuid}`}
            expanded={expanded === `step-profile-${profile.uuid}`}
            profile={profile}
            handleExpand={handleExpand(`step-profile-${profile.uuid}`)}
            handleClick={handleProrfileProgressClick}
          />
          {expanded === `step-profile-${profile.uuid}` && (
            <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
              <ScoringPanel uuidEntry={uuidEntry} uuidUser={profile.ids.uuidUser} />
            </Accessible>
          )}
        </>
      ))}

      <ProgressItem
        sx={successSx}
        name='bank'
        expanded={expanded}
        onExpand={handleExpand}
        disabled={progressUtils.getProfileProgressValue() < 100}
        progressUtils={progressUtils}
        progressNumber={progressUtils.progressValueField('bank.complete')}
      >
        <Fragment>
          <LinearProgress
            sx={{ height: '1px', marginBottom: '20px' }}
            variant='determinate'
            value={progressUtils.progressValue?.('bank')}
          />
          <BankStepProgress />
        </Fragment>
      </ProgressItem>
      <ProgressItem
        accordionButton={
          <Accessible requireRoles={[EUserRole.manager, EUserRole.admin]}>
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end', flexDirection: { xs: 'column', md: 'row' }, gap: '24px' }}>
              <Button
                onClick={approveRealEstateRequest}
                variant="contained"
                color="primary"
                disabled={realEstate?.mortgage?.status !== EMortgageEntryRealEstateStatus.verification}
              >
                {t('interface:base.ProgressContainer.approveRealEstate')}
              </Button>
              <Button
                onClick={rejectRealEstateRequest}
                variant="outlined"
                color="secondary"
                disabled={
                  realEstate?.mortgage?.status === EMortgageEntryRealEstateStatus.process ||
                  realEstate?.mortgage?.status === EMortgageEntryRealEstateStatus.reject
                }
              >
                {t('interface:base.ProgressContainer.rejectRealEstate')}
              </Button>
            </Box>
          </Accessible>
        }
        progressNumber={progressUtils.progressValue('realEstate')}
        progress={progress}
        sx={{ ...successSx, pointerEvents: 'all' }}
        disabled={progressUtils.progressValueField('bank.complete') < 100}
        name='realEstate'
        onClickItem={handleClickProgressItem}
        expanded={expanded}
        onExpand={handleExpand}
        progressUtils={progressUtils}
      />
      <ProgressItem
        progress={progress}
        sx={successSx}
        disabled={progressUtils.progressValue('realEstate') < 100}
        name='insuranceMortgage'
        onClickItem={handleClickInsuranceProgressItem}
        expanded={expanded}
        onExpand={handleExpand}
        progressUtils={progressUtils}
      />
      <ProgressItem
        progress={progress}
        sx={successSx}
        disabled={progressUtils.progressValue('insuranceMortgage') < 100}
        name='deal'
        onClickItem={handleClickProgressItem}
        expanded={expanded}
        onExpand={handleExpand}
        progressUtils={progressUtils}
      />
    </Fragment>
  );
}
