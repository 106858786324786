import { Paper, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IMortgageEntryPropose } from 'api/services/mortgage.dto';
import Absolut from 'components/Icons/Absolut';
import AlfaIcon from 'components/Icons/Alfa';
import BankLogoDomrfIcon from 'components/Icons/BankLogoDomRf';
import Bzf from 'components/Icons/Bzf';
import MetalInvest from 'components/Icons/MetalInvest';
import PromSocialBank from 'components/Icons/PromSocialBank';
import Raiffeisen from 'components/Icons/Raiffeisen';
import Rosbank from 'components/Icons/Rosbank';
import RusNar from 'components/Icons/RusNar';
import Sovcom from 'components/Icons/Sovcom';
import SurgutNefteGas from 'components/Icons/SurgutNefteGas';
import TransKapital from 'components/Icons/TransKapital';
import UralSib from 'components/Icons/UralSib';
import Zenit from 'components/Icons/Zenit';
import { useTranslation } from 'react-i18next';
import { A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

import 'swiper/css/bundle';

const mapIcons: Record<ObjectType, ObjectType> = {
  absolut: Absolut,
  rosbank: Rosbank,
  domrf: BankLogoDomrfIcon,
  rusnar: RusNar,
  sovcom: Sovcom,
  alfa: AlfaIcon,
  bzf: Bzf,
  promSocial: PromSocialBank,
  metalInvest: MetalInvest,
  uralsib: UralSib,
  transkapital: TransKapital,
  raiffeisen: Raiffeisen,
  zenit: Zenit,
  surgutneftegas: SurgutNefteGas,
};

const mock = [
  {
    uuid: '74a1c70b-c023-485b-93aa-eaa8f9c663c91',
    createdAt: 1661435457,
    bank: 'uralsib',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 600,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: { opportunity: { opportunityId: '61f07293-d851-4237-84de-27b762c96bbb', error: null } },
  },
  {
    uuid: '74a1c70b-c023-485b-93aa-eaa8f9c663c92',
    createdAt: 1661435457,
    bank: 'metalInvest',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 439,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: { opportunity: { opportunityId: '61f07293-d851-4237-84de-27b762c96bbb', error: null } },
  },
  {
    uuid: '74a1c70b-c023-485b-93aa-eaa8f9c663c93',
    createdAt: 1661435457,
    bank: 'promSocial',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 935,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: { opportunity: { opportunityId: '61f07293-d851-4237-84de-27b762c96bbb', error: null } },
  },
  {
    uuid: '74a1c70b-c023-485b-93aa-eaa8f9c663c94',
    createdAt: 1661435457,
    bank: 'bzf',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 1349,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: { opportunity: { opportunityId: '61f07293-d851-4237-84de-27b762c96bbb', error: null } },
  },
  {
    uuid: '74a1c70b-c023-485b-93aa-eaa8f9c663c95',
    createdAt: 1661435457,
    bank: 'rusnar',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 1260,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: { opportunity: { opportunityId: '61f07293-d851-4237-84de-27b762c96bbb', error: null } },
  },
  {
    uuid: '74a1c70b-c023-485b-93aa-eaa8f9c663c96',
    createdAt: 1661435457,
    bank: 'alfa',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 470,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: { opportunity: { opportunityId: '61f07293-d851-4237-84de-27b762c96bbb', error: null } },
  },
  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'rosbank',
    decision: {
      status: 'approved',
      receivedAt: 1664286162,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 145,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: 'a82f4a6c-2e04-42f9-bfbe-c005b9826b6f',
    createdAt: 1664286161,
    bank: 'sovcom',
    decision: {
      status: 'declined',
      receivedAt: 1664286161,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 199,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: 'fd4dbee0-112c-49c2-9fea-bf6f7e305c95',
    createdAt: 1664286161,
    bank: 'absolut',
    decision: {
      status: 'reworkRequired',
      receivedAt: 1664286161,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 450,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: 'bfea35be-262d-4d86-8b79-cec5ee6fbad8',
    createdAt: 1664286161,
    bank: 'transkapital',
    decision: {
      status: 'failed',
      receivedAt: 1664286161,
      approvedSum: 10000000000,
      creditDuration: 125,
      rate: 1105,
      monthlyPayment: 80000000,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: 'ba93b9d4-ad4b-4669-ad20-d7d8b38470ea',
    createdAt: 1664286161,
    bank: 'raiffeisen',
    decision: {
      status: 'approved',
      receivedAt: 1664286161,
      approvedSum: 8000000000,
      creditDuration: 215,
      rate: 1069,
      monthlyPayment: 37209302,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: 'bb1ec50b-3d7d-4f4e-a19e-c91bbb06c58e',
    createdAt: 1664286161,
    bank: 'domrf',
    decision: {
      status: 'approved',
      receivedAt: 1664286161,
      approvedSum: 9650000000,
      creditDuration: 364,
      rate: 90,
      monthlyPayment: 26510989,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: '2846ecde-a59b-4f82-bc9b-e66d83a982d8',
    createdAt: 1664286161,
    bank: 'zenit',
    decision: {
      status: 'approved',
      receivedAt: 1664286161,
      approvedSum: 2222000000,
      creditDuration: 364,
      rate: 535,
      monthlyPayment: 2510989,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: 'a284f22a-10c6-463e-8a24-4a49b18fb4db',
    createdAt: 1664286161,
    bank: 'surgutneftegas',
    decision: {
      status: 'approved',
      receivedAt: 1664286161,
      approvedSum: 2222000000,
      creditDuration: 364,
      rate: 600,
      monthlyPayment: 2510989,
    },
    status: 'closed',
    internalBankData: {},
  },
];

function CarouselItem<T extends IMortgageEntryPropose>({ propose }: { propose: T }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Paper
      elevation={0}
      sx={{
        cursor: 'pointer',
        padding: '16px',
        background: theme.palette.secondary.main,
        borderRadius: '16px',
        minWidth: '151px',
      }}
    >
      <SvgIcon component={mapIcons[propose?.bank as string]} sx={{ width: 48, height: 48 }} />

      <Typography variant='h5'>{t(`common:mortgage.banks.${propose?.bank}`)}</Typography>

      <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
        {t('common:from')} {utils.format.percent(propose?.decision?.rate as number)}
      </Typography>
    </Paper>
  );
}

export default function BankCarousel() {
  const proposes = mock as Array<IMortgageEntryPropose>;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Swiper
      spaceBetween={15}
      autoplay={{ pauseOnMouseEnter: true, delay: 2000 }}
      slidesPerView={isDesktop ? 3 : 2}
      modules={[A11y, Autoplay]}
    >
      {proposes &&
        proposes.length > 0 &&
        proposes
          ?.filter((x) => !!x)
          .map((propose) => (
            <SwiperSlide key={propose.uuid}>
              <CarouselItem key={propose.uuid} propose={propose} />{' '}
            </SwiperSlide>
          ))}
    </Swiper>
  );
}
