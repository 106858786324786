import { Avatar, Box, Link, Paper, Typography, useTheme } from '@mui/material';
import { IAdEntity } from 'api/services/ad.dto';
import StatusDot from 'components/base/StatusDot';
import utils from 'utils';

const CompanyBanner = ({ title, subtitle, avatarUrl, contactInfo }: IAdEntity) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        order: { xs: 1, lg: 2 },
        display: 'flex',
        flexDirection: { xs: 'column' },
        justifyContent: 'space-evently',
        gap: '16px',
        cursor: 'pointer',
        padding: { xs: '16px', lg: '24px' },
        background: theme.palette.secondary.main,
        minHeight: '94px',
        borderRadius: '16px',
      }}
    >
      <Typography variant={'h3b'}>{title}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 0.5, gap: { xs: '12px', lg: '16px' } }}>
        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {avatarUrl && <Avatar sx={{ width: { xs: '48px', lg: '72px' }, height: { xs: '48px', lg: '72px' } }} src={avatarUrl} />}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography variant={'h4'} sx={{ fontSize: '18px' }}>
              {subtitle}
            </Typography>
            {contactInfo && (
              <Box sx={{ display: 'flex', gap: { xs: '4px', md: '8px' }, flexWrap: 'wrap', alignItems: 'center' }}>
                <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
                  {contactInfo.phone && utils.format.getPhoneNumber(`${contactInfo.phone}`)}
                </Typography>
                {contactInfo.phone && contactInfo.email && (
                  <StatusDot color={'#D2D2CF'} sx={{ display: { xs: 'none', md: 'block' }, width: '4px', height: '4px' }} />
                )}
                <Link sx={{ w: 1, fontSize: '16px' }} variant={'h5'} href={`mailto:${contactInfo.email}`}>
                  {contactInfo.email}
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default CompanyBanner;
