import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { getInsuranceOrderStats } from 'api/services/agent';
import BaseStatisticChart from 'components/base/StatisticChart';
import Page from 'components/layouts/Page';
import dayjs from 'dayjs';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

const AgentInsurancePropertyStatisticPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data } = useFetch(getInsuranceOrderStats);

  // TODO: I'ts just temp mock, need to refactor all page
  const mockGetReportDate = (dayjs().get('date') >= 15 ? dayjs().add(1, 'month').set('date', 15) : dayjs().set('date', 15)).format(
    'DD.MM.YYYY',
  );

  const { allTimeBounty = 0, currentMonthStats = 0 } = data || {};
  const { awaitingPaymentSales = 0 } = currentMonthStats || {};

  return (
    <Page
      title={t('helmet:AgentInsurancePropertyStatisticPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentInsurancePropertyStatisticPage.description') }]}
      header={{ title: t('interface:page.AgentInsurancePropertyStatisticPage.headerTitle') }}
    >
      <Box
        sx={{
          marginTop: '24px',
          marginBottom: '32px',
          width: 1,
          background: '#F9F8F4',
          borderRadius: '8px',
        }}
      >
        <Box sx={{ padding: '24px 24px 0 24px' }}>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t('interface:page.AgentInsurancePropertyStatisticPage.allTimeBounty')}
          </Typography>

          <Typography variant='h3' sx={{ margin: '8px 0 24px', fontSize: '22px', fontWeight: '700' }}>
            {utils.format.price(allTimeBounty / 100)}
          </Typography>
        </Box>
      </Box>

      <BaseStatisticChart timeline={data?.timeline} />

      <Box sx={{ mb: '24px' }}>
        <Typography variant='h3b'>{t('interface:page.AgentInsurancePropertyStatisticPage.awaitingPayment')}</Typography>

        <Box
          sx={{
            marginTop: '16px',
            width: 1,
            background: '#F9F8F4',
            borderRadius: '8px',
          }}
        >
          <Stack direction='row' spacing={2} sx={{ padding: '24px' }}>
            <Box sx={{ color: theme.palette.text.secondary }}>
              <Typography variant='h5' sx={{ mb: '4px' }}>
                {t('interface:page.AgentInsurancePropertyStatisticPage.sales')}
              </Typography>

              <Typography variant='h4b'>{utils.format.price(awaitingPaymentSales / 100)}</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box sx={{ mb: '40px' }}>
        <Typography variant='h3b'>{t('interface:page.AgentInsurancePropertyStatisticPage.resultsTheMonth')}</Typography>

        <Box
          sx={{
            marginTop: '16px',
            width: 1,
            background: '#F9F8F4',
            borderRadius: '8px',
          }}
        >
          <Box sx={{ padding: '24px' }}>
            <Typography variant='h4' sx={{ mb: '8px', color: theme.palette.text.secondary }}>
              {t('interface:page.AgentInsurancePropertyStatisticPage.paymentForMonth', { month: dayjs().format('MMMM') })}
            </Typography>

            <Typography variant='h4' sx={{ color: theme.palette.common.greyLight }}>
              {`${t('interface:page.AgentInsurancePropertyStatisticPage.dataWillApear')} ${mockGetReportDate}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button variant='contained'>{t('interface:page.AgentInsurancePropertyStatisticPage.downloadDetails')}</Button>
    </Page>
  );
};

export default AgentInsurancePropertyStatisticPage;
