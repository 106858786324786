/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const Share = createSvgIcon(
  <svg width='128' height='128' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127C98.7939 127 127 98.7939 127 64C127 29.2061 98.7939 1 64 1C29.2061 1 1 29.2061 1 64C1 98.7939 29.2061 127 64 127Z'
      fill='#F5F5F5'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M61.6659 40.8209L69.7744 32.7124C73.2075 29.5833 77.7142 27.8973 82.358 28.0048C87.0019 28.1124 91.4258 30.0051 94.7104 33.2897C97.9949 36.5743 99.8876 40.9981 99.9952 45.642C100.103 50.2859 98.4167 54.7926 95.2877 58.2257L83.6982 69.7743C82.0279 71.4508 80.043 72.7811 77.8574 73.6888C75.6719 74.5965 73.3286 75.0637 70.962 75.0637C68.5955 75.0637 66.2522 74.5965 64.0666 73.6888C61.881 72.7811 59.8962 71.4508 58.2259 69.7743'
      fill='#F5F5F5'
    />
    <path
      d='M66.3341 87.1791L58.2256 95.2876C54.7925 98.4167 50.2858 100.103 45.6419 99.9951C40.9981 99.8876 36.5742 97.9949 33.2896 94.7103C30.0051 91.4257 28.1124 87.0019 28.0048 82.358C27.8973 77.7141 29.5833 73.2074 32.7123 69.7743L44.3018 58.2257C45.9721 56.5492 47.957 55.2189 50.1425 54.3112C52.3281 53.4035 54.6714 52.9363 57.038 52.9363C59.4045 52.9363 61.7478 53.4035 63.9334 54.3112C66.119 55.2189 68.1038 56.5492 69.7741 58.2257'
      fill='#F5F5F5'
    />
    <path
      d='M61.6659 40.8209L69.7744 32.7124C73.2075 29.5833 77.7142 27.8973 82.358 28.0048C87.0019 28.1124 91.4258 30.0051 94.7104 33.2897C97.9949 36.5743 99.8876 40.9981 99.9952 45.642C100.103 50.2859 98.4167 54.7926 95.2877 58.2257L83.6982 69.7743C82.0279 71.4508 80.043 72.7811 77.8574 73.6888C75.6719 74.5965 73.3286 75.0637 70.962 75.0637C68.5955 75.0637 66.2522 74.5965 64.0666 73.6888C61.881 72.7811 59.8962 71.4508 58.2259 69.7743M66.3341 87.1791L58.2256 95.2876C54.7925 98.4167 50.2858 100.103 45.6419 99.9951C40.9981 99.8876 36.5742 97.9949 33.2896 94.7103C30.0051 91.4257 28.1124 87.0019 28.0048 82.358C27.8973 77.7141 29.5833 73.2074 32.7123 69.7743L44.3018 58.2257C45.9721 56.5492 47.957 55.2189 50.1425 54.3112C52.3281 53.4035 54.6714 52.9363 57.038 52.9363C59.4045 52.9363 61.7478 53.4035 63.9334 54.3112C66.119 55.2189 68.1038 56.5492 69.7741 58.2257'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>,
  'Share',
);

export default Share;
