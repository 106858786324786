import { req } from 'api/http';
import { ECMortgageEntryParams } from './mortgage.schema';
import { EMortgageEntryRole, IMortgageEntryEntity } from '../mortgage.dto';

export function postMortgageEntryParams(body: {
  params: ECMortgageEntryParams;
  users: { role: EMortgageEntryRole; uuidUser: string }[];
}) {
  return req<
    {
      params: ECMortgageEntryParams;
      users: { role: EMortgageEntryRole; uuidUser: string }[];
    },
    IMortgageEntryEntity
  >(`user/mortgage-entry`, { method: 'POST', body });
}

export function putMortgageEntryParams(uuidEntry: string, body: ECMortgageEntryParams) {
  return req<ECMortgageEntryParams, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/params`, { method: 'PUT', body });
}
