import { Components } from '@mui/material';
import { Theme } from '@mui/system';
import typography from 'theme/typography';

const components: Components<Theme> = {
  MuiRadio: {
    styleOverrides: {
      root: {
        color: '#00000033',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-focused': {
          color: theme.palette.text.secondary,
        },
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiInputLabel-shrink': {
          '&.Mui-focused': {
            color: theme.palette.text.secondary,
          },
        },
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      InputLabelProps: {
        shrink: true,
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.filter': {
          '.MuiInputBase-root': {
            background: theme.palette.secondary.main,
            borderRadius: '8px',
          },
          '& fieldset': {
            border: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '11px',
          },
          '& .MuiInputBase-input:hover + fieldset': {
            border: 'none',
          },
          '& .MuiInputBase-input:focus + fieldset': {
            border: 'none',
          },
        },
      }),
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ ownerState: { error }, theme }) => ({
        '&:before': {
          borderColor: `${theme.palette.common.greyLight} !important`,
        },
        '&:after': {
          borderBottomWidth: '1px',
          borderBottomColor: error ? theme.palette.error.main : theme.palette.common.greyLight,
        },
        '&.filter': {
          background: 'back  !important',
        },
      }),
    },
    variants: [
      {
        props: { variant: 'filter' },
        style: ({ theme }) => ({
          border: '1px solid black',
          background: theme.palette.secondary.dark,
        }),
      },
      {
        props: { variant: 'shareLink' },
        style: ({ theme }) => ({
          ...typography.h4,
          border: 'none',
          background: theme.palette.info.light,
          color: theme.palette.info.main,
          borderRadius: '8px',
          height: '56px',
          '&:before': {
            borderBottom: 'none !important',
          },
          '&:after': {
            borderBottom: 'none !important',
          },
          input: {
            padding: '0 20px',
            textAlign: 'center',
          },
        }),
      },
      {
        props: { variant: 'signin-phone' },
        style: () => ({
          '&:before': {
            borderBottom: '0px !important',
          },
          '&:after': {
            borderBottom: '0px !important',
          },
          fontWeight: 600,
          fontSize: '40px',
          lineHeight: '130%',
        }),
      },
    ],
  },
};

export default components;
