/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material/utils';

const InsuranceAlfaIcon = createSvgIcon(
  <svg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.6708 0H8.33178C3.73622 0 0 3.74798 0 8.35417V19.7267C0 24.3319 3.73622 28.0807 8.33178 28.0807H19.6708C24.2625 28.0807 28 24.3319 28 19.7267V8.35417C28 3.74798 24.2625 0 19.6708 0Z'
      fill='#DD1731'
    />
    <path
      d='M15.1344 19.2025C14.4378 20.2259 13.5283 20.7659 12.5027 20.7659C11.3088 20.7659 10.406 20.2291 9.7407 19.1202C9.04392 17.9594 8.69005 16.2192 8.69005 13.9477C8.69005 11.8651 9.06469 10.2101 9.8025 9.02795C10.4959 7.91829 11.3642 7.40075 12.5357 7.40075C13.5785 7.40075 14.3926 7.83533 15.1006 8.77151C15.8593 9.77631 16.5 11.4363 16.9594 13.5693C16.3831 16.4542 15.8191 18.1981 15.1344 19.2025ZM22.8392 17.4912L22.8294 17.6165C22.7894 18.1035 22.4848 18.5969 21.9795 18.5969C21.5931 18.5969 21.2823 18.4531 21.0291 18.1584C20.7246 17.8064 20.4502 17.3847 20.1733 16.6186C20.9588 13.9461 23.3809 5.38619 23.3809 5.38619H18.7839C18.7839 5.38619 18.3592 7.39331 17.9761 9.21935C17.9571 9.17515 17.9375 9.13045 17.9175 9.0888C17.5345 8.26835 16.923 7.35522 16.243 6.71357C15.0586 5.59693 13.6662 5.05324 11.9846 5.05324C9.66379 5.05324 7.70025 5.91422 6.1485 7.61261C4.604 9.3012 3.78879 11.4673 3.78879 13.8757C3.78879 16.5592 4.56359 18.7629 6.09075 20.4233C7.68464 22.1584 9.58943 22.9661 12.0851 22.9661C13.5389 22.9661 14.9268 22.5328 16.2134 21.6796C17.0447 21.1293 17.8034 20.3671 18.5197 19.3768C18.8211 20.3543 19.1242 21.0223 19.4866 21.5218C20.0738 22.3287 20.9533 22.7919 21.8987 22.7919C22.8829 22.7919 23.7795 22.2858 24.2402 21.4727C24.6481 20.7508 24.8145 19.8561 24.8145 18.3845V17.4912H22.8392Z'
      fill='white'
    />
  </svg>,
  'InsuranceAlfaIcon',
);

export default InsuranceAlfaIcon;
