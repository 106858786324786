/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const WalletEmpty = createSvgIcon(
  <svg width='100%' height='100%' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127C98.7939 127 127 98.7939 127 64C127 29.2061 98.7939 1 64 1C29.2061 1 1 29.2061 1 64C1 98.7939 29.2061 127 64 127Z'
      fill='#F5F5F5'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M29.5 39.6V88.4C29.5 90.0178 30.1505 91.5694 31.3083 92.7134C32.4661 93.8574 34.0365 94.5 35.6739 94.5H97.4131C98.2318 94.5 99.017 94.1787 99.5959 93.6067C100.175 93.0347 100.5 92.2589 100.5 91.45V48.75C100.5 47.9411 100.175 47.1653 99.5959 46.5934C99.017 46.0214 98.2318 45.7 97.4131 45.7H35.6739C34.0365 45.7 32.4661 45.0574 31.3083 43.9134C30.1505 42.7694 29.5 41.2179 29.5 39.6C29.5 37.9822 30.1505 36.4307 31.3083 35.2867C32.4661 34.1427 34.0365 33.5 35.6739 33.5H88.1522'
      fill='#F5F5F5'
    />
    <path
      d='M83.5218 74.675C86.0791 74.675 88.1522 72.6267 88.1522 70.1C88.1522 67.5733 86.0791 65.525 83.5218 65.525C80.9644 65.525 78.8913 67.5733 78.8913 70.1C78.8913 72.6267 80.9644 74.675 83.5218 74.675Z'
      fill='#F5F5F5'
    />
    <path
      d='M29.5 39.6V88.4C29.5 90.0179 30.1505 91.5694 31.3083 92.7134C32.4661 93.8574 34.0365 94.5 35.6739 94.5H97.4131C98.2318 94.5 99.017 94.1787 99.5959 93.6067C100.175 93.0347 100.5 92.2589 100.5 91.45V48.75C100.5 47.9411 100.175 47.1653 99.5959 46.5934C99.017 46.0214 98.2318 45.7 97.4131 45.7H35.6739C34.0365 45.7 32.4661 45.0574 31.3083 43.9134C30.1505 42.7694 29.5 41.2179 29.5 39.6ZM29.5 39.6C29.5 37.9822 30.1505 36.4307 31.3083 35.2867C32.4661 34.1427 34.0365 33.5 35.6739 33.5H88.1522M88.1522 70.1C88.1522 72.6267 86.0791 74.675 83.5218 74.675C80.9644 74.675 78.8913 72.6267 78.8913 70.1C78.8913 67.5733 80.9644 65.525 83.5218 65.525C86.0791 65.525 88.1522 67.5733 88.1522 70.1Z'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>,
  'WalletEmpty',
);

export default WalletEmpty;
