import { EBank } from './bank.dto';
import { EContractStatus, EContractType } from './contract.dto';
import { ECMortgageEntryParams } from './mortgage/mortgage.schema';

export enum EMortgageEntryRegion {
  moscow = 'moscow',
  moscowRegion = 'moscowRegion',
  stPetersburg = 'stPetersburg',
  leningradObl = 'leningradObl',
  otherRegions = 'otherRegions',
}

export enum EMortgageEntryInitialPaySourceType {
  savings = 'savings',
  maternityCapital = 'maternityCapital',
  subsidium = 'subsidium',
  loan = 'loan',
  other = 'other',
}

export enum EMortgageEntryPurpose {
  finishedRealEstate = 'finishedRealEstate',
  realEstateUnderConstruction = 'realEstateUnderConstruction',
  realEstateEquityLoan = 'realEstateEquityLoan',
  refinancing = 'refinancing',
  mortgageWith2Documents = 'mortgageWith2Documents',
  stateProgramForFamiliesWithChildren = 'stateProgramForFamiliesWithChildren',
  stateProgramForPurchasingNewBuildings = 'stateProgramForPurchasingNewBuildings',
  stateProgramForITSSpecialists = 'stateProgramForITSSpecialists',
  stateProgramFarEasternMortgage = 'stateProgramFarEasternMortgage',
  stateProgramsRuralMortgage = 'stateProgramsRuralMortgage',
  stateProgramMortgageForMilitary = 'stateProgramMortgageForMilitary',
}

export enum EMortgageEntryObjectType {
  flat = 'flat',
  apartments = 'apartments',
  other = 'other',
}

export interface IMortgageEntryParamsIns {
  property: boolean;
  life: boolean;
  title: boolean;
}

export interface IMortgageInitialPaymentSource {
  type: EMortgageEntryInitialPaySourceType;
  amount: number;
  otherSourceName?: string;
}

export interface IMortgageEntryParams {
  insurance: IMortgageEntryParamsIns;

  mortgagePurpose: EMortgageEntryPurpose;
  mortgageRegion: EMortgageEntryRegion;

  objectType: EMortgageEntryObjectType;
  otherObjectType?: string;
  objectRegion: EMortgageEntryRegion;
  objectPrice: number;

  initialPayment: number;
  // im months
  mortgagePeriod: number;

  initialPaymentSources: IMortgageInitialPaymentSource[];
}

export enum EMortgageEntryRole {
  borrower = 'borrower',
  coBorrower = 'coBorrower',
  guarantor = 'guarantor',
  agent = 'agent',
}

export interface IMortgageEntryUser {
  uuidUser: string;
  role: EMortgageEntryRole;
}
export enum EMortgageEntryStatus {
  awaiting = 'awaiting',
  lookup = 'lookup',
  verification = 'verification',
  completed = 'completed',
  rejected = 'rejected',
  cancelled = 'cancelled',
  deleted = 'deleted',
}

export enum EMortgageEntryStage {
  questionnaire = 'questionnaire',
  bank = 'bank',
  realEstate = 'realEstate',
  insurance = 'insurance',
  deal = 'deal',
}
export enum EMortgageEntryProposeDecisionStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
  reworkRequired = 'reworkRequired',
  failed = 'failed',
}
export enum EMortgageEntryProposeStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
  reworkRequired = 'reworkRequired',
  failed = 'failed',
  withoutSuitablePrograms = 'withoutSuitablePrograms',
}

export interface IMortgageEntryProposeDecision {
  status?: EMortgageEntryProposeDecisionStatus;
  receivedAt?: number;
  // saved as rub * 100
  approvedSum?: number;
  // in month
  creditDuration?: number;
  // percent
  rate?: number;
  initialFee: number | null;
  additionalConditions: string | null;
  monthlyPayment?: number;
}

export interface IMortgageEntryPropose<T = unknown> {
  uuid: string;
  createdAt: number;
  bank: EBank;
  status: EMortgageEntryProposeStatus;
  internalBankData?: T;
  decision?: IMortgageEntryProposeDecision | null;
}

export interface IMortgageEntryEntity {
  uuid: string;
  currentStage: EMortgageEntryStage;

  excludedProviders: EBank[];
  dates: {
    createdAtTs: number;
    updatedAtTs: number;
  };
  users: IMortgageEntryUser[];
  status: EMortgageEntryStatus;
  stage: EMortgageEntryStage;
  proposes?: IMortgageEntryPropose[];
  params: ECMortgageEntryParams;
  stagesStatus: ECMortgageEntryStage;

  primaryPropose: IMortgageEntryPrimaryPropose | null;
  // TODO: participants
}
export interface IMortgageEntryPrimaryPropose {
  uuid: string;
  bank: EBank;
  status: EMortgageEntryProposeStatus;
  decision: IMortgageEntryProposeDecision | null;
}
export interface IMortgageEntryPDFResponse {
  link: string;
}
export interface IInsMortgageProgress {
  baseData: number;
  company: number;
  creditContract: number;
  payment: number;
  personalInsurance?: number;
  program: number;
  propertyInsurance?: number;
  titleInsurance?: number;
}
export interface IRealEstateProgress {
  info: number;
  documents: number;
  valuation: number;
  approvals: number;
  //propertyInsurance: number;
}
export interface IDealProgress {
  manager: number;
  appointment: number;
  dealMaking: number;
  dealRegistration: number;
}
export interface IBankProgress {
  complete: number;
  proposes?: IMortgageEntryPropose[];
}

export interface IProfileProgressMortgage {
  personal: number;
  family: number;
  incomesList: number;
  incomes: number;
  expenses: number;
  actives: number;
  documents: number;
  questionnaire: number;
  verification: number;
  signing: number;
}
export interface IProfileProgressContract {
  uuid: string;
  status: EContractStatus;
  type: EContractType;
  signedFileLink?: string;
}
export interface IProfileProgressUserData {
  uuid: string;
  ids: { uuidUser: string; uuidAgent?: string };
  firstname: string;
  lastname: string;
  patronymic: string;
  role: EMortgageEntryRole;
  progress: IProfileProgressMortgage;
  contracts: Array<IProfileProgressContract>;
  experiments: { name: string; status: boolean; params: Record<string, string> }[];
}
export interface IMortgageEntryProgress {
  profile: IProfileProgressUserData[];
  bank: IBankProgress;
  realEstate: IRealEstateProgress;
  insuranceMortgage: IInsMortgageProgress;
  deal: IDealProgress;
}

export enum EInsMortgagePackageCompany {
  alfastrah = 'alfastrah',
  ingosstrah = 'ingosstrah',
  resoGarant = 'resoGarant',
}
export enum EInsPropPackageCompany {
  alfastrah = 'alfastrah',
  ingosstrah = 'ingosstrah',
  resoGarant = 'resoGarant',
}

export interface IInsMortgagePackageDesc {
  title?: string;
  body: string;
}

export interface InsuranceProfile {
  uuid: string;
  dates: Dates;
  ids: InsuranceProfileIDS;
  firstname: string;
  lastname: string;
  patronymic: string;
  gender: string;
  birth: Birth;
  citizenship: Citizenship[];
  contacts: Contact[];
  documents: Document[];
  education: Education[];
  family: Family;
  sign: Sign;
  addresses: Address[];
  incomes: Income[];
  workplaces: Workplace[];
  expenses: Expense[];
  actives: Active[];
  health: Health;
  questionnaire: Questionnaire;
}

export interface Active {
  uuid: string;
  type: string;
  data: ActiveData;
}

export interface ActiveData {
  type?: string;
  brand?: string;
  year?: number;
  estimatedValue?: number;
  isPledged?: boolean;
  amount?: number;
  currency?: string;
  address?: Address;
  marketPrice?: number;
  ownershipShare?: number;
  ownershipBasis?: string;
  purchaseYear?: number;
}

export interface Address {
  uuid: string;
  type: string;
  zipCode: string;
  ids: AddressIDS;
  country: Country;
  geo: Geo;
  region: string;
  federalDistrict: string;
  city: string;
  district: string;
  street: string;
  house: string;
  buildingNumber: string;
  flat: string;
  fullAddress: string;
  externalFormat: ExternalFormat;
  floor: string;
  totalAreaSize: number;
  houseBuildYear: number;
}

export interface Country {
  formatted: string;
  isoCode: string;
}

export interface ExternalFormat {
  dadata: Dadata;
}

export interface Dadata {
  postalCode: string;
  fiasCode: string;
  country: string;
  countryIsoCode: string;
  geoLat: string;
  geoLon: string;
  region: string;
  federalDistrict: string;
  city: string;
  cityDistrict: string;
  street: string;
  house: string;
  houseKladrId: string;
  houseCadnum: string;
  kladrId: string;
  block: string;
  flat: string;
  flatCadnum: string;
  flatArea: string;
  formatted: string;
  source: string;
}

export interface Geo {
  lat: string;
  lon: string;
}

export interface AddressIDS {
  fias: string;
  kladr: string;
  cadNumFlat: string;
  cadNumHouse: string;
}

export interface Birth {
  date: string;
  place: string;
  country: string;
}

export interface Citizenship {
  uuid: string;
  type: string;
  expireType: string;
  date: string;
  data: string;
  country: string;
  taxResidence: string;
}

export interface Contact {
  uuid: string;
  type: string;
  name: string;
  value: number | string;
}

export interface Dates {
  createdAtTs: number;
  updatedAtTs: number;
}

export interface Document {
  uuid: string;
  status: string;
  type: string;
  links: Link[];
  data: DocumentData;
}

export interface DocumentData {
  value?: string;
  docNo: number;
  docSeries: number;
  issueDate: string;
  issueUnit: string;
  issuedBy: string;
  birthPlace: string;
  birthCountry: string;
  validTill: string;
}

export interface Link {
  part: string;
  src: string;
}

export interface Education {
  uuid: string;
  type: string;
  data: string;
}

export interface Expense {
  uuid: string;
  type: string;
  data: ExpenseData;
}

export interface ExpenseData {
  creditor?: string;
  creditType?: string;
  totalLoanAmount?: number;
  currency?: string;
  startDate?: string;
  endDate?: string;
  monthlyPayment: number;
  leftToPay?: number;
  expenseName?: string;
}

export interface Family {
  status: string;
  marriageContractStatus: string;
  dependants: Dependant[];
}

export interface Dependant {
  uuid: string;
  type: string;
  birthDate: string;
  livingWith: string;
  citizenship: string;
  haveDisability: boolean;
}

export interface Health {
  profession: string;
  creditAmount: number;
  physicalIndicators: PhysicalIndicators;
  badHabits: BadHabits;
  risks: Risks;
}

export interface BadHabits {
  smokedCigarettes: number;
  alcoholLess10Percents: number;
  alcoholFrom10to40Percents: number;
  alcoholOver40Percents: number;
}

export interface PhysicalIndicators {
  height: number;
  weight: number;
  upArteriotony: number;
  downArteriotony: number;
}

export interface Risks {
  healthProblems: boolean;
  doctorVisitsOrPlans: boolean;
  disabilityGroup: string;
  cancerTumorsCardiovascularDisease: boolean;
  dispensaryRegister: boolean;
  organDefects: boolean;
  hivAndAidsTestResults: string;
  takingAntidepressants: boolean;
  psychosocialDisabilities: boolean;
  pestilentActivity: boolean;
  dangerousSports: boolean;
  covidLastYear: string;
  covidVaccineLastYear: string;
}

export interface InsuranceProfileIDS {
  uuidUser: string;
  uuidAgent: string;
}

export interface Income {
  uuid: string;
  documentUuid: string;
  type: string;
  period: string;
  amount: number;
  confirmationDocType: string;
  confirmationDocData: ConfirmationDocData;
  confirmationOtherDoc: string;
}

export interface ConfirmationDocData {
  incomeTax: number;
  firstDateConfirm: string;
  lastDateConfirm: string;
  issueDate: string;
}

export interface Questionnaire {
  useLoanFinances: boolean;
  jobChangeOrLongWorkTrip: boolean;
  maternityLeave: boolean;
  relativeOrEmployeeReceivingLoan: boolean;
  politicallyExposedPerson: boolean;
  connectionPoliticallyExposedPerson: boolean;
  taxpayerInUsa: boolean;
  taxResidentForeign: boolean;
  permissionToStayNotInRf: boolean;
  longPermissionToStayNotInRf: boolean;
  consentToAssignation: boolean;
  consentToExecutoryEndorsement: boolean;
  relationshipWithBorrower: string;
  relationshipWithBorrowerOther: string;
  primaryEducation: string;
  primaryIdentityDoc: string;
  haveAdditionalIdentityDoc: boolean;
  additionalIdentityDoc: string;
  haveOfficialIncome: boolean;
  workExpYearStart: number;
  workExpYearEnd: number;
  workExperienceYears: number;
  registrationPlaceEqualsLivingPlace: boolean;
  haveOfficialEmploymentContract: boolean;
  haveAlterationOfName: boolean;
  haveExpenseCredit: boolean;
  haveExpenseAlimony: boolean;
  haveExpenseRent: boolean;
  haveExpenseCourtDecision: boolean;
  haveExpenseOther: boolean;
  haveFamilyChildren: boolean;
  haveFamilyOtherDependants: boolean;
  haveInfoAboutPreviousPassport: boolean;
  haveInfoAboutPreviousForeignPassport: boolean;
  hasProperty: boolean;
  hasVehicle: boolean;
  hasDepositations: boolean;
  hasOtherSavings: boolean;
  groundsForResidency: string;
  groundsForResidencyOther: string;
}

export interface Sign {
  url: string;
  addedAt: string;
}

export interface Workplace {
  isMain: boolean;
  uuid: string;
  type: string;
  company: Company;
  companySharePercent: number;
  contractType: string;
  contractStartDate: string;
  contractEndDate: string;
  contractPositionWorkField: string;
  contractPosition: string;
  contractPositionExpLevel: string;
  emplConfirmDocType: string;
  emplConfirmDocTypeOther: string;
  sphereOfWorkExpStartYear: number;
  workAddress: Address;
  income: Income;
}

export interface Company {
  status: string;
  name: string;
  inn: string;
  phone: number;
  website: string;
  kpp: string;
  ogrn: string;
  okved: string;
  ids: CompanyIDS;
  organizationType: string;
  management: Management;
  scopeOfWork: string;
  scopeOfWorkOther: string;
  bankUsedByCompany: string;
  employeeCount: number;
  address: string;
  registrationDate: string;
  source: string;
  taxationSchema: string;
  taxationSchemaOther: string;
}

export interface CompanyIDS {
  uuidDadata: string;
}

export interface Management {
  name: string;
  post: string;
}

// todo: we get the data for it dynamically, so we might not need an entity in our db
// but we still need to save the package in order, so we need an interface for that
export interface IInsMortgagePackageEntity {
  price: number;
  company: EInsMortgagePackageCompany;
  // description blocks like in figma
  description?: IInsMortgagePackageDesc[];
  // todo:
  providerApiData: any;
}

export interface IMortgageEntryCreateDTO {
  users: IMortgageEntryUser[];
  params: IMortgageEntryParams;
}

export interface IPublicMortgageEntryEntity extends Omit<IMortgageEntryEntity, 'proposes' | 'history'> {
  proposes?: TPublicMortgageEntryPropose[];
}

export type TPublicMortgageEntryPropose = Omit<IMortgageEntryPropose, 'errors' | 'internalBankData' | 'creationStep'>;

export enum EMortgageEntryStageProfileStatus {
  filling = 'filling',
  signing = 'signing',
  signed = 'signed',
}

export enum EMortgageEntryStageBankStatus {
  awaiting = 'awaiting',
  selected = 'selected',
  approved = 'approved',
}

export enum EMortgageEntryStageRealEstateStatus {
  filling = 'filling',
  awaiting = 'awaiting',
  approved = 'approved',
  rejected = 'rejected',
}

export enum EMortgageEntryStageInsuranceMortgageStatus {
  filling = 'filling',
  awaiting = 'awaiting',
  approved = 'approved',
  rejected = 'rejected',
  // TODO: pay
}

export enum EMortgageEntryStageDealStatus {
  awaiting = 'awaiting',
  approved = 'approved',
  rejected = 'rejected',
}
export interface IMortgageEntryStageProfileUser {
  uuidUser: string;
  // TODO: remove EMortgageEntryStatus
  status: EMortgageEntryStageProfileStatus | EMortgageEntryStatus;
}

export interface IMortgageEntryStageProfile {
  users: IMortgageEntryStageProfileUser[];
}
export interface IMortgageEntryStageBank {
  // TODO: remove EMortgageEntryStatus
  status: EMortgageEntryStageBankStatus | EMortgageEntryStatus;
}
export interface IMortgageEntryStageRealEstate {
  // TODO: remove EMortgageEntryStatus
  status: EMortgageEntryStageRealEstateStatus | EMortgageEntryStatus;
}
export interface IMortgageEntryStageInsurance {
  // TODO: remove EMortgageEntryStatus
  status: EMortgageEntryStageInsuranceMortgageStatus | EMortgageEntryStatus;
}
export interface IMortgageEntryStageDeal {
  // TODO: remove EMortgageEntryStatus
  status: EMortgageEntryStageDealStatus | EMortgageEntryStatus;
}
export interface ECMortgageEntryStage {
  profile?: IMortgageEntryStageProfile;
  bank?: IMortgageEntryStageBank;
  realEstate?: IMortgageEntryStageRealEstate;
  insurance?: IMortgageEntryStageInsurance;
  deal?: IMortgageEntryStageDeal;
}
