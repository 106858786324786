import { Box, IconButton, SxProps, Typography, useTheme } from '@mui/material';
import BackIcon from 'components/Icons/Back';
import { useNavigate } from 'react-router-dom';
import { ObjectType } from 'utils/iots';

interface IProps {
  backRoute?: string;
  title: string;
  subtitle?: string;
  sx?: SxProps;
  onBackEvent?: () => void;
}

const BaseButtonBack = ({ backRoute, title, subtitle, sx = {}, onBackEvent }: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: 1,
        minHeight: '48px',
        display: 'flex',
        margin: '16px 0',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'relative',
        ...sx,
      }}
    >
      <IconButton
        sx={{ position: 'absolute' }}
        aria-label='Back'
        onClick={() => {
          if (onBackEvent) {
            onBackEvent();
            return;
          }

          navigate((backRoute || -1) as ObjectType);
        }}
      >
        <BackIcon sx={{ width: '16px', color: `${theme.palette.text.secondary}` }} />
      </IconButton>
      <Box sx={{ width: 1 }}>
        {subtitle && (
          <Typography variant='h7' textAlign='center' sx={{ display: 'block', color: `${theme.palette.common.greyLight}` }}>
            {subtitle}
          </Typography>
        )}
        <Typography variant={subtitle ? 'h6' : 'h5'} textAlign='center' sx={{ padding: 0, display: 'block' }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default BaseButtonBack;
