import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Location, useLocation } from 'react-router-dom';
import ym, { YMInitializer } from 'react-yandex-metrika';
import utils from 'utils';

const { REACT_APP_YM_ACC: ymAccount, REACT_APP_JC_ACC: jivoChatCode, REACT_APP_WP_ID: widgetProfeatToken } = import.meta.env;

export const goal = (goal: string) => {
  if (!ymAccount) {
    console.log('YM:', `goal('${goal}')`);
    return;
  }
  ym('reachGoal', goal);
};

export const hit = (url: string) => {
  if (!ymAccount) {
    console.log('YM:', `hit('${url}')`);
    return;
  }
  ym('hit', url);
};

export const rehit = () => {
  hit(window.location.href);
};

const useLocationChange = (action: (location: Location) => void) => {
  const location = useLocation();
  React.useEffect(() => {
    action(location);
  }, [location]);
};

const Counters = () => {
  useLocationChange((location) => {
    hit(`${window.location.origin}${location.pathname}`);
  });

  return (
    <>
      {/* WidgetProfeatToken */}
      {widgetProfeatToken && !utils.env.isTesting() && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML:
                // eslint-disable-next-line max-len
                `(function () {
                    var widget = document.createElement('script');
                    widget.dataset.pfId = '${widgetProfeatToken}';
                    widget.src = 'https://widget.profeat.team/script/widget.js?id=${widgetProfeatToken}&now='+Date.now();
                    document.head.appendChild(widget);
                  })()`,
            },
          ]}
        />
      )}
      {/* JivoChat */}
      {jivoChatCode && !utils.env.isTesting() && (
        <Helmet>
          <script src={`//code.jivo.ru/widget/${jivoChatCode}`} async />
        </Helmet>
      )}
      {/* Yandex.Metrika */}
      {ymAccount && (
        <YMInitializer
          accounts={[ymAccount as unknown as number]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
          version='1'
        />
      )}
    </>
  );
};

export default Counters;
