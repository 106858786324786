import { AppBar, Box, IconButton, SvgIcon, Typography, useTheme } from '@mui/material';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import BackIcon from 'components/Icons/Back';
import UserIcon from 'components/Icons/User';
import CommonButtonNotification from 'components/common/Notification';
import { useAuth } from 'components/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { PATH_USER_GENERAL } from 'routes/paths';
import utils from 'utils';
import { IDataNameFormat } from 'utils/format/format';

function BackButton({ backRoute }: { backRoute?: string }) {
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <AppBar
      position='static'
      elevation={0}
      sx={{
        minHeight: '72px',
        background: theme.palette.background.paper,

        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        alignItems: 'center',
      }}
    >
      <IconButton
        onClick={() => {
          navigate((backRoute || -1) as number);
        }}
      >
        <BackIcon sx={{ width: '16px', color: `${theme.palette.text.secondary}` }} />
      </IconButton>
    </AppBar>
  );
}
export default function MobileMenu() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleNavigate = () => {
    navigate(PATH_USER_GENERAL);
  };
  const userContainer = (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px', padding: '16px 0', flexGrow: 1 }} onClick={handleNavigate}>
      <SvgIcon viewBox='0 0 40 40' component={UserIcon} sx={{ height: '40px', width: '40px' }} />
      <Typography>{utils.format.nameWithInitials(user as IDataNameFormat)}</Typography>
      <SvgIcon sx={{ height: '20px', marginTop: '3px' }} component={ArrowRightIcon} />
    </Box>
  );

  return (
    <AppBar
      position='static'
      elevation={0}
      sx={{
        minHeight: '72px',
        background: theme.palette.background.paper,

        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        alignItems: 'center',
      }}
    >
      {userContainer}
      <Box sx={{ padding: '25px 0' }}>
        <CommonButtonNotification sxIcon={{ height: '20px', width: '20px' }} />
      </Box>
    </AppBar>
  );
}

MobileMenu.BackButton = BackButton;
