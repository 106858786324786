import { Button, CircularProgress, Dialog, DialogTitle, SvgIcon, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { changeClientManager, getManagersList } from 'api/services/agent';
import { entryGet } from 'api/services/mortgage';
import { IPublicUser } from 'api/services/user.dto';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'components/Icons/Close';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  uuidEntry?: string;
  uuidUser?: string;
  isUuidRequest?: boolean;
}

export const ChangeClientManager = ({ uuidEntry, uuidUser, isOpen, onClose }: Props) => {
  const [managersList, setManagersList] = useState<IPublicUser[]>();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);

  const { t } = useTranslation();

  const getManagerList = async () => {
    const res = await getManagersList();
    if (res.ok) {
      setLoading(true);
      await setManagersList(res.body);
      setLoading(false);
    }
  };

  const attachManager = async (uuidPersonalManager: string) => {
    let uuid = uuidUser;
    try {
      if (uuidEntry) {
        const res = await entryGet(uuidEntry);
        const user = res.body.users.find((user) => user.role === 'borrower');
        uuid = user?.uuidUser;
      }
      if (!uuid) {
        throw new Error();
      }
      await changeClientManager(uuidPersonalManager, uuid);
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    getManagerList();
  }, []);

  useEffect(() => {
    setSuccess(false);
  }, [onClose]);

  const ManagersListModal = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        sx={{
          '&.MuiPaper-root': {
            borderRadius: '24px',
          },
        }}
      >
        <Fragment>
          <DialogTitle
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: '20px',
            }}
          >
            <Typography variant="h3b">
              {isSuccess
                ? t('interface:page.ChangeClientManager.modal.successTitle')
                : t('interface:page.ChangeClientManager.modal.defaultTitle')}
            </Typography>
          </DialogTitle>
          <SvgIcon
            component={CloseIcon}
            sx={{ position: 'absolute', right: '15px', top: '15px', width: '15px', height: '15px', cursor: 'pointer' }}
            onClick={onClose}
          />
          {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
          {!isLoading &&
            !isSuccess &&
            managersList &&
            managersList?.map((manager) => (
              <Box sx={{ padding: '10px', display: 'flex', gap: '15px', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  {manager.lastname} {manager.firstname} {manager.patronymic}
                </Box>
                <Button variant='contained' onClick={() => attachManager(manager.uuid)}>
                  {t('interface:page.ChangeClientManager.modal.button')}
                </Button>
              </Box>
            ))}
          {isSuccess && (
            <Box sx={{ padding: '10px', display: 'flex', gap: '15px', alignItems: 'center', justifyContent: 'space-between' }}>
              {t('interface:page.ChangeClientManager.modal.successMessage')}
            </Box>
          )}
          {isError && (
            <Box sx={{ padding: '10px', display: 'flex', gap: '15px', alignItems: 'center', justifyContent: 'center', color: 'red' }}>
              {t('interface:page.ChangeClientManager.modal.errorMessage')}
            </Box>
          )}
        </Fragment>
      </Dialog>
    );
  };

  return isOpen ? <ManagersListModal /> : null;
};
