/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const Sovcom = createSvgIcon(
  <svg viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' fill='white' />
    <path
      d='M8 24.4992C8 33.3368 15.1647 40.5 24.0005 40.5C24.2144 40.5 24.4282 40.4952 24.6415 40.485V34.4194H24.0005C18.5214 34.4194 14.0816 29.9789 14.0816 24.4992C14.0816 19.0195 18.5214 14.5806 24.0005 14.5806H24.6415V8.51497C24.4282 8.50802 24.2144 8.5 24.0005 8.5C15.1647 8.5 8 15.6632 8 24.4992Z'
      fill='#FC5055'
    />
    <path
      d='M27.1996 8.822V17.1394H24.0011C19.9349 17.1394 16.6386 20.4342 16.6386 24.4994C16.6386 28.5661 19.9349 31.8593 24.0011 31.8593H27.1996V40.1783C34.5059 38.6985 40 32.2421 40 24.4994C40 16.7588 34.5059 10.3034 27.1996 8.822Z'
      fill='#003790'
    />
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' stroke='#F5F5F5' />
  </svg>,
  'Sovcom',
);

export default Sovcom;
