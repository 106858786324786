import { IRequestResult, req } from '../http';

import { EPurchaseName, IPurchaseResponse, IPurchasesPaymentResponse } from './purchases.dto';

export const syncPaymentStatus = (uuidPurchase: string): Promise<IRequestResult<IPurchaseResponse>> => {
  return req<null, IPurchaseResponse>(`purchases/payment/${uuidPurchase}/status`, { method: 'GET' });
};

export const createPurchase = (name: EPurchaseName): Promise<IRequestResult<IPurchasesPaymentResponse>> => {
  return req<null, IPurchasesPaymentResponse>(`purchases/payment/${name}`, { method: 'POST' });
};
