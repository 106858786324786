/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const SuccessIcon = createSvgIcon(
  <svg viewBox='0 0 174 174' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle opacity='0.5' cx='87' cy='87' r='87' fill='#FEFCE8' />
    <circle cx='87' cy='87' r='72' fill='white' />
    <path
      d='M120.469 67.357C121.219 66.5456 121.168 65.2803 120.357 64.5308C119.546 63.7814 118.28 63.8316 117.531 64.643L120.469 67.357ZM80.2068 108L78.7508 109.371C79.131 109.775 79.6618 110.003 80.2165 110C80.7711 109.997 81.2997 109.764 81.676 109.357L80.2068 108ZM59.4561 83.046C58.6988 82.2419 57.4331 82.2038 56.6289 82.9611C55.8247 83.7183 55.7867 84.984 56.5439 85.7882L59.4561 83.046ZM117.531 64.643L78.7376 106.643L81.676 109.357L120.469 67.357L117.531 64.643ZM81.6629 106.629L59.4561 83.046L56.5439 85.7882L78.7508 109.371L81.6629 106.629Z'
      fill='#F8E11A'
    />
  </svg>,
  'SuccessIcon',
);

export default SuccessIcon;
