import { Components } from '@mui/material';
import { Theme } from '@mui/system';

const components: Components<Theme> = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-disabled': {
          color: `${theme.palette.text.disabled}`,
          background: `${theme.palette.common.white}`,
        },
      }),
    },
  },
};

export default components;
