// import { Button, Skeleton, Typography, useTheme } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import { EContractType } from 'api/services/contract.dto';
import { userIssueContractSmsCode } from 'api/services/user';
import PhoneCodeInput from 'components/base/PhoneCodeInput';
import useTimer from 'hooks/useTimer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';

export type TLocationParams = {
  uuid: string;
};
type Props = {
  handleNextBtnClick: (code: string) => void;
  contractType: EContractType;
};
const SmsVerify = ({ contractType, handleNextBtnClick }: Props) => {
  const { t } = useTranslation();
  // const theme = useTheme();
  const [code, setCode] = useState<string>('');

  const timer = useTimer(30, 'desc');

  const onSuccess = () => {
    handleNextBtnClick(code);
  };

  const handleResendCode = async () => {
    await userIssueContractSmsCode(contractType);
    timer.handleStart();
  };
  useEffect(() => {
    userIssueContractSmsCode(contractType).then(() => {
      timer.handleStart();
    });
  }, [contractType]);

  useEffect(() => {
    if (code.length > 6) onSuccess();
  }, [code]);

  useEffect(() => {
    if (timer.timer === 0) {
      timer.handleReset();
    }
  }, [timer.timer]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <PhoneCodeInput
        onChange={(e) => {
          setCode(e.target.value);
        }}
        value={code}
      />
      <Box sx={{ paddingTop: '56px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {timer.isPaused && (
          <Button sx={{ color: 'common.link' }} onClick={handleResendCode}>
            {t('interface:base.SmsVerify.resendCode')}
          </Button>
        )}
        {!timer.isPaused && timer.timer > 0 && (
          <>
            <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{t('interface:base.SmsVerify.resendCode')}</Typography>
            <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
              {t('interface:base.SmsVerify.waitResend', { count: timer.timer })}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SmsVerify;
