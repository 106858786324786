import { Box, SxProps } from '@mui/material';

interface IProps {
  image: {
    src: string;
    sx?: SxProps;
  };
  boxSx?: SxProps;
  sx?: SxProps;
  children?: React.ReactChild;
}

const PictureBox = (props: IProps) => {
  const { image } = props;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto', ...(props.sx || {}) }}>
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: '#F8E11A',
          marginTop: '50px',
          height: '550px',
          width: '554px',
          borderRadius: '117px',
          boxShadow: '30px 7px 75px rgba(255, 229, 0, 0.25), inset -43px -27px 81px rgba(0, 0, 0, 0.1)',

          ...(props.boxSx || {}),
        }}
      />
      <Box
        sx={{
          backgroundImage: `url(${image.src})`,
          backgroundSize: '130%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          height: '680px',
          width: '600px',
          borderRadius: '117px',
          zIndex: 2,
          ...(image.sx || {}),
        }}
      />
      {props.children && props.children}
    </Box>
  );
};

export default PictureBox;
