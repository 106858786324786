import { Box } from '@mui/material';
import HomeEmpty from 'components/Icons/HomeEmpty';
import BaseListEmpty from 'components/base/List/Empty';
import Page from 'components/layouts/Page';
import { useTranslation } from 'react-i18next';

export default function AgentMortgagePropertyIncomesPage() {
  const { t } = useTranslation();
  return (
    <Page
      title={t('helmet:AgentMortgagePropertyIncomesPage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentMortgagePropertyIncomesPage.description') }]}
      header={{ type: 'withNotification', title: t('helmet:AgentMortgagePropertyIncomesPage.title') }}
      sx={{ height: 1 }}
    >
      <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <BaseListEmpty
          customIcon={<HomeEmpty sx={{ width: '128px', height: '128px', mb: 3 }} />}
          title={t('interface:page.AgentMortgagePropertyIncomesPage.title')}
        />
      </Box>
    </Page>
  );
}
