import { IBusinessCreditCompanyUuidDTO } from 'api/services/businessCredit/businessCredit.dto';
import { EBusinessCreditEntryDocumentType } from 'api/services/businessCredit/businessCredit.enum';
import { EProfileDocumentType, IExperimentQueryParams, IPreloadDocument } from 'api/services/experiment/experiment.dto';
import creditServiceHook from 'hooks/logic/useDocuments/creditServiceHook';
import mortgageServiceHook from 'hooks/logic/useDocuments/mortgageServiceHook';
import { MortgageDocumentService, MsbDocumentService } from 'hooks/logic/useDocuments/service';
import { UseFileManager, UseRequiredDocuments } from 'hooks/logic/useDocuments/types';
import { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type DocumentContextProps = UseFileManager &
  UseRequiredDocuments & {
    typesOfFiles: { type: EProfileDocumentType | EBusinessCreditEntryDocumentType; label: string }[];
    docTranslation: Record<string, string>;
    expanded: EProfileDocumentType;
    expandDocument: (value: EProfileDocumentType | undefined) => void;
    queue: IPreloadDocument[];
  };

export const DocumentContext = createContext<Partial<DocumentContextProps>>({});

function DocumentProviderMortgage({ children }: { children: React.ReactChild }) {
  const [expanded, expandDocument] = useState<EProfileDocumentType>();
  const { t } = useTranslation();

  const pathParams = useParams() as unknown as IExperimentQueryParams;
  const requiredDocuments = mortgageServiceHook.useRequiredDocuments<IExperimentQueryParams>(pathParams, MortgageDocumentService);
  const fileManager = mortgageServiceHook.useFileManager<IExperimentQueryParams>(
    pathParams,
    requiredDocuments.loadAsync,
    MortgageDocumentService,
  );

  const typesOfFiles = useMemo(() => {
    const result = [];
    for (const document of requiredDocuments.requiredDocuments) {
      if (document.type === EProfileDocumentType.passport) {
        continue;
      }
      const label =
        document?.quarter === null
          ? t(`common:documents.${document.type}.title`, document)
          : t(`common:documents.${document.type}.title_quarter`, document);
      result.push({ type: document.type, label: label });
    }
    result.push({ type: EProfileDocumentType.passport, label: t('common:documents.passport.title') });

    return result;
  }, [fileManager.documents, requiredDocuments.requiredDocuments]);

  return (
    <DocumentContext.Provider value={{ ...requiredDocuments, ...fileManager, typesOfFiles, expanded, expandDocument }}>
      {children}
    </DocumentContext.Provider>
  );
}
function DocumentProviderBusiness({ children }: { children: React.ReactChild }) {
  const { t } = useTranslation();

  const pathParams = useParams() as unknown as IBusinessCreditCompanyUuidDTO;
  const requiredDocuments = creditServiceHook.useRequiredDocuments<IBusinessCreditCompanyUuidDTO>(pathParams, MsbDocumentService);
  const fileManager = creditServiceHook.useFileManager<IBusinessCreditCompanyUuidDTO>(
    pathParams,
    requiredDocuments.loadAsync,
    MsbDocumentService,
  );

  const typesOfFiles = useMemo(() => {
    const result = [];
    for (const document of requiredDocuments.requiredDocuments) {
      if (document.type === EProfileDocumentType.passport) {
        continue;
      }
      const label =
        document?.quarter === null
          ? t(`common:documents.${document.type}.title`, document)
          : t(`common:documents.${document.type}.title_quarter`, document);
      result.push({ type: document.type, label: label });
    }
    result.push({ type: EProfileDocumentType.passport, label: t('common:documents.passport.title') });

    return result;
  }, [fileManager.documents, requiredDocuments.requiredDocuments]);

  return (
    <DocumentContext.Provider value={{ ...requiredDocuments, ...fileManager, typesOfFiles, queue: [] }}>
      {children}
    </DocumentContext.Provider>
  );
}
export default { DocumentProviderBusiness, DocumentProviderMortgage };

export function useDocumentService() {
  return useContext(DocumentContext) as unknown as DocumentContextProps;
}
