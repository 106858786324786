import { InputField } from 'pages/Client/Mortgage/Entry/Insurance/components/InputField';
import { SelectField } from 'pages/Client/Mortgage/Entry/Insurance/components/SelectField';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectFieldOtherProps {
  prefix: string;
  label: string;
  otherLabel: string;
  answers: string[];
  required?: boolean;
  defaultValue?: string;
  defaultOtherValue?: string;
}

export const SelectFieldOther: FC<SelectFieldOtherProps> = ({
  prefix,
  label,
  answers,
  defaultValue,
  required = false,
  otherLabel = '',
  defaultOtherValue,
}) => {
  const hookForm = useFormContext();

  const otherValue = hookForm.watch(label);

  const answersWithOther = [...answers, 'other'];

  return (
    <>
      <SelectField prefix={prefix} label={label} answers={answersWithOther} defaultValue={defaultValue} required={required} />
      {otherValue === 'other' && <InputField prefix={prefix} label={otherLabel} defaultValue={defaultOtherValue} />}
    </>
  );
};
