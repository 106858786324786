import Uploader from 'components/base/Uploader';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

import { FormSchemeData } from '../../form.types';
import { HookForm } from '../../types';

interface MutilFileFieldProps {
  node: FormSchemeData;
  hookForm: HookForm;
  path?: string[];
}

const MutilFileField = ({ node, hookForm, path = [] }: MutilFileFieldProps) => {
  const { control, watch, setValue } = hookForm;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  const handleFileUpload = (files?: string[]) => {
    // TODO: Temp hack for form fill nulls
    setValue(path.join('.'), files?.join(';'));
  };

  return (
    <Controller
      name={path.join('.')}
      control={control}
      defaultValue=''
      render={() => (
        <>
          <Uploader
            data-testid={path.join('.')}
            fileLink={node?.value}
            onChange={handleFileUpload}
            allowDownload
            allowDelete
            allowInfo={!!node?.settings?.info}
            infoDescription={node?.settings?.info}
            path={path as string[]}
            labelUploaded={'interface:base.UploadButton.labelUploaded'}
          />
        </>
      )}
    />
  );
};

export default MutilFileField;
