import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import { Accordion, AccordionSummary, Box, Container, Grid, Link, Skeleton, SvgIcon, Typography, useTheme } from '@mui/material';
import Rosbank from 'components/Icons/Rosbank';
import { useEffect, useState } from 'react';
import utils from 'utils';

export default function () {
  const [step, setStep] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => step + 1);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const theme = useTheme();
  const fadeAnimation = (v: number) => ({
    '@keyframes myAnim': {
      '0%': { opacity: 0, transform: 'translateY(50px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    animation: `myAnim ${v}s ease-in 0s 1 normal forwards`,
    width: 1,
  });
  return (
    <Box sx={{ height: 1, width: 1, marginTop: '40px' }}>
      <Container sx={{ width: 1, display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box>
          <Typography sx={{ color: theme.palette.text.secondary }}>Квартира</Typography>
          <Typography>ул. Профсоюзная, 73, кв. 12</Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.text.secondary }}>Оценка стоимости</Typography>
          {step < 2 ? <Skeleton height='30px' /> : <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>10 060 000 ₽</Typography>}
        </Box>

        {step >= 3 && (
          <Box sx={fadeAnimation(1)}>
            <Accordion
              data-testid={`credit-params`}
              key={`credit-params`}
              sx={{ width: 1, background: theme.palette.success.light, borderRadius: '16px', boxShadow: 'none', '&:before': { height: 0 } }}
            >
              <AccordionSummary
                sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0', alignItems: 'center' } }}
              >
                <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '12px' }}>
                  <Typography variant='h4m' sx={{ fontSize: '14px' }}>
                    Объект одобрен
                  </Typography>
                  <SvgIcon
                    sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }}
                    color={'success'}
                    component={DoneIcon}
                  />
                </Box>

                <SvgIcon sx={{ height: '12px', width: '11px' }} htmlColor='#ABC6A7' component={ArrowForwardIosIcon} />
              </AccordionSummary>
            </Accordion>
          </Box>
        )}

        {step >= 5 && (
          <Typography sx={{ fontSize: '18px', fontWeight: 500, margin: '10px 0px 0px 0px' }}>Финальное предложение банка</Typography>
        )}
        {step >= 6 && (
          <Box sx={fadeAnimation(1)}>
            <Box sx={{ padding: '20px', border: '1px solid #D2D2CF', borderRadius: '16px' }}>
              <Grid container direction='row' spacing={1}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <SvgIcon component={Rosbank} sx={{ height: '40px', width: '40px' }} />
                  <Typography>РОСБАНК</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>
                    Ставка
                  </Typography>
                  <Typography fontWeight={500} variant='h2' fontSize='18px'>
                    {utils.format.percent(1020)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6' sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }}>
                    Взнос
                  </Typography>
                  <Typography fontWeight={500} variant='h2' fontSize='18px'>
                    {utils.format.price(2600000)}
                  </Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6' sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }}>
                    Сумма кредита
                  </Typography>
                  <Typography fontWeight={500} variant='h2' fontSize='18px'>
                    {utils.format.price(2600000)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6' sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }}>
                    Срок кредита
                  </Typography>
                  <Typography fontWeight={500} variant='h2' fontSize='18px'>
                    240 мес.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6' sx={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }}>
                    Ежемесячный платеж
                  </Typography>
                  <Typography fontWeight={500} variant='h2' fontSize='24px'>
                    от 72 394 ₽
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        {step >= 7 && (
          <Box sx={{ marginTop: '20px', width: 1, display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <Link>Обсудить с консультантом</Link>
          </Box>
        )}
      </Container>
    </Box>
  );
}
