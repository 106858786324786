import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';
import { PATH_HOME } from 'routes/paths';
import BankGuaranteeCreateFormMobile from './components/Form/index.mobile';
import { Container } from '@mui/material';
//@ts-ignore
const T_PREFIX = 'interface:page.BankGuaranteeEntryCreatePage';

export default function BankGuaranteeCreatePage() {
  const { t } = useTranslation();
  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreatePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreatePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntryCreatePage.title')} />,
        lg: (
          <TopMenu
            sx={{ zIndex: 9999 }}
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },

              { title: 'Параметры гарантии', activeLink: true },
            ]}
          />
        ),
      }}
      sx={{ height: 1, width: 1 }}
      maxWidth='xl'
    >
      <Container maxWidth='md'>
        <BankGuaranteeCreateFormMobile disabledFields={false} />
      </Container>
    </ContainerLayout>
  );
}
