import React, { createContext, useState } from 'react';
import { useContext } from 'react';
import { Fn } from 'utils/iots';

type FormControlsState = { hidden: boolean };

interface FormGeneratorContext {
  cleanState: () => void;
  setBackEvent: (action: Fn<void>) => void;
  backEvent: { action?: Fn<void> };
  formControls: FormControlsState;
  setFormControls: (state: FormControlsState) => void;
  redirect: (pathTo: string) => void;
  setRedirect: (action: (pathTo: string) => void) => void;
  setSaveForm: (action: Fn<void>) => void;
  saveForm?: () => void;
}

export const FormGeneratorContext = createContext<FormGeneratorContext | null>(null);

export default function FormGeneratorProvider({ children }: { children: React.ReactChild }) {
  const [redirectAction, setRedirect] = useState<{ action?: (pathTo: string) => void }>();
  const [backEvent, setbBackEvent] = useState<{ action?: Fn<void> }>({});

  const [saveFormAction, setSaveForm] = useState<{ action?: Fn<void> }>({});

  const [formControls, setFormControls] = useState({ hidden: false });
  const cleanState = () => {
    setbBackEvent({ action: undefined });
    setFormControls({ hidden: false });
  };

  const handleSetRedirect = (action: (pathTo: string) => void) => {
    setRedirect({ action });
  };
  const handleSetBackEvent = (action: Fn<void>) => {
    setbBackEvent({ action });
  };

  const handleRedirect = (pathTo: string) => {
    if (!redirectAction) {
      console.error('No available redirect action');
      return;
    }
    redirectAction?.action?.(pathTo);
  };

  return (
    <FormGeneratorContext.Provider
      value={{
        setRedirect: handleSetRedirect,
        redirect: handleRedirect,
        setBackEvent: handleSetBackEvent,
        cleanState,
        backEvent,
        setFormControls,
        formControls,
        setSaveForm: (action) => setSaveForm({ action }),
        saveForm: saveFormAction.action,
      }}
    >
      {children}
    </FormGeneratorContext.Provider>
  );
}

export function useFormLayoutContext() {
  return useContext(FormGeneratorContext) as FormGeneratorContext;
}
