/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const SurgutNefteGas = createSvgIcon(
  <svg viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' fill='white' />
    <path d='M32.6075 31.3273H15.3614V16.6721H32.6075V31.3273Z' fill='white' />
    <path
      d='M19.8517 27.6506L23.6629 27.6468C24.4 27.6468 25.0532 27.4442 25.5536 27.1035C26.0867 26.7611 26.487 26.2526 26.725 25.6758L28.686 20.3434L25.0081 20.3455C24.2757 20.3455 23.6241 20.4802 23.0931 20.823C22.5251 21.1641 22.0452 21.8064 21.9004 22.2834L19.8517 27.6506Z'
      fill='#FAB20B'
    />
    <path
      d='M41 15.5H17.9319C16.6608 15.5 14.9579 15.8632 13.5459 16.8495C12.2484 17.8011 11.9988 18.6821 11.4989 19.7033C11.4146 19.9063 11.2545 20.1786 11.1834 20.4178L8.0518 29.5095L7 32.5H30.7503C32.0192 32.4983 33.7238 32.1381 35.1372 31.15C36.4291 30.1989 36.6851 29.3149 37.1786 28.2976C37.2655 28.0924 37.4269 27.821 37.4984 27.5844L41 15.5578H40.9791L41 15.5ZM29.6039 24.6019L28.8161 26.5717C28.4789 27.3197 27.867 28.1349 27.4714 28.4072C26.7006 28.984 25.5977 29.4951 24.3947 29.4972L17.1075 29.504L19.0778 23.3981L19.866 21.4283C20.2029 20.6803 20.8139 19.8643 21.207 19.592C21.9828 19.0155 23.0815 18.5037 24.2849 18.5037L31.1488 18.496L29.6039 24.6019Z'
      fill='#173E7D'
    />
    <rect x='0.5' y='1' width='47' height='47' rx='23.5' stroke='#F5F5F5' />
  </svg>,
  'SurgutNefteGas',
);

export default SurgutNefteGas;
