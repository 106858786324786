import { Skeleton, Stack } from '@mui/material';

const SkeletonForm = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />

      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' height={35} />
    </Stack>
  );
};

export default SkeletonForm;
