/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const BankLogoDomrfIcon = createSvgIcon(
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='48' rx='24' fill='white' />
    <path
      d='M12.2889 41H11V37.8571H13.1297V38.4044H11.6288V39.0399H12.2889C12.9355 39.0399 13.3331 39.4465 13.3331 40.02C13.3331 40.594 12.9399 41 12.2889 41ZM12.2573 39.5875H11.6288V40.453H12.2573C12.5375 40.453 12.7047 40.2808 12.7047 40.02C12.7047 39.7642 12.5375 39.5875 12.2573 39.5875Z'
      fill='#211D1D'
    />
    <path
      d='M20.6225 41L20.4324 40.4441H19.2884L19.0938 41H18.4385L19.6096 37.8572H20.1023L21.2779 41H20.6225ZM19.8717 38.7842L19.4648 39.9274H20.2649L19.8717 38.7842Z'
      fill='#211D1D'
    />
    <path d='M28.2574 41V39.6843H27.1677V41H26.5395V37.8572H27.1677V39.1414H28.2574V37.8572H28.8861V41H28.2574Z' fill='#211D1D' />
    <path
      d='M34.4726 41V37.8572H35.1009V39.3493L36.141 37.8572H36.8918L35.811 39.3318L37 41H36.2316L35.1009 39.3579V41H34.4726Z'
      fill='#211D1D'
    />
    <path
      d='M30.8802 32.2088H36.9901V25.908H23.2416V32.2088H29.3515V33.7856H30.8802V32.2088ZM29.3515 30.6331H24.7653V27.4834H29.3515V30.6331ZM30.8802 27.4837H35.4613V30.635H30.8802V27.4837ZM11.0174 24.3312H36.9901V22.7555H11.0174V24.3312ZM35.4613 21.1811H36.9901V7H34.6995V7.00432L32.4077 9.36717V7H30.1133L26.2941 10.9392V21.1811H27.8215V11.7275L30.8802 8.57319V21.1811H32.4077V11.7275L35.4613 8.57319V21.1811ZM18.6567 21.1811H24.7653V7H18.6567V21.1811ZM20.1841 8.57319H23.2416V19.6042H20.1841V8.57319ZM11.0174 21.1811H17.128V7H14.8373L11.0174 10.9392V21.1811ZM12.5468 11.7275L15.6042 8.57319V19.6042H12.5468V11.7275ZM21.7135 25.908H11.0174V33.7856H12.5468V32.2088H21.7135V25.908ZM20.1841 30.6331H12.5468V27.4834H20.1841V30.6331Z'
      fill='#211D1D'
    />
  </svg>,
  'BankLogoDomrfIcon',
);

export default BankLogoDomrfIcon;
