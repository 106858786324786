import { req } from 'api/http';

import { EContractType } from './contract.dto';

export function getContractContentBy(type: EContractType) {
  return req<null, { content: string }>(`user/contract/${type}/content`, { method: 'GET' });
}

export function verfiyContract(uuidContract: string) {
  return req<null, boolean>(`user/contract/${uuidContract}/verify`, { method: 'POST' });
}

export function approveContract(uuidContract: string) {
  return req(`user/contract/${uuidContract}/approve`, { method: 'POST' });
}

export function declineContract(uuidContract: string) {
  return req(`user/contract/${uuidContract}/decline`, { method: 'POST' });
}
