import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import ScoringTable from './components/ScoringTable';
import { Fragment, MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getScoringResult, runScoring } from 'api/services/scoring';
import { EScoringTypeName, IScoringParams } from 'api/services/scoring.dto';
import RefreshIcon from '@mui/icons-material/Refresh';
interface Props {
  uuidUser: string;
  uuidEntry: string;
  expanded?: boolean;
}

export default function ScoringPanel(props: Props) {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadAsync = async () => {
    setLoading(true);
    try {
      const res = await getScoringResult(props as Required<Props>);
      setData(res.body);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  /**
   * Request all scorings wihtout Credit History
   * @param e
   */
  const requestScoring: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const scoringParams: IScoringParams = {
        uuidEntry: props.uuidEntry,
        uuidUser: props.uuidUser,
        scoringTypes: [
          EScoringTypeName.arrearages,
          EScoringTypeName.bankrupt,
          EScoringTypeName.canceledInn,
          EScoringTypeName.canceledPassport,
          EScoringTypeName.hasTerrorist,
          EScoringTypeName.employerFiledForBankruptcy,
          EScoringTypeName.employerBankrupt,
          EScoringTypeName.loanToValue,
        ],
      };
      await runScoring(scoringParams);
    } catch (err) {
    } finally {
      setLoading(false);
      await loadAsync();
    }
  };

  /**
   * Request Credit History scroing
   * @param e
   */
  const requestCreditHistoryScoring: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const scoringParams: IScoringParams = {
        uuidEntry: props.uuidEntry,
        uuidUser: props.uuidUser,
        scoringTypes: [EScoringTypeName.nbki],
      };
      await runScoring(scoringParams);
    } catch (err) {
    } finally {
      setLoading(false);
      await loadAsync();
    }
  };

  useEffect(() => {
    loadAsync();
  }, []);

  const handleRefreshBtnClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    await loadAsync();
  };

  return (
    <Accordion
      expanded={props.expanded}
      sx={{ border: '1px solid #D2D2CF', borderRadius: '8px', boxShadow: 'none', '&:before': { height: 0 } }}
    >
      <AccordionSummary sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0' } }}>
        <Fragment>
          <Typography variant='h4m'>{t('interface:base.ScoringPanel.title')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Button
              disabled={loading}
              variant="contained"
              data-testid='btnScoring'
              onClick={requestScoring}
              size="small"
              sx={{ fontSize: '16px' }}
            >
              {t('interface:base.ScoringPanel.run')}
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              data-testid='btnScoring'
              onClick={requestCreditHistoryScoring}
              size="small"
              sx={{ fontSize: '16px' }}
            >
              {t('interface:base.ScoringPanel.runCreditHistory')}
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              data-testid='btnScoring'
              onClick={handleRefreshBtnClick}
              size="small"
              sx={{ fontSize: '16px' }}
            >
              <RefreshIcon />
            </Button>
          </Box>
        </Fragment>
      </AccordionSummary>
      <AccordionDetails>
        <LinearProgress sx={{ height: '1px', marginBottom: '20px' }} variant={loading ? 'indeterminate' : 'determinate'} value={100} />
        {loading && !data ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Skeleton width="100%" height="400px" />
          </Box>
        ) : (
          <ScoringTable rows={data} />
        )}
      </AccordionDetails>
      <AccordionActions />
    </Accordion>
  );
}
