import { Container } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ClientRegisterForm from './component/ClientRegisterForm';

/**
 * @name AgentMortgagePropertyClientCreatePage
 *
 * @description that component is page to create client page
 * path for this '/agent/mortgage/client/create'
 * @constructor
 */
export default function AgentMortgagePropertyClientCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ContainerLayout
      title={t('helmet:AgentMortgagePropertyClientCreatePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentMortgagePropertyClientCreatePage.description') }]}
      header={{
        lg: (
          <TopMenu
            breadcrumbs={[
              {
                title: t('common:breadcrumbs.home'),
                href: '/',
              },
              { title: t('helmet:AgentMortgagePropertyClientCreatePage.title'), action: () => navigate(-1) },
              { title: t('page.AgentMortgagePropertyClientCreatePage.addClient'), activeLink: true },
            ]}
          />
        ),
        xs: (
          <BaseButtonBack
            subtitle={t('helmet:AgentMortgagePropertyClientCreatePage.title')}
            title={t('page.AgentMortgagePropertyClientCreatePage.addClient')}
          />
        ),
      }}
      maxWidth='xl'
    >
      <Container maxWidth='md' sx={{ padding: 0 }}>
        <ClientRegisterForm />
      </Container>
    </ContainerLayout>
  );
}
