/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material/utils';
// TODO: Provide colors from theme
const CheckboxCheckedIcon = createSvgIcon(
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='8' y='8' width='16' height='16' rx='2' fill='#F8E11A' />
    <path
      d='M20.7863 12.6178C21.1275 12.1835 21.0521 11.5549 20.6178 11.2137C20.1835 10.8725 19.5549 10.9479 19.2137 11.3822L20.7863 12.6178ZM14.5 19L13.7137 19.6178C13.9033 19.8591 14.1931 20 14.5 20C14.8069 20 15.0967 19.8591 15.2863 19.6178L14.5 19ZM12.7863 15.2004C12.4451 14.7661 11.8165 14.6906 11.3822 15.0319C10.9479 15.3731 10.8725 16.0017 11.2137 16.436L12.7863 15.2004ZM19.2137 11.3822L13.7137 18.3822L15.2863 19.6178L20.7863 12.6178L19.2137 11.3822ZM15.2863 18.3822L12.7863 15.2004L11.2137 16.436L13.7137 19.6178L15.2863 18.3822Z'
      fill='white'
    />
  </svg>,
  'CheckboxCheckedIcon',
);

export default CheckboxCheckedIcon;
