import { Box, Typography, useTheme } from '@mui/material';

interface IProps {
  'data-testid'?: string;
  label: string;
  text: string;
}

const BaseDetailBlock = ({ label, text, ['data-testid']: dataTestId }: IProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant={'h5'} sx={{ color: theme.palette.text.secondary, marginBottom: '4px' }}>
        {label}
      </Typography>

      <Typography variant={'h4'} data-testid={dataTestId}>
        {text ?? '-'}
      </Typography>
    </Box>
  );
};

export default BaseDetailBlock;
