import { Box, SxProps } from '@mui/material';

type IProps = {
  color: string;
  sx?: SxProps;
};

const StatusDot: React.FC<IProps> = ({ color, sx }: IProps) => {
  return (
    <Box
      component='span'
      sx={{
        height: '6px',
        width: '6px',
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
        ...(sx || {}),
      }}
    />
  );
};

export default StatusDot;
