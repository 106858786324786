import { Button, LinearProgress, Link, Paper, Radio, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { EUserRole } from 'api/services/user.dto';
import { useAuth } from 'components/providers/AuthProvider';
import useSupportChat from 'hooks/useSupportChat';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';
import utils from 'utils';
import { Nullable } from 'utils/iots';

const groupMapping: Record<string, { name: EUserRole; active: boolean }> = {
  buyer: {
    name: EUserRole.client,
    active: utils.env.is(import.meta.env.REACT_APP_ROLE_BUYER),
  },
  seller: {
    name: EUserRole.client,
    active: utils.env.is(import.meta.env.REACT_APP_ROLE_SELLER),
  },
  realtor: {
    name: EUserRole.agent,
    active: utils.env.is(import.meta.env.REACT_APP_ROLE_REALTOR),
  },
};

function CardRole({ role, onSelectRole, activeRole }: { role: string; onSelectRole: () => void; activeRole?: string }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Fragment>
      <Paper
        onClick={onSelectRole}
        elevation={0}
        sx={{
          borderWidth: '1.5px',
          borderStyle: 'solid',
          borderColor: role === activeRole ? theme.palette.primary.dark : theme.palette.common.greyLight,
          borderRadius: '8px',
          padding: '24px 24px 24px 6px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Radio checked={role === activeRole} />
        <div>
          <Typography display='block' variant='h3m'>
            {t(`interface:page.AuthRolePage.RoleSelector.${role}.title`)}
          </Typography>
          <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
            {t(`interface:page.AuthRolePage.RoleSelector.${role}.description`)}
          </Typography>
        </div>
      </Paper>
    </Fragment>
  );
}

const RoleSelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const supportChat = useSupportChat();
  const [selectedRole, selectRole] = useState<string>();

  const [error, setError] = React.useState<Nullable<string>>(null);
  const [loading, setLoading] = React.useState(false);

  const auth = useAuth();

  const handleSetRole = async () => {
    if (!selectedRole) {
      return;
    }
    const group = groupMapping[selectedRole as string];
    if (!group) {
      setError('logic.somethingWrong');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await auth?.setUserRole?.(group.name);
      navigate(PATH_HOME);
    } catch (err) {
      setError('logic.badConnection');
    } finally {
      setLoading(false);
    }
  };
  const handleSupportClick = () => {
    supportChat.open();
  };

  return (
    <>
      {loading && <LinearProgress sx={{ margin: '0 -20px', width: 1, position: 'fixed', top: 0 }} />}
      <Box sx={{ width: { xs: 1, sm: 1 }, height: 0.9, display: 'flex', flexDirection: 'column', gap: '32px' }}>
        {/* Header */}
        <Typography sx={{ width: 1, textAlign: 'left', fontSize: '32px', fontWeight: '700', marginBottom: '24px' }}>
          {t('interface:page.AuthRolePage.RoleSelector.title')}
        </Typography>
        {/* {} */}
        {/* Role buttons */}
        <Stack gap='16px'>
          {Object.keys(groupMapping).map(
            (role) =>
              groupMapping[role].active && (
                <CardRole
                  key={role}
                  activeRole={selectedRole}
                  role={role}
                  onSelectRole={() => {
                    selectRole(role);
                  }}
                />
              ),
          )}
        </Stack>

        <Button
          sx={{ marginTop: '41px' }}
          variant='contained'
          color='primary'
          fullWidth
          size='large'
          disabled={!selectedRole}
          onClick={() => {
            handleSetRole();
          }}
        >
          {t('interface:page.AuthRolePage.RoleSelector.btnConfirm')}
        </Button>
        <Link
          sx={{ cursor: 'pointer', paddingBottom: '50px' }}
          textAlign='center'
          variant='h4'
          color={theme.palette.common.link}
          underline='none'
          onClick={handleSupportClick}
        >
          {t('interface:page.AuthRolePage.RoleSelector.btnSupport')}
        </Link>

        {/* Error Display */}
        {error && (
          <Typography
            textAlign='center'
            sx={{ margin: '0 -20px', width: 1, position: 'absolute', color: `${theme.palette.error.main}` }}
            variant='h5'
          >
            {t(`errors:${error}`)}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default RoleSelector;
