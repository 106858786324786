import * as datefns from 'date-fns';
import _ from 'lodash';
import iots from 'utils/iots';

function digitString(data: unknown): string | null {
  if (!iots.isPrimitiveType(data)) {
    return null;
  }
  const clear = String(data).replace(/\D/g, '');
  return clear ? clear : null;
}
function digitNumber(data: unknown): number | null {
  if (!iots.isPrimitiveType(data)) {
    return null;
  }
  const clear = String(data).replace(/\D/g, '');
  return clear ? Number(clear) : null;
}

/**
 * format date format to yyyy-MM-dd
 * @param data
 * @returns
 */
function dateString(data: unknown): string | null {
  if (!data) {
    return null;
  }
  if (_.isNumber(data) || _.isDate(data)) {
    return datefns.format(data, 'yyyy-MM-dd');
  }
  return null;
}

export default {
  digitString,
  digitNumber,
  dateString,
  moneyNumber: digitNumber,
};
