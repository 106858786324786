import { Button, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import RadioCard from 'components/base/RadioCard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps<T extends string> {
  onSelectRole: (role: T) => void;
  roles: Record<T, string>;
}

export default function RoleSelector<T extends string>({ onSelectRole, roles }: IProps<T>) {
  const { t } = useTranslation();
  const [selectedRole, selectRole] = useState<T>();

  const handleRoleBtnClick = (role: T) => {
    onSelectRole(role);
    selectRole(role);
  };
  const roleList = Object.values(roles) as T[];
  const theme = useTheme();

  const isDesktop = useMediaQuery<Theme>(theme.breakpoints.up('sm'));

  return (
    <Stack gap='96px'>
      {/* Header */}

      {/* Role buttons */}
      <Stack gap='24px'>
        {!isDesktop && (
          <Typography variant='h1' sx={{ width: 1, textAlign: 'center', fontSize: '48px', fontWeight: 700 }}>
            {t('interface:page.ClientMortgageEntryCreateRolePage.RoleSelector.lblChoose')}
          </Typography>
        )}

        <Stack gap='16px'>
          {roleList.map((role: string) =>
            isDesktop ? (
              <RadioCard
                key={role}
                title={t(`common:mortgage.roles.${role}`)}
                item={role as T}
                selectedItem={selectedRole}
                onSelect={(eventData) => handleRoleBtnClick(eventData as T)}
              />
            ) : (
              <Button
                fullWidth
                key={role}
                name={role}
                variant='contained'
                color='secondary'
                size='large'
                onClick={() => {
                  handleRoleBtnClick(role as T);
                }}
              >
                {t(`common:mortgage.roles.${role}`)}
              </Button>
            ),
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
