/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const HomeEmpty = createSvgIcon(
  <svg height='100%' width='100%' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 127C98.7939 127 127 98.7939 127 64C127 29.2061 98.7939 1 64 1C29.2061 1 1 29.2061 1 64C1 98.7939 29.2061 127 64 127Z'
      fill='#F5F5F5'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M72.4545 92.2185V75.5295C72.4545 74.7918 72.1576 74.0843 71.6291 73.5627C71.1006 73.0411 70.3838 72.748 69.6364 72.748H58.3636C57.6162 72.748 56.8994 73.0411 56.3709 73.5627C55.8424 74.0843 55.5455 74.7918 55.5455 75.5295V92.2185C55.5455 92.9562 55.2485 93.6637 54.72 94.1853C54.1915 94.707 53.4747 95 52.7273 95H35.8182C35.0708 95 34.3539 94.707 33.8254 94.1853C33.2969 93.6637 33 92.9562 33 92.2185V60.0575C33.0063 59.6725 33.0906 59.2927 33.2479 58.9404C33.4052 58.5881 33.6323 58.2704 33.9159 58.0061L62.0977 32.7293C62.6172 32.2602 63.2959 32 64 32C64.7041 32 65.3828 32.2602 65.9023 32.7293L94.0841 58.0061C94.3677 58.2704 94.5948 58.5881 94.7521 58.9404C94.9094 59.2927 94.9937 59.6725 95 60.0575V92.2185C95 92.9562 94.7031 93.6637 94.1746 94.1853C93.6461 94.707 92.9292 95 92.1818 95H75.2727C74.5253 95 73.8085 94.707 73.28 94.1853C72.7515 93.6637 72.4545 92.9562 72.4545 92.2185Z'
      fill='#F5F5F5'
      stroke='#B9B9B2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>,
  'HomeEmpty',
);

export default HomeEmpty;
