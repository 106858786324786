import { Grid, SvgIcon, Typography, useTheme } from '@mui/material';
import { Box, Container } from '@mui/system';
import { mapBankIcon } from 'mocks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

export const BANK_MOCK = [
  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'rosbank',
    decision: {
      status: 'approved',
      approvedSum: 7_200_000,
      rate: 850,
    },
    status: 'closed',
    internalBankData: {},
  },

  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'alfa',
    decision: {
      status: 'approved',
      approvedSum: 7_205_000,
      rate: 920,
    },
    status: 'closed',
    internalBankData: {},
  },

  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'absolut',
    decision: {
      status: 'approved',
      approvedSum: 7_765_000,
      rate: 910,
    },
    status: 'closed',
    internalBankData: {},
  },

  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'sovcom',
    decision: {
      status: 'approved',
      approvedSum: 7_304_000,
      rate: 960,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'raiffeisen',
    decision: {
      status: 'approved',
      approvedSum: 7_648_000,
      rate: 850,
    },
    status: 'closed',
    internalBankData: {},
  },

  {
    uuid: 'bb1ec50b-3d7d-4f4e-a19e-c91bbb06c58e',
    createdAt: 1664286161,
    bank: 'domrf',
    decision: {
      status: 'approved',
      approvedSum: 7_458_000,
      rate: 750,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: '52e779ea-5de7-4b22-a741-c3fb972846a8',
    createdAt: 1661435457,
    bank: 'uralsib',
    decision: {
      status: 'approved',
      approvedSum: 7_648_000,
      rate: 850,
    },
    status: 'closed',
    internalBankData: {},
  },
  {
    uuid: '2846ecde-a59b-4f82-bc9b-e66d83a982d8',
    createdAt: 1664286161,
    bank: 'zenit',
    decision: {
      status: 'approved',
      approvedSum: 7_848_000,
      rate: 980,
    },
    status: 'closed',
    internalBankData: {},
  },
];

export default function () {
  const [step, setStep] = useState(0);

  const { t } = useTranslation();
  const theme = useTheme();
  const fadeAnimation = (v: number) => ({
    '@keyframes myAnim': {
      '0%': { opacity: 0, transform: 'translateY(50px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    animation: `myAnim ${v}s ease-in 0s 1 normal forwards`,
    width: 1,
  });
  const converted = {
    '@keyframes bns': {
      '0%': {
        animationTimingFunction: 'ease-out',
        transform: 'scale(1)',
        transformOrigin: 'center center',
      },
      '10%': { animationTimingFunction: 'ease-in', transform: 'scale(0.91)' },
      '17%': { animationTimingFunction: 'ease-out', transform: 'scale(0.98)' },
      '33%': { animationTimingFunction: 'ease-in', transform: 'scale(0.87)' },
      '45%': { animationTimingFunction: 'ease-out', transform: 'scale(1)' },
    },
    animation: 'bns 2s ease-in 0s 1 normal forwards',
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => step + 1);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box sx={{ height: 1, width: 1, marginTop: '40px' }}>
      <Container sx={{ width: 1 }}>
        <Grid container direction='row' spacing={2}>
          {BANK_MOCK.map((bank, idx) =>
            step >= idx + 1 ? (
              <Grid item key={bank.bank} xs={6}>
                <Box sx={fadeAnimation(0.5)}>
                  <Box
                    sx={{
                      width: '100%',
                      background: theme.palette.secondary.main,
                      padding: '16px',
                      borderRadius: '16px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                    key={bank.uuid}
                  >
                    {step > idx + 1 && <SvgIcon component={mapBankIcon(bank.bank)} sx={{ width: '48px', height: '48px', ...converted }} />}
                    {step > idx + 1 && <Typography sx={{ fontWeight: 'bold' }}>{utils.format.percent(bank.decision.rate)}</Typography>}

                    {step > idx + 1 && (
                      <Box>
                        <Typography sx={{ fontSize: '14px' }}>{t(`common:mortgage.banks.${bank.bank}`)}</Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                          {utils.format.price(bank.decision.approvedSum)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            ) : null,
          )}
        </Grid>
      </Container>
    </Box>
  );
}
