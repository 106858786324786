import { Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { getEntryProfile } from 'api/services/mortgage';
import { InsuranceProfile } from 'api/services/mortgage.dto';
import ErrorMessage from 'components/base/ErrorMessage';
import useTypedParams from 'hooks/useTypedParams';
import { InfoField } from 'pages/Client/Mortgage/Entry/Insurance/components/InfoField';
import { Accordion } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/Accordion';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE } from 'routes/paths';
import utils from 'utils';

interface AccordionPolicyHolderProps {
  title?: string;
}

export const AccordionPolicyHolder: FC<AccordionPolicyHolderProps> = ({ title = 'title' }) => {
  const [data, setData] = useState<InsuranceProfile | null>(null);
  const [error, setError] = useState<ApiError>();
  const [ok, setOk] = useState(false);

  const { uuidEntry, uuidUser } = useTypedParams<typeof PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE>();

  const { t } = useTranslation();

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceBaseDataForm';

  const fetchData = async () => {
    try {
      const data = await getEntryProfile(`${uuidEntry}`, `${uuidUser}`);
      setData(data.body);
      setOk(data.ok);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    if (uuidEntry && uuidUser) fetchData();
  }, [uuidEntry, uuidUser]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!ok) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  if (data !== null) {
    const passport = data.documents.find((document) => document.type === 'passport')!;
    const inn = data.documents.find((document) => document.type === 'inn')!;
    const address = data.addresses.find((document) => document.type === 'registration')!;
    const email = data.contacts.find((document) => document.type === 'email')!;
    const mobilePhone = data.contacts.find((document) => document.type === 'mobilePhone')!;
    const gender = getGenderTranslation(data.gender);
    // TODO: In future we will need to get country of passport
    const passportStatus = getPassportStatusTranslation(passport.status);

    return (
      <Accordion title={t(`${prefix}.policyHolder.${title}`)} helperText={t(`${prefix}.helperText`)}>
        <InfoField title={data.lastname} helperText={t(`${prefix}.beneficiary.lastname`)} />
        <InfoField title={data.firstname} helperText={t(`${prefix}.beneficiary.firstname`)} />
        <InfoField title={data.patronymic} helperText={t(`${prefix}.beneficiary.patronymic`)} />
        <InfoField title={utils.date.format(data.birth.date, 'DD.MM.YYYY')} helperText={t(`${prefix}.beneficiary.birth`)} />
        <InfoField title={t(gender)} helperText={t(`${prefix}.beneficiary.gender`)} />
        <InfoField title={t(passportStatus)} helperText={t(`${prefix}.beneficiary.documents.isRussian`)} />
        <InfoField title={passport.data.docSeries} helperText={t(`${prefix}.beneficiary.documents.docSeries`)} />
        <InfoField title={passport.data.docNo} helperText={t(`${prefix}.beneficiary.documents.docNo`)} />
        <InfoField title={passport.data.issueUnit} helperText={t(`${prefix}.beneficiary.documents.issueUnit`)} />
        <InfoField title={passport.data.issuedBy} helperText={t(`${prefix}.beneficiary.documents.issuedBy`)} />
        <InfoField
          title={utils.date.format(passport.data.issueDate, 'DD.MM.YYYY')}
          helperText={t(`${prefix}.beneficiary.documents.issueDate`)}
        />
        <InfoField title={passport.data.birthPlace} helperText={t(`${prefix}.beneficiary.documents.birthCountry`)} />
        <InfoField title={address.fullAddress} helperText={t(`${prefix}.beneficiary.documents.birthPlace`)} />
        <InfoField title={utils.format.getPhoneNumber(mobilePhone.value)} helperText={t(`${prefix}.beneficiary.contacts.phone`)} />
        <InfoField title={email.value} helperText={t(`${prefix}.beneficiary.contacts.email`)} />
        <InfoField title={inn.data.value} helperText={t(`${prefix}.beneficiary.inn`)} />
      </Accordion>
    );
  }

  return <></>;
};

const getGenderTranslation = (gender: string) => {
  switch (gender) {
    case 'male':
      return 'base.Gender.male';
    case 'female':
      return 'base.Gender.female';
    default:
      return 'base.Gender.unknown';
  }
};

const getPassportStatusTranslation = (status: string) => {
  switch (status) {
    case 'active':
      return 'base.Passport.active';
    case 'disable':
      return 'base.Passport.disable';
    default:
      return 'base.Passport.unknown';
  }
};
