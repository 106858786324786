import { Box } from '@mui/material';
import Absolut from 'components/Icons/Absolut';
import AlfaIcon from 'components/Icons/Alfa';
import BankLogoDomrfIcon from 'components/Icons/BankLogoDomRf';
import Bzf from 'components/Icons/Bzf';
import GazpromBank from 'components/Icons/Gazprom';
import MetalInvest from 'components/Icons/MetalInvest';
import PromSocialBank from 'components/Icons/PromSocialBank';
import Psb from 'components/Icons/Psb';
import Raiffeisen from 'components/Icons/Raiffeisen';
import Rosbank from 'components/Icons/Rosbank';
import RusNar from 'components/Icons/RusNar';
import SberIcon from 'components/Icons/Sber';
import Sovcom from 'components/Icons/Sovcom';
import SurgutNefteGas from 'components/Icons/SurgutNefteGas';
import Tinkoff from 'components/Icons/Tinkoff';
import TransKapital from 'components/Icons/TransKapital';
import UralSib from 'components/Icons/UralSib';
import Vtb from 'components/Icons/Vtb';
import Zenit from 'components/Icons/Zenit';
import { ObjectType } from 'utils/iots';

const MkbImage = () => {
  return <Box component='img' src={'/img/landing/mkb.png'} width='48px' height='48px' borderRadius='50%' />;
};

export const MapIcons: Record<string, ObjectType> = {
  sberbank: SberIcon,
  absolut: Absolut,
  rosbank: Rosbank,
  domrf: BankLogoDomrfIcon,
  rusnarbank: RusNar,
  sovcom: Sovcom,
  alfa: AlfaIcon,
  primsoc: PromSocialBank,
  bgfbank: Bzf,
  pskb: PromSocialBank,
  metallinvestbank: MetalInvest,
  uralsib: UralSib,
  transkapital: TransKapital,
  raiffeisen: Raiffeisen,
  zenit: Zenit,
  surgutneftegas: SurgutNefteGas,
  mkb: MkbImage,
  gazprombank: GazpromBank,
  vtb: Vtb,
  tinkoff: Tinkoff,
  psb: Psb,
};
