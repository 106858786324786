import { req } from 'api/http';
import { DocumentSource, DocumentProgress } from '../experiment/experiment.dto';
import {
  BankGuaranteeEntryEntity,
  BankGuaranteeParams,
  DTOBankGuaranteeEntryAndCompanyRes,
  DTOCreateBankGuaranteeEntryAndCompanyBodyReq,
} from './bankGuarantee.dto';

const getEntryList = () => req<null, BankGuaranteeEntryEntity[]>(`bankGuarantee`, { method: 'GET' });

const postCreateEntryWithCompany = (payload: DTOCreateBankGuaranteeEntryAndCompanyBodyReq) =>
  req<DTOCreateBankGuaranteeEntryAndCompanyBodyReq, DTOBankGuaranteeEntryAndCompanyRes>(`bankGuarantee`, { method: 'POST', body: payload });

const putUpdateEntry = (payload: DTOCreateBankGuaranteeEntryAndCompanyBodyReq) =>
  req<Partial<DTOCreateBankGuaranteeEntryAndCompanyBodyReq>, DTOBankGuaranteeEntryAndCompanyRes>(`bankGuarantee`, {
    method: 'PUT',
    body: payload,
  });

const getEntry = ({ uuidEntry }: { uuidEntry: string }) => req(`bankGuarantee/${uuidEntry}`, { method: 'GET' });

const getDocuments = (params: BankGuaranteeParams, signal: AbortSignal) =>
  req<null, DocumentSource[]>(`bankGuarantee/${params.uuidEntry}/document`, { method: 'GET', signal });

const deleteDocument = (params: BankGuaranteeParams, { uuidDocument }: { uuidDocument: string }) =>
  req<null, DocumentSource>(`bankGuarantee/${params.uuidEntry}/document/${uuidDocument}`, {
    method: 'DELETE',
  });

const getRequeiredDocuments = (params: BankGuaranteeParams, signal: AbortSignal) => {
  return req<null, DocumentProgress[]>(`bankGuarantee/${params.uuidEntry}/document/progress`, { method: 'GET', signal });
};

function putUpdateDocument(params: BankGuaranteeParams, payload: DocumentSource) {
  return req<any, DocumentSource[]>(`bankGuarantee/${params.uuidEntry}/document/${payload.uuid}`, {
    method: 'PUT',
    body: payload,
  });
}
function postUploadDocument(params: BankGuaranteeParams, file: File) {
  return req<{ link: string }, DocumentSource[]>(`bankGuarantee/${params.uuidEntry}/uploadDocument`, {
    method: 'POST',
    body: { file },
  });
}

export default {
  getEntry,
  getEntryList,
  postCreateEntryWithCompany,
  putUpdateEntry,
  getRequeiredDocuments,
  putUpdateDocument,
  deleteDocument,
  getDocuments,
  postUploadDocument,
};
