import { DefaultValidation, NaviteEnumValidation, NumberSchema } from 'utils/schema';
import { z } from 'zod';
import { EBusinessCreditEntryParamsPurpose } from './businessCredit.dto';

export const businessCreditCreateSchema = z
  .object({
    entry: z.object({
      params: z
        .object({
          purpose: z.nativeEnum(EBusinessCreditEntryParamsPurpose, NaviteEnumValidation),
          period: NumberSchema.max(60, { message: 'errors:businessCreditCreateForm.period_max' }),
          amount: NumberSchema.min(100_000, { message: 'errors:businessCreditCreateForm.amount_min' }),
        })
        .required({ purpose: true, period: true, amount: true }),
    }),
    company: z.any().or(z.object({ name: z.string(), inn: z.string() })),
  })
  .required({ entry: true, company: true });

// const balanceSchema = z.object({
//   revenueFigureLastQuartal: z.object({ type: z.string(), value: z.number(DefaultValidation).nullish() }).nullish(),
//   revenueFigureLastYear: z.object({ type: z.string(), value: z.number(DefaultValidation).nullish() }).nullish(),
// });

export const companyProfileFormSchema = z.object({
  user: z.object({
    email: z.string().email({ message: 'errors:businessCreditCompanyForm.email' }),
    name: z
      .string()
      .transform((c) => c.split(' '))
      .pipe(z.string().array().min(2, { message: 'errors:businessCreditCompanyForm.name' })),
  }),
  company: z.object({
    bankAccount: z.object({
      bic: z.string(DefaultValidation).length(9, { message: 'errors:businessCreditCompanyForm.bic' }),
      bankAccount: z.string(DefaultValidation).length(24, { message: 'errors:businessCreditCompanyForm.bankAccount' }),
      bankAddress: z.string(),
      bankName: z.string(),
    }),
    balance: z.any(),
  }),
});

export type BusinessCompanyProfileSchema = z.infer<typeof companyProfileFormSchema>;

export type BusinessCreditSchema = z.infer<typeof businessCreditCreateSchema>;
