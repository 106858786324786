/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const ResoIcon = createSvgIcon(
  <path
    d='M53 35.7199L52.6513 32.4726C51.9539 26.326 49.9781 21.2232 46.8399 17.3961C44.0504 13.8009 40.3311 11.2495 35.7982 9.39387V0L32.5439 0.347921C26.3838 1.04376 21.2697 3.01532 17.4342 6.14661C13.8311 8.92998 11.2741 12.6411 9.41447 17.1641H0L0.348684 20.4114C1.04605 26.558 3.02193 31.6608 6.16009 35.6039C8.94956 39.1991 12.6689 41.7505 17.2018 43.6061V53L20.4561 52.6521C26.6162 51.9562 31.7303 49.9847 35.5658 46.8534C39.1689 44.07 41.7259 40.3589 43.5855 35.8359H53V35.7199ZM31.9627 42.0985C29.5219 43.954 26.6162 45.3457 23.0132 46.1575V26.9059H17.2018V37.2276C14.6447 35.9519 12.4364 34.2123 10.8092 32.0088C8.94956 29.5733 7.55482 26.674 6.74123 23.0788H26.1513V17.2801H15.807C17.0855 14.7287 18.8289 12.5252 21.0373 10.9015C23.4781 9.04595 26.3838 7.65427 29.9868 6.84245V26.442H35.7982V15.8884C38.3553 17.1641 40.5636 18.9037 42.1908 21.1072C44.0504 23.5427 45.4452 26.442 46.2588 30.0372H27.0811V35.8359H37.193C35.9145 38.2713 34.1711 40.4748 31.9627 42.0985Z'
    fill='#009639'
  />,
  'ResoIcon',
);

export default ResoIcon;
