interface Tag {
  color: string;
  title: string;
}

interface Tags {
  [key: string]: Tag;
  dataFilling: Tag;
  productConfirmation: Tag;
  productSelection: Tag;
  sendLink: Tag;
  waitingPayment: Tag;
  paid: Tag;
}

// TODO: use colors from theme
// TODO: This is bullshit, remove
const clientTags: Tags = {
  dataFilling: {
    color: '#0FC2EB',
    title: 'заполнение данных',
  },
  productConfirmation: {
    color: '#E81E1E',
    title: 'подтверждение продукта',
  },
  productSelection: {
    color: '#1A6CE7',
    title: 'выбор продукта',
  },
  sendLink: {
    color: '#E81E1E',
    title: 'ссылка не отправлена',
  },
  waitingPayment: {
    color: '#F4A611',
    title: 'ожидание оплаты',
  },
  paid: {
    color: '#31C918',
    title: 'полис оплачен',
  },
};

export const getClientTag = (tag: string): Tag | null => {
  if (!tag) {
    return null;
  }

  return clientTags[tag];
};
