import { ECompanyAddressType, ECompanyContactType, ECompanyDataType, ECompanyRequisiteType } from './businessCredit.enum';
import { EBCBank, ECBCEntryPrimaryPropose, ECBCEntryPropose } from './businessCredit.propose.dto';

export interface IBusinessCreditCompanyUuidDTO {
  uuidCompany: string;
  uuidEntry: string;
}

/**
 * Entry data to create company entry
 */
export interface IBusinessCreditCompanyEntryCreatingDataDTO {
  entry: IBusinessCreditCompanyEntryCreatingDataEntry;
  company: IBusinessCreditCompanyEntryCreatingDataCompany;
}

export interface IBusinessCreditCompanyEntryCreatingDataEntry {
  params: ECBusinessCreditEntryParams;
}

export interface ECBusinessCreditEntryParams {
  purpose: EBusinessCreditEntryParamsPurpose;
  period: number; // in month
  // TODO: maybe rubles?
  amount: number; // in copecks
}

export enum EBusinessCreditEntryStatus {
  active = 'active',
  success = 'success',
  failed = 'failed',
}

export enum EBusinessCreditEntryParamsPurpose {
  bankGuarantee = 'bankGuarantee',
  account = 'account',
}

export interface IBusinessCreditCompanyEntryCreatingDataCompany {
  uuid?: string;
  name: string;
  inn: string;
  type: string;
  addresses?: ECCompanyAddresses[];
  contacts?: ECCompanyContacts[];
  requisite: ECCompanyRequisite[];
  bankAccount: IECCompanyBankAccount;
  balance: IECCompanyBalance[];
}

export interface ECCompanyContacts {
  type: ECompanyContactType;
  data: string;
}

export interface ECCompanyAddresses {
  type: ECompanyAddressType;
  data: string;
}

export interface ECCompanyRequisite {
  type: ECompanyRequisiteType;
  data: string;
}

export interface IBusinessCreditEntryEntity {
  ids: { uuidCompany: string };
  status: EBusinessCreditEntryStatus;

  params: ECBusinessCreditEntryParams;
  documents: unknown[];
  uuid: string;
  proposes: ECBCEntryPropose[];
  primaryPropose: ECBCEntryPrimaryPropose | null;
  excludedProviders: EBCBank[];
}

export interface IBusinessCreditEntryResponse {
  entry: IBusinessCreditEntryEntity;
  company: IBusinessCreditCompanyEntryCreatingDataCompany;
}

export interface BusinessCreditCompanyForm {
  company: { bic: { type: ECompanyRequisiteType; data: string }; bankAccount: { type: ECompanyRequisiteType; data: string } };
  user: { email: string; name: string };
}

export interface IECCompanyBankAccount {
  bankName: string;
  bankAddress: string;
  bankAccount: string;
  correspondentBankAccount: string;
  bik: string;
}

export interface IECCompanyBalance {
  type: ECompanyDataType;
  value: number;
  year: number;
  quarter?: number;
}
