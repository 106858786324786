import { Box, Typography } from '@mui/material';
import { LS_SIGN_CONTEXT } from 'api/http';
import { CreditProfileContract, EContractType } from 'api/services/contract.dto';
import { userCheckContractCode, userUploadFile } from 'api/services/user';
import SuccessIcon from 'components/Icons/SuccessLigth';
import BaseButtonBack from 'components/base/Button/Back';
import DrawSign from 'components/base/DrawSign';
import NoticeScreen from 'components/base/NoticeScreen';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useNavigateParams from 'hooks/useNavigateParams';
import { isObject } from 'lodash';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY } from 'routes/paths';
import { ObjectType } from 'typescript';
import utils from 'utils';

import PreviewPDF from './components/PreviewPDF';
import SmsVerify from './components/SmsVerify';

enum ESignSteps {
  preview = 'preview',
  signByDraw = 'signByDraw',
  signBySMS = 'signBySMS',
  success = 'success',
}

export type TLocationParams = {
  uuid: string;
};

const ClientMortgageEntrySignEntryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigateParams();
  const [params] = useSearchParams();
  const { uuid } = useParams<keyof TLocationParams>() as TLocationParams;

  const step = params.get('step');
  /**
   *
   */
  const loadContext = useCallback((): CreditProfileContract => {
    const ctx = localStorage.getItem(LS_SIGN_CONTEXT);
    if (isObject(ctx)) {
      return ctx;
    } else {
      return {
        uuidEntry: uuid,
        type: EContractType.creditProfileContract,
        agreements: { personalQuestionnaireDataCorrect: true },
        details: { link: 'link' },
      } as CreditProfileContract;
    }
  }, []);

  const hookForm = useForm<CreditProfileContract>({ defaultValues: useMemo(() => loadContext(), []) });

  /**
   *
   */
  const saveContext = useCallback(() => {
    localStorage.setItem(LS_SIGN_CONTEXT, JSON.stringify(hookForm.getValues()));
  }, []);
  useEffect(() => {
    window.addEventListener('beforeunload', saveContext);
    return () => {
      window.removeEventListener('beforeunload', saveContext);
    };
  });

  const nextStep = () => {
    switch (step) {
      case ESignSteps.preview: {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY, { params: { uuid }, query: { step: ESignSteps.signByDraw } });

        break;
      }
      case ESignSteps.signByDraw: {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY, { params: { uuid }, query: { step: ESignSteps.signBySMS } });
        break;
      }
      case ESignSteps.signBySMS: {
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY, { params: { uuid }, query: { step: ESignSteps.success } });
        break;
      }
      case ESignSteps.success:
        navigate(PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS, { params: { uuid } });
    }
  };

  const handleNextBtnClick = () => {
    nextStep();
  };

  const uploadSignature = async (bsfile: string) => {
    try {
      const signatureImage = await utils.file.dataURLtoFile(bsfile, 'signature.png');
      const resourceResponse = await userUploadFile({ file: signatureImage, resourceType: 'profile' });

      if (resourceResponse.ok) {
        const link = resourceResponse.body.link;
        hookForm.setValue('assets.handSigImageUrl', [import.meta.env.REACT_APP_STORAGE_URL, link].join(''));
        hookForm.setValue('signatures.handwritten', [import.meta.env.REACT_APP_STORAGE_URL, link].join('') as never);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveSignature = (signature: ObjectType) => {
    uploadSignature(signature as unknown as string).then(() => {
      handleNextBtnClick();
    });
  };

  const handleSaveSmsCode = (code: string) => {
    hookForm.setValue('signatures.sms', utils.format.digitString(code) as never);
    userCheckContractCode(hookForm.getValues())
      .then((res) => {
        if (res.ok) {
          handleNextBtnClick();
        }
      })
      .catch(console.log);
  };

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntrySignEntryPage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntrySignEntryPage.description') }]}
      hideHeader={step === ESignSteps.success}
      header={{ xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntrySignEntryPage.title')} />, lg: <TopMenu /> }}
      sx={{ height: 1, justifyContent: 'space-evently', gap: '16px' }}
    >
      {step === ESignSteps.preview && <PreviewPDF handleNextBtnClick={handleNextBtnClick} />}
      {step === ESignSteps.signByDraw && (
        <DrawSign
          lables={{
            title: 'interface:page.ClientMortgageEntrySignEntryPage.Draw.lblTitle',
            btnSign: 'interface:page.ClientMortgageEntrySignEntryPage.Draw.btnSign',
          }}
          handleSignBtnClick={handleSaveSignature}
        />
      )}
      {step === ESignSteps.signBySMS && (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '40px' }}>
          <Typography variant='h2' sx={{ fontWeight: 400, width: 'fit-content', textAlign: 'center' }}>
            {t('interface:page.AuthSignInByPhoneCodePage.inpCode')}
          </Typography>
          <SmsVerify contractType={EContractType.creditProfileContract} handleNextBtnClick={handleSaveSmsCode} />
        </Box>
      )}
      {step === ESignSteps.success && (
        <NoticeScreen
          successIcon={SuccessIcon}
          successTitle={t('interface:page.ClientMortgageEntrySignEntryPage.Success.title')}
          successSubtitle={t('interface:page.ClientMortgageEntrySignEntryPage.Success.subtitle')}
          successBtnText={t('interface:page.ClientMortgageEntrySignEntryPage.Success.textBtn')}
        />
      )}
    </ContainerLayout>
  );
};

export default ClientMortgageEntrySignEntryPage;
