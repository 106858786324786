import { Done, ArrowForwardIos } from '@mui/icons-material';
import { Accordion, AccordionSummary, Box, SvgIcon, SxProps, Typography, useTheme } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import ErrorMessage from 'components/base/ErrorMessage';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import useTypedParams from 'hooks/useTypedParams';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_BUSINESS_CREDIT_ENTRY_EDIT, PATH_BUSINESS_CREDIT_ENTRY_PROGRESS, PATH_HOME } from 'routes/paths';
import utils from 'utils';
import BusinessCreditProfile from './components/BusinessCreditProfile';
import BusinessCreditProposeProgress from './components/BusinessCreditProposeProgress';

export default function BusinessCreditProgressPage() {
  const [expanded, setExpanded] = useState<string>('');

  const theme = useTheme();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const businessCreditContext = useBusinessCredit();
  const params = useTypedParams<typeof PATH_BUSINESS_CREDIT_ENTRY_PROGRESS>();

  const navigateCreditParams = () => {
    navigate(utils.url.urlReplace(PATH_BUSINESS_CREDIT_ENTRY_EDIT, params));
  };

  const handleExpand = (name: string) => () => {
    setExpanded(expanded === name ? '' : name);
  };
  const successSx: SxProps = {
    background: theme.palette.success.light,
    borderRadius: '8px',
    boxShadow: 'none',
    '&:before': { height: 0 },
    pointerEvents: 'all',
  };

  useEffect(() => {
    businessCreditContext.loadEntryRequest(params);
  }, []);
  return (
    <ContainerLayout
      maxWidth="xl"
      title={t('helmet:ClientMortgageProgressPage.title')}
      meta={[
        {
          name: 'description',
          content: t('helmet:ClientMortgageProgressPage.description'),
        },
      ]}
      header={{
        xs: (
          <BaseButtonBack
            title={t(`interface:page.ClientMortgageProgressPage.${businessCreditContext.loading ? 'loadingLabel' : 'title'}`)}
            onBackEvent={() => {
              navigate(PATH_HOME);
              return false;
            }}
          />
        ),
        lg: (
          <TopMenu
            breadcrumbs={[
              {
                title: t('interface:page.ClientMortgageProgressPage.routes.home'),
                href: PATH_HOME,
              },
              {
                title: t(`interface:page.ClientMortgageProgressPage.${businessCreditContext.loading ? 'loadingLabel' : 'title'}`),
                activeLink: true,
              },
            ]}
          />
        ),
      }}
      sx={{
        justifyContent: 'space-evently',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingBottom: '40px',
      }}
    >
      <Accordion
        data-testid={`credit-params`}
        key={`credit-params`}
        sx={{ ...successSx, pointerEvents: 'all' }}
        expanded={expanded === `credit-params`}
        onClick={navigateCreditParams}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              justifyContent: 'space-between',
              margin: '20px 0',
              alignItems: 'center',
            },
          }}
        >
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '12px' }}>
            <Typography variant='h4m'>{t(`interface:page.ClientMortgageProgressPage.progress.credit.title`)}</Typography>
            <SvgIcon sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }} color={'success'} component={Done} />
          </Box>

          <SvgIcon sx={{ height: '12px', width: '11px' }} htmlColor='#ABC6A7' component={ArrowForwardIos} />
        </AccordionSummary>
      </Accordion>
      <BusinessCreditProfile
        uuidEntry={businessCreditContext?.entry?.entry?.uuid!}
        expanded={expanded === 'profile'}
        handleClick={(path) => {
          navigate(path);
        }}
        handleExpand={handleExpand('profile')}
      />
      <BusinessCreditProposeProgress
        uuidEntry={businessCreditContext?.entry?.entry?.uuid!}
        expanded={expanded === 'propose'}
        handleClick={(path) => {
          navigate(path);
        }}
        handleExpand={handleExpand('propose')}
      />

      <ErrorMessage error={businessCreditContext.apiError} />
    </ContainerLayout>
  );
}
