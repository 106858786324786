import {
  Alert,
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageIcon from '@mui/icons-material/Image';
import { Error as ErrorIcon } from '@mui/icons-material';

import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import {
  DocumentSource,
  EDocRecognitionStatus,
  EProfileDocumentType,
  ERequiredPassportParts,
} from 'api/services/experiment/experiment.dto';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import FilePreview from '../FilePreview';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileChipInfo from '../FileChipInfo';
import utils from 'utils';
import { useBusinessDocuments } from 'components/providers/Document/BusinessDocumentProvider';
import { getFileTypeCombination } from 'utils/document';
import { ApiError } from 'api/errors';

interface FileViewProps {
  file: DocumentSource;
  onUpdateFile(document: DocumentSource, callback: (error: ApiError) => void): Promise<void>;
  onDeleteFile(document: DocumentSource): Promise<void>;
  expanded: boolean;
  index: number;
}

export default function FileDescriptorView(props: FileViewProps) {
  const { file, onDeleteFile, onUpdateFile } = props;
  const theme = useTheme();
  const docService = useBusinessDocuments();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const optionMenuOpen = Boolean(menuAnchor);

  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [fileType, setFileType] = useState(getFileTypeCombination(props.file));
  const [partType, setPartType] = useState(props.file.part as string);

  const [fileError, setFileError] = useState<ApiError>();
  const [currentFileType] = fileType?.split('_');

  useEffect(() => {
    setFileType(getFileTypeCombination(file));
    setPartType(file.part as string);
  }, [file.type, file.part]);

  const handleDeleteFile = () => onDeleteFile(file);

  const filename = useMemo(() => {
    if (file.part !== ERequiredPassportParts.unknown) return t(`common:documents.${file.type}.parts.${file.part}`, file);
    if (file.quarter !== null) {
      return t(`common:documents.${file.type}.title_quarter`, file);
    }
    return t(`common:documents.${file.type}.title`, file);
  }, [file.url, file.type, file.part]);

  const isShowProgressBar = useMemo(() => {
    return file.classificationStatus === EDocRecognitionStatus.awaiting;
  }, [file.classificationStatus]);
  /**
   *
   * save file type to state and
   * execute update file if type===passport
   * @param event
   */
  const handleUpdateFile = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const [type] = value.split('_');
    setFileType(value);
    if (type !== EProfileDocumentType.passport) {
      updateFileAdditional({ typeOfFile: value });
    }
  };
  /**
   *
   * update input filePart
   * @param event
   */
  const handleUpdateFilePart = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPartType(value);

    if (currentFileType === EProfileDocumentType.passport) {
      updateFileAdditional({ typeOfFile: currentFileType, typeOfPart: value });
    }
  };

  /**
   * method to update file data and send update request
   *
   */
  const updateFileAdditional = useCallback(
    ({ typeOfFile, typeOfPart }: { typeOfFile: string; typeOfPart?: string }) => {
      setFileError(undefined);
      const updated = _.cloneDeep(file);
      const [type, subtype] = typeOfFile.split('_');
      _.set(updated, 'type', type);

      if (subtype === 'year' || subtype === 'quarter') {
        const menuItem = docService.typesOfFiles.find((x) => x.subType === typeOfFile);
        _.set(updated, 'quarter', menuItem?.quarter);
        _.set(updated, 'year', menuItem?.year);
      }
      if (!!typeOfPart) _.set(updated, 'part', typeOfPart);
      onUpdateFile(updated, (err) => {
        setFileError(err);
      });
    },
    [fileType, partType],
  );

  /**
   * open preview for document
   */
  const handleClickView = () => {
    setModalOpen(true);
  };

  /**
   * open mobile menu with delete and download
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  /**
   * close menuAhchor
   */
  const handleClose = () => {
    setMenuAnchor(null);
  };

  /**
   * show file error component if has network error
   */
  if (file?.recognitionStatus === EDocRecognitionStatus.failed) {
    return (
      <ListItem>
        <ListItemIcon>
          <ErrorIcon />
        </ListItemIcon>
        <ListItemText primary={filename} sx={{ fontSize: '12px' }} />
      </ListItem>
    );
  }

  const isShowForm = useMemo(() => {
    if (file.type === EProfileDocumentType.unknown) {
      return true;
    }
    if (currentFileType === EProfileDocumentType.unknown || currentFileType === EProfileDocumentType.other) {
      return true;
    }
    if (currentFileType === EProfileDocumentType.passport && file?.part === ERequiredPassportParts.unknown) {
      return true;
    }
    return false;
  }, [currentFileType, file.type, file.part]);

  const isHasPreview = useMemo(() => {
    const ext = utils.file.getExtension(file.url);
    return ['jpg', 'png', 'jpeg'].includes(ext);
  }, []);

  return (
    <Paper
      data-testid={`file_${props.file.type}`}
      elevation={0}
      sx={{
        background: '#f5f5f5',
        padding: '8px',
        borderRadius: '8px',
        border: `2px solid ${
          ![EProfileDocumentType.unknown, EProfileDocumentType.other].includes(file.type) ? theme.palette.common.green : '#ffa726'
        }`,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, gap: '8px', justifyContent: 'flex-start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', flexGrow: { lg: 1, xs: 0 } }}>
          {isHasPreview && (
            <IconButton
              onClick={handleClickView}
              sx={{
                position: 'absolute',
                zIndex: 1,
              }}
            >
              <VisibilityIcon />
            </IconButton>
          )}

          <Avatar src={file.url} sx={{ borderRadius: 0, zIndex: 0 }}>
            <ImageIcon />
          </Avatar>

          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography sx={{ fontSize: '14px' }}>{filename}</Typography>
            {/* <Typography sx={{ fontSize: '12px',color:theme.palette.common.softGrey }}>{file.}</Typography> */}
          </Box>
          <IconButton
            sx={{ display: { xs: 'block', lg: 'none' } }}
            id='basic-button'
            aria-controls={menuAnchor ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={optionMenuOpen ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <Menu
            id='basic-menu'
            anchorEl={menuAnchor}
            open={optionMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuList disablePadding sx={{ padding: 0 }}>
              <MenuItem component='a' href={file.url} target='_blank'>
                <ListItemIcon>
                  <DownloadIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Скачать</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDeleteFile}>
                <ListItemIcon>
                  <DeleteIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Удалить</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>
          <FileChipInfo status={file.classificationStatus} type={file.type} part={file.part} />
        </Box>

        <Box sx={{ justifySelf: 'flex-end', display: { lg: 'flex', xs: 'none' }, alignItems: 'center' }}>
          <FileChipInfo status={file.classificationStatus} type={file.type} part={file.part} />

          <IconButton onClick={handleDeleteFile}>
            <DeleteIcon />
          </IconButton>
          <IconButton component='a' href={file.url} target='_blank'>
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <FilePreview
        fileName={filename}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        descriptor={file}
      />
      {isShowForm && !isShowProgressBar && (
        <Fragment>
          <Grid container sx={{ mt: 2 }} spacing={1}>
            <Grid item xs={12}>
              {!isShowProgressBar && (
                <Typography sx={{ fontSize: '14px', marginTop: '8px' }}>
                  Ой, что-то пошло не так! Мы не смогли распознать ваш документ. Не волнуйтесь, выберите нужный документ из списка ниже:
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {fileError && (
                <Alert severity='error'>{t([`errors:${fileError?.name}`, `errors:${fileError}`, 'errors:logic.somethingWrong'])}</Alert>
              )}
            </Grid>
            <Grid item xs={12} md={fileType === EProfileDocumentType.passport ? 6 : 12}>
              <TextField
                select
                fullWidth
                variant='standard'
                label={'Тип документа'}
                value={`${fileType}`}
                onChange={handleUpdateFile}
                data-testid={`document.type`}
                inputProps={{ ['data-testid']: `document.type-input-${props.index}` }}
              >
                {docService.typesOfFiles?.map((scope) => (
                  <MenuItem value={scope.subType} key={scope.type} data-testid={scope.subType} sx={{ whiteSpace: 'normal' }}>
                    {scope.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {currentFileType === EProfileDocumentType.passport && (
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  variant='standard'
                  label={'Разворот документа'}
                  value={`${partType}`}
                  data-testid={`document.part`}
                  onChange={handleUpdateFilePart}
                  inputProps={{ ['data-testid']: `document.part-input-${props.index}` }}
                  error={partType === 'empty'}
                >
                  {Object.values(ERequiredPassportParts)
                    .filter((x) => ![ERequiredPassportParts.blank, ERequiredPassportParts.unknown].includes(x))
                    .map((scope) => (
                      <MenuItem data-testid={scope} value={scope} key={scope} sx={{ whiteSpace: 'normal' }}>
                        {t(`common:documents.passport.parts.${scope}`)}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </Paper>
  );
}
