import { Button } from '@mui/material';
import Share from 'components/Icons/Share';
import BaseButtonBack from 'components/base/Button/Back';
import ContainerLayout from 'components/layouts/ContainerLayout';
import { ParticipantRoleSelector } from 'pages/Client/Home/components/ParticipantInvite';
import TopMenu from 'pages/Client/Home/components/TopMenu';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_AGENT_MORTGAGE_CLIENT_CREATE } from 'routes/paths';

import InviteShort from './components/InviteShort';

export default function AgentMortgagePropertyInvitePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedRole, selectRole] = useState<string | null>(null);
  const handleNavigate = (e: string) => {
    selectRole(e);
  };

  const handleCreateClient = () => {
    navigate(PATH_AGENT_MORTGAGE_CLIENT_CREATE);
  };

  return (
    <ContainerLayout
      title={t('helmet:AgentMortgagePropertyInvitePage.title')}
      meta={[{ name: 'description', content: t('helmet:AgentMortgagePropertyInvitePage.description') }]}
      header={{
        lg: (
          <TopMenu
            breadcrumbs={[
              {
                title: t('common:breadcrumbs.home'),
                href: '/',
              },
              { title: t('helmet:AgentMortgagePropertyInvitePage.title'), activeLink: true },
            ]}
          />
        ),
        xs: <BaseButtonBack title={t('helmet:AgentMortgagePropertyInvitePage.title')} />,
      }}
      maxWidth='xl'
      sx={{ height: 1 }}
    >
      {selectedRole ? (
        <InviteShort role={selectedRole} />
      ) : (
        <ParticipantRoleSelector
          renderButton={() => (
            <Fragment>
              <Button variant='contained' color='secondary' size='large' onClick={() => handleNavigate('mortgageBuyer')}>
                {t('interface:page.AgentMortgagePropertyInvitePage.inviteByLink')}
              </Button>
              <Button data-testid='createClient' variant='contained' size='large' onClick={handleCreateClient}>
                {t('interface:page.AgentMortgagePropertyInvitePage.createClient')}
              </Button>
            </Fragment>
          )}
          title={t('interface:page.AgentMortgagePropertyInvitePage.link')}
          icon={Share}
          roles={{ mortgageBuyer: 'mortgageBuyer' }}
          onSelectRole={(e) => {
            handleNavigate(e);
          }}
        />
      )}
    </ContainerLayout>
  );
}
