import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Avatar, Box, Button, Link, Paper, SvgIcon, Typography, useTheme } from '@mui/material';
import { EUserPersonalManagerStatus, IUserManagerInfo } from 'api/services/user.dto';
import StatusDot from 'components/base/StatusDot';
import useSupportChat from 'hooks/useSupportChat';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

type Props = {
  status: EUserPersonalManagerStatus;
  manager?: IUserManagerInfo;
};

const PersonalManager = ({ status, manager }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const supportChat = useSupportChat();

  if (status === EUserPersonalManagerStatus.active) {
    return (
      <Paper
        elevation={0}
        sx={{
          order: { xs: 1, lg: 2 },
          display: 'flex',
          flexDirection: { xs: 'column' },
          justifyContent: 'space-evently',
          gap: '16px',
          cursor: 'pointer',
          padding: { xs: '16px', lg: '24px' },
          background: theme.palette.secondary.main,
          minHeight: '94px',
          borderRadius: '16px',
        }}
      >
        <Typography variant={'h3b'}>{t(`interface:page.HomeClientPage.blockPeronsalManager.lblTtitle`)}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 0.5, gap: { xs: '12px', lg: '16px' } }}>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Avatar sx={{ width: { xs: '48px', lg: '72px' }, height: { xs: '48px', lg: '72px' } }} src={manager?.avatar} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography variant={'h4'} sx={{ fontSize: '18px' }}>
                {manager?.lastname} {manager?.firstname}
              </Typography>
              <Box sx={{ display: 'flex', gap: { xs: '4px', md: '8px' }, flexWrap: 'wrap', alignItems: 'center' }}>
                <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
                  {manager?.phone && utils.format.getPhoneNumber(`${manager?.phone}`)}
                </Typography>
                <StatusDot color={'#D2D2CF'} sx={{ display: { xs: 'none', md: 'block' }, width: '4px', height: '4px' }} />
                <Link sx={{ w: 1, fontSize: '16px' }} variant={'h5'} href={`mailto:${manager?.email}`}>
                  {manager?.email}
                </Link>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '40px' }}>
            <Button
              sx={{ width: '200px' }}
              size='small'
              onClick={() => {
                supportChat.open();
              }}
              variant='contained'
              fullWidth
              data-testid={'btnPersonalManager'}
            >
              {t('interface:page.HomeClientPage.blockPeronsalManager.btnChat')}
            </Button>
            <Button sx={{ width: '200px' }} href={`tel:${manager?.phone}`} fullWidth data-testid={'btnPersonalManager'}>
              {t('interface:page.HomeClientPage.blockPeronsalManager.btnCall')}
            </Button>
          </Box>
        </Box>
      </Paper>
    );
  }
  if (status === EUserPersonalManagerStatus.waiting) {
    return (
      <Paper
        elevation={0}
        sx={{
          order: { xs: 1, lg: 2 },
          display: 'flex',
          flexDirection: { xs: 'column' },

          gap: '16px',
          cursor: 'pointer',
          padding: { xs: '16px', lg: '24px' },
          background: theme.palette.secondary.main,
          minHeight: '94px',
          borderRadius: '16px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
          <Typography variant={'h3b'}>{t(`interface:page.HomeClientPage.blockPeronsalManager.lblTtitle`)}</Typography>
          <SvgIcon fontSize='small' component={AccessTimeIcon} />
        </Box>

        <Box sx={{ width: 1 }}>
          <Typography>{t('interface:page.HomeClientPage.blockPeronsalManager.waitDescription')}</Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

export default PersonalManager;
