import { useCallback, useState } from 'react';
import StoryList from './components/List/StoryList';
import StoryViewer from './components/StoryViewer';
import { goal } from '../Counters';
import { storiesEntities } from './staticStories';

export default function Stories() {
  const [storyIndex, setStoryIndex] = useState(0);
  const [isOpenState, setOpenState] = useState(false);
  const handleOpenStory = (index: number) => {
    if (import.meta.env.REACT_APP_YM_ACC) {
      goal(`stories-view-${index + 1}`);
    }
    setStoryIndex(index);
    setOpenState(true);
  };
  const handleCloseStory = useCallback(() => {
    if (import.meta.env.REACT_APP_YM_ACC) {
      goal(`stories-close`);
    }
    setOpenState(false);
  }, []);

  return (
    <>
      <StoryList stories={storiesEntities} onClickStory={handleOpenStory} />
      <StoryViewer isViewMode={isOpenState} onCloseView={handleCloseStory} startFrom={storyIndex} />
    </>
  );
}
