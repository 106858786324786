import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function BottomPopover({
  children,
  isOpen,
  handleClose,
}: {
  children: React.ReactChild;
  isOpen: boolean;
  handleClose: () => void;
}) {
  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: '20px 20px 0px 0px', maxWidth: '500px' } }}
      sx={{ top: '670px' }}
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {children}
    </Dialog>
  );
}
