import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { HookForm } from 'components/base/FormGenerator/types';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';

import { FormSchemeData } from '../../form.types';
import { ft } from '../../utils';

const FormRadioField = ({ node, hookForm, path = [] }: { node: FormSchemeData; path?: string[]; hookForm: HookForm }) => {
  const { t } = useTranslation();
  const { control, watch } = hookForm;
  // TODO: <Radio required={required} /> ?
  const {
    settings: { options = [] } = {},
  } = node;

  // Depends logic
  // TODO: Try to optimize this part
  if (node?.settings?.dependsOn?.length) {
    const watchAllFields = watch();
    for (const depends of node.settings.dependsOn) {
      const { name, requiredValues } = depends;
      if (!requiredValues.includes(_.get(watchAllFields, name))) {
        return null;
      }
    }
  }

  return (
    <FormControl sx={{ width: 1 }}>
      <FormLabel sx={{ typography: 'h4m', color: 'common.black' }}>{t(`${ft(path)}.title`)}</FormLabel>
      <Controller
        name={path.join('.')}
        control={control}
        defaultValue={node.value}
        render={({ field }) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <RadioGroup name={`${path.join('.')}`} row {...field} value={field.value || ''}>
            {options.map((option) => (
              <FormControlLabel
                id={path.join('.')}
                key={`${[...path, option].join('.')}`}
                value={option}
                control={<Radio inputProps={{ 'data-testid': `${path.join('.')}.options.${option}` } as ObjectType} />}
                label={t(`${ft(path)}.options.${option}`)}
              />
            ))}
          </RadioGroup>
        )}
      />
      {node?.settings?.description && (
        <Typography component='span' sx={{ color: 'text.secondary', lineHeight: '140%', whiteSpace: 'normal', fontSize: '14px' }}>
          {t(`${ft(path)}.description`)}
        </Typography>
      )}
    </FormControl>
  );
};

export default FormRadioField;
