import { IAbstractListResults } from './common';
import { IPublicMortgageEntryEntity } from './mortgage.dto';
import { EUserRole, IFullUserDTO, IPublicUserEntityDTO } from './user.dto';

export enum EClientType {
  mortgage = 'mortgage',
  referral = 'referral',
  insurance = 'insurance',
}
export interface GetClientsMortgageRequestArgs {
  filterTags: string[];
  sort: 'createdAt:desc';
  page: number;
  size: number;
}
export interface IRegisterClientAsAgent {
  uuidAgent: string;
  lastname: string;
  firstname: string;
  patronymic?: string;
  email?: string;
  phone: number;
  roles?: EUserRole[];
  secret: string;
}

export enum EGreetingSmsRecipientTypeDTO {
  phone = 'phone',
  uuid = 'uuid',
}
export interface IGreetingSmsRecipientDataDTO {
  type: EGreetingSmsRecipientTypeDTO;
  recipient: string;
}

export enum EMortgageEntryStatus {
  awaiting = 'awaiting',
  lookup = 'lookup',
  verification = 'verification',
  completed = 'completed',
  rejected = 'rejected',
  cancelled = 'cancelled',
  deleted = 'deleted',
  // TODO: new statuses
  active = 'active',
  success = 'success',
  failed = 'failed',
}

export enum EMortgageEntryStage {
  // TODO: replace to profile
  questionnaire = 'questionnaire',
  // TODO: remove
  primaryPropose = 'primaryPropose',
  profile = 'profile',
  bank = 'bank',
  realEstate = 'realEstate',
  insurance = 'insurance',
  deal = 'deal',
}

export enum EEntryAgentClientStageStatusFilter {
  profileFilling = 'profile:filling', // Заполнение анкеты
  profileVerification = 'profile:verification', // Ожидает верификации анкеты
  profileSigning = 'profile:signing', // На этапе подписания анкеты
  profileSigned = 'profile:signed', // Анкета подписана
  bankAwaiting = 'bank:awaiting', // Ожидает предложений от банков
  bankSelected = 'bank:selected', // Выбрал банк
  bankApproved = 'bank:approved', // На этапе выбора банка
  bankPurposeAwaiting = 'bank:purposeAwaiting', // Ожидает решения от банка
  realEstateFilling = 'realEstate:filling', // На этапе заполнения Объекта недвижимости
  realEstateAwaiting = 'realEstate:awaiting', // Ожидает заполнения Объекта недвидимости
  realEstateApproved = 'realEstate:approved', // Объект недвижимости одобрен
  realEstateRejected = 'realEstate:rejected', // Объект недвижимости отклонен
  realEstateVerification = 'realEstate:verification', // Ожидает верификации Объекта недвидимости
  insuranceFilling = 'insurance:filling', // На этапе заполнения Страхования
  insuranceAwaiting = 'insurance:awaiting', // Ожидает заполнения Страхования
  insuranceApproved = 'insurance:approved', // Страхование одобрено
  insuranceRejected = 'insurance:rejected', // Страхование отклонено
  dealAwaiting = 'deal:awaiting', // Ожидает сделки
  dealApproved = 'deal:approved', // Сделка совершена - одобрено
  dealRejected = 'deal:rejected', // Сделка совершена - отклонено
}

export interface IMortgageEntryAgentClientQueryDTO {
  page?: number;
  size?: number;
  sort?: string;
  filters?: EEntryAgentClientStageStatusFilter[];
}

export interface IMortgageEntryCLientListResult {
  user: IPublicUserEntityDTO | IFullUserDTO;
  entry?: IPublicMortgageEntryEntity;
}

export type TMortgageEntryCLientListResultDTO = IAbstractListResults<IMortgageEntryCLientListResult>;
