import { Box, Typography } from '@mui/material';
import { EProfilePassportPart, ERealEstateDocumentType } from 'api/services/realEstate/realEstate.enum';
import { RealEstateFormSchema } from 'api/services/realEstate/realEstate.schema';
import Uploader from 'components/base/Uploader';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import UploadWrapper from './components/UploadWrapper';
import { useRealEstate } from '../../RealEstateProvider';

const tprefix = `interface:base.MortgageEntryRealEstateDocumentForm`;

export default function MortgageEntryRealEstateDocumentForm() {
  const { t } = useTranslation();
  const { control } = useFormContext<RealEstateFormSchema>();
  const [infoState, setInfoState] = useState<Record<string, boolean>>({});

  const handleInfoStatus = (name: string) => () => {
    setInfoState((prev) => ({
      ...infoState,
      [name]: !prev[name],
    }));
  };

  const handleMultipleFile = (field: ControllerRenderProps<RealEstateFormSchema, 'documents.other'>) => (files?: string[]) => {
    if (files) {
      const result = {
        type: ERealEstateDocumentType.other,
        links: files.map((fileLink) => ({
          part: EProfilePassportPart.firstPage,
          src: fileLink,
        })),
      };
      field.onChange(result);
    }
  };

  const mutipleFileFieldValue = (field: ControllerRenderProps<RealEstateFormSchema, 'documents.other'>): string => {
    const value = field.value as { links: { part: string; src: string }[] };
    if (!value?.links) {
      return '';
    }
    const links = value.links.map((i) => i.src).join(';');
    return links;
  };
  const { isFormDisabled } = useRealEstate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '130%' }}>Объект</Typography>

        <UploadWrapper
          disabled={isFormDisabled}
          type={ERealEstateDocumentType.EGRN}
          control={control}
          onClickInfo={handleInfoStatus}
          name='documents.egrn'
          label={t(`${tprefix}.egrn`)}
        />
        <UploadWrapper
          disabled={isFormDisabled}
          type={ERealEstateDocumentType.certificatOfOwnershipRegistration}
          control={control}
          onClickInfo={handleInfoStatus}
          name='documents.certificatOfOwnershipRegistration'
          label={t(`${tprefix}.certificatOfOwnershipRegistration`)}
        />
        <UploadWrapper
          disabled={isFormDisabled}
          type={ERealEstateDocumentType.rightsOfOwnership}
          control={control}
          onClickInfo={handleInfoStatus}
          name='documents.rightsOfOwnership'
          label={t(`${tprefix}.rightsOfOwnership`)}
        />
        <UploadWrapper
          disabled={isFormDisabled}
          type={ERealEstateDocumentType.technicalPassportOfApartment}
          control={control}
          onClickInfo={handleInfoStatus}
          name='documents.technicalPassportOfApartment'
          label={t(`${tprefix}.technicalPassportOfApartment`)}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '130%' }}>{t(`${tprefix}.vendor`)}</Typography>
        <UploadWrapper
          disabled={isFormDisabled}
          type={ERealEstateDocumentType.passportRf}
          control={control}
          onClickInfo={handleInfoStatus}
          name='documents.passportRf'
          label={t(`${tprefix}.passportRf`)}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '130%' }}>{t(`${tprefix}.other`)}</Typography>

        <Controller
          name='documents.other'
          render={({ field }) => (
            <Uploader
              disabled={isFormDisabled}
              //@ts-ignore
              fileLink={mutipleFileFieldValue(field)}
              //@ts-ignore
              onChange={handleMultipleFile(field)}
            />
          )}
        />
      </Box>
    </Box>
  );
}
