import { Checkbox, FormControlLabel } from '@mui/material';
import { forwardRef } from 'react';

const BaseCheckbox = (props, ref) => {
  return (
    <FormControlLabel
      control={<Checkbox sx={{ width: '16px', height: '16px', marginRight: '16px' }} {...props} />}
      sx={{ fontSize: '12px', padding: '8px 16px', width: 1 }}
      {...props}
      ref={ref}
    />
  );
};
export default forwardRef(BaseCheckbox);
