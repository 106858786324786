import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EBank } from 'api/services/bank.dto';
import { MapIcons } from 'modules/Propose/icons';
import { getBankListImplicit } from 'modules/Propose/utils';
import { useProposeCommon } from '../../context';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function ProposeCheckbox({ bank, onChange, value }: { bank: string; onChange: (bank: string, checked: boolean) => void; value: string[] }) {
  const [checked, setChecked] = useState(value.includes(bank));
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(bank, e.target.checked);
    setChecked(e.target.checked);
  };

  return (
    <FormControlLabel
      name={bank}
      label=''
      control={
        <Box
          sx={{
            width: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            padding: '8px',
          }}
        >
          <Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
              alignItems: { md: 'center', xs: 'center' },
            }}
          >
            <SvgIcon
              component={MapIcons[bank] || AccountBalanceIcon}
              sx={{ width: 48, height: 48, borderRadius: bank !== 'kamkom' ? '50%' : 'none' }}
            />

            <Typography variant='h4' sx={{ whiteSpace: 'nowrap' }}>
              {t(`common:banks.${bank}`)}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
}

interface ProposeSelectorProps {
  excludedList?: string[];
  sourceList: string[];
}

export default function ProposeSelector(props: ProposeSelectorProps) {
  const theme = useTheme();
  const proposeHook = useProposeCommon();

  const [bankList, setBankList] = useState<string[]>(() => getBankListImplicit(props.sourceList, props.excludedList));
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (bank: string, checked: boolean) => {
    setBankList((prev) => {
      const list = [...prev];
      if (checked) {
        list.push(bank);
        return [...new Set(list)];
      } else {
        const updated = list.filter((x) => x !== bank);
        return [...new Set(updated)];
      }
    });
  };

  const handleExcludeBank = async () => {
    try {
      const implicitList = (props.sourceList as EBank[]).filter((x) => !bankList.includes(x));
      await proposeHook.managerExcludeBanks(implicitList as EBank[]);
    } catch (err) {
    } finally {
      handleClose();
    }
  };

  const handleClean = () => {
    setBankList(() => getBankListImplicit(props.sourceList, props.excludedList));
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Button data-testid='propose.sendbank' size='large' variant='contained' color='primary' fullWidth onClick={handleOpen}>
          {t('interface:base.ProposeSelector.sendBank')}
        </Button>
        <Dialog
          sx={{
            '.MuiPaper-root': {
              maxWidth: '1200px',
            },
          }}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby='propose-selector-title'
          aria-describedby='propose-selector-description'
          maxWidth='xl'
        >
          <DialogTitle id='propose-selector-title'>
            <Typography sx={{ fontWeight: 500, fontSize: '26px' }}>{t('interface:base.ProposeSelector.title')}</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container gap='24px'>
              {Object.values(props.sourceList).map((bank) => (
                <Grid
                  key={bank}
                  item
                  xs={12}
                  lg={4}
                  sx={{
                    background: theme.palette.secondary.main,
                    borderRadius: '8px',
                  }}
                >
                  <ProposeCheckbox value={bankList} bank={bank} onChange={handleChange} />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: { md: '30px', xs: 0 } }}>
            <Button onClick={handleClean} color='secondary' sx={{ color: theme.palette.common.link }}>
              {t('interface:base.ProposeSelector.cancel')}
            </Button>
            <Button onClick={handleExcludeBank} variant='contained'>
              {t('interface:base.ProposeSelector.apply')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
