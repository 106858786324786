import { Skeleton } from '@mui/material';
import { ApiError } from 'api/errors';
import { RealEstateEntity } from 'api/services/realEstate/realEstate.interface';
import { getRealEstate } from 'api/services/realEstate/realEstate.service';
import ErrorMessage from 'components/base/ErrorMessage';
import { InfoField } from 'pages/Client/Mortgage/Entry/Insurance/components/InfoField';
import { Accordion } from 'pages/Client/Mortgage/Entry/Insurance/components/accordion/Accordion';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import utils from 'utils';

interface AccordionBailProps {
  title?: string;
}

export const AccordionBail: FC<AccordionBailProps> = ({ title = 'title' }) => {
  const [realEstate, setRealEstate] = useState<RealEstateEntity>();
  const [error, setError] = useState<ApiError>();
  const { t } = useTranslation();

  const { uuidEntry } = useParams();

  const fetchData = async () => {
    try {
      const realEstate = await getRealEstate({ uuidEntry });
      setRealEstate(realEstate.body);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    getRealEstate({ uuidEntry }).then(console.log);
    fetchData();
  }, []);

  const prefix = 'interface:page.MortgageEntryInsurancePage.InsuranceBaseDataForm';

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (realEstate === undefined) {
    return <Skeleton variant='rectangular' width={'100%'} height={64} />;
  }

  //TODO
  return (
    <Accordion title={t(`${prefix}.pledge.${title}`)} helperText={t(`${prefix}.helperText`)}>
      {/*<InfoField title={'Квартира в многоквартирном доме'} helperText={t(`${prefix}.bail.object`)} />*/}
      <InfoField title={realEstate.data?.address?.fullAddress} helperText={t(`${prefix}.bail.address`)} />
      {/*<InfoField title={'62 м2'} helperText={t(`${prefix}.bail.area`)} />*/}
      {/*<InfoField title={'55 м2'} helperText={t(`${prefix}.bail.liveArea`)} />*/}
      {/*<InfoField title={'2020'} helperText={t(`${prefix}.bail.created`)} />*/}
      <InfoField title={utils.format.price(realEstate.price || 0)} helperText={t(`${prefix}.bail.price`)} />
      <InfoField title={realEstate.data?.address?.floor} helperText={t(`${prefix}.bail.floor`)} />
      {/*<InfoField title={realEstate.data?.address.floor} helperText={t(`${prefix}.bail.buildingFloor`)} />*/}
      {/*<InfoField title={'33 : 14 : 000713 : 280'} helperText={t(`${prefix}.bail.number`)} />*/}
      <InfoField title={realEstate.data?.rooms} helperText={t(`${prefix}.bail.roomsCount`)} />
    </Accordion>
  );
};
