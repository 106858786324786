import { Done } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { IMortgageEntryProgress } from 'api/services/mortgage.dto';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import StatusDot from 'components/base/StatusDot';
import React, { Fragment, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProgressUtils } from '../../utils';

interface Props {
  expanded: string;
  name: keyof IMortgageEntryProgress;
  progress?: IMortgageEntryProgress;
  onExpand: (step: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => void;
  disabled: boolean;
  onClickItem?: (path: string) => () => void;
  children?: ReactNode;
  progressUtils: ProgressUtils;
  progressNumber?: number;
  sx: SxProps;
  disabledFields?: string[];
  accordionButton?: React.ReactNode;
}

function ProgressItem({
  accordionButton,
  sx,
  name,
  expanded,
  progress,
  progressNumber,
  onExpand,
  disabled,
  onClickItem,
  children,
  progressUtils,
  disabledFields = [],
}: Readonly<Props>) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { progressValue, progressColor } = progressUtils;

  const progressVal = progressNumber || progressValue(name);
  const isSuccess = progressVal === 100;

  return (
    <Accordion
      disabled={disabled}
      data-testid={name}
      sx={{ border: '1px solid #D2D2CF', borderRadius: '8px', boxShadow: 'none', '&:before': { height: 0 }, ...(isSuccess ? sx : {}) }}
      expanded={expanded === name}
      onChange={onExpand(name)}
    >
      <AccordionSummary sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between', margin: '20px 0' } }}>
        {isSuccess ? (
          <Fragment>
            <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '12px' }}>
              <Typography variant='h4m'>{t(`interface:page.ClientMortgageProgressPage.progress.${name}.title`)}</Typography>
              <SvgIcon sx={{ height: '16px', width: '16px', fill: theme.palette.success.main }} color={'success'} component={Done} />
            </Box>
            <Typography variant='h4' data-testid={`${name}-percent`}>
              {progressVal}%
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant='h4m'>{t(`interface:page.ClientMortgageProgressPage.progress.${name}.title`)}</Typography>
            <Typography variant='h4' data-testid={`${name}-percent`}>
              {progressVal}%
            </Typography>
          </Fragment>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {!children && <LinearProgress sx={{ height: '1px', marginBottom: '20px' }} variant='determinate' value={progressVal} />}

        {children
          ? children
          : Object.keys(progress?.[name] || {})?.map((substep: string) => {
              const disabled = disabledFields.indexOf(substep) !== -1;
              return (
                <Button
                  disabled={disabled}
                  data-testid={`${name}-${substep}`}
                  key={`${name}-${substep}`}
                  fullWidth
                  sx={{ color: `${theme.palette.text.primary}`, justifyContent: 'space-between' }}
                  endIcon={<SvgIcon sx={{ fontSize: '15px' }} component={ArrowRightIcon} />}
                  onClick={onClickItem?.(`${name}.${substep}`)}
                >
                  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    {t(`interface:page.ClientMortgageProgressPage.progress.${name}.${substep}`)}
                    <StatusDot color={progressColor(`${name}.${substep}`)} />
                  </Box>
                </Button>
              );
            })}
      </AccordionDetails>
      {accordionButton && <AccordionActions>{accordionButton}</AccordionActions>}
    </Accordion>
  );
}

export default memo(ProgressItem);
