import { Alert, Box, Button, Grid, Input, Typography } from '@mui/material';
import BaseButtonBack from 'components/base/Button/Back';
import QRCode from 'components/base/QRCode';
import ContainerLayout from 'components/layouts/ContainerLayout';
import useCopy from 'hooks/useCopy';
import TopMenu from 'pages/Client/Home/components/TopMenu/TopMenu';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT_MORTGAGE_ENTRY_CREATE, PATH_GETAPP, PATH_HOME } from 'routes/paths';

const INVITE_URL = [import.meta.env.REACT_APP_HOST || `https://${window.location.host}`, PATH_GETAPP].join('');
const hasShareAPI = !!navigator.share;

const ClientMortgageEntryCreateInvitePage = () => {
  const { t } = useTranslation();
  const [copied, copy, setCopied] = useCopy(INVITE_URL);

  const handleBtnShareClick = (): void => {
    if (!hasShareAPI) return;
    navigator.share({ url: INVITE_URL });
  };

  const handleLinkInputClick = () => {
    copy();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <ContainerLayout
      title={t('helmet:ClientMortgageEntryCreateInvitePage.title')}
      meta={[{ name: 'description', content: t('helmet:ClientMortgageEntryCreateInvitePage.description') }]}
      header={{
        xs: <BaseButtonBack title={t('interface:page.ClientMortgageEntryCreateInvitePage.haderTitle')} />,
        lg: (
          <TopMenu
            breadcrumbs={[
              { title: t('interface:base.TopMenu.routes.home'), href: PATH_HOME },
              { title: t('interface:base.TopMenu.routes.selectRole'), activeLink: false, href: PATH_CLIENT_MORTGAGE_ENTRY_CREATE },
              { title: t('interface:page.ClientMortgageEntryCreateInvitePage.haderTitle'), activeLink: true },
            ]}
          />
        ),
      }}
      maxWidth='xl'
      sx={{ height: 1, paddingRight: 0 }}
    >
      {copied && (
        <Alert sx={{ position: 'absolute', top: '20px', left: '20px', width: 'calc(100% - 40px)' }} severity='info' variant='filled'>
          {t('interface:page.AgentInsurancePropertyOrderSharePage.copiedLbl')}
        </Alert>
      )}
      <Grid container spacing={3} sx={{ height: { xs: 0.9, md: 0.5 }, width: 1 }}>
        <Grid item md={12} sx={{ order: { md: 1, xs: 1 }, display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography variant='h3m' sx={{ fontSize: { md: '32px', xs: '22px' }, fontWeight: { md: 700, xs: 700 } }}>
            {t(`interface:page.ClientMortgageEntryCreateInvitePage.title`)}
          </Typography>
          <Typography variant='h5' sx={{ fontSize: { xs: '16px', md: '18px' } }}>
            {t(`interface:page.ClientMortgageEntryCreateInvitePage.subtitle`)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          md={12}
          xs={12}
          sx={{
            display: { md: 'flex', xs: 'flex' },
            order: { md: 2, xs: 3 },
            alignSelf: { md: 'start', xs: 'end' },
          }}
        >
          <Grid item xs={12} md={3}>
            <Input
              fullWidth
              sx={{ width: { xs: '100%', md: '320px' } }}
              variant='shareLink'
              value={INVITE_URL}
              onClick={handleLinkInputClick}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {hasShareAPI && (
              <Button fullWidth sx={{ width: { xs: '100%', md: '320px' } }} variant='contained' size='large' onClick={handleBtnShareClick}>
                {t(`interface:page.ClientMortgageEntryCreateInvitePage.btnShare`)}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item md={4} xs={12} sx={{ order: { md: 3, xs: 2 }, height: '300px' }}>
          <Box sx={{ display: { md: 'block', xs: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
            <QRCode url={INVITE_URL} />
          </Box>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
};

export default ClientMortgageEntryCreateInvitePage;
