/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const MetalInvest = createSvgIcon(
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='48' rx='24' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.7299 7.99988C28.943 8.22876 32.5475 9.87684 35.5131 12.9593C38.4941 16.0418 40 19.7194 40 23.977C40 28.3718 38.4181 32.141 35.3004 35.2846C32.1673 38.4129 28.3802 39.9999 23.9695 39.9999C19.5893 39.9999 15.8328 38.4129 12.7149 35.2846C9.58181 32.1562 8 28.4024 8 23.977C8 19.7194 9.50579 16.0418 12.4868 12.9289C15.4525 9.83098 19.0571 8.1829 23.2852 7.99988V12.5472C20.2738 12.7304 17.7338 13.9207 15.6655 16.1333C13.5514 18.3461 12.4868 21.0012 12.4868 24.0226C12.4868 27.6545 13.9316 30.6302 16.8061 32.9193L21.1559 21.5049H18.175C17.7947 21.4286 17.4905 21.4742 17.2624 21.7033C17.0495 21.9168 16.8061 22.1153 16.5171 22.3441L16.5476 20.2535L16.8974 19.0176C17.1255 18.224 17.384 17.6136 17.7186 17.1865C18.2966 16.5759 19.0571 16.2096 19.9392 16.1333H29.5819L28.152 17.644L31.4524 32.7209C34.0837 30.4319 35.4067 27.5326 35.4373 24.0226C35.4067 21.0012 34.3727 18.3461 32.3194 16.1333C30.2663 13.9207 27.7263 12.7304 24.7299 12.5472V7.99988ZM21.0343 17.4303C20.7301 17.4303 20.578 17.6136 20.578 17.9492C20.578 18.2698 20.7301 18.4222 21.0343 18.4222C21.4145 18.4222 21.5969 18.2698 21.5969 17.9492C21.5969 17.6136 21.4145 17.4303 21.0343 17.4303'
      fill='#255284'
    />
  </svg>,
  'MetalInvest',
);

export default MetalInvest;
