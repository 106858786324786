/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const LogoIcon = createSvgIcon(
  <svg viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='88' height='88' rx='16' fill='#F8E11A' />
    <path
      d='M50.2712 64.9232V52.4723C50.2712 51.922 50.0509 51.3942 49.6588 51.005C49.2667 50.6158 48.7348 50.3972 48.1803 50.3972H39.8166C39.2621 50.3972 38.7303 50.6158 38.3382 51.005C37.946 51.3942 37.7257 51.922 37.7257 52.4723V64.9232C37.7257 65.4735 37.5055 66.0013 37.1134 66.3905C36.7214 66.7796 36.1896 66.9983 35.6351 66.9984L23.0912 67C22.8166 67 22.5446 66.9464 22.2909 66.8421C22.0372 66.7378 21.8067 66.585 21.6125 66.3923C21.4183 66.1996 21.2643 65.9708 21.1592 65.7191C21.0541 65.4673 21 65.1974 21 64.9249V40.9417C21 40.6526 21.0609 40.3667 21.1787 40.1023C21.2965 39.8379 21.4687 39.6008 21.6843 39.4064L42.5919 20.5398C42.9768 20.1925 43.4783 20 43.9985 20C44.5187 20 45.0202 20.1924 45.4051 20.5397L66.3156 39.4063C66.5312 39.6008 66.7034 39.8379 66.8213 40.1023C66.9391 40.3667 67 40.6526 67 40.9418V64.9249C67 65.1974 66.9459 65.4673 66.8408 65.7191C66.7357 65.9708 66.5817 66.1996 66.3875 66.3923C66.1933 66.585 65.9628 66.7378 65.7091 66.8421C65.4554 66.9464 65.1834 67 64.9088 67L52.3618 66.9984C51.8073 66.9983 51.2756 66.7796 50.8835 66.3905C50.4914 66.0013 50.2712 65.4736 50.2712 64.9232V64.9232Z'
      stroke='#0C0B02'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'LogoIcon',
);

export default LogoIcon;
