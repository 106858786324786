export enum EEsiaAuthType {
  justAuth = 'justAuth',
  authProfile = 'authProfile',
}

export const genEsiaUrl = () => {
  const { REACT_APP_ESIA_HOST: host } = import.meta.env;

  return genEsiaGatewayUrl(`${host}`);
};

export const genEsiaGatewayUrl = (type: string) => {
  switch (type) {
    case 'dev2nick':
      return [
        'https://dpweb.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=ef52b8a0cbe04c76baab63fd14f9ca4e',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=http://192.168.0.122:3000/auth/gos',
        '&force_esia=true',
      ].join('');
    case 'dev2local':
      return [
        'https://dpweb.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=ecddd0bd4aaa24a64e8353bf6568bb3c',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=http://localhost:3000/auth/gos',
        '&force_esia=true',
      ].join('');
    case 'dev2dev':
      return [
        'https://dpweb.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=b42fb55e661b5210d698f38ccf62e240',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=https://app.ip.mindcode.ru/auth/gos',
        '&force_esia=true',
      ].join('');
    case 'dev2prod':
      return [
        'https://dpweb.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=9a694de531c745543a9fee4897c4cd54',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=https://app.kopidoo.ru/auth/gos',
        '&force_esia=true',
      ].join('');
    case 'prod2local':
      return [
        'https://dpgate.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=6acdd661931da538916e2e69aa42d73a',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=http://localhost:3000/auth/gos',
        '&force_esia=true',
      ].join('');
    case 'prod2dev':
      return [
        'https://dpgate.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=41f8a448f114fe83b6953f1807e5526a',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=https://app.ip.mindcode.ru/auth/gos',
        '&force_esia=true',
      ].join('');
    case 'prod2prod':
    default:
      return [
        'https://dpgate.resocreditbank.ru/prophet/authorizations/new',
        '?client_id=673e59a0157e14ff06b56443e5a51f38',
        '&response_type=code',
        '&scope=openid%20profile',
        '&redirect_uri=https://app.kopidoo.ru/auth/gos',
        '&force_esia=true',
      ].join('');
  }
};
