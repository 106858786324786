import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { RenderNode } from 'components/base/FormGenerator';
import _ from 'lodash';
import { Fragment, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectType } from 'utils/iots';

import { ft, getAllDependedKeys } from '../../../utils';
import { WrappedSchemeData } from '../../types';

const setValueObject = (_item: ObjectType, defaultValue: ObjectType, path: string[]) => {
  if (!defaultValue.value) {
    return {};
  }
  const obj = {};
  Object.entries(defaultValue.value).forEach(([k, v]) => {
    _.set(obj, [...path, k], v);
  });
  return obj;
};

interface InlineGroupWrapperProps {
  item: WrappedSchemeData;
  path: string[];
  index: number;
  defaultValue: ObjectType;
  onValueUpdate: (inputValues: ObjectType, ppath: string[], index: number, uuid: string) => void;
  onRemoveValue: (uuid: string) => void;
  noIndex: boolean;
}

export default function InlineGroupWrapper({
  item,
  path,
  index,
  onValueUpdate,
  defaultValue,
  onRemoveValue,
  noIndex,
}: InlineGroupWrapperProps) {
  const { t } = useTranslation();
  const hookForm = useForm({
    defaultValues: useMemo(() => {
      return defaultValue ? setValueObject(item, defaultValue, [...path, item.name] as string[]) : {};
    }, []),
  });

  useEffect(() => {
    const watcher = hookForm.watch((data) => {
      onValueUpdate(data, [...path, item.name] as string[], index, item.uuid);
    });

    return () => {
      watcher.unsubscribe();
    };
  }, []);
  const dependedFields = useMemo(() => {
    return getAllDependedKeys(item);
  }, [item]);

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography sx={{ width: 1 }} variant='h2b'>
          {t(`${ft([...path, item.name])}.title`)} {!noIndex && index + 1}
        </Typography>
        {!noIndex && (
          <IconButton
            onClick={() => {
              onRemoveValue(item.uuid);
            }}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        )}
      </Box>
      {item?.data?.map((nodeData) => (
        <RenderNode
          key={`${item.uuid}__${nodeData.name}`}
          node={nodeData}
          hookForm={hookForm}
          path={[...path, item.name] as string[]}
          dependedFields={dependedFields}
          options={nodeData.settings}
        />
      ))}
    </Fragment>
  );
}
