import Typography from '@mui/material/Typography';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import NumberFormat from 'react-number-format';
import { Controller, UseFormReturn } from 'react-hook-form';
import { suggestFullCompany, suggestSearchCompany } from 'api/services/suggestions';
import { SuggestionCompanyResponse } from 'api/services/suggestions.dto';
import DadataSuggestion from 'components/base/DadataSuggestion';
import { EProfileWorkspaceJobSchema } from 'api/services/profile/profile.enum';
import utils from 'utils';
import { EProfileWorkspaceType } from 'api/services/incomes/incomes.enum';
import DatePickerInput from 'components/base/DatePickerInput';
import { useTranslation } from 'react-i18next';
import { IFillingQuestionnaireDTO } from 'api/services/experiment/experiment.dto';
import { useState } from 'react';

export default function IncomeExperimentForm({ hookForm }: { hookForm: UseFormReturn<IFillingQuestionnaireDTO> }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isShowInfo, setShowInfo] = useState(false);

  const suggestionCompany = async (data: SuggestionCompanyResponse) => {
    if (!data || !data.inn) {
      return;
    }
    try {
      const res = await suggestFullCompany(String(data.inn));
      hookForm.setValue('companyName', res.body.name);
      hookForm.setValue('companyInn', data.inn);
      hookForm.setValue('companyPhone', utils.format.digitNumber(res.body.phone) || undefined);
      hookForm.setValue('companyAddress', res.body.address);
    } catch (err) {
      //todo
      console.log(err);
    }
  };
  const handleSelect = (selectedValue: SuggestionCompanyResponse) => {
    suggestionCompany(selectedValue);
  };

  const jobType = hookForm.watch('jobType');

  const companyName = hookForm.watch('companyName');
  const companyInn = hookForm.watch('companyInn');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h3' sx={{ fontSize: '22px', fontWeight: '700' }}>
          Расскажите о работе
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={hookForm.control}
          name={`jobType`}
          render={({ field, fieldState }) => (
            <TextField
              required
              select
              variant='standard'
              label="Выберите тип занятости "
              fullWidth
              {...field}
              value={`${field.value}`}
              defaultValue={`${field.value}`}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ 'data-testid': field.name }}
            >
              {Object.values(EProfileWorkspaceType)
                .filter((x) => x !== EProfileWorkspaceType.selfEmployed)
                .map((workplaceType) => (
                  <MenuItem value={workplaceType} key={workplaceType} data-testid={`${field.name}.${workplaceType}`}>
                    {t(`page.IncomesForm.workplaceType.${workplaceType}`)}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="jobSchema"
          control={hookForm.control}
          render={({ field }) => (
            <FormControl>
              <FormLabel
                sx={{ color: 'common.black', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant='h4m'>По какой схеме вы работаете ?</Typography>
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    setShowInfo((prev) => !prev);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </FormLabel>
              <Collapse in={isShowInfo}>
                <Box>
                  <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>
                    Выберите опцию "белая", если вы полностью официально трудоустроены, то есть ваша зарплата полностью облагается налогом и
                    подтверждается соответствующими документами.
                  </Typography>
                  <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>
                    Выберите опцию "серая", если ваша зарплата хотя бы частично не облагается налогом и не подтверждается официальными
                    документами (так называемая "зарплата в конверте").
                  </Typography>
                  {/* <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}>
                    Выбор типа вашего дохода влияет на набор документов, которые необходимо предоставить для подтверждения вашего дохода.
                  </Typography> */}
                </Box>
              </Collapse>
              <RadioGroup row {...field} aria-labelledby={field.name} value={`${field.value}`} defaultValue={`${field.value}`}>
                <FormControlLabel
                  value={EProfileWorkspaceJobSchema.white}
                  control={<Radio required inputProps={{ 'data-testid': `${field.name}.${EProfileWorkspaceJobSchema.white}` } as any} />}
                  label="Белая"
                />
                <FormControlLabel
                  value={EProfileWorkspaceJobSchema.grey}
                  control={<Radio inputProps={{ 'data-testid': `${field.name}.${EProfileWorkspaceJobSchema.grey}` } as any} />}
                  label="Серая"
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <DadataSuggestion
          label='Наименование компании'
          variant='standard'
          onSelect={handleSelect}
          path={['name']}
          onQuery={suggestSearchCompany}
          placeholder={t('base.CompanySearch.searchPlaceholder')}
          data-testid="company.search"
        />
      </Grid>
      {companyName && companyInn && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('page.IncomesForm.info.companyName')}
              </Typography>
              <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                {companyName}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={{ color: theme.palette.text.secondary }} variant='h6'>
                {t('page.IncomesForm.info.inn')}
              </Typography>
              <Typography variant='h5m' sx={{ fontSize: '18px' }}>
                {companyInn}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {[EProfileWorkspaceType.employee, EProfileWorkspaceType.individualEntrepreneur, EProfileWorkspaceType.owner].includes(jobType) && (
        <Grid item xs={12}>
          <Controller
            name={`contractStartDate`}
            control={hookForm.control}
            render={({ field }) => (
              <DatePickerInput data-testid={field.name} label={t('page.IncomesForm.contractStartDate.title')} {...field} />
            )}
          />
        </Grid>
      )}
      {jobType === EProfileWorkspaceType.owner && (
        <Grid item xs={12}>
          <Controller
            name={`companySharePercent`}
            control={hookForm.control}
            render={({ field, fieldState }) => (
              <NumberFormat
                variant='standard'
                suffix=' %'
                fullWidth
                customInput={TextField}
                label={t('page.IncomesForm.companySharePercent.title')}
                value={field.value}
                onValueChange={(values) => {
                  field.onChange(values.floatValue);
                }}
                error={!!fieldState.error}
                helperText={t(`${fieldState.error?.message || ''}`)}
                inputProps={{ 'data-testid': field.name }}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          control={hookForm.control}
          name={`salaryAmount`}
          render={({ field, fieldState }) => (
            <NumberFormat
              variant='standard'
              suffix=' ₽'
              fullWidth
              customInput={TextField}
              label={t('page.IncomesForm.incomeAmount.title')}
              thousandSeparator=' '
              value={field.value}
              onValueChange={(values) => {
                field.onChange(values.floatValue);
              }}
              error={!!fieldState.error}
              helperText={t(`${fieldState.error?.message || ''}`)}
              inputProps={{ 'data-testid': field.name }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
