import { StoryModel } from './types';
import StoryBox from './components/StoryBox';

const STORY_DURATION = 8000;

export const storiesEntities: StoryModel[] = [
  {
    uuid: 'story_one',
    title: 'Знакомьтесь с Копиду: Ваш Надежный Партнер для МСБ Финансирования!',
    bannerImage: {
      alt: 'phone with banks',
      src: '/img/stories_phone.png',
    },
    description: [{ content: '🌟 Копиду - это ваш компаньон в мире финансов для Малого и Среднего Бизнеса (МСБ).' }],
    tags: [{ title: '#МСБ' }, { title: '#КредитныеПродукты' }, { title: '#ПоддержкаБизнеса' }],
    sx: {
      background: 'radial-gradient(at left top, #20E1FA, #0D1466)',
    },
    shortTitle: 'Знакомьтесь с Копиду',
  },
  {
    uuid: 'story_two',
    shortTitle: 'Кредитные продукты',
    title: 'Кредитные Продукты для Малого и Среднего Бизнеса!',
    bannerImage: {
      alt: 'phone with banks',
      src: '/img/businessMan.png',
    },
    description: [{ content: '💼 С нами вы получаете доступ к гибким финансовым инструментам и персональному подходу.' }],
    tags: [{ title: '#МСБ' }, { title: '#Кредиты' }, { title: '#РостБизнеса' }],
    sx: {
      background: '#F8E11A',
    },
  },
  {
    uuid: 'story_three',
    shortTitle: 'соц.сети',
    title: 'Присоединяйтесь к нашему Телеграм-каналу для эксклюзивных обновлений!    ',
    bannerImage: {
      alt: 'phone with banks',
      src: '/img/social.png',
    },
    description: [
      { content: '📢 Узнавайте первыми о наших новых продуктах и специальных предложениях, подписавшись на наш Телеграм-канал.' },
    ],
    tags: [],
    actionElement: {
      variant: 'contained',
      color: 'secondary',
      href: 'https://t.me/kopidoo',
      sx: { zIndex: 3, color: 'common.link' },
      target: '_black',
      children: ['Присоединяйтесь прямо сейчас'],
    },
    sx: {
      background: '#0FC2EB',
    },
  },

  {
    uuid: 'story_four',
    shortTitle: 'Поддержка',
    title: 'Нужна помощь или у вас есть вопросы? Напишите нам в поддержку!',
    bannerImage: {
      alt: 'phone with banks',
      src: '/img/support.png',
    },
    description: [
      {
        content: `🙋‍♂️ Наша команда поддержки всегда готова помочь вам. Если у вас возникли вопросы или есть нечто, с чем нужно разобраться, не
    стесняйтесь писать нам.`,
      },
    ],
    tags: [],
    sx: {
      background: '#D2D2CF',
    },
  },
];

export const staticStories = storiesEntities.map((story) => ({
  type: 'component',
  duration: STORY_DURATION,
  component: () => <StoryBox {...story} />,
}));
