import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import StatusDot from 'components/base/StatusDot';
import { useAuth } from 'components/providers/AuthProvider';
import { useBusinessCredit } from 'components/providers/BusinessCreditProvider';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

interface Props {
  onClick(uuidEntry: string): void;
}

export default function BusinessCreditCard(props: Props) {
  const businessCredit = useBusinessCredit();
  const { onClick } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();

  const handleSelect = () => {
    onClick(businessCredit.entries?.[0].uuid!);
  };

  if (!businessCredit.hasEntries) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      // data-urlname={pg.urlname}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evently',
        padding: '16px',
        gap: { xs: '16px', lg: '24px' },
        background: theme.palette.secondary.main,
        borderRadius: '16px',
      }}
    >
      <Box sx={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
        <Typography variant={'h3b'} sx={{ lineHeight: '130%', fontSize: { xs: '22px', lg: '26px' } }}>
          {t('interface:base.BusinessCreditCard.title')}
        </Typography>
      </Box>

      <Box>
        <Box>
          <Box sx={{ display: 'inline-flex', width: '100%', gap: '8px', alignItems: 'center' }}>
            <Typography sx={{ color: theme.palette.text.secondary }} variant='h6' component='span'>
              {t('interface:base.BusinessCreditCard.contactTitle')}
            </Typography>
            <StatusDot sx={{ width: '4px', height: '4px' }} color={theme.palette.text.secondary} />
            <Typography sx={{ flexGrow: 1, color: theme.palette.text.secondary }} variant='h6' component='span'>
              {utils.format.nameWithInitials(auth.user!)}
            </Typography>
          </Box>
          <Button
            onClick={handleSelect}
            data-testid='entry-info-btn'
            size='small'
            fullWidth
            sx={{
              padding: 0,
              heigth: '21px',
              color: `${theme.palette.text.primary}`,
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
              <StatusDot sx={{ width: '11px', height: '11px' }} color={'#F8E11A'} />
              <Typography variant={'h5'} sx={{ lineHeight: '130%' }}>
                <Fragment> {t('interface:base.BusinessCreditCard.fillEntry')}</Fragment>
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between', gap: '8px' }}>
        <Button onClick={handleSelect} fullWidth variant='contained' color='primary' size='large' data-testid={'additional'}>
          {t('interface:page.HomeClientPage.EntryCard.additional')}
        </Button>
      </Box>
    </Paper>
  );
}
