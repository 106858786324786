enum Statuses {
  IDLE = 'idle', // initial status
  FETCHING = 'fetching', // waiting request without data
  FETCHING_FAILED = 'fetchingFailed', // fetching was failed and we have no data
  LOADING = 'loading', // waiting request with data
  FAILED = 'failed', // requset was failed and we have no data
  SUCCESS = 'success', // all ok
  LOADING_MORE = 'loadingMore', // we use it on loading more in list with lazy loading
  LOADING_MORE_FAILED = 'loadingMoreFailed', // we use it when loading more was failed in list with lazy loading
  FINAl = 'final', // we use it when all data was loaded in list with lazy loading
}

export default Statuses;
export const { IDLE, FETCHING, FETCHING_FAILED, LOADING, FAILED, SUCCESS, LOADING_MORE, LOADING_MORE_FAILED, FINAl } = Statuses;
