import { ERequiredPassportParts } from '../experiment/experiment.dto';

export class ECBCreditEntryLinkedDoc {
  uuidDocument: string;

  uuidRequest?: string;
}

export class ECBCEntryProposeDate {
  createdAtTs: number;

  updatedAtTs: number;
}

export enum EBCBank {
  psb = 'psb',
  mkb = 'mkb',
  alfa = 'alfa',
  ubrir = 'ubrir',
  sberbank = 'sberbank',
  kamkom = 'kamkom',
  tinkoff = 'tinkoff',
  absolut = 'absolut',
  potok = 'potok',
  jetland = 'jetland',
  msp = 'msp',
  otkritie = 'otkritie',
  metallinvestbank = 'metallinvestbank',
  fordewind = 'fordewind',
  primsoc = 'primsoc',
  simplefinance = 'simplefinance',
  bbr = 'bbr',
  vtb = 'vtb',
  realist = 'realist',
  sgb = 'sgb',
  akbars = 'akbars',
  vdelo = 'vdelo',
}

export enum EBCEntryProposeStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
  preliminary = 'preliminary',
  dataRequested = 'dataRequested',
  failed = 'failed',
  withoutSuitablePrograms = 'withoutSuitablePrograms',
}

export enum EBCEntryFieldRequestedDataName {
  authorityGrantDate = 'authorityGrantDate',
  powersDuration = 'powersDuration',
  hasCompanyOverdueWagesMoreThan30Days = 'hasCompanyOverdueWagesMoreThan30Days',
  taxationScheme = 'taxationScheme',
  beneficiarySnils = 'beneficiarySnils',
  other = 'other',
}

export enum EFieldInputType {
  // TODO remove
  suggestion = 'input.suggestion',
  textField = 'input.textField',
  number = 'input.number',
  checkbox = 'input.checkbox',
  selector = 'input.select',
  // TODO remove
  radio = 'input.radio',
  // TODO remove
  date = 'input.date',
  // TODO remove
  slider = 'input.slider',
}
export class ECBCEntryFieldRequestedData {
  type: EBCEntryFieldRequestedDataName;

  otherType?: string;

  inputType: EFieldInputType;

  selectValue?: string[];

  isPresent: boolean;
}

export class ECBCEntryAdditionalFieldData {
  uuidRequest?: string;

  type: EBCEntryFieldRequestedDataName;

  otherType?: string;

  value: string | number | boolean;
}

export enum ERequestedDataType {
  document = 'document',
  field = 'field',
}
export type TRequestedData =
  | {
      uuid: string;
      type: ERequestedDataType.document;
      data: ECBCEntryDocRequestedData;
    }
  | {
      uuid: string;
      type: ERequestedDataType.field;
      data: ECBCEntryFieldRequestedData;
    };

export class ECBCEntryDocRequestedData {
  quarter?: any;
  year?: number;
  type: EDocumentType;
  part?: ERequiredPassportParts;
  otherType?: string;
  isPresent: boolean;
}

export interface IRequestFieldReq {
  value: string;
}

export class ECBCEntryProposeDecisionRate {
  value: number;

  commission?: number;
}

export class ECBCEntryProposeDecision {
  amount?: number;

  period?: number;

  rates: ECBCEntryProposeDecisionRate[];
}

export class ECBCEntryProposeErrorDate {
  createdAtTs: number;

  updatedAtTs: number;

  resolvedUnixTs: number | null;
}

export class ECBCEntryProposeErrorResolver {
  uuidUser: string;
}

export class ECBCEntryProposeError {
  uuid: string;

  uuidPropose: string;

  bank: EBCBank;

  date: ECBCEntryProposeErrorDate;

  isResolved: boolean;

  resolver: ECBCEntryProposeErrorResolver | null;

  error: Record<string, unknown>;
}
export class ECBCEntryPropose<T = unknown> {
  uuid: string;

  date: ECBCEntryProposeDate;

  bank: EBCBank;

  status: EBCEntryProposeStatus;

  internalBankData?: T | null;

  requestedData: TRequestedData[];

  decision: ECBCEntryProposeDecision | null;
  //
  // creationStep: ECMortgageEntryProposeCreationStep;

  errors: ECBCEntryProposeError[];
}

export class ECBCEntryPrimaryPropose {
  uuid: string;

  bank: EBCBank;

  status: EBCEntryProposeStatus;

  decision: ECBCEntryProposeDecision | null;
}

export enum EBCEntryStageStatus {
  awaiting = 'awaiting',
  inProgress = 'inProgress',
  success = 'success',
  awaitingPropose = 'awaitingPropose',
  awaitingDecision = 'awaitingDecision',
  selectedPurpose = 'selectedPropose',
}
export class ECBCEntryStageStatuses {
  status: EBCEntryStageStatus;
}

export class ECBCEntryStage {
  company?: ECBCEntryStageStatuses;

  documents?: ECBCEntryStageStatuses;

  banks?: ECBCEntryStageStatuses;
  // TODO: next steps
}

export interface IBCRequestCreate {
  data: ECBCEntryFieldRequestedData | ECBCEntryDocRequestedData;
  type: ERequestedDataType;
}

export enum EDocumentType {
  unknown = 'unknown',
  other = 'other',
  // Паспорт владельца
  passport = 'passport',
  balanceSheet = 'balanceSheet',
  // Банковская выписка
  bankStatement = 'bankStatement',
  // Налоговая декларация
  taxReturnDeclaration = 'taxReturnDeclaration',
  // Карточка счёта 51
  accountStatement51 = 'accountStatement51',
}

export enum EDocPart {
  unknown = 'unknown',
  blank = 'blank',
  passportP2_P3 = 'passportP2_P3',
  passportP4_P5 = 'passportP4_P5',
  passportP6_P7 = 'passportP6_P7',
  passportP8_P9 = 'passportP8_P9',
  passportP10_P11 = 'passportP10_P11',
  passportP12_P13 = 'passportP12_P13',
  passportP14_P15 = 'passportP14_P15',
  passportP16_P17 = 'passportP16_P17',
  passportP18_P19 = 'passportP18_P19',
}
