import { Components, Theme } from '@mui/material';

const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: () => ({
      backgroundColor: 'transparent',
    }),
  },
};

const components: Components<Theme> = {
  MuiAppBar,
};

export default components;
