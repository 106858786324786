import React from 'react';
import { IMaskInput } from 'react-imask';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

export const TextMaskCustom = React.forwardRef((props: ObjectType, ref: ObjectType) => {
  const { onChange, ...other } = props;

  const handleInput = (value: unknown) => {
    const val = other.transform?.(value) || value;
    onChange({ target: { name: props.name, value: val } });
  };
  return <IMaskInput {...other} inputRef={ref} onAccept={handleInput} />;
});

export const MASK = {
  UPPERCASE: 'uppercase',
  PASSPORT_ISSUE_UNIT: 'passportIssueUnit',
  PASSPORT_SERIES: 'passportSeries',
  PASSPORT_NUMBER: 'passportNumber',
  DRVR_LICENSE_SERIES: 'drivingLicenseSeries',
  DRVR_LICENSE_NUMBER: 'drivingLicenseNumber',
  MILITARYID_SERIES: 'militaryIDSeries',
  MILITARYID_NUMBER: 'militaryIDNumber',
  EMAIL: 'email',
  PHONE: 'phone',
  SQUARE_METERS: 'squareMeters',
  YEAR: 'year',
  CURRENCY: 'currency',
  INN: 'inn',
  INN_LEGAL: 'innLegal',
  INN_PERSON: 'innPerson',
  BIC: 'bic',
  DATE_STRING: 'dateString',
  BANK_ACCOUNT: 'bankAccount',
  BANK_BY_NAME: 'suggestBankByName',
  OGRNIP: 'ogrnip',
  SNILS: 'snils',
  ONLY_NUMBERS: 'onlyNumbers',
  DIGIT_6: 'digit:6',
  PERCENT: 'percent',
};

/**
 *
 * @param {string} value
 */
const numberMask = (value: unknown) => {
  const pattern = new RegExp(/^[0-9]+$/);
  return pattern.test(value as string);
};

export const maskComponent = (type: string, props = {}) => {
  switch (type) {
    case MASK.INN_LEGAL: {
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0000-00000-0',
          inputMode: 'numeric',
        },
      };
    }
    case MASK.SNILS:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '000-000-000 00',
          inputMode: 'numeric',
        },
      };
    case MASK.ONLY_NUMBERS:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: numberMask,

          inputMode: 'numeric',
        },
      };
    case MASK.BIC:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '000000000',
          inputMode: 'numeric',
        },
      };
    case MASK.DATE_STRING:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '00.00.0000',
          lazy: false,
          inputMode: 'numeric',
        },
      };
    case MASK.INN:
    case MASK.INN_PERSON:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0000-00000-000',
          inputMode: 'numeric',
        },
      };
    case MASK.UPPERCASE:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: /^.*$/,
          commit: function (value: ObjectType, masked: ObjectType) {
            masked._value = value.toUpperCase();
          },
        },
      };
    case MASK.PASSPORT_ISSUE_UNIT:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '000-000',
          inputMode: 'numeric',
        },
      };
    case MASK.PASSPORT_SERIES:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0000',
          inputMode: 'numeric',
        },
      };
    case MASK.PASSPORT_NUMBER:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '000000',
          inputMode: 'numeric',
        },
      };
    case MASK.DRVR_LICENSE_SERIES:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0000',
          inputMode: 'numeric',
        },
      };
    case MASK.DRVR_LICENSE_NUMBER:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '000000',
          inputMode: 'numeric',
        },
      };
    case MASK.MILITARYID_SERIES:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: /^[А-я]{1,2}$/,
          commit: function (value: ObjectType, masked: ObjectType) {
            masked._value = value.toUpperCase();
          },
          inputMode: 'text',
        },
      };
    case MASK.MILITARYID_NUMBER:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0000000',
          inputMode: 'numeric',
        },
      };
    case MASK.EMAIL:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: /^\S*@?\S*$/,
        },
      };
    case MASK.OGRNIP:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          inputMode: 'numeric',
          mask: '000 000 000 000 000',
        },
      };
    case MASK.PHONE:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          // overwrite: true,
          type: 'text',
          inputMode: 'numeric',
          mask: '+7 000 000 00 00',
          transform: utils.format.digitNumber,
        },
      };
    case MASK.SQUARE_METERS:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0[000] кв. м.',
          inputMode: 'numeric',
          lazy: false,
          transform: utils.format.digitNumber,
        },
      };
    case MASK.PERCENT:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: Number,
          // mask: '0[00]%',
          min: 1,
          max: 100,
          inputMode: 'numeric',
          lazy: false,
        },
      };
    case MASK.BANK_ACCOUNT:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          type: 'text',
          inputMode: 'numeric',
          mask: '0000 0000 0000 0000 0000',
        },
      };
    case MASK.YEAR:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: '0000',
        },
      };
    case MASK.DIGIT_6:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          mask: Number,
          min: 1,
          max: 999999,
        },
      };

    case MASK.CURRENCY:
      return {
        inputComponent: TextMaskCustom,
        inputProps: {
          ...props,
          includeThousandsSeparator: true,
          allowNegative: false,
          allowLeadingZeroes: false,

          mask: Number,
          inputMode: 'numeric',
          lazy: false,
          thousandsSeparator: ' ',
        },
      };
  }
  return { inputProps: props };
};
