import { ButtonBase, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';

const storesSize = { height: { lg: '130px', xs: '80px' }, width: { lg: '100%', xs: '80px' } };

export default function ({
  src,
  title,
  onClickStory,
  background,
}: {
  src: string;
  title: string;
  onClickStory: () => void;
  background: SxProps;
}) {
  return (
    <Box
      component={ButtonBase}
      onClick={onClickStory}
      sx={{
        ...storesSize,
        ...background,
        border: '2px solid #f5f5f5',
        borderRadius: '16px',
        backgroundImage: `url(${src})`,
        backgroundSize: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <Typography
        sx={{
          width: '100%',
          fontSize: { lg: '14px', xs: '10px' },
          fontWeight: 'bold',
          color: '#fff',
          ///background: 'rgba(0, 0, 0, 0.18)',
          backgroundImage: 'linear-gradient(to top, #0d0d0d7b 20%, transparent 100%)',
          borderRadius: '0px 0px 16px 16px',
          padding: '0px 8px 4px 8px',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
