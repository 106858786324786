import { LoadingButton } from '@mui/lab';
import { Box, styled, TextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import useTimer from 'hooks/useTimer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';
import { Nullable } from 'utils/iots';
const RESEND_TIMEOUT = 30;

const SmsTextField = styled(TextField)((props) => ({
  padding: 0,
  marginBottom: 16,
  '& .MuiFilledInput-root': {
    height: 'inherit',
    borderRadius: 8,
    padding: { xs: '11px 0', lg: '15px 0 18px' },
    fontSize: { xs: '18px', lg: '22px' },
    color: props.error ? '#E81E1E' : undefined,
    backgroundColor: props.error ? '#FFEAEA' : undefined,
    '& fieldset': {
      border: 'none',
    },
    '&:hover': {
      color: props.error ? '#E81E1E' : undefined,
      backgroundColor: props.error ? '#FFEAEA' : undefined,
    },
    '&:hover:before': {
      border: 'none',
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'none',
      borderBottom: 'none',
    },
    '&:hover::after': {
      border: 'none',
      borderBottom: 'none',
    },
    '& input': {
      boxSizing: 'border-box',
    },
    '& input::placeholder': {
      fontSize: { xs: '18px', lg: '22px' },
      letterSpacing: 0.5,
      fontWeight: '400',
    },
    '&::before': {
      border: 'none',
    },
    '&::after': {
      border: 'none',
    },
  },
}));
interface Props {
  resendCodeRequest: () => Promise<void>;
  checkCodeRequest: (code: string) => Promise<boolean | undefined> | Promise<void>;
  pathAfterCheck?: string;
}

// TODO: Need refactoring for whole component
const CommonCheckCode = ({ resendCodeRequest, checkCodeRequest, pathAfterCheck = '' }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { timer, isActive: isTimerActive, handleStart: startTimer, handleReset: stopTimer } = useTimer(RESEND_TIMEOUT, 'descending');
  const [loading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const navigate = useNavigate();

  const getErrorMessage = (entity: string, type: string) => t(`errors:${type}.${entity}`);

  const sendNewCodeTimer = t('interface:base.CommonCheckCode.sendNewCodeTimer', { timer });
  const sendNewCode = t('interface:base.CommonCheckCode.sendNewCode');
  const agreeBtn = t('interface:base.CommonCheckCode.confirm');

  const [buttonText, setButtonText] = useState(sendNewCodeTimer);

  const onInputCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > 4) return;
    setCode(value);
  };

  const resendCode = async () => {
    await resendCodeRequest();

    setCode('');
    stopTimer();
    startTimer();
  };

  const handleCommonCheckCode = () => {
    checkCodeRequest(code).then((isOk) => {
      if (isOk)
        if (pathAfterCheck) {
          navigate(PATH_HOME);
        }
    });
  };

  const codeLength = code.length;
  const activeError = error;
  const buttonAction = codeLength !== 4 || activeError ? resendCode : handleCommonCheckCode;

  useEffect(() => {
    if (codeLength !== 4) {
      setError(null);
    }

    if (codeLength !== 4 || isTimerActive) {
      setDisabled(true);

      if (isTimerActive) {
        setButtonText(sendNewCodeTimer);

        if (codeLength === 4) {
          setButtonText(agreeBtn);
          setDisabled(false);
        }
      } else {
        if (codeLength === 0) {
          setButtonText(sendNewCode);
          setDisabled(false);
        } else {
          setButtonText(agreeBtn);
          setDisabled(true);
        }
      }
    } else {
      setButtonText(agreeBtn);
      setDisabled(false);
    }
  }, [timer, isTimerActive, codeLength, activeError, sendNewCodeTimer, agreeBtn, sendNewCode]);

  useEffect(() => {
    startTimer();

    return stopTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timer === 0) {
      stopTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const isDesktop = useMediaQuery<Theme>(theme?.breakpoints?.up('sm'), { noSsr: true });

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { md: '16px', xs: 0 } }}>
      <SmsTextField
        fullWidth
        hiddenLabel
        value={code}
        error={!!error}
        inputProps={{
          min: 0,
          step: 1,
          style: { textAlign: 'center', letterSpacing: 16, fontWeight: 'bold', fontSize: isDesktop ? '32px' : '26px' },
        }}
        placeholder={t('interface:base.CommonCheckCode.SMSPlaceholder')}
        data-testid={'SMSPlaceholder'}
        onChange={onInputCode}
        variant='filled'
        type='number'
        sx={{
          height: { xs: '56px', lg: '62px' },
          'input::-webkit-inner-spin-button': {
            display: 'none',
            '-webkit-appearance': 'none',
          },
          'input::placeholder': {
            fontSize: { xs: '18px', lg: '22px' },
          },
        }}
      />

      {error && (
        <Typography sx={{ marginBottom: 1, color: 'red.main' }} textAlign={'center'} component='p' variant='h6'>
          {getErrorMessage('invalidPhoneCode', 'auth')}
        </Typography>
      )}

      <LoadingButton
        sx={{
          height: { xs: '56px', lg: '62px' },
          fontWeight: 'regular',
          fontSize: { xs: '18px', md: '22px' },
          textTransform: 'none',
        }}
        fullWidth
        disabled={disabled}
        size='large'
        variant='contained'
        loading={loading}
        onClick={buttonAction}
      >
        {buttonText}
      </LoadingButton>
    </Box>
  );
};

export default CommonCheckCode;
