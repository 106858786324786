/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

const SberIcon = createSvgIcon(
  <svg viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M35.8514 7.77051C36.7889 8.99885 37.5826 10.3333 38.2163 11.7472L20.2395 25.1772L12.7266 20.4042V14.6622L20.2395 19.4351L35.8514 7.77051Z'
      fill='#21A038'
    />
    <path
      d='M5.05927 19.9991C5.05927 19.7414 5.06547 19.4855 5.07787 19.231L0.524797 19.0049C0.5093 19.3347 0.500006 19.6677 0.500006 20.0037C0.498068 22.6295 1.00769 25.2298 1.99964 27.6554C2.99159 30.0811 4.44634 32.2844 6.28045 34.1389L9.51005 30.8674C8.09823 29.4424 6.97822 27.7486 6.2144 25.8834C5.45058 24.0182 5.05802 22.0185 5.05927 19.9991Z'
      fill='url(#paint0_linear_63_1119)'
    />
    <path
      d='M20.2355 4.62042C20.4896 4.62042 20.7422 4.62985 20.9932 4.64241L21.2211 0.0265021C20.8947 0.0107964 20.5661 0.00294452 20.2355 0.00294452C17.6434 -0.000259062 15.0763 0.515474 12.6814 1.52053C10.2866 2.52559 8.11123 4.00017 6.28027 5.85962L9.50988 9.13268C10.9161 7.70139 12.5876 6.5659 14.4283 5.79152C16.269 5.01715 18.2425 4.61915 20.2355 4.62042Z'
      fill='url(#paint1_linear_63_1119)'
    />
    <path
      d='M20.2361 35.3798C19.982 35.3798 19.7294 35.3798 19.4768 35.3594L19.249 39.9737C19.5765 39.9904 19.9056 39.9989 20.2361 39.9989C22.8272 40.0016 25.3931 39.4855 27.7866 38.4802C30.1802 37.4748 32.3541 36 34.1837 34.1406L30.9603 30.8691C29.5538 32.2997 27.8825 33.4345 26.0422 34.2086C24.2019 34.9827 22.2287 35.3808 20.2361 35.3798Z'
      fill='url(#paint2_linear_63_1119)'
    />
    <path
      d='M28.7902 7.30319L32.6273 4.43689C29.1202 1.55876 24.7423 -0.00798939 20.2295 3.06379e-05V4.61908C23.2854 4.61524 26.2703 5.55117 28.7902 7.30319Z'
      fill='url(#paint3_linear_63_1119)'
    />
    <path
      d='M39.9702 20.0001C39.9727 18.7985 39.869 17.5989 39.6602 16.416L35.4125 19.5886C35.4125 19.7253 35.4125 19.8619 35.4125 20.0001C35.4136 22.1491 34.9691 24.2743 34.1079 26.238C33.2466 28.2017 31.9878 29.9601 30.4131 31.3992L33.4769 34.831C35.5242 32.9579 37.1601 30.6698 38.2787 28.1149C39.3974 25.5599 39.9737 22.7952 39.9702 20.0001Z'
      fill='#21A038'
    />
    <path
      d='M20.2356 35.3802C18.1149 35.3809 16.0178 34.9303 14.0799 34.0575C12.1421 33.1847 10.4067 31.9091 8.98613 30.3135L5.60156 33.4169C7.44953 35.4921 9.70724 37.1503 12.2283 38.2843C14.7493 39.4181 17.4773 40.0024 20.2356 39.9991V35.3802Z'
      fill='url(#paint4_linear_63_1119)'
    />
    <path
      d='M10.0587 8.59967L6.99642 5.16797C4.94858 7.04068 3.31201 9.32871 2.19285 11.8837C1.0737 14.4386 0.496949 17.2034 0.500012 19.9988H5.05928C5.05834 17.85 5.5029 15.7247 6.36412 13.761C7.22535 11.7973 8.48405 10.0389 10.0587 8.59967Z'
      fill='url(#paint5_linear_63_1119)'
    />
    <defs>
      <linearGradient id='paint0_linear_63_1119' x1='7.20253' y1='33.5625' x2='2.10155' y2='19.0125' gradientUnits='userSpaceOnUse'>
        <stop offset='0.14' stopColor='#F1E813' />
        <stop offset='0.3' stopColor='#E6E418' />
        <stop offset='0.58' stopColor='#C9DA26' />
        <stop offset='0.89' stopColor='#A2CC39' />
      </linearGradient>
      <linearGradient id='paint1_linear_63_1119' x1='7.58203' y1='6.67631' x2='20.4552' y2='1.83568' gradientUnits='userSpaceOnUse'>
        <stop offset='0.06' stopColor='#0FA7DF' />
        <stop offset='0.54' stopColor='#0098F8' />
        <stop offset='0.92' stopColor='#0290EA' />
      </linearGradient>
      <linearGradient id='paint2_linear_63_1119' x1='18.9484' y1='37.2111' x2='33.4754' y2='33.9728' gradientUnits='userSpaceOnUse'>
        <stop offset='0.12' stopColor='#A2CC39' />
        <stop offset='0.28' stopColor='#86C239' />
        <stop offset='0.87' stopColor='#219F38' />
      </linearGradient>
      <linearGradient id='paint3_linear_63_1119' x1='19.2811' y1='1.54704' x2='31.7135' y2='5.3327' gradientUnits='userSpaceOnUse'>
        <stop offset='0.06' stopColor='#0290EA' />
        <stop offset='0.79' stopColor='#0C89CA' />
      </linearGradient>
      <linearGradient id='paint4_linear_63_1119' x1='6.74525' y1='32.8546' x2='20.2902' y2='37.8497' gradientUnits='userSpaceOnUse'>
        <stop offset='0.13' stopColor='#F1E813' />
        <stop offset='0.3' stopColor='#EAE616' />
        <stop offset='0.53' stopColor='#D8DF1F' />
        <stop offset='0.8' stopColor='#BAD52D' />
        <stop offset='0.98' stopColor='#A2CC39' />
      </linearGradient>
      <linearGradient id='paint5_linear_63_1119' x1='2.1985' y1='20.558' x2='7.74536' y2='6.32019' gradientUnits='userSpaceOnUse'>
        <stop offset='0.07' stopColor='#A2CC39' />
        <stop offset='0.26' stopColor='#81C45E' />
        <stop offset='0.92' stopColor='#0FA7DF' />
      </linearGradient>
    </defs>
  </svg>,
  'SberIcon',
);

export default SberIcon;
