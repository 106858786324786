import { Box, SxProps, Theme } from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef } from 'react';

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  image: '/img/qr-logo-white.png',
  dotsOptions: {
    type: 'rounded',
    color: '#0C0B02',
  },
  cornersSquareOptions: {
    type: 'dot',
  },
  cornersDotOptions: {
    type: 'dot',
  },
  backgroundOptions: { color: '#F9F8F4' },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 0,
  },
});

interface IQRCodeProps {
  url: string;
  sx?: SxProps<Theme>;
}

const QRCode = (props: IQRCodeProps) => {
  // const theme = useTheme();
  const ref = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: props.url,
    });
  }, [props.url]);

  return (
    <Box
      sx={{
        backgroundColor: '#F9F8F4',
        borderRadius: '48px',
        padding: '24px',
        width: '248px',
        ...props.sx,
      }}
    >
      <div style={{ lineHeight: '0px' }} ref={ref} />
    </Box>
  );
};

export default QRCode;
