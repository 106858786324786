export const PATH_HOME = '/';

// Auth
export const PATH_AUTH_SIGNIN = '/auth/signin';
export const PATH_AUTH_SIGNIN_CODE = '/auth/signin/code';
export const PATH_AUTH_REGISTER = '/auth/register';
export const PATH_AUTH_ROLE = '/auth/role';
export const PATH_AUTH_GOS = '/auth/gos';

export const PATH_AUTH_SIGNIN_EMAIL = '/auth/signin/email';
export const PATH_GUI = '/gui';

// Shortlink
export const PATH_SHORTLINK = '/s/:shortlink';

export const PATH_SHORTLINK_ACCEPT = '/invite/:shortlink/accept';
export const PATH_GETAPP = '/getapp';

// Landing page
export const PATH_LANDING = '/landing';
export const PATH_LANDING_PERSONAL_MANAGER = '/landing/personal-manager';
export const PATH_LANDING_PERSONAL_MANAGER_PAID = '/landing/personal-manager/paid/:uuidPurchase';

//Agent property mortgage

export const PATH_AGENT_MORTGAGE_PROPERTY_INVITE = '/agent/mortgage/invite';
export const PATH_AGENT_MORTGAGE_CLIENT_CREATE = '/agent/mortgage/client/create';
export const PATH_AGENT_MORTGAGE_HOME = '/agent/mortgage/home';
export const PATH_AGENT_MORTGAGE_ENTRY_CREATE = '/agent/mortgage/create/:uuidUser';
export const PATH_AGENT_MORTGAGE_PROPERTY = '/agent/mortgage/property';
export const PATH_AGENT_MORTGAGE_PROPERTY_CLIENTS = '/agent/mortgage/property/clients';
export const PATH_AGENT_MORTGAGE_PROPERTY_POLICY = '/agent/mortgage/property/policy';
export const PATH_AGENT_MORTGAGE_PROPERTY_INCOMES = '/agent/mortgage/property/incomes';
export const PATH_AGENT_MORTGAGE_PROPERTY_TUTORIAL = '/agent/mortgage/property/tutorial';
export const PATH_AGENT_MORTGAGE_PROPERTY_TUTORIAL_CONTENT = '/agent/mortgage/property/tutorial/:urlname';

export const PATH_AGENT_INSURANCE_LIST = '/agent/insurance';

// Agent property insurance
export const PATH_AGENT_INSURANCE_PROPERTY = '/agent/insurance/property';
export const PATH_AGENT_INSURANCE_PROPERTY_CLIENT = '/agent/insurance/property/client';
export const PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_CREATE = '/agent/insurance/property/client/form';
export const PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_UPDATE = '/agent/insurance/property/client/form/:uuidProfile';
export const PATH_AGENT_INSURANCE_PROPERTY_POLICY = '/agent/insurance/property/policy';
export const PATH_AGENT_INSURANCE_PROPERTY_ORDER = '/agent/insurance/property/policy/order';

export const PATH_AGENT_INSURANCE_PROPERTY_ORDER_SELECT = '/agent/insurance/property/:uuidPackage/order';
export const PATH_AGENT_INSURANCE_PROPERTY_ORDER_CREATE = '/agent/insurance/property/:uuidPackage/order/:uuidProfile';
export const PATH_AGENT_INSURANCE_PROPERTY_ORDER_SHARE = '/agent/insurance/property/policy/order/:uuidOrder/share';

export const PATH_AGENT_INSURANCE_PROPERTY_INCOMES = '/agent/insurance/property/incomes';
export const PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL = '/agent/insurance/property/tutorial';
export const PATH_AGENT_INSURANCE_PROPERTY_TUTORIAL_CONTENT = '/agent/insurance/property/tutorial/:urlname';

export const PATH_AGENT_INSURANCE_PROPERTY_STATISTIC = '/agent/insurance/property/statistic';

// Notifications
export const PATH_NOTIFICATIONS = '/notifications';

// User
export const PATH_USER = '/user';

export const PATH_TUTORIAL = '/tutorial';
export const PATH_USER_MY_INCOMES = '/user/my-incomes';
export const PATH_USER_GENERAL = '/user/general';
export const PATH_USER_PASSPORT = '/user/passport';
export const PATH_USER_ADDRESS = '/user/address';
export const PATH_USER_REQUISITES = '/user/requisites';
export const PATH_USER_DOCS = '/user/docs';
export const PATH_USER_CONTRACT = '/user/contract';

// Client
export const PATH_CLIENT_MORTGAGE_FORM_PERSONAL = '/mortgage/:uuidEntry/:uuidProfile/form/:formName/:groupName';
export const PATH_CLIENT_MORTGAGE_FORM_PERSONAL_MODERN = '/mortgage/:uuidEntry/:uuidUser/form/:formName/:groupName';
export const PATH_CLIENT_MORTGAGE_FORM_INCOMES = '/mortgage/:uuidEntry/:uuidUser/form/profile/incomes';

export const PATH_CLIENT_MORTGAGE_FORM_VERIFICATION = '/mortgage/:uuidEntry/:uuidUser/verification';
export const PATH_CLIENT_MORTGAGE_REMOTE_CONTENT = '/mortgage/content/:urlname';
export const PATH_CLIENT_MORTGAGE_ENTRY_INVITE = '/mortgage/:uuid/invite/:role';

// Client entry create form
export const PATH_CLIENT_MORTGAGE_ENTRY_CREATE_FORM = '/mortgage/create/form';
export const PATH_CLIENT_MORTGAGE_ENTRY_CREATE_INVITE = '/mortgage/create/invite';
export const PATH_CLIENT_MORTGAGE_ENTRY_CREATE_SUCCESS = '/mortgage/create/:uuid/:uuidProfile/success';
export const PATH_CLIENT_MORTGAGE_ENTRY_CREATE = '/mortgage/create';

export const PATH_CLIENT_MORTGAGE_ENTRY_UUID_CREATE = '/mortgage/:uuidUser/create';

export const PATH_CLIENT_MORTGAGE_ENTRY_EDIT = '/mortgage/:uuid/edit';
export const PATH_CLIENT_MORTGAGE_ENTRY_PROGRESS = '/mortgage/:uuid/progress';
export const PATH_CLIENT_MORTGAGE_ENTRY_PROPOSE = '/mortgage/:uuid/propose';
export const PATH_CLIENT_MORTGAGE_ENTRY_SIGN = '/mortgage/:uuid/sign';
export const PATH_CLIENT_MORTGAGE_ENTRY_SIGN_PRIVACY = '/mortgage/:uuid/sign/privacy';
export const PATH_CLIENT_MORTGAGE_ENTRY_SIGN_ENTRY = '/mortgage/:uuid/sign/entry';
export const PATH_ONBOARDING = '/onboarding';

// real estate paths

export const PATH_CLIENT_MORTGAGE_ENTRY_FORM_REAL_ESTATE = '/mortgage/:uuidEntry/:uuidProfile/form/realEstate';
export const PATH_CLIENT_MORTGAGE_ENTRY_FORM_REAL_ESTATE_APPROVALS = '/mortgage/:uuidEntry/:uuidProfile/form/realEstate/approvals';
export const PATH_CLIENT_MORTGAGE_ENTRY_FORM_REAL_ESTATE_VERIFICATION = '/mortgage/:uuidEntry/:uuidProfile/form/realEstate/verification';

// insurance paths

export const PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE = '/mortgage/:uuidEntry/:uuidUser/form/insuranceMortgage';
export const PATH_CLIENT_MORTGAGE_ENTRY_FORM_INSURANCE_GROUP = '/mortgage/:uuidEntry/:uuidUser/form/insuranceMortgage/:groupName';

export enum InsuranceGroupNames {
  PROGRAM = 'program',
  BASE_DATA = 'baseData',
  CREDIT_CONTRACT = 'creditContract',
  PROPERTY = 'propertyInsurance',
  PERSONAL = 'personalInsurance',
  TITLE = 'titleInsurance',
  PROPOSAL_WAITING = 'proposalWaiting',
  COMPANY = 'company',
  PAYMENT = 'payment',
}

//particiant invite
export const PATH_CLIENT_MORTGAGE_PARTICIPANT_INVITE = '/mortgage/:uuid/participant/invite';

export const PATH_AUTH_INVITE_REGISTER = '/auth/invite/:shortlink/register';
export const PATH_AUTH_INVITE_SIGNIN = '/auth/invite/:shortlink/signin';

// admin pages
export const PATH_ADMIN = '/admin';

//experements pages
export const PATH_CLIENT_MORTGAGE_ENTRY_EXPERIMENT = '/mortgage/:uuidEntry/:uuidUser/experiment';

export const PATH_BUSINESS_CREDIT_ROOT = `/businessCredit`;

export const PATH_BUSINESS_CREDIT_ENTRY_PROGRESS = '/businessCredit/:uuidEntry';
export const PATH_BUSINESS_CREDIT_ENTRY_CREATE = `/businessCredit/create`;
export const PATH_BUSINESS_CREDIT_ENTRY_EDIT = '/businessCredit/:uuidEntry/edit';
export const PATH_BUSINESS_CREDIT_ENTRY_COMPANY = '/businessCredit/:uuidEntry/company';

export const PATH_BUSINESS_CREDIT_ENTRY_DOCUMENTS = '/businessCredit/:uuidEntry/documents';
export const PATH_BUSINESS_CREDIT_ENTRY_SIGN = '/businessCredit/:uuidEntry/sign';
export const PATH_BUSINESS_CREDIT_WELCOME = '/businessCredit/:uuidEntry/welcome';

export const PATH_BUSINESS_CREDIT_PROPOSE = '/businessCredit/:uuidEntry/propose';

export const PATH_BANK_GARANTEE_ROOT = `/BankGuarantee`;

export const PATH_BANK_GARANTEE_ENTRY_PROGRESS = '/BankGuarantee/:uuidEntry';
export const PATH_BANK_GARANTEE_ENTRY_CREATE = `/BankGuarantee/create`;
export const PATH_BANK_GARANTEE_ENTRY_EDIT = '/BankGuarantee/:uuidEntry/edit';
export const PATH_BANK_GARANTEE_ENTRY_COMPANY = '/BankGuarantee/:uuidEntry/company';

export const PATH_BANK_GARANTEE_ENTRY_DOCUMENTS = '/BankGuarantee/:uuidEntry/documents';
export const PATH_BANK_GARANTEE_ENTRY_SIGN = '/BankGuarantee/:uuidEntry/sign';
export const PATH_BANK_GARANTEE_WELCOME = '/BankGuarantee/:uuidEntry/welcome';

export const PATH_BANK_GARANTEE_PROPOSE = '/BankGuarantee/:uuidEntry/propose';
