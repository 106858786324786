import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_CLIENT_MORTGAGE_ENTRY_EXPERIMENT } from 'routes/paths';
import ProfileExperimentForm from './forms/profile.form';
import FamilyExperimentForm from './forms/family.form';
import IncomeExperimentForm from './forms/Income.form';
import utils from 'utils';
import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { IFillingQuestionnaireDTO } from 'api/services/experiment/experiment.dto';
import { LoadingButton } from '@mui/lab';
import { getQuestionarie, postSendQuestionarie } from 'api/services/experiment/experiment.service';
import { useEffect, useState } from 'react';
import { EProfileMarriageContractStatus } from 'api/services/profile/profile.enum';
import CoborrowerDialog from '../CoborrowerDialog';
import { zodResolver } from '@hookform/resolvers/zod';
import { experimentSchema } from 'api/services/experiment/experiment.schema';
import { useMortgage } from 'components/providers/MortageEntryProvider';
import { EMortgageEntryRole } from 'api/services/mortgage.dto';

export default function MortgageEntryExperimentPage({ uuidUser, uuidEntry }: { uuidUser: string; uuidEntry: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalActive, setModalActive] = useState(false);
  const mortgage = useMortgage();
  const [disabled, setDisabled] = useState(false);

  const hookForm = useForm<IFillingQuestionnaireDTO>({
    defaultValues: {},
    resolver: zodResolver(experimentSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const handleSubmit = async () => {
    if (disabled) {
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_EXPERIMENT, { uuidEntry, uuidUser }));
      return;
    }
    await updateProfileRequest();
    const executedEffect = showDialogEffect();
    if (!executedEffect) {
      navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_EXPERIMENT, { uuidEntry, uuidUser }));
    }
  };
  const updateProfileRequest = async () => {
    const values = hookForm.getValues();
    const enumToBool = (value: unknown) => (value === 'yes' ? true : false);
    const updatedData = {
      ...values,
      isMarried: enumToBool(values.isMarried),
      haveAlterationOfName: enumToBool(values.haveAlterationOfName),
      haveFamilyChildren: enumToBool(values.haveFamilyChildren),
    };
    try {
      await postSendQuestionarie({ uuidEntry: uuidEntry, uuidUser: uuidUser }, updatedData);
    } catch (err) {}
  };

  const loadAsync = async () => {
    const boolToEnum = (value: unknown) => (!!value ? 'yes' : 'no');
    try {
      const { body } = await getQuestionarie({ uuidEntry: uuidEntry!, uuidUser: uuidUser! });
      const data = body.data;
      setDisabled(body.isFull);
      hookForm.reset({
        ...data,
        isMarried: boolToEnum(data.isMarried) as unknown as boolean,
        haveAlterationOfName: boolToEnum(data.haveAlterationOfName) as unknown as boolean,
        haveFamilyChildren: boolToEnum(data.haveFamilyChildren) as unknown as boolean,
      });
    } catch (err) {}
  };

  const showDialogEffect = () => {
    const hasCoborrower = mortgage.progress?.profile.find((userProfile) => userProfile.role === EMortgageEntryRole.coBorrower);

    const values = hookForm.getValues();
    const enumToBool = (value: unknown) => (value === 'yes' ? true : false);
    const updatedData = {
      ...values,
      isMarried: enumToBool(values.isMarried),
      haveAlterationOfName: enumToBool(values.haveAlterationOfName),
      haveFamilyChildren: enumToBool(values.haveFamilyChildren),
    };

    if (updatedData.isMarried && updatedData.marriageContractStatus === EProfileMarriageContractStatus.missing && !hasCoborrower) {
      setModalActive(true);
      return true;
    }
    return false;
  };

  useEffect(() => {
    loadAsync();
  }, []);

  const hasErrors = Object.values(hookForm.formState.errors).length > 0;

  const saveAndContinueBtn = () => handleSubmit();

  return (
    <>
      <Grid component="form" onSubmit={hookForm.handleSubmit(handleSubmit)} container spacing={3}>
        <Grid item xs={12}>
          <ProfileExperimentForm hookForm={hookForm} />
        </Grid>
        <Grid item xs={12}>
          <FamilyExperimentForm hookForm={hookForm} />
        </Grid>
        <Grid item xs={12}>
          <IncomeExperimentForm hookForm={hookForm} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', paddingBottom: '8px', paddingTop: '20px' }}>
            <LoadingButton variant='contained' fullWidth disabled={hasErrors} type="submit" data-testid="experiment.submit">
              {t('base.ExperimentProfile.save')}
            </LoadingButton>
            <Button
              data-testid="experiment.saveExit"
              disabled={disabled}
              onClick={saveAndContinueBtn}
              fullWidth
              variant='contained'
              color='secondary'
              size='large'
            >
              {t('interface:page.ClientMortgageFormPage.saveAndContinueBtn')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {modalActive && (
        <CoborrowerDialog
          handleSuccessSubmit={() => {
            navigate(utils.url.urlReplace(PATH_CLIENT_MORTGAGE_ENTRY_EXPERIMENT, { uuidEntry, uuidUser }));
          }}
          open={modalActive}
          handleClose={() => {
            setModalActive(false);
            hookForm.setValue('marriageContractStatus', EProfileMarriageContractStatus.planned);
          }}
        />
      )}
    </>
  );
}
