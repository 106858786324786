export enum ERealEstateCategory {
  flat = 'flat',
  apartments = 'apartments',
  other = 'other',
}

// Todo: correct titles
export enum ERealEstateDocumentType {
  EGRN = 'egrn',
  certificatOfOwnershipRegistration = 'certificatOfOwnershipRegistration',
  rightsOfOwnership = 'rightsOfOwnership',
  technicalPassportOfApartment = 'technicalPassportOfApartment',
  passportRf = 'passportRf',
  other = 'other',
}

export enum ERealEstateValuationOrderStatus {
  draft = 'draft',
  cancelled = 'cancelled',
  inProgress = 'inProgress',
  finished = 'finished',
}

export enum ERealEstateValuationOrderProvider {
  SRG = 'SRG',
}

export enum ERealEstateValuationOrderPaymentType {
  card = 'card',
  invoice = 'invoice',
}

export enum ERealEstateValuationOrderPaymentStatus {
  hold = 'hold',
  paid = 'paid',
  cancelled = 'cancelled',
}
export enum EProfilePassportPart {
  firstPage = 'firstPage',
}
export enum EProfileDocumentPart {
  front = 'front',
  back = 'back',
}
export enum ERealEstatePropOwnershipType {
  firstHand = 'firstHand',
  secondHand = 'secondHand',
}

export enum ERealEstateCurrentOwnerType {
  individual = 'individual',
  legalEntity = 'legalEntity',
}
export enum EMortgageEntryRealEstateStatus {
  process = 'process',
  verification = 'verification',
  approved = 'approved',
  reject = 'reject',
}
export enum ERealEstateStatus {
  active = 'active',
  deleted = 'deleted',
}
