import Page from 'components/layouts/Page';
import { useTranslation } from 'react-i18next';
import { PATH_USER } from 'routes/paths';

import NotificationsList from './components/List';

const NotificationsPage = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('helmet:NotificationsPage.title')}
      meta={[{ name: 'description', content: t('helmet:NotificationsPage.description') }]}
      header={{ type: 'backButton', title: t('interface:page.NotificationsPage.title'), backRoute: PATH_USER }}
    >
      <NotificationsList />
    </Page>
  );
};

export default NotificationsPage;
