import { Box, Button, Chip, Paper, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { deleteAgentClientById } from 'api/services/agent';
import InsuranceAlfaIcon from 'components/Icons/InsuranceAlfa';
import InsuranceIngosIcon from 'components/Icons/InsuranceIngos';
import InsuranceResoIcon from 'components/Icons/InsuranceReso';
import TrashIcon from 'components/Icons/Trash';
import BaseLinkRouter from 'components/base/Link/Router';
import BaseSwipeable from 'components/base/Swipeable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PATH_AGENT_INSURANCE_PROPERTY,
  PATH_AGENT_INSURANCE_PROPERTY_CLIENT,
  PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_UPDATE,
} from 'routes/paths';
import utils from 'utils';
import { getFullName } from 'utils/format/format';

// TODO: add colors from theme
// TODO: use tag util
const clientTags = {
  dataFilling: {
    color: '#0FC2EB',
    title: 'заполнение данных',
  },
  productConfirmation: {
    color: '#E81E1E',
    title: 'подтверждение продукта',
  },
  productSelection: {
    color: '#1A6CE7',
    title: 'выбор продукта',
  },
  sendLink: {
    color: '#E81E1E',
    title: 'ссылка не отправлена',
  },
  waitingPayment: {
    color: '#F4A611',
    title: 'ожидание оплаты',
  },
  paid: {
    color: '#31C918',
    title: 'полис оплачен',
  },
};

const clientCompany = {
  resoGarant: {
    img: InsuranceResoIcon,
  },
  ingosstrah: {
    img: InsuranceIngosIcon,
  },
  alfastrah: {
    img: InsuranceAlfaIcon,
  },
};
const ListItem = ({ client, hideButtons = false, onDeleted }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [status, setStatus] = useState('idle');
  const { enqueueSnackbar: showSnackbar } = useSnackbar();

  const { address = '', firstname = '', lastname = '', patronymic = '', tags = [], order, uuid } = client;
  const { company, price } = order?.data || {};
  const { img } = clientCompany[company] || {};

  const fullName = getFullName({ lastname, firstname, patronymic });

  const editRestrictedTags = ['dataFilling', 'waitingPayment', 'paid'];

  const isEditRestricted = (tags) => {
    return tags.some((tag) => editRestrictedTags.includes(tag));
  };

  const onDelete = async () => {
    setStatus('loading');

    try {
      const res = await deleteAgentClientById(uuid);

      if (res.ok) {
        onDeleted(uuid);
      } else {
        setStatus('failed');
        showSnackbar(t('errors:components.views.Insurance.Client.List.Card.delete'), { variant: 'error' });
      }
    } catch (error) {
      // TODO: correct error handling
      setStatus('failed');
      showSnackbar(t('errors:components.views.Insurance.Client.List.Card.delete'), { variant: 'error' });
    }
  };

  // TODO: move in base
  // eslint-disable-next-line react/prop-types
  const DetailsLink = ({ sx }) => {
    return (
      <BaseLinkRouter
        sx={{ color: theme.palette.common.link, ...sx }}
        textAlign='center'
        underline='none'
        to={`${PATH_AGENT_INSURANCE_PROPERTY_CLIENT}/${uuid}`}
      >
        Подробнее
      </BaseLinkRouter>
    );
  };

  return (
    <BaseSwipeable
      key={uuid}
      distanceToFix='0.3'
      swipeLeftConfig={{
        active: !hideButtons,
        button: {
          styles: {
            backgroundColor: theme.palette.error.light,
          },
          onClick: onDelete,
        },
        icon: {
          component: TrashIcon,
          styles: { width: '22px', height: '25px', color: theme.palette.common.delete },
        },
      }}
      containerStyle={{
        marginTop: '16px',
        overflow: 'visible',
      }}
      status={status}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 1,
          minHeight: '100px',
          padding: '24px',
          borderRadius: '16px',
        }}
        variant='outlined'
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {tags?.map((tag) => {
              if (!clientTags[tag]) {
                return null;
              }

              const { title, color } = clientTags[tag];

              return (
                <Chip
                  key={title}
                  label={title}
                  variant='outlined'
                  sx={{
                    color: theme.palette.common.white,
                    backgroundColor: color,
                    border: 'none',
                    fontSize: '14px',
                    marginBottom: '12px',
                  }}
                />
              );
            })}
          </Box>

          <Typography variant='h3m' sx={{ marginBottom: '4px' }}>
            {fullName}
          </Typography>

          <Typography variant='h6' sx={{ color: theme.palette.text.secondary, marginBottom: '16px' }}>
            {address === 'null' ? null : address}
          </Typography>

          {company && (
            <Stack justifyContent='space-between' alignItems='center' direction='row' mb={2}>
              <Stack direction='row' spacing='9px'>
                <SvgIcon component={img} sx={{ width: '50px', height: '50px' }} />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: theme.palette.secondary.main,
                    borderRadius: '8px',
                    padding: '0 15px',
                  }}
                >
                  <Typography variant={'h3m'}>{utils.format.price(price / 100)}</Typography>
                </Box>
              </Stack>

              {['dataFilling', 'productSelection'].some((item) => !tags.includes(item)) && <DetailsLink />}
            </Stack>
          )}

          {!hideButtons && tags && tags.includes('dataFilling') && (
            <Button
              component={BaseLinkRouter}
              to={PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_UPDATE.replace(':uuidProfile', client.uuid)}
              sx={{ marginBottom: '8px' }}
              variant='contained'
              color='secondary'
              value={uuid}
            >
              Заполнить данные
            </Button>
          )}

          {!hideButtons && tags && tags.includes('productSelection') && (
            <Button
              component={BaseLinkRouter}
              to={`${PATH_AGENT_INSURANCE_PROPERTY}/all?uuidClient=${client.uuid}`}
              sx={{ marginBottom: '8px' }}
              variant='contained'
              color='secondary'
              value={uuid}
            >
              Выбрать продукт
            </Button>
          )}
        </Box>

        {!hideButtons && tags && !isEditRestricted(tags) && (
          <BaseLinkRouter
            sx={{ marginTop: '16px', color: theme.palette.common.link }}
            textAlign='center'
            underline='none'
            to={PATH_AGENT_INSURANCE_PROPERTY_CLIENT_FORM_UPDATE.replace(':uuidProfile', client.uuid)}
          >
            {t('interface:base.ListItem.editBtn')}
          </BaseLinkRouter>
        )}

        {!hideButtons && (['dataFilling', 'productSelection'].every((item) => tags.includes(item)) || !company) && (
          <DetailsLink sx={{ marginTop: '16px' }} />
        )}
      </Paper>
    </BaseSwipeable>
  );
};

ListItem.propTypes = {
  client: PropTypes.object,
  onDeleted: PropTypes.func,
  hideButtons: PropTypes.bool,
};

export default ListItem;
