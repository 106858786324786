import { IRequestResult, req } from '../http';

import { EContractType, EMortgageContractFileType } from './contract.dto';
import { IExperimentUserInvite } from './experiment/experiment.dto';
import { EmptyFields } from './form.dto';
import {
  IMortgageEntryCreateDTO,
  IMortgageEntryEntity,
  IMortgageEntryParams,
  IMortgageEntryProgress,
  IMortgageEntryPropose,
  IMortgageEntryProposeDecision,
  InsuranceProfile,
} from './mortgage.dto';
import { IUserEntity } from './user.dto';

export const entryGet = (uuidEntry: string) => {
  return req<null, IMortgageEntryEntity>(`mortgageEntry/${uuidEntry}`, {
    method: 'GET',
  });
};

export const entryList = () => {
  return req<null, Array<IMortgageEntryEntity>>(`user/mortgage-entry/list`, {
    method: 'GET',
  });
};

export const entryCreate = (entry: IMortgageEntryCreateDTO): Promise<IRequestResult<IMortgageEntryEntity>> => {
  return req<IMortgageEntryCreateDTO, IMortgageEntryEntity>(`user/mortgage-entry`, { method: 'POST', body: entry });
};
export const entryUpdate = (uuidEntry: string, entry: IMortgageEntryParams) => {
  return req<IMortgageEntryParams, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/params`, { method: 'PUT', body: entry });
};
export const entryGenerateContract = (
  uuidEntry: string,
  uuidUser: string,
  contractType: EContractType,
  fileType: EMortgageContractFileType,
): Promise<IRequestResult<string | { link: string }>> => {
  return req<undefined, string | { link: string }>(`mortgageEntry/${uuidEntry}/user/${uuidUser}/contract/${contractType}/${fileType}`, {
    method: 'GET',
    headers: {
      'Content-Type': fileType === 'html' ? 'text/html' : 'application/json',
    },
  });
};

export const entryProgress = (uuid: string): Promise<IRequestResult<IMortgageEntryProgress>> => {
  return req<undefined, IMortgageEntryProgress>(`user/mortgage-entry/${uuid}/progress`, {
    method: 'GET',
  });
};

export const getEntryProfile = (uuid: string, uuidUser: string): Promise<IRequestResult<InsuranceProfile>> => {
  return req<undefined, InsuranceProfile>(`mortgageEntry/${uuid}/user/${uuidUser}/profile`, {
    method: 'GET',
  });
};

export function getEntryUserEmptyFields(uuid: string, uuidUser: string) {
  return req<null, EmptyFields>(`mortgageEntry/${uuid}/user/${uuidUser}/emptyFields`, { method: 'GET' });
}

export function getEntryProposeList(uuid: string) {
  return req<null, IMortgageEntryPropose[]>(`/user/mortgage-entry/${uuid}/proposes`, { method: 'GET' });
}

export function getEntryProposeDecisions(uuid: string) {
  return req<null, IMortgageEntryProposeDecision[]>(`/user/mortgage-entry/${uuid}/proposes/decisions`, { method: 'GET' });
}

export function getEntryProposeByDecision(uuid: string, uuidPropose: string) {
  return req<null, IMortgageEntryProposeDecision>(`/user/mortgage-entry/${uuid}/propose/${uuidPropose}/decision`, { method: 'GET' });
}

export function putProposeAccept(uuidEntry: string, uuidPropose: string, body: IMortgageEntryProposeDecision) {
  return req<IMortgageEntryProposeDecision, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/${uuidPropose}/accept`, {
    method: 'PUT',
    body,
  });
}

export function postSelectPropose(uuidEntry: string, uuidPropose: string) {
  return req<null, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/${uuidPropose}/select`, {
    method: 'POST',
  });
}

export function postCancelPropose(uuidEntry: string, uuidPropose: string) {
  return req<null, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/${uuidPropose}/cancel`, {
    method: 'PUT',
  });
}

/**
 * method to confirm final decision
 *
 * role: [manager] or [borrower]
 *
 * {@link https://gate.ip.mindcode.ru/documentation/static/index.html#/user-entry/updatePrimaryProposeDecision}
 * @param uuidEntry uuid of entry
 * @param uuidPropose uuid of propose
 * @param body  decision data
 * @returns
 */
export function putFinalDecision(uuidEntry: string, uuidPropose: string, body: IMortgageEntryProposeDecision) {
  return req<IMortgageEntryProposeDecision, IMortgageEntryEntity>(`user/mortgage-entry/${uuidEntry}/${uuidPropose}/accept`, {
    method: 'PUT',
    body,
  });
}

export function addNewUserToEntry(uuidEntry: string, body: IExperimentUserInvite) {
  return req<unknown, IUserEntity>(`mortgageEntry/${uuidEntry}/user`, {
    method: 'POST',
    body,
  });
}
