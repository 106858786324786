/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import { createSvgIcon } from '@mui/material/utils';

const SentData = createSvgIcon(
  <svg viewBox='0 0 160 160' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z'
      fill='#F8E11A'
    />
    <g filter='url(#filter0_d_9826_117204)'>
      <path
        d='M90.7303 40.6338V60.9671C90.7303 62.624 92.0734 63.9671 93.7303 63.9671H114.286'
        fill='url(#paint0_radial_9826_117204)'
        shapeRendering='crispEdges'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.3493 120.634H110.921C111.813 120.634 112.669 120.283 113.3 119.657C113.931 119.032 114.286 118.185 114.286 117.3V65.8301H92.889C90.6799 65.8301 88.889 64.0393 88.889 61.8301V40.6338H50.3493C49.4569 40.6338 48.6009 40.985 47.9699 41.6101C47.3388 42.2352 46.9843 43.0831 46.9843 43.9671V117.3C46.9843 118.185 47.3388 119.032 47.9699 119.657C48.6009 120.283 49.4569 120.634 50.3493 120.634ZM114.286 63.9671L90.7303 40.6338V60.9671C90.7303 62.624 92.0734 63.9671 93.7303 63.9671H114.286Z'
        fill='url(#paint1_radial_9826_117204)'
        shapeRendering='crispEdges'
      />
    </g>
    <path
      d='M139.683 159.995C150.904 159.995 160 150.899 160 139.678C160 128.457 150.904 119.36 139.683 119.36C128.462 119.36 119.365 128.457 119.365 139.678C119.365 150.899 128.462 159.995 139.683 159.995Z'
      fill='#FFF5E3'
    />
    <path
      d='M132.161 146.861L147.01 132.012M147.01 132.012L134.925 132.076M147.01 132.012L146.946 144.097'
      stroke='#F4A611'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <filter
        id='filter0_d_9826_117204'
        x='38.9843'
        y='29.6338'
        width='99.3016'
        height='112'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx='8' dy='5' />
        <feGaussianBlur stdDeviation='8' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9826_117204' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9826_117204' result='shape' />
      </filter>
      <radialGradient
        id='paint0_radial_9826_117204'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(55.8731 55.237) rotate(62.6263) scale(126.42 114.273)'
      >
        <stop offset='0.416533' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_9826_117204'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(55.8731 55.237) rotate(62.6263) scale(126.42 114.273)'
      >
        <stop offset='0.416533' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </radialGradient>
    </defs>
  </svg>,
  'SentData',
);

export default SentData;
