import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { LS_INVITE_LINK } from 'api/http';
import { postJoinByShortLink } from 'api/services/short-link';
import { Email } from 'components/Icons/Email';
import SuccessIcon from 'components/Icons/Success';
import NoticeScreen from 'components/base/NoticeScreen';
import useInviteLogic from 'hooks/logic/useInviteLogic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'routes/paths';
import utils from 'utils';
import { ObjectType } from 'utils/iots';

const RoleInviteAccept = () => {
  const { shortlink, getShortLinkData, shortlinkData } = useInviteLogic();
  const navigate = useNavigate();
  const [isSuccess, setSuccess] = useState(false);
  const theme = useTheme();

  const { t } = useTranslation();

  // // TODO: Send errors to sentry
  useEffect(() => {
    if (!shortlink) {
      navigate(PATH_HOME);
    }

    getShortLinkData().catch(console.error);
  }, [shortlink]);

  const handleInvite = () => {
    if (shortlinkData?.url)
      postJoinByShortLink(shortlinkData?.url).then((res) => {
        if (res.ok) {
          setSuccess(true);
          sessionStorage.removeItem(LS_INVITE_LINK);
        }
        // TODO: add handle errors
      });
  };

  const handleCancel = () => {
    sessionStorage.removeItem(LS_INVITE_LINK);
    navigate(PATH_HOME, { replace: true });
  };

  return (
    <>
      {isSuccess ? (
        <NoticeScreen
          successIcon={SuccessIcon}
          successTitle={t('interface:page.InviteAccept.Success.title')}
          successSubtitle={t('interface:page.InviteAccept.Success.subtitle')}
          successBtnText={t('interface:page.InviteAccept.Success.textBtn')}
        />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              flexDirection: 'column',

              padding: '0 20px',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                gap: '8px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '50%',
                  justifyContent: 'center',
                  padding: '26px 28px 35px 28px',
                  border: '2px solid #B9B9B2',
                  backgroundColor: theme.palette.secondary.main,
                }}
              >
                <SvgIcon viewBox='0 0 72 68' component={Email} sx={{ height: '65px', width: '70px' }} />
              </Box>
              <Typography variant='h2b'>{utils.format.nameWithInitials(shortlinkData?.data?.inviter)}</Typography>
              <Typography variant='h3' sx={{ fontWeight: 500 }}>
                {utils.format.hidePhoneNumber(shortlinkData?.data?.inviter?.phone)}
              </Typography>
              <Typography variant='h4'>{t('interface:page.InviteAccept.description')}</Typography>
              <Button size='small' variant='contained'>
                {t(`common:mortgage.roles.${(shortlinkData?.data as ObjectType)?.role || ''}`)}
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                flexDirection: 'column',
                justifyContent: 'center',
                width: { xs: 1, sm: 500 },
                paddingBottom: '42px',
                justifySelf: 'flex-end',
              }}
            >
              <Button sx={{ height: '56px' }} variant='contained' onClick={handleInvite}>
                {t('interface:page.InviteAccept.btnApply')}
              </Button>
              <Button sx={{ backgroundColor: theme.palette.secondary.main, height: '56px' }} onClick={handleCancel} variant='contained'>
                {t('interface:page.InviteAccept.btnCancel')}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default RoleInviteAccept;
