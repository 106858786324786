import { Typography, useTheme } from '@mui/material';
import { ApiError } from 'api/errors';
import { useTranslation } from 'react-i18next';

interface Props {
  error?: unknown;
}

export default function ErrorMessage({ error }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  return (
    <Typography textAlign='center' sx={{ padding: '16px 0', color: `${theme.palette.error.main}` }} variant='h5'>
      {t([`errors:${(error as ApiError)?.name}`, `errors:${error}`, 'errors:logic.somethingWrong'])}
    </Typography>
  );
}
